import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import VisualIdentityService from 'services/VisualIdentityService';
import logoSVG from 'assets/images/logo.svg';
import { IImage } from 'interfaces/IImage';
import MediaKitService from 'services/MediaKitService';

interface PortalContextProps {
  portalName: string;
  portalLogo: string | File | IImage[] | undefined;
  portalUser: string;
  midiaKitURL?: string;
  setPortalName: (name: string) => void;
  setPortalLogo: (logo: string | File | IImage[] | undefined) => void;
  setPortalUser: (user: string) => void;
  setMidiaKitURL?: (url: string) => void;
}

const PortalContext = createContext<PortalContextProps | undefined>(undefined);

export const PortalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [portalName, setPortalName] = useState<string>('');
  const [portalLogo, setPortalLogo] = useState<string | File | IImage[] | undefined>('');
  const [portalUser, setPortalUser] = useState<string>('');
  const [midiaKitURL, setMidiaKitURL] = useState<string>('');

  const fetchPortalName = async () => {
    const response = await new VisualIdentityService().getPortalName();
    response ? setPortalName(response as unknown as string) : setPortalName('Porto Seguro');
  };

  const fetchLogo = async () => {
    const response = await new VisualIdentityService().getLogo();
    response ? setPortalLogo(response as unknown as string) : setPortalLogo(logoSVG);
  };

  const fetchMidiaKit = async () => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      setMidiaKitURL('');
      return;
    } else {
      const response = await new MediaKitService().findAll();
      response ? setMidiaKitURL(response[0].description) : setMidiaKitURL('');
    }
  };

  useEffect(() => {
    fetchPortalName();
    fetchLogo();
  }, []);

  useEffect(() => {
    fetchMidiaKit();
  }, [PortalContext]);

  return (
    <PortalContext.Provider
      value={{
        portalName,
        portalLogo,
        portalUser,
        midiaKitURL,
        setPortalName,
        setPortalLogo,
        setPortalUser,
        setMidiaKitURL,
      }}>
      {children}
    </PortalContext.Provider>
  );
};

export const usePortalContext = (): PortalContextProps => {
  const context = useContext(PortalContext);
  if (!context) {
    throw new Error('usePortalContext must be used within a PortalProvider');
  }
  return context;
};
