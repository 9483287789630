import styled from 'styled-components';

export const Container = styled.div`
  width: 50vw;
  height: fit-content;
  border: 1px solid #9a9a9a;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  color: #272727;
  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5vh;
    h2,
    span {
      font-weight: 500;
    }
  }
  label {
    font-size: 16px;
    font-weight: 500;
    span {
      color: #ec3237;
    }
  }
  input {
    margin-top: 1vh;
    width: 100%;
    height: 40px;
    margin-bottom: 2.5vh;
    border-radius: 18px;
    border: 1px solid #9a9a9a;
    padding: 0 10px;
  }
  .dropdown {
    margin-top: 1vh;
    width: 100%;
    height: 40px;
    margin-bottom: 2.5vh;
    padding: 0 0.5vw;
    border-radius: 18px;
    div {
      margin-top: 5vh;
    }
  }
  button {
    margin: 0 0 0 auto;
  }

  @media (max-width: 960px) {
    width: 90%;
    padding: 16px;
    margin: 0 auto;
  }
`;

export const InputField = styled.div`
  div {
    display: flex;
    flex-direction: column;

    label {
      white-space: nowrap;
      span {
        color: #ec3237;
        margin-left: 0.2vw;
      }
    }

    input {
      padding: 0 10px;
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 20px;

  @media (max-width: 960px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const SignUpForm = styled.div`
  width: 50vw;
  height: auto;
  border: 1px solid #9a9a9a;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 18px;
  margin: 5% 0;
  h2 {
    margin-bottom: 1vw;
  }
  h2,
  span {
    font-weight: 500;
  }
  input {
    &:not([type='checkbox']) {
      border: 1px solid #9a9a9a;
      border-radius: 18px;
      height: 40px;
      margin-top: 1vh;
      padding: 0 10px;
    }
  }
  header {
    display: flex;
    justify-content: space-between;
  }
  #cadastur-input {
    width: 23vw;
  }
  #company-name-input {
    width: 23vw;
  }
  #social-reason-input {
    width: 100%;
  }
  #full-name-input {
    width: 60%;
  }
  #cpf-input {
    width: 40%;
  }
  #cellphone-input {
    width: 30%;
  }
  #email-input {
    width: 40%;
  }
  #cep-input {
    width: 30%;
  }
  #address-input {
    width: 84%;
  }
  #number-input {
    width: 6vw;
  }
  #complement-input {
    width: 23vw;
  }
  #neighborhood-input {
    width: 100%;
  }
  #city-input {
    width: 100%;

    .CustomSelect {
      margin-top: 5px;
    }
  }
  #state-input {
    width: 30%;

    .CustomSelect {
      margin-top: 5px;
    }
  }
  #password-input {
    width: 100% !important;
  }

  @media (max-width: 960px) {
    width: 90%;
    padding: 16px;
    margin: 0 auto;

    #cadastur-input,
    #company-name-input,
    #social-reason-input,
    #full-name-input,
    #CPF-input {
      width: 40%;
    }
    #cellphone-input {
      width: 30%;
    }
    #email-input {
      width: 40%;
    }
    #cep-input {
      width: 30%;
    }
    #address-input {
      width: 55%;
    }
    #number-input {
      width: 30%;
    }
    #complement-input {
      width: 45%;
    }
    #neighborhood-input {
      width: 100%;
    }
    #city-input {
      width: 100%;
    }
    #state-input {
      width: 25%;
    }
    #password-input {
      width: 100% !important;
    }
  }
`;
export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  border-top: 1px dotted #9a9a9a;
  margin: 20px 0;
`;

export const LabelCheck = styled.div`
  display: flex;
  white-space: nowrap;
  margin: 0;
  label {
    margin-left: 1vw;
    white-space: break-spaces;
    a {
      color: black;
      margin-left: 5px;
    }
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2vh;
  button {
    &:nth-child(2) {
      background-color: #fff;
      color: var(--ps-amarelo-mdio-ffcb-29, #ffcb29);
      border: 1px solid #ffcb29;
      box-shadow: 0px 0px 10px 0px #00000026;
      margin-right: 1.5vw;
      margin-left: auto;
    }
    &:hover {
      background-color: #020c90;
      border: 1px solid #020c90;
      color: white;
    }
  }
`;
