import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; // Fixar para ocupar toda a tela
  top: 0;
  left: 0;
  width: 100vw; // Garantir que ocupa toda a largura
  height: 100vh;
  background: rgba(0, 0, 0, 0.3); // Fundo escurecido para modal
`;

export const Panel = styled.div`
  background-color: #ffffff;
  border-radius: 18px;
  padding: 25px;
  width: 50vw; // Desktop: largura média
  max-width: 500px; // Define um limite para desktop

  @media (max-width: 430px) {
    width: 100%; // Mobile: ocupa 90% da largura da tela
    width: 130%;
    margin: 0 -15%;
  }
`;
