import styled from 'styled-components';

export const ExcursionPublicDetailsArticleContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 20px;
  padding: 0 50px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const ExcursionPublicDetailsArticleContainerId = styled.h2`
  margin-bottom: 20px;
  padding: 10px 20px;
  width: fit-content;
  font-size: 1.5rem;
  font-weight: lighter;
  color: #020c90;
  border-radius: 50px;
  border: 1px solid #020c90;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const ExcursionPublicDetailsArticleContainerInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 30px 0;
  width: 100%;
  border-bottom: 1px dashed #272727;

  &:first-of-type {
    border-top: 1px dashed #272727;
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

export const ExcursionPublicDetailsArticleContainerInformationTitle = styled.h3`
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const ExcursionPublicDetailsArticleContainerInformationList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 0;
  width: 100%;
  list-style: none;
`;

export const ExcursionPublicDetailsArticleContainerInformationListItem = styled.li`
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: lighter;
  list-style: none;

  &.excursion-id {
    margin: 0;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;
