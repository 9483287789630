import React, { useState } from 'react';
import { Box, Popper } from '@mui/material';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { StatusButton, StatusButtonDashedLine } from '../styles';
import upArrowWhite from 'assets/images/upArrowWhite.svg';
import downArrowWhite from 'assets/images/downArrowWhite.svg';
import { ExcursionStatusAPI } from 'interfaces/IExcursion';
import { UserType } from 'interfaces/IUser';

interface ExcursionStatusDropdownProps {
  id: number;
  status: ExcursionStatusAPI;
  userType: UserType | null;
  onAction: (id: number, action: 'approve' | 'refuse', status: ExcursionStatusAPI) => void;
}

const ExcursionStatusDropdown: React.FC<ExcursionStatusDropdownProps> = ({ id, status, userType, onAction }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(isOpen ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Definir a classe CSS de cada status
  const statusClass =
    {
      [ExcursionStatusAPI.APPROVED]: 'approvedBadge',
      [ExcursionStatusAPI.REFUSED]: 'reprovedBadge',
      [ExcursionStatusAPI.PROCESSING]: 'processingBadge',
      [ExcursionStatusAPI.ANNALYSIS]: 'annaliseBadge',
      [ExcursionStatusAPI.PAYMENT_APPROVED]: 'approvedBadge',
      [ExcursionStatusAPI.PAYMENT_DENIED]: 'reprovedBadge',
      [ExcursionStatusAPI.CANCELED]: 'canceledBadge',
      [ExcursionStatusAPI.NOT_DEFINED]: 'canceledBadge',
    }[status] || 'defaultBadge';

  // Apenas exibe dropdown para tour-admin e se o status for "Em Processamento" ou "Isenção em Análise"
  const shouldShowDropdown =
    userType === 'tour-admin' && (status === ExcursionStatusAPI.PROCESSING || status === ExcursionStatusAPI.ANNALYSIS);

  if (!shouldShowDropdown) {
    return <span className={statusClass}>{status}</span>;
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <StatusButton className={statusClass} onClick={handleClick}>
          {status}
          <span className="arrowIcon" style={{ marginLeft: '5px' }}>
            {isOpen ? <img src={upArrowWhite} alt="Fechar" /> : <img src={downArrowWhite} alt="Abrir" />}
          </span>
        </StatusButton>

        <Popper open={isOpen} anchorEl={anchorEl} placement="bottom-start">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              border: 1,
              p: 1,
              borderColor: '#ccc',
              borderRadius: 3,
              bgcolor: 'background.paper',
            }}>
            <StatusButton className="approvedBadge" onClick={() => onAction(id, 'approve', status)}>
              {status === ExcursionStatusAPI.PROCESSING ? 'Pagamento Aprovado' : 'Isenção Aprovada'}
            </StatusButton>
            <StatusButtonDashedLine />
            <StatusButton className="reprovedBadge" onClick={() => onAction(id, 'refuse', status)}>
              {status === ExcursionStatusAPI.PROCESSING ? 'Pagamento Recusado' : 'Isenção Reprovada'}
            </StatusButton>
          </Box>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default ExcursionStatusDropdown;
