import React, { useEffect, useState } from 'react';
import { Container, AccomodationLink } from './styles';
import MainTitle from 'components/MainTitle';
import { Box, Grid } from '@mui/material';
import ActivityCard from 'components/ActivityCard';
import AddBookmark from 'components/AddBookmark';
import PublicService from 'services/PublicService';
import IAccommodation from 'interfaces/IAccommodation';
import { useLoading } from 'hooks/useLoading';

const AccommodationsPage = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [accommodation, setAccommodation] = useState<IAccommodation[]>([]);

  const service = new PublicService();

  useEffect(() => {
    getAccommodation();
  }, []);

  const getAccommodation = async () => {
    try {
      openLoading();
      const data = await service.getAllAccommodation();
      setAccommodation(data);
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  };
  return (
    <>
      <MainTitle>Onde Ficar</MainTitle>
      <Container>
        <Box sx={{ width: '100%' }}>
          <Grid container spacing={3}>
            {accommodation?.map((accommodate, index) => (
              <Grid key={index} xs={12} sm={6} md={4} lg={3} xl={3} item>
                <AccomodationLink>
                  <ActivityCard
                    image={Array.isArray(accommodate.image) ? accommodate.image[0] : accommodate.image}
                    category={accommodate.businessType}
                    title={accommodate.tradeName}
                    description={accommodate.description}
                    link={`/accommodations/${accommodate.id}`}
                    action={<AddBookmark type="business" id={accommodate.id} />}
                  />
                </AccomodationLink>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default AccommodationsPage;
