import styled from 'styled-components';

export const StyledButton = styled.button<{ variant?: 'primary' | 'secondary' }>`
  background-color: ${({ variant = 'primary' }) => (variant === 'secondary' ? '#ffffff' : '#ffcb29')};
  border-radius: 18px;
  width: 118px;
  height: 40px;
  cursor: pointer;
  font-weight: 700;
  align-items: center;
  text-transform: uppercase;
  color: ${({ variant = 'primary' }) => (variant === 'secondary' ? '#ffcb29' : '#ffffff')};
  border: ${({ variant = 'primary' }) => (variant === 'secondary' ? '2px solid #ffcb29' : 'none')};
  box-shadow: 0px 0px 10px 0px #00000026;

  &:hover {
    background-color: ${({ variant = 'primary' }) => (variant === 'secondary' ? '#f1f1f1' : '#020c90')};
    color: ${({ variant = 'primary' }) => (variant === 'secondary' ? '#ffcb29' : '#ffffff')};
  }

  &:disabled {
    background-color: #d9d9d9;
    color: ${({ variant = 'primary' }) => (variant === 'secondary' ? '#a1a1a1' : '#ffffff')};
    border: ${({ variant = 'primary' }) => (variant === 'secondary' ? '2px solid #d9d9d9' : 'none')};
    cursor: not-allowed;
  }
`;
