import React from 'react';
import { StyledButton } from './styles';

interface Props {
  text?: string;
  children?: React.ReactNode;
  type?: 'submit' | 'reset' | 'button';
  onClick?: () => void;
  disabled?: boolean;
  variant?: 'primary' | 'secondary';
}
const Button: React.FC<Props> = (props: Props) => {
  const { children, text, type = 'button', onClick, disabled, variant = 'primary' } = props;
  return (
    <StyledButton type={type} onClick={onClick} disabled={disabled} variant={variant}>
      {text ?? children}
    </StyledButton>
  );
};

export default Button;
