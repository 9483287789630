import React, { useEffect, useState } from 'react';
import {
  ExcursionPublicDetailsArticleContainer,
  ExcursionPublicDetailsArticleContainerId,
  ExcursionPublicDetailsArticleContainerInformation,
  ExcursionPublicDetailsArticleContainerInformationList,
  ExcursionPublicDetailsArticleContainerInformationListItem,
  ExcursionPublicDetailsArticleContainerInformationTitle,
} from './styles';
import { useLoading } from 'hooks/useLoading';
import { IExcursion } from 'interfaces/IExcursion';

interface ExcursionPublicDetailsArticleProps {
  excursionDetails: IExcursion;
}

const ExcursionPublicDetailsArticle: React.FC<ExcursionPublicDetailsArticleProps> = ({ excursionDetails }) => {
  const { openLoading, closeLoading } = useLoading();
  //const [excursionId, setExcursionId] = useState<string>('');
  const [excursion, setExcursion] = useState<any>();

  useEffect(() => {
    openLoading();
    setExcursion(excursionDetails);
    closeLoading();
  }, []);

  console.log('excursionDetails', excursionDetails);
  console.log('excursion', excursion);

  return (
    <ExcursionPublicDetailsArticleContainer>
      <ExcursionPublicDetailsArticleContainerId>
        <ExcursionPublicDetailsArticleContainerInformationListItem className="excursion-id">
          <strong>Código ID</strong>{' '}
          {(() => {
            const baseNumber = parseInt(String(excursionDetails.id)) % 100000;
            const letter = String.fromCharCode(65 + Math.floor(baseNumber / 10000));
            const number = baseNumber.toString().padStart(5, '0');
            return `${letter}${number}`;
          })()}
        </ExcursionPublicDetailsArticleContainerInformationListItem>
      </ExcursionPublicDetailsArticleContainerId>
      <ExcursionPublicDetailsArticleContainerInformation>
        <ExcursionPublicDetailsArticleContainerInformationTitle>
          Dados do responsável pela excursão
        </ExcursionPublicDetailsArticleContainerInformationTitle>
        <ExcursionPublicDetailsArticleContainerInformationList>
          <ExcursionPublicDetailsArticleContainerInformationListItem>
            <strong>Nome completo</strong> {excursionDetails.organizer.name}
          </ExcursionPublicDetailsArticleContainerInformationListItem>
        </ExcursionPublicDetailsArticleContainerInformationList>
      </ExcursionPublicDetailsArticleContainerInformation>
      <ExcursionPublicDetailsArticleContainerInformation>
        <ExcursionPublicDetailsArticleContainerInformationTitle>
          Dados da viagem
        </ExcursionPublicDetailsArticleContainerInformationTitle>
        <ExcursionPublicDetailsArticleContainerInformationList>
          <ExcursionPublicDetailsArticleContainerInformationListItem>
            <strong>Chegada</strong>{' '}
            {new Date(excursionDetails.start_date.split('T')[0] + 'T12:00:00').toLocaleDateString('pt-BR')} -{' '}
            {excursionDetails.hour_start.slice(0, 5).replace(':', 'h')}
          </ExcursionPublicDetailsArticleContainerInformationListItem>
          <ExcursionPublicDetailsArticleContainerInformationListItem>
            <strong>Saída</strong>{' '}
            {new Date(excursionDetails.end_date.split('T')[0] + 'T12:00:00').toLocaleDateString('pt-BR')} -{' '}
            {excursionDetails.hour_end.slice(0, 5).replace(':', 'h')}
          </ExcursionPublicDetailsArticleContainerInformationListItem>
          <ExcursionPublicDetailsArticleContainerInformationListItem>
            <strong>Acesso ao centro</strong> {excursionDetails.central_access ? 'Sim' : 'Não'}
          </ExcursionPublicDetailsArticleContainerInformationListItem>
        </ExcursionPublicDetailsArticleContainerInformationList>
      </ExcursionPublicDetailsArticleContainerInformation>
      <ExcursionPublicDetailsArticleContainerInformation>
        <ExcursionPublicDetailsArticleContainerInformationTitle>
          Dados do transporte
        </ExcursionPublicDetailsArticleContainerInformationTitle>
        <ExcursionPublicDetailsArticleContainerInformationList>
          <ExcursionPublicDetailsArticleContainerInformationListItem>
            <strong>Tipo de Veículo</strong> {excursionDetails.transport.vehicle_type}
          </ExcursionPublicDetailsArticleContainerInformationListItem>
          <ExcursionPublicDetailsArticleContainerInformationListItem>
            <strong>Placa</strong> {excursionDetails.transport.vehicle_plate.replace(/([A-Z]{3})(\d{4})/, '$1-$2')}
          </ExcursionPublicDetailsArticleContainerInformationListItem>
          <ExcursionPublicDetailsArticleContainerInformationListItem>
            <strong>Passageiro PCD</strong> {excursionDetails.transport.has_pcd ? 'Sim' : 'Não'}
          </ExcursionPublicDetailsArticleContainerInformationListItem>
        </ExcursionPublicDetailsArticleContainerInformationList>
      </ExcursionPublicDetailsArticleContainerInformation>
      <ExcursionPublicDetailsArticleContainerInformation>
        <ExcursionPublicDetailsArticleContainerInformationTitle>
          Dados do meio de hospedagem
        </ExcursionPublicDetailsArticleContainerInformationTitle>
        <ExcursionPublicDetailsArticleContainerInformationList>
          <ExcursionPublicDetailsArticleContainerInformationListItem>
            <strong>Tipo de hospedagem</strong>{' '}
            {excursionDetails.accommodation.accommodation_type === 1 && 'Não irei me hospedar em Porto Seguro'}
            {excursionDetails.accommodation.accommodation_type === 2 &&
              'Estabelecimento em Porto Seguro com CNPJ, alvará e Cadastur e contribuinte do ISS municipal'}
            {excursionDetails.accommodation.accommodation_type === 3 &&
              'Imóvel de locação em Porto Seguro e contribuinte do ISS municipal'}
            {excursionDetails.accommodation.accommodation_type === 4 &&
              'Imóvel de locação em Porto Seguro sem CNPJ, alvará e Cadastur e não contribuinte do ISS municipal'}
          </ExcursionPublicDetailsArticleContainerInformationListItem>
          {excursionDetails.accommodation.accommodation_type === 1 && (
            <ExcursionPublicDetailsArticleContainerInformationListItem>
              <strong>Justificativa</strong> {excursionDetails.accommodation.justification}
            </ExcursionPublicDetailsArticleContainerInformationListItem>
          )}
          {excursionDetails.accommodation.accommodation_type === 2 && (
            <>
              <ExcursionPublicDetailsArticleContainerInformationListItem>
                <strong>Cadastur</strong> {excursionDetails.accommodation.cadastur}
              </ExcursionPublicDetailsArticleContainerInformationListItem>
              <ExcursionPublicDetailsArticleContainerInformationListItem>
                <strong>Número do alvará</strong> {excursionDetails.accommodation.license_number}
              </ExcursionPublicDetailsArticleContainerInformationListItem>
              <ExcursionPublicDetailsArticleContainerInformationListItem>
                <strong>ISS municipal</strong> {excursionDetails.accommodation.iss}
              </ExcursionPublicDetailsArticleContainerInformationListItem>
              <ExcursionPublicDetailsArticleContainerInformationListItem>
                <strong>CNPJ</strong>{' '}
                {excursionDetails.accommodation.document_number.replace(
                  /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                  '$1.$2.$3/$4-$5'
                )}
              </ExcursionPublicDetailsArticleContainerInformationListItem>
              <ExcursionPublicDetailsArticleContainerInformationListItem>
                <strong>Nome fantasia</strong> {excursionDetails.accommodation.name}
              </ExcursionPublicDetailsArticleContainerInformationListItem>
              {/* <ExcursionPublicDetailsArticleContainerInformationListItem>
                <strong>Razão social</strong> {excursionDetails.accommodation.name}
              </ExcursionPublicDetailsArticleContainerInformationListItem> */}
              <ExcursionPublicDetailsArticleContainerInformationListItem>
                <strong>Telefone ou celular</strong> {excursionDetails.accommodation.phone}
              </ExcursionPublicDetailsArticleContainerInformationListItem>
              <ExcursionPublicDetailsArticleContainerInformationListItem>
                <strong>E-mail</strong> {excursionDetails.accommodation.email}
              </ExcursionPublicDetailsArticleContainerInformationListItem>
              <ExcursionPublicDetailsArticleContainerInformationListItem>
                <strong>Endereço</strong> {excursionDetails.accommodation.address},{' '}
                {excursionDetails.accommodation.number}
                {excursionDetails.accommodation.complement}, {excursionDetails.accommodation.neighborhood},{' '}
                {excursionDetails.accommodation.cep}, {excursionDetails.accommodation.city} -{' '}
                {excursionDetails.accommodation.state}
              </ExcursionPublicDetailsArticleContainerInformationListItem>
            </>
          )}
          {excursionDetails.accommodation.accommodation_type === 3 && (
            <>
              <ExcursionPublicDetailsArticleContainerInformationListItem>
                <strong>ISS municipal</strong> {excursionDetails.accommodation.iss}
              </ExcursionPublicDetailsArticleContainerInformationListItem>
              {excursionDetails.accommodation.document_type === 'CNPJ' && (
                <>
                  <ExcursionPublicDetailsArticleContainerInformationListItem>
                    <strong>CNPJ</strong>{' '}
                    {excursionDetails.accommodation.document_number.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                      '$1.$2.$3/$4-$5'
                    )}
                  </ExcursionPublicDetailsArticleContainerInformationListItem>
                  <ExcursionPublicDetailsArticleContainerInformationListItem>
                    <strong>Nome fantasia</strong> {excursionDetails.accommodation.name}
                  </ExcursionPublicDetailsArticleContainerInformationListItem>
                </>
              )}

              {excursionDetails.accommodation.document_type === 'CPF' && (
                <>
                  <ExcursionPublicDetailsArticleContainerInformationListItem>
                    <strong>CPF do proprietário</strong>{' '}
                    {excursionDetails.accommodation.document_number.replace(
                      /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                      '$1.$2.$3-$4'
                    )}
                  </ExcursionPublicDetailsArticleContainerInformationListItem>
                  <ExcursionPublicDetailsArticleContainerInformationListItem>
                    <strong>Nome completo do proprietário</strong> {excursionDetails.accommodation.name}
                  </ExcursionPublicDetailsArticleContainerInformationListItem>
                </>
              )}

              {/* <ExcursionPublicDetailsArticleContainerInformationListItem>
                <strong>Razão social</strong> {excursionDetails.accommodation.name}
              </ExcursionPublicDetailsArticleContainerInformationListItem> */}
              <ExcursionPublicDetailsArticleContainerInformationListItem>
                <strong>Telefone ou celular</strong> {excursionDetails.accommodation.phone}
              </ExcursionPublicDetailsArticleContainerInformationListItem>
              <ExcursionPublicDetailsArticleContainerInformationListItem>
                <strong>E-mail</strong> {excursionDetails.accommodation.email}
              </ExcursionPublicDetailsArticleContainerInformationListItem>
              <ExcursionPublicDetailsArticleContainerInformationListItem>
                <strong>Endereço</strong> {excursionDetails.accommodation.address},{' '}
                {excursionDetails.accommodation.number}
                {excursionDetails.accommodation.complement}, {excursionDetails.accommodation.neighborhood},{' '}
                {excursionDetails.accommodation.cep}, {excursionDetails.accommodation.city} -{' '}
                {excursionDetails.accommodation.state}
              </ExcursionPublicDetailsArticleContainerInformationListItem>
            </>
          )}

          {excursionDetails.accommodation.accommodation_type === 4 && (
            <>
              <ExcursionPublicDetailsArticleContainerInformationListItem>
                <strong>CPF do proprietário</strong>{' '}
                {excursionDetails.accommodation.document_number.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')}
              </ExcursionPublicDetailsArticleContainerInformationListItem>
              <ExcursionPublicDetailsArticleContainerInformationListItem>
                <strong>Nome completo do proprietário</strong> {excursionDetails.accommodation.name}
              </ExcursionPublicDetailsArticleContainerInformationListItem>
              <ExcursionPublicDetailsArticleContainerInformationListItem>
                <strong>Telefone ou celular</strong> {excursionDetails.accommodation.phone}
              </ExcursionPublicDetailsArticleContainerInformationListItem>
              <ExcursionPublicDetailsArticleContainerInformationListItem>
                <strong>E-mail</strong> {excursionDetails.accommodation.email}
              </ExcursionPublicDetailsArticleContainerInformationListItem>
              <ExcursionPublicDetailsArticleContainerInformationListItem>
                <strong>Endereço</strong> {excursionDetails.accommodation.address},{' '}
                {excursionDetails.accommodation.number}
                {excursionDetails.accommodation.complement}, {excursionDetails.accommodation.neighborhood},{' '}
                {excursionDetails.accommodation.cep}, {excursionDetails.accommodation.city} -{' '}
                {excursionDetails.accommodation.state}
              </ExcursionPublicDetailsArticleContainerInformationListItem>
            </>
          )}
        </ExcursionPublicDetailsArticleContainerInformationList>
      </ExcursionPublicDetailsArticleContainerInformation>
    </ExcursionPublicDetailsArticleContainer>
  );
};

export default ExcursionPublicDetailsArticle;
