import { ExcursionOrganizatorForm } from 'interfaces/IExcursionOrganizerForm';
import { IExcursion } from 'interfaces/IExcursion';
import RestService from './RestService';

export default class ExcursionsService extends RestService<ExcursionOrganizatorForm> {
  constructor() {
    super('excursions');
  }

  public async postExcursion(excursion: ExcursionOrganizatorForm): Promise<ExcursionOrganizatorForm> {
    const response = await this.httpClient.post('', excursion);
    return response.data;
  }

  public async getExcursions(): Promise<IExcursion[]> {
    const response = await this.httpClient.get('');
    return response.data;
  }

  public async getExcursion(id: string): Promise<ExcursionOrganizatorForm> {
    const response = await this.httpClient.get(`/${id}`);
    return response.data;
  }

  public async putExcursion(id: string, excursion: ExcursionOrganizatorForm): Promise<ExcursionOrganizatorForm> {
    const response = await this.httpClient.put(`/${id}`, excursion);
    return response.data;
  }

  public async approveExcursion(id: string): Promise<ExcursionOrganizatorForm> {
    const response = await this.httpClient.put(`/${id}/approve`);
    return response.data;
  }

  public async refuseExcursion(id: string): Promise<ExcursionOrganizatorForm> {
    const response = await this.httpClient.put(`/${id}/refuse`);
    return response.data;
  }

  public async cancelExcursion(id: string): Promise<ExcursionOrganizatorForm> {
    const response = await this.httpClient.put(`/${id}/cancel`);
    return response.data;
  }
}
