import React, { useEffect, useState } from 'react';
import { Container, ContainerTitle, PDFButton, PDFButtonContainer } from './styles';
import StampContainer from './components/StampContainer';
import StampContainerList from './components/StampContainerList';
import StampPcdInformation from './components/StampPcdInformation';
import StampSacInformation from './components/StampSacInformation';
import { useLoading } from 'hooks/useLoading';
import ExcursionsService from 'services/ExcursionsService';
//import { v4 as uuidv4 } from 'uuid';
import StampQrCodeContainer from './components/StampQrCodeContainer';
import ExcursionPublicDetails404 from 'features/ExcursionPublicDetailsPage/components/ExcursionPublicDetails404';

const ExcursionStampPage: React.FC = () => {
  const { openLoading, closeLoading } = useLoading();
  const [excursion, setExcursion] = useState<any>();
  const [excursionId, setExcursionId] = useState<string>('');
  const [excursionStampId, setExcursionStampId] = useState<string>('');
  const [page404, setPage404] = useState<boolean>(false);
  const [canceled, setCanceled] = useState<boolean>(false);
  const originURL = window.location.origin;
  //const [accessNumber, setAccessNumber] = useState<string>('');

  // useEffect(() => {
  //   const uuid = uuidv4();
  //   const formattedAccessNumber = uuid;
  //   setAccessNumber(formattedAccessNumber);
  // }, []);

  // const replaceIdUrlToAccessNumber = () => {
  //   window.history.replaceState({}, '', `/excursion/excursion-stamp/${accessNumber}`);
  // };

  const fetchExcursion = async () => {
    const excursionIdFromUrl = window.location.pathname.split('/').pop();
    if (!excursionIdFromUrl) return;
    try {
      openLoading();
      const response = await new ExcursionsService().getExcursion(excursionIdFromUrl);
      const data = response;
      if (data && data.status === 'Cancelada') {
        setExcursion(null);
        setCanceled(true);
      } else {
        setExcursion(data);
        setExcursionId(excursionIdFromUrl);
        setExcursionStampId(data.id_consulta);
        console.log(data);
      }
    } catch (error) {
      console.error(error);
      setPage404(true);
    } finally {
      closeLoading();
    }
  };

  const preparingToPDF = () => {
    openLoading();
    import('html2canvas')
      .then(({ default: html2canvas }) => {
        import('jspdf')
          .then(({ jsPDF }) => {
            const element = document.querySelector('.stamp-container') as HTMLElement;
            if (!element) {
              closeLoading();
              return;
            }

            html2canvas(element)
              .then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                  orientation: 'portrait',
                  unit: 'mm',
                  format: 'a4',
                });
                const pageWidth = 210;
                const pageHeight = 297;
                const scale = (pageWidth / canvas.width) * 1.4;
                const scaledHeight = canvas.height * scale;
                const yPosition = (pageHeight - scaledHeight) / 2;
                const xPosition = (pageWidth - canvas.width * scale) / 2;
                pdf.addImage(imgData, 'PNG', xPosition, yPosition, canvas.width * scale, scaledHeight);
                pdf.save('selo-de-acesso-a-porto-seguro.pdf');
                closeLoading();
              })
              .catch(() => {
                closeLoading();
              });
          })
          .catch(() => {
            closeLoading();
          });
      })
      .catch(() => {
        closeLoading();
      });
  };

  useEffect(() => {
    fetchExcursion();
    //replaceIdUrlToAccessNumber();
    preparingToPDF();
  }, [excursionId]);

  return (
    <>
      {excursion && (
        <>
          <PDFButtonContainer>
            <PDFButton buttonType="primary" onClick={preparingToPDF}>
              Download em PDF
            </PDFButton>{' '}
          </PDFButtonContainer>
          <Container className="stamp-container">
            <ContainerTitle>Selo de Acesso a Porto Seguro</ContainerTitle>
            <StampContainer
              hasPCD={excursion?.transport?.has_pcd}
              centralAccess={excursion?.central_access ? 'Sim' : 'Não'}
              AccessNumber={(() => {
                const baseNumber = parseInt(excursionId.replace(/-/g, '').substring(0, 5), 10);
                const letter = String.fromCharCode(65 + Math.floor(baseNumber / 100000));
                const number = (baseNumber % 100000).toString().padStart(5, '0');
                return `${letter}${number}`;
              })()}
            />
            <StampContainerList
              information={[
                {
                  name: excursion?.organizer?.name,
                  vehicle: excursion?.transport?.vehicle_type,
                  plate: excursion?.transport?.vehicle_plate
                    ? `${excursion.transport.vehicle_plate.slice(0, 3)}-${excursion.transport.vehicle_plate.slice(3)}`
                    : '',
                  period: excursion?.start_date
                    ? `${new Date(excursion.start_date.split('T')[0] + 'T12:00:00').toLocaleDateString(
                        'pt-BR'
                      )} - ${new Date(excursion.end_date.split('T')[0] + 'T12:00:00').toLocaleDateString('pt-BR')}`
                    : '',
                },
              ]}
            />
            <StampPcdInformation visible={excursion?.transport?.has_pcd} />
            <StampQrCodeContainer value={`${originURL}/public/excursion-details/${excursionStampId}`} />
            <StampSacInformation visible={true} />
          </Container>
        </>
      )}

      {page404 && (
        <ExcursionPublicDetails404
          title="Excursão não encontrada"
          message="A excursão que você está tentando acessar não foi encontrada."
        />
      )}

      {canceled && (
        <ExcursionPublicDetails404
          title="Excursão cancelada"
          message="O selo é inválido devido ao cancelamento da excursão"
        />
      )}
    </>
  );
};

export default ExcursionStampPage;
