import React, { useEffect, useRef, useState } from 'react';
import yup from 'utils/validation';
import { ButtonsDiv, Container, DeleteAccountButton, Divider, FormRow, LabelData } from './styles';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import Button from 'components/Buttom/Button';
import ToggleField from 'components/ToggleField';
import ProfileImage from 'components/ProfileImage';
import ChangePasswordButton from 'features/Account/components/ChangePasswordButton';
import { validDDDs } from 'utils/basicUtils';
import { useLoading } from 'hooks/useLoading';
import Loading from 'components/Loading';
import { usePortalContext } from 'common/PortalContext';

interface Props {
  setExcludeModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  saveUserData?: (userData: Record<string, any>) => Promise<void>;
  userData: Record<string, any>;
}

const TourOrganizerAccount = (props: Props): JSX.Element => {
  useLoading();
  const form = useRef<FormikProps<FormikValues>>(null);
  const [editing, setEditing] = useState(false);
  const [companyDataVisibility, setCompanyDataVisibility] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const { setExcludeModal } = props || {};
  const PortalContext = usePortalContext();

  const validationPJSchema = yup.object({
    document: yup.string().required(),
    email: yup
      .string()
      .email()
      .matches(/^(?!\d+$).*/, 'O campo email não pode ser apenas números')
      .required(),
    extraInfo: yup.object().shape({
      legalName: yup.string().required(),
      responsibleCpf: yup.string().required(),
      responsibleName: yup.string().required(),
      tradeName: yup.string().required(),
      phone: yup
        .string()
        .required('Telefone é obrigatório')
        .test('is-valid-ddd', 'DDD inválido', function (value) {
          if (!value) return false;
          const ddd = value.match(/^\((\d{2})\)/);
          if (!ddd) return false;
          return validDDDs.includes(ddd[1]);
        })
        .test('not-all-same', 'Telefone não pode ter todos os números iguais', function (value) {
          if (!value) return false;
          const digits = value.replace(/\D/g, '');
          return !/^(\d)\1+$/.test(digits);
        }),
      address: yup.object().shape({
        additionalData: yup.string(),
        city: yup.string().required(),
        neighborhood: yup
          .string()
          .matches(/^(?!^\d+$)[\d\D]*$/, 'O bairro não pode conter somente números')
          .required(),
        number: yup.number().required(),
        state: yup.string().required(),
        street: yup
          .string()
          .matches(/^(?!\d+$).*/, 'O campo endereço não pode ser apenas números')
          .required(),
        zipCode: yup.string().length(9).required(),
      }),
    }),
  });

  const validationPFSchema = yup.object({
    name: yup.string().required(),
    document: yup.string().required(),
    email: yup
      .string()
      .email()
      .matches(/^(?!\d+$).*/, 'O campo email não pode ser apenas números')
      .required(),
    extraInfo: yup.object().shape({
      phone: yup
        .string()
        .required('Telefone é obrigatório')
        .test('is-valid-ddd', 'DDD inválido', function (value) {
          if (!value) return false;
          const ddd = value.match(/^\((\d{2})\)/);
          if (!ddd) return false;
          return validDDDs.includes(ddd[1]);
        })
        .test('not-all-same', 'Telefone não pode ter todos os números iguais', function (value) {
          if (!value) return false;
          const digits = value.replace(/\D/g, '');
          return !/^(\d)\1+$/.test(digits);
        }),
      address: yup.object().shape({
        additionalData: yup.string(),
        city: yup.string().required(),
        neighborhood: yup
          .string()
          .matches(/^(?!^\d+$)[\d\D]*$/, 'O bairro não pode conter somente números')
          .required(),
        number: yup.number().required(),
        state: yup.string().required(),
        street: yup
          .string()
          .matches(/^(?!\d+$).*/, 'O campo endereço não pode ser apenas números')
          .required(),
        zipCode: yup.string().length(9).required(),
      }),
    }),
  });

  const validationAdminSchema = yup.object({
    name: yup.string().required(),
    document: yup.string().required(),
    email: yup
      .string()
      .email()
      .matches(/^(?!\d+$).*/, 'O campo email não pode ser apenas números')
      .required(),
    extraInfo: yup.object().shape({
      registration: yup.string(),
    }),
  });

  const handleCancelButtonClick = () => {
    form?.current?.setValues(props.userData);
    setEditing(false);
  };

  const handleSaveButtonClick = async () => {
    const newData = JSON.parse(localStorage.getItem('userData') || '{}');
    newData.name = form?.current?.values.name;
    localStorage.setItem('userData', JSON.stringify(newData));
    PortalContext.setPortalUser(form?.current?.values.name);

    await form?.current?.submitForm();

    if (!form?.current?.isValid) {
      return;
    }

    setEditing(false);
  };

  useEffect(() => {
    switch (props.userData?.extraInfo?.role) {
      case 'tour-pj':
        try {
          setIsLoading(true);
          setCompanyDataVisibility('tour-pj');
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
        break;
      case 'tour-pf':
        try {
          setIsLoading(true);
          setCompanyDataVisibility('tour-pf');
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
        break;
      case 'tour-admin':
        try {
          setIsLoading(true);
          setCompanyDataVisibility('tour-admin');
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
        break;
      case 'Gestor de Excursão':
        try {
          setIsLoading(true);
          setCompanyDataVisibility('tour-admin');
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
        }
        break;
      default:
        null;
        break;
    }
    setIsLoading(false);
  }, [props.userData]);

  const verifyName = companyDataVisibility === 'tour-pj' ? 'extraInfo.responsibleName' : 'name';
  const verifyCpf = companyDataVisibility === 'tour-pj' ? 'extraInfo.responsibleCpf' : 'document';

  const verifyValidationSchema = () => {
    if (props.userData?.extraInfo?.role === 'tour-pj') {
      return validationPJSchema;
    } else if (props.userData?.extraInfo?.role === 'tour-pf') {
      return validationPFSchema;
    } else {
      return validationAdminSchema;
    }
  };

  const handleSubmit = async (values: Record<string, any>) => {
    if (props.saveUserData) {
      await props.saveUserData(values);
      PortalContext.setPortalUser(values.name);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  const handlePositionName = () => {
    switch (props.userData?.extraInfo?.role) {
      case 'tour-pj':
        return 'Organizador de excursão';
      case 'tour-pf':
        return 'Organizador de excursão';
      case 'tour-admin':
        return 'Secretaria do Turismo (SETUR)';
      default:
        return null;
    }
  };

  return (
    <>
      {isLoading ? <Loading /> : null}
      <Container>
        <Formik
          innerRef={form}
          initialValues={
            companyDataVisibility === 'tour-admin'
              ? { ...props.userData, extraInfo: { ...props.userData.extraInfo, role: 'Excursões' } }
              : { ...props.userData }
          }
          validationSchema={verifyValidationSchema()}
          onSubmit={handleSubmit}
          enableReinitialize>
          <Form>
            <FormRow id="only-view-first-row">
              <section>
                <article className="profile-image">
                  <ProfileImage name="profileImage" />
                </article>

                <article className="profile-info">
                  {editing && <label>Nome Completo</label>}
                  <ToggleField showField={editing} name={verifyName} />
                  {!editing && <p>{handlePositionName()}</p>}
                </article>
              </section>
              {!editing && <Button text={'EDITAR'} onClick={() => setEditing(true)} />}
            </FormRow>
            <>
              {companyDataVisibility === 'tour-pj' && (
                <>
                  <FormRow id="only-view-second-row">
                    <LabelData>
                      <label>CNPJ</label>
                      <ToggleField showField={editing} name="document" mask="cnpj" />
                    </LabelData>
                    <LabelData>
                      <label>Nome Fantasia</label>
                      <ToggleField showField={editing} name="extraInfo.tradeName" />
                    </LabelData>
                    <LabelData>
                      <label>Razão Social</label>
                      <ToggleField showField={editing} name="extraInfo.legalName" />
                    </LabelData>
                    <LabelData />
                  </FormRow>
                  <FormRow id="only-view-fourth-row">
                    <LabelData>
                      <label>CPF</label>
                      <ToggleField disabled={true} showField={editing} name={verifyCpf} />
                    </LabelData>
                    <LabelData>
                      <label>Telefone ou Celular</label>
                      <ToggleField showField={editing} name="extraInfo.phone" mask="phone" />
                    </LabelData>
                    <LabelData>
                      <label>E-mail</label>
                      <ToggleField showField={editing} name="email" />
                    </LabelData>
                  </FormRow>
                  <FormRow id="only-view-fourth-row">
                    <LabelData>
                      <label>CEP</label>
                      <ToggleField showField={editing} name="extraInfo.address.zipCode" mask="zipcode" />
                    </LabelData>
                    <LabelData>
                      <label>Endereço</label>
                      <ToggleField showField={editing} name="extraInfo.address.street" />
                    </LabelData>
                    <LabelData>
                      <label>Número</label>
                      <ToggleField showField={editing} name="extraInfo.address.number" />
                    </LabelData>
                    <LabelData>
                      <label>Complemento</label>
                      <ToggleField showField={editing} name="extraInfo.address.additionalData" />
                    </LabelData>
                  </FormRow>
                  <FormRow id="only-view-fourth-row">
                    <LabelData>
                      <label>Bairro</label>
                      <ToggleField showField={editing} name="extraInfo.address.neighborhood" />
                    </LabelData>
                    <LabelData>
                      <label>Cidade</label>
                      <ToggleField showField={editing} name="extraInfo.address.city" />
                    </LabelData>
                    <LabelData>
                      <label>Estado</label>
                      <ToggleField showField={editing} name="extraInfo.address.state" />
                    </LabelData>
                  </FormRow>
                </>
              )}
              {companyDataVisibility === 'tour-pf' && (
                <>
                  <FormRow id="only-view-fourth-row">
                    <LabelData>
                      <label>CPF</label>
                      <ToggleField disabled={true} showField={editing} name={verifyCpf} />
                    </LabelData>
                    <LabelData>
                      <label>Telefone ou Celular</label>
                      <ToggleField showField={editing} name="extraInfo.phone" mask="phone" />
                    </LabelData>
                    <LabelData>
                      <label>E-mail</label>
                      <ToggleField showField={editing} name="email" />
                    </LabelData>
                  </FormRow>
                  <FormRow id="only-view-fourth-row">
                    <LabelData>
                      <label>CEP</label>
                      <ToggleField showField={editing} name="extraInfo.address.zipCode" mask="zipcode" />
                    </LabelData>
                    <LabelData>
                      <label>Endereço</label>
                      <ToggleField showField={editing} name="extraInfo.address.street" />
                    </LabelData>
                    <LabelData>
                      <label>Número</label>
                      <ToggleField showField={editing} name="extraInfo.address.number" />
                    </LabelData>
                    <LabelData>
                      <label>Complemento</label>
                      <ToggleField showField={editing} name="extraInfo.address.additionalData" />
                    </LabelData>
                  </FormRow>
                  <FormRow id="only-view-fourth-row">
                    <LabelData>
                      <label>Bairro</label>
                      <ToggleField showField={editing} name="extraInfo.address.neighborhood" />
                    </LabelData>
                    <LabelData>
                      <label>Cidade</label>
                      <ToggleField showField={editing} name="extraInfo.address.city" />
                    </LabelData>
                    <LabelData>
                      <label>Estado</label>
                      <ToggleField showField={editing} name="extraInfo.address.state" />
                    </LabelData>
                  </FormRow>
                </>
              )}
              {companyDataVisibility === 'tour-admin' && (
                <>
                  <FormRow id="only-view-two-columns">
                    <LabelData>
                      <label>CPF</label>
                      <ToggleField disabled={true} showField={editing} name={verifyCpf} />
                    </LabelData>
                    <LabelData>
                      <label>Matrícula</label>
                      <ToggleField disabled showField={editing} name="extraInfo.registration" />
                    </LabelData>
                  </FormRow>
                  <FormRow id="only-view-two-columns">
                    <LabelData>
                      <label>Setor</label>
                      <ToggleField disabled showField={editing} name="extraInfo.role" />
                    </LabelData>
                    <LabelData>
                      <label>E-mail</label>
                      <ToggleField showField={editing} name="email" />
                    </LabelData>
                  </FormRow>
                </>
              )}
            </>
          </Form>
        </Formik>
        <Divider />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ButtonsDiv>
            <DeleteAccountButton onClick={() => setExcludeModal && setExcludeModal(true)}>
              <img src="" alt="" />
              <span>Solicitar exclusão de conta</span>
            </DeleteAccountButton>
            <ChangePasswordButton />
          </ButtonsDiv>
          {editing && (
            <div style={{ display: 'flex', gap: '10px' }}>
              <Button text={'Cancelar'} variant="secondary" onClick={handleCancelButtonClick} />
              <Button text={'Salvar'} type="submit" onClick={handleSaveButtonClick} />
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default TourOrganizerAccount;
