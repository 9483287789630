import React, { useEffect, useState } from 'react';
import { useLoading } from 'hooks/useLoading';
import { useParams } from 'react-router';
import UserService from 'services/UserService';
import { IUserAPI } from 'interfaces/IUser';
import NewsAccount from 'features/Account/pages/NewsAccount';
import TourOrganizerAccount from 'features/Account/pages/TourOrganizerAccount';
import TouristAccount from 'features/Account/pages/TouristAccount';
import AdminAccount from 'features/Account/pages/AdminAccount';
import SETURAccount from 'features/Account/pages/SETURAccount';
import TradeAccount from 'features/Account/pages/TradeAccount';
import { Container } from './styles';

const UserDetail = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { openLoading, closeLoading } = useLoading();
  const [user, setUser] = useState<IUserAPI | undefined>();

  const service = new UserService();

  useEffect(() => {
    openLoading();
    fetchDestination().finally(() => closeLoading());
  }, []);

  const fetchDestination = async () => {
    const userId = Number(id);

    if (Number.isNaN(userId)) {
      return;
    }

    const data = await service.findOneById(userId);
    setUser(data);
  };

  return (
    <Container>
      {user?.type.startsWith('news') && <NewsAccount userData={user} />}
      {user?.type === 'tour' && <TourOrganizerAccount userData={user} />}
      {user?.type === 'tour-pf' && <TourOrganizerAccount userData={user} />}
      {user?.type === 'tour-pj' && <TourOrganizerAccount userData={user} />}
      {user?.type === 'tour-admin' && <TourOrganizerAccount userData={user} />}
      {user?.type === 'tourist' && <TouristAccount userData={user} />}
      {user?.type === 'admin' && <AdminAccount userData={user} />}
      {user?.type.startsWith('setur') && <SETURAccount userData={user} />}
      {user?.type === 'trade' && <TradeAccount userData={user} />}
    </Container>
  );
};

export default UserDetail;
