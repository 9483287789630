import { AxiosInstance } from 'axios';
import getHTTPClient from './httpClient';
import IHomeData from 'interfaces/IHomeData';
import { IDestinationSlider } from 'interfaces/IDestinationsBannerts';
import IItinerary from 'interfaces/IItinerary';
import INews from 'interfaces/INews';
import IActivity from 'interfaces/IActivity';
import IEvent from 'interfaces/IEvent';
import IAccommodation from 'interfaces/IAccommodation';
import { IBusiness } from 'interfaces/IBusinesses';
import IService from 'interfaces/IService';
import { ExcursionOrganizatorForm } from 'interfaces/IExcursionOrganizerForm';

export default class PublicService {
  private instance: AxiosInstance;

  constructor() {
    this.instance = getHTTPClient();
  }

  public async getHomeData(): Promise<IHomeData> {
    const { data } = await this.instance.get<IHomeData>('/public');
    return data;
  }

  public async getDestinations(): Promise<IDestinationSlider[]> {
    const { data } = await this.instance.get<IDestinationSlider[]>('/public/destinations');
    return data;
  }

  public async getItineraries(): Promise<IItinerary[]> {
    const { data } = await this.instance.get<IItinerary[]>('/public/itineraries');
    return data;
  }

  public async getItinerary(slug: string): Promise<IItinerary> {
    const { data } = await this.instance.get<IItinerary>(`/public/itineraries/${slug}`);
    return data;
  }

  public async getAllNews(): Promise<INews[]> {
    const { data } = await this.instance.get<INews[]>('/public/news');
    return data;
  }

  public async getNews(slug: string): Promise<INews> {
    const { data } = await this.instance.get<INews>(`/public/news/${slug}`);
    return data;
  }

  public async getAllActivities(): Promise<IBusiness[]> {
    const { data } = await this.instance.get<IBusiness[]>('/public/businesses/activities');
    return data;
  }

  public async getAllTransports(): Promise<IBusiness[]> {
    const { data } = await this.instance.get<IBusiness[]>('/public/businesses/transports');
    return data;
  }

  public async getActivities(id: number): Promise<IBusiness> {
    const { data } = await this.instance.get<IBusiness>(`/public/businesses/${id}`);
    return data;
  }

  public async getAllEvents(): Promise<IEvent[]> {
    const { data } = await this.instance.get<IEvent[]>('/public/events');
    return data;
  }

  public async getEvents(slug: string): Promise<IEvent> {
    const { data } = await this.instance.get<IEvent>(`/public/events/${slug}`);
    return data;
  }

  public async getAllAccommodation(): Promise<IAccommodation[]> {
    const { data } = await this.instance.get<IAccommodation[]>('/public/businesses');
    return data;
  }

  public async getAccommodation(id: string): Promise<IAccommodation> {
    const { data } = await this.instance.get<IAccommodation>(`/public/businesses/${id}`);
    return data;
  }

  public async getAllBasicService(): Promise<IService[]> {
    const { data } = await this.instance.get<IService[]>('/public/services');
    return data;
  }

  public async getPublicExcursionStamp(id_selo: string): Promise<ExcursionOrganizatorForm> {
    const response = await this.instance.get(`/public/excursions/selo/${id_selo}`);
    return response.data;
  }

  public async getPublicExcursion(id_consulta: string): Promise<ExcursionOrganizatorForm> {
    const response = await this.instance.get(`/public/excursions/consulta/${id_consulta}`);
    return response.data;
  }
}
