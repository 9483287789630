import styled from 'styled-components';
import Button from 'components/Button';

export const PDFButtonContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  width: 100%;
`;

export const PDFButton = styled(Button)`
  display: flex;
  align-items: center;
  font-size: 25px;
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  width: 100%;
  min-height: 60vh;

  @media (max-width: 768px) {
    padding: 30px;
  }
`;

export const ContainerTitle = styled.h1`
  margin-bottom: 20px;
  font-size: 55px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 35px;
    text-align: center;
  }
`;
