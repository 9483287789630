import styled from 'styled-components';

export const StampQrCodeDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: auto;
  margin: 0 auto;
  max-width: 64px;
  width: 100%;
`;
