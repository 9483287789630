import React, { useEffect, useState } from 'react';
import {
  MidiaKitContainer,
  MidiaKitHeader,
  MidiaKitTitle,
  InputLabel,
  Input,
  MidiaKitActionButtons,
  MidiaKitCancelButton,
  MidiaKitSaveButton,
} from './styles';
import MediaKitService from 'services/MediaKitService';
import { useLoading } from 'hooks/useLoading';
import IMediaKit from 'interfaces/IMediaKit';
import { usePortalContext } from 'common/PortalContext';

const Kit = (): JSX.Element => {
  const [showEditInput, setShowEditInput] = useState(false);
  const [midiakit, setMidiakit] = useState<IMediaKit | undefined>();
  const [link, setLink] = useState('');
  const { openLoading, closeLoading } = useLoading();
  const PortalContext = usePortalContext();

  useEffect(() => {
    openLoading();
    const fetchData = [fetchMidiaKit()];
    Promise.all(fetchData).finally(() => closeLoading());
  }, []);

  const service = {
    midiakit: new MediaKitService(),
  };

  const fetchMidiaKit = async () => {
    const data = await service.midiakit.findAll();
    data.sort((a, b) => b.id - a.id);
    setMidiakit(data[0]);
    setLink(data[0]?.description || '');
  };

  const cancelSubmitLink = () => {
    setShowEditInput(false);
  };

  const submitLink = async (link: string) => {
    openLoading();
    if (midiakit?.id) {
      await service.midiakit.update(midiakit.id, { description: link });
      PortalContext.setMidiaKitURL?.(link);
    } else {
      await service.midiakit.create({ description: link });
      PortalContext.setMidiaKitURL?.(link);
      await fetchMidiaKit();
    }
    setShowEditInput(false);
    closeLoading();
  };

  return (
    <MidiaKitContainer>
      <MidiaKitHeader>
        <MidiaKitTitle>Link de Acesso</MidiaKitTitle>
        {showEditInput ? (
          <MidiaKitActionButtons>
            <MidiaKitCancelButton onClick={cancelSubmitLink}>Cancelar</MidiaKitCancelButton>
            <MidiaKitSaveButton type="submit" onClick={() => submitLink(link)} disabled={!link}>
              Salvar
            </MidiaKitSaveButton>
          </MidiaKitActionButtons>
        ) : (
          <InputLabel htmlFor="link" onClick={() => setShowEditInput(true)}>
            Editar
          </InputLabel>
        )}
      </MidiaKitHeader>
      <Input
        className="link-input"
        id="link"
        type="url"
        placeholder={link || 'Insira o link de acesso ao Mídia Kit'}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setLink(event.target.value)}
        disabled={!showEditInput}
        value={link}
      />
    </MidiaKitContainer>
  );
};

export default Kit;
