import styled from 'styled-components';

export const Title = styled.h2`
  margin-bottom: 20px;
  color: #272727;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  @media (max-width: 960px) {
    font-size: 18px;
  }
`;
