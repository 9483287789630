import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
`;

export const ButtonsDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;

  button {
    margin: 0px 15px;
  }

  @media (max-width: 450px) {
    button {
      margin: 0px 5px;
      font-size: 14px;
    }
  }

  @media (max-width: 375px) {
    margin: 10px auto;
    gap: 10px;
  }
`;

// Overlay para cobrir a tela enquanto carrega
export const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); // Fundo semi-transparente
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px; // Segue o design da modal
`;

// Loader animado (spinner)
export const Loader = styled.div`
  width: 40px;
  height: 40px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #f5b700;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
