import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  ButtonsDiv,
  Container,
  ExcursionCompanyInformation,
  ExcursionFirstForm,
  ExcursionFirstFormContainer,
  FormRow4Cols,
  InputField,
  StepperBullet,
  StepperContainer,
  StepperItem,
  StepperLine,
  StepperName,
} from '../../styles';
import MainTitle from 'components/MainTitle';
import Button from 'components/Button';
import { Formik, Field, Form, useFormikContext } from 'formik';
import ErrorMessageContext from 'components/ErrorMessageContext';
import FieldMask from 'components/FieldMask';
import SelectComponent from 'components/Select';
import { useLoading } from 'hooks/useLoading';
import {
  IconGuia,
  IconHospedagem,
  IconOrganizador,
  IconPagamento,
  IconResumo,
  IconTransporte,
  IconViagem,
} from 'features/NewExcursionForm/components/StepperIcons';
import UserService from 'services/UserService';
import ConfirmationModal from 'components/ConfirmationModal';
import { useExcursionOrganizatorForm } from 'common/ExcursionOrganizatorFormContext';
import yup from 'utils/validation';
import { validateCPF, states } from 'features/Signup/pages/SignupTourOrganizer/utils';
import { validDDDs } from 'utils/basicUtils';
import { Estados, buscarCidadesPorEstado, CIDADES_POR_ESTADO } from 'utils/location';
import CEPField from 'features/NewExcursionForm/components/Select/CEPFieldComponent';
import CitySelectComponent from 'features/NewExcursionForm/components/Select/CitySelectComponent';
import ErrorMessage from 'components/ErrorMessage';
import LockNavigatorHook from 'features/NewExcursionForm/hooks/LockNavigatorHook';

interface Form1Props {
  disabledInput: boolean;
  disabledButton?: boolean;
}

const Form1: React.FC<Form1Props> = ({ disabledInput, disabledButton }) => {
  const [user, setUser] = useState<any>();
  const { values, setErrors, setFieldValue } = useFormikContext<any>();
  const { openLoading, closeLoading } = useLoading();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [selectedState, setSelectedState] = useState<string>('');
  const excursionOrganizatorFormContext = useExcursionOrganizatorForm();
  const numberInputRef = useRef<HTMLInputElement>(null);
  const addressInputRef = useRef<HTMLInputElement>(null);
  const [cidades, setCidades] = useState<{ label: string; value: string }[]>([]);

  const validationSchema = yup.object().shape({
    organizer: yup.object().shape({
      name: yup
        .string()
        .trim()
        .required('Campo obrigatório')
        .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, 'O nome deve conter apenas letras e espaços')
        .test('contains-full-name', 'Informe um nome e sobrenome', value => {
          if (!value) return false;
          return value.trim().split(/\s+/).length > 1; // Deve ter pelo menos dois nomes
        })
        .min(3, 'Nome deve possuir no mínimo 3 caracteres com sobrenome')
        .max(150, 'Nome muito longo, máximo 150 caracteres'),
      document_number: yup
        .string()
        .matches(/^([\w\.\-\/]{18}|[\w\.\-\/]{14})$/, 'Informe um CPF completo')
        .test('is-valid-cpf', 'Informe um CPF válido', value => validateCPF(value || ''))
        .required('Campo obrigatório'),
      phone: yup
        .string()
        .matches(/^\(\d{2}\)\s\d{1}\s\d{4}-\d{4}$/, 'Telefone deve ter o formato (XX) X XXXX-XXXX')
        .test('is-valid-ddd', 'DDD inválido', function (value) {
          if (!value) return false;
          const ddd = value.match(/^\((\d{2})\)/);
          if (!ddd) return false;
          return validDDDs.includes(ddd[1]);
        })
        .test('not-all-same', 'O número do telefone não pode ter todos os dígitos iguais', function (value) {
          if (!value) return false;

          const digits = value.replace(/\D/g, ''); // Remove caracteres não numéricos
          const phoneNumber = digits.slice(2); // Remove os dois primeiros dígitos do DDD

          return !/^(\d)\1+$/.test(phoneNumber); // Garante que o telefone não tenha todos os números iguais
        })
        .required('Telefone é obrigatório'),
      email: yup
        .string()
        .trim()
        .lowercase()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'E-mail inválido. Use um formato válido, ex: usuario@email.com'
        )
        .required('Campo Obrigatório'),
      cep: yup
        .string()
        .matches(/^\d{5}-?\d{3}$/, 'CEP deve ter o formato XXXXX-XXX')
        .test('is-not-equal', 'CEP não pode conter todos os números iguais', function (value) {
          if (!value) return false;
          const cleanedValue = value.replace('-', '');
          return !/^(.)\1+$/.test(cleanedValue);
        })
        .required('Campo Obrigatório'),
      address: yup
        .string()
        .matches(/^(?!\d+$).*/, 'O campo endereço não pode ser apenas números')
        .required('Campo Obrigatório'),
      number: yup.string().matches(/^\d+$/, 'Número deve conter apenas dígitos').required('Campo Obrigatório'),
      complement: yup.string().notRequired(),
      neighborhood: yup
        .string()
        .matches(/^(?!^\d+$)[\d\D]*$/, 'O bairro não pode conter somente números')
        .required('Campo Obrigatório'),
      city: yup
        .string()
        .matches(/^(?!^\d+$)[\d\D]*$/, 'A cidade não pode conter somente números')
        .test('is-valid-city', 'Cidade inválida para o estado selecionado', function (value) {
          if (!value) return false;

          const selectedState = this.parent.state; // Estado selecionado
          if (!selectedState) return false; // Se não houver estado, não valida cidade

          // Obtendo lista de cidades para o estado selecionado
          const cidadesEncontradas = buscarCidadesPorEstado(selectedState as Estados);

          // Normalizando entrada do usuário (removendo acentos e transformando para minúsculas)
          const normalizedUserInput = value
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase();

          // Normalizando lista de cidades e verificando se alguma corresponde
          const cidadeExiste = cidadesEncontradas.some(
            cidade =>
              cidade.nomeIBGE
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase() === normalizedUserInput
          );

          return cidadeExiste;
        })
        .required('Campo Obrigatório'),
      state: yup.string().required('Campo obrigatório'),
      using_user_data: yup.boolean().notRequired(),
    }),
  });

  const onSubmit = async () => {
    console.log('values', values);
    try {
      const validationFormInputs = await validationSchema.validate(values, { abortEarly: false });

      excursionOrganizatorFormContext.setForm({
        ...excursionOrganizatorFormContext.form,
        organizer: {
          name: validationFormInputs.organizer.name,
          document_number: validationFormInputs.organizer.document_number,
          document_type: user?.type === 'tour-pf' ? 'CPF' : 'CNPJ',
          phone: validationFormInputs.organizer.phone,
          email: validationFormInputs.organizer.email,
          cep: validationFormInputs.organizer.cep,
          address: validationFormInputs.organizer.address,
          number: validationFormInputs.organizer.number,
          complement: validationFormInputs.organizer.complement || '',
          neighborhood: validationFormInputs.organizer.neighborhood,
          city: validationFormInputs.organizer.city,
          state: validationFormInputs.organizer.state,
          using_user_data: disabledInput,
        },
      });
      history.push('/admin/new-excursion/step2');
    } catch (error: any) {
      setErrors(
        error.inner.reduce((errors: any, innerError: any) => ({ ...errors, [innerError.path]: innerError.message }), {})
      );
      console.error(error);
    }
  };

  const fetchUser = async () => {
    openLoading();
    try {
      const user = await new UserService().findOne();
      setUser(user);
      excursionOrganizatorFormContext.setForm({
        ...excursionOrganizatorFormContext.form,
        user_id: user?.id,
      });
      disabledInput
        ? setSelectedState(user?.extraInfo?.address?.state)
        : setSelectedState(excursionOrganizatorFormContext.form.organizer.state);
    } catch (error) {
      console.error(error);
    } finally {
      closeLoading();
    }
  };

  useEffect(() => {
    if (values.organizer.state) {
      setSelectedState(values.organizer.state);

      const cidadesEncontradas = CIDADES_POR_ESTADO[values.organizer.state as Estados] || [];
      setCidades(
        cidadesEncontradas
          .map(cidade => ({
            label: cidade.nomeIBGE,
            value: cidade.nomeIBGE,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)) // Ordenar em ordem alfabética
      );

      if (values.organizer.city) {
        const cidadeExiste = cidadesEncontradas.some(cidade => cidade.nomeIBGE === values.organizer.city);
        if (!cidadeExiste) {
          setFieldValue('organizer.city', '');
        }
      }
    }
  }, [values.organizer.state, values.organizer.city, setFieldValue]);

  useEffect(() => {
    fetchUser();
    if (excursionOrganizatorFormContext.form.organizer.state && !user?.extraInfo?.address?.state) {
      setSelectedState(excursionOrganizatorFormContext.form.organizer.state);
    }
  }, [disabledInput]);

  const handleCancel = () => {
    excursionOrganizatorFormContext.setForm({
      ...excursionOrganizatorFormContext.form,
      organizer: {
        ...excursionOrganizatorFormContext.form.organizer,
        document_type: '',
        name: '',
        document_number: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
      },
      origin_city: '',
      origin_state: '',
      start_date: '',
      hour_start: '',
      end_date: '',
      hour_end: '',
      people_number: 0,
      destination: [],
      central_access: false,
      travel_reason: '',
      declaration_file: '',
      transport: {
        ...excursionOrganizatorFormContext.form.transport,
        trade_name: '',
        legal_name: '',
        document_number: '',
        cadastur: '',
        phone: '',
        email: '',
        vehicle_type: '',
        vehicle_plate: '',
        has_pcd: false,
      },
      accommodation: {
        ...excursionOrganizatorFormContext.form.accommodation,
        accommodation_type: 0,
        cadastur: '',
        license_number: '',
        document_number: '',
        name: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        justification: '',
        iss: '',
      },
      guide: {
        ...excursionOrganizatorFormContext.form.guide,
        city_tour: false,
        coastal_tour: false,
        type: '',
        guide_name: '',
        cadastur: '',
        phone: '',
        email: '',
      },
      total_price: 0,
    });
    setShowModal(false);
    history.push('/admin/excursions');
  };

  const handleStateChange = async (
    estado: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    setFieldValue('organizer.state', estado);

    const cidadesEncontradas = CIDADES_POR_ESTADO[estado as Estados] || [];
    setCidades(
      cidadesEncontradas
        .map(cidade => ({
          label: cidade.nomeIBGE,
          value: cidade.nomeIBGE,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) // Ordenar em ordem alfabética
    );

    setFieldValue('organizer.city', ''); // Limpa a cidade selecionada
  };

  return (
    <>
      <Form
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onSubmit();
          }
        }}>
        <FormRow4Cols>
          <InputField id="full-name-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                Nome Completo<span>*</span>
              </label>
              <Field
                name="organizer.name"
                onKeyPress={(e: React.KeyboardEvent) => {
                  if (!/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                disabled={disabledInput}
              />
            </div>
            <ErrorMessage name="organizer.name" />
          </InputField>
          <InputField id="cpf-input">
            <div>
              <label htmlFor="">
                CPF<span>*</span>
              </label>
              <FieldMask type="cpf">
                <Field
                  type="text"
                  name="organizer.document_number"
                  placeholder="ex: xxx.xxx.xxx-xx"
                  disabled={disabledInput}
                />
              </FieldMask>
            </div>
            <ErrorMessage name="organizer.document_number" />
          </InputField>
          <InputField id="cellphone-input">
            <div>
              <label htmlFor="">
                Telefone ou Celular<span>*</span>
              </label>
              <FieldMask type="phone">
                <Field name="organizer.phone" disabled={disabledInput} placeholder="ex.: (xx) x xxxx-xxxx" />
              </FieldMask>
            </div>
            <ErrorMessage name="organizer.phone" />
          </InputField>
          <InputField id="email-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                E-mail<span>*</span>
              </label>
              <FieldMask type="email">
                <Field type="text" name="organizer.email" disabled={disabledInput} />
              </FieldMask>
            </div>
            <ErrorMessage name="organizer.email" />
          </InputField>
        </FormRow4Cols>
        <FormRow4Cols>
          <InputField id="state-input" className="responsive-grid-column-span-2">
            <CEPField
              validateOnBlur={true}
              name="organizer.cep"
              label="CEP"
              currentCity={values.organizer.city} // Passa o valor atual da cidade
              required
              value={values.organizer.cep}
              onChange={(name, value) => setFieldValue(name, value)} // Atualiza no Formik
              nextFieldRef={numberInputRef}
              fallbackFieldRef={addressInputRef}
              disabled={disabledInput}
              fieldMappings={[
                { formField: 'organizer.city', responseField: 'localidade' },
                { formField: 'organizer.address', responseField: 'logradouro' },
                { formField: 'organizer.neighborhood', responseField: 'bairro' },
                { formField: 'organizer.state', responseField: 'uf' },
                { formField: 'organizer.complement', responseField: 'complemento' },
              ]}
            />
            <ErrorMessage name="organizer.cep" />
          </InputField>
          <InputField id="address-input" style={{ gridColumn: 'span 2' }}>
            <div>
              <label htmlFor="">
                Endereço<span>*</span>
              </label>
              <Field name="organizer.address" innerRef={addressInputRef} disabled={disabledInput} />
            </div>
            <ErrorMessage name="organizer.address" />
          </InputField>
          <InputField id="number-input">
            <div>
              <label htmlFor="">
                Número<span>*</span>
              </label>
              <Field name="organizer.number" innerRef={numberInputRef} disabled={disabledInput} />
            </div>
            <ErrorMessage name="organizer.number" />
          </InputField>
          <InputField id="complement-input">
            <div>
              <label htmlFor="">Complemento</label>
              <Field name="organizer.complement" disabled={disabledInput} />
            </div>
            <ErrorMessage name="organizer.complement" />
          </InputField>
          <InputField id="neighborhood-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                Bairro<span>*</span>
              </label>
              <Field name="organizer.neighborhood" disabled={disabledInput} />
            </div>
            <ErrorMessage name="organizer.neighborhood" />
          </InputField>

          <InputField id="state-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                Estado<span>*</span>
              </label>
              <Field
                validateOnBlur
                component={({ field, form }: { field: any; form: any }) => (
                  <SelectComponent
                    label=""
                    options={states.map(state => ({ label: state.label, value: state.value }))}
                    onChange={value => {
                      handleStateChange(value as string, form.setFieldValue); // Atualiza o estado e carrega as cidades
                      form.setFieldValue(field.name, value); // Atualiza o estado selecionado no Formik
                    }}
                    name={field.name}
                    selected={field.value ? [field.value] : []} // Mantém o estado selecionado
                    disabled={disabledInput}
                  />
                )}
                name="organizer.state"
              />
            </div>
            <ErrorMessage name="organizer.state" />
          </InputField>

          <InputField id="state-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                Cidade<span>*</span>
              </label>
              <Field
                name="organizer.city"
                component={({ field, form }: { field: any; form: any }) => (
                  <CitySelectComponent
                    label=""
                    name={field.name}
                    options={cidades} // Lista de cidades para o estado selecionado
                    value={field.value} // Valor atual no Formik
                    onChange={selectedCity => form.setFieldValue(field.name, selectedCity)} // Atualiza diretamente a cidade
                    placeholderText="Selecione uma cidade"
                    disabled={disabledInput || !selectedState}
                  />
                )}
              />
            </div>
            <ErrorMessageContext name="organizer.city" />
          </InputField>
        </FormRow4Cols>
        <ButtonsDiv style={{ justifyContent: 'flex-end' }}>
          <Button buttonType="secondary" style={{ display: 'none' }}>
            Voltar
          </Button>
          <article>
            <Button
              type="button"
              buttonType="secondary"
              onClick={e => {
                e.preventDefault();
                setShowModal(true);
              }}>
              Cancelar
            </Button>
            <Button
              type="button"
              buttonType={disabledButton ? 'disabled' : 'primary'}
              onClick={onSubmit}
              disabled={disabledButton}>
              Próximo
            </Button>
          </article>
        </ButtonsDiv>
      </Form>
      {showModal && (
        <ConfirmationModal
          open={showModal}
          onCancel={() => {
            setShowModal(false);
          }}
          onConfirm={handleCancel}
          title="Cancelar Cadastro de Excursão"
          message={
            <p>
              Se prosseguir com a ação, <strong>não será possível recuperar os dados</strong> já perdidos. Deseja
              continuar?
            </p>
          }
          confirmText="Confirmar"
          cancelText="Voltar"
        />
      )}
    </>
  );
};

const NewExcursionStep1Form = (): JSX.Element => {
  const excursionOrganizatorFormContext = useExcursionOrganizatorForm();
  const [disabledInput, setDisabledInput] = useState<boolean>(
    !excursionOrganizatorFormContext.form.organizer.name // Desabilitado se já houver dados no contexto
  );
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const history = useHistory();
  const [user, setUser] = useState<any>();
  const { openLoading, closeLoading } = useLoading();
  const [selectedState, setSelectedState] = useState<string>('');
  const [showModal, setShowModal] = useState(false);

  LockNavigatorHook(history);

  useEffect(() => {
    if (excursionOrganizatorFormContext.form.organizer.using_user_data || disabledInput) {
      setDisabledInput(true);
    } else {
      setDisabledInput(false);
    }
  }, []);

  const validationSchema = yup.object().shape({
    organizer: yup.object().shape({
      name: yup
        .string()
        .trim()
        .required('Campo Obrigatório')
        .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, 'O nome deve conter apenas letras e espaços')
        .test('contains-full-name', 'Informe um nome e sobrenome', value => {
          if (!value) return false;
          return value.trim().split(/\s+/).length > 1;
        })
        .min(2, 'Nome deve possuir no mínimo 2 caracteres com sobrenome')
        .max(150, 'Nome muito longo, máximo 150 caracteres'),
      document_number: yup
        .string()
        .matches(/^([\w\.\-\/]{18}|[\w\.\-\/]{14})$/, 'Informe um CPF completo')
        .test('is-valid-cpf', 'Informe um CPF válido', value => validateCPF(value || ''))
        .required('Campo Obrigatório'),
      phone: yup
        .string()
        .required('Telefone é obrigatório')
        .matches(/^\(\d{2}\)\s\d{1}\s\d{4}-\d{4}$/, 'Telefone deve ter o formato (XX) X XXXX-XXXX')
        .test('is-valid-ddd', 'DDD inválido', function (value) {
          if (!value) return false;
          const ddd = value.match(/^\((\d{2})\)/);
          if (!ddd) return false;
          return validDDDs.includes(ddd[1]);
        })
        .test('not-all-same', 'Telefone não pode ter todos os números iguais', function (value) {
          if (!value) return false;
          const digits = value.replace(/\D/g, '');
          return !/^(\d)\1+$/.test(digits);
        }),
      email: yup
        .string()
        .email()
        .matches(/^(?!\d+$).*/, 'O campo email não pode ser apenas números')
        .required('Campo Obrigatório'),
      cep: yup
        .string()
        .matches(/^\d{5}-?\d{3}$/, 'CEP deve ter o formato XXXXX-XXX')
        .test('is-not-equal', 'CEP não pode conter todos os números iguais', function (value) {
          if (!value) return false;
          const cleanedValue = value.replace('-', '');
          return !/^(.)\1+$/.test(cleanedValue);
        })
        .required('Campo Obrigatório'),
      address: yup
        .string()
        .matches(/^(?!\d+$).*/, 'O campo endereço não pode ser apenas números')
        .required('Campo Obrigatório'),
      number: yup.string().required('Campo Obrigatório').matches(/^\d+$/, 'Número deve conter apenas dígitos'),
      complement: yup.string().notRequired(),
      neighborhood: yup
        .string()
        .matches(/^(?!^\d+$)[\d\D]*$/, 'O bairro não pode conter somente números')
        .required('Campo Obrigatório'),
      city: yup
        .string()
        .matches(/^(?!^\d+$)[\d\D]*$/, 'A cidade não pode conter somente números')
        .required('Campo Obrigatório'),
      state: yup.string().required('Campo obrigatório'),
    }),
  });

  const fetchUser = async () => {
    openLoading();
    try {
      const user = await new UserService().findOne();
      setUser(user);

      if (user?.extraInfo?.address?.state && Object.values(Estados).includes(user.extraInfo.address.state)) {
        setSelectedState(user.extraInfo.address.state);
      } else {
        setSelectedState('');
      }
    } catch (error) {
      console.error(error);
    } finally {
      closeLoading();
    }
  };

  useEffect(() => {
    fetchUser();
    if (excursionOrganizatorFormContext.form.organizer.state && !user?.extraInfo?.address?.state) {
      setSelectedState(excursionOrganizatorFormContext.form.organizer.state);
    }
  }, [disabledInput]);

  const initialValues = {
    user_id: user?.id ?? 0,
    organizer: {
      name: disabledInput
        ? user?.extraInfo?.responsibleName
        : excursionOrganizatorFormContext.form.organizer.name ?? '',
      document_number: disabledInput
        ? user?.extraInfo?.responsibleCpf
        : excursionOrganizatorFormContext.form.organizer.document_number ?? '',
      document_type:
        excursionOrganizatorFormContext.form.organizer.document_type ?? user?.type === 'tour-pf' ? 'cpf' : 'cnpj',
      phone: disabledInput ? user?.extraInfo?.phone : excursionOrganizatorFormContext.form.organizer.phone ?? '',
      email: disabledInput ? user?.email : excursionOrganizatorFormContext.form.organizer.email ?? '',
      cep: disabledInput ? user?.extraInfo?.address?.zipCode : excursionOrganizatorFormContext.form.organizer.cep ?? '',
      address: disabledInput
        ? user?.extraInfo?.address?.street
        : excursionOrganizatorFormContext.form.organizer.address ?? '',
      number: disabledInput
        ? user?.extraInfo?.address?.number
        : excursionOrganizatorFormContext.form.organizer.number ?? '',
      complement: disabledInput
        ? user?.extraInfo?.address?.additionalData
        : excursionOrganizatorFormContext.form.organizer.complement ?? '',
      neighborhood: disabledInput
        ? user?.extraInfo?.address?.neighborhood
        : excursionOrganizatorFormContext.form.organizer.neighborhood ?? '',
      city: disabledInput ? user?.extraInfo?.address?.city : excursionOrganizatorFormContext.form.organizer.city ?? '',
      state: disabledInput
        ? user?.extraInfo?.address?.state || ''
        : excursionOrganizatorFormContext.form.organizer.state || '',
      using_user_data: excursionOrganizatorFormContext.form.organizer.using_user_data || false,
    },
  };

  const handleCancel = () => {
    excursionOrganizatorFormContext.setForm({
      ...excursionOrganizatorFormContext.form,
      organizer: {
        ...excursionOrganizatorFormContext.form.organizer,
        document_type: '',
        name: '',
        document_number: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
      },
      origin_city: '',
      origin_state: '',
      start_date: '',
      hour_start: '',
      end_date: '',
      hour_end: '',
      people_number: 0,
      destination: [],
      central_access: false,
      travel_reason: '',
      declaration_file: '',
      transport: {
        ...excursionOrganizatorFormContext.form.transport,
        trade_name: '',
        legal_name: '',
        document_number: '',
        cadastur: '',
        phone: '',
        email: '',
        vehicle_type: '',
        vehicle_plate: '',
        has_pcd: false,
      },
      accommodation: {
        ...excursionOrganizatorFormContext.form.accommodation,
        accommodation_type: 0,
        cadastur: '',
        license_number: '',
        document_number: '',
        name: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        justification: '',
        iss: '',
      },
      guide: {
        ...excursionOrganizatorFormContext.form.guide,
        city_tour: false,
        coastal_tour: false,
        type: '',
        guide_name: '',
        cadastur: '',
        phone: '',
        email: '',
      },
      total_price: 0,
    });
    setShowModal(false);
    history.push('/admin/excursions');
  };

  const onSubmit = async () => {
    try {
      excursionOrganizatorFormContext.setForm({
        ...excursionOrganizatorFormContext.form,
        user_id: user?.id || 0,
        organizer: {
          name: user?.name,
          document_number: user?.document,
          document_type: user?.type === 'tour-pf' ? 'CPF' : 'CNPJ',
          phone: user?.extraInfo?.phone,
          email: user?.email,
          cep: user?.extraInfo?.address?.zipCode,
          address: user?.extraInfo?.address?.street,
          number: user?.extraInfo?.address?.number,
          complement: user?.extraInfo?.address?.additionalData,
          neighborhood: user?.extraInfo?.address?.neighborhood,
          city: user?.extraInfo?.address?.city,
          state: user?.extraInfo?.address?.state,
          using_user_data: disabledInput,
        },
      });
      history.push('/admin/new-excursion/step2');
    } catch (error) {
      console.error(error);
    }
  };

  console.log('excursionOrganizatorFormContext.form.organizer', excursionOrganizatorFormContext.form.organizer);

  return (
    <>
      <Container>
        <MainTitle>Cadastro de Excursão</MainTitle>

        <StepperContainer>
          <StepperItem className="flex flex-col items-center justify-center">
            <article>
              <StepperBullet className="onEdit" onClick={e => e.preventDefault()}>
                <IconOrganizador color="#020c90" />
              </StepperBullet>
              <StepperLine className="onEdit" />
            </article>
            <StepperName className="onEdit">Organizador</StepperName>
          </StepperItem>

          <StepperItem>
            <article>
              <StepperLine />
              <Link to="#">
                <StepperBullet onClick={e => e.preventDefault()}>
                  <IconViagem />
                </StepperBullet>
              </Link>
              <StepperLine />
            </article>
            <StepperName>Viagem</StepperName>
          </StepperItem>

          <StepperItem>
            <article>
              <StepperLine />
              <Link to="#">
                <StepperBullet onClick={e => e.preventDefault()}>
                  <IconTransporte />
                </StepperBullet>
              </Link>
              <StepperLine />
            </article>
            <StepperName>Transporte</StepperName>
          </StepperItem>

          <StepperItem>
            <article>
              <StepperLine />
              <Link to="#">
                <StepperBullet onClick={e => e.preventDefault()}>
                  <IconHospedagem />
                </StepperBullet>
              </Link>
              <StepperLine />
            </article>
            <StepperName>Hospedagem</StepperName>
          </StepperItem>

          <StepperItem>
            <article>
              <StepperLine />
              <Link to="#">
                <StepperBullet onClick={e => e.preventDefault()}>
                  <IconGuia />
                </StepperBullet>
              </Link>
              <StepperLine />
            </article>
            <StepperName>Guia</StepperName>
          </StepperItem>

          <StepperItem>
            <article>
              <StepperLine />
              <Link to="#">
                <StepperBullet onClick={e => e.preventDefault()}>
                  <IconPagamento />
                </StepperBullet>
              </Link>
              <StepperLine />
            </article>
            <StepperName>Pagamento</StepperName>
          </StepperItem>

          <StepperItem>
            <article>
              <StepperLine />
              <Link to="#">
                <StepperBullet onClick={e => e.preventDefault()}>
                  <IconResumo />
                </StepperBullet>
              </Link>
            </article>
            <StepperName>Resumo</StepperName>
          </StepperItem>
        </StepperContainer>

        <ExcursionFirstFormContainer>
          <p>{user?.type === 'tour-pj' ? '* Campo Obrigatório' : null}</p>
          <ExcursionCompanyInformation>
            <h2>{user?.type === 'tour-pj' ? 'Dados da empresa:' : 'Dados do responsável pela excursão:'}</h2>
            {user?.type === 'tour-pj' ? (
              <>
                <p>
                  Para cadastrar uma excursão, utilizamos os dados da empresa organizadora da excursão registrados no
                  seu perfil. Para atualizar alguma informação, edite o campo em <strong>Minha Conta</strong> antes de
                  continuar com o cadastro.
                </p>
                <h3>{user?.extraInfo?.tradeName}</h3>
                <h4>{user?.extraInfo?.legalName}</h4>
                <h4>{user?.document}</h4>
              </>
            ) : (
              <>
                <p>
                  <p>
                    Para cadastrar uma excursão, utilizamos os dados do responsável pela excursão registrados no seu
                    perfil. Para atualizar alguma informação, edite o campo em <strong>Minha Conta</strong> antes de
                    continuar com o cadastro.
                  </p>
                </p>
                <article>
                  <h3>{user?.name}</h3>
                  <h4>{user?.document}</h4>
                  <h4>{user?.extraInfo?.phone}</h4>
                  <h4>{user?.email}</h4>
                </article>
                <article style={{ marginTop: '20px' }}>
                  <h3>Endereço:</h3>
                  <h4>
                    {user?.extraInfo?.address?.street}, {user?.extraInfo?.address?.number}
                  </h4>
                  <h4>{user?.extraInfo?.address?.additionalData}</h4>
                  <h4>{user?.extraInfo?.address?.neighborhood}</h4>
                  <h4>
                    {user?.extraInfo?.address?.city} - {user?.extraInfo?.address?.state},{' '}
                    {user?.extraInfo?.address?.zipCode}
                  </h4>
                </article>
                <ButtonsDiv style={{ justifyContent: 'flex-end' }}>
                  <Button buttonType="secondary" style={{ display: 'none' }}>
                    Voltar
                  </Button>
                  <article>
                    <Button
                      type="button"
                      buttonType="secondary"
                      onClick={e => {
                        e.preventDefault();
                        setShowModal(true);
                      }}>
                      Cancelar
                    </Button>
                    <Button type="button" onClick={onSubmit}>
                      Próximo
                    </Button>
                  </article>
                </ButtonsDiv>
                {showModal && (
                  <ConfirmationModal
                    open={showModal}
                    onCancel={() => {
                      setShowModal(false);
                    }}
                    onConfirm={handleCancel}
                    title="Cancelar Cadastro de Excursão"
                    message={
                      <p>
                        Se prosseguir com a ação, <strong>não será possível recuperar os dados</strong> já perdidos.
                        Deseja continuar?
                      </p>
                    }
                    confirmText="Confirmar"
                    cancelText="Voltar"
                  />
                )}
              </>
            )}
          </ExcursionCompanyInformation>

          <>
            {user?.type === 'tour-pj' && (
              <ExcursionFirstForm>
                <h2>Dados do responsável pela excursão:</h2>
                <article>
                  <input
                    type="checkbox"
                    onChange={() => {
                      setDisabledInput(!disabledInput);
                      excursionOrganizatorFormContext.setForm({
                        ...excursionOrganizatorFormContext.form,
                        organizer: {
                          name: '',
                          document_number: '',
                          document_type: '',
                          phone: '',
                          email: '',
                          cep: '',
                          address: '',
                          number: '',
                          complement: '',
                          neighborhood: '',
                          city: '',
                          state: '',
                          using_user_data: !disabledInput,
                        },
                      });
                      !disabledInput ? setDisabledButton(false) : setDisabledButton(true);
                    }}
                    checked={excursionOrganizatorFormContext.form.organizer.using_user_data || disabledInput}
                  />
                  <span>
                    Utilizar dados cadastrados em <strong>Minha Conta</strong>
                  </span>
                </article>

                <Formik
                  validateOnBlur
                  validate={values => {
                    const isValid = validationSchema.isValidSync(values);
                    setDisabledButton(!isValid);
                  }}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={() => {
                    null;
                  }}
                  enableReinitialize>
                  <Form1 disabledInput={disabledInput} disabledButton={disabledButton} />
                </Formik>
              </ExcursionFirstForm>
            )}
          </>
        </ExcursionFirstFormContainer>
      </Container>
    </>
  );
};

export default NewExcursionStep1Form;
