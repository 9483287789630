export default interface IUser {
  id: string;
  email: string;
  name: string;
  profile: {
    name: string;
    image: string;
  };
  emailVerified?: boolean;
  type?: string;
  status?: UserStatusAPI;
}

export interface IUserAPI {
  id: number;
  name: string;
  email: string;
  document: string;
  type: UserType;
  status: UserStatusAPI;
  extraInfo: any;
}

export enum UserStatusAPI {
  APPROVED = 'approved',
  REFUSED = 'refused',
  NEW_PROFILE = 'new-profile',
  DELETE_REQUEST = 'delete-request',
  BLOCK = 'block',
}

export enum UserType {
  ADMIN = 'admin',
  NEWS = 'news',
  NEWS_EXTERNAL = 'news-external',
  SETUR = 'setur',
  SETUR_MARKET = 'setur-market',
  SETUR_MARKETING = 'setur-marketing',
  SETUR_FUNDETUR = 'setur-fundetur',
  SETUR_ATTORNEY = 'setur-attorney',
  SETUR_EXCURSION = 'setur-excursion',
  TOUR = 'tour',
  TOUR_PF = 'tour-pf',
  TOUR_PJ = 'tour-pj',
  TOUR_ADMIN = 'tour-admin',
  TOURIST = 'tourist',
  TRADE = 'trade',
}
