import React, { forwardRef, Ref } from 'react';
import { MaskProps, useMask } from '@react-input/mask';

export type MaskType = 'cnpj' | 'cpf' | 'cnpj-or-cpf' | 'date' | 'phone' | 'zipcode' | 'time' | 'currency' | 'email';
interface ExtendedMaskProps extends MaskProps {
  validate?: (value: string) => boolean;
  blocks?: { [key: string]: { mask: string } };
}

type IMaskOptions = { [key in MaskType]: ExtendedMaskProps };

interface IProps {
  type: MaskType;
  children: JSX.Element;
  id?: string;
}

const maskOptions: IMaskOptions = {
  cnpj: { mask: '__.___.___/____-__', replacement: { _: /\d/ } },
  cpf: { mask: '___.___.___-__', replacement: { _: /\d/ } },
  date: { mask: '__/__/____', replacement: { _: /\d/ } },
  time: {
    mask: 'Hh:Mm',
    replacement: {
      H: /[0-2]/, // Primeiro dígito das horas (0-2)
      h: /[0-9]/, // Segundo dígito das horas (0-9)
      M: /[0-5]/, // Primeiro dígito dos minutos (0-5)
      m: /[0-9]/, // Segundo dígito dos minutos (0-9)
    },
    blocks: {
      HH: {
        mask: 'Hh',
      },
      MM: {
        mask: 'Mm',
      },
    },
  },
  phone: { mask: '(__) _ ____-____', replacement: { _: /\d/ } },
  zipcode: {
    mask: '_____-___',
    replacement: { _: /\d/ },
    validate: (value: string) => {
      const uniqueDigits = new Set(value.replace(/[^0-9]/g, ''));
      return uniqueDigits.size > 1;
    },
  },
  'cnpj-or-cpf': {
    mask: '$'.repeat(18),
    replacement: { _: /\d/, $: /\d/ },
    modify: ({ length }: string) => {
      const cpf = '___.___.___-__$';
      const cnpj = '__.___.___/____-__';
      return { mask: length <= 11 ? cpf : cnpj };
    },
  },
  currency: {
    mask: 'R$ _',
    replacement: { _: /\d/ },
    modify: ({ length }) => {
      const masks = ['R$ _', 'R$ 0,0__', 'R$ 0,___', 'R$ _,___', 'R$ __,___', 'R$ ___,___', 'R$ ____,___'];
      const mask = masks[length] || 'R$ _____,__';
      return { mask };
    },
  },
  email: { mask: '_'.repeat(100), replacement: { _: /[\w@\-.+]/ } },
};

const FieldMask = forwardRef((props: IProps, ref: Ref<any>): React.ReactElement => {
  const { children, type } = props;

  const options = maskOptions[type];
  const innerRef = useMask(options);

  return React.cloneElement(children, { innerRef, ref: innerRef });
});

FieldMask.displayName = 'FieldMask';
export default FieldMask;
