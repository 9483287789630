import styled from 'styled-components';
import closeBlue from 'assets/images/closeBlue.svg';
import check from 'assets/images/check.svg';
import RadioCheck from 'assets/images/radioCheck.svg';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 75vh;

  a {
    font-weight: bold;
    color: #272727;
  }

  h3 {
    font-size: 16px;
    color: #272727;
    font-weight: 500;
  }

  input[type='checkbox'] {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    padding: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid black;
    border-radius: 3px;
    cursor: pointer;
  }

  input[type='checkbox']:checked {
    background: url(${check}) center no-repeat;
    border: none;
  }

  input[type='radio'] {
    appearance: unset;
    height: 16px;
    padding: 0 7px;
    margin: 0;
  }

  input[type='radio']:checked {
    background: url(${RadioCheck}) center no-repeat;
    border: none;
    padding: 0 8px;
  }
`;

export const StepperContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
  width: 100%;
  list-style: none;

  :nth-child(1) p {
    margin-left: -50%;
  }

  :last-child p {
    margin-right: -50%;
  }

  @media all and (max-width: 1024px) {
    margin: 10px auto;
  }
`;

export const StepperItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  article {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #9a9a9a;
  }

  a {
    text-decoration: none;
  }

  @media all and (max-width: 1024px) {
    p {
      display: none;
    }
  }
`;

export const StepperBullet = styled.button`
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  background: #9a9a9a;
  color: white;
  border-radius: 50%;

  &.onEdit {
    background: transparent;
    border: 1px solid #020c90;
    color: #020c90;
  }

  &.edited {
    background: #020c90;
  }

  @media all and (max-width: 450px) {
    width: 30px;
    height: 30px;
  }
`;

export const StepperLine = styled.hr`
  margin-top: -5px;
  width: 50px;
  height: 1px;
  background: #9a9a9a;
  border: none;

  &.onEdit {
    background: #020c90;
  }

  @media all and (max-width: 1024px) {
    width: 10px;
  }

  @media all and (max-width: 450px) {
    width: 5px;
  }
`;

export const StepperName = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #9a9a9a;

  &.onEdit {
    color: #020c90;
  }
`;

export const ExcursionFirstFormContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 90%;

  p {
    margin: 10px 0;
    color: #ec3237;
  }
`;

export const ExcursionCompanyInformation = styled.article`
  display: flex;
  flex-direction: column;
  margin: 10px 0 20px 0;
  width: 100%;

  section {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 10px 0;
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px dashed #9a9a9a;

    &:nth-child(6) {
      border-bottom: none;
    }

    @media (max-width: 960px) {
      flex-direction: column;
      padding-bottom: 0;
    }

    article {
      width: 50%;

      @media (max-width: 960px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  h2 {
    font-size: 20px;
    font-weight: 500;
    color: #272727;

    @media (max-width: 960px) {
      margin-bottom: 15px;
    }
  }

  p {
    margin: 10px 0;
    font-size: 16px;
    color: #272727;
  }

  h3 {
    font-size: 16px;
    color: #272727;
    font-weight: 500;
  }

  h4 {
    font-size: 16px;
    color: #272727;
    font-weight: lighter;
  }

  h5 {
    margin-bottom: 10px;
    font-size: 16px;
    color: #272727;
    font-weight: lighter;
  }

  button {
    width: fit-content;
  }
`;

export const ExcursionFirstForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 15px 0;

    li {
      display: flex;
      align-items: center;
      width: fit-content;
      gap: 5px;

      input {
        margin-top: 0;
        width: 15px;
        height: 15px;
      }
    }
  }

  h2 {
    margin: 20px 0;
    font-size: 20px;
    color: #272727;
    font-weight: 500;
  }

  article {
    display: flex;
    align-items: center;

    span {
      margin-left: 10px;
      font-size: 14px;
      cursor: pointer;
    }

    [type='checkbox'] {
      margin: 0;
      width: 15px;
      height: 15px;
      accent-color: #ffcb29;
      cursor: pointer;
      position: relative;

      @media (max-width: 960px) {
        width: 20px;
        height: 20px;
      }
    }

    [type='checkbox']:checked {
      background-color: #ffcb29;
    }

    [type='checkbox']::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);
    }

    [type='checkbox']:checked::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);
    }
  }

  label {
    font-size: 16px;
    font-weight: 500;

    span {
      color: #ec3237;
    }
  }

  input {
    margin-top: 1vh;
    width: 100%;
    height: 40px;
    border-radius: 18px;
    border: 1px solid #9a9a9a;
    padding: 0 10px;
  }

  .dropdown {
    margin-top: 1vh;
    width: 100%;
    height: 40px;
    margin-bottom: 2.5vh;
    padding: 0 0.5vw;
    border-radius: 18px;
    div {
      margin-top: 5vh;
    }
  }

  #state-input,
  #document-type-input {
    .CustomSelect {
      margin-top: 5px;
    }

    .SelectedOption {
      padding: 0 0 0 10px;
    }

    p {
      overflow: initial;
      color: #272727;
    }
  }
`;

export const FormRow4Cols = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin: 20px 0 30px 0;

  a {
    color: #272727;
  }

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const InputField = styled.div`
  &.responsive-grid-column-span-2 {
    grid-column: span 1;

    @media (max-width: 960px) {
      grid-column: span 2;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    label {
      white-space: nowrap;
      span {
        color: #ec3237;
        margin-left: 0.2vw;
      }
    }

    input {
      padding: 0 10px;
    }

    small {
      color: #9a9a9a;
    }
  }

  #city-select {
    label {
      margin-bottom: 5px;
      color: #272727;
    }

    p {
      margin: 0;
      color: #272727;
    }
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 36px 0 50px 0;

  article {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    min-width: fit-content;

    @media (max-width: 450px) {
      padding: 5px 20px;
      font-size: 12px;
    }
  }

  article button {
    margin-left: 20px;

    .hidden {
      display: none;
    }

    @media (max-width: 450px) {
      padding: 5px 20px;
      font-size: 12px;
    }
  }
`;

export const RadioButtonsContainer = styled.article`
  display: flex;
  margin: 10px 0 0 0;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }

  p {
    margin: 0 0 0 5px;
    font-size: 16px;
    color: #272727;
  }
`;

export const Input = styled.input``;

export const InputLabel = styled.label`
  width: fit-content;
  border-radius: 18px;
  border: none;
  box-shadow: 0px 0px 10px 0px #00000026;
  color: #ffffff;
  background-color: #ffcb29;
  border: 1px solid #ffcb29;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 15px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #020c90;
    border: 1px solid #020c90;
  }
`;

export const FileViewer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  background: #fff;
  border-radius: 18px;
  border: 2px solid #9a9a9a;
  width: 30%;
  height: 40px;
  text-align: left;
  margin-right: 1rem;
  padding: 0 10px;

  @media (max-width: 960px) {
    width: 70%;
  }

  p:nth-child(1) {
    width: 80%;
    overflow: hidden;
    line-height: 15px;
    color: #272727 !important;
  }

  img {
    width: 30px;
    height: 30px;
    margin-top: 2px;
    cursor: pointer;

    &:hover {
      content: url(${closeBlue});
    }
  }

  :active {
    opacity: 1;
    border-color: rgba(0, 0, 0, 0.25);
  }
`;
