import { ExcursionOrganizatorForm } from 'interfaces/IExcursionOrganizerForm';

export const initialExcursionOrganizatorForm: ExcursionOrganizatorForm = {
  id: 0,
  id_selo: '',
  id_consulta: '',
  user_id: 0,
  origin_state: '',
  origin_city: '',
  start_date: '',
  end_date: '',
  hour_start: '',
  hour_end: '',
  people_number: 0,
  destination: [],
  total_price: 0,
  travel_reason: '',
  declaration_file_signed: '',
  declaration_file: '',
  central_access: false,
  status: '',

  organizer: {
    name: '',
    document_number: '',
    document_type: '',
    phone: '',
    email: '',
    cep: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    using_user_data: false,
  },

  transport: {
    document_number: '',
    trade_name: '',
    legal_name: '',
    cadastur: '',
    has_pcd: false,
    phone: '',
    email: '',
    vehicle_type: '',
    vehicle_plate: '',
  },

  accommodation: {
    accommodation_type: 0,
    justification: '',
    cadastur: '',
    license_number: '',
    iss: '',
    document_number: '',
    document_type: '',
    name: '',
    phone: '',
    email: '',
    cep: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    state: '',
    city: '',
  },

  guide: {
    city_tour: false,
    coastal_tour: false,
    type: '',
    guide_name: '',
    phone: '',
    email: '',
    cadastur: '',
    information_check: false,
  },
};
