import styled from 'styled-components';

export const ExcursionPublicDetails404Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  height: 70vh;
  width: 100%;
`;

export const ExcursionPublicDetails404ContainerIcon = styled.img`
  width: 200px;
  height: 200px;
  margin-bottom: 1rem;
`;

export const ExcursionPublicDetails404ContainerTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #999;
`;

export const ExcursionPublicDetails404ContainerMessage = styled.p`
  font-size: 1.5rem;
  color: #999;
  margin-top: 1rem;
`;
