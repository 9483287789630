import React, { useEffect } from 'react';

const LockNavigatorHook: React.FC = () => {
  useEffect(() => {
    const currentUrl = window.location.href;
    const maskedUrl = currentUrl.replace(/\/[^/]*$/, '/form-new-excursion');
    window.history.replaceState(null, '', maskedUrl);

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'F5' || (e.ctrlKey && e.key === 'r')) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey && e.key === 'R') {
        e.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return null;
};

export default LockNavigatorHook;
