export enum Estados {
  RO = 'RO',
  RR = 'RR',
  GO = 'GO',
  PA = 'PA',
  AP = 'AP',
  TO = 'TO',
  MT = 'MT',
  MA = 'MA',
  AC = 'AC',
  MS = 'MS',
  AM = 'AM',
  PI = 'PI',
  RN = 'RN',
  PB = 'PB',
  PE = 'PE',
  AL = 'AL',
  MG = 'MG',
  ES = 'ES',
  RJ = 'RJ',
  SP = 'SP',
  PR = 'PR',
  SC = 'SC',
  RS = 'RS',
  CE = 'CE',
  SE = 'SE',
  BA = 'BA',
  EX = 'EX',
  DF = 'DF',
}

export interface Cidade {
  codigoTOM: number;
  codigoIBGE: number;
  nomeTOM: string;
  nomeIBGE: string;
}

export const CIDADES_POR_ESTADO: Record<Estados, Cidade[]> = {
  [Estados.RO]: [
    { codigoTOM: 1, codigoIBGE: 1100106, nomeTOM: 'GUAJARÁ-MIRIM', nomeIBGE: 'Guajará-Mirim' },
    { codigoTOM: 2, codigoIBGE: 1100379, nomeTOM: 'ALTO ALEGRE DOS PARECIS', nomeIBGE: 'Alto Alegre dos Parecis' },
    { codigoTOM: 3, codigoIBGE: 1100205, nomeTOM: 'PORTO VELHO', nomeIBGE: 'Porto Velho' },
    { codigoTOM: 4, codigoIBGE: 1100452, nomeTOM: 'BURITIS', nomeIBGE: 'Buritis' },
    { codigoTOM: 5, codigoIBGE: 1100122, nomeTOM: 'JI-PARANÁ', nomeIBGE: 'Ji-Paraná' },
    { codigoTOM: 6, codigoIBGE: 1100924, nomeTOM: 'CHUPINGUAIA', nomeIBGE: 'Chupinguaia' },
    { codigoTOM: 7, codigoIBGE: 1100023, nomeTOM: 'ARIQUEMES', nomeIBGE: 'Ariquemes' },
    { codigoTOM: 8, codigoIBGE: 1100940, nomeTOM: 'CUJUBIM', nomeIBGE: 'Cujubim' },
    { codigoTOM: 9, codigoIBGE: 1100049, nomeTOM: 'CACOAL', nomeIBGE: 'Cacoal' },
    { codigoTOM: 10, codigoIBGE: 1101435, nomeTOM: 'NOVA UNIÃO', nomeIBGE: 'Nova União' },
    { codigoTOM: 11, codigoIBGE: 1100189, nomeTOM: 'PIMENTA BUENO', nomeIBGE: 'Pimenta Bueno' },
    { codigoTOM: 12, codigoIBGE: 1101450, nomeTOM: 'PARECIS', nomeIBGE: 'Parecis' },
    { codigoTOM: 13, codigoIBGE: 1100304, nomeTOM: 'VILHENA', nomeIBGE: 'Vilhena' },
    { codigoTOM: 14, codigoIBGE: 1101468, nomeTOM: 'PIMENTEIRAS DO OESTE', nomeIBGE: 'Pimenteiras do Oeste' },
    { codigoTOM: 15, codigoIBGE: 1100114, nomeTOM: 'JARU', nomeIBGE: 'Jaru' },
    { codigoTOM: 16, codigoIBGE: 1101476, nomeTOM: 'PRIMAVERA DE RONDÔNIA', nomeIBGE: 'Primavera de Rondônia' },
    { codigoTOM: 17, codigoIBGE: 1100155, nomeTOM: 'OURO PRETO DO OESTE', nomeIBGE: 'Ouro Preto do Oeste' },
    { codigoTOM: 18, codigoIBGE: 1101484, nomeTOM: "SÃO FELIPE D'OESTE", nomeIBGE: "São Felipe D'Oeste" },
    { codigoTOM: 19, codigoIBGE: 1100254, nomeTOM: 'PRESIDENTE MÉDICI', nomeIBGE: 'Presidente Médici' },
    { codigoTOM: 20, codigoIBGE: 1101492, nomeTOM: 'SÃO FRANCISCO DO GUAPORÉ', nomeIBGE: 'São Francisco do Guaporé' },
    { codigoTOM: 21, codigoIBGE: 1100080, nomeTOM: 'COSTA MARQUES', nomeIBGE: 'Costa Marques' },
    { codigoTOM: 22, codigoIBGE: 1101559, nomeTOM: 'TEIXEIRÓPOLIS', nomeIBGE: 'Teixeirópolis' },
    { codigoTOM: 23, codigoIBGE: 1100064, nomeTOM: 'COLORADO DO OESTE', nomeIBGE: 'Colorado do Oeste' },
    { codigoTOM: 24, codigoIBGE: 1101757, nomeTOM: 'VALE DO ANARI', nomeIBGE: 'Vale do Anari' },
    { codigoTOM: 25, codigoIBGE: 1100098, nomeTOM: "ESPIGÃO D'OESTE", nomeIBGE: "Espigão D'Oeste" },
    { codigoTOM: 27, codigoIBGE: 1100056, nomeTOM: 'CEREJEIRAS', nomeIBGE: 'Cerejeiras' },
    { codigoTOM: 29, codigoIBGE: 1100288, nomeTOM: 'ROLIM DE MOURA', nomeIBGE: 'Rolim de Moura' },
    { codigoTOM: 33, codigoIBGE: 1100015, nomeTOM: "ALTA FLORESTA D'OESTE", nomeIBGE: "Alta Floresta D'Oeste" },
    { codigoTOM: 35, codigoIBGE: 1100346, nomeTOM: "ALVORADA D'OESTE", nomeIBGE: "Alvorada D'Oeste" },
    { codigoTOM: 37, codigoIBGE: 1100031, nomeTOM: 'CABIXI', nomeIBGE: 'Cabixi' },
    { codigoTOM: 39, codigoIBGE: 1100130, nomeTOM: "MACHADINHO D'OESTE", nomeIBGE: "Machadinho D'Oeste" },
    { codigoTOM: 41, codigoIBGE: 1100148, nomeTOM: "NOVA BRASILÂNDIA D'OESTE", nomeIBGE: "Nova Brasilândia D'Oeste" },
    { codigoTOM: 43, codigoIBGE: 1100296, nomeTOM: "SANTA LUZIA D'OESTE", nomeIBGE: "Santa Luzia D'Oeste" },
    { codigoTOM: 45, codigoIBGE: 1100320, nomeTOM: 'SÃO MIGUEL DO GUAPORÉ', nomeIBGE: 'São Miguel do Guaporé' },
    { codigoTOM: 47, codigoIBGE: 1100338, nomeTOM: 'Nova Mamoré', nomeIBGE: 'Nova Mamoré' },
    { codigoTOM: 675, codigoIBGE: 1100403, nomeTOM: 'ALTO PARAÍSO', nomeIBGE: 'Alto Paraíso' },
    { codigoTOM: 677, codigoIBGE: 1100601, nomeTOM: 'CACAULÂNDIA', nomeIBGE: 'Cacaulândia' },
    { codigoTOM: 679, codigoIBGE: 1100700, nomeTOM: 'CAMPO NOVO DE RONDÔNIA', nomeIBGE: 'Campo Novo de Rondônia' },
    { codigoTOM: 681, codigoIBGE: 1100809, nomeTOM: 'CANDEIAS DO JAMARI', nomeIBGE: 'Candeias do Jamari' },
    { codigoTOM: 683, codigoIBGE: 1101104, nomeTOM: 'ITAPUÃ DO OESTE', nomeIBGE: 'Itapuã do Oeste' },
    { codigoTOM: 685, codigoIBGE: 1101401, nomeTOM: 'MONTE NEGRO', nomeIBGE: 'Monte Negro' },
    { codigoTOM: 687, codigoIBGE: 1100262, nomeTOM: 'RIO CRESPO', nomeIBGE: 'Rio Crespo' },
    { codigoTOM: 689, codigoIBGE: 1100502, nomeTOM: 'NOVO HORIZONTE DO OESTE', nomeIBGE: 'Novo Horizonte do Oeste' },
    { codigoTOM: 691, codigoIBGE: 1100908, nomeTOM: 'CASTANHEIRAS', nomeIBGE: 'Castanheiras' },
    {
      codigoTOM: 693,
      codigoIBGE: 1101005,
      nomeTOM: 'GOVERNADOR JORGE TEIXEIRA',
      nomeIBGE: 'Governador Jorge Teixeira',
    },
    { codigoTOM: 695, codigoIBGE: 1101203, nomeTOM: 'MINISTRO ANDREAZZA', nomeIBGE: 'Ministro Andreazza' },
    { codigoTOM: 697, codigoIBGE: 1101302, nomeTOM: 'MIRANTE DA SERRA', nomeIBGE: 'Mirante da Serra' },
    { codigoTOM: 699, codigoIBGE: 1101500, nomeTOM: 'SERINGUEIRAS', nomeIBGE: 'Seringueiras' },
    { codigoTOM: 975, codigoIBGE: 1101609, nomeTOM: 'THEOBROMA', nomeIBGE: 'Theobroma' },
    { codigoTOM: 977, codigoIBGE: 1101708, nomeTOM: 'URUPÁ', nomeIBGE: 'Urupá' },
    { codigoTOM: 979, codigoIBGE: 1101807, nomeTOM: 'VALE DO PARAÍSO', nomeIBGE: 'Vale do Paraíso' },
    { codigoTOM: 981, codigoIBGE: 1100072, nomeTOM: 'CORUMBIARA', nomeIBGE: 'Corumbiara' },
  ],
  [Estados.RR]: [
    { codigoTOM: 26, codigoIBGE: 1400027, nomeTOM: 'AMAJARI', nomeIBGE: 'Amajari' },
    { codigoTOM: 28, codigoIBGE: 1400175, nomeTOM: 'CANTÁ', nomeIBGE: 'Cantá' },
    { codigoTOM: 30, codigoIBGE: 1400233, nomeTOM: 'CAROEBE', nomeIBGE: 'Caroebe' },
    { codigoTOM: 32, codigoIBGE: 1400282, nomeTOM: 'IRACEMA', nomeIBGE: 'Iracema' },
    { codigoTOM: 34, codigoIBGE: 1400456, nomeTOM: 'PACARAIMA', nomeIBGE: 'Pacaraima' },
    { codigoTOM: 36, codigoIBGE: 1400472, nomeTOM: 'RORAINÓPOLIS', nomeIBGE: 'Rorainópolis' },
    { codigoTOM: 38, codigoIBGE: 1400704, nomeTOM: 'UIRAMUTÃ', nomeIBGE: 'Uiramutã' },
    { codigoTOM: 301, codigoIBGE: 1400100, nomeTOM: 'BOA VISTA', nomeIBGE: 'Boa Vista' },
    { codigoTOM: 303, codigoIBGE: 1400209, nomeTOM: 'CARACARAÍ', nomeIBGE: 'Caracaraí' },
    { codigoTOM: 305, codigoIBGE: 1400050, nomeTOM: 'ALTO ALEGRE', nomeIBGE: 'Alto Alegre' },
    { codigoTOM: 307, codigoIBGE: 1400159, nomeTOM: 'BONFIM', nomeIBGE: 'Bonfim' },
    { codigoTOM: 309, codigoIBGE: 1400308, nomeTOM: 'MUCAJAÍ', nomeIBGE: 'Mucajaí' },
    { codigoTOM: 311, codigoIBGE: 1400407, nomeTOM: 'NORMANDIA', nomeIBGE: 'Normandia' },
    { codigoTOM: 313, codigoIBGE: 1400506, nomeTOM: 'SÃO JOÃO DA BALIZA', nomeIBGE: 'São João da Baliza' },
    { codigoTOM: 315, codigoIBGE: 1400605, nomeTOM: 'SÃO LUIZ', nomeIBGE: 'São Luiz' },
  ],
  [Estados.GO]: [
    { codigoTOM: 31, codigoIBGE: 5204557, nomeTOM: 'CALDAZINHA', nomeIBGE: 'Caldazinha' },
    { codigoTOM: 49, codigoIBGE: 5212055, nomeTOM: 'JESÚPOLIS', nomeIBGE: 'Jesúpolis' },
    { codigoTOM: 51, codigoIBGE: 5218391, nomeTOM: 'PROFESSOR JAMIL', nomeIBGE: 'Professor Jamil' },
    { codigoTOM: 53, codigoIBGE: 5219738, nomeTOM: 'SANTO ANTÔNIO DE GOIÁS', nomeIBGE: 'Santo Antônio de Goiás' },
    { codigoTOM: 55, codigoIBGE: 5205513, nomeTOM: 'COCALZINHO DE GOIÁS', nomeIBGE: 'Cocalzinho de Goiás' },
    { codigoTOM: 57, codigoIBGE: 5221197, nomeTOM: 'TEREZÓPOLIS DE GOIÁS', nomeIBGE: 'Terezópolis de Goiás' },
    { codigoTOM: 59, codigoIBGE: 5221577, nomeTOM: 'UIRAPURU', nomeIBGE: 'Uirapuru' },
    { codigoTOM: 61, codigoIBGE: 5203962, nomeTOM: 'BURITINÓPOLIS', nomeIBGE: 'Buritinópolis' },
    { codigoTOM: 63, codigoIBGE: 5203939, nomeTOM: 'BURITI DE GOIÁS', nomeIBGE: 'Buriti de Goiás' },
    { codigoTOM: 65, codigoIBGE: 5209291, nomeTOM: 'GUARAÍTA', nomeIBGE: 'Guaraíta' },
    { codigoTOM: 67, codigoIBGE: 5222203, nomeTOM: 'VILA BOA', nomeIBGE: 'Vila Boa' },
    { codigoTOM: 69, codigoIBGE: 5209937, nomeTOM: 'INACIOLÂNDIA', nomeIBGE: 'Inaciolândia' },
    { codigoTOM: 71, codigoIBGE: 5201454, nomeTOM: 'APARECIDA DO RIO DOCE', nomeIBGE: 'Aparecida do Rio Doce' },
    { codigoTOM: 73, codigoIBGE: 5205471, nomeTOM: 'CHAPADÃO DO CÉU', nomeIBGE: 'Chapadão do Céu' },
    { codigoTOM: 75, codigoIBGE: 5216452, nomeTOM: 'PEROLÂNDIA', nomeIBGE: 'Perolândia' },
    { codigoTOM: 77, codigoIBGE: 5205497, nomeTOM: 'CIDADE OCIDENTAL', nomeIBGE: 'Cidade Ocidental' },
    { codigoTOM: 79, codigoIBGE: 5213772, nomeTOM: 'MONTIVIDIU DO NORTE', nomeIBGE: 'Montividiu do Norte' },
    { codigoTOM: 81, codigoIBGE: 5205059, nomeTOM: 'CASTELÂNDIA', nomeIBGE: 'Castelândia' },
    { codigoTOM: 83, codigoIBGE: 5219712, nomeTOM: 'SANTO ANTÔNIO DA BARRA', nomeIBGE: 'Santo Antônio da Barra' },
    { codigoTOM: 85, codigoIBGE: 5200555, nomeTOM: 'ALTO HORIZONTE', nomeIBGE: 'Alto Horizonte' },
    { codigoTOM: 87, codigoIBGE: 5214879, nomeTOM: 'NOVA IGUAÇU DE GOIÁS', nomeIBGE: 'Nova Iguaçu de Goiás' },
    { codigoTOM: 1050, codigoIBGE: 5200050, nomeTOM: 'ABADIA DE GOIÁS', nomeIBGE: 'Abadia de Goiás' },
    { codigoTOM: 1052, codigoIBGE: 5200258, nomeTOM: 'ÁGUAS LINDAS DE GOIÁS', nomeIBGE: 'Águas Lindas de Goiás' },
    { codigoTOM: 1054, codigoIBGE: 5200829, nomeTOM: 'AMARALINA', nomeIBGE: 'Amaralina' },
    { codigoTOM: 1056, codigoIBGE: 5203575, nomeTOM: 'BONÓPOLIS', nomeIBGE: 'Bonópolis' },
    { codigoTOM: 1058, codigoIBGE: 5215231, nomeTOM: 'NOVO GAMA', nomeIBGE: 'Novo Gama' },
    { codigoTOM: 1060, codigoIBGE: 5218052, nomeTOM: 'PORTEIRÃO', nomeIBGE: 'Porteirão' },
    {
      codigoTOM: 1062,
      codigoIBGE: 5219456,
      nomeTOM: 'SANTA RITA DO NOVO DESTINO',
      nomeIBGE: 'Santa Rita do Novo Destino',
    },
    { codigoTOM: 1064, codigoIBGE: 5220280, nomeTOM: 'SÃO PATRÍCIO', nomeIBGE: 'São Patrício' },
    { codigoTOM: 1066, codigoIBGE: 5221858, nomeTOM: 'VALPARAÍSO DE GOIÁS', nomeIBGE: 'Valparaíso de Goiás' },
    { codigoTOM: 1068, codigoIBGE: 5222302, nomeTOM: 'VILA PROPÍCIO', nomeIBGE: 'Vila Propício' },
    { codigoTOM: 9359, codigoIBGE: 5207907, nomeTOM: 'FLORES DE GOIÁS', nomeIBGE: 'Flores de Goiás' },
    { codigoTOM: 9361, codigoIBGE: 5208004, nomeTOM: 'FORMOSA', nomeIBGE: 'Formosa' },
    { codigoTOM: 9363, codigoIBGE: 5208103, nomeTOM: 'FORMOSO', nomeIBGE: 'Formoso' },
    { codigoTOM: 9367, codigoIBGE: 5208400, nomeTOM: 'GOIANÁPOLIS', nomeIBGE: 'Goianápolis' },
    { codigoTOM: 9369, codigoIBGE: 5208509, nomeTOM: 'GOIANDIRA', nomeIBGE: 'Goiandira' },
    { codigoTOM: 9371, codigoIBGE: 5208608, nomeTOM: 'GOIANÉSIA', nomeIBGE: 'Goianésia' },
    { codigoTOM: 9373, codigoIBGE: 5208707, nomeTOM: 'GOIÂNIA', nomeIBGE: 'Goiânia' },
    { codigoTOM: 9375, codigoIBGE: 5208806, nomeTOM: 'GOIANIRA', nomeIBGE: 'Goianira' },
    { codigoTOM: 9377, codigoIBGE: 5208905, nomeTOM: 'GOIÁS', nomeIBGE: 'Goiás' },
    { codigoTOM: 9379, codigoIBGE: 5209101, nomeTOM: 'GOIATUBA', nomeIBGE: 'Goiatuba' },
    { codigoTOM: 9381, codigoIBGE: 5209200, nomeTOM: 'GUAPÓ', nomeIBGE: 'Guapó' },
    { codigoTOM: 9383, codigoIBGE: 5209408, nomeTOM: 'GUARANI DE GOIÁS', nomeIBGE: 'Guarani de Goiás' },
    { codigoTOM: 9387, codigoIBGE: 5209606, nomeTOM: 'HEITORAÍ', nomeIBGE: 'Heitoraí' },
    { codigoTOM: 9389, codigoIBGE: 5209705, nomeTOM: 'HIDROLÂNDIA', nomeIBGE: 'Hidrolândia' },
    { codigoTOM: 9391, codigoIBGE: 5209804, nomeTOM: 'HIDROLINA', nomeIBGE: 'Hidrolina' },
    { codigoTOM: 9393, codigoIBGE: 5209903, nomeTOM: 'IACIARA', nomeIBGE: 'Iaciara' },
    { codigoTOM: 9395, codigoIBGE: 5210000, nomeTOM: 'INHUMAS', nomeIBGE: 'Inhumas' },
    { codigoTOM: 9397, codigoIBGE: 5210109, nomeTOM: 'IPAMERI', nomeIBGE: 'Ipameri' },
    { codigoTOM: 9399, codigoIBGE: 5210208, nomeTOM: 'IPORÁ', nomeIBGE: 'Iporá' },
    { codigoTOM: 9401, codigoIBGE: 5210307, nomeTOM: 'ISRAELÂNDIA', nomeIBGE: 'Israelândia' },
    { codigoTOM: 9403, codigoIBGE: 5210406, nomeTOM: 'ITABERAÍ', nomeIBGE: 'Itaberaí' },
    { codigoTOM: 9407, codigoIBGE: 5210604, nomeTOM: 'ITAGUARU', nomeIBGE: 'Itaguaru' },
    { codigoTOM: 9411, codigoIBGE: 5210802, nomeTOM: 'ITAJÁ', nomeIBGE: 'Itajá' },
    { codigoTOM: 9413, codigoIBGE: 5210901, nomeTOM: 'ITAPACI', nomeIBGE: 'Itapaci' },
    { codigoTOM: 9415, codigoIBGE: 5211008, nomeTOM: 'ITAPIRAPUÃ', nomeIBGE: 'Itapirapuã' },
    { codigoTOM: 9419, codigoIBGE: 5211206, nomeTOM: 'ITAPURANGA', nomeIBGE: 'Itapuranga' },
    { codigoTOM: 9421, codigoIBGE: 5211305, nomeTOM: 'ITARUMÃ', nomeIBGE: 'Itarumã' },
    { codigoTOM: 9423, codigoIBGE: 5211404, nomeTOM: 'ITAUÇU', nomeIBGE: 'Itauçu' },
    { codigoTOM: 9425, codigoIBGE: 5211503, nomeTOM: 'ITUMBIARA', nomeIBGE: 'Itumbiara' },
    { codigoTOM: 9427, codigoIBGE: 5211602, nomeTOM: 'IVOLÂNDIA', nomeIBGE: 'Ivolândia' },
    { codigoTOM: 9429, codigoIBGE: 5211701, nomeTOM: 'JANDAIA', nomeIBGE: 'Jandaia' },
    { codigoTOM: 9431, codigoIBGE: 5211800, nomeTOM: 'JARAGUÁ', nomeIBGE: 'Jaraguá' },
    { codigoTOM: 9433, codigoIBGE: 5211909, nomeTOM: 'JATAÍ', nomeIBGE: 'Jataí' },
    { codigoTOM: 9435, codigoIBGE: 5212006, nomeTOM: 'JAUPACI', nomeIBGE: 'Jaupaci' },
    { codigoTOM: 9437, codigoIBGE: 5212105, nomeTOM: 'JOVIÂNIA', nomeIBGE: 'Joviânia' },
    { codigoTOM: 9439, codigoIBGE: 5212204, nomeTOM: 'JUSSARA', nomeIBGE: 'Jussara' },
    { codigoTOM: 9443, codigoIBGE: 5212303, nomeTOM: 'LEOPOLDO DE BULHÕES', nomeIBGE: 'Leopoldo de Bulhões' },
    { codigoTOM: 9445, codigoIBGE: 5212501, nomeTOM: 'LUZIÂNIA', nomeIBGE: 'Luziânia' },
    { codigoTOM: 9447, codigoIBGE: 5212600, nomeTOM: 'MAIRIPOTABA', nomeIBGE: 'Mairipotaba' },
    { codigoTOM: 9449, codigoIBGE: 5212709, nomeTOM: 'MAMBAÍ', nomeIBGE: 'Mambaí' },
    { codigoTOM: 9451, codigoIBGE: 5212808, nomeTOM: 'MARA ROSA', nomeIBGE: 'Mara Rosa' },
    { codigoTOM: 9453, codigoIBGE: 5212907, nomeTOM: 'MARZAGÃO', nomeIBGE: 'Marzagão' },
    { codigoTOM: 9455, codigoIBGE: 5216304, nomeTOM: 'PARANAIGUARA', nomeIBGE: 'Paranaiguara' },
    { codigoTOM: 9457, codigoIBGE: 5213004, nomeTOM: 'MAURILÂNDIA', nomeIBGE: 'Maurilândia' },
    { codigoTOM: 9459, codigoIBGE: 5213103, nomeTOM: 'MINEIROS', nomeIBGE: 'Mineiros' },
    { codigoTOM: 9465, codigoIBGE: 5213400, nomeTOM: 'MOIPORÁ', nomeIBGE: 'Moiporá' },
    { codigoTOM: 9467, codigoIBGE: 5213509, nomeTOM: 'MONTE ALEGRE DE GOIÁS', nomeIBGE: 'Monte Alegre de Goiás' },
    { codigoTOM: 9471, codigoIBGE: 5213707, nomeTOM: 'MONTES CLAROS DE GOIÁS', nomeIBGE: 'Montes Claros de Goiás' },
    { codigoTOM: 9473, codigoIBGE: 5213806, nomeTOM: 'MORRINHOS', nomeIBGE: 'Morrinhos' },
    { codigoTOM: 9475, codigoIBGE: 5213905, nomeTOM: 'MOSSÂMEDES', nomeIBGE: 'Mossâmedes' },
    { codigoTOM: 9477, codigoIBGE: 5214002, nomeTOM: 'MOZARLÂNDIA', nomeIBGE: 'Mozarlândia' },
    { codigoTOM: 9479, codigoIBGE: 5214101, nomeTOM: 'MUTUNÓPOLIS', nomeIBGE: 'Mutunópolis' },
    { codigoTOM: 9485, codigoIBGE: 5214408, nomeTOM: 'NAZÁRIO', nomeIBGE: 'Nazário' },
    { codigoTOM: 9487, codigoIBGE: 5214507, nomeTOM: 'NERÓPOLIS', nomeIBGE: 'Nerópolis' },
    { codigoTOM: 9489, codigoIBGE: 5214606, nomeTOM: 'NIQUELÂNDIA', nomeIBGE: 'Niquelândia' },
    { codigoTOM: 9491, codigoIBGE: 5214705, nomeTOM: 'NOVA AMÉRICA', nomeIBGE: 'Nova América' },
    { codigoTOM: 9493, codigoIBGE: 5214804, nomeTOM: 'NOVA AURORA', nomeIBGE: 'Nova Aurora' },
    { codigoTOM: 9495, codigoIBGE: 5214903, nomeTOM: 'NOVA ROMA', nomeIBGE: 'Nova Roma' },
    { codigoTOM: 9497, codigoIBGE: 5215009, nomeTOM: 'NOVA VENEZA', nomeIBGE: 'Nova Veneza' },
    { codigoTOM: 9501, codigoIBGE: 5215207, nomeTOM: 'NOVO BRASIL', nomeIBGE: 'Novo Brasil' },
    { codigoTOM: 9503, codigoIBGE: 5215306, nomeTOM: 'ORIZONA', nomeIBGE: 'Orizona' },
    { codigoTOM: 9505, codigoIBGE: 5215405, nomeTOM: 'OURO VERDE DE GOIÁS', nomeIBGE: 'Ouro Verde de Goiás' },
    { codigoTOM: 9507, codigoIBGE: 5215504, nomeTOM: 'OUVIDOR', nomeIBGE: 'Ouvidor' },
    { codigoTOM: 9509, codigoIBGE: 5215603, nomeTOM: 'PADRE BERNARDO', nomeIBGE: 'Padre Bernardo' },
    { codigoTOM: 9511, codigoIBGE: 5215702, nomeTOM: 'PALMEIRAS DE GOIÁS', nomeIBGE: 'Palmeiras de Goiás' },
    { codigoTOM: 9513, codigoIBGE: 5215801, nomeTOM: 'PALMELO', nomeIBGE: 'Palmelo' },
    { codigoTOM: 9515, codigoIBGE: 5215900, nomeTOM: 'PALMINÓPOLIS', nomeIBGE: 'Palminópolis' },
    { codigoTOM: 9517, codigoIBGE: 5216007, nomeTOM: 'PANAMÁ', nomeIBGE: 'Panamá' },
    { codigoTOM: 9523, codigoIBGE: 5216403, nomeTOM: 'PARAÚNA', nomeIBGE: 'Paraúna' },
    { codigoTOM: 9531, codigoIBGE: 5216809, nomeTOM: 'PETROLINA DE GOIÁS', nomeIBGE: 'Petrolina de Goiás' },
    { codigoTOM: 9535, codigoIBGE: 5216908, nomeTOM: 'PILAR DE GOIÁS', nomeIBGE: 'Pilar de Goiás' },
    { codigoTOM: 9539, codigoIBGE: 5217104, nomeTOM: 'PIRACANJUBA', nomeIBGE: 'Piracanjuba' },
    { codigoTOM: 9541, codigoIBGE: 5217203, nomeTOM: 'PIRANHAS', nomeIBGE: 'Piranhas' },
    { codigoTOM: 9543, codigoIBGE: 5217302, nomeTOM: 'PIRENÓPOLIS', nomeIBGE: 'Pirenópolis' },
    { codigoTOM: 9545, codigoIBGE: 5217401, nomeTOM: 'PIRES DO RIO', nomeIBGE: 'Pires do Rio' },
    { codigoTOM: 9549, codigoIBGE: 5217708, nomeTOM: 'PONTALINA', nomeIBGE: 'Pontalina' },
    { codigoTOM: 9555, codigoIBGE: 5218003, nomeTOM: 'PORANGATU', nomeIBGE: 'Porangatu' },
    { codigoTOM: 9557, codigoIBGE: 5218102, nomeTOM: 'PORTELÂNDIA', nomeIBGE: 'Portelândia' },
    { codigoTOM: 9561, codigoIBGE: 5218300, nomeTOM: 'POSSE', nomeIBGE: 'Posse' },
    { codigoTOM: 9563, codigoIBGE: 5218508, nomeTOM: 'QUIRINÓPOLIS', nomeIBGE: 'Quirinópolis' },
    { codigoTOM: 9565, codigoIBGE: 5218607, nomeTOM: 'RIALMA', nomeIBGE: 'Rialma' },
    { codigoTOM: 9567, codigoIBGE: 5218706, nomeTOM: 'RIANÁPOLIS', nomeIBGE: 'Rianápolis' },
    { codigoTOM: 1070, codigoIBGE: 5204854, nomeTOM: 'CAMPO LIMPO DE GOIÁS', nomeIBGE: 'Campo Limpo de Goiás' },
    { codigoTOM: 1072, codigoIBGE: 5208152, nomeTOM: 'GAMELEIRA DE GOIÁS', nomeIBGE: 'Gameleira de Goiás' },
    { codigoTOM: 1074, codigoIBGE: 5210158, nomeTOM: 'IPIRANGA DE GOIÁS', nomeIBGE: 'Ipiranga de Goiás' },
    { codigoTOM: 1076, codigoIBGE: 5212253, nomeTOM: 'LAGOA SANTA', nomeIBGE: 'Lagoa Santa' },
    { codigoTOM: 9201, codigoIBGE: 5200100, nomeTOM: 'ABADIÂNIA', nomeIBGE: 'Abadiânia' },
    { codigoTOM: 9203, codigoIBGE: 5200209, nomeTOM: 'ÁGUA LIMPA', nomeIBGE: 'Água Limpa' },
    { codigoTOM: 9205, codigoIBGE: 5200308, nomeTOM: 'ALEXÂNIA', nomeIBGE: 'Alexânia' },
    { codigoTOM: 9209, codigoIBGE: 5200506, nomeTOM: 'ALOÂNDIA', nomeIBGE: 'Aloândia' },
    { codigoTOM: 9211, codigoIBGE: 5200605, nomeTOM: 'ALTO PARAÍSO DE GOIÁS', nomeIBGE: 'Alto Paraíso de Goiás' },
    { codigoTOM: 9215, codigoIBGE: 5200803, nomeTOM: 'ALVORADA DO NORTE', nomeIBGE: 'Alvorada do Norte' },
    { codigoTOM: 9217, codigoIBGE: 5200902, nomeTOM: 'AMORINÓPOLIS', nomeIBGE: 'Amorinópolis' },
    { codigoTOM: 9221, codigoIBGE: 5201108, nomeTOM: 'ANÁPOLIS', nomeIBGE: 'Anápolis' },
    { codigoTOM: 9223, codigoIBGE: 5201207, nomeTOM: 'ANHANGUERA', nomeIBGE: 'Anhanguera' },
    { codigoTOM: 9225, codigoIBGE: 5201306, nomeTOM: 'ANICUNS', nomeIBGE: 'Anicuns' },
    { codigoTOM: 9227, codigoIBGE: 5201405, nomeTOM: 'APARECIDA DE GOIÂNIA', nomeIBGE: 'Aparecida de Goiânia' },
    { codigoTOM: 9229, codigoIBGE: 5201504, nomeTOM: 'APORÉ', nomeIBGE: 'Aporé' },
    { codigoTOM: 9231, codigoIBGE: 5201603, nomeTOM: 'ARAÇU', nomeIBGE: 'Araçu' },
    { codigoTOM: 9233, codigoIBGE: 5201702, nomeTOM: 'ARAGARÇAS', nomeIBGE: 'Aragarças' },
    { codigoTOM: 9235, codigoIBGE: 5201801, nomeTOM: 'ARAGOIÂNIA', nomeIBGE: 'Aragoiânia' },
    { codigoTOM: 9249, codigoIBGE: 5202502, nomeTOM: 'ARUANÃ', nomeIBGE: 'Aruanã' },
    { codigoTOM: 9251, codigoIBGE: 5202601, nomeTOM: 'AURILÂNDIA', nomeIBGE: 'Aurilândia' },
    { codigoTOM: 9255, codigoIBGE: 5202809, nomeTOM: 'AVELINÓPOLIS', nomeIBGE: 'Avelinópolis' },
    { codigoTOM: 9261, codigoIBGE: 5203104, nomeTOM: 'BALIZA', nomeIBGE: 'Baliza' },
    { codigoTOM: 9263, codigoIBGE: 5203203, nomeTOM: 'BARRO ALTO', nomeIBGE: 'Barro Alto' },
    { codigoTOM: 9265, codigoIBGE: 5203302, nomeTOM: 'BELA VISTA DE GOIÁS', nomeIBGE: 'Bela Vista de Goiás' },
    { codigoTOM: 9267, codigoIBGE: 5203401, nomeTOM: 'BOM JARDIM DE GOIÁS', nomeIBGE: 'Bom Jardim de Goiás' },
    { codigoTOM: 9269, codigoIBGE: 5203500, nomeTOM: 'BOM JESUS', nomeIBGE: 'Bom Jesus de Goiás' },
    { codigoTOM: 9271, codigoIBGE: 5203609, nomeTOM: 'BRAZABRANTES', nomeIBGE: 'Brazabrantes' },
    { codigoTOM: 9275, codigoIBGE: 5203807, nomeTOM: 'BRITÂNIA', nomeIBGE: 'Britânia' },
    { codigoTOM: 9277, codigoIBGE: 5203906, nomeTOM: 'BURITI ALEGRE', nomeIBGE: 'Buriti Alegre' },
    { codigoTOM: 9279, codigoIBGE: 5204003, nomeTOM: 'CABECEIRAS', nomeIBGE: 'Cabeceiras' },
    { codigoTOM: 9281, codigoIBGE: 5204102, nomeTOM: 'CACHOEIRA ALTA', nomeIBGE: 'Cachoeira Alta' },
    { codigoTOM: 9283, codigoIBGE: 5204201, nomeTOM: 'CACHOEIRA DE GOIÁS', nomeIBGE: 'Cachoeira de Goiás' },
    { codigoTOM: 9285, codigoIBGE: 5204300, nomeTOM: 'CAÇU', nomeIBGE: 'Caçu' },
    { codigoTOM: 9287, codigoIBGE: 5204409, nomeTOM: 'CAIAPÔNIA', nomeIBGE: 'Caiapônia' },
    { codigoTOM: 9289, codigoIBGE: 5204508, nomeTOM: 'CALDAS NOVAS', nomeIBGE: 'Caldas Novas' },
    { codigoTOM: 9291, codigoIBGE: 5204607, nomeTOM: 'CAMPESTRE DE GOIÁS', nomeIBGE: 'Campestre de Goiás' },
    { codigoTOM: 9293, codigoIBGE: 5204706, nomeTOM: 'CAMPINORTE', nomeIBGE: 'Campinorte' },
    { codigoTOM: 9295, codigoIBGE: 5204805, nomeTOM: 'CAMPO ALEGRE DE GOIÁS', nomeIBGE: 'Campo Alegre de Goiás' },
    { codigoTOM: 9297, codigoIBGE: 5204904, nomeTOM: 'CAMPOS BELOS', nomeIBGE: 'Campos Belos' },
    { codigoTOM: 9299, codigoIBGE: 5205000, nomeTOM: 'CARMO DO RIO VERDE', nomeIBGE: 'Carmo do Rio Verde' },
    { codigoTOM: 9301, codigoIBGE: 5205109, nomeTOM: 'CATALÃO', nomeIBGE: 'Catalão' },
    { codigoTOM: 9303, codigoIBGE: 5205208, nomeTOM: 'CATURAÍ', nomeIBGE: 'Caturaí' },
    { codigoTOM: 9305, codigoIBGE: 5205307, nomeTOM: 'CAVALCANTE', nomeIBGE: 'Cavalcante' },
    { codigoTOM: 9307, codigoIBGE: 5205406, nomeTOM: 'CERES', nomeIBGE: 'Ceres' },
    { codigoTOM: 9309, codigoIBGE: 5208301, nomeTOM: 'DIVINÓPOLIS DE GOIÁS', nomeIBGE: 'Divinópolis de Goiás' },
    { codigoTOM: 9315, codigoIBGE: 5205703, nomeTOM: 'CÓRREGO DO OURO', nomeIBGE: 'Córrego do Ouro' },
    { codigoTOM: 9317, codigoIBGE: 5205802, nomeTOM: 'CORUMBÁ DE GOIÁS', nomeIBGE: 'Corumbá de Goiás' },
    { codigoTOM: 9319, codigoIBGE: 5205901, nomeTOM: 'CORUMBAÍBA', nomeIBGE: 'Corumbaíba' },
    { codigoTOM: 9325, codigoIBGE: 5206206, nomeTOM: 'CRISTALINA', nomeIBGE: 'Cristalina' },
    { codigoTOM: 9327, codigoIBGE: 5206305, nomeTOM: 'CRISTIANÓPOLIS', nomeIBGE: 'Cristianópolis' },
    { codigoTOM: 9329, codigoIBGE: 5206404, nomeTOM: 'CRIXÁS', nomeIBGE: 'Crixás' },
    { codigoTOM: 9331, codigoIBGE: 5206503, nomeTOM: 'CROMÍNIA', nomeIBGE: 'Cromínia' },
    { codigoTOM: 9333, codigoIBGE: 5206602, nomeTOM: 'CUMARI', nomeIBGE: 'Cumari' },
    { codigoTOM: 9335, codigoIBGE: 5206701, nomeTOM: 'DAMIANÓPOLIS', nomeIBGE: 'Damianópolis' },
    { codigoTOM: 9337, codigoIBGE: 5206800, nomeTOM: 'DAMOLÂNDIA', nomeIBGE: 'Damolândia' },
    { codigoTOM: 9339, codigoIBGE: 5206909, nomeTOM: 'DAVINÓPOLIS', nomeIBGE: 'Davinópolis' },
    { codigoTOM: 9343, codigoIBGE: 5207105, nomeTOM: 'DIORAMA', nomeIBGE: 'Diorama' },
    { codigoTOM: 9349, codigoIBGE: 5207402, nomeTOM: 'EDÉIA', nomeIBGE: 'Edéia' },
    { codigoTOM: 9351, codigoIBGE: 5207501, nomeTOM: 'ESTRELA DO NORTE', nomeIBGE: 'Estrela do Norte' },
    { codigoTOM: 9353, codigoIBGE: 5207600, nomeTOM: 'FAZENDA NOVA', nomeIBGE: 'Fazenda Nova' },
    { codigoTOM: 9357, codigoIBGE: 5207808, nomeTOM: 'FIRMINÓPOLIS', nomeIBGE: 'Firminópolis' },
    { codigoTOM: 9571, codigoIBGE: 5218805, nomeTOM: 'RIO VERDE', nomeIBGE: 'Rio Verde' },
    { codigoTOM: 9573, codigoIBGE: 5218904, nomeTOM: 'RUBIATABA', nomeIBGE: 'Rubiataba' },
    { codigoTOM: 9575, codigoIBGE: 5219001, nomeTOM: 'SANCLERLÂNDIA', nomeIBGE: 'Sanclerlândia' },
    { codigoTOM: 9577, codigoIBGE: 5219100, nomeTOM: 'SANTA BÁRBARA DE GOIÁS', nomeIBGE: 'Santa Bárbara de Goiás' },
    { codigoTOM: 9579, codigoIBGE: 5219209, nomeTOM: 'SANTA CRUZ DE GOIÁS', nomeIBGE: 'Santa Cruz de Goiás' },
    { codigoTOM: 9581, codigoIBGE: 5219308, nomeTOM: 'SANTA HELENA DE GOIÁS', nomeIBGE: 'Santa Helena de Goiás' },
    { codigoTOM: 9583, codigoIBGE: 5219407, nomeTOM: 'SANTA RITA DO ARAGUAIA', nomeIBGE: 'Santa Rita do Araguaia' },
    { codigoTOM: 9585, codigoIBGE: 5219506, nomeTOM: 'SANTA ROSA DE GOIÁS', nomeIBGE: 'Santa Rosa de Goiás' },
    { codigoTOM: 9587, codigoIBGE: 5219605, nomeTOM: 'SANTA TEREZA DE GOIÁS', nomeIBGE: 'Santa Tereza de Goiás' },
    { codigoTOM: 9589, codigoIBGE: 5219704, nomeTOM: 'SANTA TEREZINHA DE GOIÁS', nomeIBGE: 'Santa Terezinha de Goiás' },
    { codigoTOM: 9591, codigoIBGE: 5219803, nomeTOM: 'SÃO DOMINGOS', nomeIBGE: 'São Domingos' },
    { codigoTOM: 9593, codigoIBGE: 5219902, nomeTOM: 'SÃO FRANCISCO DE GOIÁS', nomeIBGE: 'São Francisco de Goiás' },
    { codigoTOM: 9595, codigoIBGE: 5217609, nomeTOM: 'PLANALTINA', nomeIBGE: 'Planaltina' },
    { codigoTOM: 9597, codigoIBGE: 5220009, nomeTOM: "SÃO JOÃO D'ALIANÇA", nomeIBGE: "São João d'Aliança" },
    { codigoTOM: 9599, codigoIBGE: 5220108, nomeTOM: 'SÃO LUÍS DE MONTES BELOS', nomeIBGE: 'São Luís de Montes Belos' },
    { codigoTOM: 9601, codigoIBGE: 5220207, nomeTOM: 'SÃO MIGUEL DO ARAGUAIA', nomeIBGE: 'São Miguel do Araguaia' },
    { codigoTOM: 9605, codigoIBGE: 5220405, nomeTOM: 'SÃO SIMÃO', nomeIBGE: 'São Simão' },
    { codigoTOM: 9607, codigoIBGE: 5220504, nomeTOM: 'SERRANÓPOLIS', nomeIBGE: 'Serranópolis' },
    { codigoTOM: 9609, codigoIBGE: 5220603, nomeTOM: 'SILVÂNIA', nomeIBGE: 'Silvânia' },
    { codigoTOM: 9611, codigoIBGE: 5220702, nomeTOM: "SÍTIO D'ABADIA", nomeIBGE: "Sítio d'Abadia" },
    { codigoTOM: 9617, codigoIBGE: 5221007, nomeTOM: 'TAQUARAL DE GOIÁS', nomeIBGE: 'Taquaral de Goiás' },
    { codigoTOM: 9623, codigoIBGE: 5221304, nomeTOM: 'TRÊS RANCHOS', nomeIBGE: 'Três Ranchos' },
    { codigoTOM: 9625, codigoIBGE: 5221403, nomeTOM: 'TRINDADE', nomeIBGE: 'Trindade' },
    { codigoTOM: 9631, codigoIBGE: 5221502, nomeTOM: 'TURVÂNIA', nomeIBGE: 'Turvânia' },
    { codigoTOM: 9633, codigoIBGE: 5221601, nomeTOM: 'URUAÇU', nomeIBGE: 'Uruaçu' },
    { codigoTOM: 9635, codigoIBGE: 5221700, nomeTOM: 'URUANA', nomeIBGE: 'Uruana' },
    { codigoTOM: 9637, codigoIBGE: 5221809, nomeTOM: 'URUTAÍ', nomeIBGE: 'Urutaí' },
    { codigoTOM: 9639, codigoIBGE: 5221908, nomeTOM: 'VARJÃO', nomeIBGE: 'Varjão' },
    { codigoTOM: 9641, codigoIBGE: 5222005, nomeTOM: 'VIANÓPOLIS', nomeIBGE: 'Vianópolis' },
    { codigoTOM: 9645, codigoIBGE: 5200134, nomeTOM: 'ACREÚNA', nomeIBGE: 'Acreúna' },
    { codigoTOM: 9647, codigoIBGE: 5213087, nomeTOM: 'MINAÇU', nomeIBGE: 'Minaçu' },
    { codigoTOM: 9651, codigoIBGE: 5214051, nomeTOM: 'MUNDO NOVO', nomeIBGE: 'Mundo Novo' },
    { codigoTOM: 9653, codigoIBGE: 5214838, nomeTOM: 'NOVA CRIXÁS', nomeIBGE: 'Nova Crixás' },
    { codigoTOM: 9655, codigoIBGE: 5214861, nomeTOM: 'NOVA GLÓRIA', nomeIBGE: 'Nova Glória' },
    { codigoTOM: 9657, codigoIBGE: 5222054, nomeTOM: 'VICENTINÓPOLIS', nomeIBGE: 'Vicentinópolis' },
    { codigoTOM: 9661, codigoIBGE: 5200852, nomeTOM: 'AMERICANO DO BRASIL', nomeIBGE: 'Americano do Brasil' },
    { codigoTOM: 9669, codigoIBGE: 5202155, nomeTOM: 'ARAGUAPAZ', nomeIBGE: 'Araguapaz' },
    { codigoTOM: 9671, codigoIBGE: 5202353, nomeTOM: 'ARENÓPOLIS', nomeIBGE: 'Arenópolis' },
    { codigoTOM: 9673, codigoIBGE: 5204250, nomeTOM: 'CACHOEIRA DOURADA', nomeIBGE: 'Cachoeira Dourada' },
    { codigoTOM: 9675, codigoIBGE: 5207253, nomeTOM: 'DOVERLÂNDIA', nomeIBGE: 'Doverlândia' },
    {
      codigoTOM: 9677,
      codigoIBGE: 5219753,
      nomeTOM: 'SANTO ANTÔNIO DO DESCOBERTO',
      nomeIBGE: 'Santo Antônio do Descoberto',
    },
    { codigoTOM: 9681, codigoIBGE: 5209952, nomeTOM: 'INDIARA', nomeIBGE: 'Indiara' },
    { codigoTOM: 9687, codigoIBGE: 5204656, nomeTOM: 'CAMPINAÇU', nomeIBGE: 'Campinaçu' },
    { codigoTOM: 9689, codigoIBGE: 5219357, nomeTOM: 'SANTA ISABEL', nomeIBGE: 'Santa Isabel' },
    { codigoTOM: 9735, codigoIBGE: 5215256, nomeTOM: 'NOVO PLANALTO', nomeIBGE: 'Novo Planalto' },
    { codigoTOM: 9737, codigoIBGE: 5215652, nomeTOM: 'PALESTINA DE GOIÁS', nomeIBGE: 'Palestina de Goiás' },
    { codigoTOM: 9743, codigoIBGE: 5219258, nomeTOM: 'SANTA FÉ DE GOIÁS', nomeIBGE: 'Santa Fé de Goiás' },
    { codigoTOM: 9747, codigoIBGE: 5220058, nomeTOM: 'SÃO JOÃO DA PARAÚNA', nomeIBGE: 'São João da Paraúna' },
    { codigoTOM: 9749, codigoIBGE: 5220157, nomeTOM: 'SÃO LUIZ DO NORTE', nomeIBGE: 'São Luiz do Norte' },
    {
      codigoTOM: 9751,
      codigoIBGE: 5220264,
      nomeTOM: 'SÃO MIGUEL DO PASSA QUATRO',
      nomeIBGE: 'São Miguel do Passa Quatro',
    },
    { codigoTOM: 9753, codigoIBGE: 5220454, nomeTOM: 'SENADOR CANEDO', nomeIBGE: 'Senador Canedo' },
    { codigoTOM: 9755, codigoIBGE: 5220686, nomeTOM: 'SIMOLÂNDIA', nomeIBGE: 'Simolândia' },
    { codigoTOM: 9759, codigoIBGE: 5221080, nomeTOM: 'TERESINA DE GOIÁS', nomeIBGE: 'Teresina de Goiás' },
    { codigoTOM: 9761, codigoIBGE: 5221452, nomeTOM: 'TROMBAS', nomeIBGE: 'Trombas' },
    { codigoTOM: 9765, codigoIBGE: 5221551, nomeTOM: 'TURVELÂNDIA', nomeIBGE: 'Turvelândia' },
    { codigoTOM: 9769, codigoIBGE: 5200159, nomeTOM: 'ADELÂNDIA', nomeIBGE: 'Adelândia' },
    { codigoTOM: 9771, codigoIBGE: 5200175, nomeTOM: 'ÁGUA FRIA DE GOIÁS', nomeIBGE: 'Água Fria de Goiás' },
    { codigoTOM: 9775, codigoIBGE: 5203559, nomeTOM: 'BONFINÓPOLIS', nomeIBGE: 'Bonfinópolis' },
    { codigoTOM: 9781, codigoIBGE: 5204953, nomeTOM: 'CAMPOS VERDES', nomeIBGE: 'Campos Verdes' },
    { codigoTOM: 9785, codigoIBGE: 5205455, nomeTOM: 'CEZARINA', nomeIBGE: 'Cezarina' },
    { codigoTOM: 9791, codigoIBGE: 5205521, nomeTOM: 'COLINAS DO SUL', nomeIBGE: 'Colinas do Sul' },
    { codigoTOM: 9795, codigoIBGE: 5207352, nomeTOM: 'EDEALINA', nomeIBGE: 'Edealina' },
    { codigoTOM: 9797, codigoIBGE: 5207535, nomeTOM: 'FAINA', nomeIBGE: 'Faina' },
    { codigoTOM: 9799, codigoIBGE: 5209150, nomeTOM: 'GOUVELÂNDIA', nomeIBGE: 'Gouvelândia' },
    { codigoTOM: 9919, codigoIBGE: 5210562, nomeTOM: 'ITAGUARI', nomeIBGE: 'Itaguari' },
    { codigoTOM: 9927, codigoIBGE: 5212956, nomeTOM: 'MATRINCHÃ', nomeIBGE: 'Matrinchã' },
    { codigoTOM: 9931, codigoIBGE: 5213053, nomeTOM: 'MIMOSO DE GOIÁS', nomeIBGE: 'Mimoso de Goiás' },
    { codigoTOM: 9933, codigoIBGE: 5213756, nomeTOM: 'MONTIVIDIU', nomeIBGE: 'Montividiu' },
    { codigoTOM: 9935, codigoIBGE: 5213855, nomeTOM: 'MORRO AGUDO DE GOIÁS', nomeIBGE: 'Morro Agudo de Goiás' },
    { codigoTOM: 9993, codigoIBGE: 5209457, nomeTOM: 'GUARINOS', nomeIBGE: 'Guarinos' },
    { codigoTOM: 9995, codigoIBGE: 5218789, nomeTOM: 'RIO QUENTE', nomeIBGE: 'Rio Quente' },
  ],
  [Estados.PA]: [
    { codigoTOM: 40, codigoIBGE: 1500859, nomeTOM: 'ANAPU', nomeIBGE: 'Anapu' },
    { codigoTOM: 42, codigoIBGE: 1501253, nomeTOM: 'BANNACH', nomeIBGE: 'Bannach' },
    { codigoTOM: 44, codigoIBGE: 1501451, nomeTOM: 'BELTERRA', nomeIBGE: 'Belterra' },
    { codigoTOM: 46, codigoIBGE: 1501956, nomeTOM: 'CACHOEIRA DO PIRIÁ', nomeIBGE: 'Cachoeira do Piriá' },
    { codigoTOM: 48, codigoIBGE: 1502152, nomeTOM: 'CANAÃ DOS CARAJÁS', nomeIBGE: 'Canaã dos Carajás' },
    { codigoTOM: 50, codigoIBGE: 1502855, nomeTOM: 'CURUÁ', nomeIBGE: 'Curuá' },
    { codigoTOM: 52, codigoIBGE: 1503044, nomeTOM: 'FLORESTA DO ARAGUAIA', nomeIBGE: 'Floresta do Araguaia' },
    { codigoTOM: 54, codigoIBGE: 1504422, nomeTOM: 'MARITUBA', nomeIBGE: 'Marituba' },
    { codigoTOM: 56, codigoIBGE: 1504976, nomeTOM: 'NOVA IPIXUNA', nomeIBGE: 'Nova Ipixuna' },
    { codigoTOM: 58, codigoIBGE: 1505635, nomeTOM: 'PIÇARRA', nomeIBGE: 'Piçarra' },
    { codigoTOM: 60, codigoIBGE: 1505650, nomeTOM: 'PLACAS', nomeIBGE: 'Placas' },
    { codigoTOM: 62, codigoIBGE: 1506112, nomeTOM: 'QUATIPURU', nomeIBGE: 'Quatipuru' },
    { codigoTOM: 64, codigoIBGE: 1507466, nomeTOM: 'SÃO JOÃO DA PONTA', nomeIBGE: 'São João da Ponta' },
    { codigoTOM: 66, codigoIBGE: 1507755, nomeTOM: 'SAPUCAIA', nomeIBGE: 'Sapucaia' },
    { codigoTOM: 68, codigoIBGE: 1508035, nomeTOM: 'TRACUATEUA', nomeIBGE: 'Tracuateua' },
    { codigoTOM: 369, codigoIBGE: 1506351, nomeTOM: 'SANTA BÁRBARA DO PARÁ', nomeIBGE: 'Santa Bárbara do Pará' },
    { codigoTOM: 371, codigoIBGE: 1506559, nomeTOM: 'SANTA LUZIA DO PARÁ', nomeIBGE: 'Santa Luzia do Pará' },
    { codigoTOM: 373, codigoIBGE: 1507961, nomeTOM: 'TERRA ALTA', nomeIBGE: 'Terra Alta' },
    { codigoTOM: 375, codigoIBGE: 1500131, nomeTOM: 'ABEL FIGUEIREDO', nomeIBGE: 'Abel Figueiredo' },
    { codigoTOM: 377, codigoIBGE: 1502954, nomeTOM: 'ELDORADO DOS CARAJÁS', nomeIBGE: 'Eldorado do Carajás' },
    { codigoTOM: 379, codigoIBGE: 1505494, nomeTOM: 'PALESTINA DO PARÁ', nomeIBGE: 'Palestina do Pará' },
    { codigoTOM: 381, codigoIBGE: 1507151, nomeTOM: 'SÃO DOMINGOS DO ARAGUAIA', nomeIBGE: 'São Domingos do Araguaia' },
    { codigoTOM: 383, codigoIBGE: 1500347, nomeTOM: 'ÁGUA AZUL DO NORTE', nomeIBGE: 'Água Azul do Norte' },
    { codigoTOM: 385, codigoIBGE: 1502764, nomeTOM: 'CUMARU DO NORTE', nomeIBGE: 'Cumaru do Norte' },
    { codigoTOM: 387, codigoIBGE: 1505551, nomeTOM: "PAU D'ARCO", nomeIBGE: "Pau D'Arco" },
    { codigoTOM: 389, codigoIBGE: 1500958, nomeTOM: 'AURORA DO PARÁ', nomeIBGE: 'Aurora do Pará' },
    { codigoTOM: 391, codigoIBGE: 1504950, nomeTOM: 'NOVA ESPERANÇA DO PIRIÁ', nomeIBGE: 'Nova Esperança do Piriá' },
    { codigoTOM: 393, codigoIBGE: 1507474, nomeTOM: 'SÃO JOÃO DE PIRABAS', nomeIBGE: 'São João de Pirabas' },
    { codigoTOM: 395, codigoIBGE: 1507953, nomeTOM: 'TAILÂNDIA', nomeIBGE: 'Tailândia' },
    { codigoTOM: 397, codigoIBGE: 1508084, nomeTOM: 'TUCUMÃ', nomeIBGE: 'Tucumã' },
    { codigoTOM: 399, codigoIBGE: 1508159, nomeTOM: 'URUARÁ', nomeIBGE: 'Uruará' },
    { codigoTOM: 401, codigoIBGE: 1500107, nomeTOM: 'ABAETETUBA', nomeIBGE: 'Abaetetuba' },
    { codigoTOM: 403, codigoIBGE: 1500206, nomeTOM: 'ACARÁ', nomeIBGE: 'Acará' },
    { codigoTOM: 405, codigoIBGE: 1500305, nomeTOM: 'AFUÁ', nomeIBGE: 'Afuá' },
    { codigoTOM: 407, codigoIBGE: 1500404, nomeTOM: 'ALENQUER', nomeIBGE: 'Alenquer' },
    { codigoTOM: 409, codigoIBGE: 1500503, nomeTOM: 'ALMEIRIM', nomeIBGE: 'Almeirim' },
    { codigoTOM: 411, codigoIBGE: 1500602, nomeTOM: 'ALTAMIRA', nomeIBGE: 'Altamira' },
    { codigoTOM: 413, codigoIBGE: 1500701, nomeTOM: 'ANAJÁS', nomeIBGE: 'Anajás' },
    { codigoTOM: 415, codigoIBGE: 1500800, nomeTOM: 'ANANINDEUA', nomeIBGE: 'Ananindeua' },
    { codigoTOM: 417, codigoIBGE: 1500909, nomeTOM: 'AUGUSTO CORRÊA', nomeIBGE: 'Augusto Corrêa' },
    { codigoTOM: 419, codigoIBGE: 1501006, nomeTOM: 'AVEIRO', nomeIBGE: 'Aveiro' },
    { codigoTOM: 421, codigoIBGE: 1501105, nomeTOM: 'BAGRE', nomeIBGE: 'Bagre' },
    { codigoTOM: 423, codigoIBGE: 1501204, nomeTOM: 'BAIÃO', nomeIBGE: 'Baião' },
    { codigoTOM: 425, codigoIBGE: 1501303, nomeTOM: 'BARCARENA', nomeIBGE: 'Barcarena' },
    { codigoTOM: 427, codigoIBGE: 1501402, nomeTOM: 'BELÉM', nomeIBGE: 'Belém' },
    { codigoTOM: 429, codigoIBGE: 1501501, nomeTOM: 'BENEVIDES', nomeIBGE: 'Benevides' },
    { codigoTOM: 431, codigoIBGE: 1501600, nomeTOM: 'BONITO', nomeIBGE: 'Bonito' },
    { codigoTOM: 433, codigoIBGE: 1501709, nomeTOM: 'BRAGANÇA', nomeIBGE: 'Bragança' },
    { codigoTOM: 435, codigoIBGE: 1501808, nomeTOM: 'BREVES', nomeIBGE: 'Breves' },
    { codigoTOM: 437, codigoIBGE: 1501907, nomeTOM: 'BUJARU', nomeIBGE: 'Bujaru' },
    { codigoTOM: 439, codigoIBGE: 1502004, nomeTOM: 'CACHOEIRA DO ARARI', nomeIBGE: 'Cachoeira do Arari' },
    { codigoTOM: 441, codigoIBGE: 1502103, nomeTOM: 'CAMETÁ', nomeIBGE: 'Cametá' },
    { codigoTOM: 443, codigoIBGE: 1502202, nomeTOM: 'CAPANEMA', nomeIBGE: 'Capanema' },
    { codigoTOM: 445, codigoIBGE: 1502301, nomeTOM: 'CAPITÃO POÇO', nomeIBGE: 'Capitão Poço' },
    { codigoTOM: 447, codigoIBGE: 1502400, nomeTOM: 'CASTANHAL', nomeIBGE: 'Castanhal' },
    { codigoTOM: 449, codigoIBGE: 1502509, nomeTOM: 'CHAVES', nomeIBGE: 'Chaves' },
    { codigoTOM: 451, codigoIBGE: 1502608, nomeTOM: 'COLARES', nomeIBGE: 'Colares' },
    { codigoTOM: 453, codigoIBGE: 1502707, nomeTOM: 'CONCEIÇÃO DO ARAGUAIA', nomeIBGE: 'Conceição do Araguaia' },
    { codigoTOM: 455, codigoIBGE: 1502806, nomeTOM: 'CURRALINHO', nomeIBGE: 'Curralinho' },
    { codigoTOM: 457, codigoIBGE: 1502905, nomeTOM: 'CURUÇÁ', nomeIBGE: 'Curuçá' },
    { codigoTOM: 459, codigoIBGE: 1503002, nomeTOM: 'FARO', nomeIBGE: 'Faro' },
    { codigoTOM: 461, codigoIBGE: 1503101, nomeTOM: 'GURUPÁ', nomeIBGE: 'Gurupá' },
    { codigoTOM: 463, codigoIBGE: 1503200, nomeTOM: 'IGARAPÉ-AÇU', nomeIBGE: 'Igarapé-Açu' },
    { codigoTOM: 465, codigoIBGE: 1503309, nomeTOM: 'IGARAPÉ-MIRI', nomeIBGE: 'Igarapé-Miri' },
    { codigoTOM: 467, codigoIBGE: 1503408, nomeTOM: 'INHANGAPI', nomeIBGE: 'Inhangapi' },
    { codigoTOM: 469, codigoIBGE: 1503507, nomeTOM: 'IRITUIA', nomeIBGE: 'Irituia' },
    { codigoTOM: 471, codigoIBGE: 1503606, nomeTOM: 'ITAITUBA', nomeIBGE: 'Itaituba' },
    { codigoTOM: 473, codigoIBGE: 1503705, nomeTOM: 'ITUPIRANGA', nomeIBGE: 'Itupiranga' },
    { codigoTOM: 475, codigoIBGE: 1503804, nomeTOM: 'JACUNDÁ', nomeIBGE: 'Jacundá' },
    { codigoTOM: 477, codigoIBGE: 1503903, nomeTOM: 'JURUTI', nomeIBGE: 'Juruti' },
    { codigoTOM: 479, codigoIBGE: 1504000, nomeTOM: 'LIMOEIRO DO AJURU', nomeIBGE: 'Limoeiro do Ajuru' },
    { codigoTOM: 481, codigoIBGE: 1504109, nomeTOM: 'MAGALHÃES BARATA', nomeIBGE: 'Magalhães Barata' },
    { codigoTOM: 483, codigoIBGE: 1504208, nomeTOM: 'MARABÁ', nomeIBGE: 'Marabá' },
    { codigoTOM: 485, codigoIBGE: 1504307, nomeTOM: 'MARACANÃ', nomeIBGE: 'Maracanã' },
    { codigoTOM: 487, codigoIBGE: 1504406, nomeTOM: 'MARAPANIM', nomeIBGE: 'Marapanim' },
    { codigoTOM: 489, codigoIBGE: 1504505, nomeTOM: 'MELGAÇO', nomeIBGE: 'Melgaço' },
    { codigoTOM: 491, codigoIBGE: 1504604, nomeTOM: 'MOCAJUBA', nomeIBGE: 'Mocajuba' },
    { codigoTOM: 493, codigoIBGE: 1504703, nomeTOM: 'MOJU', nomeIBGE: 'Moju' },
    { codigoTOM: 495, codigoIBGE: 1504802, nomeTOM: 'MONTE ALEGRE', nomeIBGE: 'Monte Alegre' },
    { codigoTOM: 497, codigoIBGE: 1504901, nomeTOM: 'MUANÁ', nomeIBGE: 'Muaná' },
    { codigoTOM: 499, codigoIBGE: 1505007, nomeTOM: 'NOVA TIMBOTEUA', nomeIBGE: 'Nova Timboteua' },
    { codigoTOM: 501, codigoIBGE: 1505106, nomeTOM: 'ÓBIDOS', nomeIBGE: 'Óbidos' },
    { codigoTOM: 503, codigoIBGE: 1505205, nomeTOM: 'OEIRAS DO PARÁ', nomeIBGE: 'Oeiras do Pará' },
    { codigoTOM: 505, codigoIBGE: 1505304, nomeTOM: 'ORIXIMINÁ', nomeIBGE: 'Oriximiná' },
    { codigoTOM: 507, codigoIBGE: 1505403, nomeTOM: 'OURÉM', nomeIBGE: 'Ourém' },
    { codigoTOM: 509, codigoIBGE: 1505502, nomeTOM: 'PARAGOMINAS', nomeIBGE: 'Paragominas' },
    { codigoTOM: 511, codigoIBGE: 1505601, nomeTOM: 'PEIXE-BOI', nomeIBGE: 'Peixe-Boi' },
    { codigoTOM: 513, codigoIBGE: 1505700, nomeTOM: 'PONTA DE PEDRAS', nomeIBGE: 'Ponta de Pedras' },
    { codigoTOM: 515, codigoIBGE: 1505809, nomeTOM: 'PORTEL', nomeIBGE: 'Portel' },
    { codigoTOM: 517, codigoIBGE: 1505908, nomeTOM: 'PORTO DE MOZ', nomeIBGE: 'Porto de Moz' },
    { codigoTOM: 519, codigoIBGE: 1506005, nomeTOM: 'PRAINHA', nomeIBGE: 'Prainha' },
    { codigoTOM: 521, codigoIBGE: 1506104, nomeTOM: 'PRIMAVERA', nomeIBGE: 'Primavera' },
    { codigoTOM: 523, codigoIBGE: 1506203, nomeTOM: 'SALINÓPOLIS', nomeIBGE: 'Salinópolis' },
    { codigoTOM: 525, codigoIBGE: 1506302, nomeTOM: 'SALVATERRA', nomeIBGE: 'Salvaterra' },
    { codigoTOM: 527, codigoIBGE: 1506401, nomeTOM: 'SANTA CRUZ DO ARARI', nomeIBGE: 'Santa Cruz do Arari' },
    { codigoTOM: 529, codigoIBGE: 1506500, nomeTOM: 'SANTA ISABEL DO PARÁ', nomeIBGE: 'Santa Izabel do Pará' },
    { codigoTOM: 531, codigoIBGE: 1506609, nomeTOM: 'SANTA MARIA DO PARÁ', nomeIBGE: 'Santa Maria do Pará' },
    { codigoTOM: 533, codigoIBGE: 1506708, nomeTOM: 'SANTANA DO ARAGUAIA', nomeIBGE: 'Santana do Araguaia' },
    { codigoTOM: 535, codigoIBGE: 1506807, nomeTOM: 'SANTARÉM', nomeIBGE: 'Santarém' },
    { codigoTOM: 537, codigoIBGE: 1506906, nomeTOM: 'SANTARÉM NOVO', nomeIBGE: 'Santarém Novo' },
    { codigoTOM: 539, codigoIBGE: 1507003, nomeTOM: 'SANTO ANTÔNIO DO TAUÁ', nomeIBGE: 'Santo Antônio do Tauá' },
    { codigoTOM: 541, codigoIBGE: 1507102, nomeTOM: 'SÃO CAETANO DE ODIVELAS', nomeIBGE: 'São Caetano de Odivelas' },
    { codigoTOM: 543, codigoIBGE: 1507201, nomeTOM: 'SÃO DOMINGOS DO CAPIM', nomeIBGE: 'São Domingos do Capim' },
    { codigoTOM: 545, codigoIBGE: 1507300, nomeTOM: 'SÃO FÉLIX DO XINGU', nomeIBGE: 'São Félix do Xingu' },
    { codigoTOM: 547, codigoIBGE: 1507409, nomeTOM: 'SÃO FRANCISCO DO PARÁ', nomeIBGE: 'São Francisco do Pará' },
    { codigoTOM: 549, codigoIBGE: 1507508, nomeTOM: 'SÃO JOÃO DO ARAGUAIA', nomeIBGE: 'São João do Araguaia' },
    { codigoTOM: 551, codigoIBGE: 1507607, nomeTOM: 'SÃO MIGUEL DO GUAMÁ', nomeIBGE: 'São Miguel do Guamá' },
    {
      codigoTOM: 553,
      codigoIBGE: 1507706,
      nomeTOM: 'SÃO SEBASTIÃO DA BOA VISTA',
      nomeIBGE: 'São Sebastião da Boa Vista',
    },
    { codigoTOM: 555, codigoIBGE: 1507805, nomeTOM: 'SENADOR JOSÉ PORFÍRIO', nomeIBGE: 'Senador José Porfírio' },
    { codigoTOM: 557, codigoIBGE: 1507904, nomeTOM: 'SOURE', nomeIBGE: 'Soure' },
    { codigoTOM: 559, codigoIBGE: 1508001, nomeTOM: 'TOMÉ-AÇU', nomeIBGE: 'Tomé-Açu' },
    { codigoTOM: 561, codigoIBGE: 1508100, nomeTOM: 'TUCURUÍ', nomeIBGE: 'Tucuruí' },
    { codigoTOM: 563, codigoIBGE: 1508209, nomeTOM: 'VIGIA', nomeIBGE: 'Vigia' },
    { codigoTOM: 565, codigoIBGE: 1508308, nomeTOM: 'VISEU', nomeIBGE: 'Viseu' },
    { codigoTOM: 567, codigoIBGE: 1506138, nomeTOM: 'REDENÇÃO', nomeIBGE: 'Redenção' },
    { codigoTOM: 569, codigoIBGE: 1506161, nomeTOM: 'RIO MARIA', nomeIBGE: 'Rio Maria' },
    { codigoTOM: 571, codigoIBGE: 1508407, nomeTOM: 'XINGUARA', nomeIBGE: 'Xinguara' },
    { codigoTOM: 573, codigoIBGE: 1506187, nomeTOM: 'RONDON DO PARÁ', nomeIBGE: 'Rondon do Pará' },
    { codigoTOM: 575, codigoIBGE: 1501576, nomeTOM: 'BOM JESUS DO TOCANTINS', nomeIBGE: 'Bom Jesus do Tocantins' },
    { codigoTOM: 577, codigoIBGE: 1501758, nomeTOM: 'BREJO GRANDE DO ARAGUAIA', nomeIBGE: 'Brejo Grande do Araguaia' },
    { codigoTOM: 579, codigoIBGE: 1502756, nomeTOM: 'CONCÓRDIA DO PARÁ', nomeIBGE: 'Concórdia do Pará' },
    { codigoTOM: 581, codigoIBGE: 1502772, nomeTOM: 'CURIONÓPOLIS', nomeIBGE: 'Curionópolis' },
    { codigoTOM: 583, codigoIBGE: 1502939, nomeTOM: 'DOM ELISEU', nomeIBGE: 'Dom Eliseu' },
    { codigoTOM: 585, codigoIBGE: 1503077, nomeTOM: 'GARRAFÃO DO NORTE', nomeIBGE: 'Garrafão do Norte' },
    { codigoTOM: 587, codigoIBGE: 1504059, nomeTOM: 'MÃE DO RIO', nomeIBGE: 'Mãe do Rio' },
    { codigoTOM: 589, codigoIBGE: 1504455, nomeTOM: 'MEDICILÂNDIA', nomeIBGE: 'Medicilândia' },
    { codigoTOM: 591, codigoIBGE: 1505437, nomeTOM: 'OURILÂNDIA DO NORTE', nomeIBGE: 'Ourilândia do Norte' },
    { codigoTOM: 593, codigoIBGE: 1505486, nomeTOM: 'PACAJÁ', nomeIBGE: 'Pacajá' },
    { codigoTOM: 595, codigoIBGE: 1505536, nomeTOM: 'PARAUAPEBAS', nomeIBGE: 'Parauapebas' },
    { codigoTOM: 597, codigoIBGE: 1506195, nomeTOM: 'RURÓPOLIS', nomeIBGE: 'Rurópolis' },
    {
      codigoTOM: 599,
      codigoIBGE: 1506583,
      nomeTOM: 'SANTA MARIA DAS BARREIRAS',
      nomeIBGE: 'Santa Maria das Barreiras',
    },
    { codigoTOM: 619, codigoIBGE: 1507458, nomeTOM: 'SÃO GERALDO DO ARAGUAIA', nomeIBGE: 'São Geraldo do Araguaia' },
    { codigoTOM: 621, codigoIBGE: 1503457, nomeTOM: 'IPIXUNA DO PARÁ', nomeIBGE: 'Ipixuna do Pará' },
    { codigoTOM: 623, codigoIBGE: 1508126, nomeTOM: 'ULIANÓPOLIS', nomeIBGE: 'Ulianópolis' },
    { codigoTOM: 625, codigoIBGE: 1501782, nomeTOM: 'BREU BRANCO', nomeIBGE: 'Breu Branco' },
    { codigoTOM: 627, codigoIBGE: 1503093, nomeTOM: 'GOIANÉSIA DO PARÁ', nomeIBGE: 'Goianésia do Pará' },
    { codigoTOM: 629, codigoIBGE: 1505064, nomeTOM: 'NOVO REPARTIMENTO', nomeIBGE: 'Novo Repartimento' },
    { codigoTOM: 631, codigoIBGE: 1503754, nomeTOM: 'JACAREACANGA', nomeIBGE: 'Jacareacanga' },
    { codigoTOM: 633, codigoIBGE: 1505031, nomeTOM: 'NOVO PROGRESSO', nomeIBGE: 'Novo Progresso' },
    { codigoTOM: 635, codigoIBGE: 1508050, nomeTOM: 'TRAIRÃO', nomeIBGE: 'Trairão' },
    { codigoTOM: 637, codigoIBGE: 1507979, nomeTOM: 'TERRA SANTA', nomeIBGE: 'Terra Santa' },
    { codigoTOM: 639, codigoIBGE: 1501725, nomeTOM: 'BRASIL NOVO', nomeIBGE: 'Brasil Novo' },
    { codigoTOM: 641, codigoIBGE: 1508357, nomeTOM: 'VITÓRIA DO XINGU', nomeIBGE: 'Vitória do Xingu' },
    { codigoTOM: 1190, codigoIBGE: 1504752, nomeTOM: 'MOJUÍ DOS CAMPOS', nomeIBGE: 'Mojuí dos Campos' },
  ],
  [Estados.AP]: [
    { codigoTOM: 70, codigoIBGE: 1600808, nomeTOM: 'VITÓRIA DO JARI', nomeIBGE: 'Vitória do Jari' },
    { codigoTOM: 601, codigoIBGE: 1600105, nomeTOM: 'AMAPÁ', nomeIBGE: 'Amapá' },
    { codigoTOM: 603, codigoIBGE: 1600204, nomeTOM: 'CALÇOENE', nomeIBGE: 'Calçoene' },
    { codigoTOM: 605, codigoIBGE: 1600303, nomeTOM: 'MACAPÁ', nomeIBGE: 'Macapá' },
    { codigoTOM: 607, codigoIBGE: 1600402, nomeTOM: 'MAZAGÃO', nomeIBGE: 'Mazagão' },
    { codigoTOM: 609, codigoIBGE: 1600501, nomeTOM: 'OIAPOQUE', nomeIBGE: 'Oiapoque' },
    { codigoTOM: 611, codigoIBGE: 1600238, nomeTOM: 'FERREIRA GOMES', nomeIBGE: 'Ferreira Gomes' },
    { codigoTOM: 613, codigoIBGE: 1600279, nomeTOM: 'LARANJAL DO JARI', nomeIBGE: 'Laranjal do Jari' },
    { codigoTOM: 615, codigoIBGE: 1600600, nomeTOM: 'SANTANA', nomeIBGE: 'Santana' },
    { codigoTOM: 617, codigoIBGE: 1600709, nomeTOM: 'TARTARUGALZINHO', nomeIBGE: 'Tartarugalzinho' },
    { codigoTOM: 663, codigoIBGE: 1600154, nomeTOM: 'PEDRA BRANCA DO AMAPARI', nomeIBGE: 'Pedra Branca do Amapari' },
    { codigoTOM: 665, codigoIBGE: 1600055, nomeTOM: 'SERRA DO NAVIO', nomeIBGE: 'Serra do Navio' },
    { codigoTOM: 667, codigoIBGE: 1600212, nomeTOM: 'CUTIAS', nomeIBGE: 'Cutias' },
    { codigoTOM: 669, codigoIBGE: 1600253, nomeTOM: 'ITAUBAL', nomeIBGE: 'Itaubal' },
    { codigoTOM: 671, codigoIBGE: 1600535, nomeTOM: 'PORTO GRANDE', nomeIBGE: 'Porto Grande' },
    { codigoTOM: 673, codigoIBGE: 1600550, nomeTOM: 'PRACUÚBA', nomeIBGE: 'Pracuúba' },
  ],
  [Estados.TO]: [
    { codigoTOM: 72, codigoIBGE: 1700301, nomeTOM: 'AGUIARNÓPOLIS', nomeIBGE: 'Aguiarnópolis' },
    { codigoTOM: 74, codigoIBGE: 1703057, nomeTOM: 'BANDEIRANTES DO TOCANTINS', nomeIBGE: 'Bandeirantes do Tocantins' },
    { codigoTOM: 76, codigoIBGE: 1703073, nomeTOM: 'BARRA DO OURO', nomeIBGE: 'Barra do Ouro' },
    { codigoTOM: 78, codigoIBGE: 1704600, nomeTOM: 'CHAPADA DE AREIA', nomeIBGE: 'Chapada de Areia' },
    { codigoTOM: 80, codigoIBGE: 1705102, nomeTOM: 'CHAPADA DA NATIVIDADE', nomeIBGE: 'Chapada da Natividade' },
    { codigoTOM: 82, codigoIBGE: 1706258, nomeTOM: 'CRIXÁS DO TOCANTINS', nomeIBGE: 'Crixás do Tocantins' },
    { codigoTOM: 84, codigoIBGE: 1709807, nomeTOM: 'IPUEIRAS', nomeIBGE: 'Ipueiras' },
    { codigoTOM: 86, codigoIBGE: 1712157, nomeTOM: 'LAVANDEIRA', nomeIBGE: 'Lavandeira' },
    { codigoTOM: 88, codigoIBGE: 1712454, nomeTOM: 'LUZINÓPOLIS', nomeIBGE: 'Luzinópolis' },
    { codigoTOM: 90, codigoIBGE: 1713700, nomeTOM: 'MONTE SANTO DO TOCANTINS', nomeIBGE: 'Monte Santo do Tocantins' },
    { codigoTOM: 92, codigoIBGE: 1715507, nomeTOM: 'OLIVEIRA DE FÁTIMA', nomeIBGE: 'Oliveira de Fátima' },
    { codigoTOM: 94, codigoIBGE: 1718451, nomeTOM: 'PUGMIL', nomeIBGE: 'Pugmil' },
    { codigoTOM: 96, codigoIBGE: 1718899, nomeTOM: 'SANTA RITA DO TOCANTINS', nomeIBGE: 'Santa Rita do Tocantins' },
    {
      codigoTOM: 98,
      codigoIBGE: 1720002,
      nomeTOM: 'SANTA TEREZINHA DO TOCANTINS',
      nomeIBGE: 'Santa Terezinha do Tocantins',
    },
    { codigoTOM: 100, codigoIBGE: 1720978, nomeTOM: 'TALISMÃ', nomeIBGE: 'Talismã' },
    { codigoTOM: 102, codigoIBGE: 1721257, nomeTOM: 'TUPIRAMA', nomeIBGE: 'Tupirama' },
    { codigoTOM: 165, codigoIBGE: 1701051, nomeTOM: 'ANGICO', nomeIBGE: 'Angico' },
    { codigoTOM: 167, codigoIBGE: 1701309, nomeTOM: 'ARAGOMINAS', nomeIBGE: 'Aragominas' },
    { codigoTOM: 169, codigoIBGE: 1702158, nomeTOM: 'ARAGUANÃ', nomeIBGE: 'Araguanã' },
    { codigoTOM: 171, codigoIBGE: 1703826, nomeTOM: 'CACHOEIRINHA', nomeIBGE: 'Cachoeirinha' },
    { codigoTOM: 173, codigoIBGE: 1703842, nomeTOM: 'CAMPOS LINDOS', nomeIBGE: 'Campos Lindos' },
    { codigoTOM: 175, codigoIBGE: 1703883, nomeTOM: 'CARMOLÂNDIA', nomeIBGE: 'Carmolândia' },
    { codigoTOM: 177, codigoIBGE: 1703891, nomeTOM: 'CARRASCO BONITO', nomeIBGE: 'Carrasco Bonito' },
    { codigoTOM: 179, codigoIBGE: 1706506, nomeTOM: 'DARCINÓPOLIS', nomeIBGE: 'Darcinópolis' },
    { codigoTOM: 181, codigoIBGE: 1707405, nomeTOM: 'ESPERANTINA', nomeIBGE: 'Esperantina' },
    { codigoTOM: 183, codigoIBGE: 1712801, nomeTOM: 'MAURILÂNDIA DO TOCANTINS', nomeIBGE: 'Maurilândia do Tocantins' },
    { codigoTOM: 185, codigoIBGE: 1713809, nomeTOM: 'PALMEIRAS DO TOCANTINS', nomeIBGE: 'Palmeiras do Tocantins' },
    { codigoTOM: 187, codigoIBGE: 1713957, nomeTOM: 'MURICILÂNDIA', nomeIBGE: 'Muricilândia' },
    { codigoTOM: 189, codigoIBGE: 1715705, nomeTOM: 'PALMEIRANTE', nomeIBGE: 'Palmeirante' },
    { codigoTOM: 191, codigoIBGE: 1716307, nomeTOM: "PAU D'ARCO", nomeIBGE: "Pau D'Arco" },
    { codigoTOM: 193, codigoIBGE: 1718550, nomeTOM: 'RIACHINHO', nomeIBGE: 'Riachinho' },
    { codigoTOM: 195, codigoIBGE: 1718865, nomeTOM: 'SANTA FÉ DO ARAGUAIA', nomeIBGE: 'Santa Fé do Araguaia' },
    { codigoTOM: 197, codigoIBGE: 1720101, nomeTOM: 'SÃO BENTO DO TOCANTINS', nomeIBGE: 'São Bento do Tocantins' },
    { codigoTOM: 199, codigoIBGE: 1720200, nomeTOM: 'SÃO MIGUEL DO TOCANTINS', nomeIBGE: 'São Miguel do Tocantins' },
    { codigoTOM: 317, codigoIBGE: 1712702, nomeTOM: 'MATEIROS', nomeIBGE: 'Mateiros' },
    { codigoTOM: 321, codigoIBGE: 1715259, nomeTOM: 'NOVO JARDIM', nomeIBGE: 'Novo Jardim' },
    { codigoTOM: 323, codigoIBGE: 1718659, nomeTOM: 'RIO DA CONCEIÇÃO', nomeIBGE: 'Rio da Conceição' },
    { codigoTOM: 325, codigoIBGE: 1720937, nomeTOM: 'TAIPAS DO TOCANTINS', nomeIBGE: 'Taipas do Tocantins' },
    { codigoTOM: 327, codigoIBGE: 1703867, nomeTOM: 'CARIRI DO TOCANTINS', nomeIBGE: 'Cariri do Tocantins' },
    { codigoTOM: 329, codigoIBGE: 1711506, nomeTOM: 'JAÚ DO TOCANTINS', nomeIBGE: 'Jaú do Tocantins' },
    { codigoTOM: 331, codigoIBGE: 1718840, nomeTOM: 'SANDOLÂNDIA', nomeIBGE: 'Sandolândia' },
    {
      codigoTOM: 333,
      codigoIBGE: 1720259,
      nomeTOM: 'SÃO SALVADOR DO TOCANTINS',
      nomeIBGE: 'São Salvador do Tocantins',
    },
    { codigoTOM: 335, codigoIBGE: 1720853, nomeTOM: 'SUCUPIRA', nomeIBGE: 'Sucupira' },
    { codigoTOM: 337, codigoIBGE: 1700251, nomeTOM: 'ABREULÂNDIA', nomeIBGE: 'Abreulândia' },
    { codigoTOM: 339, codigoIBGE: 1703602, nomeTOM: 'BRASILÂNDIA DO TOCANTINS', nomeIBGE: 'Brasilândia do Tocantins' },
    { codigoTOM: 341, codigoIBGE: 1703305, nomeTOM: 'BOM JESUS DO TOCANTINS', nomeIBGE: 'Bom Jesus do Tocantins' },
    { codigoTOM: 343, codigoIBGE: 1704105, nomeTOM: 'CENTENÁRIO', nomeIBGE: 'Centenário' },
    { codigoTOM: 345, codigoIBGE: 1708254, nomeTOM: 'FORTALEZA DO TABOCÃO', nomeIBGE: 'Tabocão' },
    { codigoTOM: 347, codigoIBGE: 1710904, nomeTOM: 'ITAPIRATINS', nomeIBGE: 'Itapiratins' },
    { codigoTOM: 349, codigoIBGE: 1711803, nomeTOM: 'JUARINA', nomeIBGE: 'Juarina' },
    { codigoTOM: 351, codigoIBGE: 1712009, nomeTOM: 'LAJEADO', nomeIBGE: 'Lajeado' },
    { codigoTOM: 353, codigoIBGE: 1711951, nomeTOM: 'LAGOA DO TOCANTINS', nomeIBGE: 'Lagoa do Tocantins' },
    { codigoTOM: 355, codigoIBGE: 1717206, nomeTOM: 'PIRAQUÊ', nomeIBGE: 'Piraquê' },
    { codigoTOM: 357, codigoIBGE: 1718501, nomeTOM: 'RECURSOLÂNDIA', nomeIBGE: 'Recursolândia' },
    { codigoTOM: 359, codigoIBGE: 1718709, nomeTOM: 'RIO DOS BOIS', nomeIBGE: 'Rio dos Bois' },
    { codigoTOM: 361, codigoIBGE: 1718881, nomeTOM: 'SANTA MARIA DO TOCANTINS', nomeIBGE: 'Santa Maria do Tocantins' },
    { codigoTOM: 363, codigoIBGE: 1720150, nomeTOM: 'SÃO FÉLIX DO TOCANTINS', nomeIBGE: 'São Félix do Tocantins' },
    { codigoTOM: 365, codigoIBGE: 1721307, nomeTOM: 'TUPIRATINS', nomeIBGE: 'Tupiratins' },
    { codigoTOM: 367, codigoIBGE: 1711902, nomeTOM: 'LAGOA DA CONFUSÃO', nomeIBGE: 'Lagoa da Confusão' },
    { codigoTOM: 9365, codigoIBGE: 1708205, nomeTOM: 'FORMOSO DO ARAGUAIA', nomeIBGE: 'Formoso do Araguaia' },
    { codigoTOM: 9385, codigoIBGE: 1709500, nomeTOM: 'GURUPI', nomeIBGE: 'Gurupi' },
    { codigoTOM: 9405, codigoIBGE: 1710508, nomeTOM: 'ITACAJÁ', nomeIBGE: 'Itacajá' },
    { codigoTOM: 9409, codigoIBGE: 1710706, nomeTOM: 'ITAGUATINS', nomeIBGE: 'Itaguatins' },
    { codigoTOM: 9417, codigoIBGE: 1711100, nomeTOM: 'ITAPORÃ DO TOCANTINS', nomeIBGE: 'Itaporã do Tocantins' },
    { codigoTOM: 9441, codigoIBGE: 1700350, nomeTOM: 'ALIANÇA DO TOCANTINS', nomeIBGE: 'Aliança do Tocantins' },
    { codigoTOM: 9461, codigoIBGE: 1713205, nomeTOM: 'MIRACEMA DO TOCANTINS', nomeIBGE: 'Miracema do Tocantins' },
    { codigoTOM: 9463, codigoIBGE: 1713304, nomeTOM: 'MIRANORTE', nomeIBGE: 'Miranorte' },
    { codigoTOM: 9469, codigoIBGE: 1713601, nomeTOM: 'MONTE DO CARMO', nomeIBGE: 'Monte do Carmo' },
    { codigoTOM: 9481, codigoIBGE: 1714203, nomeTOM: 'NATIVIDADE', nomeIBGE: 'Natividade' },
    { codigoTOM: 9483, codigoIBGE: 1714302, nomeTOM: 'NAZARÉ', nomeIBGE: 'Nazaré' },
    { codigoTOM: 9499, codigoIBGE: 1715101, nomeTOM: 'NOVO ACORDO', nomeIBGE: 'Novo Acordo' },
    { codigoTOM: 9519, codigoIBGE: 1716109, nomeTOM: 'PARAÍSO DO TOCANTINS', nomeIBGE: 'Paraíso do Tocantins' },
    { codigoTOM: 9521, codigoIBGE: 1716208, nomeTOM: 'PARANÃ', nomeIBGE: 'Paranã' },
    { codigoTOM: 9525, codigoIBGE: 1716505, nomeTOM: 'PEDRO AFONSO', nomeIBGE: 'Pedro Afonso' },
    { codigoTOM: 9527, codigoIBGE: 1716604, nomeTOM: 'PEIXE', nomeIBGE: 'Peixe' },
    { codigoTOM: 9529, codigoIBGE: 1716703, nomeTOM: 'COLMÉIA', nomeIBGE: 'Colméia' },
    { codigoTOM: 9533, codigoIBGE: 1709005, nomeTOM: 'GOIATINS', nomeIBGE: 'Goiatins' },
    { codigoTOM: 9537, codigoIBGE: 1717008, nomeTOM: 'PINDORAMA DO TOCANTINS', nomeIBGE: 'Pindorama do Tocantins' },
    { codigoTOM: 9547, codigoIBGE: 1717503, nomeTOM: 'PIUM', nomeIBGE: 'Pium' },
    { codigoTOM: 9551, codigoIBGE: 1717800, nomeTOM: 'PONTE ALTA DO BOM JESUS', nomeIBGE: 'Ponte Alta do Bom Jesus' },
    { codigoTOM: 9553, codigoIBGE: 1717909, nomeTOM: 'PONTE ALTA DO TOCANTINS', nomeIBGE: 'Ponte Alta do Tocantins' },
    { codigoTOM: 9559, codigoIBGE: 1718204, nomeTOM: 'PORTO NACIONAL', nomeIBGE: 'Porto Nacional' },
    { codigoTOM: 9569, codigoIBGE: 1712405, nomeTOM: 'LIZARDA', nomeIBGE: 'Lizarda' },
    { codigoTOM: 9207, codigoIBGE: 1700400, nomeTOM: 'ALMAS', nomeIBGE: 'Almas' },
    { codigoTOM: 9213, codigoIBGE: 1700707, nomeTOM: 'ALVORADA', nomeIBGE: 'Alvorada' },
    { codigoTOM: 9219, codigoIBGE: 1701002, nomeTOM: 'ANANÁS', nomeIBGE: 'Ananás' },
    { codigoTOM: 9237, codigoIBGE: 1701903, nomeTOM: 'ARAGUACEMA', nomeIBGE: 'Araguacema' },
    { codigoTOM: 9239, codigoIBGE: 1702000, nomeTOM: 'ARAGUAÇU', nomeIBGE: 'Araguaçu' },
    { codigoTOM: 9241, codigoIBGE: 1702109, nomeTOM: 'ARAGUAÍNA', nomeIBGE: 'Araguaína' },
    { codigoTOM: 9243, codigoIBGE: 1702208, nomeTOM: 'ARAGUATINS', nomeIBGE: 'Araguatins' },
    { codigoTOM: 9245, codigoIBGE: 1702307, nomeTOM: 'ARAPOEMA', nomeIBGE: 'Arapoema' },
    { codigoTOM: 9247, codigoIBGE: 1702406, nomeTOM: 'ARRAIAS', nomeIBGE: 'Arraias' },
    { codigoTOM: 9253, codigoIBGE: 1702703, nomeTOM: 'AURORA DO TOCANTINS', nomeIBGE: 'Aurora do Tocantins' },
    { codigoTOM: 9257, codigoIBGE: 1702901, nomeTOM: 'AXIXÁ DO TOCANTINS', nomeIBGE: 'Axixá do Tocantins' },
    { codigoTOM: 9259, codigoIBGE: 1703008, nomeTOM: 'BABAÇULÂNDIA', nomeIBGE: 'Babaçulândia' },
    { codigoTOM: 9273, codigoIBGE: 1703701, nomeTOM: 'BREJINHO DE NAZARÉ', nomeIBGE: 'Brejinho de Nazaré' },
    { codigoTOM: 9311, codigoIBGE: 1705508, nomeTOM: 'COLINAS DO TOCANTINS', nomeIBGE: 'Colinas do Tocantins' },
    { codigoTOM: 9313, codigoIBGE: 1705607, nomeTOM: 'CONCEIÇÃO DO TOCANTINS', nomeIBGE: 'Conceição do Tocantins' },
    { codigoTOM: 9321, codigoIBGE: 1706001, nomeTOM: 'COUTO DE MAGALHÃES', nomeIBGE: 'Couto Magalhães' },
    { codigoTOM: 9323, codigoIBGE: 1706100, nomeTOM: 'CRISTALÂNDIA', nomeIBGE: 'Cristalândia' },
    { codigoTOM: 9341, codigoIBGE: 1707009, nomeTOM: 'DIANÓPOLIS', nomeIBGE: 'Dianópolis' },
    { codigoTOM: 9345, codigoIBGE: 1707207, nomeTOM: 'DOIS IRMÃOS DO TOCANTINS', nomeIBGE: 'Dois Irmãos do Tocantins' },
    { codigoTOM: 9347, codigoIBGE: 1707306, nomeTOM: 'DUERÉ', nomeIBGE: 'Dueré' },
    { codigoTOM: 9355, codigoIBGE: 1707702, nomeTOM: 'FILADÉLFIA', nomeIBGE: 'Filadélfia' },
    {
      codigoTOM: 9603,
      codigoIBGE: 1720309,
      nomeTOM: 'SÃO SEBASTIÃO DO TOCANTINS',
      nomeIBGE: 'São Sebastião do Tocantins',
    },
    { codigoTOM: 9613, codigoIBGE: 1720804, nomeTOM: 'SÍTIO NOVO DO TOCANTINS', nomeIBGE: 'Sítio Novo do Tocantins' },
    { codigoTOM: 9615, codigoIBGE: 1720903, nomeTOM: 'TAGUATINGA', nomeIBGE: 'Taguatinga' },
    { codigoTOM: 9619, codigoIBGE: 1721109, nomeTOM: 'TOCANTÍNIA', nomeIBGE: 'Tocantínia' },
    { codigoTOM: 9621, codigoIBGE: 1721208, nomeTOM: 'TOCANTINÓPOLIS', nomeIBGE: 'Tocantinópolis' },
    { codigoTOM: 9627, codigoIBGE: 1709302, nomeTOM: 'GUARAÍ', nomeIBGE: 'Guaraí' },
    { codigoTOM: 9629, codigoIBGE: 1718402, nomeTOM: 'PRESIDENTE KENNEDY', nomeIBGE: 'Presidente Kennedy' },
    { codigoTOM: 9643, codigoIBGE: 1722107, nomeTOM: 'XAMBIOÁ', nomeIBGE: 'Xambioá' },
    { codigoTOM: 9649, codigoIBGE: 1715754, nomeTOM: 'PALMEIRÓPOLIS', nomeIBGE: 'Palmeirópolis' },
    { codigoTOM: 9659, codigoIBGE: 1720655, nomeTOM: 'SILVANÓPOLIS', nomeIBGE: 'Silvanópolis' },
    { codigoTOM: 9663, codigoIBGE: 1714880, nomeTOM: 'NOVA OLINDA', nomeIBGE: 'Nova Olinda' },
    { codigoTOM: 9665, codigoIBGE: 1722081, nomeTOM: 'WANDERLÂNDIA', nomeIBGE: 'Wanderlândia' },
    { codigoTOM: 9667, codigoIBGE: 1707652, nomeTOM: 'FIGUEIRÓPOLIS', nomeIBGE: 'Figueirópolis' },
    { codigoTOM: 9679, codigoIBGE: 1718758, nomeTOM: 'RIO SONO', nomeIBGE: 'Rio Sono' },
    { codigoTOM: 9683, codigoIBGE: 1707553, nomeTOM: 'FÁTIMA', nomeIBGE: 'Fátima' },
    { codigoTOM: 9685, codigoIBGE: 1702554, nomeTOM: 'AUGUSTINÓPOLIS', nomeIBGE: 'Augustinópolis' },
    { codigoTOM: 9691, codigoIBGE: 1720499, nomeTOM: 'SÃO VALÉRIO DA NATIVIDADE', nomeIBGE: 'São Valério' },
    { codigoTOM: 9693, codigoIBGE: 1703107, nomeTOM: 'BARROLÂNDIA', nomeIBGE: 'Barrolândia' },
    { codigoTOM: 9695, codigoIBGE: 1703206, nomeTOM: 'BERNARDO SAYÃO', nomeIBGE: 'Bernardo Sayão' },
    { codigoTOM: 9697, codigoIBGE: 1705557, nomeTOM: 'COMBINADO', nomeIBGE: 'Combinado' },
    { codigoTOM: 9699, codigoIBGE: 1708304, nomeTOM: 'GOIANORTE', nomeIBGE: 'Goianorte' },
    { codigoTOM: 9703, codigoIBGE: 1715150, nomeTOM: 'NOVO ALEGRE', nomeIBGE: 'Novo Alegre' },
    { codigoTOM: 9705, codigoIBGE: 1716653, nomeTOM: 'PEQUIZEIRO', nomeIBGE: 'Pequizeiro' },
    {
      codigoTOM: 9711,
      codigoIBGE: 1712504,
      nomeTOM: 'MARIANÓPOLIS DO TOCANTINS',
      nomeIBGE: 'Marianópolis do Tocantins',
    },
    { codigoTOM: 9713, codigoIBGE: 1701101, nomeTOM: 'APARECIDA DO RIO NEGRO', nomeIBGE: 'Aparecida do Rio Negro' },
    { codigoTOM: 9715, codigoIBGE: 1703800, nomeTOM: 'BURITI DO TOCANTINS', nomeIBGE: 'Buriti do Tocantins' },
    { codigoTOM: 9717, codigoIBGE: 1703909, nomeTOM: 'CASEARA', nomeIBGE: 'Caseara' },
    { codigoTOM: 9719, codigoIBGE: 1707108, nomeTOM: 'DIVINÓPOLIS DO TOCANTINS', nomeIBGE: 'Divinópolis do Tocantins' },
    { codigoTOM: 9721, codigoIBGE: 1715002, nomeTOM: 'NOVA ROSALÂNDIA', nomeIBGE: 'Nova Rosalândia' },
    {
      codigoTOM: 9723,
      codigoIBGE: 1718006,
      nomeTOM: 'PORTO ALEGRE DO TOCANTINS',
      nomeIBGE: 'Porto Alegre do Tocantins',
    },
    { codigoTOM: 9725, codigoIBGE: 1718303, nomeTOM: 'PRAIA NORTE', nomeIBGE: 'Praia Norte' },
    { codigoTOM: 9727, codigoIBGE: 1718808, nomeTOM: 'SAMPAIO', nomeIBGE: 'Sampaio' },
    { codigoTOM: 9729, codigoIBGE: 1718907, nomeTOM: 'SANTA ROSA DO TOCANTINS', nomeIBGE: 'Santa Rosa do Tocantins' },
    {
      codigoTOM: 9731,
      codigoIBGE: 1719004,
      nomeTOM: 'SANTA TEREZA DO TOCANTINS',
      nomeIBGE: 'Santa Tereza do Tocantins',
    },
    { codigoTOM: 9733, codigoIBGE: 1721000, nomeTOM: 'PALMAS', nomeIBGE: 'Palmas' },
  ],
  [Estados.MT]: [
    { codigoTOM: 89, codigoIBGE: 5103379, nomeTOM: 'COTRIGUAÇU', nomeIBGE: 'Cotriguaçu' },
    { codigoTOM: 91, codigoIBGE: 5106455, nomeTOM: 'PLANALTO DA SERRA', nomeIBGE: 'Planalto da Serra' },
    { codigoTOM: 93, codigoIBGE: 5107404, nomeTOM: 'SÃO PEDRO DA CIPA', nomeIBGE: 'São Pedro da Cipa' },
    { codigoTOM: 95, codigoIBGE: 5106653, nomeTOM: 'PONTAL DO ARAGUAIA', nomeIBGE: 'Pontal do Araguaia' },
    { codigoTOM: 97, codigoIBGE: 5107065, nomeTOM: 'QUERÊNCIA', nomeIBGE: 'Querência' },
    { codigoTOM: 99, codigoIBGE: 5107198, nomeTOM: 'RIBEIRÃOZINHO', nomeIBGE: 'Ribeirãozinho' },
    { codigoTOM: 101, codigoIBGE: 5106851, nomeTOM: 'PORTO ESTRELA', nomeIBGE: 'Porto Estrela' },
    { codigoTOM: 103, codigoIBGE: 5108857, nomeTOM: 'NOVA MARILÂNDIA', nomeIBGE: 'Nova Marilândia' },
    { codigoTOM: 111, codigoIBGE: 5108907, nomeTOM: 'NOVA MARINGÁ', nomeIBGE: 'Nova Maringá' },
    { codigoTOM: 115, codigoIBGE: 5107263, nomeTOM: 'SANTO AFONSO', nomeIBGE: 'Santo Afonso' },
    { codigoTOM: 117, codigoIBGE: 5106158, nomeTOM: 'NOVA BANDEIRANTES', nomeIBGE: 'Nova Bandeirantes' },
    { codigoTOM: 119, codigoIBGE: 5108956, nomeTOM: 'NOVA MONTE VERDE', nomeIBGE: 'Nova Monte Verde' },
    { codigoTOM: 121, codigoIBGE: 5108808, nomeTOM: 'NOVA GUARITA', nomeIBGE: 'Nova Guarita' },
    { codigoTOM: 123, codigoIBGE: 5107248, nomeTOM: 'SANTA CARMEM', nomeIBGE: 'Santa Carmem' },
    { codigoTOM: 125, codigoIBGE: 5107941, nomeTOM: 'TABAPORÃ', nomeIBGE: 'Tabaporã' },
    { codigoTOM: 127, codigoIBGE: 5100359, nomeTOM: 'ALTO BOA VISTA', nomeIBGE: 'Alto Boa Vista' },
    { codigoTOM: 129, codigoIBGE: 5102694, nomeTOM: 'CANABRAVA DO NORTE', nomeIBGE: 'Canabrava do Norte' },
    { codigoTOM: 131, codigoIBGE: 5103353, nomeTOM: 'CONFRESA', nomeIBGE: 'Confresa' },
    { codigoTOM: 133, codigoIBGE: 5107354, nomeTOM: 'SÃO JOSÉ DO XINGU', nomeIBGE: 'São José do Xingu' },
    { codigoTOM: 135, codigoIBGE: 5103957, nomeTOM: "GLÓRIA D'OESTE", nomeIBGE: "Glória D'Oeste" },
    { codigoTOM: 137, codigoIBGE: 5105234, nomeTOM: "LAMBARI D'OESTE", nomeIBGE: "Lambari D'Oeste" },
    { codigoTOM: 1032, codigoIBGE: 5102686, nomeTOM: 'CAMPOS DE JÚLIO', nomeIBGE: 'Campos de Júlio' },
    { codigoTOM: 1034, codigoIBGE: 5102793, nomeTOM: 'CARLINDA', nomeIBGE: 'Carlinda' },
    { codigoTOM: 1036, codigoIBGE: 5103700, nomeTOM: 'FELIZ NATAL', nomeIBGE: 'Feliz Natal' },
    { codigoTOM: 1038, codigoIBGE: 5103858, nomeTOM: 'GAÚCHA DO NORTE', nomeIBGE: 'Gaúcha do Norte' },
    { codigoTOM: 1040, codigoIBGE: 5106182, nomeTOM: 'NOVA LACERDA', nomeIBGE: 'Nova Lacerda' },
    { codigoTOM: 1042, codigoIBGE: 5106240, nomeTOM: 'NOVA UBIRATÃ', nomeIBGE: 'Nova Ubiratã' },
    { codigoTOM: 1044, codigoIBGE: 5106265, nomeTOM: 'NOVO MUNDO', nomeIBGE: 'Novo Mundo' },
    { codigoTOM: 1046, codigoIBGE: 5107875, nomeTOM: 'SAPEZAL', nomeIBGE: 'Sapezal' },
    { codigoTOM: 1048, codigoIBGE: 5108303, nomeTOM: 'UNIÃO DO SUL', nomeIBGE: 'União do Sul' },
    { codigoTOM: 1094, codigoIBGE: 5107743, nomeTOM: 'SANTA CRUZ DO XINGU', nomeIBGE: 'Santa Cruz do Xingu' },
    { codigoTOM: 1096, codigoIBGE: 5107768, nomeTOM: 'SANTA RITA DO TRIVELATO', nomeIBGE: 'Santa Rita do Trivelato' },
    { codigoTOM: 1098, codigoIBGE: 5107792, nomeTOM: 'SANTO ANTÔNIO DO LESTE', nomeIBGE: 'Santo Antônio do Leste' },
    { codigoTOM: 1100, codigoIBGE: 5107883, nomeTOM: 'SERRA NOVA DOURADA', nomeIBGE: 'Serra Nova Dourada' },
    { codigoTOM: 1102, codigoIBGE: 5108352, nomeTOM: 'VALE DE SÃO DOMINGOS', nomeIBGE: 'Vale de São Domingos' },
    { codigoTOM: 1184, codigoIBGE: 5104526, nomeTOM: 'IPIRANGA DO NORTE', nomeIBGE: 'Ipiranga do Norte' },
    { codigoTOM: 1186, codigoIBGE: 5104542, nomeTOM: 'ITANHANGÁ', nomeIBGE: 'Itanhangá' },
    { codigoTOM: 6087, codigoIBGE: 5107297, nomeTOM: 'SÃO JOSÉ DO POVO', nomeIBGE: 'São José do Povo' },
    { codigoTOM: 1078, codigoIBGE: 5101852, nomeTOM: 'BOM JESUS DO ARAGUAIA', nomeIBGE: 'Bom Jesus do Araguaia' },
    { codigoTOM: 1080, codigoIBGE: 5103254, nomeTOM: 'COLNIZA', nomeIBGE: 'Colniza' },
    { codigoTOM: 1082, codigoIBGE: 5103361, nomeTOM: "CONQUISTA D'OESTE", nomeIBGE: "Conquista D'Oeste" },
    { codigoTOM: 1084, codigoIBGE: 5103437, nomeTOM: 'CURVELÂNDIA', nomeIBGE: 'Curvelândia' },
    { codigoTOM: 1086, codigoIBGE: 5106174, nomeTOM: 'NOVA NAZARÉ', nomeIBGE: 'Nova Nazaré' },
    { codigoTOM: 1088, codigoIBGE: 5106190, nomeTOM: 'NOVA SANTA HELENA', nomeIBGE: 'Nova Santa Helena' },
    { codigoTOM: 1090, codigoIBGE: 5106315, nomeTOM: 'NOVO SANTO ANTÔNIO', nomeIBGE: 'Novo Santo Antônio' },
    { codigoTOM: 1092, codigoIBGE: 5107578, nomeTOM: 'RONDOLÂNDIA', nomeIBGE: 'Rondolândia' },
    { codigoTOM: 8979, codigoIBGE: 5103205, nomeTOM: 'COLÍDER', nomeIBGE: 'Colíder' },
    { codigoTOM: 8981, codigoIBGE: 5106208, nomeTOM: 'NOVA BRASILÂNDIA', nomeIBGE: 'Nova Brasilândia' },
    { codigoTOM: 8983, codigoIBGE: 5106307, nomeTOM: 'PARANATINGA', nomeIBGE: 'Paranatinga' },
    { codigoTOM: 8985, codigoIBGE: 5107909, nomeTOM: 'SINOP', nomeIBGE: 'Sinop' },
    { codigoTOM: 8987, codigoIBGE: 5100250, nomeTOM: 'ALTA FLORESTA', nomeIBGE: 'Alta Floresta' },
    { codigoTOM: 8989, codigoIBGE: 5101258, nomeTOM: 'ARAPUTANGA', nomeIBGE: 'Araputanga' },
    { codigoTOM: 8991, codigoIBGE: 5105002, nomeTOM: 'JAURU', nomeIBGE: 'Jauru' },
    {
      codigoTOM: 8993,
      codigoIBGE: 5107107,
      nomeTOM: 'SÃO JOSÉ DOS QUATRO MARCOS',
      nomeIBGE: 'São José dos Quatro Marcos',
    },
    { codigoTOM: 8995, codigoIBGE: 5107206, nomeTOM: 'RIO BRANCO', nomeIBGE: 'Rio Branco' },
    { codigoTOM: 8997, codigoIBGE: 5107750, nomeTOM: 'SALTO DO CÉU', nomeIBGE: 'Salto do Céu' },
    { codigoTOM: 8999, codigoIBGE: 5106752, nomeTOM: 'PONTES E LACERDA', nomeIBGE: 'Pontes e Lacerda' },
    { codigoTOM: 9001, codigoIBGE: 5100102, nomeTOM: 'ACORIZAL', nomeIBGE: 'Acorizal' },
    { codigoTOM: 9005, codigoIBGE: 5100300, nomeTOM: 'ALTO ARAGUAIA', nomeIBGE: 'Alto Araguaia' },
    { codigoTOM: 9007, codigoIBGE: 5100409, nomeTOM: 'ALTO GARÇAS', nomeIBGE: 'Alto Garças' },
    { codigoTOM: 9009, codigoIBGE: 5100508, nomeTOM: 'ALTO PARAGUAI', nomeIBGE: 'Alto Paraguai' },
    { codigoTOM: 9023, codigoIBGE: 5101209, nomeTOM: 'ARAGUAINHA', nomeIBGE: 'Araguainha' },
    { codigoTOM: 9025, codigoIBGE: 5101308, nomeTOM: 'ARENÁPOLIS', nomeIBGE: 'Arenápolis' },
    { codigoTOM: 9027, codigoIBGE: 5101407, nomeTOM: 'ARIPUANÃ', nomeIBGE: 'Aripuanã' },
    { codigoTOM: 9031, codigoIBGE: 5101605, nomeTOM: 'BARÃO DE MELGAÇO', nomeIBGE: 'Barão de Melgaço' },
    { codigoTOM: 9033, codigoIBGE: 5101704, nomeTOM: 'BARRA DO BUGRES', nomeIBGE: 'Barra do Bugres' },
    { codigoTOM: 9035, codigoIBGE: 5101803, nomeTOM: 'BARRA DO GARÇAS', nomeIBGE: 'Barra do Garças' },
    { codigoTOM: 9047, codigoIBGE: 5102504, nomeTOM: 'CÁCERES', nomeIBGE: 'Cáceres' },
    { codigoTOM: 9059, codigoIBGE: 5103007, nomeTOM: 'CHAPADA DOS GUIMARÃES', nomeIBGE: 'Chapada dos Guimarães' },
    { codigoTOM: 9067, codigoIBGE: 5103403, nomeTOM: 'CUIABÁ', nomeIBGE: 'Cuiabá' },
    { codigoTOM: 9069, codigoIBGE: 5103502, nomeTOM: 'DIAMANTINO', nomeIBGE: 'Diamantino' },
    { codigoTOM: 9071, codigoIBGE: 5103601, nomeTOM: 'DOM AQUINO', nomeIBGE: 'Dom Aquino' },
    { codigoTOM: 9077, codigoIBGE: 5103908, nomeTOM: 'GENERAL CARNEIRO', nomeIBGE: 'General Carneiro' },
    { codigoTOM: 9083, codigoIBGE: 5104203, nomeTOM: 'GUIRATINGA', nomeIBGE: 'Guiratinga' },
    { codigoTOM: 9091, codigoIBGE: 5104609, nomeTOM: 'ITIQUIRA', nomeIBGE: 'Itiquira' },
    { codigoTOM: 9095, codigoIBGE: 5104807, nomeTOM: 'JACIARA', nomeIBGE: 'Jaciara' },
    { codigoTOM: 9105, codigoIBGE: 5105309, nomeTOM: 'LUCIÁRA', nomeIBGE: 'Luciara' },
    {
      codigoTOM: 9109,
      codigoIBGE: 5105507,
      nomeTOM: 'VILA BELA DA SANTÍSSIMA TRINDADE',
      nomeIBGE: 'Vila Bela da Santíssima Trindade',
    },
    { codigoTOM: 9117, codigoIBGE: 5105903, nomeTOM: 'NOBRES', nomeIBGE: 'Nobres' },
    { codigoTOM: 9119, codigoIBGE: 5106000, nomeTOM: 'NORTELÂNDIA', nomeIBGE: 'Nortelândia' },
    {
      codigoTOM: 9121,
      codigoIBGE: 5106109,
      nomeTOM: 'NOSSA SENHORA DO LIVRAMENTO',
      nomeIBGE: 'Nossa Senhora do Livramento',
    },
    { codigoTOM: 9129, codigoIBGE: 5106505, nomeTOM: 'POCONÉ', nomeIBGE: 'Poconé' },
    { codigoTOM: 9133, codigoIBGE: 5106703, nomeTOM: 'PONTE BRANCA', nomeIBGE: 'Ponte Branca' },
    { codigoTOM: 9135, codigoIBGE: 5106802, nomeTOM: 'PORTO DOS GAÚCHOS', nomeIBGE: 'Porto dos Gaúchos' },
    { codigoTOM: 9139, codigoIBGE: 5107008, nomeTOM: 'POXORÉU', nomeIBGE: 'Poxoréu' },
    { codigoTOM: 9151, codigoIBGE: 5107602, nomeTOM: 'RONDONÓPOLIS', nomeIBGE: 'Rondonópolis' },
    { codigoTOM: 9153, codigoIBGE: 5107701, nomeTOM: 'ROSÁRIO OESTE', nomeIBGE: 'Rosário Oeste' },
    {
      codigoTOM: 9155,
      codigoIBGE: 5107800,
      nomeTOM: 'SANTO ANTÔNIO DO LEVERGER',
      nomeIBGE: 'Santo Antônio do Leverger',
    },
    { codigoTOM: 9161, codigoIBGE: 5108105, nomeTOM: 'TESOURO', nomeIBGE: 'Tesouro' },
    { codigoTOM: 9163, codigoIBGE: 5108204, nomeTOM: 'TORIXORÉU', nomeIBGE: 'Torixoréu' },
    { codigoTOM: 9167, codigoIBGE: 5108402, nomeTOM: 'VÁRZEA GRANDE', nomeIBGE: 'Várzea Grande' },
    { codigoTOM: 9177, codigoIBGE: 5105622, nomeTOM: "MIRASSOL D'OESTE", nomeIBGE: "Mirassol d'Oeste" },
    { codigoTOM: 9181, codigoIBGE: 5106372, nomeTOM: 'PEDRA PRETA', nomeIBGE: 'Pedra Preta' },
    { codigoTOM: 9183, codigoIBGE: 5107859, nomeTOM: 'SÃO FÉLIX DO ARAGUAIA', nomeIBGE: 'São Félix do Araguaia' },
    { codigoTOM: 9185, codigoIBGE: 5107958, nomeTOM: 'TANGARÁ DA SERRA', nomeIBGE: 'Tangará da Serra' },
    { codigoTOM: 9189, codigoIBGE: 5105200, nomeTOM: 'JUSCIMEIRA', nomeIBGE: 'Juscimeira' },
    { codigoTOM: 9191, codigoIBGE: 5100201, nomeTOM: 'ÁGUA BOA', nomeIBGE: 'Água Boa' },
    { codigoTOM: 9193, codigoIBGE: 5102702, nomeTOM: 'CANARANA', nomeIBGE: 'Canarana' },
    { codigoTOM: 9195, codigoIBGE: 5106257, nomeTOM: 'NOVA XAVANTINA', nomeIBGE: 'Nova Xavantina' },
    { codigoTOM: 9197, codigoIBGE: 5107776, nomeTOM: 'SANTA TEREZINHA', nomeIBGE: 'Santa Terezinha' },
    { codigoTOM: 9199, codigoIBGE: 5107305, nomeTOM: 'SÃO JOSÉ DO RIO CLARO', nomeIBGE: 'São José do Rio Claro' },
    { codigoTOM: 9741, codigoIBGE: 5107180, nomeTOM: 'RIBEIRÃO CASCALHEIRA', nomeIBGE: 'Ribeirão Cascalheira' },
    { codigoTOM: 9763, codigoIBGE: 5108006, nomeTOM: 'TAPURAH', nomeIBGE: 'Tapurah' },
    { codigoTOM: 9773, codigoIBGE: 5100805, nomeTOM: 'APIACÁS', nomeIBGE: 'Apiacás' },
    { codigoTOM: 9777, codigoIBGE: 5102637, nomeTOM: 'CAMPO NOVO DO PARECIS', nomeIBGE: 'Campo Novo do Parecis' },
    { codigoTOM: 9779, codigoIBGE: 5102678, nomeTOM: 'CAMPO VERDE', nomeIBGE: 'Campo Verde' },
    { codigoTOM: 9783, codigoIBGE: 5102850, nomeTOM: 'CASTANHEIRA', nomeIBGE: 'Castanheira' },
    { codigoTOM: 9789, codigoIBGE: 5103056, nomeTOM: 'CLÁUDIA', nomeIBGE: 'Cláudia' },
    { codigoTOM: 9819, codigoIBGE: 5105101, nomeTOM: 'JUARA', nomeIBGE: 'Juara' },
    { codigoTOM: 9831, codigoIBGE: 5105150, nomeTOM: 'JUÍNA', nomeIBGE: 'Juína' },
    { codigoTOM: 9833, codigoIBGE: 5103452, nomeTOM: 'DENISE', nomeIBGE: 'Denise' },
    { codigoTOM: 9861, codigoIBGE: 5104906, nomeTOM: 'JANGADA', nomeIBGE: 'Jangada' },
    { codigoTOM: 9863, codigoIBGE: 5102603, nomeTOM: 'CAMPINÁPOLIS', nomeIBGE: 'Campinápolis' },
    { codigoTOM: 9865, codigoIBGE: 5103106, nomeTOM: 'COCALINHO', nomeIBGE: 'Cocalinho' },
    { codigoTOM: 9867, codigoIBGE: 5106281, nomeTOM: 'NOVO SÃO JOAQUIM', nomeIBGE: 'Novo São Joaquim' },
    { codigoTOM: 9869, codigoIBGE: 5101001, nomeTOM: 'ARAGUAIANA', nomeIBGE: 'Araguaiana' },
    { codigoTOM: 9871, codigoIBGE: 5107040, nomeTOM: 'PRIMAVERA DO LESTE', nomeIBGE: 'Primavera do Leste' },
    { codigoTOM: 9873, codigoIBGE: 5101902, nomeTOM: 'BRASNORTE', nomeIBGE: 'Brasnorte' },
    { codigoTOM: 9875, codigoIBGE: 5106828, nomeTOM: 'PORTO ESPERIDIÃO', nomeIBGE: 'Porto Esperidião' },
    { codigoTOM: 9877, codigoIBGE: 5104500, nomeTOM: 'INDIAVAÍ', nomeIBGE: 'Indiavaí' },
    { codigoTOM: 9879, codigoIBGE: 5107156, nomeTOM: 'RESERVA DO CABAÇAL', nomeIBGE: 'Reserva do Cabaçal' },
    { codigoTOM: 9881, codigoIBGE: 5103809, nomeTOM: "FIGUEIRÓPOLIS D'OESTE", nomeIBGE: "Figueirópolis D'Oeste" },
    { codigoTOM: 9883, codigoIBGE: 5103304, nomeTOM: 'COMODORO', nomeIBGE: 'Comodoro' },
    { codigoTOM: 9885, codigoIBGE: 5106299, nomeTOM: 'PARANAÍTA', nomeIBGE: 'Paranaíta' },
    { codigoTOM: 9887, codigoIBGE: 5104104, nomeTOM: 'GUARANTÃ DO NORTE', nomeIBGE: 'Guarantã do Norte' },
    { codigoTOM: 9889, codigoIBGE: 5106216, nomeTOM: 'NOVA CANAÃ DO NORTE', nomeIBGE: 'Nova Canaã do Norte' },
    { codigoTOM: 9891, codigoIBGE: 5106422, nomeTOM: 'PEIXOTO DE AZEVEDO', nomeIBGE: 'Peixoto de Azevedo' },
    { codigoTOM: 9893, codigoIBGE: 5106232, nomeTOM: 'NOVA OLÍMPIA', nomeIBGE: 'Nova Olímpia' },
    { codigoTOM: 9895, codigoIBGE: 5106778, nomeTOM: 'PORTO ALEGRE DO NORTE', nomeIBGE: 'Porto Alegre do Norte' },
    { codigoTOM: 9897, codigoIBGE: 5108600, nomeTOM: 'VILA RICA', nomeIBGE: 'Vila Rica' },
    { codigoTOM: 9899, codigoIBGE: 5105580, nomeTOM: 'MARCELÂNDIA', nomeIBGE: 'Marcelândia' },
    { codigoTOM: 9901, codigoIBGE: 5104559, nomeTOM: 'ITAÚBA', nomeIBGE: 'Itaúba' },
    { codigoTOM: 9903, codigoIBGE: 5106273, nomeTOM: 'NOVO HORIZONTE DO NORTE', nomeIBGE: 'Novo Horizonte do Norte' },
    { codigoTOM: 9905, codigoIBGE: 5108501, nomeTOM: 'VERA', nomeIBGE: 'Vera' },
    { codigoTOM: 9907, codigoIBGE: 5107925, nomeTOM: 'SORRISO', nomeIBGE: 'Sorriso' },
    { codigoTOM: 9909, codigoIBGE: 5108055, nomeTOM: 'TERRA NOVA DO NORTE', nomeIBGE: 'Terra Nova do Norte' },
    { codigoTOM: 9911, codigoIBGE: 5100607, nomeTOM: 'ALTO TAQUARI', nomeIBGE: 'Alto Taquari' },
    { codigoTOM: 9921, codigoIBGE: 5105176, nomeTOM: 'JURUENA', nomeIBGE: 'Juruena' },
    { codigoTOM: 9925, codigoIBGE: 5105259, nomeTOM: 'LUCAS DO RIO VERDE', nomeIBGE: 'Lucas do Rio Verde' },
    { codigoTOM: 9929, codigoIBGE: 5105606, nomeTOM: 'MATUPÁ', nomeIBGE: 'Matupá' },
    { codigoTOM: 9937, codigoIBGE: 5106224, nomeTOM: 'NOVA MUTUM', nomeIBGE: 'Nova Mutum' },
  ],
  [Estados.MA]: [
    { codigoTOM: 104, codigoIBGE: 2100154, nomeTOM: 'ÁGUA DOCE DO MARANHÃO', nomeIBGE: 'Água Doce do Maranhão' },
    { codigoTOM: 106, codigoIBGE: 2100436, nomeTOM: 'ALTO ALEGRE DO MARANHÃO', nomeIBGE: 'Alto Alegre do Maranhão' },
    { codigoTOM: 108, codigoIBGE: 2100477, nomeTOM: 'ALTO ALEGRE DO PINDARÉ', nomeIBGE: 'Alto Alegre do Pindaré' },
    { codigoTOM: 110, codigoIBGE: 2100550, nomeTOM: 'AMAPÁ DO MARANHÃO', nomeIBGE: 'Amapá do Maranhão' },
    { codigoTOM: 112, codigoIBGE: 2100832, nomeTOM: 'APICUM-AÇU', nomeIBGE: 'Apicum-Açu' },
    { codigoTOM: 114, codigoIBGE: 2100873, nomeTOM: 'ARAGUANÃ', nomeIBGE: 'Araguanã' },
    { codigoTOM: 116, codigoIBGE: 2101251, nomeTOM: 'BACABEIRA', nomeIBGE: 'Bacabeira' },
    { codigoTOM: 118, codigoIBGE: 2101350, nomeTOM: 'BACURITUBA', nomeIBGE: 'Bacurituba' },
    { codigoTOM: 120, codigoIBGE: 2101731, nomeTOM: 'BELÁGUA', nomeIBGE: 'Belágua' },
    { codigoTOM: 122, codigoIBGE: 2101772, nomeTOM: 'BELA VISTA DO MARANHÃO', nomeIBGE: 'Bela Vista do Maranhão' },
    { codigoTOM: 124, codigoIBGE: 2101939, nomeTOM: 'BERNARDO DO MEARIM', nomeIBGE: 'Bernardo do Mearim' },
    { codigoTOM: 126, codigoIBGE: 2101970, nomeTOM: 'BOA VISTA DO GURUPI', nomeIBGE: 'Boa Vista do Gurupi' },
    { codigoTOM: 128, codigoIBGE: 2102036, nomeTOM: 'BOM JESUS DAS SELVAS', nomeIBGE: 'Bom Jesus das Selvas' },
    { codigoTOM: 130, codigoIBGE: 2102077, nomeTOM: 'BOM LUGAR', nomeIBGE: 'Bom Lugar' },
    { codigoTOM: 132, codigoIBGE: 2102150, nomeTOM: 'BREJO DE AREIA', nomeIBGE: 'Brejo de Areia' },
    { codigoTOM: 134, codigoIBGE: 2102325, nomeTOM: 'BURITICUPU', nomeIBGE: 'Buriticupu' },
    { codigoTOM: 136, codigoIBGE: 2102358, nomeTOM: 'BURITIRANA', nomeIBGE: 'Buritirana' },
    { codigoTOM: 138, codigoIBGE: 2102374, nomeTOM: 'CACHOEIRA GRANDE', nomeIBGE: 'Cachoeira Grande' },
    { codigoTOM: 140, codigoIBGE: 2102556, nomeTOM: 'CAMPESTRE DO MARANHÃO', nomeIBGE: 'Campestre do Maranhão' },
    { codigoTOM: 142, codigoIBGE: 2102754, nomeTOM: 'CAPINZAL DO NORTE', nomeIBGE: 'Capinzal do Norte' },
    { codigoTOM: 144, codigoIBGE: 2103125, nomeTOM: 'CENTRAL DO MARANHÃO', nomeIBGE: 'Central do Maranhão' },
    { codigoTOM: 146, codigoIBGE: 2103158, nomeTOM: 'CENTRO DO GUILHERME', nomeIBGE: 'Centro do Guilherme' },
    { codigoTOM: 148, codigoIBGE: 2103174, nomeTOM: 'CENTRO NOVO DO MARANHÃO', nomeIBGE: 'Centro Novo do Maranhão' },
    { codigoTOM: 150, codigoIBGE: 2103257, nomeTOM: 'CIDELÂNDIA', nomeIBGE: 'Cidelândia' },
    { codigoTOM: 152, codigoIBGE: 2103554, nomeTOM: 'CONCEIÇÃO DO LAGO-AÇU', nomeIBGE: 'Conceição do Lago-Açu' },
    { codigoTOM: 154, codigoIBGE: 2103752, nomeTOM: 'DAVINÓPOLIS', nomeIBGE: 'Davinópolis' },
    { codigoTOM: 156, codigoIBGE: 2104073, nomeTOM: 'FEIRA NOVA DO MARANHÃO', nomeIBGE: 'Feira Nova do Maranhão' },
    { codigoTOM: 158, codigoIBGE: 2104081, nomeTOM: 'FERNANDO FALCÃO', nomeIBGE: 'Fernando Falcão' },
    { codigoTOM: 160, codigoIBGE: 2104099, nomeTOM: 'FORMOSA DA SERRA NEGRA', nomeIBGE: 'Formosa da Serra Negra' },
    { codigoTOM: 162, codigoIBGE: 2104552, nomeTOM: 'GOVERNADOR EDISON LOBÃO', nomeIBGE: 'Governador Edison Lobão' },
    { codigoTOM: 164, codigoIBGE: 2104628, nomeTOM: 'GOVERNADOR LUIZ ROCHA', nomeIBGE: 'Governador Luiz Rocha' },
    { codigoTOM: 166, codigoIBGE: 2104651, nomeTOM: 'GOVERNADOR NEWTON BELLO', nomeIBGE: 'Governador Newton Bello' },
    { codigoTOM: 168, codigoIBGE: 2104677, nomeTOM: 'GOVERNADOR NUNES FREIRE', nomeIBGE: 'Governador Nunes Freire' },
    { codigoTOM: 170, codigoIBGE: 2105153, nomeTOM: 'IGARAPÉ DO MEIO', nomeIBGE: 'Igarapé do Meio' },
    { codigoTOM: 172, codigoIBGE: 2105351, nomeTOM: 'ITAIPAVA DO GRAJAÚ', nomeIBGE: 'Itaipava do Grajaú' },
    { codigoTOM: 174, codigoIBGE: 2105427, nomeTOM: 'ITINGA DO MARANHÃO', nomeIBGE: 'Itinga do Maranhão' },
    { codigoTOM: 176, codigoIBGE: 2105450, nomeTOM: 'JATOBÁ', nomeIBGE: 'Jatobá' },
    { codigoTOM: 178, codigoIBGE: 2105476, nomeTOM: 'JENIPAPO DOS VIEIRAS', nomeIBGE: 'Jenipapo dos Vieiras' },
    { codigoTOM: 180, codigoIBGE: 2105658, nomeTOM: 'JUNCO DO MARANHÃO', nomeIBGE: 'Junco do Maranhão' },
    { codigoTOM: 182, codigoIBGE: 2105922, nomeTOM: 'LAGOA DO MATO', nomeIBGE: 'Lagoa do Mato' },
    { codigoTOM: 184, codigoIBGE: 2105948, nomeTOM: 'LAGO DOS RODRIGUES', nomeIBGE: 'Lago dos Rodrigues' },
    { codigoTOM: 186, codigoIBGE: 2105963, nomeTOM: 'LAGOA GRANDE DO MARANHÃO', nomeIBGE: 'Lagoa Grande do Maranhão' },
    { codigoTOM: 188, codigoIBGE: 2105989, nomeTOM: 'LAJEADO NOVO', nomeIBGE: 'Lajeado Novo' },
    { codigoTOM: 190, codigoIBGE: 2106326, nomeTOM: 'MARACAÇUMÉ', nomeIBGE: 'Maracaçumé' },
    { codigoTOM: 192, codigoIBGE: 2106359, nomeTOM: 'MARAJÁ DO SENA', nomeIBGE: 'Marajá do Sena' },
    { codigoTOM: 194, codigoIBGE: 2106375, nomeTOM: 'MARANHÃOZINHO', nomeIBGE: 'Maranhãozinho' },
    { codigoTOM: 196, codigoIBGE: 2106631, nomeTOM: 'MATÕES DO NORTE', nomeIBGE: 'Matões do Norte' },
    { codigoTOM: 198, codigoIBGE: 2106672, nomeTOM: 'MILAGRES DO MARANHÃO', nomeIBGE: 'Milagres do Maranhão' },
    { codigoTOM: 200, codigoIBGE: 2107258, nomeTOM: 'NOVA COLINAS', nomeIBGE: 'Nova Colinas' },
    { codigoTOM: 202, codigoIBGE: 2107357, nomeTOM: 'NOVA OLINDA DO MARANHÃO', nomeIBGE: 'Nova Olinda do Maranhão' },
    { codigoTOM: 204, codigoIBGE: 2107456, nomeTOM: 'OLINDA NOVA DO MARANHÃO', nomeIBGE: 'Olinda Nova do Maranhão' },
    { codigoTOM: 206, codigoIBGE: 2108058, nomeTOM: 'PAULINO NEVES', nomeIBGE: 'Paulino Neves' },
    { codigoTOM: 208, codigoIBGE: 2108256, nomeTOM: 'PEDRO DO ROSÁRIO', nomeIBGE: 'Pedro do Rosário' },
    { codigoTOM: 210, codigoIBGE: 2108454, nomeTOM: 'PERITORÓ', nomeIBGE: 'Peritoró' },
    { codigoTOM: 212, codigoIBGE: 2109056, nomeTOM: 'PORTO RICO DO MARANHÃO', nomeIBGE: 'Porto Rico do Maranhão' },
    { codigoTOM: 214, codigoIBGE: 2109239, nomeTOM: 'PRESIDENTE MÉDICI', nomeIBGE: 'Presidente Médici' },
    { codigoTOM: 216, codigoIBGE: 2109270, nomeTOM: 'PRESIDENTE SARNEY', nomeIBGE: 'Presidente Sarney' },
    { codigoTOM: 218, codigoIBGE: 2109452, nomeTOM: 'RAPOSA', nomeIBGE: 'Raposa' },
    { codigoTOM: 220, codigoIBGE: 2109551, nomeTOM: 'RIBAMAR FIQUENE', nomeIBGE: 'Ribamar Fiquene' },
    {
      codigoTOM: 222,
      codigoIBGE: 2109759,
      nomeTOM: 'SANTA FILOMENA DO MARANHÃO',
      nomeIBGE: 'Santa Filomena do Maranhão',
    },
    { codigoTOM: 224, codigoIBGE: 2110237, nomeTOM: 'SANTANA DO MARANHÃO', nomeIBGE: 'Santana do Maranhão' },
    { codigoTOM: 226, codigoIBGE: 2110278, nomeTOM: 'SANTO AMARO DO MARANHÃO', nomeIBGE: 'Santo Amaro do Maranhão' },
    { codigoTOM: 228, codigoIBGE: 2110658, nomeTOM: 'SÃO DOMINGOS DO AZEITÃO', nomeIBGE: 'São Domingos do Azeitão' },
    { codigoTOM: 230, codigoIBGE: 2110856, nomeTOM: 'SÃO FRANCISCO DO BREJÃO', nomeIBGE: 'São Francisco do Brejão' },
    { codigoTOM: 232, codigoIBGE: 2111029, nomeTOM: 'SÃO JOÃO DO CARÚ', nomeIBGE: 'São João do Carú' },
    { codigoTOM: 234, codigoIBGE: 2111052, nomeTOM: 'SÃO JOÃO DO PARAÍSO', nomeIBGE: 'São João do Paraíso' },
    { codigoTOM: 236, codigoIBGE: 2111078, nomeTOM: 'SÃO JOÃO DO SOTER', nomeIBGE: 'São João do Soter' },
    { codigoTOM: 238, codigoIBGE: 2111250, nomeTOM: 'SÃO JOSÉ DOS BASÍLIOS', nomeIBGE: 'São José dos Basílios' },
    { codigoTOM: 240, codigoIBGE: 2111532, nomeTOM: 'SÃO PEDRO DA ÁGUA BRANCA', nomeIBGE: 'São Pedro da Água Branca' },
    { codigoTOM: 242, codigoIBGE: 2111573, nomeTOM: 'SÃO PEDRO DOS CRENTES', nomeIBGE: 'São Pedro dos Crentes' },
    {
      codigoTOM: 244,
      codigoIBGE: 2111631,
      nomeTOM: 'SÃO RAIMUNDO DO DOCA BEZERRA',
      nomeIBGE: 'São Raimundo do Doca Bezerra',
    },
    { codigoTOM: 246, codigoIBGE: 2111672, nomeTOM: 'SÃO ROBERTO', nomeIBGE: 'São Roberto' },
    { codigoTOM: 248, codigoIBGE: 2111722, nomeTOM: 'SATUBINHA', nomeIBGE: 'Satubinha' },
    { codigoTOM: 250, codigoIBGE: 2111748, nomeTOM: 'SENADOR ALEXANDRE COSTA', nomeIBGE: 'Senador Alexandre Costa' },
    { codigoTOM: 252, codigoIBGE: 2111763, nomeTOM: 'SENADOR LA ROCQUE', nomeIBGE: 'Senador La Rocque' },
    { codigoTOM: 254, codigoIBGE: 2111789, nomeTOM: 'SERRANO DO MARANHÃO', nomeIBGE: 'Serrano do Maranhão' },
    { codigoTOM: 256, codigoIBGE: 2111953, nomeTOM: 'SUCUPIRA DO RIACHÃO', nomeIBGE: 'Sucupira do Riachão' },
    { codigoTOM: 258, codigoIBGE: 2112233, nomeTOM: 'TRIZIDELA DO VALE', nomeIBGE: 'Trizidela do Vale' },
    { codigoTOM: 260, codigoIBGE: 2112274, nomeTOM: 'TUFILÂNDIA', nomeIBGE: 'Tufilândia' },
    { codigoTOM: 262, codigoIBGE: 2112456, nomeTOM: 'TURILÂNDIA', nomeIBGE: 'Turilândia' },
    { codigoTOM: 264, codigoIBGE: 2112852, nomeTOM: 'VILA NOVA DOS MARTÍRIOS', nomeIBGE: 'Vila Nova dos Martírios' },
    { codigoTOM: 701, codigoIBGE: 2100105, nomeTOM: 'AFONSO CUNHA', nomeIBGE: 'Afonso Cunha' },
    { codigoTOM: 703, codigoIBGE: 2100204, nomeTOM: 'ALCÂNTARA', nomeIBGE: 'Alcântara' },
    { codigoTOM: 705, codigoIBGE: 2100303, nomeTOM: 'ALDEIAS ALTAS', nomeIBGE: 'Aldeias Altas' },
    { codigoTOM: 707, codigoIBGE: 2100402, nomeTOM: 'ALTAMIRA DO MARANHÃO', nomeIBGE: 'Altamira do Maranhão' },
    { codigoTOM: 709, codigoIBGE: 2100501, nomeTOM: 'ALTO PARNAÍBA', nomeIBGE: 'Alto Parnaíba' },
    { codigoTOM: 711, codigoIBGE: 2100600, nomeTOM: 'AMARANTE DO MARANHÃO', nomeIBGE: 'Amarante do Maranhão' },
    { codigoTOM: 713, codigoIBGE: 2100709, nomeTOM: 'ANAJATUBA', nomeIBGE: 'Anajatuba' },
    { codigoTOM: 715, codigoIBGE: 2100808, nomeTOM: 'ANAPURUS', nomeIBGE: 'Anapurus' },
    { codigoTOM: 717, codigoIBGE: 2100907, nomeTOM: 'ARAIOSES', nomeIBGE: 'Araioses' },
    { codigoTOM: 719, codigoIBGE: 2101004, nomeTOM: 'ARARI', nomeIBGE: 'Arari' },
    { codigoTOM: 721, codigoIBGE: 2101103, nomeTOM: 'AXIXÁ', nomeIBGE: 'Axixá' },
    { codigoTOM: 723, codigoIBGE: 2101202, nomeTOM: 'BACABAL', nomeIBGE: 'Bacabal' },
    { codigoTOM: 725, codigoIBGE: 2101301, nomeTOM: 'BACURI', nomeIBGE: 'Bacuri' },
    { codigoTOM: 727, codigoIBGE: 2101400, nomeTOM: 'BALSAS', nomeIBGE: 'Balsas' },
    { codigoTOM: 729, codigoIBGE: 2101509, nomeTOM: 'BARÃO DE GRAJAÚ', nomeIBGE: 'Barão de Grajaú' },
    { codigoTOM: 731, codigoIBGE: 2101608, nomeTOM: 'BARRA DO CORDA', nomeIBGE: 'Barra do Corda' },
    { codigoTOM: 733, codigoIBGE: 2101707, nomeTOM: 'BARREIRINHAS', nomeIBGE: 'Barreirinhas' },
    { codigoTOM: 735, codigoIBGE: 2101806, nomeTOM: 'BENEDITO LEITE', nomeIBGE: 'Benedito Leite' },
    { codigoTOM: 737, codigoIBGE: 2101905, nomeTOM: 'BEQUIMÃO', nomeIBGE: 'Bequimão' },
    { codigoTOM: 739, codigoIBGE: 2102101, nomeTOM: 'BREJO', nomeIBGE: 'Brejo' },
    { codigoTOM: 741, codigoIBGE: 2102200, nomeTOM: 'BURITI', nomeIBGE: 'Buriti' },
    { codigoTOM: 743, codigoIBGE: 2102309, nomeTOM: 'BURITI BRAVO', nomeIBGE: 'Buriti Bravo' },
    { codigoTOM: 745, codigoIBGE: 2102408, nomeTOM: 'CAJAPIÓ', nomeIBGE: 'Cajapió' },
    { codigoTOM: 747, codigoIBGE: 2102507, nomeTOM: 'CAJARI', nomeIBGE: 'Cajari' },
    { codigoTOM: 749, codigoIBGE: 2102606, nomeTOM: 'CÂNDIDO MENDES', nomeIBGE: 'Cândido Mendes' },
    { codigoTOM: 751, codigoIBGE: 2102705, nomeTOM: 'CANTANHEDE', nomeIBGE: 'Cantanhede' },
    { codigoTOM: 753, codigoIBGE: 2102804, nomeTOM: 'CAROLINA', nomeIBGE: 'Carolina' },
    { codigoTOM: 755, codigoIBGE: 2102903, nomeTOM: 'CARUTAPERA', nomeIBGE: 'Carutapera' },
    { codigoTOM: 757, codigoIBGE: 2103000, nomeTOM: 'CAXIAS', nomeIBGE: 'Caxias' },
    { codigoTOM: 759, codigoIBGE: 2103109, nomeTOM: 'CEDRAL', nomeIBGE: 'Cedral' },
    { codigoTOM: 761, codigoIBGE: 2103208, nomeTOM: 'CHAPADINHA', nomeIBGE: 'Chapadinha' },
    { codigoTOM: 763, codigoIBGE: 2103307, nomeTOM: 'CODÓ', nomeIBGE: 'Codó' },
    { codigoTOM: 765, codigoIBGE: 2103406, nomeTOM: 'COELHO NETO', nomeIBGE: 'Coelho Neto' },
    { codigoTOM: 767, codigoIBGE: 2103505, nomeTOM: 'COLINAS', nomeIBGE: 'Colinas' },
    { codigoTOM: 769, codigoIBGE: 2103604, nomeTOM: 'COROATÁ', nomeIBGE: 'Coroatá' },
    { codigoTOM: 771, codigoIBGE: 2103703, nomeTOM: 'CURURUPU', nomeIBGE: 'Cururupu' },
    { codigoTOM: 773, codigoIBGE: 2103802, nomeTOM: 'DOM PEDRO', nomeIBGE: 'Dom Pedro' },
    { codigoTOM: 775, codigoIBGE: 2103901, nomeTOM: 'DUQUE BACELAR', nomeIBGE: 'Duque Bacelar' },
    { codigoTOM: 777, codigoIBGE: 2104008, nomeTOM: 'ESPERANTINÓPOLIS', nomeIBGE: 'Esperantinópolis' },
    { codigoTOM: 779, codigoIBGE: 2104107, nomeTOM: 'FORTALEZA DOS NOGUEIRAS', nomeIBGE: 'Fortaleza dos Nogueiras' },
    { codigoTOM: 781, codigoIBGE: 2104206, nomeTOM: 'FORTUNA', nomeIBGE: 'Fortuna' },
    { codigoTOM: 783, codigoIBGE: 2104305, nomeTOM: 'GODOFREDO VIANA', nomeIBGE: 'Godofredo Viana' },
    { codigoTOM: 785, codigoIBGE: 2104404, nomeTOM: 'GONÇALVES DIAS', nomeIBGE: 'Gonçalves Dias' },
    { codigoTOM: 787, codigoIBGE: 2104503, nomeTOM: 'GOVERNADOR ARCHER', nomeIBGE: 'Governador Archer' },
    {
      codigoTOM: 789,
      codigoIBGE: 2104602,
      nomeTOM: 'GOVERNADOR EUGÊNIO BARROS',
      nomeIBGE: 'Governador Eugênio Barros',
    },
    { codigoTOM: 791, codigoIBGE: 2104701, nomeTOM: 'GRAÇA ARANHA', nomeIBGE: 'Graça Aranha' },
    { codigoTOM: 793, codigoIBGE: 2104800, nomeTOM: 'GRAJAÚ', nomeIBGE: 'Grajaú' },
    { codigoTOM: 795, codigoIBGE: 2104909, nomeTOM: 'GUIMARÃES', nomeIBGE: 'Guimarães' },
    { codigoTOM: 797, codigoIBGE: 2105005, nomeTOM: 'HUMBERTO DE CAMPOS', nomeIBGE: 'Humberto de Campos' },
    { codigoTOM: 799, codigoIBGE: 2105104, nomeTOM: 'ICATU', nomeIBGE: 'Icatu' },
    { codigoTOM: 801, codigoIBGE: 2105203, nomeTOM: 'IGARAPÉ GRANDE', nomeIBGE: 'Igarapé Grande' },
    { codigoTOM: 803, codigoIBGE: 2105302, nomeTOM: 'IMPERATRIZ', nomeIBGE: 'Imperatriz' },
    {
      codigoTOM: 805,
      codigoIBGE: 2111409,
      nomeTOM: 'SÃO LUÍS GONZAGA DO MARANHÃO',
      nomeIBGE: 'São Luís Gonzaga do Maranhão',
    },
    { codigoTOM: 807, codigoIBGE: 2105401, nomeTOM: 'ITAPECURU MIRIM', nomeIBGE: 'Itapecuru Mirim' },
    { codigoTOM: 809, codigoIBGE: 2105500, nomeTOM: 'JOÃO LISBOA', nomeIBGE: 'João Lisboa' },
    { codigoTOM: 811, codigoIBGE: 2105609, nomeTOM: 'JOSELÂNDIA', nomeIBGE: 'Joselândia' },
    { codigoTOM: 813, codigoIBGE: 2105708, nomeTOM: 'LAGO DA PEDRA', nomeIBGE: 'Lago da Pedra' },
    { codigoTOM: 815, codigoIBGE: 2105807, nomeTOM: 'LAGO DO JUNCO', nomeIBGE: 'Lago do Junco' },
    { codigoTOM: 817, codigoIBGE: 2105906, nomeTOM: 'LAGO VERDE', nomeIBGE: 'Lago Verde' },
    { codigoTOM: 819, codigoIBGE: 2106003, nomeTOM: 'LIMA CAMPOS', nomeIBGE: 'Lima Campos' },
    { codigoTOM: 821, codigoIBGE: 2106102, nomeTOM: 'LORETO', nomeIBGE: 'Loreto' },
    { codigoTOM: 823, codigoIBGE: 2106201, nomeTOM: 'LUÍS DOMINGUES', nomeIBGE: 'Luís Domingues' },
    { codigoTOM: 825, codigoIBGE: 2106300, nomeTOM: 'MAGALHÃES DE ALMEIDA', nomeIBGE: 'Magalhães de Almeida' },
    { codigoTOM: 827, codigoIBGE: 2106409, nomeTOM: 'MATA ROMA', nomeIBGE: 'Mata Roma' },
    { codigoTOM: 829, codigoIBGE: 2106508, nomeTOM: 'MATINHA', nomeIBGE: 'Matinha' },
    { codigoTOM: 831, codigoIBGE: 2106607, nomeTOM: 'MATÕES', nomeIBGE: 'Matões' },
    { codigoTOM: 833, codigoIBGE: 2106706, nomeTOM: 'MIRADOR', nomeIBGE: 'Mirador' },
    { codigoTOM: 835, codigoIBGE: 2106805, nomeTOM: 'MIRINZAL', nomeIBGE: 'Mirinzal' },
    { codigoTOM: 837, codigoIBGE: 2106904, nomeTOM: 'MONÇÃO', nomeIBGE: 'Monção' },
    { codigoTOM: 839, codigoIBGE: 2107001, nomeTOM: 'MONTES ALTOS', nomeIBGE: 'Montes Altos' },
    { codigoTOM: 841, codigoIBGE: 2107100, nomeTOM: 'MORROS', nomeIBGE: 'Morros' },
    { codigoTOM: 843, codigoIBGE: 2107209, nomeTOM: 'NINA RODRIGUES', nomeIBGE: 'Nina Rodrigues' },
    { codigoTOM: 845, codigoIBGE: 2107308, nomeTOM: 'NOVA IORQUE', nomeIBGE: 'Nova Iorque' },
    { codigoTOM: 847, codigoIBGE: 2107407, nomeTOM: "OLHO D'ÁGUA DAS CUNHÃS", nomeIBGE: "Olho d'Água das Cunhãs" },
    { codigoTOM: 849, codigoIBGE: 2107506, nomeTOM: 'PAÇO DO LUMIAR', nomeIBGE: 'Paço do Lumiar' },
    { codigoTOM: 851, codigoIBGE: 2107605, nomeTOM: 'PALMEIRÂNDIA', nomeIBGE: 'Palmeirândia' },
    { codigoTOM: 853, codigoIBGE: 2107704, nomeTOM: 'PARAIBANO', nomeIBGE: 'Paraibano' },
    { codigoTOM: 855, codigoIBGE: 2107803, nomeTOM: 'PARNARAMA', nomeIBGE: 'Parnarama' },
    { codigoTOM: 857, codigoIBGE: 2107902, nomeTOM: 'PASSAGEM FRANCA', nomeIBGE: 'Passagem Franca' },
    { codigoTOM: 859, codigoIBGE: 2108009, nomeTOM: 'PASTOS BONS', nomeIBGE: 'Pastos Bons' },
    { codigoTOM: 861, codigoIBGE: 2108207, nomeTOM: 'PEDREIRAS', nomeIBGE: 'Pedreiras' },
    { codigoTOM: 863, codigoIBGE: 2108306, nomeTOM: 'PENALVA', nomeIBGE: 'Penalva' },
    { codigoTOM: 865, codigoIBGE: 2108405, nomeTOM: 'PERI MIRIM', nomeIBGE: 'Peri Mirim' },
    { codigoTOM: 867, codigoIBGE: 2108504, nomeTOM: 'PINDARE MIRIM', nomeIBGE: 'Pindaré-Mirim' },
    { codigoTOM: 869, codigoIBGE: 2108603, nomeTOM: 'PINHEIRO', nomeIBGE: 'Pinheiro' },
    { codigoTOM: 871, codigoIBGE: 2108702, nomeTOM: 'PIO XII', nomeIBGE: 'Pio XII' },
    { codigoTOM: 873, codigoIBGE: 2108801, nomeTOM: 'PIRAPEMAS', nomeIBGE: 'Pirapemas' },
    { codigoTOM: 875, codigoIBGE: 2108900, nomeTOM: 'POÇÃO DE PEDRAS', nomeIBGE: 'Poção de Pedras' },
    { codigoTOM: 877, codigoIBGE: 2109007, nomeTOM: 'PORTO FRANCO', nomeIBGE: 'Porto Franco' },
    { codigoTOM: 879, codigoIBGE: 2109106, nomeTOM: 'PRESIDENTE DUTRA', nomeIBGE: 'Presidente Dutra' },
    { codigoTOM: 881, codigoIBGE: 2109205, nomeTOM: 'PRESIDENTE JUSCELINO', nomeIBGE: 'Presidente Juscelino' },
    { codigoTOM: 883, codigoIBGE: 2109304, nomeTOM: 'PRESIDENTE VARGAS', nomeIBGE: 'Presidente Vargas' },
    { codigoTOM: 885, codigoIBGE: 2109403, nomeTOM: 'PRIMEIRA CRUZ', nomeIBGE: 'Primeira Cruz' },
    { codigoTOM: 887, codigoIBGE: 2109502, nomeTOM: 'RIACHÃO', nomeIBGE: 'Riachão' },
    { codigoTOM: 889, codigoIBGE: 2111201, nomeTOM: 'SÃO JOSÉ DE RIBAMAR', nomeIBGE: 'São José de Ribamar' },
    { codigoTOM: 891, codigoIBGE: 2109601, nomeTOM: 'ROSÁRIO', nomeIBGE: 'Rosário' },
    { codigoTOM: 893, codigoIBGE: 2109700, nomeTOM: 'SAMBAÍBA', nomeIBGE: 'Sambaíba' },
    { codigoTOM: 895, codigoIBGE: 2109809, nomeTOM: 'SANTA HELENA', nomeIBGE: 'Santa Helena' },
    { codigoTOM: 897, codigoIBGE: 2110005, nomeTOM: 'SANTA LUZIA', nomeIBGE: 'Santa Luzia' },
    {
      codigoTOM: 899,
      codigoIBGE: 2110104,
      nomeTOM: 'SANTA QUITÉRIA DO MARANHÃO',
      nomeIBGE: 'Santa Quitéria do Maranhão',
    },
    { codigoTOM: 901, codigoIBGE: 2110203, nomeTOM: 'SANTA RITA', nomeIBGE: 'Santa Rita' },
    { codigoTOM: 903, codigoIBGE: 2110302, nomeTOM: 'SANTO ANTÔNIO DOS LOPES', nomeIBGE: 'Santo Antônio dos Lopes' },
    {
      codigoTOM: 905,
      codigoIBGE: 2110401,
      nomeTOM: 'SÃO BENEDITO DO RIO PRETO',
      nomeIBGE: 'São Benedito do Rio Preto',
    },
    { codigoTOM: 907, codigoIBGE: 2110500, nomeTOM: 'SÃO BENTO', nomeIBGE: 'São Bento' },
    { codigoTOM: 909, codigoIBGE: 2110609, nomeTOM: 'SÃO BERNARDO', nomeIBGE: 'São Bernardo' },
    { codigoTOM: 911, codigoIBGE: 2110708, nomeTOM: 'SÃO DOMINGOS DO MARANHÃO', nomeIBGE: 'São Domingos do Maranhão' },
    { codigoTOM: 913, codigoIBGE: 2110807, nomeTOM: 'SÃO FÉLIX DE BALSAS', nomeIBGE: 'São Félix de Balsas' },
    {
      codigoTOM: 915,
      codigoIBGE: 2110906,
      nomeTOM: 'SÃO FRANCISCO DO MARANHÃO',
      nomeIBGE: 'São Francisco do Maranhão',
    },
    { codigoTOM: 917, codigoIBGE: 2111003, nomeTOM: 'SÃO JOÃO BATISTA', nomeIBGE: 'São João Batista' },
    { codigoTOM: 919, codigoIBGE: 2111102, nomeTOM: 'SÃO JOÃO DOS PATOS', nomeIBGE: 'São João dos Patos' },
    { codigoTOM: 921, codigoIBGE: 2111300, nomeTOM: 'SÃO LUÍS', nomeIBGE: 'São Luís' },
    { codigoTOM: 923, codigoIBGE: 2111508, nomeTOM: 'SÃO MATEUS DO MARANHÃO', nomeIBGE: 'São Mateus do Maranhão' },
    {
      codigoTOM: 925,
      codigoIBGE: 2111607,
      nomeTOM: 'SÃO RAIMUNDO DAS MANGABEIRAS',
      nomeIBGE: 'São Raimundo das Mangabeiras',
    },
    { codigoTOM: 927, codigoIBGE: 2111706, nomeTOM: 'SÃO VICENTE FERRER', nomeIBGE: 'São Vicente Ferrer' },
    { codigoTOM: 929, codigoIBGE: 2111805, nomeTOM: 'SÍTIO NOVO', nomeIBGE: 'Sítio Novo' },
    { codigoTOM: 931, codigoIBGE: 2111904, nomeTOM: 'SUCUPIRA DO NORTE', nomeIBGE: 'Sucupira do Norte' },
    { codigoTOM: 933, codigoIBGE: 2112001, nomeTOM: 'TASSO FRAGOSO', nomeIBGE: 'Tasso Fragoso' },
    { codigoTOM: 935, codigoIBGE: 2112100, nomeTOM: 'TIMBIRAS', nomeIBGE: 'Timbiras' },
    { codigoTOM: 937, codigoIBGE: 2112209, nomeTOM: 'TIMON', nomeIBGE: 'Timon' },
    { codigoTOM: 939, codigoIBGE: 2112308, nomeTOM: 'TUNTUM', nomeIBGE: 'Tuntum' },
    { codigoTOM: 941, codigoIBGE: 2112407, nomeTOM: 'TURIAÇU', nomeIBGE: 'Turiaçu' },
    { codigoTOM: 943, codigoIBGE: 2112506, nomeTOM: 'TUTÓIA', nomeIBGE: 'Tutóia' },
    { codigoTOM: 945, codigoIBGE: 2112605, nomeTOM: 'URBANO SANTOS', nomeIBGE: 'Urbano Santos' },
    { codigoTOM: 947, codigoIBGE: 2112704, nomeTOM: 'VARGEM GRANDE', nomeIBGE: 'Vargem Grande' },
    { codigoTOM: 949, codigoIBGE: 2112803, nomeTOM: 'VIANA', nomeIBGE: 'Viana' },
    { codigoTOM: 951, codigoIBGE: 2112902, nomeTOM: 'VITÓRIA DO MEARIM', nomeIBGE: 'Vitória do Mearim' },
    { codigoTOM: 953, codigoIBGE: 2113009, nomeTOM: 'VITORINO FREIRE', nomeIBGE: 'Vitorino Freire' },
    { codigoTOM: 955, codigoIBGE: 2102002, nomeTOM: 'BOM JARDIM', nomeIBGE: 'Bom Jardim' },
    { codigoTOM: 957, codigoIBGE: 2109908, nomeTOM: 'SANTA INÊS', nomeIBGE: 'Santa Inês' },
    { codigoTOM: 959, codigoIBGE: 2108108, nomeTOM: 'PAULO RAMOS', nomeIBGE: 'Paulo Ramos' },
    { codigoTOM: 961, codigoIBGE: 2100055, nomeTOM: 'AÇAILÂNDIA', nomeIBGE: 'Açailândia' },
    { codigoTOM: 963, codigoIBGE: 2104057, nomeTOM: 'ESTREITO', nomeIBGE: 'Estreito' },
    { codigoTOM: 1281, codigoIBGE: 2100956, nomeTOM: 'ARAME', nomeIBGE: 'Arame' },
    { codigoTOM: 1283, codigoIBGE: 2106755, nomeTOM: 'MIRANDA DO NORTE', nomeIBGE: 'Miranda do Norte' },
    { codigoTOM: 1285, codigoIBGE: 2110039, nomeTOM: 'SANTA LUZIA DO PARUÁ', nomeIBGE: 'Santa Luzia do Paruá' },
    { codigoTOM: 1287, codigoIBGE: 2114007, nomeTOM: 'ZÉ DOCA', nomeIBGE: 'Zé Doca' },
  ],
  [Estados.AC]: [
    { codigoTOM: 105, codigoIBGE: 1200104, nomeTOM: 'BRASILÉIA', nomeIBGE: 'Brasiléia' },
    { codigoTOM: 107, codigoIBGE: 1200203, nomeTOM: 'CRUZEIRO DO SUL', nomeIBGE: 'Cruzeiro do Sul' },
    { codigoTOM: 109, codigoIBGE: 1200336, nomeTOM: 'MÂNCIO LIMA', nomeIBGE: 'Mâncio Lima' },
    { codigoTOM: 113, codigoIBGE: 1200302, nomeTOM: 'FEIJÓ', nomeIBGE: 'Feijó' },
    { codigoTOM: 139, codigoIBGE: 1200401, nomeTOM: 'RIO BRANCO', nomeIBGE: 'Rio Branco' },
    { codigoTOM: 145, codigoIBGE: 1200500, nomeTOM: 'SENA MADUREIRA', nomeIBGE: 'Sena Madureira' },
    { codigoTOM: 147, codigoIBGE: 1200609, nomeTOM: 'TARAUACÁ', nomeIBGE: 'Tarauacá' },
    { codigoTOM: 149, codigoIBGE: 1200708, nomeTOM: 'XAPURI', nomeIBGE: 'Xapuri' },
    { codigoTOM: 151, codigoIBGE: 1200385, nomeTOM: 'PLÁCIDO DE CASTRO', nomeIBGE: 'Plácido de Castro' },
    { codigoTOM: 153, codigoIBGE: 1200450, nomeTOM: 'SENADOR GUIOMARD', nomeIBGE: 'Senador Guiomard' },
    { codigoTOM: 155, codigoIBGE: 1200344, nomeTOM: 'MANOEL URBANO', nomeIBGE: 'Manoel Urbano' },
    { codigoTOM: 157, codigoIBGE: 1200054, nomeTOM: 'ASSIS BRASIL', nomeIBGE: 'Assis Brasil' },
    { codigoTOM: 643, codigoIBGE: 1200013, nomeTOM: 'ACRELÂNDIA', nomeIBGE: 'Acrelândia' },
    { codigoTOM: 645, codigoIBGE: 1200138, nomeTOM: 'BUJARI', nomeIBGE: 'Bujari' },
    { codigoTOM: 647, codigoIBGE: 1200179, nomeTOM: 'CAPIXABA', nomeIBGE: 'Capixaba' },
    { codigoTOM: 649, codigoIBGE: 1200807, nomeTOM: 'PORTO ACRE', nomeIBGE: 'Porto Acre' },
    { codigoTOM: 651, codigoIBGE: 1200252, nomeTOM: 'EPITACIOLÂNDIA', nomeIBGE: 'Epitaciolândia' },
    { codigoTOM: 653, codigoIBGE: 1200328, nomeTOM: 'JORDÃO', nomeIBGE: 'Jordão' },
    { codigoTOM: 655, codigoIBGE: 1200351, nomeTOM: 'MARECHAL THAUMATURGO', nomeIBGE: 'Marechal Thaumaturgo' },
    { codigoTOM: 657, codigoIBGE: 1200393, nomeTOM: 'PORTO WALTER', nomeIBGE: 'Porto Walter' },
    { codigoTOM: 659, codigoIBGE: 1200427, nomeTOM: 'RODRIGUES ALVES', nomeIBGE: 'Rodrigues Alves' },
    { codigoTOM: 661, codigoIBGE: 1200435, nomeTOM: 'SANTA ROSA DO PURUS', nomeIBGE: 'Santa Rosa do Purus' },
  ],
  [Estados.MS]: [
    { codigoTOM: 141, codigoIBGE: 5000252, nomeTOM: 'ALCINÓPOLIS', nomeIBGE: 'Alcinópolis' },
    { codigoTOM: 143, codigoIBGE: 5006002, nomeTOM: 'NOVA ALVORADA DO SUL', nomeIBGE: 'Nova Alvorada do Sul' },
    { codigoTOM: 159, codigoIBGE: 5006259, nomeTOM: 'NOVO HORIZONTE DO SUL', nomeIBGE: 'Novo Horizonte do Sul' },
    { codigoTOM: 161, codigoIBGE: 5004809, nomeTOM: 'JAPORÃ', nomeIBGE: 'Japorã' },
    { codigoTOM: 163, codigoIBGE: 5005251, nomeTOM: 'LAGUNA CARAPÃ', nomeIBGE: 'Laguna Carapã' },
    { codigoTOM: 1178, codigoIBGE: 5003900, nomeTOM: 'FIGUEIRÃO', nomeIBGE: 'Figueirão' },
    { codigoTOM: 1196, codigoIBGE: 5006275, nomeTOM: 'PARAÍSO DAS ÁGUAS', nomeIBGE: 'Paraíso das Águas' },
    { codigoTOM: 9003, codigoIBGE: 5000203, nomeTOM: 'ÁGUA CLARA', nomeIBGE: 'Água Clara' },
    { codigoTOM: 9011, codigoIBGE: 5000609, nomeTOM: 'AMAMBAÍ', nomeIBGE: 'Amambai' },
    { codigoTOM: 9013, codigoIBGE: 5000708, nomeTOM: 'ANASTÁCIO', nomeIBGE: 'Anastácio' },
    { codigoTOM: 9015, codigoIBGE: 5000807, nomeTOM: 'ANAURILÂNDIA', nomeIBGE: 'Anaurilândia' },
    { codigoTOM: 9017, codigoIBGE: 5000906, nomeTOM: 'ANTÔNIO JOÃO', nomeIBGE: 'Antônio João' },
    { codigoTOM: 9019, codigoIBGE: 5001003, nomeTOM: 'APARECIDA DO TABOADO', nomeIBGE: 'Aparecida do Taboado' },
    { codigoTOM: 9021, codigoIBGE: 5001102, nomeTOM: 'AQUIDAUANA', nomeIBGE: 'Aquidauana' },
    { codigoTOM: 9029, codigoIBGE: 5001508, nomeTOM: 'BANDEIRANTES', nomeIBGE: 'Bandeirantes' },
    { codigoTOM: 9037, codigoIBGE: 5001904, nomeTOM: 'BATAGUASSU', nomeIBGE: 'Bataguassu' },
    { codigoTOM: 9039, codigoIBGE: 5002001, nomeTOM: 'BATAYPORÃ', nomeIBGE: 'Batayporã' },
    { codigoTOM: 9041, codigoIBGE: 5002100, nomeTOM: 'BELA VISTA', nomeIBGE: 'Bela Vista' },
    { codigoTOM: 9043, codigoIBGE: 5002209, nomeTOM: 'BONITO', nomeIBGE: 'Bonito' },
    { codigoTOM: 9045, codigoIBGE: 5002308, nomeTOM: 'BRASILÂNDIA', nomeIBGE: 'Brasilândia' },
    { codigoTOM: 9049, codigoIBGE: 5002605, nomeTOM: 'CAMAPUÃ', nomeIBGE: 'Camapuã' },
    { codigoTOM: 9051, codigoIBGE: 5002704, nomeTOM: 'CAMPO GRANDE', nomeIBGE: 'Campo Grande' },
    { codigoTOM: 9053, codigoIBGE: 5002803, nomeTOM: 'CARACOL', nomeIBGE: 'Caracol' },
    { codigoTOM: 9055, codigoIBGE: 5002407, nomeTOM: 'CAARAPÓ', nomeIBGE: 'Caarapó' },
    { codigoTOM: 9057, codigoIBGE: 5002902, nomeTOM: 'CASSILÂNDIA', nomeIBGE: 'Cassilândia' },
    { codigoTOM: 9061, codigoIBGE: 5003108, nomeTOM: 'CORGUINHO', nomeIBGE: 'Corguinho' },
    { codigoTOM: 9063, codigoIBGE: 5003207, nomeTOM: 'CORUMBÁ', nomeIBGE: 'Corumbá' },
    { codigoTOM: 9065, codigoIBGE: 5003306, nomeTOM: 'COXIM', nomeIBGE: 'Coxim' },
    { codigoTOM: 9073, codigoIBGE: 5003702, nomeTOM: 'DOURADOS', nomeIBGE: 'Dourados' },
    { codigoTOM: 9075, codigoIBGE: 5003801, nomeTOM: 'FÁTIMA DO SUL', nomeIBGE: 'Fátima do Sul' },
    { codigoTOM: 9079, codigoIBGE: 5004007, nomeTOM: 'GLÓRIA DE DOURADOS', nomeIBGE: 'Glória de Dourados' },
    { codigoTOM: 9081, codigoIBGE: 5004106, nomeTOM: 'GUIA LOPES DA LAGUNA', nomeIBGE: 'Guia Lopes da Laguna' },
    { codigoTOM: 9085, codigoIBGE: 5004304, nomeTOM: 'IGUATEMI', nomeIBGE: 'Iguatemi' },
    { codigoTOM: 9087, codigoIBGE: 5004403, nomeTOM: 'INOCÊNCIA', nomeIBGE: 'Inocência' },
    { codigoTOM: 9089, codigoIBGE: 5004502, nomeTOM: 'ITAPORÃ', nomeIBGE: 'Itaporã' },
    { codigoTOM: 9093, codigoIBGE: 5004700, nomeTOM: 'IVINHEMA', nomeIBGE: 'Ivinhema' },
    { codigoTOM: 9097, codigoIBGE: 5004908, nomeTOM: 'JARAGUARI', nomeIBGE: 'Jaraguari' },
    { codigoTOM: 9099, codigoIBGE: 5005004, nomeTOM: 'JARDIM', nomeIBGE: 'Jardim' },
    { codigoTOM: 9101, codigoIBGE: 5005103, nomeTOM: 'JATEÍ', nomeIBGE: 'Jateí' },
    { codigoTOM: 9103, codigoIBGE: 5005202, nomeTOM: 'LADÁRIO', nomeIBGE: 'Ladário' },
    { codigoTOM: 9107, codigoIBGE: 5005400, nomeTOM: 'MARACAJU', nomeIBGE: 'Maracaju' },
    { codigoTOM: 9111, codigoIBGE: 5005608, nomeTOM: 'MIRANDA', nomeIBGE: 'Miranda' },
    { codigoTOM: 9113, codigoIBGE: 5005707, nomeTOM: 'NAVIRAÍ', nomeIBGE: 'Naviraí' },
    { codigoTOM: 9115, codigoIBGE: 5005806, nomeTOM: 'NIOAQUE', nomeIBGE: 'Nioaque' },
    { codigoTOM: 9123, codigoIBGE: 5006200, nomeTOM: 'NOVA ANDRADINA', nomeIBGE: 'Nova Andradina' },
    { codigoTOM: 9125, codigoIBGE: 5006309, nomeTOM: 'PARANAÍBA', nomeIBGE: 'Paranaíba' },
    { codigoTOM: 9127, codigoIBGE: 5006408, nomeTOM: 'PEDRO GOMES', nomeIBGE: 'Pedro Gomes' },
    { codigoTOM: 9131, codigoIBGE: 5006606, nomeTOM: 'PONTA PORÃ', nomeIBGE: 'Ponta Porã' },
    { codigoTOM: 9137, codigoIBGE: 5006903, nomeTOM: 'PORTO MURTINHO', nomeIBGE: 'Porto Murtinho' },
    { codigoTOM: 9141, codigoIBGE: 5007109, nomeTOM: 'RIBAS DO RIO PARDO', nomeIBGE: 'Ribas do Rio Pardo' },
    { codigoTOM: 9143, codigoIBGE: 5007208, nomeTOM: 'RIO BRILHANTE', nomeIBGE: 'Rio Brilhante' },
    { codigoTOM: 9145, codigoIBGE: 5007307, nomeTOM: 'RIO NEGRO', nomeIBGE: 'Rio Negro' },
    { codigoTOM: 9147, codigoIBGE: 5007406, nomeTOM: 'RIO VERDE DE MATO GROSSO', nomeIBGE: 'Rio Verde de Mato Grosso' },
    { codigoTOM: 9149, codigoIBGE: 5007505, nomeTOM: 'ROCHEDO', nomeIBGE: 'Rochedo' },
    { codigoTOM: 9157, codigoIBGE: 5007901, nomeTOM: 'SIDROLÂNDIA', nomeIBGE: 'Sidrolândia' },
    { codigoTOM: 9159, codigoIBGE: 5008008, nomeTOM: 'TERENOS', nomeIBGE: 'Terenos' },
    { codigoTOM: 9165, codigoIBGE: 5008305, nomeTOM: 'TRÊS LAGOAS', nomeIBGE: 'Três Lagoas' },
    { codigoTOM: 9169, codigoIBGE: 5000856, nomeTOM: 'ANGÉLICA', nomeIBGE: 'Angélica' },
    { codigoTOM: 9171, codigoIBGE: 5001243, nomeTOM: 'ARAL MOREIRA', nomeIBGE: 'Aral Moreira' },
    { codigoTOM: 9173, codigoIBGE: 5003751, nomeTOM: 'ELDORADO', nomeIBGE: 'Eldorado' },
    { codigoTOM: 9175, codigoIBGE: 5003454, nomeTOM: 'DEODÁPOLIS', nomeIBGE: 'Deodápolis' },
    { codigoTOM: 9179, codigoIBGE: 5005681, nomeTOM: 'MUNDO NOVO', nomeIBGE: 'Mundo Novo' },
    { codigoTOM: 9187, codigoIBGE: 5008404, nomeTOM: 'VICENTINA', nomeIBGE: 'Vicentina' },
    { codigoTOM: 9739, codigoIBGE: 5006358, nomeTOM: 'PARANHOS', nomeIBGE: 'Paranhos' },
    { codigoTOM: 9745, codigoIBGE: 5007554, nomeTOM: 'SANTA RITA DO PARDO', nomeIBGE: 'Santa Rita do Pardo' },
    { codigoTOM: 9757, codigoIBGE: 5007935, nomeTOM: 'SONORA', nomeIBGE: 'Sonora' },
    { codigoTOM: 9787, codigoIBGE: 5002951, nomeTOM: 'CHAPADÃO DO SUL', nomeIBGE: 'Chapadão do Sul' },
    { codigoTOM: 9793, codigoIBGE: 5003488, nomeTOM: 'DOIS IRMÃOS DO BURITI', nomeIBGE: 'Dois Irmãos do Buriti' },
    { codigoTOM: 9801, codigoIBGE: 5002159, nomeTOM: 'BODOQUENA', nomeIBGE: 'Bodoquena' },
    { codigoTOM: 9803, codigoIBGE: 5003256, nomeTOM: 'COSTA RICA', nomeIBGE: 'Costa Rica' },
    { codigoTOM: 9805, codigoIBGE: 5003504, nomeTOM: 'DOURADINA', nomeIBGE: 'Douradina' },
    { codigoTOM: 9807, codigoIBGE: 5004601, nomeTOM: 'ITAQUIRAÍ', nomeIBGE: 'Itaquiraí' },
    { codigoTOM: 9809, codigoIBGE: 5007695, nomeTOM: 'SÃO GABRIEL DO OESTE', nomeIBGE: 'São Gabriel do Oeste' },
    { codigoTOM: 9811, codigoIBGE: 5007802, nomeTOM: 'SELVÍRIA', nomeIBGE: 'Selvíria' },
    { codigoTOM: 9813, codigoIBGE: 5007703, nomeTOM: 'SETE QUEDAS', nomeIBGE: 'Sete Quedas' },
    { codigoTOM: 9815, codigoIBGE: 5007950, nomeTOM: 'TACURU', nomeIBGE: 'Tacuru' },
    { codigoTOM: 9817, codigoIBGE: 5007976, nomeTOM: 'TAQUARUSSU', nomeIBGE: 'Taquarussu' },
    { codigoTOM: 9923, codigoIBGE: 5005152, nomeTOM: 'JUTI', nomeIBGE: 'Juti' },
    { codigoTOM: 9997, codigoIBGE: 5003157, nomeTOM: 'CORONEL SAPUCAIA', nomeIBGE: '' },
  ],
  [Estados.AM]: [
    { codigoTOM: 201, codigoIBGE: 1303205, nomeTOM: 'NOVO AIRÃO', nomeIBGE: 'Novo Airão' },
    { codigoTOM: 203, codigoIBGE: 1300102, nomeTOM: 'ANORI', nomeIBGE: 'Anori' },
    { codigoTOM: 205, codigoIBGE: 1300201, nomeTOM: 'ATALAIA DO NORTE', nomeIBGE: 'Atalaia do Norte' },
    { codigoTOM: 207, codigoIBGE: 1300300, nomeTOM: 'AUTAZES', nomeIBGE: 'Autazes' },
    { codigoTOM: 209, codigoIBGE: 1300409, nomeTOM: 'BARCELOS', nomeIBGE: 'Barcelos' },
    { codigoTOM: 211, codigoIBGE: 1300508, nomeTOM: 'BARREIRINHA', nomeIBGE: 'Barreirinha' },
    { codigoTOM: 213, codigoIBGE: 1300607, nomeTOM: 'BENJAMIN CONSTANT', nomeIBGE: 'Benjamin Constant' },
    { codigoTOM: 215, codigoIBGE: 1300706, nomeTOM: 'BOCA DO ACRE', nomeIBGE: 'Boca do Acre' },
    { codigoTOM: 217, codigoIBGE: 1300805, nomeTOM: 'BORBA', nomeIBGE: 'Borba' },
    { codigoTOM: 219, codigoIBGE: 1300904, nomeTOM: 'CANUTAMA', nomeIBGE: 'Canutama' },
    { codigoTOM: 221, codigoIBGE: 1301001, nomeTOM: 'CARAUARI', nomeIBGE: 'Carauari' },
    { codigoTOM: 223, codigoIBGE: 1301100, nomeTOM: 'CAREIRO', nomeIBGE: 'Careiro' },
    { codigoTOM: 225, codigoIBGE: 1301209, nomeTOM: 'COARI', nomeIBGE: 'Coari' },
    { codigoTOM: 227, codigoIBGE: 1301308, nomeTOM: 'CODAJÁS', nomeIBGE: 'Codajás' },
    { codigoTOM: 229, codigoIBGE: 1301407, nomeTOM: 'EIRUNEPÉ', nomeIBGE: 'Eirunepé' },
    { codigoTOM: 231, codigoIBGE: 1301506, nomeTOM: 'ENVIRA', nomeIBGE: 'Envira' },
    { codigoTOM: 233, codigoIBGE: 1301605, nomeTOM: 'FONTE BOA', nomeIBGE: 'Fonte Boa' },
    { codigoTOM: 235, codigoIBGE: 1301704, nomeTOM: 'HUMAITÁ', nomeIBGE: 'Humaitá' },
    {
      codigoTOM: 237,
      codigoIBGE: 1303601,
      nomeTOM: 'SANTA ISABEL DO RIO NEGRO',
      nomeIBGE: 'Santa Isabel do Rio Negro',
    },
    { codigoTOM: 239, codigoIBGE: 1301803, nomeTOM: 'IPIXUNA', nomeIBGE: 'Ipixuna' },
    { codigoTOM: 241, codigoIBGE: 1301902, nomeTOM: 'ITACOATIARA', nomeIBGE: 'Itacoatiara' },
    { codigoTOM: 243, codigoIBGE: 1302009, nomeTOM: 'ITAPIRANGA', nomeIBGE: 'Itapiranga' },
    { codigoTOM: 245, codigoIBGE: 1302108, nomeTOM: 'JAPURÁ', nomeIBGE: 'Japurá' },
    { codigoTOM: 247, codigoIBGE: 1302207, nomeTOM: 'JURUÁ', nomeIBGE: 'Juruá' },
    { codigoTOM: 249, codigoIBGE: 1302306, nomeTOM: 'JUTAÍ', nomeIBGE: 'Jutaí' },
    { codigoTOM: 251, codigoIBGE: 1302405, nomeTOM: 'LÁBREA', nomeIBGE: 'Lábrea' },
    { codigoTOM: 253, codigoIBGE: 1302504, nomeTOM: 'MANACAPURU', nomeIBGE: 'Manacapuru' },
    { codigoTOM: 255, codigoIBGE: 1302603, nomeTOM: 'MANAUS', nomeIBGE: 'Manaus' },
    { codigoTOM: 257, codigoIBGE: 1302702, nomeTOM: 'MANICORÉ', nomeIBGE: 'Manicoré' },
    { codigoTOM: 259, codigoIBGE: 1302801, nomeTOM: 'MARAÃ', nomeIBGE: 'Maraã' },
    { codigoTOM: 261, codigoIBGE: 1302900, nomeTOM: 'MAUÉS', nomeIBGE: 'Maués' },
    { codigoTOM: 263, codigoIBGE: 1303007, nomeTOM: 'NHAMUNDÁ', nomeIBGE: 'Nhamundá' },
    { codigoTOM: 265, codigoIBGE: 1303106, nomeTOM: 'NOVA OLINDA DO NORTE', nomeIBGE: 'Nova Olinda do Norte' },
    { codigoTOM: 267, codigoIBGE: 1303304, nomeTOM: 'NOVO ARIPUANÃ', nomeIBGE: 'Novo Aripuanã' },
    { codigoTOM: 269, codigoIBGE: 1303403, nomeTOM: 'PARINTINS', nomeIBGE: 'Parintins' },
    { codigoTOM: 271, codigoIBGE: 1303502, nomeTOM: 'PAUINI', nomeIBGE: 'Pauini' },
    { codigoTOM: 273, codigoIBGE: 1303700, nomeTOM: 'SANTO ANTÔNIO DO IÇÁ', nomeIBGE: 'Santo Antônio do Içá' },
    { codigoTOM: 275, codigoIBGE: 1303908, nomeTOM: 'SÃO PAULO DE OLIVENÇA', nomeIBGE: 'São Paulo de Olivença' },
    { codigoTOM: 277, codigoIBGE: 1304005, nomeTOM: 'SILVES', nomeIBGE: 'Silves' },
    { codigoTOM: 279, codigoIBGE: 1304104, nomeTOM: 'TAPAUÁ', nomeIBGE: 'Tapauá' },
    { codigoTOM: 281, codigoIBGE: 1304203, nomeTOM: 'TEFÉ', nomeIBGE: 'Tefé' },
    { codigoTOM: 283, codigoIBGE: 1303809, nomeTOM: 'SÃO GABRIEL DA CACHOEIRA', nomeIBGE: 'São Gabriel da Cachoeira' },
    { codigoTOM: 285, codigoIBGE: 1304302, nomeTOM: 'URUCARÁ', nomeIBGE: 'Urucará' },
    { codigoTOM: 287, codigoIBGE: 1304401, nomeTOM: 'URUCURITUBA', nomeIBGE: 'Urucurituba' },
    { codigoTOM: 289, codigoIBGE: 1300029, nomeTOM: 'ALVARÃES', nomeIBGE: 'Alvarães' },
    { codigoTOM: 291, codigoIBGE: 1300060, nomeTOM: 'AMATURÁ', nomeIBGE: 'Amaturá' },
    { codigoTOM: 293, codigoIBGE: 1300086, nomeTOM: 'ANAMÃ', nomeIBGE: 'Anamã' },
    { codigoTOM: 295, codigoIBGE: 1300631, nomeTOM: 'BERURI', nomeIBGE: 'Beruri' },
    { codigoTOM: 297, codigoIBGE: 1300680, nomeTOM: 'BOA VISTA DO RAMOS', nomeIBGE: 'Boa Vista do Ramos' },
    { codigoTOM: 299, codigoIBGE: 1300839, nomeTOM: 'CAAPIRANGA', nomeIBGE: 'Caapiranga' },
    { codigoTOM: 965, codigoIBGE: 1301159, nomeTOM: 'CAREIRO DA VÁRZEA', nomeIBGE: 'Careiro da Várzea' },
    { codigoTOM: 967, codigoIBGE: 1301654, nomeTOM: 'GUAJARÁ', nomeIBGE: 'Guajará' },
    { codigoTOM: 969, codigoIBGE: 1300144, nomeTOM: 'APUÍ', nomeIBGE: 'Apuí' },
    { codigoTOM: 9835, codigoIBGE: 1301852, nomeTOM: 'IRANDUBA', nomeIBGE: 'Iranduba' },
    { codigoTOM: 9837, codigoIBGE: 1301951, nomeTOM: 'ITAMARATI', nomeIBGE: 'Itamarati' },
    { codigoTOM: 9839, codigoIBGE: 1302553, nomeTOM: 'MANAQUIRI', nomeIBGE: 'Manaquiri' },
    { codigoTOM: 9841, codigoIBGE: 1303536, nomeTOM: 'PRESIDENTE FIGUEIREDO', nomeIBGE: 'Presidente Figueiredo' },
    { codigoTOM: 9843, codigoIBGE: 1303569, nomeTOM: 'RIO PRETO DA EVA', nomeIBGE: 'Rio Preto da Eva' },
    { codigoTOM: 9845, codigoIBGE: 1303957, nomeTOM: 'SÃO SEBASTIÃO DO UATUMÃ', nomeIBGE: 'São Sebastião do Uatumã' },
    { codigoTOM: 9847, codigoIBGE: 1304062, nomeTOM: 'TABATINGA', nomeIBGE: 'Tabatinga' },
    { codigoTOM: 9849, codigoIBGE: 1304260, nomeTOM: 'UARINI', nomeIBGE: 'Uarini' },
    { codigoTOM: 9851, codigoIBGE: 1304237, nomeTOM: 'TONANTINS', nomeIBGE: 'Tonantins' },
  ],
  [Estados.PI]: [
    { codigoTOM: 266, codigoIBGE: 2200053, nomeTOM: 'ACAUÃ', nomeIBGE: 'Acauã' },
    { codigoTOM: 268, codigoIBGE: 2200459, nomeTOM: 'ALVORADA DO GURGUÉIA', nomeIBGE: 'Alvorada do Gurguéia' },
    { codigoTOM: 270, codigoIBGE: 2201051, nomeTOM: 'ASSUNÇÃO DO PIAUÍ', nomeIBGE: 'Assunção do Piauí' },
    { codigoTOM: 272, codigoIBGE: 2201176, nomeTOM: "BARRA D'ALCÂNTARA", nomeIBGE: "Barra D'Alcântara" },
    { codigoTOM: 274, codigoIBGE: 2201556, nomeTOM: 'BELA VISTA DO PIAUÍ', nomeIBGE: 'Bela Vista do Piauí' },
    { codigoTOM: 276, codigoIBGE: 2201572, nomeTOM: 'BELÉM DO PIAUÍ', nomeIBGE: 'Belém do Piauí' },
    { codigoTOM: 278, codigoIBGE: 2201739, nomeTOM: 'BETÂNIA DO PIAUÍ', nomeIBGE: 'Betânia do Piauí' },
    { codigoTOM: 280, codigoIBGE: 2201770, nomeTOM: 'BOA HORA', nomeIBGE: 'Boa Hora' },
    { codigoTOM: 282, codigoIBGE: 2201945, nomeTOM: 'BOQUEIRÃO DO PIAUÍ', nomeIBGE: 'Boqueirão do Piauí' },
    { codigoTOM: 284, codigoIBGE: 2201988, nomeTOM: 'BREJO DO PIAUÍ', nomeIBGE: 'Brejo do Piauí' },
    { codigoTOM: 286, codigoIBGE: 2202075, nomeTOM: 'CAJAZEIRAS DO PIAUÍ', nomeIBGE: 'Cajazeiras do Piauí' },
    { codigoTOM: 288, codigoIBGE: 2202083, nomeTOM: 'CAJUEIRO DA PRAIA', nomeIBGE: 'Cajueiro da Praia' },
    { codigoTOM: 290, codigoIBGE: 2202117, nomeTOM: 'CAMPO ALEGRE DO FIDALGO', nomeIBGE: 'Campo Alegre do Fidalgo' },
    { codigoTOM: 292, codigoIBGE: 2202133, nomeTOM: 'CAMPO GRANDE DO PIAUÍ', nomeIBGE: 'Campo Grande do Piauí' },
    { codigoTOM: 294, codigoIBGE: 2202174, nomeTOM: 'CAMPO LARGO DO PIAUÍ', nomeIBGE: 'Campo Largo do Piauí' },
    {
      codigoTOM: 296,
      codigoIBGE: 2202455,
      nomeTOM: 'CAPITÃO GERVÁSIO OLIVEIRA',
      nomeIBGE: 'Capitão Gervásio Oliveira',
    },
    { codigoTOM: 298, codigoIBGE: 2202539, nomeTOM: 'CARAÚBAS DO PIAUÍ', nomeIBGE: 'Caraúbas do Piauí' },
    { codigoTOM: 300, codigoIBGE: 2202554, nomeTOM: 'CARIDADE DO PIAUÍ', nomeIBGE: 'Caridade do Piauí' },
    { codigoTOM: 302, codigoIBGE: 2202653, nomeTOM: 'CAXINGÓ', nomeIBGE: 'Caxingó' },
    { codigoTOM: 304, codigoIBGE: 2202711, nomeTOM: 'COCAL DE TELHA', nomeIBGE: 'Cocal de Telha' },
    { codigoTOM: 306, codigoIBGE: 2202729, nomeTOM: 'COCAL DOS ALVES', nomeIBGE: 'Cocal dos Alves' },
    { codigoTOM: 308, codigoIBGE: 2203230, nomeTOM: 'CURRAIS', nomeIBGE: 'Currais' },
    { codigoTOM: 310, codigoIBGE: 2203255, nomeTOM: 'CURRALINHOS', nomeIBGE: 'Curralinhos' },
    { codigoTOM: 312, codigoIBGE: 2203271, nomeTOM: 'CURRAL NOVO DO PIAUÍ', nomeIBGE: 'Curral Novo do Piauí' },
    { codigoTOM: 314, codigoIBGE: 2203859, nomeTOM: 'FLORESTA DO PIAUÍ', nomeIBGE: 'Floresta do Piauí' },
    { codigoTOM: 316, codigoIBGE: 2204154, nomeTOM: 'FRANCISCO MACEDO', nomeIBGE: 'Francisco Macedo' },
    { codigoTOM: 318, codigoIBGE: 2204352, nomeTOM: 'GEMINIANO', nomeIBGE: 'Geminiano' },
    { codigoTOM: 320, codigoIBGE: 2204550, nomeTOM: 'GUARIBAS', nomeIBGE: 'Guaribas' },
    { codigoTOM: 322, codigoIBGE: 2204659, nomeTOM: 'ILHA GRANDE', nomeIBGE: 'Ilha Grande' },
    { codigoTOM: 324, codigoIBGE: 2205276, nomeTOM: 'JATOBÁ DO PIAUÍ', nomeIBGE: 'Jatobá do Piauí' },
    { codigoTOM: 326, codigoIBGE: 2205359, nomeTOM: 'JOÃO COSTA', nomeIBGE: 'João Costa' },
    { codigoTOM: 328, codigoIBGE: 2205458, nomeTOM: 'JOCA MARQUES', nomeIBGE: 'Joca Marques' },
    { codigoTOM: 330, codigoIBGE: 2205516, nomeTOM: 'JUAZEIRO DO PIAUÍ', nomeIBGE: 'Juazeiro do Piauí' },
    { codigoTOM: 332, codigoIBGE: 2205524, nomeTOM: 'JÚLIO BORGES', nomeIBGE: 'Júlio Borges' },
    { codigoTOM: 334, codigoIBGE: 2205532, nomeTOM: 'JUREMA', nomeIBGE: 'Jurema' },
    { codigoTOM: 336, codigoIBGE: 2205540, nomeTOM: 'LAGOINHA DO PIAUÍ', nomeIBGE: 'Lagoinha do Piauí' },
    { codigoTOM: 338, codigoIBGE: 2205573, nomeTOM: 'LAGOA DE SÃO FRANCISCO', nomeIBGE: 'Lagoa de São Francisco' },
    { codigoTOM: 340, codigoIBGE: 2205581, nomeTOM: 'LAGOA DO PIAUÍ', nomeIBGE: 'Lagoa do Piauí' },
    { codigoTOM: 342, codigoIBGE: 2205599, nomeTOM: 'LAGOA DO SÍTIO', nomeIBGE: 'Lagoa do Sítio' },
    { codigoTOM: 344, codigoIBGE: 2205854, nomeTOM: 'MADEIRO', nomeIBGE: 'Madeiro' },
    { codigoTOM: 346, codigoIBGE: 2206050, nomeTOM: 'MASSAPÊ DO PIAUÍ', nomeIBGE: 'Massapê do Piauí' },
    { codigoTOM: 348, codigoIBGE: 2206357, nomeTOM: 'MILTON BRANDÃO', nomeIBGE: 'Milton Brandão' },
    { codigoTOM: 350, codigoIBGE: 2206654, nomeTOM: 'MORRO CABEÇA NO TEMPO', nomeIBGE: 'Morro Cabeça no Tempo' },
    { codigoTOM: 352, codigoIBGE: 2206670, nomeTOM: 'MORRO DO CHAPÉU DO PIAUÍ', nomeIBGE: 'Morro do Chapéu do Piauí' },
    { codigoTOM: 354, codigoIBGE: 2206696, nomeTOM: 'MURICI DOS PORTELAS', nomeIBGE: 'Murici dos Portelas' },
    { codigoTOM: 356, codigoIBGE: 2206753, nomeTOM: 'NOSSA SENHORA DE NAZARÉ', nomeIBGE: 'Nossa Senhora de Nazaré' },
    { codigoTOM: 358, codigoIBGE: 2206951, nomeTOM: 'NOVO SANTO ANTÔNIO', nomeIBGE: 'Novo Santo Antônio' },
    { codigoTOM: 360, codigoIBGE: 2207108, nomeTOM: "OLHO D'ÁGUA DO PIAUÍ", nomeIBGE: "Olho D'Água do Piauí" },
    { codigoTOM: 362, codigoIBGE: 2207355, nomeTOM: 'PAJEÚ DO PIAUÍ', nomeIBGE: 'Pajeú do Piauí' },
    { codigoTOM: 364, codigoIBGE: 2207553, nomeTOM: 'PAQUETÁ', nomeIBGE: 'Paquetá' },
    { codigoTOM: 366, codigoIBGE: 2207850, nomeTOM: 'PAVUSSU', nomeIBGE: 'Pavussu' },
    { codigoTOM: 368, codigoIBGE: 2207934, nomeTOM: 'PEDRO LAURENTINO', nomeIBGE: 'Pedro Laurentino' },
    { codigoTOM: 370, codigoIBGE: 2207959, nomeTOM: 'NOVA SANTA RITA', nomeIBGE: 'Nova Santa Rita' },
    { codigoTOM: 372, codigoIBGE: 2208551, nomeTOM: 'PORTO ALEGRE DO PIAUÍ', nomeIBGE: 'Porto Alegre do Piauí' },
    { codigoTOM: 374, codigoIBGE: 2208858, nomeTOM: 'RIACHO FRIO', nomeIBGE: 'Riacho Frio' },
    { codigoTOM: 376, codigoIBGE: 2208874, nomeTOM: 'RIBEIRA DO PIAUÍ', nomeIBGE: 'Ribeira do Piauí' },
    {
      codigoTOM: 378,
      codigoIBGE: 2209450,
      nomeTOM: 'SANTO ANTÔNIO DOS MILAGRES',
      nomeIBGE: 'Santo Antônio dos Milagres',
    },
    {
      codigoTOM: 380,
      codigoIBGE: 2209658,
      nomeTOM: 'SÃO FRANCISCO DE ASSIS DO PIAUÍ',
      nomeIBGE: 'São Francisco de Assis do Piauí',
    },
    { codigoTOM: 382, codigoIBGE: 2209757, nomeTOM: 'SÃO GONÇALO DO GURGUÉIA', nomeIBGE: 'São Gonçalo do Gurguéia' },
    { codigoTOM: 384, codigoIBGE: 2209872, nomeTOM: 'SÃO JOÃO DA FRONTEIRA', nomeIBGE: 'São João da Fronteira' },
    { codigoTOM: 386, codigoIBGE: 2209955, nomeTOM: 'SÃO JOÃO DA VARJOTA', nomeIBGE: 'São João da Varjota' },
    { codigoTOM: 388, codigoIBGE: 2209971, nomeTOM: 'SÃO JOÃO DO ARRAIAL', nomeIBGE: 'São João do Arraial' },
    { codigoTOM: 390, codigoIBGE: 2210375, nomeTOM: 'SÃO LUIS DO PIAUÍ', nomeIBGE: 'São Luis do Piauí' },
    {
      codigoTOM: 392,
      codigoIBGE: 2210383,
      nomeTOM: 'SÃO MIGUEL DA BAIXA GRANDE',
      nomeIBGE: 'São Miguel da Baixa Grande',
    },
    { codigoTOM: 394, codigoIBGE: 2210391, nomeTOM: 'SÃO MIGUEL DO FIDALGO', nomeIBGE: 'São Miguel do Fidalgo' },
    { codigoTOM: 396, codigoIBGE: 2210623, nomeTOM: 'SEBASTIÃO BARROS', nomeIBGE: 'Sebastião Barros' },
    { codigoTOM: 398, codigoIBGE: 2210631, nomeTOM: 'SEBASTIÃO LEAL', nomeIBGE: 'Sebastião Leal' },
    { codigoTOM: 400, codigoIBGE: 2210938, nomeTOM: 'SUSSUAPARA', nomeIBGE: 'Sussuapara' },
    { codigoTOM: 402, codigoIBGE: 2210953, nomeTOM: 'TAMBORIL DO PIAUÍ', nomeIBGE: 'Tamboril do Piauí' },
    { codigoTOM: 404, codigoIBGE: 2210979, nomeTOM: 'TANQUE DO PIAUÍ', nomeIBGE: 'Tanque do Piauí' },
    { codigoTOM: 406, codigoIBGE: 2211506, nomeTOM: 'VERA MENDES', nomeIBGE: 'Vera Mendes' },
    { codigoTOM: 408, codigoIBGE: 2211605, nomeTOM: 'VILA NOVA DO PIAUÍ', nomeIBGE: 'Vila Nova do Piauí' },
    { codigoTOM: 410, codigoIBGE: 2211704, nomeTOM: 'WALL FERRAZ', nomeIBGE: 'Wall Ferraz' },
    { codigoTOM: 995, codigoIBGE: 2202737, nomeTOM: 'COIVARAS', nomeIBGE: 'Coivaras' },
    { codigoTOM: 997, codigoIBGE: 2205250, nomeTOM: 'JARDIM DO MULATO', nomeIBGE: 'Jardim do Mulato' },
    { codigoTOM: 999, codigoIBGE: 2205557, nomeTOM: 'LAGOA ALEGRE', nomeIBGE: 'Lagoa Alegre' },
    { codigoTOM: 1001, codigoIBGE: 2200103, nomeTOM: 'AGRICOLÂNDIA', nomeIBGE: 'Agricolândia' },
    { codigoTOM: 1003, codigoIBGE: 2200202, nomeTOM: 'ÁGUA BRANCA', nomeIBGE: 'Água Branca' },
    { codigoTOM: 1005, codigoIBGE: 2200301, nomeTOM: 'ALTO LONGÁ', nomeIBGE: 'Alto Longá' },
    { codigoTOM: 1007, codigoIBGE: 2200400, nomeTOM: 'ALTOS', nomeIBGE: 'Altos' },
    { codigoTOM: 1009, codigoIBGE: 2200509, nomeTOM: 'AMARANTE', nomeIBGE: 'Amarante' },
    { codigoTOM: 1011, codigoIBGE: 2200608, nomeTOM: 'ANGICAL DO PIAUÍ', nomeIBGE: 'Angical do Piauí' },
    { codigoTOM: 1013, codigoIBGE: 2200707, nomeTOM: 'ANÍSIO DE ABREU', nomeIBGE: 'Anísio de Abreu' },
    { codigoTOM: 1015, codigoIBGE: 2200806, nomeTOM: 'ANTÔNIO ALMEIDA', nomeIBGE: 'Antônio Almeida' },
    { codigoTOM: 1017, codigoIBGE: 2200905, nomeTOM: 'AROAZES', nomeIBGE: 'Aroazes' },
    { codigoTOM: 1019, codigoIBGE: 2201002, nomeTOM: 'ARRAIAL', nomeIBGE: 'Arraial' },
    { codigoTOM: 1021, codigoIBGE: 2201101, nomeTOM: 'AVELINO LOPES', nomeIBGE: 'Avelino Lopes' },
    { codigoTOM: 1023, codigoIBGE: 2201200, nomeTOM: 'BARRAS', nomeIBGE: 'Barras' },
    { codigoTOM: 1025, codigoIBGE: 2201309, nomeTOM: 'BARREIRAS DO PIAUÍ', nomeIBGE: 'Barreiras do Piauí' },
    { codigoTOM: 1027, codigoIBGE: 2201408, nomeTOM: 'BARRO DURO', nomeIBGE: 'Barro Duro' },
    { codigoTOM: 1029, codigoIBGE: 2201507, nomeTOM: 'BATALHA', nomeIBGE: 'Batalha' },
    { codigoTOM: 1031, codigoIBGE: 2201606, nomeTOM: 'BENEDITINOS', nomeIBGE: 'Beneditinos' },
    { codigoTOM: 1033, codigoIBGE: 2201705, nomeTOM: 'BERTOLÍNIA', nomeIBGE: 'Bertolínia' },
    { codigoTOM: 1035, codigoIBGE: 2201804, nomeTOM: 'BOCAINA', nomeIBGE: 'Bocaina' },
    { codigoTOM: 1037, codigoIBGE: 2201903, nomeTOM: 'BOM JESUS', nomeIBGE: 'Bom Jesus' },
    { codigoTOM: 1039, codigoIBGE: 2202000, nomeTOM: 'BURITI DOS LOPES', nomeIBGE: 'Buriti dos Lopes' },
    { codigoTOM: 1041, codigoIBGE: 2202109, nomeTOM: 'CAMPINAS DO PIAUÍ', nomeIBGE: 'Campinas do Piauí' },
    { codigoTOM: 1043, codigoIBGE: 2202208, nomeTOM: 'CAMPO MAIOR', nomeIBGE: 'Campo Maior' },
    { codigoTOM: 1045, codigoIBGE: 2202307, nomeTOM: 'CANTO DO BURITI', nomeIBGE: 'Canto do Buriti' },
    { codigoTOM: 1047, codigoIBGE: 2202406, nomeTOM: 'CAPITÃO DE CAMPOS', nomeIBGE: 'Capitão de Campos' },
    { codigoTOM: 1049, codigoIBGE: 2202505, nomeTOM: 'CARACOL', nomeIBGE: 'Caracol' },
    { codigoTOM: 1051, codigoIBGE: 2202604, nomeTOM: 'CASTELO DO PIAUÍ', nomeIBGE: 'Castelo do Piauí' },
    { codigoTOM: 1053, codigoIBGE: 2202703, nomeTOM: 'COCAL', nomeIBGE: 'Cocal' },
    { codigoTOM: 1055, codigoIBGE: 2202802, nomeTOM: 'CONCEIÇÃO DO CANINDÉ', nomeIBGE: 'Conceição do Canindé' },
    { codigoTOM: 1057, codigoIBGE: 2202901, nomeTOM: 'CORRENTE', nomeIBGE: 'Corrente' },
    { codigoTOM: 1059, codigoIBGE: 2203008, nomeTOM: 'CRISTALÂNDIA DO PIAUÍ', nomeIBGE: 'Cristalândia do Piauí' },
    { codigoTOM: 1061, codigoIBGE: 2203107, nomeTOM: 'CRISTINO CASTRO', nomeIBGE: 'Cristino Castro' },
    { codigoTOM: 1063, codigoIBGE: 2203206, nomeTOM: 'CURIMATÁ', nomeIBGE: 'Curimatá' },
    { codigoTOM: 1065, codigoIBGE: 2203305, nomeTOM: 'DEMERVAL LOBÃO', nomeIBGE: 'Demerval Lobão' },
    { codigoTOM: 1067, codigoIBGE: 2203404, nomeTOM: 'DOM EXPEDITO LOPES', nomeIBGE: 'Dom Expedito Lopes' },
    { codigoTOM: 1069, codigoIBGE: 2203503, nomeTOM: 'ELESBÃO VELOSO', nomeIBGE: 'Elesbão Veloso' },
    { codigoTOM: 1071, codigoIBGE: 2203602, nomeTOM: 'ELISEU MARTINS', nomeIBGE: 'Eliseu Martins' },
    { codigoTOM: 1073, codigoIBGE: 2203701, nomeTOM: 'ESPERANTINA', nomeIBGE: 'Esperantina' },
    { codigoTOM: 1075, codigoIBGE: 2203800, nomeTOM: 'FLORES DO PIAUÍ', nomeIBGE: 'Flores do Piauí' },
    { codigoTOM: 1077, codigoIBGE: 2203909, nomeTOM: 'FLORIANO', nomeIBGE: 'Floriano' },
    { codigoTOM: 1079, codigoIBGE: 2204006, nomeTOM: 'FRANCINÓPOLIS', nomeIBGE: 'Francinópolis' },
    { codigoTOM: 1081, codigoIBGE: 2204105, nomeTOM: 'FRANCISCO AYRES', nomeIBGE: 'Francisco Ayres' },
    { codigoTOM: 1083, codigoIBGE: 2204204, nomeTOM: 'FRANCISCO SANTOS', nomeIBGE: 'Francisco Santos' },
    { codigoTOM: 1085, codigoIBGE: 2204303, nomeTOM: 'FRONTEIRAS', nomeIBGE: 'Fronteiras' },
    { codigoTOM: 1087, codigoIBGE: 2204402, nomeTOM: 'GILBUÉS', nomeIBGE: 'Gilbués' },
    { codigoTOM: 1089, codigoIBGE: 2204501, nomeTOM: 'GUADALUPE', nomeIBGE: 'Guadalupe' },
    { codigoTOM: 1091, codigoIBGE: 2204600, nomeTOM: 'HUGO NAPOLEÃO', nomeIBGE: 'Hugo Napoleão' },
    { codigoTOM: 1093, codigoIBGE: 2204709, nomeTOM: 'INHUMA', nomeIBGE: 'Inhuma' },
    { codigoTOM: 1095, codigoIBGE: 2204808, nomeTOM: 'IPIRANGA DO PIAUÍ', nomeIBGE: 'Ipiranga do Piauí' },
    { codigoTOM: 1097, codigoIBGE: 2204907, nomeTOM: 'ISAÍAS COELHO', nomeIBGE: 'Isaías Coelho' },
    { codigoTOM: 1099, codigoIBGE: 2205003, nomeTOM: 'ITAINÓPOLIS', nomeIBGE: 'Itainópolis' },
    { codigoTOM: 1101, codigoIBGE: 2205102, nomeTOM: 'ITAUEIRA', nomeIBGE: 'Itaueira' },
    { codigoTOM: 1103, codigoIBGE: 2205201, nomeTOM: 'JAICÓS', nomeIBGE: 'Jaicós' },
    { codigoTOM: 1104, codigoIBGE: 2207793, nomeTOM: "PAU D'ARCO DO PIAUÍ", nomeIBGE: "Pau D'Arco do Piauí" },
    { codigoTOM: 1105, codigoIBGE: 2205300, nomeTOM: 'JERUMENHA', nomeIBGE: 'Jerumenha' },
    { codigoTOM: 1107, codigoIBGE: 2205409, nomeTOM: 'JOAQUIM PIRES', nomeIBGE: 'Joaquim Pires' },
    { codigoTOM: 1109, codigoIBGE: 2205508, nomeTOM: 'JOSÉ DE FREITAS', nomeIBGE: 'José de Freitas' },
    { codigoTOM: 1111, codigoIBGE: 2205607, nomeTOM: 'LANDRI SALES', nomeIBGE: 'Landri Sales' },
    { codigoTOM: 1113, codigoIBGE: 2205706, nomeTOM: 'LUÍS CORREIA', nomeIBGE: 'Luís Correia' },
    { codigoTOM: 1115, codigoIBGE: 2205805, nomeTOM: 'LUZILÂNDIA', nomeIBGE: 'Luzilândia' },
    { codigoTOM: 1117, codigoIBGE: 2205904, nomeTOM: 'MANOEL EMÍDIO', nomeIBGE: 'Manoel Emídio' },
    { codigoTOM: 1119, codigoIBGE: 2206001, nomeTOM: 'MARCOS PARENTE', nomeIBGE: 'Marcos Parente' },
    { codigoTOM: 1121, codigoIBGE: 2206100, nomeTOM: 'MATIAS OLÍMPIO', nomeIBGE: 'Matias Olímpio' },
    { codigoTOM: 1123, codigoIBGE: 2206209, nomeTOM: 'MIGUEL ALVES', nomeIBGE: 'Miguel Alves' },
    { codigoTOM: 1125, codigoIBGE: 2206308, nomeTOM: 'MIGUEL LEÃO', nomeIBGE: 'Miguel Leão' },
    { codigoTOM: 1127, codigoIBGE: 2206407, nomeTOM: 'MONSENHOR GIL', nomeIBGE: 'Monsenhor Gil' },
    { codigoTOM: 1129, codigoIBGE: 2206506, nomeTOM: 'MONSENHOR HIPÓLITO', nomeIBGE: 'Monsenhor Hipólito' },
    { codigoTOM: 1131, codigoIBGE: 2206605, nomeTOM: 'MONTE ALEGRE DO PIAUÍ', nomeIBGE: 'Monte Alegre do Piauí' },
    { codigoTOM: 1133, codigoIBGE: 2206704, nomeTOM: 'NAZARÉ DO PIAUÍ', nomeIBGE: 'Nazaré do Piauí' },
    {
      codigoTOM: 1135,
      codigoIBGE: 2206803,
      nomeTOM: 'NOSSA SENHORA DOS REMÉDIOS',
      nomeIBGE: 'Nossa Senhora dos Remédios',
    },
    { codigoTOM: 1137, codigoIBGE: 2206902, nomeTOM: 'NOVO ORIENTE DO PIAUÍ', nomeIBGE: 'Novo Oriente do Piauí' },
    { codigoTOM: 1139, codigoIBGE: 2207009, nomeTOM: 'OEIRAS', nomeIBGE: 'Oeiras' },
    { codigoTOM: 1141, codigoIBGE: 2203420, nomeTOM: 'DOMINGOS MOURÃO', nomeIBGE: 'Domingos Mourão' },
    { codigoTOM: 1143, codigoIBGE: 2207207, nomeTOM: 'PADRE MARCOS', nomeIBGE: 'Padre Marcos' },
    { codigoTOM: 1145, codigoIBGE: 2207306, nomeTOM: 'PAES LANDIM', nomeIBGE: 'Paes Landim' },
    { codigoTOM: 1147, codigoIBGE: 2207405, nomeTOM: 'PALMEIRA DO PIAUÍ', nomeIBGE: 'Palmeira do Piauí' },
    { codigoTOM: 1149, codigoIBGE: 2207504, nomeTOM: 'PALMEIRAIS', nomeIBGE: 'Palmeirais' },
    { codigoTOM: 1151, codigoIBGE: 2207603, nomeTOM: 'PARNAGUÁ', nomeIBGE: 'Parnaguá' },
    { codigoTOM: 1153, codigoIBGE: 2207702, nomeTOM: 'PARNAÍBA', nomeIBGE: 'Parnaíba' },
    { codigoTOM: 1155, codigoIBGE: 2207801, nomeTOM: 'PAULISTANA', nomeIBGE: 'Paulistana' },
    { codigoTOM: 1157, codigoIBGE: 2207900, nomeTOM: 'PEDRO II', nomeIBGE: 'Pedro II' },
    { codigoTOM: 1159, codigoIBGE: 2208007, nomeTOM: 'PICOS', nomeIBGE: 'Picos' },
    { codigoTOM: 1161, codigoIBGE: 2208106, nomeTOM: 'PIMENTEIRAS', nomeIBGE: 'Pimenteiras' },
    { codigoTOM: 1163, codigoIBGE: 2208205, nomeTOM: 'PIO IX', nomeIBGE: 'Pio IX' },
    { codigoTOM: 1165, codigoIBGE: 2208304, nomeTOM: 'PIRACURUCA', nomeIBGE: 'Piracuruca' },
    { codigoTOM: 1167, codigoIBGE: 2208403, nomeTOM: 'PIRIPIRI', nomeIBGE: 'Piripiri' },
    { codigoTOM: 1169, codigoIBGE: 2208502, nomeTOM: 'PORTO', nomeIBGE: 'Porto' },
    { codigoTOM: 1171, codigoIBGE: 2208601, nomeTOM: 'PRATA DO PIAUÍ', nomeIBGE: 'Prata do Piauí' },
    { codigoTOM: 1173, codigoIBGE: 2208700, nomeTOM: 'REDENÇÃO DO GURGUÉIA', nomeIBGE: 'Redenção do Gurguéia' },
    { codigoTOM: 1175, codigoIBGE: 2208809, nomeTOM: 'REGENERAÇÃO', nomeIBGE: 'Regeneração' },
    { codigoTOM: 1177, codigoIBGE: 2208908, nomeTOM: 'RIBEIRO GONÇALVES', nomeIBGE: 'Ribeiro Gonçalves' },
    { codigoTOM: 1179, codigoIBGE: 2209005, nomeTOM: 'RIO GRANDE DO PIAUÍ', nomeIBGE: 'Rio Grande do Piauí' },
    { codigoTOM: 1181, codigoIBGE: 2209104, nomeTOM: 'SANTA CRUZ DO PIAUÍ', nomeIBGE: 'Santa Cruz do Piauí' },
    { codigoTOM: 1183, codigoIBGE: 2209203, nomeTOM: 'SANTA FILOMENA', nomeIBGE: 'Santa Filomena' },
    { codigoTOM: 1185, codigoIBGE: 2209302, nomeTOM: 'SANTA LUZ', nomeIBGE: 'Santa Luz' },
    { codigoTOM: 1187, codigoIBGE: 2209401, nomeTOM: 'SANTO ANTÔNIO DE LISBOA', nomeIBGE: 'Santo Antônio de Lisboa' },
    { codigoTOM: 1188, codigoIBGE: 2200954, nomeTOM: 'AROEIRAS DO ITAIM', nomeIBGE: 'Aroeiras do Itaim' },
    { codigoTOM: 1189, codigoIBGE: 2209500, nomeTOM: 'SANTO INÁCIO DO PIAUÍ', nomeIBGE: 'Santo Inácio do Piauí' },
    { codigoTOM: 1191, codigoIBGE: 2209609, nomeTOM: 'SÃO FÉLIX DO PIAUÍ', nomeIBGE: 'São Félix do Piauí' },
    { codigoTOM: 1193, codigoIBGE: 2209708, nomeTOM: 'SÃO FRANCISCO DO PIAUÍ', nomeIBGE: 'São Francisco do Piauí' },
    { codigoTOM: 1195, codigoIBGE: 2209807, nomeTOM: 'SÃO GONÇALO DO PIAUÍ', nomeIBGE: 'São Gonçalo do Piauí' },
    { codigoTOM: 1197, codigoIBGE: 2209906, nomeTOM: 'SÃO JOÃO DA SERRA', nomeIBGE: 'São João da Serra' },
    { codigoTOM: 1199, codigoIBGE: 2210003, nomeTOM: 'SÃO JOÃO DO PIAUÍ', nomeIBGE: 'São João do Piauí' },
    { codigoTOM: 1201, codigoIBGE: 2210102, nomeTOM: 'SÃO JOSÉ DO PEIXE', nomeIBGE: 'São José do Peixe' },
    { codigoTOM: 1203, codigoIBGE: 2210201, nomeTOM: 'SÃO JOSÉ DO PIAUÍ', nomeIBGE: 'São José do Piauí' },
    { codigoTOM: 1205, codigoIBGE: 2210300, nomeTOM: 'SÃO JULIÃO', nomeIBGE: 'São Julião' },
    { codigoTOM: 1207, codigoIBGE: 2210409, nomeTOM: 'SÃO MIGUEL DO TAPUIO', nomeIBGE: 'São Miguel do Tapuio' },
    { codigoTOM: 1209, codigoIBGE: 2210508, nomeTOM: 'SÃO PEDRO DO PIAUÍ', nomeIBGE: 'São Pedro do Piauí' },
    { codigoTOM: 1211, codigoIBGE: 2210607, nomeTOM: 'SÃO RAIMUNDO NONATO', nomeIBGE: 'São Raimundo Nonato' },
    { codigoTOM: 1213, codigoIBGE: 2210706, nomeTOM: 'SIMÕES', nomeIBGE: 'Simões' },
    { codigoTOM: 1215, codigoIBGE: 2210805, nomeTOM: 'SIMPLÍCIO MENDES', nomeIBGE: 'Simplício Mendes' },
    { codigoTOM: 1217, codigoIBGE: 2210904, nomeTOM: 'SOCORRO DO PIAUÍ', nomeIBGE: 'Socorro do Piauí' },
    { codigoTOM: 1219, codigoIBGE: 2211001, nomeTOM: 'TERESINA', nomeIBGE: 'Teresina' },
    { codigoTOM: 1221, codigoIBGE: 2211100, nomeTOM: 'UNIÃO', nomeIBGE: 'União' },
    { codigoTOM: 1223, codigoIBGE: 2211209, nomeTOM: 'URUÇUÍ', nomeIBGE: 'Uruçuí' },
    { codigoTOM: 1225, codigoIBGE: 2211308, nomeTOM: 'VALENÇA DO PIAUÍ', nomeIBGE: 'Valença do Piauí' },
    { codigoTOM: 1227, codigoIBGE: 2211407, nomeTOM: 'VÁRZEA GRANDE', nomeIBGE: 'Várzea Grande' },
    { codigoTOM: 1229, codigoIBGE: 2203354, nomeTOM: 'DIRCEU ARCOVERDE', nomeIBGE: 'Dirceu Arcoverde' },
    { codigoTOM: 1289, codigoIBGE: 2203453, nomeTOM: 'DOM INOCÊNCIO', nomeIBGE: 'Dom Inocêncio' },
    { codigoTOM: 1291, codigoIBGE: 2209856, nomeTOM: 'SÃO JOÃO DA CANABRAVA', nomeIBGE: 'São João da Canabrava' },
    { codigoTOM: 1293, codigoIBGE: 2207751, nomeTOM: 'PASSAGEM FRANCA DO PIAUÍ', nomeIBGE: 'Passagem Franca do Piauí' },
    { codigoTOM: 1295, codigoIBGE: 2209153, nomeTOM: 'SANTA CRUZ DOS MILAGRES', nomeIBGE: 'Santa Cruz dos Milagres' },
    { codigoTOM: 1297, codigoIBGE: 2202026, nomeTOM: 'BURITI DOS MONTES', nomeIBGE: 'Buriti dos Montes' },
    { codigoTOM: 1299, codigoIBGE: 2202059, nomeTOM: 'CABECEIRAS DO PIAUÍ', nomeIBGE: 'Cabeceiras do Piauí' },
    { codigoTOM: 1379, codigoIBGE: 2210656, nomeTOM: 'SIGEFREDO PACHECO', nomeIBGE: 'Sigefredo Pacheco' },
    { codigoTOM: 2245, codigoIBGE: 2201150, nomeTOM: 'BAIXA GRANDE DO RIBEIRO', nomeIBGE: 'Baixa Grande do Ribeiro' },
    { codigoTOM: 2247, codigoIBGE: 2202251, nomeTOM: 'CANAVIEIRA', nomeIBGE: 'Canavieira' },
    { codigoTOM: 2249, codigoIBGE: 2202752, nomeTOM: 'COLÔNIA DO GURGUÉIA', nomeIBGE: 'Colônia do Gurguéia' },
    { codigoTOM: 2251, codigoIBGE: 2201929, nomeTOM: 'BONFIM DO PIAUÍ', nomeIBGE: 'Bonfim do Piauí' },
    { codigoTOM: 2253, codigoIBGE: 2202778, nomeTOM: 'COLÔNIA DO PIAUÍ', nomeIBGE: 'Colônia do Piauí' },
    { codigoTOM: 2255, codigoIBGE: 2202851, nomeTOM: 'CORONEL JOSÉ DIAS', nomeIBGE: 'Coronel José Dias' },
    { codigoTOM: 2257, codigoIBGE: 2203750, nomeTOM: 'FARTURA DO PIAUÍ', nomeIBGE: 'Fartura do Piauí' },
    { codigoTOM: 2259, codigoIBGE: 2205565, nomeTOM: 'LAGOA DO BARRO DO PIAUÍ', nomeIBGE: 'Lagoa do Barro do Piauí' },
    { codigoTOM: 2261, codigoIBGE: 2209377, nomeTOM: 'SANTA ROSA DO PIAUÍ', nomeIBGE: 'Santa Rosa do Piauí' },
    { codigoTOM: 2263, codigoIBGE: 2209559, nomeTOM: 'SÃO BRAZ DO PIAUÍ', nomeIBGE: 'São Braz do Piauí' },
    { codigoTOM: 2265, codigoIBGE: 2210359, nomeTOM: 'SÃO LOURENÇO DO PIAUÍ', nomeIBGE: 'São Lourenço do Piauí' },
    { codigoTOM: 2267, codigoIBGE: 2211357, nomeTOM: 'VÁRZEA BRANCA', nomeIBGE: 'Várzea Branca' },
    { codigoTOM: 2269, codigoIBGE: 2200277, nomeTOM: 'ALEGRETE DO PIAUÍ', nomeIBGE: 'Alegrete do Piauí' },
    {
      codigoTOM: 2271,
      codigoIBGE: 2202091,
      nomeTOM: 'CALDEIRÃO GRANDE DO PIAUÍ',
      nomeIBGE: 'Caldeirão Grande do Piauí',
    },
    { codigoTOM: 2273, codigoIBGE: 2205151, nomeTOM: 'JACOBINA DO PIAUÍ', nomeIBGE: 'Jacobina do Piauí' },
    { codigoTOM: 2275, codigoIBGE: 2205953, nomeTOM: 'MARCOLÂNDIA', nomeIBGE: 'Marcolândia' },
    { codigoTOM: 2277, codigoIBGE: 2207777, nomeTOM: 'PATOS DO PIAUÍ', nomeIBGE: 'Patos do Piauí' },
    { codigoTOM: 2279, codigoIBGE: 2208650, nomeTOM: 'QUEIMADA NOVA', nomeIBGE: 'Queimada Nova' },
    { codigoTOM: 2281, codigoIBGE: 2209351, nomeTOM: 'SANTANA DO PIAUÍ', nomeIBGE: 'Santana do Piauí' },
    { codigoTOM: 2283, codigoIBGE: 2201960, nomeTOM: 'BRASILEIRA', nomeIBGE: 'Brasileira' },
    { codigoTOM: 2285, codigoIBGE: 2210052, nomeTOM: 'SÃO JOSÉ DO DIVINO', nomeIBGE: 'São José do Divino' },
    { codigoTOM: 2287, codigoIBGE: 2201919, nomeTOM: 'BOM PRINCÍPIO DO PIAUÍ', nomeIBGE: 'Bom Princípio do Piauí' },
    { codigoTOM: 1180, codigoIBGE: 2206720, nomeTOM: 'NAZÁRIA', nomeIBGE: 'Nazária' },
    { codigoTOM: 9767, codigoIBGE: 2200251, nomeTOM: 'ALAGOINHA DO PIAUÍ', nomeIBGE: 'Alagoinha do Piauí' },
  ],
  [Estados.RN]: [
    { codigoTOM: 412, codigoIBGE: 2401651, nomeTOM: 'BODÓ', nomeIBGE: 'Bodó' },
    { codigoTOM: 414, codigoIBGE: 2401859, nomeTOM: 'CAIÇARA DO NORTE', nomeIBGE: 'Caiçara do Norte' },
    { codigoTOM: 416, codigoIBGE: 2403756, nomeTOM: 'FERNANDO PEDROZA', nomeIBGE: 'Fernando Pedroza' },
    { codigoTOM: 418, codigoIBGE: 2404853, nomeTOM: 'ITAJÁ', nomeIBGE: 'Itajá' },
    { codigoTOM: 420, codigoIBGE: 2407252, nomeTOM: 'MAJOR SALES', nomeIBGE: 'Major Sales' },
    { codigoTOM: 422, codigoIBGE: 2408953, nomeTOM: 'RIO DO FOGO', nomeIBGE: 'Rio do Fogo' },
    { codigoTOM: 424, codigoIBGE: 2409332, nomeTOM: 'SANTA MARIA', nomeIBGE: 'Santa Maria' },
    { codigoTOM: 426, codigoIBGE: 2410256, nomeTOM: 'PORTO DO MANGUE', nomeIBGE: 'Porto do Mangue' },
    { codigoTOM: 428, codigoIBGE: 2411056, nomeTOM: 'TIBAU', nomeIBGE: 'Tibau' },
    { codigoTOM: 430, codigoIBGE: 2412559, nomeTOM: 'SÃO MIGUEL DO GOSTOSO', nomeIBGE: 'São Miguel do Gostoso' },
    { codigoTOM: 432, codigoIBGE: 2413557, nomeTOM: 'SERRINHA DOS PINTOS', nomeIBGE: 'Serrinha dos Pintos' },
    { codigoTOM: 434, codigoIBGE: 2414159, nomeTOM: 'TENENTE LAURENTINO CRUZ', nomeIBGE: 'Tenente Laurentino Cruz' },
    { codigoTOM: 436, codigoIBGE: 2414456, nomeTOM: 'TRIUNFO POTIGUAR', nomeIBGE: 'Triunfo Potiguar' },
    { codigoTOM: 438, codigoIBGE: 2414753, nomeTOM: 'VENHA-VER', nomeIBGE: 'Venha-Ver' },
    { codigoTOM: 1108, codigoIBGE: 2406155, nomeTOM: 'JUNDIÁ', nomeIBGE: 'Jundiá' },
    { codigoTOM: 1601, codigoIBGE: 2400109, nomeTOM: 'ACARI', nomeIBGE: 'Acari' },
    { codigoTOM: 1603, codigoIBGE: 2400208, nomeTOM: 'ASSU', nomeIBGE: 'Açu' },
    { codigoTOM: 1605, codigoIBGE: 2400307, nomeTOM: 'AFONSO BEZERRA', nomeIBGE: 'Afonso Bezerra' },
    { codigoTOM: 1607, codigoIBGE: 2400406, nomeTOM: 'ÁGUA NOVA', nomeIBGE: 'Água Nova' },
    { codigoTOM: 1609, codigoIBGE: 2400505, nomeTOM: 'ALEXANDRIA', nomeIBGE: 'Alexandria' },
    { codigoTOM: 1611, codigoIBGE: 2400604, nomeTOM: 'ALMINO AFONSO', nomeIBGE: 'Almino Afonso' },
    { codigoTOM: 1613, codigoIBGE: 2400703, nomeTOM: 'ALTO DO RODRIGUES', nomeIBGE: 'Alto do Rodrigues' },
    { codigoTOM: 1615, codigoIBGE: 2400802, nomeTOM: 'ANGICOS', nomeIBGE: 'Angicos' },
    { codigoTOM: 1617, codigoIBGE: 2400901, nomeTOM: 'ANTÔNIO MARTINS', nomeIBGE: 'Antônio Martins' },
    { codigoTOM: 1619, codigoIBGE: 2401008, nomeTOM: 'APODI', nomeIBGE: 'Apodi' },
    { codigoTOM: 1621, codigoIBGE: 2401107, nomeTOM: 'AREIA BRANCA', nomeIBGE: 'Areia Branca' },
    { codigoTOM: 1623, codigoIBGE: 2401206, nomeTOM: 'ARÊS', nomeIBGE: 'Arês' },
    { codigoTOM: 1625, codigoIBGE: 2401305, nomeTOM: 'CAMPO GRANDE', nomeIBGE: 'Campo Grande' },
    { codigoTOM: 1627, codigoIBGE: 2401404, nomeTOM: 'BAÍA FORMOSA', nomeIBGE: 'Baía Formosa' },
    { codigoTOM: 1629, codigoIBGE: 2401503, nomeTOM: 'BARCELONA', nomeIBGE: 'Barcelona' },
    { codigoTOM: 1631, codigoIBGE: 2401602, nomeTOM: 'BENTO FERNANDES', nomeIBGE: 'Bento Fernandes' },
    { codigoTOM: 1633, codigoIBGE: 2401701, nomeTOM: 'BOM JESUS', nomeIBGE: 'Bom Jesus' },
    { codigoTOM: 1635, codigoIBGE: 2401800, nomeTOM: 'BREJINHO', nomeIBGE: 'Brejinho' },
    { codigoTOM: 1637, codigoIBGE: 2401909, nomeTOM: 'CAIÇARA DO RIO DO VENTO', nomeIBGE: 'Caiçara do Rio do Vento' },
    { codigoTOM: 1639, codigoIBGE: 2402006, nomeTOM: 'CAICÓ', nomeIBGE: 'Caicó' },
    { codigoTOM: 1641, codigoIBGE: 2402105, nomeTOM: 'CAMPO REDONDO', nomeIBGE: 'Campo Redondo' },
    { codigoTOM: 1643, codigoIBGE: 2402204, nomeTOM: 'CANGUARETAMA', nomeIBGE: 'Canguaretama' },
    { codigoTOM: 1645, codigoIBGE: 2402303, nomeTOM: 'CARAÚBAS', nomeIBGE: 'Caraúbas' },
    { codigoTOM: 1647, codigoIBGE: 2402402, nomeTOM: 'CARNAÚBA DOS DANTAS', nomeIBGE: 'Carnaúba dos Dantas' },
    { codigoTOM: 1649, codigoIBGE: 2402501, nomeTOM: 'CARNAUBAIS', nomeIBGE: 'Carnaubais' },
    { codigoTOM: 1651, codigoIBGE: 2402600, nomeTOM: 'CEARÁ-MIRIM', nomeIBGE: 'Ceará-Mirim' },
    { codigoTOM: 1653, codigoIBGE: 2402709, nomeTOM: 'CERRO CORÁ', nomeIBGE: 'Cerro Corá' },
    { codigoTOM: 1655, codigoIBGE: 2402808, nomeTOM: 'CORONEL EZEQUIEL', nomeIBGE: 'Coronel Ezequiel' },
    { codigoTOM: 1657, codigoIBGE: 2402907, nomeTOM: 'CORONEL JOÃO PESSOA', nomeIBGE: 'Coronel João Pessoa' },
    { codigoTOM: 1659, codigoIBGE: 2403004, nomeTOM: 'CRUZETA', nomeIBGE: 'Cruzeta' },
    { codigoTOM: 1661, codigoIBGE: 2403103, nomeTOM: 'CURRAIS NOVOS', nomeIBGE: 'Currais Novos' },
    { codigoTOM: 1663, codigoIBGE: 2403202, nomeTOM: 'DOUTOR SEVERIANO', nomeIBGE: 'Doutor Severiano' },
    { codigoTOM: 1665, codigoIBGE: 2403301, nomeTOM: 'ENCANTO', nomeIBGE: 'Encanto' },
    { codigoTOM: 1667, codigoIBGE: 2403400, nomeTOM: 'EQUADOR', nomeIBGE: 'Equador' },
    { codigoTOM: 1669, codigoIBGE: 2403509, nomeTOM: 'ESPÍRITO SANTO', nomeIBGE: 'Espírito Santo' },
    { codigoTOM: 1671, codigoIBGE: 2403608, nomeTOM: 'EXTREMOZ', nomeIBGE: 'Extremoz' },
    { codigoTOM: 1673, codigoIBGE: 2403707, nomeTOM: 'FELIPE GUERRA', nomeIBGE: 'Felipe Guerra' },
    { codigoTOM: 1675, codigoIBGE: 2403806, nomeTOM: 'FLORÂNIA', nomeIBGE: 'Florânia' },
    { codigoTOM: 1677, codigoIBGE: 2403905, nomeTOM: 'FRANCISCO DANTAS', nomeIBGE: 'Francisco Dantas' },
    { codigoTOM: 1679, codigoIBGE: 2404101, nomeTOM: 'GALINHOS', nomeIBGE: 'Galinhos' },
    { codigoTOM: 1681, codigoIBGE: 2404200, nomeTOM: 'GOIANINHA', nomeIBGE: 'Goianinha' },
    {
      codigoTOM: 1683,
      codigoIBGE: 2404309,
      nomeTOM: 'GOVERNADOR DIX-SEPT ROSADO',
      nomeIBGE: 'Governador Dix-Sept Rosado',
    },
    { codigoTOM: 1685, codigoIBGE: 2404408, nomeTOM: 'GROSSOS', nomeIBGE: 'Grossos' },
    { codigoTOM: 1687, codigoIBGE: 2404507, nomeTOM: 'GUAMARÉ', nomeIBGE: 'Guamaré' },
    { codigoTOM: 1689, codigoIBGE: 2404606, nomeTOM: 'IELMO MARINHO', nomeIBGE: 'Ielmo Marinho' },
    { codigoTOM: 1691, codigoIBGE: 2404705, nomeTOM: 'IPANGUAÇU', nomeIBGE: 'Ipanguaçu' },
    { codigoTOM: 1693, codigoIBGE: 2404804, nomeTOM: 'IPUEIRA', nomeIBGE: 'Ipueira' },
    { codigoTOM: 1695, codigoIBGE: 2404903, nomeTOM: 'ITAÚ', nomeIBGE: 'Itaú' },
    { codigoTOM: 1697, codigoIBGE: 2405009, nomeTOM: 'JAÇANÃ', nomeIBGE: 'Jaçanã' },
    { codigoTOM: 1699, codigoIBGE: 2405108, nomeTOM: 'JANDAÍRA', nomeIBGE: 'Jandaíra' },
    { codigoTOM: 1701, codigoIBGE: 2405207, nomeTOM: 'JANDUÍS', nomeIBGE: 'Janduís' },
    { codigoTOM: 1703, codigoIBGE: 2405306, nomeTOM: 'BOA SAÚDE', nomeIBGE: 'Januário Cicco' },
    { codigoTOM: 1705, codigoIBGE: 2405405, nomeTOM: 'JAPI', nomeIBGE: 'Japi' },
    { codigoTOM: 1707, codigoIBGE: 2405504, nomeTOM: 'JARDIM DE ANGICOS', nomeIBGE: 'Jardim de Angicos' },
    { codigoTOM: 1709, codigoIBGE: 2405603, nomeTOM: 'JARDIM DE PIRANHAS', nomeIBGE: 'Jardim de Piranhas' },
    { codigoTOM: 1711, codigoIBGE: 2405702, nomeTOM: 'JARDIM DO SERIDÓ', nomeIBGE: 'Jardim do Seridó' },
    { codigoTOM: 1713, codigoIBGE: 2405801, nomeTOM: 'JOÃO CÂMARA', nomeIBGE: 'João Câmara' },
    { codigoTOM: 1715, codigoIBGE: 2405900, nomeTOM: 'JOÃO DIAS', nomeIBGE: 'João Dias' },
    { codigoTOM: 1717, codigoIBGE: 2406007, nomeTOM: 'JOSÉ DA PENHA', nomeIBGE: 'José da Penha' },
    { codigoTOM: 1719, codigoIBGE: 2406106, nomeTOM: 'JUCURUTU', nomeIBGE: 'Jucurutu' },
    { codigoTOM: 1721, codigoIBGE: 2407609, nomeTOM: 'MESSIAS TARGINO', nomeIBGE: 'Messias Targino' },
    { codigoTOM: 1723, codigoIBGE: 2406205, nomeTOM: "Lagoa d'Anta", nomeIBGE: "Lagoa d'Anta" },
    { codigoTOM: 1725, codigoIBGE: 2406304, nomeTOM: 'LAGOA DE PEDRAS', nomeIBGE: 'Lagoa de Pedras' },
    { codigoTOM: 1727, codigoIBGE: 2406403, nomeTOM: 'LAGOA DE VELHOS', nomeIBGE: 'Lagoa de Velhos' },
    { codigoTOM: 1729, codigoIBGE: 2406502, nomeTOM: 'LAGOA NOVA', nomeIBGE: 'Lagoa Nova' },
    { codigoTOM: 1731, codigoIBGE: 2406601, nomeTOM: 'LAGOA SALGADA', nomeIBGE: 'Lagoa Salgada' },
    { codigoTOM: 1733, codigoIBGE: 2406700, nomeTOM: 'LAJES', nomeIBGE: 'Lajes' },
    { codigoTOM: 1735, codigoIBGE: 2406809, nomeTOM: 'LAJES PINTADAS', nomeIBGE: 'Lajes Pintadas' },
    { codigoTOM: 1737, codigoIBGE: 2406908, nomeTOM: 'LUCRÉCIA', nomeIBGE: 'Lucrécia' },
    { codigoTOM: 1739, codigoIBGE: 2407005, nomeTOM: 'LUÍS GOMES', nomeIBGE: 'Luís Gomes' },
    { codigoTOM: 1741, codigoIBGE: 2407104, nomeTOM: 'MACAÍBA', nomeIBGE: 'Macaíba' },
    { codigoTOM: 1743, codigoIBGE: 2407203, nomeTOM: 'MACAU', nomeIBGE: 'Macau' },
    { codigoTOM: 1745, codigoIBGE: 2407302, nomeTOM: 'MARCELINO VIEIRA', nomeIBGE: 'Marcelino Vieira' },
    { codigoTOM: 1747, codigoIBGE: 2407401, nomeTOM: 'MARTINS', nomeIBGE: 'Martins' },
    { codigoTOM: 1749, codigoIBGE: 2407500, nomeTOM: 'MAXARANGUAPE', nomeIBGE: 'Maxaranguape' },
    { codigoTOM: 1751, codigoIBGE: 2404002, nomeTOM: 'FRUTUOSO GOMES', nomeIBGE: 'Frutuoso Gomes' },
    { codigoTOM: 1753, codigoIBGE: 2407708, nomeTOM: 'MONTANHAS', nomeIBGE: 'Montanhas' },
    { codigoTOM: 1755, codigoIBGE: 2407807, nomeTOM: 'MONTE ALEGRE', nomeIBGE: 'Monte Alegre' },
    { codigoTOM: 1757, codigoIBGE: 2407906, nomeTOM: 'MONTE DAS GAMELEIRAS', nomeIBGE: 'Monte das Gameleiras' },
    { codigoTOM: 1759, codigoIBGE: 2408003, nomeTOM: 'MOSSORÓ', nomeIBGE: 'Mossoró' },
    { codigoTOM: 1761, codigoIBGE: 2408102, nomeTOM: 'NATAL', nomeIBGE: 'Natal' },
    { codigoTOM: 1763, codigoIBGE: 2408201, nomeTOM: 'NÍSIA FLORESTA', nomeIBGE: 'Nísia Floresta' },
    { codigoTOM: 1765, codigoIBGE: 2408300, nomeTOM: 'NOVA CRUZ', nomeIBGE: 'Nova Cruz' },
    { codigoTOM: 1767, codigoIBGE: 2408409, nomeTOM: "OLHO D'AGUA DO BORGES", nomeIBGE: "Olho d'Água do Borges" },
    { codigoTOM: 1769, codigoIBGE: 2408508, nomeTOM: 'OURO BRANCO', nomeIBGE: 'Ouro Branco' },
    { codigoTOM: 1771, codigoIBGE: 2408607, nomeTOM: 'PARANÁ', nomeIBGE: 'Paraná' },
    { codigoTOM: 1773, codigoIBGE: 2408706, nomeTOM: 'PARAÚ', nomeIBGE: 'Paraú' },
    { codigoTOM: 1775, codigoIBGE: 2408805, nomeTOM: 'PARAZINHO', nomeIBGE: 'Parazinho' },
    { codigoTOM: 1777, codigoIBGE: 2408904, nomeTOM: 'PARELHAS', nomeIBGE: 'Parelhas' },
    { codigoTOM: 1779, codigoIBGE: 2403251, nomeTOM: 'PARNAMIRIM', nomeIBGE: 'Parnamirim' },
    { codigoTOM: 1781, codigoIBGE: 2409100, nomeTOM: 'PASSA E FICA', nomeIBGE: 'Passa e Fica' },
    { codigoTOM: 1783, codigoIBGE: 2409209, nomeTOM: 'PASSAGEM', nomeIBGE: 'Passagem' },
    { codigoTOM: 1785, codigoIBGE: 2409308, nomeTOM: 'PATU', nomeIBGE: 'Patu' },
    { codigoTOM: 1787, codigoIBGE: 2409407, nomeTOM: 'PAU DOS FERROS', nomeIBGE: 'Pau dos Ferros' },
    { codigoTOM: 1789, codigoIBGE: 2409506, nomeTOM: 'PEDRA GRANDE', nomeIBGE: 'Pedra Grande' },
    { codigoTOM: 1791, codigoIBGE: 2409605, nomeTOM: 'PEDRA PRETA', nomeIBGE: 'Pedra Preta' },
    { codigoTOM: 1793, codigoIBGE: 2409704, nomeTOM: 'PEDRO AVELINO', nomeIBGE: 'Pedro Avelino' },
    { codigoTOM: 1795, codigoIBGE: 2409803, nomeTOM: 'PEDRO VELHO', nomeIBGE: 'Pedro Velho' },
    { codigoTOM: 1797, codigoIBGE: 2409902, nomeTOM: 'PENDÊNCIAS', nomeIBGE: 'Pendências' },
    { codigoTOM: 1799, codigoIBGE: 2410009, nomeTOM: 'PILÕES', nomeIBGE: 'Pilões' },
    { codigoTOM: 1801, codigoIBGE: 2410108, nomeTOM: 'POÇO BRANCO', nomeIBGE: 'Poço Branco' },
    { codigoTOM: 1803, codigoIBGE: 2410207, nomeTOM: 'PORTALEGRE', nomeIBGE: 'Portalegre' },
    { codigoTOM: 1805, codigoIBGE: 2410306, nomeTOM: 'SERRA CAIADA', nomeIBGE: 'Serra Caiada' },
    { codigoTOM: 1807, codigoIBGE: 2410405, nomeTOM: 'PUREZA', nomeIBGE: 'Pureza' },
    { codigoTOM: 1809, codigoIBGE: 2410504, nomeTOM: 'RAFAEL FERNANDES', nomeIBGE: 'Rafael Fernandes' },
    { codigoTOM: 1811, codigoIBGE: 2410702, nomeTOM: 'RIACHO DA CRUZ', nomeIBGE: 'Riacho da Cruz' },
    { codigoTOM: 1813, codigoIBGE: 2410801, nomeTOM: 'RIACHO DE SANTANA', nomeIBGE: 'Riacho de Santana' },
    { codigoTOM: 1815, codigoIBGE: 2410900, nomeTOM: 'RIACHUELO', nomeIBGE: 'Riachuelo' },
    { codigoTOM: 1817, codigoIBGE: 2411007, nomeTOM: 'RODOLFO FERNANDES', nomeIBGE: 'Rodolfo Fernandes' },
    { codigoTOM: 1819, codigoIBGE: 2411106, nomeTOM: 'RUY BARBOSA', nomeIBGE: 'Ruy Barbosa' },
    { codigoTOM: 1821, codigoIBGE: 2411908, nomeTOM: 'SÃO FRANCISCO DO OESTE', nomeIBGE: 'São Francisco do Oeste' },
    { codigoTOM: 1823, codigoIBGE: 2411205, nomeTOM: 'SANTA CRUZ', nomeIBGE: 'Santa Cruz' },
    { codigoTOM: 1825, codigoIBGE: 2411429, nomeTOM: 'SANTANA DO SERIDÓ', nomeIBGE: 'Santana do Seridó' },
    { codigoTOM: 1827, codigoIBGE: 2411403, nomeTOM: 'SANTANA DO MATOS', nomeIBGE: 'Santana do Matos' },
    { codigoTOM: 1829, codigoIBGE: 2411502, nomeTOM: 'SANTO ANTÔNIO', nomeIBGE: 'Santo Antônio' },
    { codigoTOM: 1831, codigoIBGE: 2411601, nomeTOM: 'SÃO BENTO DO NORTE', nomeIBGE: 'São Bento do Norte' },
    { codigoTOM: 1833, codigoIBGE: 2411700, nomeTOM: 'SÃO BENTO DO TRAIRÍ', nomeIBGE: 'São Bento do Trairí' },
    { codigoTOM: 1835, codigoIBGE: 2411809, nomeTOM: 'SÃO FERNANDO', nomeIBGE: 'São Fernando' },
    { codigoTOM: 1837, codigoIBGE: 2412005, nomeTOM: 'SÃO GONÇALO DO AMARANTE', nomeIBGE: 'São Gonçalo do Amarante' },
    { codigoTOM: 1839, codigoIBGE: 2412104, nomeTOM: 'SÃO JOÃO DO SABUGI', nomeIBGE: 'São João do Sabugi' },
    { codigoTOM: 1841, codigoIBGE: 2412203, nomeTOM: 'SÃO JOSÉ DE MIPIBU', nomeIBGE: 'São José de Mipibu' },
    { codigoTOM: 1843, codigoIBGE: 2412302, nomeTOM: 'SÃO JOSÉ DO CAMPESTRE', nomeIBGE: 'São José do Campestre' },
    { codigoTOM: 1845, codigoIBGE: 2412401, nomeTOM: 'SÃO JOSÉ DO SERIDÓ', nomeIBGE: 'São José do Seridó' },
    { codigoTOM: 1847, codigoIBGE: 2412500, nomeTOM: 'SÃO MIGUEL', nomeIBGE: 'São Miguel' },
    { codigoTOM: 1849, codigoIBGE: 2412609, nomeTOM: 'SÃO PAULO DO POTENGI', nomeIBGE: 'São Paulo do Potengi' },
    { codigoTOM: 1851, codigoIBGE: 2412708, nomeTOM: 'SÃO PEDRO', nomeIBGE: 'São Pedro' },
    { codigoTOM: 1853, codigoIBGE: 2412807, nomeTOM: 'SÃO RAFAEL', nomeIBGE: 'São Rafael' },
    { codigoTOM: 1855, codigoIBGE: 2412906, nomeTOM: 'SÃO TOMÉ', nomeIBGE: 'São Tomé' },
    { codigoTOM: 1857, codigoIBGE: 2413003, nomeTOM: 'SÃO VICENTE', nomeIBGE: 'São Vicente' },
    { codigoTOM: 1859, codigoIBGE: 2413102, nomeTOM: 'SENADOR ELÓI DE SOUZA', nomeIBGE: 'Senador Elói de Souza' },
    { codigoTOM: 1861, codigoIBGE: 2413201, nomeTOM: 'SENADOR GEORGINO AVELINO', nomeIBGE: 'Senador Georgino Avelino' },
    { codigoTOM: 1863, codigoIBGE: 2413300, nomeTOM: 'SERRA DE SÃO BENTO', nomeIBGE: 'Serra de São Bento' },
    { codigoTOM: 1865, codigoIBGE: 2413409, nomeTOM: 'SERRA NEGRA DO NORTE', nomeIBGE: 'Serra Negra do Norte' },
    { codigoTOM: 1867, codigoIBGE: 2413508, nomeTOM: 'SERRINHA', nomeIBGE: 'Serrinha' },
    { codigoTOM: 1869, codigoIBGE: 2413607, nomeTOM: 'SEVERIANO MELO', nomeIBGE: 'Severiano Melo' },
    { codigoTOM: 1871, codigoIBGE: 2413706, nomeTOM: 'SÍTIO NOVO', nomeIBGE: 'Sítio Novo' },
    { codigoTOM: 1873, codigoIBGE: 2413805, nomeTOM: 'TABOLEIRO GRANDE', nomeIBGE: 'Taboleiro Grande' },
    { codigoTOM: 1875, codigoIBGE: 2413904, nomeTOM: 'TAIPU', nomeIBGE: 'Taipu' },
    { codigoTOM: 1877, codigoIBGE: 2414001, nomeTOM: 'TANGARÁ', nomeIBGE: 'Tangará' },
    { codigoTOM: 1879, codigoIBGE: 2414100, nomeTOM: 'TENENTE ANANIAS', nomeIBGE: 'Tenente Ananias' },
    { codigoTOM: 1881, codigoIBGE: 2414209, nomeTOM: 'TIBAU DO SUL', nomeIBGE: 'Tibau do Sul' },
    { codigoTOM: 1883, codigoIBGE: 2414308, nomeTOM: 'TIMBAÚBA DOS BATISTAS', nomeIBGE: 'Timbaúba dos Batistas' },
    { codigoTOM: 1885, codigoIBGE: 2414407, nomeTOM: 'TOUROS', nomeIBGE: 'Touros' },
    { codigoTOM: 1887, codigoIBGE: 2414506, nomeTOM: 'UMARIZAL', nomeIBGE: 'Umarizal' },
    { codigoTOM: 1889, codigoIBGE: 2414605, nomeTOM: 'UPANEMA', nomeIBGE: 'Upanema' },
    { codigoTOM: 1891, codigoIBGE: 2414704, nomeTOM: 'VÁRZEA', nomeIBGE: 'Várzea' },
    { codigoTOM: 1893, codigoIBGE: 2410603, nomeTOM: 'RAFAEL GODEIRO', nomeIBGE: 'Rafael Godeiro' },
    { codigoTOM: 1895, codigoIBGE: 2414803, nomeTOM: 'VERA CRUZ', nomeIBGE: 'Vera Cruz' },
    { codigoTOM: 1897, codigoIBGE: 2414902, nomeTOM: 'VIÇOSA', nomeIBGE: 'Viçosa' },
    { codigoTOM: 1899, codigoIBGE: 2415008, nomeTOM: 'VILA FLOR', nomeIBGE: 'Vila Flor' },
    { codigoTOM: 1927, codigoIBGE: 2413359, nomeTOM: 'SERRA DO MEL', nomeIBGE: 'Serra do Mel' },
    { codigoTOM: 3003, codigoIBGE: 2401453, nomeTOM: 'BARAÚNA', nomeIBGE: 'Baraúna' },
  ],
  [Estados.PB]: [
    { codigoTOM: 440, codigoIBGE: 2500536, nomeTOM: 'ALCANTIL', nomeIBGE: 'Alcantil' },
    { codigoTOM: 442, codigoIBGE: 2500577, nomeTOM: 'ALGODÃO DE JANDAÍRA', nomeIBGE: 'Algodão de Jandaíra' },
    { codigoTOM: 444, codigoIBGE: 2500734, nomeTOM: 'AMPARO', nomeIBGE: 'Amparo' },
    { codigoTOM: 446, codigoIBGE: 2500775, nomeTOM: 'APARECIDA', nomeIBGE: 'Aparecida' },
    { codigoTOM: 448, codigoIBGE: 2501153, nomeTOM: 'AREIA DE BARAÚNAS', nomeIBGE: 'Areia de Baraúnas' },
    { codigoTOM: 450, codigoIBGE: 2501351, nomeTOM: 'ASSUNÇÃO', nomeIBGE: 'Assunção' },
    { codigoTOM: 452, codigoIBGE: 2501534, nomeTOM: 'BARAÚNA', nomeIBGE: 'Baraúna' },
    { codigoTOM: 454, codigoIBGE: 2501575, nomeTOM: 'BARRA DE SANTANA', nomeIBGE: 'Barra de Santana' },
    { codigoTOM: 456, codigoIBGE: 2502052, nomeTOM: 'BERNARDINO BATISTA', nomeIBGE: 'Bernardino Batista' },
    { codigoTOM: 458, codigoIBGE: 2502151, nomeTOM: 'BOA VISTA', nomeIBGE: 'Boa Vista' },
    { codigoTOM: 460, codigoIBGE: 2503555, nomeTOM: 'CACIMBAS', nomeIBGE: 'Cacimbas' },
    { codigoTOM: 462, codigoIBGE: 2503753, nomeTOM: 'CAJAZEIRINHAS', nomeIBGE: 'Cajazeirinhas' },
    { codigoTOM: 464, codigoIBGE: 2504033, nomeTOM: 'CAPIM', nomeIBGE: 'Capim' },
    { codigoTOM: 466, codigoIBGE: 2504074, nomeTOM: 'CARAÚBAS', nomeIBGE: 'Caraúbas' },
    { codigoTOM: 468, codigoIBGE: 2504157, nomeTOM: 'CASSERENGUE', nomeIBGE: 'Casserengue' },
    { codigoTOM: 470, codigoIBGE: 2504355, nomeTOM: 'CATURITÉ', nomeIBGE: 'Caturité' },
    { codigoTOM: 472, codigoIBGE: 2504850, nomeTOM: 'COXIXOLA', nomeIBGE: 'Coxixola' },
    { codigoTOM: 474, codigoIBGE: 2505238, nomeTOM: 'CUITÉ DE MAMANGUAPE', nomeIBGE: 'Cuité de Mamanguape' },
    { codigoTOM: 476, codigoIBGE: 2505279, nomeTOM: 'CURRAL DE CIMA', nomeIBGE: 'Curral de Cima' },
    { codigoTOM: 478, codigoIBGE: 2505352, nomeTOM: 'DAMIÃO', nomeIBGE: 'Damião' },
    { codigoTOM: 480, codigoIBGE: 2506251, nomeTOM: 'GADO BRAVO', nomeIBGE: 'Gado Bravo' },
    { codigoTOM: 482, codigoIBGE: 2508554, nomeTOM: 'LOGRADOURO', nomeIBGE: 'Logradouro' },
    { codigoTOM: 484, codigoIBGE: 2509057, nomeTOM: 'MARCAÇÃO', nomeIBGE: 'Marcação' },
    { codigoTOM: 486, codigoIBGE: 2509156, nomeTOM: 'MARIZÓPOLIS', nomeIBGE: 'Marizópolis' },
    { codigoTOM: 488, codigoIBGE: 2509339, nomeTOM: 'MATINHAS', nomeIBGE: 'Matinhas' },
    { codigoTOM: 490, codigoIBGE: 2509370, nomeTOM: 'MATO GROSSO', nomeIBGE: 'Mato Grosso' },
    { codigoTOM: 492, codigoIBGE: 2509396, nomeTOM: 'MATURÉIA', nomeIBGE: 'Maturéia' },
    { codigoTOM: 494, codigoIBGE: 2510659, nomeTOM: 'PARARI', nomeIBGE: 'Parari' },
    { codigoTOM: 496, codigoIBGE: 2512036, nomeTOM: 'POÇO DANTAS', nomeIBGE: 'Poço Dantas' },
    { codigoTOM: 498, codigoIBGE: 2512077, nomeTOM: 'POÇO DE JOSÉ DE MOURA', nomeIBGE: 'Poço de José de Moura' },
    { codigoTOM: 500, codigoIBGE: 2512721, nomeTOM: 'PEDRO RÉGIS', nomeIBGE: 'Pedro Régis' },
    { codigoTOM: 502, codigoIBGE: 2512747, nomeTOM: 'RIACHÃO', nomeIBGE: 'Riachão' },
    { codigoTOM: 504, codigoIBGE: 2512754, nomeTOM: 'RIACHÃO DO BACAMARTE', nomeIBGE: 'Riachão do Bacamarte' },
    { codigoTOM: 506, codigoIBGE: 2512762, nomeTOM: 'RIACHÃO DO POÇO', nomeIBGE: 'Riachão do Poço' },
    { codigoTOM: 508, codigoIBGE: 2512788, nomeTOM: 'RIACHO DE SANTO ANTÔNIO', nomeIBGE: 'Riacho de Santo Antônio' },
    { codigoTOM: 510, codigoIBGE: 2513158, nomeTOM: 'SANTA CECÍLIA', nomeIBGE: 'Santa Cecília' },
    { codigoTOM: 512, codigoIBGE: 2513356, nomeTOM: 'SANTA INÊS', nomeIBGE: 'Santa Inês' },
    { codigoTOM: 514, codigoIBGE: 2513653, nomeTOM: 'JOCA CLAUDINO', nomeIBGE: 'Joca Claudino' },
    { codigoTOM: 516, codigoIBGE: 2513851, nomeTOM: 'SANTO ANDRÉ', nomeIBGE: 'Santo André' },
    { codigoTOM: 518, codigoIBGE: 2513927, nomeTOM: 'SÃO BENTINHO', nomeIBGE: 'São Bentinho' },
    { codigoTOM: 520, codigoIBGE: 2513943, nomeTOM: 'SÃO DOMINGOS DO CARIRI', nomeIBGE: 'São Domingos do Cariri' },
    { codigoTOM: 522, codigoIBGE: 2513968, nomeTOM: 'SÃO DOMINGOS', nomeIBGE: 'São Domingos' },
    { codigoTOM: 524, codigoIBGE: 2513984, nomeTOM: 'SÃO FRANCISCO', nomeIBGE: 'São Francisco' },
    { codigoTOM: 526, codigoIBGE: 2514453, nomeTOM: 'SÃO JOSÉ DOS RAMOS', nomeIBGE: 'São José dos Ramos' },
    { codigoTOM: 528, codigoIBGE: 2514552, nomeTOM: 'SÃO JOSÉ DE PRINCESA', nomeIBGE: 'São José de Princesa' },
    {
      codigoTOM: 530,
      codigoIBGE: 2514651,
      nomeTOM: 'SÃO JOSÉ DO BREJO DO CRUZ',
      nomeIBGE: 'São José do Brejo do Cruz',
    },
    { codigoTOM: 532, codigoIBGE: 2515930, nomeTOM: 'SERTÃOZINHO', nomeIBGE: 'Sertãozinho' },
    { codigoTOM: 534, codigoIBGE: 2515971, nomeTOM: 'SOBRADO', nomeIBGE: 'Sobrado' },
    { codigoTOM: 536, codigoIBGE: 2516151, nomeTOM: 'SOSSÊGO', nomeIBGE: 'Sossêgo' },
    { codigoTOM: 538, codigoIBGE: 2516755, nomeTOM: 'TENÓRIO', nomeIBGE: 'Tenório' },
    { codigoTOM: 540, codigoIBGE: 2517209, nomeTOM: 'VIEIRÓPOLIS', nomeIBGE: 'Vieirópolis' },
    { codigoTOM: 542, codigoIBGE: 2517407, nomeTOM: 'ZABELÊ', nomeIBGE: 'Zabelê' },
    { codigoTOM: 1901, codigoIBGE: 2500106, nomeTOM: 'ÁGUA BRANCA', nomeIBGE: 'Água Branca' },
    { codigoTOM: 1903, codigoIBGE: 2500205, nomeTOM: 'AGUIAR', nomeIBGE: 'Aguiar' },
    { codigoTOM: 1905, codigoIBGE: 2500304, nomeTOM: 'ALAGOA GRANDE', nomeIBGE: 'Alagoa Grande' },
    { codigoTOM: 1907, codigoIBGE: 2500403, nomeTOM: 'ALAGOA NOVA', nomeIBGE: 'Alagoa Nova' },
    { codigoTOM: 1909, codigoIBGE: 2500502, nomeTOM: 'ALAGOINHA', nomeIBGE: 'Alagoinha' },
    { codigoTOM: 1911, codigoIBGE: 2500601, nomeTOM: 'ALHANDRA', nomeIBGE: 'Alhandra' },
    { codigoTOM: 1913, codigoIBGE: 2500700, nomeTOM: 'SÃO JOÃO DO RIO DO PEIXE', nomeIBGE: 'São João do Rio do Peixe' },
    { codigoTOM: 1915, codigoIBGE: 2500809, nomeTOM: 'ARAÇAGI', nomeIBGE: 'Araçagi' },
    { codigoTOM: 1917, codigoIBGE: 2500908, nomeTOM: 'ARARA', nomeIBGE: 'Arara' },
    { codigoTOM: 1919, codigoIBGE: 2501005, nomeTOM: 'ARARUNA', nomeIBGE: 'Araruna' },
    { codigoTOM: 1921, codigoIBGE: 2501104, nomeTOM: 'AREIA', nomeIBGE: 'Areia' },
    { codigoTOM: 1923, codigoIBGE: 2501203, nomeTOM: 'AREIAL', nomeIBGE: 'Areial' },
    { codigoTOM: 1925, codigoIBGE: 2501302, nomeTOM: 'AROEIRAS', nomeIBGE: 'Aroeiras' },
    { codigoTOM: 1929, codigoIBGE: 2501401, nomeTOM: 'BAÍA DA TRAIÇÃO', nomeIBGE: 'Baía da Traição' },
    { codigoTOM: 1931, codigoIBGE: 2501500, nomeTOM: 'BANANEIRAS', nomeIBGE: 'Bananeiras' },
    { codigoTOM: 1933, codigoIBGE: 2501609, nomeTOM: 'BARRA DE SANTA ROSA', nomeIBGE: 'Barra de Santa Rosa' },
    { codigoTOM: 1935, codigoIBGE: 2501708, nomeTOM: 'BARRA DE SÃO MIGUEL', nomeIBGE: 'Barra de São Miguel' },
    { codigoTOM: 1937, codigoIBGE: 2501807, nomeTOM: 'BAYEUX', nomeIBGE: 'Bayeux' },
    { codigoTOM: 1939, codigoIBGE: 2501906, nomeTOM: 'BELÉM', nomeIBGE: 'Belém' },
    { codigoTOM: 1941, codigoIBGE: 2502003, nomeTOM: 'BELÉM DO BREJO DO CRUZ', nomeIBGE: 'Belém do Brejo do Cruz' },
    { codigoTOM: 1943, codigoIBGE: 2502102, nomeTOM: 'BOA VENTURA', nomeIBGE: 'Boa Ventura' },
    { codigoTOM: 1945, codigoIBGE: 2502201, nomeTOM: 'BOM JESUS', nomeIBGE: 'Bom Jesus' },
    { codigoTOM: 1947, codigoIBGE: 2502300, nomeTOM: 'BOM SUCESSO', nomeIBGE: 'Bom Sucesso' },
    { codigoTOM: 1949, codigoIBGE: 2502409, nomeTOM: 'BONITO DE SANTA FÉ', nomeIBGE: 'Bonito de Santa Fé' },
    { codigoTOM: 1951, codigoIBGE: 2502508, nomeTOM: 'BOQUEIRÃO', nomeIBGE: 'Boqueirão' },
    { codigoTOM: 1953, codigoIBGE: 2502607, nomeTOM: 'IGARACY', nomeIBGE: 'Igaracy' },
    { codigoTOM: 1955, codigoIBGE: 2502706, nomeTOM: 'BORBOREMA', nomeIBGE: 'Borborema' },
    { codigoTOM: 1957, codigoIBGE: 2502805, nomeTOM: 'BREJO DO CRUZ', nomeIBGE: 'Brejo do Cruz' },
    { codigoTOM: 1959, codigoIBGE: 2502904, nomeTOM: 'BREJO DOS SANTOS', nomeIBGE: 'Brejo dos Santos' },
    { codigoTOM: 1961, codigoIBGE: 2503001, nomeTOM: 'CAAPORÃ', nomeIBGE: 'Caaporã' },
    { codigoTOM: 1963, codigoIBGE: 2503100, nomeTOM: 'CABACEIRAS', nomeIBGE: 'Cabaceiras' },
    { codigoTOM: 1965, codigoIBGE: 2503209, nomeTOM: 'CABEDELO', nomeIBGE: 'Cabedelo' },
    { codigoTOM: 1967, codigoIBGE: 2503308, nomeTOM: 'CACHOEIRA DOS ÍNDIOS', nomeIBGE: 'Cachoeira dos Índios' },
    { codigoTOM: 1969, codigoIBGE: 2503407, nomeTOM: 'CACIMBA DE AREIA', nomeIBGE: 'Cacimba de Areia' },
    { codigoTOM: 1971, codigoIBGE: 2503506, nomeTOM: 'CACIMBA DE DENTRO', nomeIBGE: 'Cacimba de Dentro' },
    { codigoTOM: 1973, codigoIBGE: 2503605, nomeTOM: 'CAIÇARA', nomeIBGE: 'Caiçara' },
    { codigoTOM: 1975, codigoIBGE: 2503704, nomeTOM: 'CAJAZEIRAS', nomeIBGE: 'Cajazeiras' },
    { codigoTOM: 1977, codigoIBGE: 2503803, nomeTOM: 'CALDAS BRANDÃO', nomeIBGE: 'Caldas Brandão' },
    { codigoTOM: 1979, codigoIBGE: 2503902, nomeTOM: 'CAMALAÚ', nomeIBGE: 'Camalaú' },
    { codigoTOM: 1981, codigoIBGE: 2504009, nomeTOM: 'CAMPINA GRANDE', nomeIBGE: 'Campina Grande' },
    { codigoTOM: 1983, codigoIBGE: 2504108, nomeTOM: 'CARRAPATEIRA', nomeIBGE: 'Carrapateira' },
    { codigoTOM: 1985, codigoIBGE: 2504207, nomeTOM: 'CATINGUEIRA', nomeIBGE: 'Catingueira' },
    { codigoTOM: 1987, codigoIBGE: 2504306, nomeTOM: 'CATOLÉ DO ROCHA', nomeIBGE: 'Catolé do Rocha' },
    { codigoTOM: 1989, codigoIBGE: 2504405, nomeTOM: 'CONCEIÇÃO', nomeIBGE: 'Conceição' },
    { codigoTOM: 1991, codigoIBGE: 2504504, nomeTOM: 'CONDADO', nomeIBGE: 'Condado' },
    { codigoTOM: 1993, codigoIBGE: 2504603, nomeTOM: 'CONDE', nomeIBGE: 'Conde' },
    { codigoTOM: 1995, codigoIBGE: 2504702, nomeTOM: 'CONGO', nomeIBGE: 'Congo' },
    { codigoTOM: 1997, codigoIBGE: 2504801, nomeTOM: 'COREMAS', nomeIBGE: 'Coremas' },
    { codigoTOM: 1999, codigoIBGE: 2504900, nomeTOM: 'CRUZ DO ESPÍRITO SANTO', nomeIBGE: 'Cruz do Espírito Santo' },
    { codigoTOM: 2001, codigoIBGE: 2505006, nomeTOM: 'CUBATI', nomeIBGE: 'Cubati' },
    { codigoTOM: 2003, codigoIBGE: 2505105, nomeTOM: 'CUITÉ', nomeIBGE: 'Cuité' },
    { codigoTOM: 2005, codigoIBGE: 2505204, nomeTOM: 'CUITEGI', nomeIBGE: 'Cuitegi' },
    { codigoTOM: 2007, codigoIBGE: 2505303, nomeTOM: 'CURRAL VELHO', nomeIBGE: 'Curral Velho' },
    { codigoTOM: 2009, codigoIBGE: 2505402, nomeTOM: 'DESTERRO', nomeIBGE: 'Desterro' },
    { codigoTOM: 2011, codigoIBGE: 2505501, nomeTOM: 'VISTA SERRANA', nomeIBGE: 'Vista Serrana' },
    { codigoTOM: 2013, codigoIBGE: 2505600, nomeTOM: 'DIAMANTE', nomeIBGE: 'Diamante' },
    { codigoTOM: 2015, codigoIBGE: 2505709, nomeTOM: 'DONA INÊS', nomeIBGE: 'Dona Inês' },
    { codigoTOM: 2017, codigoIBGE: 2505808, nomeTOM: 'DUAS ESTRADAS', nomeIBGE: 'Duas Estradas' },
    { codigoTOM: 2019, codigoIBGE: 2505907, nomeTOM: 'EMAS', nomeIBGE: 'Emas' },
    { codigoTOM: 2021, codigoIBGE: 2506004, nomeTOM: 'ESPERANÇA', nomeIBGE: 'Esperança' },
    { codigoTOM: 2023, codigoIBGE: 2506103, nomeTOM: 'FAGUNDES', nomeIBGE: 'Fagundes' },
    { codigoTOM: 2025, codigoIBGE: 2506202, nomeTOM: 'FREI MARTINHO', nomeIBGE: 'Frei Martinho' },
    { codigoTOM: 2027, codigoIBGE: 2506301, nomeTOM: 'GUARABIRA', nomeIBGE: 'Guarabira' },
    { codigoTOM: 2029, codigoIBGE: 2506400, nomeTOM: 'GURINHÉM', nomeIBGE: 'Gurinhém' },
    { codigoTOM: 2031, codigoIBGE: 2506509, nomeTOM: 'GURJÃO', nomeIBGE: 'Gurjão' },
    { codigoTOM: 2033, codigoIBGE: 2506608, nomeTOM: 'IBIARA', nomeIBGE: 'Ibiara' },
    { codigoTOM: 2035, codigoIBGE: 2506707, nomeTOM: 'IMACULADA', nomeIBGE: 'Imaculada' },
    { codigoTOM: 2037, codigoIBGE: 2506806, nomeTOM: 'INGÁ', nomeIBGE: 'Ingá' },
    { codigoTOM: 2039, codigoIBGE: 2506905, nomeTOM: 'ITABAIANA', nomeIBGE: 'Itabaiana' },
    { codigoTOM: 2041, codigoIBGE: 2507002, nomeTOM: 'ITAPORANGA', nomeIBGE: 'Itaporanga' },
    { codigoTOM: 2043, codigoIBGE: 2507101, nomeTOM: 'ITAPOROROCA', nomeIBGE: 'Itapororoca' },
    { codigoTOM: 2045, codigoIBGE: 2507200, nomeTOM: 'ITATUBA', nomeIBGE: 'Itatuba' },
    { codigoTOM: 2047, codigoIBGE: 2507309, nomeTOM: 'JACARAÚ', nomeIBGE: 'Jacaraú' },
    { codigoTOM: 2049, codigoIBGE: 2507408, nomeTOM: 'JERICÓ', nomeIBGE: 'Jericó' },
    { codigoTOM: 2051, codigoIBGE: 2507507, nomeTOM: 'JOÃO PESSOA', nomeIBGE: 'João Pessoa' },
    { codigoTOM: 2053, codigoIBGE: 2507606, nomeTOM: 'JUAREZ TÁVORA', nomeIBGE: 'Juarez Távora' },
    { codigoTOM: 2055, codigoIBGE: 2507705, nomeTOM: 'JUAZEIRINHO', nomeIBGE: 'Juazeirinho' },
    { codigoTOM: 2057, codigoIBGE: 2507804, nomeTOM: 'JUNCO DO SERIDÓ', nomeIBGE: 'Junco do Seridó' },
    { codigoTOM: 2059, codigoIBGE: 2507903, nomeTOM: 'JURIPIRANGA', nomeIBGE: 'Juripiranga' },
    { codigoTOM: 2061, codigoIBGE: 2508000, nomeTOM: 'JURU', nomeIBGE: 'Juru' },
    { codigoTOM: 2063, codigoIBGE: 2508109, nomeTOM: 'LAGOA', nomeIBGE: 'Lagoa' },
    { codigoTOM: 2065, codigoIBGE: 2508208, nomeTOM: 'LAGOA DE DENTRO', nomeIBGE: 'Lagoa de Dentro' },
    { codigoTOM: 2067, codigoIBGE: 2508307, nomeTOM: 'LAGOA SECA', nomeIBGE: 'Lagoa Seca' },
    { codigoTOM: 2069, codigoIBGE: 2508406, nomeTOM: 'LASTRO', nomeIBGE: 'Lastro' },
    { codigoTOM: 2071, codigoIBGE: 2508505, nomeTOM: 'LIVRAMENTO', nomeIBGE: 'Livramento' },
    { codigoTOM: 2073, codigoIBGE: 2508604, nomeTOM: 'LUCENA', nomeIBGE: 'Lucena' },
    { codigoTOM: 2075, codigoIBGE: 2508703, nomeTOM: "MÃE D'ÁGUA", nomeIBGE: "Mãe d'Água" },
    { codigoTOM: 2077, codigoIBGE: 2508802, nomeTOM: 'MALTA', nomeIBGE: 'Malta' },
    { codigoTOM: 2079, codigoIBGE: 2508901, nomeTOM: 'MAMANGUAPE', nomeIBGE: 'Mamanguape' },
    { codigoTOM: 2081, codigoIBGE: 2509008, nomeTOM: 'MANAÍRA', nomeIBGE: 'Manaíra' },
    { codigoTOM: 2083, codigoIBGE: 2509107, nomeTOM: 'MARI', nomeIBGE: 'Mari' },
    { codigoTOM: 2085, codigoIBGE: 2509206, nomeTOM: 'MASSARANDUBA', nomeIBGE: 'Massaranduba' },
    { codigoTOM: 2087, codigoIBGE: 2509305, nomeTOM: 'MATARACA', nomeIBGE: 'Mataraca' },
    { codigoTOM: 2089, codigoIBGE: 2509404, nomeTOM: 'MOGEIRO', nomeIBGE: 'Mogeiro' },
    { codigoTOM: 2091, codigoIBGE: 2509503, nomeTOM: 'MONTADAS', nomeIBGE: 'Montadas' },
    { codigoTOM: 2093, codigoIBGE: 2509602, nomeTOM: 'MONTE HOREBE', nomeIBGE: 'Monte Horebe' },
    { codigoTOM: 2095, codigoIBGE: 2509701, nomeTOM: 'MONTEIRO', nomeIBGE: 'Monteiro' },
    { codigoTOM: 2097, codigoIBGE: 2509800, nomeTOM: 'MULUNGU', nomeIBGE: 'Mulungu' },
    { codigoTOM: 2099, codigoIBGE: 2509909, nomeTOM: 'NATUBA', nomeIBGE: 'Natuba' },
    { codigoTOM: 2101, codigoIBGE: 2510006, nomeTOM: 'NAZAREZINHO', nomeIBGE: 'Nazarezinho' },
    { codigoTOM: 2103, codigoIBGE: 2510105, nomeTOM: 'NOVA FLORESTA', nomeIBGE: 'Nova Floresta' },
    { codigoTOM: 2105, codigoIBGE: 2510204, nomeTOM: 'NOVA OLINDA', nomeIBGE: 'Nova Olinda' },
    { codigoTOM: 2107, codigoIBGE: 2510303, nomeTOM: 'NOVA PALMEIRA', nomeIBGE: 'Nova Palmeira' },
    { codigoTOM: 2109, codigoIBGE: 2510402, nomeTOM: "OLHO D'ÁGUA", nomeIBGE: "Olho d'Água" },
    { codigoTOM: 2111, codigoIBGE: 2510501, nomeTOM: 'OLIVEDOS', nomeIBGE: 'Olivedos' },
    { codigoTOM: 2113, codigoIBGE: 2510600, nomeTOM: 'OURO VELHO', nomeIBGE: 'Ouro Velho' },
    { codigoTOM: 2115, codigoIBGE: 2510709, nomeTOM: 'PASSAGEM', nomeIBGE: 'Passagem' },
    { codigoTOM: 2117, codigoIBGE: 2510808, nomeTOM: 'PATOS', nomeIBGE: 'Patos' },
    { codigoTOM: 2119, codigoIBGE: 2510907, nomeTOM: 'PAULISTA', nomeIBGE: 'Paulista' },
    { codigoTOM: 2121, codigoIBGE: 2511004, nomeTOM: 'PEDRA BRANCA', nomeIBGE: 'Pedra Branca' },
    { codigoTOM: 2123, codigoIBGE: 2511103, nomeTOM: 'PEDRA LAVRADA', nomeIBGE: 'Pedra Lavrada' },
    { codigoTOM: 2125, codigoIBGE: 2511202, nomeTOM: 'PEDRAS DE FOGO', nomeIBGE: 'Pedras de Fogo' },
    { codigoTOM: 2127, codigoIBGE: 2511301, nomeTOM: 'PIANCÓ', nomeIBGE: 'Piancó' },
    { codigoTOM: 2129, codigoIBGE: 2511400, nomeTOM: 'PICUÍ', nomeIBGE: 'Picuí' },
    { codigoTOM: 2131, codigoIBGE: 2511509, nomeTOM: 'PILAR', nomeIBGE: 'Pilar' },
    { codigoTOM: 2133, codigoIBGE: 2511608, nomeTOM: 'PILÕES', nomeIBGE: 'Pilões' },
    { codigoTOM: 2135, codigoIBGE: 2511707, nomeTOM: 'PILÕEZINHOS', nomeIBGE: 'Pilõezinhos' },
    { codigoTOM: 2137, codigoIBGE: 2511806, nomeTOM: 'PIRPIRITUBA', nomeIBGE: 'Pirpirituba' },
    { codigoTOM: 2139, codigoIBGE: 2511905, nomeTOM: 'PITIMBU', nomeIBGE: 'Pitimbu' },
    { codigoTOM: 2141, codigoIBGE: 2512002, nomeTOM: 'POCINHOS', nomeIBGE: 'Pocinhos' },
    { codigoTOM: 2143, codigoIBGE: 2512101, nomeTOM: 'POMBAL', nomeIBGE: 'Pombal' },
    { codigoTOM: 2145, codigoIBGE: 2512200, nomeTOM: 'PRATA', nomeIBGE: 'Prata' },
    { codigoTOM: 2147, codigoIBGE: 2512309, nomeTOM: 'PRINCESA ISABEL', nomeIBGE: 'Princesa Isabel' },
    { codigoTOM: 2149, codigoIBGE: 2512408, nomeTOM: 'PUXINANÃ', nomeIBGE: 'Puxinanã' },
    { codigoTOM: 2151, codigoIBGE: 2512507, nomeTOM: 'QUEIMADAS', nomeIBGE: 'Queimadas' },
    { codigoTOM: 2153, codigoIBGE: 2512606, nomeTOM: 'QUIXABÁ', nomeIBGE: 'Quixaba' },
    { codigoTOM: 2155, codigoIBGE: 2512705, nomeTOM: 'REMÍGIO', nomeIBGE: 'Remígio' },
    { codigoTOM: 2157, codigoIBGE: 2512804, nomeTOM: 'RIACHO DOS CAVALOS', nomeIBGE: 'Riacho dos Cavalos' },
    { codigoTOM: 2159, codigoIBGE: 2512903, nomeTOM: 'RIO TINTO', nomeIBGE: 'Rio Tinto' },
    { codigoTOM: 2161, codigoIBGE: 2513000, nomeTOM: 'SALGADINHO', nomeIBGE: 'Salgadinho' },
    { codigoTOM: 2163, codigoIBGE: 2513109, nomeTOM: 'SALGADO DE SÃO FÉLIX', nomeIBGE: 'Salgado de São Félix' },
    { codigoTOM: 2165, codigoIBGE: 2513208, nomeTOM: 'SANTA CRUZ', nomeIBGE: 'Santa Cruz' },
    { codigoTOM: 2167, codigoIBGE: 2513307, nomeTOM: 'SANTA HELENA', nomeIBGE: 'Santa Helena' },
    { codigoTOM: 2169, codigoIBGE: 2513406, nomeTOM: 'SANTA LUZIA', nomeIBGE: 'Santa Luzia' },
    { codigoTOM: 2171, codigoIBGE: 2513505, nomeTOM: 'SANTANA DE MANGUEIRA', nomeIBGE: 'Santana de Mangueira' },
    { codigoTOM: 2173, codigoIBGE: 2513604, nomeTOM: 'SANTANA DOS GARROTES', nomeIBGE: 'Santana dos Garrotes' },
    { codigoTOM: 2175, codigoIBGE: 2513703, nomeTOM: 'SANTA RITA', nomeIBGE: 'Santa Rita' },
    { codigoTOM: 2177, codigoIBGE: 2513802, nomeTOM: 'SANTA TERESINHA', nomeIBGE: 'Santa Teresinha' },
    { codigoTOM: 2179, codigoIBGE: 2513901, nomeTOM: 'SÃO BENTO', nomeIBGE: 'São Bento' },
    { codigoTOM: 2181, codigoIBGE: 2514008, nomeTOM: 'SÃO JOÃO DO CARIRI', nomeIBGE: 'São João do Cariri' },
    { codigoTOM: 2183, codigoIBGE: 2514107, nomeTOM: 'SÃO JOÃO DO TIGRE', nomeIBGE: 'São João do Tigre' },
    { codigoTOM: 2185, codigoIBGE: 2514206, nomeTOM: 'SÃO JOSÉ DA LAGOA TAPADA', nomeIBGE: 'São José da Lagoa Tapada' },
    { codigoTOM: 2187, codigoIBGE: 2514305, nomeTOM: 'SÃO JOSÉ DE CAIANA', nomeIBGE: 'São José de Caiana' },
    { codigoTOM: 2189, codigoIBGE: 2514404, nomeTOM: 'SÃO JOSÉ DE ESPINHARAS', nomeIBGE: 'São José de Espinharas' },
    { codigoTOM: 2191, codigoIBGE: 2514503, nomeTOM: 'SÃO JOSÉ DE PIRANHAS', nomeIBGE: 'São José de Piranhas' },
    { codigoTOM: 2193, codigoIBGE: 2514602, nomeTOM: 'SÃO JOSÉ DO BONFIM', nomeIBGE: 'São José do Bonfim' },
    { codigoTOM: 2195, codigoIBGE: 2514701, nomeTOM: 'SÃO JOSÉ DO SABUGI', nomeIBGE: 'São José do Sabugi' },
    { codigoTOM: 2197, codigoIBGE: 2514800, nomeTOM: 'SÃO JOSÉ DOS CORDEIROS', nomeIBGE: 'São José dos Cordeiros' },
    { codigoTOM: 2199, codigoIBGE: 2514909, nomeTOM: 'SÃO MAMEDE', nomeIBGE: 'São Mamede' },
    { codigoTOM: 2201, codigoIBGE: 2515005, nomeTOM: 'SÃO MIGUEL DE TAIPU', nomeIBGE: 'São Miguel de Taipu' },
    {
      codigoTOM: 2203,
      codigoIBGE: 2515104,
      nomeTOM: 'SÃO SEBASTIÃO DE LAGOA DE ROÇA',
      nomeIBGE: 'São Sebastião de Lagoa de Roça',
    },
    {
      codigoTOM: 2205,
      codigoIBGE: 2515203,
      nomeTOM: 'SÃO SEBASTIÃO DO UMBUZEIRO',
      nomeIBGE: 'São Sebastião do Umbuzeiro',
    },
    { codigoTOM: 2207, codigoIBGE: 2515302, nomeTOM: 'SAPÉ', nomeIBGE: 'Sapé' },
    { codigoTOM: 2209, codigoIBGE: 2515401, nomeTOM: 'SÃO VICENTE DO SERIDÓ', nomeIBGE: 'São Vicente do Seridó' },
    { codigoTOM: 2211, codigoIBGE: 2515500, nomeTOM: 'SERRA BRANCA', nomeIBGE: 'Serra Branca' },
    { codigoTOM: 2213, codigoIBGE: 2515609, nomeTOM: 'SERRA DA RAIZ', nomeIBGE: 'Serra da Raiz' },
    { codigoTOM: 2215, codigoIBGE: 2515708, nomeTOM: 'SERRA GRANDE', nomeIBGE: 'Serra Grande' },
    { codigoTOM: 2217, codigoIBGE: 2515807, nomeTOM: 'SERRA REDONDA', nomeIBGE: 'Serra Redonda' },
    { codigoTOM: 2219, codigoIBGE: 2515906, nomeTOM: 'SERRARIA', nomeIBGE: 'Serraria' },
    { codigoTOM: 2221, codigoIBGE: 2516003, nomeTOM: 'SOLÂNEA', nomeIBGE: 'Solânea' },
    { codigoTOM: 2223, codigoIBGE: 2516102, nomeTOM: 'SOLEDADE', nomeIBGE: 'Soledade' },
    { codigoTOM: 2225, codigoIBGE: 2516201, nomeTOM: 'SOUSA', nomeIBGE: 'Sousa' },
    { codigoTOM: 2227, codigoIBGE: 2516300, nomeTOM: 'SUMÉ', nomeIBGE: 'Sumé' },
    { codigoTOM: 2229, codigoIBGE: 2516409, nomeTOM: 'TACIMA', nomeIBGE: 'Tacima' },
    { codigoTOM: 2231, codigoIBGE: 2516508, nomeTOM: 'TAPEROÁ', nomeIBGE: 'Taperoá' },
    { codigoTOM: 2233, codigoIBGE: 2516607, nomeTOM: 'TAVARES', nomeIBGE: 'Tavares' },
    { codigoTOM: 2235, codigoIBGE: 2516706, nomeTOM: 'TEIXEIRA', nomeIBGE: 'Teixeira' },
    { codigoTOM: 2237, codigoIBGE: 2516805, nomeTOM: 'TRIUNFO', nomeIBGE: 'Triunfo' },
    { codigoTOM: 2239, codigoIBGE: 2516904, nomeTOM: 'UIRAÚNA', nomeIBGE: 'Uiraúna' },
    { codigoTOM: 2241, codigoIBGE: 2517001, nomeTOM: 'UMBUZEIRO', nomeIBGE: 'Umbuzeiro' },
    { codigoTOM: 2243, codigoIBGE: 2517100, nomeTOM: 'VÁRZEA', nomeIBGE: 'Várzea' },
  ],
  [Estados.PE]: [
    { codigoTOM: 544, codigoIBGE: 2601052, nomeTOM: 'ARAÇOIABA', nomeIBGE: 'Araçoiaba' },
    { codigoTOM: 546, codigoIBGE: 2604155, nomeTOM: 'CASINHAS', nomeIBGE: 'Casinhas' },
    { codigoTOM: 548, codigoIBGE: 2607950, nomeTOM: 'JAQUEIRA', nomeIBGE: 'Jaqueira' },
    { codigoTOM: 550, codigoIBGE: 2608057, nomeTOM: 'JATOBÁ', nomeIBGE: 'Jatobá' },
    { codigoTOM: 552, codigoIBGE: 2608750, nomeTOM: 'LAGOA GRANDE', nomeIBGE: 'Lagoa Grande' },
    { codigoTOM: 554, codigoIBGE: 2609154, nomeTOM: 'MANARI', nomeIBGE: 'Manari' },
    { codigoTOM: 556, codigoIBGE: 2612554, nomeTOM: 'SANTA FILOMENA', nomeIBGE: 'Santa Filomena' },
    { codigoTOM: 558, codigoIBGE: 2614857, nomeTOM: 'TAMANDARÉ', nomeIBGE: 'Tamandaré' },
    { codigoTOM: 2289, codigoIBGE: 2608453, nomeTOM: 'LAGOA DO CARRO', nomeIBGE: 'Lagoa do Carro' },
    { codigoTOM: 2291, codigoIBGE: 2616183, nomeTOM: 'VERTENTE DO LÉRIO', nomeIBGE: 'Vertente do Lério' },
    { codigoTOM: 2293, codigoIBGE: 2616506, nomeTOM: 'XEXÉU', nomeIBGE: 'Xexéu' },
    { codigoTOM: 2295, codigoIBGE: 2608255, nomeTOM: 'JUCATI', nomeIBGE: 'Jucati' },
    { codigoTOM: 2297, codigoIBGE: 2612455, nomeTOM: 'SANTA CRUZ', nomeIBGE: 'Santa Cruz' },
    { codigoTOM: 2299, codigoIBGE: 2605152, nomeTOM: 'DORMENTES', nomeIBGE: 'Dormentes' },
    { codigoTOM: 2301, codigoIBGE: 2600104, nomeTOM: 'AFOGADOS DA INGAZEIRA', nomeIBGE: 'Afogados da Ingazeira' },
    { codigoTOM: 2303, codigoIBGE: 2600203, nomeTOM: 'AFRÂNIO', nomeIBGE: 'Afrânio' },
    { codigoTOM: 2305, codigoIBGE: 2600302, nomeTOM: 'AGRESTINA', nomeIBGE: 'Agrestina' },
    { codigoTOM: 2307, codigoIBGE: 2600401, nomeTOM: 'ÁGUA PRETA', nomeIBGE: 'Água Preta' },
    { codigoTOM: 2309, codigoIBGE: 2600500, nomeTOM: 'ÁGUAS BELAS', nomeIBGE: 'Águas Belas' },
    { codigoTOM: 2311, codigoIBGE: 2600609, nomeTOM: 'ALAGOINHA', nomeIBGE: 'Alagoinha' },
    { codigoTOM: 2313, codigoIBGE: 2600708, nomeTOM: 'ALIANÇA', nomeIBGE: 'Aliança' },
    { codigoTOM: 2315, codigoIBGE: 2600807, nomeTOM: 'ALTINHO', nomeIBGE: 'Altinho' },
    { codigoTOM: 2317, codigoIBGE: 2600906, nomeTOM: 'AMARAJI', nomeIBGE: 'Amaraji' },
    { codigoTOM: 2319, codigoIBGE: 2601003, nomeTOM: 'ANGELIM', nomeIBGE: 'Angelim' },
    { codigoTOM: 2321, codigoIBGE: 2601102, nomeTOM: 'ARARIPINA', nomeIBGE: 'Araripina' },
    { codigoTOM: 2323, codigoIBGE: 2601201, nomeTOM: 'ARCOVERDE', nomeIBGE: 'Arcoverde' },
    { codigoTOM: 2325, codigoIBGE: 2601300, nomeTOM: 'BARRA DE GUABIRABA', nomeIBGE: 'Barra de Guabiraba' },
    { codigoTOM: 2327, codigoIBGE: 2601409, nomeTOM: 'BARREIROS', nomeIBGE: 'Barreiros' },
    { codigoTOM: 2329, codigoIBGE: 2601508, nomeTOM: 'BELÉM DE MARIA', nomeIBGE: 'Belém de Maria' },
    { codigoTOM: 2331, codigoIBGE: 2601607, nomeTOM: 'BELÉM DE SÃO FRANCISCO', nomeIBGE: 'Belém do São Francisco' },
    { codigoTOM: 2333, codigoIBGE: 2601706, nomeTOM: 'BELO JARDIM', nomeIBGE: 'Belo Jardim' },
    { codigoTOM: 2335, codigoIBGE: 2601805, nomeTOM: 'BETÂNIA', nomeIBGE: 'Betânia' },
    { codigoTOM: 2337, codigoIBGE: 2601904, nomeTOM: 'BEZERROS', nomeIBGE: 'Bezerros' },
    { codigoTOM: 2339, codigoIBGE: 2602001, nomeTOM: 'BODOCÓ', nomeIBGE: 'Bodocó' },
    { codigoTOM: 2341, codigoIBGE: 2602100, nomeTOM: 'BOM CONSELHO', nomeIBGE: 'Bom Conselho' },
    { codigoTOM: 2343, codigoIBGE: 2602209, nomeTOM: 'BOM JARDIM', nomeIBGE: 'Bom Jardim' },
    { codigoTOM: 2345, codigoIBGE: 2602308, nomeTOM: 'BONITO', nomeIBGE: 'Bonito' },
    { codigoTOM: 2347, codigoIBGE: 2602407, nomeTOM: 'BREJÃO', nomeIBGE: 'Brejão' },
    { codigoTOM: 2349, codigoIBGE: 2602506, nomeTOM: 'BREJINHO', nomeIBGE: 'Brejinho' },
    { codigoTOM: 2351, codigoIBGE: 2602605, nomeTOM: 'BREJO DA MADRE DE DEUS', nomeIBGE: 'Brejo da Madre de Deus' },
    { codigoTOM: 2353, codigoIBGE: 2602704, nomeTOM: 'BUENOS AIRES', nomeIBGE: 'Buenos Aires' },
    { codigoTOM: 2355, codigoIBGE: 2602803, nomeTOM: 'BUÍQUE', nomeIBGE: 'Buíque' },
    { codigoTOM: 2357, codigoIBGE: 2602902, nomeTOM: 'CABO DE SANTO AGOSTINHO', nomeIBGE: 'Cabo de Santo Agostinho' },
    { codigoTOM: 2359, codigoIBGE: 2603009, nomeTOM: 'CABROBÓ', nomeIBGE: 'Cabrobó' },
    { codigoTOM: 2361, codigoIBGE: 2603108, nomeTOM: 'CACHOEIRINHA', nomeIBGE: 'Cachoeirinha' },
    { codigoTOM: 2363, codigoIBGE: 2603207, nomeTOM: 'CAETÉS', nomeIBGE: 'Caetés' },
    { codigoTOM: 2365, codigoIBGE: 2603306, nomeTOM: 'CALÇADO', nomeIBGE: 'Calçado' },
    { codigoTOM: 2367, codigoIBGE: 2603405, nomeTOM: 'CALUMBI', nomeIBGE: 'Calumbi' },
    { codigoTOM: 2369, codigoIBGE: 2603504, nomeTOM: 'CAMOCIM DE SÃO FÉLIX', nomeIBGE: 'Camocim de São Félix' },
    { codigoTOM: 2371, codigoIBGE: 2603603, nomeTOM: 'CAMUTANGA', nomeIBGE: 'Camutanga' },
    { codigoTOM: 2373, codigoIBGE: 2603702, nomeTOM: 'CANHOTINHO', nomeIBGE: 'Canhotinho' },
    { codigoTOM: 2375, codigoIBGE: 2603801, nomeTOM: 'CAPOEIRAS', nomeIBGE: 'Capoeiras' },
    { codigoTOM: 2377, codigoIBGE: 2603900, nomeTOM: 'CARNAÍBA', nomeIBGE: 'Carnaíba' },
    { codigoTOM: 2379, codigoIBGE: 2604007, nomeTOM: 'CARPINA', nomeIBGE: 'Carpina' },
    { codigoTOM: 2381, codigoIBGE: 2604106, nomeTOM: 'CARUARU', nomeIBGE: 'Caruaru' },
    { codigoTOM: 2383, codigoIBGE: 2604205, nomeTOM: 'CATENDE', nomeIBGE: 'Catende' },
    { codigoTOM: 2385, codigoIBGE: 2604304, nomeTOM: 'CEDRO', nomeIBGE: 'Cedro' },
    { codigoTOM: 2387, codigoIBGE: 2604403, nomeTOM: 'CHÃ DE ALEGRIA', nomeIBGE: 'Chã de Alegria' },
    { codigoTOM: 2389, codigoIBGE: 2604502, nomeTOM: 'CHÃ GRANDE', nomeIBGE: 'Chã Grande' },
    { codigoTOM: 2391, codigoIBGE: 2604601, nomeTOM: 'CONDADO', nomeIBGE: 'Condado' },
    { codigoTOM: 2393, codigoIBGE: 2604700, nomeTOM: 'CORRENTES', nomeIBGE: 'Correntes' },
    { codigoTOM: 2395, codigoIBGE: 2604809, nomeTOM: 'CORTÊS', nomeIBGE: 'Cortês' },
    { codigoTOM: 2397, codigoIBGE: 2604908, nomeTOM: 'CUMARU', nomeIBGE: 'Cumaru' },
    { codigoTOM: 2399, codigoIBGE: 2605004, nomeTOM: 'CUPIRA', nomeIBGE: 'Cupira' },
    { codigoTOM: 2401, codigoIBGE: 2605103, nomeTOM: 'CUSTÓDIA', nomeIBGE: 'Custódia' },
    { codigoTOM: 2403, codigoIBGE: 2605202, nomeTOM: 'ESCADA', nomeIBGE: 'Escada' },
    { codigoTOM: 2405, codigoIBGE: 2605301, nomeTOM: 'EXU', nomeIBGE: 'Exu' },
    { codigoTOM: 2407, codigoIBGE: 2605400, nomeTOM: 'FEIRA NOVA', nomeIBGE: 'Feira Nova' },
    { codigoTOM: 2409, codigoIBGE: 2605509, nomeTOM: 'FERREIROS', nomeIBGE: 'Ferreiros' },
    { codigoTOM: 2411, codigoIBGE: 2605608, nomeTOM: 'FLORES', nomeIBGE: 'Flores' },
    { codigoTOM: 2413, codigoIBGE: 2605707, nomeTOM: 'FLORESTA', nomeIBGE: 'Floresta' },
    { codigoTOM: 2415, codigoIBGE: 2605806, nomeTOM: 'FREI MIGUELINHO', nomeIBGE: 'Frei Miguelinho' },
    { codigoTOM: 2417, codigoIBGE: 2605905, nomeTOM: 'GAMELEIRA', nomeIBGE: 'Gameleira' },
    { codigoTOM: 2419, codigoIBGE: 2606002, nomeTOM: 'GARANHUNS', nomeIBGE: 'Garanhuns' },
    { codigoTOM: 2421, codigoIBGE: 2606101, nomeTOM: 'GLÓRIA DO GOITÁ', nomeIBGE: 'Glória do Goitá' },
    { codigoTOM: 2423, codigoIBGE: 2606200, nomeTOM: 'GOIANA', nomeIBGE: 'Goiana' },
    { codigoTOM: 2425, codigoIBGE: 2606309, nomeTOM: 'GRANITO', nomeIBGE: 'Granito' },
    { codigoTOM: 2427, codigoIBGE: 2606408, nomeTOM: 'GRAVATÁ', nomeIBGE: 'Gravatá' },
    { codigoTOM: 2429, codigoIBGE: 2606507, nomeTOM: 'IATI', nomeIBGE: 'Iati' },
    { codigoTOM: 2431, codigoIBGE: 2606606, nomeTOM: 'IBIMIRIM', nomeIBGE: 'Ibimirim' },
    { codigoTOM: 2433, codigoIBGE: 2606705, nomeTOM: 'IBIRAJUBA', nomeIBGE: 'Ibirajuba' },
    { codigoTOM: 2435, codigoIBGE: 2606804, nomeTOM: 'IGARASSU', nomeIBGE: 'Igarassu' },
    { codigoTOM: 2437, codigoIBGE: 2606903, nomeTOM: 'IGUARACI', nomeIBGE: 'Iguaracy' },
    { codigoTOM: 2439, codigoIBGE: 2607000, nomeTOM: 'INAJÁ', nomeIBGE: 'Inajá' },
    { codigoTOM: 2441, codigoIBGE: 2607109, nomeTOM: 'INGAZEIRA', nomeIBGE: 'Ingazeira' },
    { codigoTOM: 2443, codigoIBGE: 2607208, nomeTOM: 'IPOJUCA', nomeIBGE: 'Ipojuca' },
    { codigoTOM: 2445, codigoIBGE: 2607307, nomeTOM: 'IPUBI', nomeIBGE: 'Ipubi' },
    { codigoTOM: 2447, codigoIBGE: 2607406, nomeTOM: 'ITACURUBA', nomeIBGE: 'Itacuruba' },
    { codigoTOM: 2449, codigoIBGE: 2607505, nomeTOM: 'ITAÍBA', nomeIBGE: 'Itaíba' },
    { codigoTOM: 2451, codigoIBGE: 2607604, nomeTOM: 'ILHA DE ITAMARACÁ', nomeIBGE: 'Ilha de Itamaracá' },
    { codigoTOM: 2453, codigoIBGE: 2607703, nomeTOM: 'ITAPETIM', nomeIBGE: 'Itapetim' },
    { codigoTOM: 2455, codigoIBGE: 2607802, nomeTOM: 'ITAQUITINGA', nomeIBGE: 'Itaquitinga' },
    { codigoTOM: 2457, codigoIBGE: 2607901, nomeTOM: 'JABOATÃO DOS GUARARAPES', nomeIBGE: 'Jaboatão dos Guararapes' },
    { codigoTOM: 2459, codigoIBGE: 2608008, nomeTOM: 'JATAÚBA', nomeIBGE: 'Jataúba' },
    { codigoTOM: 2461, codigoIBGE: 2608107, nomeTOM: 'JOÃO ALFREDO', nomeIBGE: 'João Alfredo' },
    { codigoTOM: 2463, codigoIBGE: 2608206, nomeTOM: 'JOAQUIM NABUCO', nomeIBGE: 'Joaquim Nabuco' },
    { codigoTOM: 2465, codigoIBGE: 2608305, nomeTOM: 'JUPI', nomeIBGE: 'Jupi' },
    { codigoTOM: 2467, codigoIBGE: 2608404, nomeTOM: 'JUREMA', nomeIBGE: 'Jurema' },
    { codigoTOM: 2469, codigoIBGE: 2608503, nomeTOM: 'LAGOA DO ITAENGA', nomeIBGE: 'Lagoa de Itaenga' },
    { codigoTOM: 2471, codigoIBGE: 2608602, nomeTOM: 'LAGOA DO OURO', nomeIBGE: 'Lagoa do Ouro' },
    { codigoTOM: 2473, codigoIBGE: 2608701, nomeTOM: 'LAGOA DOS GATOS', nomeIBGE: 'Lagoa dos Gatos' },
    { codigoTOM: 2475, codigoIBGE: 2608800, nomeTOM: 'LAJEDO', nomeIBGE: 'Lajedo' },
    { codigoTOM: 2477, codigoIBGE: 2608909, nomeTOM: 'LIMOEIRO', nomeIBGE: 'Limoeiro' },
    { codigoTOM: 2479, codigoIBGE: 2609006, nomeTOM: 'MACAPARANA', nomeIBGE: 'Macaparana' },
    { codigoTOM: 2481, codigoIBGE: 2609105, nomeTOM: 'MACHADOS', nomeIBGE: 'Machados' },
    { codigoTOM: 2483, codigoIBGE: 2609204, nomeTOM: 'MARAIAL', nomeIBGE: 'Maraial' },
    { codigoTOM: 2485, codigoIBGE: 2609303, nomeTOM: 'MIRANDIBA', nomeIBGE: 'Mirandiba' },
    { codigoTOM: 2487, codigoIBGE: 2609402, nomeTOM: 'MORENO', nomeIBGE: 'Moreno' },
    { codigoTOM: 2489, codigoIBGE: 2609501, nomeTOM: 'NAZARÉ DA MATA', nomeIBGE: 'Nazaré da Mata' },
    { codigoTOM: 2491, codigoIBGE: 2609600, nomeTOM: 'OLINDA', nomeIBGE: 'Olinda' },
    { codigoTOM: 2493, codigoIBGE: 2609709, nomeTOM: 'OROBÓ', nomeIBGE: 'Orobó' },
    { codigoTOM: 2495, codigoIBGE: 2609808, nomeTOM: 'OROCÓ', nomeIBGE: 'Orocó' },
    { codigoTOM: 2497, codigoIBGE: 2609907, nomeTOM: 'OURICURI', nomeIBGE: 'Ouricuri' },
    { codigoTOM: 2499, codigoIBGE: 2610004, nomeTOM: 'PALMARES', nomeIBGE: 'Palmares' },
    { codigoTOM: 2501, codigoIBGE: 2610103, nomeTOM: 'PALMEIRINA', nomeIBGE: 'Palmeirina' },
    { codigoTOM: 2503, codigoIBGE: 2610202, nomeTOM: 'PANELAS', nomeIBGE: 'Panelas' },
    { codigoTOM: 2505, codigoIBGE: 2610301, nomeTOM: 'PARANATAMA', nomeIBGE: 'Paranatama' },
    { codigoTOM: 2507, codigoIBGE: 2610400, nomeTOM: 'PARNAMIRIM', nomeIBGE: 'Parnamirim' },
    { codigoTOM: 2509, codigoIBGE: 2610509, nomeTOM: 'PASSIRA', nomeIBGE: 'Passira' },
    { codigoTOM: 2511, codigoIBGE: 2610608, nomeTOM: 'PAUDALHO', nomeIBGE: 'Paudalho' },
    { codigoTOM: 2513, codigoIBGE: 2610707, nomeTOM: 'PAULISTA', nomeIBGE: 'Paulista' },
    { codigoTOM: 2515, codigoIBGE: 2610806, nomeTOM: 'PEDRA', nomeIBGE: 'Pedra' },
    { codigoTOM: 2517, codigoIBGE: 2610905, nomeTOM: 'PESQUEIRA', nomeIBGE: 'Pesqueira' },
    { codigoTOM: 2519, codigoIBGE: 2611002, nomeTOM: 'PETROLÂNDIA', nomeIBGE: 'Petrolândia' },
    { codigoTOM: 2521, codigoIBGE: 2611101, nomeTOM: 'PETROLINA', nomeIBGE: 'Petrolina' },
    { codigoTOM: 2523, codigoIBGE: 2611200, nomeTOM: 'POÇÃO', nomeIBGE: 'Poção' },
    { codigoTOM: 2525, codigoIBGE: 2611309, nomeTOM: 'POMBOS', nomeIBGE: 'Pombos' },
    { codigoTOM: 2527, codigoIBGE: 2611408, nomeTOM: 'PRIMAVERA', nomeIBGE: 'Primavera' },
    { codigoTOM: 2529, codigoIBGE: 2611507, nomeTOM: 'QUIPAPÁ', nomeIBGE: 'Quipapá' },
    { codigoTOM: 2531, codigoIBGE: 2611606, nomeTOM: 'RECIFE', nomeIBGE: 'Recife' },
    { codigoTOM: 2533, codigoIBGE: 2611705, nomeTOM: 'RIACHO DAS ALMAS', nomeIBGE: 'Riacho das Almas' },
    { codigoTOM: 2535, codigoIBGE: 2611804, nomeTOM: 'RIBEIRÃO', nomeIBGE: 'Ribeirão' },
    { codigoTOM: 2537, codigoIBGE: 2611903, nomeTOM: 'RIO FORMOSO', nomeIBGE: 'Rio Formoso' },
    { codigoTOM: 2539, codigoIBGE: 2612000, nomeTOM: 'SAIRÉ', nomeIBGE: 'Sairé' },
    { codigoTOM: 2541, codigoIBGE: 2612109, nomeTOM: 'SALGADINHO', nomeIBGE: 'Salgadinho' },
    { codigoTOM: 2543, codigoIBGE: 2612208, nomeTOM: 'SALGUEIRO', nomeIBGE: 'Salgueiro' },
    { codigoTOM: 2545, codigoIBGE: 2612307, nomeTOM: 'SALOÁ', nomeIBGE: 'Saloá' },
    { codigoTOM: 2547, codigoIBGE: 2612406, nomeTOM: 'SANHARÓ', nomeIBGE: 'Sanharó' },
    { codigoTOM: 2549, codigoIBGE: 2612505, nomeTOM: 'SANTA CRUZ DO CAPIBARIBE', nomeIBGE: 'Santa Cruz do Capibaribe' },
    { codigoTOM: 2551, codigoIBGE: 2612604, nomeTOM: 'SANTA MARIA DA BOA VISTA', nomeIBGE: 'Santa Maria da Boa Vista' },
    { codigoTOM: 2553, codigoIBGE: 2612703, nomeTOM: 'SANTA MARIA DO CAMBUCÁ', nomeIBGE: 'Santa Maria do Cambucá' },
    { codigoTOM: 2555, codigoIBGE: 2612802, nomeTOM: 'SANTA TEREZINHA', nomeIBGE: 'Santa Terezinha' },
    { codigoTOM: 2557, codigoIBGE: 2612901, nomeTOM: 'SÃO BENEDITO DO SUL', nomeIBGE: 'São Benedito do Sul' },
    { codigoTOM: 2559, codigoIBGE: 2613008, nomeTOM: 'SÃO BENTO DO UNA', nomeIBGE: 'São Bento do Una' },
    { codigoTOM: 2561, codigoIBGE: 2613107, nomeTOM: 'SÃO CAITANO', nomeIBGE: 'São Caitano' },
    { codigoTOM: 2563, codigoIBGE: 2613206, nomeTOM: 'SÃO JOÃO', nomeIBGE: 'São João' },
    { codigoTOM: 2565, codigoIBGE: 2613305, nomeTOM: 'SÃO JOAQUIM DO MONTE', nomeIBGE: 'São Joaquim do Monte' },
    { codigoTOM: 2567, codigoIBGE: 2613404, nomeTOM: 'SÃO JOSÉ DA COROA GRANDE', nomeIBGE: 'São José da Coroa Grande' },
    { codigoTOM: 2569, codigoIBGE: 2613503, nomeTOM: 'SÃO JOSÉ DO BELMONTE', nomeIBGE: 'São José do Belmonte' },
    { codigoTOM: 2571, codigoIBGE: 2613602, nomeTOM: 'SÃO JOSÉ DO EGITO', nomeIBGE: 'São José do Egito' },
    { codigoTOM: 2573, codigoIBGE: 2613701, nomeTOM: 'SÃO LOURENÇO DA MATA', nomeIBGE: 'São Lourenço da Mata' },
    { codigoTOM: 2575, codigoIBGE: 2613800, nomeTOM: 'SÃO VICENTE FERRER', nomeIBGE: 'São Vicente Férrer' },
    { codigoTOM: 2577, codigoIBGE: 2613909, nomeTOM: 'SERRA TALHADA', nomeIBGE: 'Serra Talhada' },
    { codigoTOM: 2579, codigoIBGE: 2614006, nomeTOM: 'SERRITA', nomeIBGE: 'Serrita' },
    { codigoTOM: 2581, codigoIBGE: 2614105, nomeTOM: 'SERTÂNIA', nomeIBGE: 'Sertânia' },
    { codigoTOM: 2583, codigoIBGE: 2614204, nomeTOM: 'SIRINHAÉM', nomeIBGE: 'Sirinhaém' },
    { codigoTOM: 2585, codigoIBGE: 2614303, nomeTOM: 'MOREILÂNDIA', nomeIBGE: 'Moreilândia' },
    { codigoTOM: 2587, codigoIBGE: 2614402, nomeTOM: 'SOLIDÃO', nomeIBGE: 'Solidão' },
    { codigoTOM: 2589, codigoIBGE: 2614501, nomeTOM: 'SURUBIM', nomeIBGE: 'Surubim' },
    { codigoTOM: 2591, codigoIBGE: 2614600, nomeTOM: 'TABIRA', nomeIBGE: 'Tabira' },
    { codigoTOM: 2593, codigoIBGE: 2614709, nomeTOM: 'TACAIMBÓ', nomeIBGE: 'Tacaimbó' },
    { codigoTOM: 2595, codigoIBGE: 2614808, nomeTOM: 'TACARATU', nomeIBGE: 'Tacaratu' },
    { codigoTOM: 2597, codigoIBGE: 2607653, nomeTOM: 'ITAMBÉ', nomeIBGE: 'Itambé' },
    { codigoTOM: 2599, codigoIBGE: 2615003, nomeTOM: 'TAQUARITINGA DO NORTE', nomeIBGE: 'Taquaritinga do Norte' },
    { codigoTOM: 2601, codigoIBGE: 2615102, nomeTOM: 'TEREZINHA', nomeIBGE: 'Terezinha' },
    { codigoTOM: 2603, codigoIBGE: 2615201, nomeTOM: 'TERRA NOVA', nomeIBGE: 'Terra Nova' },
    { codigoTOM: 2605, codigoIBGE: 2615300, nomeTOM: 'TIMBAÚBA', nomeIBGE: 'Timbaúba' },
    { codigoTOM: 2607, codigoIBGE: 2615409, nomeTOM: 'TORITAMA', nomeIBGE: 'Toritama' },
    { codigoTOM: 2609, codigoIBGE: 2615508, nomeTOM: 'TRACUNHAÉM', nomeIBGE: 'Tracunhaém' },
    { codigoTOM: 2611, codigoIBGE: 2615607, nomeTOM: 'TRINDADE', nomeIBGE: 'Trindade' },
    { codigoTOM: 2613, codigoIBGE: 2615706, nomeTOM: 'TRIUNFO', nomeIBGE: 'Triunfo' },
    { codigoTOM: 2615, codigoIBGE: 2615805, nomeTOM: 'TUPANATINGA', nomeIBGE: 'Tupanatinga' },
    { codigoTOM: 2617, codigoIBGE: 2615904, nomeTOM: 'TUPARETAMA', nomeIBGE: 'Tuparetama' },
    { codigoTOM: 2619, codigoIBGE: 2616001, nomeTOM: 'VENTUROSA', nomeIBGE: 'Venturosa' },
    { codigoTOM: 2621, codigoIBGE: 2616100, nomeTOM: 'VERDEJANTE', nomeIBGE: 'Verdejante' },
    { codigoTOM: 2623, codigoIBGE: 2616209, nomeTOM: 'VERTENTES', nomeIBGE: 'Vertentes' },
    { codigoTOM: 2625, codigoIBGE: 2616308, nomeTOM: 'VICÊNCIA', nomeIBGE: 'Vicência' },
    { codigoTOM: 2627, codigoIBGE: 2616407, nomeTOM: 'VITÓRIA DE SANTO ANTÃO', nomeIBGE: 'Vitória de Santo Antão' },
    { codigoTOM: 2629, codigoIBGE: 2603454, nomeTOM: 'CAMARAGIBE', nomeIBGE: 'Camaragibe' },
    { codigoTOM: 2631, codigoIBGE: 2600054, nomeTOM: 'ABREU E LIMA', nomeIBGE: 'Abreu e Lima' },
    { codigoTOM: 2633, codigoIBGE: 2607752, nomeTOM: 'ITAPISSUMA', nomeIBGE: 'Itapissuma' },
    { codigoTOM: 2635, codigoIBGE: 2603926, nomeTOM: 'CARNAUBEIRA DA PENHA', nomeIBGE: 'Carnaubeira da Penha' },
    { codigoTOM: 2637, codigoIBGE: 2611533, nomeTOM: 'QUIXABA', nomeIBGE: 'Quixaba' },
    {
      codigoTOM: 2639,
      codigoIBGE: 2612471,
      nomeTOM: 'SANTA CRUZ DA BAIXA VERDE',
      nomeIBGE: 'Santa Cruz da Baixa Verde',
    },
    { codigoTOM: 3001, codigoIBGE: 2605459, nomeTOM: 'FERNANDO DE NORONHA', nomeIBGE: 'Fernando de Noronha' },
  ],
  [Estados.AL]: [
    { codigoTOM: 560, codigoIBGE: 2701357, nomeTOM: 'CAMPESTRE', nomeIBGE: 'Campestre' },
    { codigoTOM: 562, codigoIBGE: 2703759, nomeTOM: 'JEQUIÁ DA PRAIA', nomeIBGE: 'Jequiá da Praia' },
    { codigoTOM: 971, codigoIBGE: 2709152, nomeTOM: 'TEOTÔNIO VILELA', nomeIBGE: 'Teotônio Vilela' },
    { codigoTOM: 2641, codigoIBGE: 2706448, nomeTOM: 'PARIPUEIRA', nomeIBGE: 'Paripueira' },
    { codigoTOM: 2643, codigoIBGE: 2702553, nomeTOM: 'ESTRELA DE ALAGOAS', nomeIBGE: 'Estrela de Alagoas' },
    { codigoTOM: 2645, codigoIBGE: 2706422, nomeTOM: 'PARICONHA', nomeIBGE: 'Pariconha' },
    { codigoTOM: 2701, codigoIBGE: 2700102, nomeTOM: 'ÁGUA BRANCA', nomeIBGE: 'Água Branca' },
    { codigoTOM: 2703, codigoIBGE: 2700201, nomeTOM: 'ANADIA', nomeIBGE: 'Anadia' },
    { codigoTOM: 2705, codigoIBGE: 2700300, nomeTOM: 'ARAPIRACA', nomeIBGE: 'Arapiraca' },
    { codigoTOM: 2707, codigoIBGE: 2700409, nomeTOM: 'ATALAIA', nomeIBGE: 'Atalaia' },
    { codigoTOM: 2709, codigoIBGE: 2700508, nomeTOM: 'BARRA DE SANTO ANTÔNIO', nomeIBGE: 'Barra de Santo Antônio' },
    { codigoTOM: 2711, codigoIBGE: 2700607, nomeTOM: 'BARRA DE SÃO MIGUEL', nomeIBGE: 'Barra de São Miguel' },
    { codigoTOM: 2713, codigoIBGE: 2700706, nomeTOM: 'BATALHA', nomeIBGE: 'Batalha' },
    { codigoTOM: 2715, codigoIBGE: 2700805, nomeTOM: 'BELÉM', nomeIBGE: 'Belém' },
    { codigoTOM: 2717, codigoIBGE: 2700904, nomeTOM: 'BELO MONTE', nomeIBGE: 'Belo Monte' },
    { codigoTOM: 2719, codigoIBGE: 2701001, nomeTOM: 'BOCA DA MATA', nomeIBGE: 'Boca da Mata' },
    { codigoTOM: 2721, codigoIBGE: 2701100, nomeTOM: 'BRANQUINHA', nomeIBGE: 'Branquinha' },
    { codigoTOM: 2723, codigoIBGE: 2701209, nomeTOM: 'CACIMBINHAS', nomeIBGE: 'Cacimbinhas' },
    { codigoTOM: 2725, codigoIBGE: 2701308, nomeTOM: 'CAJUEIRO', nomeIBGE: 'Cajueiro' },
    { codigoTOM: 2727, codigoIBGE: 2701407, nomeTOM: 'CAMPO ALEGRE', nomeIBGE: 'Campo Alegre' },
    { codigoTOM: 2729, codigoIBGE: 2701506, nomeTOM: 'CAMPO GRANDE', nomeIBGE: 'Campo Grande' },
    { codigoTOM: 2731, codigoIBGE: 2701605, nomeTOM: 'CANAPI', nomeIBGE: 'Canapi' },
    { codigoTOM: 2733, codigoIBGE: 2701704, nomeTOM: 'CAPELA', nomeIBGE: 'Capela' },
    { codigoTOM: 2735, codigoIBGE: 2701803, nomeTOM: 'CARNEIROS', nomeIBGE: 'Carneiros' },
    { codigoTOM: 2737, codigoIBGE: 2701902, nomeTOM: 'CHÃ PRETA', nomeIBGE: 'Chã Preta' },
    { codigoTOM: 2739, codigoIBGE: 2702009, nomeTOM: 'COITÉ DO NÓIA', nomeIBGE: 'Coité do Nóia' },
    { codigoTOM: 2741, codigoIBGE: 2702108, nomeTOM: 'COLÔNIA LEOPOLDINA', nomeIBGE: 'Colônia Leopoldina' },
    { codigoTOM: 2743, codigoIBGE: 2702207, nomeTOM: 'COQUEIRO SECO', nomeIBGE: 'Coqueiro Seco' },
    { codigoTOM: 2745, codigoIBGE: 2702306, nomeTOM: 'CORURIPE', nomeIBGE: 'Coruripe' },
    { codigoTOM: 2747, codigoIBGE: 2702405, nomeTOM: 'DELMIRO GOUVEIA', nomeIBGE: 'Delmiro Gouveia' },
    { codigoTOM: 2749, codigoIBGE: 2702504, nomeTOM: 'DOIS RIACHOS', nomeIBGE: 'Dois Riachos' },
    { codigoTOM: 2751, codigoIBGE: 2702603, nomeTOM: 'FEIRA GRANDE', nomeIBGE: 'Feira Grande' },
    { codigoTOM: 2753, codigoIBGE: 2702702, nomeTOM: 'FELIZ DESERTO', nomeIBGE: 'Feliz Deserto' },
    { codigoTOM: 2755, codigoIBGE: 2702801, nomeTOM: 'FLEXEIRAS', nomeIBGE: 'Flexeiras' },
    { codigoTOM: 2757, codigoIBGE: 2702900, nomeTOM: 'GIRAU DO PONCIANO', nomeIBGE: 'Girau do Ponciano' },
    { codigoTOM: 2759, codigoIBGE: 2703007, nomeTOM: 'IBATEGUARA', nomeIBGE: 'Ibateguara' },
    { codigoTOM: 2761, codigoIBGE: 2703106, nomeTOM: 'IGACI', nomeIBGE: 'Igaci' },
    { codigoTOM: 2763, codigoIBGE: 2703205, nomeTOM: 'IGREJA NOVA', nomeIBGE: 'Igreja Nova' },
    { codigoTOM: 2765, codigoIBGE: 2703304, nomeTOM: 'INHAPI', nomeIBGE: 'Inhapi' },
    { codigoTOM: 2767, codigoIBGE: 2703403, nomeTOM: 'JACARÉ DOS HOMENS', nomeIBGE: 'Jacaré dos Homens' },
    { codigoTOM: 2769, codigoIBGE: 2703502, nomeTOM: 'JACUÍPE', nomeIBGE: 'Jacuípe' },
    { codigoTOM: 2771, codigoIBGE: 2703601, nomeTOM: 'JAPARATINGA', nomeIBGE: 'Japaratinga' },
    { codigoTOM: 2773, codigoIBGE: 2703700, nomeTOM: 'JARAMATAIA', nomeIBGE: 'Jaramataia' },
    { codigoTOM: 2775, codigoIBGE: 2703809, nomeTOM: 'JOAQUIM GOMES', nomeIBGE: 'Joaquim Gomes' },
    { codigoTOM: 2777, codigoIBGE: 2703908, nomeTOM: 'JUNDIÁ', nomeIBGE: 'Jundiá' },
    { codigoTOM: 2779, codigoIBGE: 2704005, nomeTOM: 'JUNQUEIRO', nomeIBGE: 'Junqueiro' },
    { codigoTOM: 2781, codigoIBGE: 2704104, nomeTOM: 'LAGOA DA CANOA', nomeIBGE: 'Lagoa da Canoa' },
    { codigoTOM: 2783, codigoIBGE: 2704203, nomeTOM: 'LIMOEIRO DE ANADIA', nomeIBGE: 'Limoeiro de Anadia' },
    { codigoTOM: 2785, codigoIBGE: 2704302, nomeTOM: 'MACEIÓ', nomeIBGE: 'Maceió' },
    { codigoTOM: 2787, codigoIBGE: 2704401, nomeTOM: 'MAJOR ISIDORO', nomeIBGE: 'Major Isidoro' },
    { codigoTOM: 2789, codigoIBGE: 2704500, nomeTOM: 'MARAGOGI', nomeIBGE: 'Maragogi' },
    { codigoTOM: 2791, codigoIBGE: 2704609, nomeTOM: 'MARAVILHA', nomeIBGE: 'Maravilha' },
    { codigoTOM: 2793, codigoIBGE: 2704708, nomeTOM: 'MARECHAL DEODORO', nomeIBGE: 'Marechal Deodoro' },
    { codigoTOM: 2795, codigoIBGE: 2704807, nomeTOM: 'MARIBONDO', nomeIBGE: 'Maribondo' },
    { codigoTOM: 2797, codigoIBGE: 2704906, nomeTOM: 'MAR VERMELHO', nomeIBGE: 'Mar Vermelho' },
    { codigoTOM: 2799, codigoIBGE: 2705002, nomeTOM: 'MATA GRANDE', nomeIBGE: 'Mata Grande' },
    { codigoTOM: 2801, codigoIBGE: 2705101, nomeTOM: 'MATRIZ DE CAMARAGIBE', nomeIBGE: 'Matriz de Camaragibe' },
    { codigoTOM: 2803, codigoIBGE: 2705200, nomeTOM: 'MESSIAS', nomeIBGE: 'Messias' },
    { codigoTOM: 2805, codigoIBGE: 2705309, nomeTOM: 'MINADOR DO NEGRÃO', nomeIBGE: 'Minador do Negrão' },
    { codigoTOM: 2807, codigoIBGE: 2705408, nomeTOM: 'MONTEIRÓPOLIS', nomeIBGE: 'Monteirópolis' },
    { codigoTOM: 2809, codigoIBGE: 2705507, nomeTOM: 'MURICI', nomeIBGE: 'Murici' },
    { codigoTOM: 2811, codigoIBGE: 2705606, nomeTOM: 'NOVO LINO', nomeIBGE: 'Novo Lino' },
    { codigoTOM: 2813, codigoIBGE: 2705705, nomeTOM: "OLHO D'ÁGUA DAS FLORES", nomeIBGE: "Olho d'Água das Flores" },
    { codigoTOM: 2815, codigoIBGE: 2705804, nomeTOM: "OLHO D'ÁGUA DO CASADO", nomeIBGE: "Olho d'Água do Casado" },
    { codigoTOM: 2817, codigoIBGE: 2705903, nomeTOM: "OLHO D'ÁGUA GRANDE", nomeIBGE: "Olho d'Água Grande" },
    { codigoTOM: 2819, codigoIBGE: 2706000, nomeTOM: 'OLIVENÇA', nomeIBGE: 'Olivença' },
    { codigoTOM: 2821, codigoIBGE: 2706109, nomeTOM: 'OURO BRANCO', nomeIBGE: 'Ouro Branco' },
    { codigoTOM: 2823, codigoIBGE: 2706208, nomeTOM: 'PALESTINA', nomeIBGE: 'Palestina' },
    { codigoTOM: 2825, codigoIBGE: 2706307, nomeTOM: 'PALMEIRA DOS ÍNDIOS', nomeIBGE: 'Palmeira dos Índios' },
    { codigoTOM: 2827, codigoIBGE: 2706406, nomeTOM: 'PÃO DE AÇÚCAR', nomeIBGE: 'Pão de Açúcar' },
    { codigoTOM: 2829, codigoIBGE: 2706505, nomeTOM: 'PASSO DE CAMARAGIBE', nomeIBGE: 'Passo de Camaragibe' },
    { codigoTOM: 2831, codigoIBGE: 2706604, nomeTOM: 'PAULO JACINTO', nomeIBGE: 'Paulo Jacinto' },
    { codigoTOM: 2833, codigoIBGE: 2706703, nomeTOM: 'PENEDO', nomeIBGE: 'Penedo' },
    { codigoTOM: 2835, codigoIBGE: 2706802, nomeTOM: 'PIAÇABUÇU', nomeIBGE: 'Piaçabuçu' },
    { codigoTOM: 2837, codigoIBGE: 2706901, nomeTOM: 'PILAR', nomeIBGE: 'Pilar' },
    { codigoTOM: 2839, codigoIBGE: 2707008, nomeTOM: 'PINDOBA', nomeIBGE: 'Pindoba' },
    { codigoTOM: 2841, codigoIBGE: 2707107, nomeTOM: 'PIRANHAS', nomeIBGE: 'Piranhas' },
    { codigoTOM: 2843, codigoIBGE: 2707206, nomeTOM: 'POÇO DAS TRINCHEIRAS', nomeIBGE: 'Poço das Trincheiras' },
    { codigoTOM: 2845, codigoIBGE: 2707305, nomeTOM: 'PORTO CALVO', nomeIBGE: 'Porto Calvo' },
    { codigoTOM: 2847, codigoIBGE: 2707404, nomeTOM: 'PORTO DE PEDRAS', nomeIBGE: 'Porto de Pedras' },
    { codigoTOM: 2849, codigoIBGE: 2707503, nomeTOM: 'PORTO REAL DO COLÉGIO', nomeIBGE: 'Porto Real do Colégio' },
    { codigoTOM: 2851, codigoIBGE: 2707602, nomeTOM: 'QUEBRANGULO', nomeIBGE: 'Quebrangulo' },
    { codigoTOM: 2853, codigoIBGE: 2707701, nomeTOM: 'RIO LARGO', nomeIBGE: 'Rio Largo' },
    { codigoTOM: 2855, codigoIBGE: 2707800, nomeTOM: 'ROTEIRO', nomeIBGE: 'Roteiro' },
    { codigoTOM: 2857, codigoIBGE: 2707909, nomeTOM: 'SANTA LUZIA DO NORTE', nomeIBGE: 'Santa Luzia do Norte' },
    { codigoTOM: 2859, codigoIBGE: 2708006, nomeTOM: 'SANTANA DO IPANEMA', nomeIBGE: 'Santana do Ipanema' },
    { codigoTOM: 2861, codigoIBGE: 2708105, nomeTOM: 'SANTANA DO MUNDAÚ', nomeIBGE: 'Santana do Mundaú' },
    { codigoTOM: 2863, codigoIBGE: 2708204, nomeTOM: 'SÃO BRÁS', nomeIBGE: 'São Brás' },
    { codigoTOM: 2865, codigoIBGE: 2708303, nomeTOM: 'SÃO JOSÉ DA LAJE', nomeIBGE: 'São José da Laje' },
    { codigoTOM: 2867, codigoIBGE: 2708402, nomeTOM: 'SÃO JOSÉ DA TAPERA', nomeIBGE: 'São José da Tapera' },
    { codigoTOM: 2869, codigoIBGE: 2708501, nomeTOM: 'SÃO LUÍS DO QUITUNDE', nomeIBGE: 'São Luís do Quitunde' },
    { codigoTOM: 2871, codigoIBGE: 2708600, nomeTOM: 'SÃO MIGUEL DOS CAMPOS', nomeIBGE: 'São Miguel dos Campos' },
    { codigoTOM: 2873, codigoIBGE: 2708709, nomeTOM: 'SÃO MIGUEL DOS MILAGRES', nomeIBGE: 'São Miguel dos Milagres' },
    { codigoTOM: 2875, codigoIBGE: 2708808, nomeTOM: 'SÃO SEBASTIÃO', nomeIBGE: 'São Sebastião' },
    { codigoTOM: 2877, codigoIBGE: 2708907, nomeTOM: 'SATUBA', nomeIBGE: 'Satuba' },
    { codigoTOM: 2879, codigoIBGE: 2709004, nomeTOM: "TANQUE D'ARCA", nomeIBGE: "Tanque d'Arca" },
    { codigoTOM: 2881, codigoIBGE: 2709103, nomeTOM: 'TAQUARANA', nomeIBGE: 'Taquarana' },
    { codigoTOM: 2883, codigoIBGE: 2709202, nomeTOM: 'TRAIPU', nomeIBGE: 'Traipu' },
    { codigoTOM: 2885, codigoIBGE: 2709301, nomeTOM: 'UNIÃO DOS PALMARES', nomeIBGE: 'União dos Palmares' },
    { codigoTOM: 2887, codigoIBGE: 2709400, nomeTOM: 'VIÇOSA', nomeIBGE: 'Viçosa' },
    { codigoTOM: 2889, codigoIBGE: 2702355, nomeTOM: 'CRAÍBAS', nomeIBGE: 'Craíbas' },
    { codigoTOM: 2891, codigoIBGE: 2708956, nomeTOM: 'SENADOR RUI PALMEIRA', nomeIBGE: 'Senador Rui Palmeira' },
  ],
  [Estados.MG]: [
    { codigoTOM: 564, codigoIBGE: 3102050, nomeTOM: 'ALTO CAPARAÓ', nomeIBGE: 'Alto Caparaó' },
    { codigoTOM: 566, codigoIBGE: 3102852, nomeTOM: 'ANGELÂNDIA', nomeIBGE: 'Angelândia' },
    { codigoTOM: 568, codigoIBGE: 3104452, nomeTOM: 'ARICANDUVA', nomeIBGE: 'Aricanduva' },
    { codigoTOM: 570, codigoIBGE: 3106655, nomeTOM: 'BERIZAL', nomeIBGE: 'Berizal' },
    { codigoTOM: 572, codigoIBGE: 3108255, nomeTOM: 'BONITO DE MINAS', nomeIBGE: 'Bonito de Minas' },
    { codigoTOM: 574, codigoIBGE: 3108552, nomeTOM: 'BRASILÂNDIA DE MINAS', nomeIBGE: 'Brasilândia de Minas' },
    { codigoTOM: 576, codigoIBGE: 3109253, nomeTOM: 'BUGRE', nomeIBGE: 'Bugre' },
    { codigoTOM: 578, codigoIBGE: 3109451, nomeTOM: 'CABECEIRA GRANDE', nomeIBGE: 'Cabeceira Grande' },
    { codigoTOM: 580, codigoIBGE: 3111150, nomeTOM: 'CAMPO AZUL', nomeIBGE: 'Campo Azul' },
    { codigoTOM: 582, codigoIBGE: 3112059, nomeTOM: 'CANTAGALO', nomeIBGE: 'Cantagalo' },
    { codigoTOM: 584, codigoIBGE: 3115359, nomeTOM: 'CATAS ALTAS', nomeIBGE: 'Catas Altas' },
    { codigoTOM: 586, codigoIBGE: 3115474, nomeTOM: 'CATUTI', nomeIBGE: 'Catuti' },
    { codigoTOM: 588, codigoIBGE: 3116159, nomeTOM: 'CHAPADA GAÚCHA', nomeIBGE: 'Chapada Gaúcha' },
    { codigoTOM: 590, codigoIBGE: 3117836, nomeTOM: 'CÔNEGO MARINHO', nomeIBGE: 'Cônego Marinho' },
    { codigoTOM: 592, codigoIBGE: 3117876, nomeTOM: 'CONFINS', nomeIBGE: 'Confins' },
    { codigoTOM: 594, codigoIBGE: 3119955, nomeTOM: 'CÓRREGO FUNDO', nomeIBGE: 'Córrego Fundo' },
    { codigoTOM: 596, codigoIBGE: 3120151, nomeTOM: 'CRISÓLITA', nomeIBGE: 'Crisólita' },
    { codigoTOM: 598, codigoIBGE: 3120839, nomeTOM: 'CUPARAQUE', nomeIBGE: 'Cuparaque' },
    { codigoTOM: 600, codigoIBGE: 3120870, nomeTOM: 'CURRAL DE DENTRO', nomeIBGE: 'Curral de Dentro' },
    { codigoTOM: 602, codigoIBGE: 3121258, nomeTOM: 'DELTA', nomeIBGE: 'Delta' },
    { codigoTOM: 604, codigoIBGE: 3122355, nomeTOM: 'DIVISA ALEGRE', nomeIBGE: 'Divisa Alegre' },
    { codigoTOM: 606, codigoIBGE: 3122470, nomeTOM: 'DOM BOSCO', nomeIBGE: 'Dom Bosco' },
    { codigoTOM: 608, codigoIBGE: 3126752, nomeTOM: 'FRANCISCÓPOLIS', nomeIBGE: 'Franciscópolis' },
    { codigoTOM: 610, codigoIBGE: 3126950, nomeTOM: 'FREI LAGONEGRO', nomeIBGE: 'Frei Lagonegro' },
    { codigoTOM: 612, codigoIBGE: 3127073, nomeTOM: 'FRUTA DE LEITE', nomeIBGE: 'Fruta de Leite' },
    { codigoTOM: 614, codigoIBGE: 3127339, nomeTOM: 'GAMELEIRAS', nomeIBGE: 'Gameleiras' },
    { codigoTOM: 616, codigoIBGE: 3127354, nomeTOM: 'GLAUCILÂNDIA', nomeIBGE: 'Glaucilândia' },
    { codigoTOM: 618, codigoIBGE: 3127370, nomeTOM: 'GOIABEIRA', nomeIBGE: 'Goiabeira' },
    { codigoTOM: 620, codigoIBGE: 3127388, nomeTOM: 'GOIANÁ', nomeIBGE: 'Goianá' },
    { codigoTOM: 622, codigoIBGE: 3128253, nomeTOM: 'GUARACIAMA', nomeIBGE: 'Guaraciama' },
    { codigoTOM: 624, codigoIBGE: 3129657, nomeTOM: 'IBIRACATU', nomeIBGE: 'Ibiracatu' },
    { codigoTOM: 626, codigoIBGE: 3130556, nomeTOM: 'IMBÉ DE MINAS', nomeIBGE: 'Imbé de Minas' },
    { codigoTOM: 628, codigoIBGE: 3130655, nomeTOM: 'INDAIABIRA', nomeIBGE: 'Indaiabira' },
    { codigoTOM: 630, codigoIBGE: 3135357, nomeTOM: 'JAPONVAR', nomeIBGE: 'Japonvar' },
    { codigoTOM: 632, codigoIBGE: 3135456, nomeTOM: 'JENIPAPO DE MINAS', nomeIBGE: 'Jenipapo de Minas' },
    { codigoTOM: 634, codigoIBGE: 3136520, nomeTOM: 'JOSÉ GONÇALVES DE MINAS', nomeIBGE: 'José Gonçalves de Minas' },
    { codigoTOM: 636, codigoIBGE: 3136553, nomeTOM: 'JOSÉ RAYDAN', nomeIBGE: 'José Raydan' },
    { codigoTOM: 638, codigoIBGE: 3136579, nomeTOM: 'JOSENÓPOLIS', nomeIBGE: 'Josenópolis' },
    { codigoTOM: 640, codigoIBGE: 3136959, nomeTOM: 'JUVENÍLIA', nomeIBGE: 'Juvenília' },
    { codigoTOM: 642, codigoIBGE: 3138351, nomeTOM: 'LEME DO PRADO', nomeIBGE: 'Leme do Prado' },
    { codigoTOM: 644, codigoIBGE: 3138674, nomeTOM: 'LUISBURGO', nomeIBGE: 'Luisburgo' },
    { codigoTOM: 646, codigoIBGE: 3138682, nomeTOM: 'LUISLÂNDIA', nomeIBGE: 'Luislândia' },
    { codigoTOM: 648, codigoIBGE: 3140159, nomeTOM: 'MÁRIO CAMPOS', nomeIBGE: 'Mário Campos' },
    { codigoTOM: 650, codigoIBGE: 3140530, nomeTOM: 'MARTINS SOARES', nomeIBGE: 'Martins Soares' },
    { codigoTOM: 652, codigoIBGE: 3142254, nomeTOM: 'MIRAVÂNIA', nomeIBGE: 'Miravânia' },
    { codigoTOM: 654, codigoIBGE: 3143153, nomeTOM: 'MONTE FORMOSO', nomeIBGE: 'Monte Formoso' },
    { codigoTOM: 656, codigoIBGE: 3144359, nomeTOM: 'NAQUE', nomeIBGE: 'Naque' },
    { codigoTOM: 658, codigoIBGE: 3144375, nomeTOM: 'NATALÂNDIA', nomeIBGE: 'Natalândia' },
    { codigoTOM: 660, codigoIBGE: 3144656, nomeTOM: 'NINHEIRA', nomeIBGE: 'Ninheira' },
    { codigoTOM: 662, codigoIBGE: 3144672, nomeTOM: 'NOVA BELÉM', nomeIBGE: 'Nova Belém' },
    { codigoTOM: 664, codigoIBGE: 3145059, nomeTOM: 'NOVA PORTEIRINHA', nomeIBGE: 'Nova Porteirinha' },
    { codigoTOM: 666, codigoIBGE: 3145356, nomeTOM: 'NOVO ORIENTE DE MINAS', nomeIBGE: 'Novo Oriente de Minas' },
    { codigoTOM: 668, codigoIBGE: 3145372, nomeTOM: 'NOVORIZONTE', nomeIBGE: 'Novorizonte' },
    { codigoTOM: 670, codigoIBGE: 3145455, nomeTOM: "OLHOS-D'ÁGUA", nomeIBGE: "Olhos-d'Água" },
    { codigoTOM: 672, codigoIBGE: 3145851, nomeTOM: 'ORATÓRIOS', nomeIBGE: 'Oratórios' },
    { codigoTOM: 674, codigoIBGE: 3145877, nomeTOM: 'ORIZÂNIA', nomeIBGE: 'Orizânia' },
    { codigoTOM: 676, codigoIBGE: 3146255, nomeTOM: 'PADRE CARVALHO', nomeIBGE: 'Padre Carvalho' },
    { codigoTOM: 678, codigoIBGE: 3146552, nomeTOM: 'PAI PEDRO', nomeIBGE: 'Pai Pedro' },
    { codigoTOM: 680, codigoIBGE: 3147956, nomeTOM: 'PATIS', nomeIBGE: 'Patis' },
    { codigoTOM: 682, codigoIBGE: 3148756, nomeTOM: 'PEDRA BONITA', nomeIBGE: 'Pedra Bonita' },
    { codigoTOM: 684, codigoIBGE: 3149952, nomeTOM: 'PERIQUITO', nomeIBGE: 'Periquito' },
    { codigoTOM: 686, codigoIBGE: 3150158, nomeTOM: 'PIEDADE DE CARATINGA', nomeIBGE: 'Piedade de Caratinga' },
    { codigoTOM: 688, codigoIBGE: 3150539, nomeTOM: "PINGO D'AGUA", nomeIBGE: "Pingo-d'Água" },
    { codigoTOM: 690, codigoIBGE: 3150570, nomeTOM: 'PINTÓPOLIS', nomeIBGE: 'Pintópolis' },
    { codigoTOM: 692, codigoIBGE: 3152131, nomeTOM: 'PONTO CHIQUE', nomeIBGE: 'Ponto Chique' },
    { codigoTOM: 694, codigoIBGE: 3152170, nomeTOM: 'PONTO DOS VOLANTES', nomeIBGE: 'Ponto dos Volantes' },
    { codigoTOM: 696, codigoIBGE: 3154150, nomeTOM: 'REDUTO', nomeIBGE: 'Reduto' },
    { codigoTOM: 698, codigoIBGE: 3156452, nomeTOM: 'ROSÁRIO DA LIMEIRA', nomeIBGE: 'Rosário da Limeira' },
    {
      codigoTOM: 700,
      codigoIBGE: 3157278,
      nomeTOM: 'SANTA BÁRBARA DO MONTE VERDE',
      nomeIBGE: 'Santa Bárbara do Monte Verde',
    },
    { codigoTOM: 702, codigoIBGE: 3157336, nomeTOM: 'SANTA CRUZ DE MINAS', nomeIBGE: 'Santa Cruz de Minas' },
    { codigoTOM: 704, codigoIBGE: 3157377, nomeTOM: 'SANTA CRUZ DE SALINAS', nomeIBGE: 'Santa Cruz de Salinas' },
    { codigoTOM: 706, codigoIBGE: 3157658, nomeTOM: 'SANTA HELENA DE MINAS', nomeIBGE: 'Santa Helena de Minas' },
    { codigoTOM: 708, codigoIBGE: 3160454, nomeTOM: 'SANTO ANTÔNIO DO RETIRO', nomeIBGE: 'Santo Antônio do Retiro' },
    { codigoTOM: 710, codigoIBGE: 3160959, nomeTOM: 'SÃO DOMINGOS DAS DORES', nomeIBGE: 'São Domingos das Dores' },
    { codigoTOM: 712, codigoIBGE: 3161056, nomeTOM: 'SÃO FÉLIX DE MINAS', nomeIBGE: 'São Félix de Minas' },
    { codigoTOM: 714, codigoIBGE: 3161650, nomeTOM: 'SÃO GERALDO DO BAIXIO', nomeIBGE: 'São Geraldo do Baixio' },
    { codigoTOM: 716, codigoIBGE: 3162252, nomeTOM: 'SÃO JOÃO DA LAGOA', nomeIBGE: 'São João da Lagoa' },
    { codigoTOM: 718, codigoIBGE: 3162450, nomeTOM: 'SÃO JOÃO DAS MISSÕES', nomeIBGE: 'São João das Missões' },
    { codigoTOM: 720, codigoIBGE: 3162658, nomeTOM: 'SÃO JOÃO DO PACUÍ', nomeIBGE: 'São João do Pacuí' },
    { codigoTOM: 722, codigoIBGE: 3162922, nomeTOM: 'SÃO JOAQUIM DE BICAS', nomeIBGE: 'São Joaquim de Bicas' },
    { codigoTOM: 724, codigoIBGE: 3162948, nomeTOM: 'SÃO JOSÉ DA BARRA', nomeIBGE: 'São José da Barra' },
    {
      codigoTOM: 726,
      codigoIBGE: 3164431,
      nomeTOM: 'SÃO SEBASTIÃO DA VARGEM ALEGRE',
      nomeIBGE: 'São Sebastião da Vargem Alegre',
    },
    { codigoTOM: 728, codigoIBGE: 3164472, nomeTOM: 'SÃO SEBASTIÃO DO ANTA', nomeIBGE: 'São Sebastião do Anta' },
    { codigoTOM: 730, codigoIBGE: 3165537, nomeTOM: 'SARZEDO', nomeIBGE: 'Sarzedo' },
    { codigoTOM: 732, codigoIBGE: 3165552, nomeTOM: 'SETUBINHA', nomeIBGE: 'Setubinha' },
    { codigoTOM: 734, codigoIBGE: 3165560, nomeTOM: 'SEM-PEIXE', nomeIBGE: 'Sem-Peixe' },
    { codigoTOM: 736, codigoIBGE: 3166956, nomeTOM: 'SERRANÓPOLIS DE MINAS', nomeIBGE: 'Serranópolis de Minas' },
    { codigoTOM: 738, codigoIBGE: 3168051, nomeTOM: 'TAPARUBA', nomeIBGE: 'Taparuba' },
    { codigoTOM: 740, codigoIBGE: 3169059, nomeTOM: 'TOCOS DO MOJI', nomeIBGE: 'Tocos do Moji' },
    { codigoTOM: 742, codigoIBGE: 3170438, nomeTOM: 'UNIÃO DE MINAS', nomeIBGE: 'União de Minas' },
    { codigoTOM: 744, codigoIBGE: 3170479, nomeTOM: 'URUANA DE MINAS', nomeIBGE: 'Uruana de Minas' },
    { codigoTOM: 746, codigoIBGE: 3170578, nomeTOM: 'VARGEM ALEGRE', nomeIBGE: 'Vargem Alegre' },
    {
      codigoTOM: 748,
      codigoIBGE: 3170651,
      nomeTOM: 'VARGEM GRANDE DO RIO PARDO',
      nomeIBGE: 'Vargem Grande do Rio Pardo',
    },
    { codigoTOM: 750, codigoIBGE: 3170750, nomeTOM: 'VARJÃO DE MINAS', nomeIBGE: 'Varjão de Minas' },
    { codigoTOM: 752, codigoIBGE: 3171030, nomeTOM: 'VERDELÂNDIA', nomeIBGE: 'Verdelândia' },
    { codigoTOM: 754, codigoIBGE: 3171071, nomeTOM: 'VEREDINHA', nomeIBGE: 'Veredinha' },
    { codigoTOM: 756, codigoIBGE: 3171154, nomeTOM: 'VERMELHO NOVO', nomeIBGE: 'Vermelho Novo' },
    { codigoTOM: 2649, codigoIBGE: 3162955, nomeTOM: 'SÃO JOSÉ DA LAPA', nomeIBGE: 'São José da Lapa' },
    { codigoTOM: 2651, codigoIBGE: 3112653, nomeTOM: 'CAPITÃO ANDRADE', nomeIBGE: 'Capitão Andrade' },
    { codigoTOM: 2653, codigoIBGE: 3115458, nomeTOM: 'CATUJI', nomeIBGE: 'Catuji' },
    { codigoTOM: 2655, codigoIBGE: 3135076, nomeTOM: 'JAMPRUCA', nomeIBGE: 'Jampruca' },
    { codigoTOM: 2657, codigoIBGE: 3122454, nomeTOM: 'DIVISÓPOLIS', nomeIBGE: 'Divisópolis' },
    { codigoTOM: 2659, codigoIBGE: 3140555, nomeTOM: 'MATA VERDE', nomeIBGE: 'Mata Verde' },
    { codigoTOM: 2661, codigoIBGE: 3146750, nomeTOM: 'PALMÓPOLIS', nomeIBGE: 'Palmópolis' },
    { codigoTOM: 2663, codigoIBGE: 3123858, nomeTOM: 'ENTRE FOLHAS', nomeIBGE: 'Entre Folhas' },
    { codigoTOM: 2665, codigoIBGE: 3131158, nomeTOM: 'IPABA', nomeIBGE: 'Ipaba' },
    { codigoTOM: 2667, codigoIBGE: 3157252, nomeTOM: 'SANTA BÁRBARA DO LESTE', nomeIBGE: 'Santa Bárbara do Leste' },
    { codigoTOM: 2669, codigoIBGE: 3159357, nomeTOM: 'SANTA RITA DE MINAS', nomeIBGE: 'Santa Rita de Minas' },
    { codigoTOM: 2671, codigoIBGE: 3170057, nomeTOM: 'UBAPORANGA', nomeIBGE: 'Ubaporanga' },
    { codigoTOM: 2673, codigoIBGE: 3158953, nomeTOM: 'SANTANA DO PARAÍSO', nomeIBGE: 'Santana do Paraíso' },
    { codigoTOM: 2675, codigoIBGE: 3123528, nomeTOM: 'DURANDÉ', nomeIBGE: 'Durandé' },
    { codigoTOM: 2677, codigoIBGE: 3162559, nomeTOM: 'SÃO JOÃO DO MANHUAÇU', nomeIBGE: 'São João do Manhuaçu' },
    { codigoTOM: 2679, codigoIBGE: 3162575, nomeTOM: 'SÃO JOÃO DO MANTENINHA', nomeIBGE: 'São João do Manteninha' },
    { codigoTOM: 2681, codigoIBGE: 3101631, nomeTOM: 'ALFREDO VASCONCELOS', nomeIBGE: 'Alfredo Vasconcelos' },
    { codigoTOM: 2683, codigoIBGE: 3125952, nomeTOM: 'FERVEDOURO', nomeIBGE: 'Fervedouro' },
    { codigoTOM: 2685, codigoIBGE: 3114550, nomeTOM: 'CARNEIRINHO', nomeIBGE: 'Carneirinho' },
    { codigoTOM: 2687, codigoIBGE: 3138625, nomeTOM: 'LIMEIRA DO OESTE', nomeIBGE: 'Limeira do Oeste' },
    { codigoTOM: 2689, codigoIBGE: 3165578, nomeTOM: 'SENADOR AMARAL', nomeIBGE: 'Senador Amaral' },
    { codigoTOM: 2691, codigoIBGE: 3136652, nomeTOM: 'JUATUBA', nomeIBGE: 'Juatuba' },
    { codigoTOM: 2693, codigoIBGE: 3130051, nomeTOM: 'ICARAÍ DE MINAS', nomeIBGE: 'Icaraí de Minas' },
    { codigoTOM: 2695, codigoIBGE: 3138658, nomeTOM: 'LONTRA', nomeIBGE: 'Lontra' },
    { codigoTOM: 2697, codigoIBGE: 3143450, nomeTOM: 'MONTEZUMA', nomeIBGE: 'Montezuma' },
    { codigoTOM: 2699, codigoIBGE: 3170529, nomeTOM: 'URUCUIA', nomeIBGE: 'Urucuia' },
    { codigoTOM: 2893, codigoIBGE: 3135050, nomeTOM: 'JAÍBA', nomeIBGE: 'Jaíba' },
    { codigoTOM: 2895, codigoIBGE: 3139250, nomeTOM: 'MAMONAS', nomeIBGE: 'Mamonas' },
    { codigoTOM: 2897, codigoIBGE: 3140852, nomeTOM: 'MATIAS CARDOSO', nomeIBGE: 'Matias Cardoso' },
    { codigoTOM: 2899, codigoIBGE: 3149150, nomeTOM: 'PEDRAS DE MARIA DA CRUZ', nomeIBGE: 'Pedras de Maria da Cruz' },
    { codigoTOM: 2901, codigoIBGE: 3154457, nomeTOM: 'RIACHINHO', nomeIBGE: 'Riachinho' },
    { codigoTOM: 2903, codigoIBGE: 3103751, nomeTOM: 'ARAPORÃ', nomeIBGE: 'Araporã' },
    { codigoTOM: 2905, codigoIBGE: 3137536, nomeTOM: 'LAGOA GRANDE', nomeIBGE: 'Lagoa Grande' },
    { codigoTOM: 4001, codigoIBGE: 3100104, nomeTOM: 'ABADIA DOS DOURADOS', nomeIBGE: 'Abadia dos Dourados' },
    { codigoTOM: 4003, codigoIBGE: 3100203, nomeTOM: 'ABAETÉ', nomeIBGE: 'Abaeté' },
    { codigoTOM: 4005, codigoIBGE: 3100302, nomeTOM: 'ABRE CAMPO', nomeIBGE: 'Abre Campo' },
    { codigoTOM: 4007, codigoIBGE: 3100401, nomeTOM: 'ACAIACA', nomeIBGE: 'Acaiaca' },
    { codigoTOM: 4009, codigoIBGE: 3100500, nomeTOM: 'AÇUCENA', nomeIBGE: 'Açucena' },
    { codigoTOM: 4011, codigoIBGE: 3100609, nomeTOM: 'ÁGUA BOA', nomeIBGE: 'Água Boa' },
    { codigoTOM: 4013, codigoIBGE: 3100708, nomeTOM: 'ÁGUA COMPRIDA', nomeIBGE: 'Água Comprida' },
    { codigoTOM: 4015, codigoIBGE: 3100807, nomeTOM: 'AGUANIL', nomeIBGE: 'Aguanil' },
    { codigoTOM: 4017, codigoIBGE: 3100906, nomeTOM: 'ÁGUAS FORMOSAS', nomeIBGE: 'Águas Formosas' },
    { codigoTOM: 4019, codigoIBGE: 3101003, nomeTOM: 'ÁGUAS VERMELHAS', nomeIBGE: 'Águas Vermelhas' },
    { codigoTOM: 4021, codigoIBGE: 3101102, nomeTOM: 'AIMORÉS', nomeIBGE: 'Aimorés' },
    { codigoTOM: 4023, codigoIBGE: 3101201, nomeTOM: 'AIURUOCA', nomeIBGE: 'Aiuruoca' },
    { codigoTOM: 4025, codigoIBGE: 3101300, nomeTOM: 'ALAGOA', nomeIBGE: 'Alagoa' },
    { codigoTOM: 4027, codigoIBGE: 3101409, nomeTOM: 'ALBERTINA', nomeIBGE: 'Albertina' },
    { codigoTOM: 4029, codigoIBGE: 3101508, nomeTOM: 'ALÉM PARAÍBA', nomeIBGE: 'Além Paraíba' },
    { codigoTOM: 4031, codigoIBGE: 3101607, nomeTOM: 'ALFENAS', nomeIBGE: 'Alfenas' },
    { codigoTOM: 4033, codigoIBGE: 3101706, nomeTOM: 'ALMENARA', nomeIBGE: 'Almenara' },
    { codigoTOM: 4035, codigoIBGE: 3101805, nomeTOM: 'ALPERCATA', nomeIBGE: 'Alpercata' },
    { codigoTOM: 4037, codigoIBGE: 3101904, nomeTOM: 'ALPINÓPOLIS', nomeIBGE: 'Alpinópolis' },
    { codigoTOM: 4039, codigoIBGE: 3102001, nomeTOM: 'ALTEROSA', nomeIBGE: 'Alterosa' },
    { codigoTOM: 4041, codigoIBGE: 3102100, nomeTOM: 'ALTO RIO DOCE', nomeIBGE: 'Alto Rio Doce' },
    { codigoTOM: 4043, codigoIBGE: 3102209, nomeTOM: 'ALVARENGA', nomeIBGE: 'Alvarenga' },
    { codigoTOM: 4045, codigoIBGE: 3102308, nomeTOM: 'ALVINÓPOLIS', nomeIBGE: 'Alvinópolis' },
    { codigoTOM: 4047, codigoIBGE: 3102407, nomeTOM: 'ALVORADA DE MINAS', nomeIBGE: 'Alvorada de Minas' },
    { codigoTOM: 4049, codigoIBGE: 3102506, nomeTOM: 'AMPARO DA SERRA', nomeIBGE: 'Amparo do Serra' },
    { codigoTOM: 4051, codigoIBGE: 3102605, nomeTOM: 'ANDRADAS', nomeIBGE: 'Andradas' },
    { codigoTOM: 4053, codigoIBGE: 3102704, nomeTOM: 'CACHOEIRA DE PAJEÚ', nomeIBGE: 'Cachoeira de Pajeú' },
    { codigoTOM: 4055, codigoIBGE: 3102803, nomeTOM: 'ANDRELÂNDIA', nomeIBGE: 'Andrelândia' },
    { codigoTOM: 4057, codigoIBGE: 3102902, nomeTOM: 'ANTÔNIO CARLOS', nomeIBGE: 'Antônio Carlos' },
    { codigoTOM: 4059, codigoIBGE: 3103009, nomeTOM: 'ANTÔNIO DIAS', nomeIBGE: 'Antônio Dias' },
    { codigoTOM: 4061, codigoIBGE: 3103108, nomeTOM: 'ANTÔNIO PRADO DE MINAS', nomeIBGE: 'Antônio Prado de Minas' },
    { codigoTOM: 4063, codigoIBGE: 3103207, nomeTOM: 'ARAÇAÍ', nomeIBGE: 'Araçaí' },
    { codigoTOM: 4065, codigoIBGE: 3103306, nomeTOM: 'ARACITABA', nomeIBGE: 'Aracitaba' },
    { codigoTOM: 4067, codigoIBGE: 3103405, nomeTOM: 'ARAÇUAÍ', nomeIBGE: 'Araçuaí' },
    { codigoTOM: 4069, codigoIBGE: 3103504, nomeTOM: 'ARAGUARI', nomeIBGE: 'Araguari' },
    { codigoTOM: 4071, codigoIBGE: 3103603, nomeTOM: 'ARANTINA', nomeIBGE: 'Arantina' },
    { codigoTOM: 4073, codigoIBGE: 3103702, nomeTOM: 'ARAPONGA', nomeIBGE: 'Araponga' },
    { codigoTOM: 4075, codigoIBGE: 3103801, nomeTOM: 'ARAPUÁ', nomeIBGE: 'Arapuá' },
    { codigoTOM: 4077, codigoIBGE: 3103900, nomeTOM: 'ARAÚJOS', nomeIBGE: 'Araújos' },
    { codigoTOM: 4079, codigoIBGE: 3104007, nomeTOM: 'ARAXÁ', nomeIBGE: 'Araxá' },
    { codigoTOM: 4081, codigoIBGE: 3104106, nomeTOM: 'ARCEBURGO', nomeIBGE: 'Arceburgo' },
    { codigoTOM: 4083, codigoIBGE: 3104205, nomeTOM: 'ARCOS', nomeIBGE: 'Arcos' },
    { codigoTOM: 4085, codigoIBGE: 3104304, nomeTOM: 'AREADO', nomeIBGE: 'Areado' },
    { codigoTOM: 4087, codigoIBGE: 3104403, nomeTOM: 'ARGIRITA', nomeIBGE: 'Argirita' },
    { codigoTOM: 4089, codigoIBGE: 3104502, nomeTOM: 'ARINOS', nomeIBGE: 'Arinos' },
    { codigoTOM: 4091, codigoIBGE: 3104601, nomeTOM: 'ASTOLFO DUTRA', nomeIBGE: 'Astolfo Dutra' },
    { codigoTOM: 4093, codigoIBGE: 3104700, nomeTOM: 'ATALÉIA', nomeIBGE: 'Ataléia' },
    { codigoTOM: 4095, codigoIBGE: 3104809, nomeTOM: 'AUGUSTO DE LIMA', nomeIBGE: 'Augusto de Lima' },
    { codigoTOM: 4097, codigoIBGE: 3104908, nomeTOM: 'BAEPENDI', nomeIBGE: 'Baependi' },
    { codigoTOM: 4099, codigoIBGE: 3105004, nomeTOM: 'BALDIM', nomeIBGE: 'Baldim' },
    { codigoTOM: 4101, codigoIBGE: 3105103, nomeTOM: 'BAMBUÍ', nomeIBGE: 'Bambuí' },
    { codigoTOM: 4103, codigoIBGE: 3105202, nomeTOM: 'BANDEIRA', nomeIBGE: 'Bandeira' },
    { codigoTOM: 4105, codigoIBGE: 3105301, nomeTOM: 'BANDEIRA DO SUL', nomeIBGE: 'Bandeira do Sul' },
    { codigoTOM: 4107, codigoIBGE: 3105400, nomeTOM: 'BARÃO DE COCAIS', nomeIBGE: 'Barão de Cocais' },
    { codigoTOM: 4109, codigoIBGE: 3105509, nomeTOM: 'BARÃO DO MONTE ALTO', nomeIBGE: 'Barão de Monte Alto' },
    { codigoTOM: 4111, codigoIBGE: 3105608, nomeTOM: 'BARBACENA', nomeIBGE: 'Barbacena' },
    { codigoTOM: 4113, codigoIBGE: 3105707, nomeTOM: 'BARRA LONGA', nomeIBGE: 'Barra Longa' },
    { codigoTOM: 4115, codigoIBGE: 3169356, nomeTOM: 'TRÊS MARIAS', nomeIBGE: 'Três Marias' },
    { codigoTOM: 4117, codigoIBGE: 3105905, nomeTOM: 'BARROSO', nomeIBGE: 'Barroso' },
    { codigoTOM: 4119, codigoIBGE: 3106002, nomeTOM: 'BELA VISTA DE MINAS', nomeIBGE: 'Bela Vista de Minas' },
    { codigoTOM: 4121, codigoIBGE: 3106101, nomeTOM: 'BELMIRO BRAGA', nomeIBGE: 'Belmiro Braga' },
    { codigoTOM: 4123, codigoIBGE: 3106200, nomeTOM: 'BELO HORIZONTE', nomeIBGE: 'Belo Horizonte' },
    { codigoTOM: 4125, codigoIBGE: 3106309, nomeTOM: 'BELO ORIENTE', nomeIBGE: 'Belo Oriente' },
    { codigoTOM: 4127, codigoIBGE: 3106408, nomeTOM: 'BELO VALE', nomeIBGE: 'Belo Vale' },
    { codigoTOM: 4129, codigoIBGE: 3106507, nomeTOM: 'BERILO', nomeIBGE: 'Berilo' },
    { codigoTOM: 4131, codigoIBGE: 3106606, nomeTOM: 'BERTÓPOLIS', nomeIBGE: 'Bertópolis' },
    { codigoTOM: 4133, codigoIBGE: 3106705, nomeTOM: 'BETIM', nomeIBGE: 'Betim' },
    { codigoTOM: 4135, codigoIBGE: 3106804, nomeTOM: 'BIAS FORTES', nomeIBGE: 'Bias Fortes' },
    { codigoTOM: 4137, codigoIBGE: 3106903, nomeTOM: 'BICAS', nomeIBGE: 'Bicas' },
    { codigoTOM: 4139, codigoIBGE: 3107000, nomeTOM: 'BIQUINHAS', nomeIBGE: 'Biquinhas' },
    { codigoTOM: 4141, codigoIBGE: 3107109, nomeTOM: 'BOA ESPERANÇA', nomeIBGE: 'Boa Esperança' },
    { codigoTOM: 4143, codigoIBGE: 3107208, nomeTOM: 'BOCAINA DE MINAS', nomeIBGE: 'Bocaina de Minas' },
    { codigoTOM: 4145, codigoIBGE: 3107307, nomeTOM: 'BOCAIÚVA', nomeIBGE: 'Bocaiúva' },
    { codigoTOM: 4147, codigoIBGE: 3107406, nomeTOM: 'BOM DESPACHO', nomeIBGE: 'Bom Despacho' },
    { codigoTOM: 4149, codigoIBGE: 3107505, nomeTOM: 'BOM JARDIM DE MINAS', nomeIBGE: 'Bom Jardim de Minas' },
    { codigoTOM: 4151, codigoIBGE: 3107604, nomeTOM: 'BOM JESUS DA PENHA', nomeIBGE: 'Bom Jesus da Penha' },
    { codigoTOM: 4153, codigoIBGE: 3107703, nomeTOM: 'BOM JESUS DO AMPARO', nomeIBGE: 'Bom Jesus do Amparo' },
    { codigoTOM: 4155, codigoIBGE: 3107802, nomeTOM: 'BOM JESUS DO GALHO', nomeIBGE: 'Bom Jesus do Galho' },
    { codigoTOM: 4157, codigoIBGE: 3107901, nomeTOM: 'BOM REPOUSO', nomeIBGE: 'Bom Repouso' },
    { codigoTOM: 4159, codigoIBGE: 3108008, nomeTOM: 'BOM SUCESSO', nomeIBGE: 'Bom Sucesso' },
    { codigoTOM: 4161, codigoIBGE: 3108107, nomeTOM: 'BONFIM', nomeIBGE: 'Bonfim' },
    { codigoTOM: 4163, codigoIBGE: 3108206, nomeTOM: 'BONFINÓPOLIS DE MINAS', nomeIBGE: 'Bonfinópolis de Minas' },
    { codigoTOM: 4165, codigoIBGE: 3108305, nomeTOM: 'BORDA DA MATA', nomeIBGE: 'Borda da Mata' },
    { codigoTOM: 4167, codigoIBGE: 3108404, nomeTOM: 'BOTELHOS', nomeIBGE: 'Botelhos' },
    { codigoTOM: 4169, codigoIBGE: 3108503, nomeTOM: 'BOTUMIRIM', nomeIBGE: 'Botumirim' },
    { codigoTOM: 4171, codigoIBGE: 3108602, nomeTOM: 'BRASÍLIA DE MINAS', nomeIBGE: 'Brasília de Minas' },
    { codigoTOM: 4173, codigoIBGE: 3108701, nomeTOM: 'BRÁS PIRES', nomeIBGE: 'Brás Pires' },
    { codigoTOM: 4175, codigoIBGE: 3108800, nomeTOM: 'BRAÚNAS', nomeIBGE: 'Braúnas' },
    { codigoTOM: 4177, codigoIBGE: 3108909, nomeTOM: 'BRASÓPOLIS', nomeIBGE: 'Brazópolis' },
    { codigoTOM: 4179, codigoIBGE: 3109006, nomeTOM: 'BRUMADINHO', nomeIBGE: 'Brumadinho' },
    { codigoTOM: 4181, codigoIBGE: 3109105, nomeTOM: 'BUENO BRANDÃO', nomeIBGE: 'Bueno Brandão' },
    { codigoTOM: 4183, codigoIBGE: 3109204, nomeTOM: 'BUENÓPOLIS', nomeIBGE: 'Buenópolis' },
    { codigoTOM: 4185, codigoIBGE: 3109303, nomeTOM: 'BURITIS', nomeIBGE: 'Buritis' },
    { codigoTOM: 4187, codigoIBGE: 3109402, nomeTOM: 'BURITIZEIRO', nomeIBGE: 'Buritizeiro' },
    { codigoTOM: 4189, codigoIBGE: 3109501, nomeTOM: 'CABO VERDE', nomeIBGE: 'Cabo Verde' },
    { codigoTOM: 4191, codigoIBGE: 3109600, nomeTOM: 'CACHOEIRA DA PRATA', nomeIBGE: 'Cachoeira da Prata' },
    { codigoTOM: 4193, codigoIBGE: 3109709, nomeTOM: 'CACHOEIRA DE MINAS', nomeIBGE: 'Cachoeira de Minas' },
    { codigoTOM: 4195, codigoIBGE: 3109808, nomeTOM: 'CACHOEIRA DOURADA', nomeIBGE: 'Cachoeira Dourada' },
    { codigoTOM: 4197, codigoIBGE: 3109907, nomeTOM: 'CAETANÓPOLIS', nomeIBGE: 'Caetanópolis' },
    { codigoTOM: 4199, codigoIBGE: 3110004, nomeTOM: 'CAETÉ', nomeIBGE: 'Caeté' },
    { codigoTOM: 4201, codigoIBGE: 3110103, nomeTOM: 'CAIANA', nomeIBGE: 'Caiana' },
    { codigoTOM: 4203, codigoIBGE: 3110202, nomeTOM: 'CAJURI', nomeIBGE: 'Cajuri' },
    { codigoTOM: 4205, codigoIBGE: 3110301, nomeTOM: 'CALDAS', nomeIBGE: 'Caldas' },
    { codigoTOM: 4207, codigoIBGE: 3110400, nomeTOM: 'CAMACHO', nomeIBGE: 'Camacho' },
    { codigoTOM: 4209, codigoIBGE: 3110509, nomeTOM: 'CAMANDUCAIA', nomeIBGE: 'Camanducaia' },
    { codigoTOM: 4211, codigoIBGE: 3110608, nomeTOM: 'CAMBUÍ', nomeIBGE: 'Cambuí' },
    { codigoTOM: 4213, codigoIBGE: 3110707, nomeTOM: 'CAMBUQUIRA', nomeIBGE: 'Cambuquira' },
    { codigoTOM: 4215, codigoIBGE: 3110806, nomeTOM: 'CAMPANÁRIO', nomeIBGE: 'Campanário' },
    { codigoTOM: 4217, codigoIBGE: 3110905, nomeTOM: 'CAMPANHA', nomeIBGE: 'Campanha' },
    { codigoTOM: 4219, codigoIBGE: 3111002, nomeTOM: 'CAMPESTRE', nomeIBGE: 'Campestre' },
    { codigoTOM: 4221, codigoIBGE: 3111101, nomeTOM: 'CAMPINA VERDE', nomeIBGE: 'Campina Verde' },
    { codigoTOM: 4223, codigoIBGE: 3111200, nomeTOM: 'CAMPO BELO', nomeIBGE: 'Campo Belo' },
    { codigoTOM: 4225, codigoIBGE: 3111309, nomeTOM: 'CAMPO DO MEIO', nomeIBGE: 'Campo do Meio' },
    { codigoTOM: 4227, codigoIBGE: 3111408, nomeTOM: 'CAMPO FLORIDO', nomeIBGE: 'Campo Florido' },
    { codigoTOM: 4229, codigoIBGE: 3111507, nomeTOM: 'CAMPOS ALTOS', nomeIBGE: 'Campos Altos' },
    { codigoTOM: 4231, codigoIBGE: 3111606, nomeTOM: 'CAMPOS GERAIS', nomeIBGE: 'Campos Gerais' },
    { codigoTOM: 4233, codigoIBGE: 3111705, nomeTOM: 'CANAÃ', nomeIBGE: 'Canaã' },
    { codigoTOM: 4235, codigoIBGE: 3111804, nomeTOM: 'CANÁPOLIS', nomeIBGE: 'Canápolis' },
    { codigoTOM: 4237, codigoIBGE: 3111903, nomeTOM: 'CANA VERDE', nomeIBGE: 'Cana Verde' },
    { codigoTOM: 4239, codigoIBGE: 3112000, nomeTOM: 'CANDEIAS', nomeIBGE: 'Candeias' },
    { codigoTOM: 4241, codigoIBGE: 3112109, nomeTOM: 'CAPARAÓ', nomeIBGE: 'Caparaó' },
    { codigoTOM: 4243, codigoIBGE: 3112208, nomeTOM: 'CAPELA NOVA', nomeIBGE: 'Capela Nova' },
    { codigoTOM: 4245, codigoIBGE: 3112307, nomeTOM: 'CAPELINHA', nomeIBGE: 'Capelinha' },
    { codigoTOM: 4247, codigoIBGE: 3112406, nomeTOM: 'CAPETINGA', nomeIBGE: 'Capetinga' },
    { codigoTOM: 4249, codigoIBGE: 3112505, nomeTOM: 'CAPIM BRANCO', nomeIBGE: 'Capim Branco' },
    { codigoTOM: 4251, codigoIBGE: 3112604, nomeTOM: 'CAPINÓPOLIS', nomeIBGE: 'Capinópolis' },
    { codigoTOM: 4253, codigoIBGE: 3112703, nomeTOM: 'CAPITÃO ENÉAS', nomeIBGE: 'Capitão Enéas' },
    { codigoTOM: 4255, codigoIBGE: 3112802, nomeTOM: 'CAPITÓLIO', nomeIBGE: 'Capitólio' },
    { codigoTOM: 4257, codigoIBGE: 3112901, nomeTOM: 'CAPUTIRA', nomeIBGE: 'Caputira' },
    { codigoTOM: 4259, codigoIBGE: 3113008, nomeTOM: 'CARAÍ', nomeIBGE: 'Caraí' },
    { codigoTOM: 4261, codigoIBGE: 3113107, nomeTOM: 'CARANAÍBA', nomeIBGE: 'Caranaíba' },
    { codigoTOM: 4263, codigoIBGE: 3113206, nomeTOM: 'CARANDAÍ', nomeIBGE: 'Carandaí' },
    { codigoTOM: 4265, codigoIBGE: 3113305, nomeTOM: 'CARANGOLA', nomeIBGE: 'Carangola' },
    { codigoTOM: 4267, codigoIBGE: 3113404, nomeTOM: 'CARATINGA', nomeIBGE: 'Caratinga' },
    { codigoTOM: 4269, codigoIBGE: 3113503, nomeTOM: 'CARBONITA', nomeIBGE: 'Carbonita' },
    { codigoTOM: 4271, codigoIBGE: 3113602, nomeTOM: 'CAREAÇU', nomeIBGE: 'Careaçu' },
    { codigoTOM: 4273, codigoIBGE: 3113701, nomeTOM: 'CARLOS CHAGAS', nomeIBGE: 'Carlos Chagas' },
    { codigoTOM: 4275, codigoIBGE: 3113800, nomeTOM: 'CARMÉSIA', nomeIBGE: 'Carmésia' },
    { codigoTOM: 4277, codigoIBGE: 3113909, nomeTOM: 'CARMO DA CACHOEIRA', nomeIBGE: 'Carmo da Cachoeira' },
    { codigoTOM: 4279, codigoIBGE: 3114006, nomeTOM: 'CARMO DA MATA', nomeIBGE: 'Carmo da Mata' },
    { codigoTOM: 4281, codigoIBGE: 3114105, nomeTOM: 'CARMO DE MINAS', nomeIBGE: 'Carmo de Minas' },
    { codigoTOM: 4283, codigoIBGE: 3114204, nomeTOM: 'CARMO DO CAJURU', nomeIBGE: 'Carmo do Cajuru' },
    { codigoTOM: 4285, codigoIBGE: 3114303, nomeTOM: 'CARMO DO PARANAÍBA', nomeIBGE: 'Carmo do Paranaíba' },
    { codigoTOM: 4287, codigoIBGE: 3114402, nomeTOM: 'CARMO DO RIO CLARO', nomeIBGE: 'Carmo do Rio Claro' },
    { codigoTOM: 4289, codigoIBGE: 3114501, nomeTOM: 'CARMÓPOLIS DE MINAS', nomeIBGE: 'Carmópolis de Minas' },
    { codigoTOM: 4291, codigoIBGE: 3114600, nomeTOM: 'CARRANCAS', nomeIBGE: 'Carrancas' },
    { codigoTOM: 4293, codigoIBGE: 3114709, nomeTOM: 'CARVALHÓPOLIS', nomeIBGE: 'Carvalhópolis' },
    { codigoTOM: 4295, codigoIBGE: 3114808, nomeTOM: 'CARVALHOS', nomeIBGE: 'Carvalhos' },
    { codigoTOM: 4297, codigoIBGE: 3114907, nomeTOM: 'CASA GRANDE', nomeIBGE: 'Casa Grande' },
    { codigoTOM: 4299, codigoIBGE: 3115003, nomeTOM: 'CASCALHO RICO', nomeIBGE: 'Cascalho Rico' },
    { codigoTOM: 4301, codigoIBGE: 3115102, nomeTOM: 'CÁSSIA', nomeIBGE: 'Cássia' },
    {
      codigoTOM: 4303,
      codigoIBGE: 3115201,
      nomeTOM: 'CONCEIÇÃO DA BARRA DE MINAS',
      nomeIBGE: 'Conceição da Barra de Minas',
    },
    { codigoTOM: 4305, codigoIBGE: 3115300, nomeTOM: 'CATAGUASES', nomeIBGE: 'Cataguases' },
    { codigoTOM: 4307, codigoIBGE: 3115409, nomeTOM: 'CATAS ALTAS DA NORUEGA', nomeIBGE: 'Catas Altas da Noruega' },
    { codigoTOM: 4309, codigoIBGE: 3115508, nomeTOM: 'CAXAMBU', nomeIBGE: 'Caxambu' },
    { codigoTOM: 4311, codigoIBGE: 3115607, nomeTOM: 'CEDRO DO ABAETÉ', nomeIBGE: 'Cedro do Abaeté' },
    { codigoTOM: 4313, codigoIBGE: 3115706, nomeTOM: 'CENTRAL DE MINAS', nomeIBGE: 'Central de Minas' },
    { codigoTOM: 4315, codigoIBGE: 3115805, nomeTOM: 'CENTRALINA', nomeIBGE: 'Centralina' },
    { codigoTOM: 4317, codigoIBGE: 3115904, nomeTOM: 'CHÁCARA', nomeIBGE: 'Chácara' },
    { codigoTOM: 4319, codigoIBGE: 3116001, nomeTOM: 'CHALÉ', nomeIBGE: 'Chalé' },
    { codigoTOM: 4321, codigoIBGE: 3116100, nomeTOM: 'CHAPADA DO NORTE', nomeIBGE: 'Chapada do Norte' },
    { codigoTOM: 4323, codigoIBGE: 3116209, nomeTOM: 'CHIADOR', nomeIBGE: 'Chiador' },
    { codigoTOM: 4325, codigoIBGE: 3116308, nomeTOM: 'CIPOTÂNEA', nomeIBGE: 'Cipotânea' },
    { codigoTOM: 4327, codigoIBGE: 3116407, nomeTOM: 'CLARAVAL', nomeIBGE: 'Claraval' },
    { codigoTOM: 4329, codigoIBGE: 3116506, nomeTOM: 'CLARO DOS POÇÕES', nomeIBGE: 'Claro dos Poções' },
    { codigoTOM: 4331, codigoIBGE: 3116605, nomeTOM: 'CLÁUDIO', nomeIBGE: 'Cláudio' },
    { codigoTOM: 4333, codigoIBGE: 3116704, nomeTOM: 'COIMBRA', nomeIBGE: 'Coimbra' },
    { codigoTOM: 4335, codigoIBGE: 3116803, nomeTOM: 'COLUNA', nomeIBGE: 'Coluna' },
    { codigoTOM: 4337, codigoIBGE: 3116902, nomeTOM: 'COMENDADOR GOMES', nomeIBGE: 'Comendador Gomes' },
    { codigoTOM: 4339, codigoIBGE: 3117009, nomeTOM: 'COMERCINHO', nomeIBGE: 'Comercinho' },
    { codigoTOM: 4341, codigoIBGE: 3117108, nomeTOM: 'CONCEIÇÃO DA APARECIDA', nomeIBGE: 'Conceição da Aparecida' },
    { codigoTOM: 4343, codigoIBGE: 3117207, nomeTOM: 'CONCEIÇÃO DAS PEDRAS', nomeIBGE: 'Conceição das Pedras' },
    { codigoTOM: 4345, codigoIBGE: 3117306, nomeTOM: 'CONCEIÇÃO DAS ALAGOAS', nomeIBGE: 'Conceição das Alagoas' },
    { codigoTOM: 4347, codigoIBGE: 3117405, nomeTOM: 'CONCEIÇÃO DE IPANEMA', nomeIBGE: 'Conceição de Ipanema' },
    { codigoTOM: 4349, codigoIBGE: 3117504, nomeTOM: 'CONCEIÇÃO DO MATO DENTRO', nomeIBGE: 'Conceição do Mato Dentro' },
    { codigoTOM: 4351, codigoIBGE: 3117603, nomeTOM: 'CONCEIÇÃO DO PARÁ', nomeIBGE: 'Conceição do Pará' },
    { codigoTOM: 4353, codigoIBGE: 3117702, nomeTOM: 'CONCEIÇÃO DO RIO VERDE', nomeIBGE: 'Conceição do Rio Verde' },
    { codigoTOM: 4355, codigoIBGE: 3117801, nomeTOM: 'CONCEIÇÃO DOS OUROS', nomeIBGE: 'Conceição dos Ouros' },
    { codigoTOM: 4357, codigoIBGE: 3117900, nomeTOM: 'CONGONHAL', nomeIBGE: 'Congonhal' },
    { codigoTOM: 4359, codigoIBGE: 3118007, nomeTOM: 'CONGONHAS', nomeIBGE: 'Congonhas' },
    { codigoTOM: 4361, codigoIBGE: 3118106, nomeTOM: 'CONGONHAS DO NORTE', nomeIBGE: 'Congonhas do Norte' },
    { codigoTOM: 4363, codigoIBGE: 3118205, nomeTOM: 'CONQUISTA', nomeIBGE: 'Conquista' },
    { codigoTOM: 4365, codigoIBGE: 3118304, nomeTOM: 'CONSELHEIRO LAFAIETE', nomeIBGE: 'Conselheiro Lafaiete' },
    { codigoTOM: 4367, codigoIBGE: 3118403, nomeTOM: 'CONSELHEIRO PENA', nomeIBGE: 'Conselheiro Pena' },
    { codigoTOM: 4369, codigoIBGE: 3118502, nomeTOM: 'CONSOLAÇÃO', nomeIBGE: 'Consolação' },
    { codigoTOM: 4371, codigoIBGE: 3118601, nomeTOM: 'CONTAGEM', nomeIBGE: 'Contagem' },
    { codigoTOM: 4373, codigoIBGE: 3118700, nomeTOM: 'COQUEIRAL', nomeIBGE: 'Coqueiral' },
    { codigoTOM: 4375, codigoIBGE: 3118809, nomeTOM: 'CORAÇÃO DE JESUS', nomeIBGE: 'Coração de Jesus' },
    { codigoTOM: 4377, codigoIBGE: 3118908, nomeTOM: 'CORDISBURGO', nomeIBGE: 'Cordisburgo' },
    { codigoTOM: 4379, codigoIBGE: 3119005, nomeTOM: 'CORDISLÂNDIA', nomeIBGE: 'Cordislândia' },
    { codigoTOM: 4381, codigoIBGE: 3119104, nomeTOM: 'CORINTO', nomeIBGE: 'Corinto' },
    { codigoTOM: 4383, codigoIBGE: 3119203, nomeTOM: 'COROACI', nomeIBGE: 'Coroaci' },
    { codigoTOM: 4385, codigoIBGE: 3119302, nomeTOM: 'COROMANDEL', nomeIBGE: 'Coromandel' },
    { codigoTOM: 4387, codigoIBGE: 3119401, nomeTOM: 'CORONEL FABRICIANO', nomeIBGE: 'Coronel Fabriciano' },
    { codigoTOM: 4389, codigoIBGE: 3119500, nomeTOM: 'CORONEL MURTA', nomeIBGE: 'Coronel Murta' },
    { codigoTOM: 4391, codigoIBGE: 3119609, nomeTOM: 'CORONEL PACHECO', nomeIBGE: 'Coronel Pacheco' },
    { codigoTOM: 4393, codigoIBGE: 3119708, nomeTOM: 'CORONEL XAVIER CHAVES', nomeIBGE: 'Coronel Xavier Chaves' },
    { codigoTOM: 4395, codigoIBGE: 3119807, nomeTOM: 'CÓRREGO DANTA', nomeIBGE: 'Córrego Danta' },
    { codigoTOM: 4397, codigoIBGE: 3119906, nomeTOM: 'CÓRREGO DO BOM JESUS', nomeIBGE: 'Córrego do Bom Jesus' },
    { codigoTOM: 4399, codigoIBGE: 3120003, nomeTOM: 'CÓRREGO NOVO', nomeIBGE: 'Córrego Novo' },
    {
      codigoTOM: 4401,
      codigoIBGE: 3120102,
      nomeTOM: 'COUTO DE MAGALHÃES DE MINAS',
      nomeIBGE: 'Couto de Magalhães de Minas',
    },
    { codigoTOM: 4403, codigoIBGE: 3120201, nomeTOM: 'CRISTAIS', nomeIBGE: 'Cristais' },
    { codigoTOM: 4405, codigoIBGE: 3120300, nomeTOM: 'CRISTÁLIA', nomeIBGE: 'Cristália' },
    { codigoTOM: 4407, codigoIBGE: 3120409, nomeTOM: 'CRISTIANO OTONI', nomeIBGE: 'Cristiano Otoni' },
    { codigoTOM: 4409, codigoIBGE: 3120508, nomeTOM: 'CRISTINA', nomeIBGE: 'Cristina' },
    { codigoTOM: 4411, codigoIBGE: 3120607, nomeTOM: 'CRUCILÂNDIA', nomeIBGE: 'Crucilândia' },
    { codigoTOM: 4413, codigoIBGE: 3120706, nomeTOM: 'CRUZEIRO DA FORTALEZA', nomeIBGE: 'Cruzeiro da Fortaleza' },
    { codigoTOM: 4415, codigoIBGE: 3120805, nomeTOM: 'CRUZÍLIA', nomeIBGE: 'Cruzília' },
    { codigoTOM: 4417, codigoIBGE: 3120904, nomeTOM: 'CURVELO', nomeIBGE: 'Curvelo' },
    { codigoTOM: 4419, codigoIBGE: 3121001, nomeTOM: 'DATAS', nomeIBGE: 'Datas' },
    { codigoTOM: 4421, codigoIBGE: 3121100, nomeTOM: 'DELFIM MOREIRA', nomeIBGE: 'Delfim Moreira' },
    { codigoTOM: 4423, codigoIBGE: 3121209, nomeTOM: 'DELFINÓPOLIS', nomeIBGE: 'Delfinópolis' },
    { codigoTOM: 4425, codigoIBGE: 3121308, nomeTOM: 'DESCOBERTO', nomeIBGE: 'Descoberto' },
    { codigoTOM: 4427, codigoIBGE: 3121407, nomeTOM: 'DESTERRO DE ENTRE RIOS', nomeIBGE: 'Desterro de Entre Rios' },
    { codigoTOM: 4429, codigoIBGE: 3121506, nomeTOM: 'DESTERRO DO MELO', nomeIBGE: 'Desterro do Melo' },
    { codigoTOM: 4431, codigoIBGE: 3121605, nomeTOM: 'DIAMANTINA', nomeIBGE: 'Diamantina' },
    { codigoTOM: 4433, codigoIBGE: 3121704, nomeTOM: 'DIOGO DE VASCONCELOS', nomeIBGE: 'Diogo de Vasconcelos' },
    { codigoTOM: 4435, codigoIBGE: 3121803, nomeTOM: 'DIONÍSIO', nomeIBGE: 'Dionísio' },
    { codigoTOM: 4437, codigoIBGE: 3121902, nomeTOM: 'DIVINÉSIA', nomeIBGE: 'Divinésia' },
    { codigoTOM: 4439, codigoIBGE: 3122009, nomeTOM: 'DIVINO', nomeIBGE: 'Divino' },
    { codigoTOM: 4441, codigoIBGE: 3122108, nomeTOM: 'DIVINO DAS LARANJEIRAS', nomeIBGE: 'Divino das Laranjeiras' },
    { codigoTOM: 4443, codigoIBGE: 3122207, nomeTOM: 'DIVINOLÂNDIA DE MINAS', nomeIBGE: 'Divinolândia de Minas' },
    { codigoTOM: 4445, codigoIBGE: 3122306, nomeTOM: 'DIVINÓPOLIS', nomeIBGE: 'Divinópolis' },
    { codigoTOM: 4447, codigoIBGE: 3122405, nomeTOM: 'DIVISA NOVA', nomeIBGE: 'Divisa Nova' },
    { codigoTOM: 4449, codigoIBGE: 3122504, nomeTOM: 'DOM CAVATI', nomeIBGE: 'Dom Cavati' },
    { codigoTOM: 4451, codigoIBGE: 3122603, nomeTOM: 'DOM JOAQUIM', nomeIBGE: 'Dom Joaquim' },
    { codigoTOM: 4453, codigoIBGE: 3122702, nomeTOM: 'DOM SILVÉRIO', nomeIBGE: 'Dom Silvério' },
    { codigoTOM: 4455, codigoIBGE: 3122801, nomeTOM: 'DOM VIÇOSO', nomeIBGE: 'Dom Viçoso' },
    { codigoTOM: 4457, codigoIBGE: 3122900, nomeTOM: 'DONA EUZEBIA', nomeIBGE: 'Dona Euzébia' },
    { codigoTOM: 4459, codigoIBGE: 3123007, nomeTOM: 'DORES DE CAMPOS', nomeIBGE: 'Dores de Campos' },
    { codigoTOM: 4461, codigoIBGE: 3123106, nomeTOM: 'DORES DE GUANHÃES', nomeIBGE: 'Dores de Guanhães' },
    { codigoTOM: 4463, codigoIBGE: 3123205, nomeTOM: 'DORES DO INDAIÁ', nomeIBGE: 'Dores do Indaiá' },
    { codigoTOM: 4465, codigoIBGE: 3123304, nomeTOM: 'DORES DO TURVO', nomeIBGE: 'Dores do Turvo' },
    { codigoTOM: 4467, codigoIBGE: 3123403, nomeTOM: 'DORESÓPOLIS', nomeIBGE: 'Doresópolis' },
    { codigoTOM: 4469, codigoIBGE: 3123502, nomeTOM: 'DOURADOQUARA', nomeIBGE: 'Douradoquara' },
    { codigoTOM: 4471, codigoIBGE: 3123601, nomeTOM: 'ELÓI MENDES', nomeIBGE: 'Elói Mendes' },
    { codigoTOM: 4473, codigoIBGE: 3123700, nomeTOM: 'ENGENHEIRO CALDAS', nomeIBGE: 'Engenheiro Caldas' },
    { codigoTOM: 4475, codigoIBGE: 3123809, nomeTOM: 'ENGENHEIRO NAVARRO', nomeIBGE: 'Engenheiro Navarro' },
    { codigoTOM: 4477, codigoIBGE: 3123908, nomeTOM: 'ENTRE RIOS DE MINAS', nomeIBGE: 'Entre Rios de Minas' },
    { codigoTOM: 4479, codigoIBGE: 3124005, nomeTOM: 'ERVÁLIA', nomeIBGE: 'Ervália' },
    { codigoTOM: 4481, codigoIBGE: 3124104, nomeTOM: 'ESMERALDAS', nomeIBGE: 'Esmeraldas' },
    { codigoTOM: 4483, codigoIBGE: 3124203, nomeTOM: 'ESPERA FELIZ', nomeIBGE: 'Espera Feliz' },
    { codigoTOM: 4485, codigoIBGE: 3124302, nomeTOM: 'ESPINOSA', nomeIBGE: 'Espinosa' },
    {
      codigoTOM: 4487,
      codigoIBGE: 3124401,
      nomeTOM: 'ESPÍRITO SANTO DO DOURADO',
      nomeIBGE: 'Espírito Santo do Dourado',
    },
    { codigoTOM: 4489, codigoIBGE: 3124500, nomeTOM: 'ESTIVA', nomeIBGE: 'Estiva' },
    { codigoTOM: 4491, codigoIBGE: 3124609, nomeTOM: 'ESTRELA DALVA', nomeIBGE: 'Estrela Dalva' },
    { codigoTOM: 4493, codigoIBGE: 3124708, nomeTOM: 'ESTRELA DO INDAIÁ', nomeIBGE: 'Estrela do Indaiá' },
    { codigoTOM: 4495, codigoIBGE: 3124807, nomeTOM: 'ESTRELA DO SUL', nomeIBGE: 'Estrela do Sul' },
    { codigoTOM: 4497, codigoIBGE: 3124906, nomeTOM: 'EUGENÓPOLIS', nomeIBGE: 'Eugenópolis' },
    { codigoTOM: 4499, codigoIBGE: 3125002, nomeTOM: 'EWBANK DA CÂMARA', nomeIBGE: 'Ewbank da Câmara' },
    { codigoTOM: 4501, codigoIBGE: 3125101, nomeTOM: 'EXTREMA', nomeIBGE: 'Extrema' },
    { codigoTOM: 4503, codigoIBGE: 3125200, nomeTOM: 'FAMA', nomeIBGE: 'Fama' },
    { codigoTOM: 4505, codigoIBGE: 3125309, nomeTOM: 'FARIA LEMOS', nomeIBGE: 'Faria Lemos' },
    { codigoTOM: 4507, codigoIBGE: 3125408, nomeTOM: 'FELÍCIO DOS SANTOS', nomeIBGE: 'Felício dos Santos' },
    { codigoTOM: 4509, codigoIBGE: 3125507, nomeTOM: 'SÃO GONÇALO DO RIO PRETO', nomeIBGE: 'São Gonçalo do Rio Preto' },
    { codigoTOM: 4511, codigoIBGE: 3125606, nomeTOM: 'FELISBURGO', nomeIBGE: 'Felisburgo' },
    { codigoTOM: 4513, codigoIBGE: 3125705, nomeTOM: 'FELIXLÂNDIA', nomeIBGE: 'Felixlândia' },
    { codigoTOM: 4515, codigoIBGE: 3125804, nomeTOM: 'FERNANDES TOURINHO', nomeIBGE: 'Fernandes Tourinho' },
    { codigoTOM: 4517, codigoIBGE: 3125903, nomeTOM: 'FERROS', nomeIBGE: 'Ferros' },
    { codigoTOM: 4519, codigoIBGE: 3126000, nomeTOM: 'FLORESTAL', nomeIBGE: 'Florestal' },
    { codigoTOM: 4521, codigoIBGE: 3126109, nomeTOM: 'FORMIGA', nomeIBGE: 'Formiga' },
    { codigoTOM: 4523, codigoIBGE: 3126208, nomeTOM: 'FORMOSO', nomeIBGE: 'Formoso' },
    { codigoTOM: 4525, codigoIBGE: 3126307, nomeTOM: 'FORTALEZA DE MINAS', nomeIBGE: 'Fortaleza de Minas' },
    { codigoTOM: 4527, codigoIBGE: 3126406, nomeTOM: 'FORTUNA DE MINAS', nomeIBGE: 'Fortuna de Minas' },
    { codigoTOM: 4529, codigoIBGE: 3126505, nomeTOM: 'FRANCISCO BADARÓ', nomeIBGE: 'Francisco Badaró' },
    { codigoTOM: 4531, codigoIBGE: 3126604, nomeTOM: 'FRANCISCO DUMONT', nomeIBGE: 'Francisco Dumont' },
    { codigoTOM: 4533, codigoIBGE: 3126703, nomeTOM: 'FRANCISCO SÁ', nomeIBGE: 'Francisco Sá' },
    { codigoTOM: 4535, codigoIBGE: 3126802, nomeTOM: 'FREI GASPAR', nomeIBGE: 'Frei Gaspar' },
    { codigoTOM: 4537, codigoIBGE: 3126901, nomeTOM: 'FREI INOCÊNCIO', nomeIBGE: 'Frei Inocêncio' },
    { codigoTOM: 4539, codigoIBGE: 3127008, nomeTOM: 'FRONTEIRA', nomeIBGE: 'Fronteira' },
    { codigoTOM: 4541, codigoIBGE: 3127107, nomeTOM: 'FRUTAL', nomeIBGE: 'Frutal' },
    { codigoTOM: 4543, codigoIBGE: 3127206, nomeTOM: 'FUNILÂNDIA', nomeIBGE: 'Funilândia' },
    { codigoTOM: 4545, codigoIBGE: 3127305, nomeTOM: 'GALILÉIA', nomeIBGE: 'Galiléia' },
    { codigoTOM: 4547, codigoIBGE: 3127404, nomeTOM: 'GONÇALVES', nomeIBGE: 'Gonçalves' },
    { codigoTOM: 4549, codigoIBGE: 3127503, nomeTOM: 'GONZAGA', nomeIBGE: 'Gonzaga' },
    { codigoTOM: 4551, codigoIBGE: 3127602, nomeTOM: 'Gouveia', nomeIBGE: 'Gouveia' },
    { codigoTOM: 4553, codigoIBGE: 3127701, nomeTOM: 'GOVERNADOR VALADARES', nomeIBGE: 'Governador Valadares' },
    { codigoTOM: 4555, codigoIBGE: 3127800, nomeTOM: 'GRÃO MOGOL', nomeIBGE: 'Grão Mogol' },
    { codigoTOM: 4557, codigoIBGE: 3127909, nomeTOM: 'GRUPIARA', nomeIBGE: 'Grupiara' },
    { codigoTOM: 4559, codigoIBGE: 3128006, nomeTOM: 'GUANHÃES', nomeIBGE: 'Guanhães' },
    { codigoTOM: 4561, codigoIBGE: 3128105, nomeTOM: 'GUAPÉ', nomeIBGE: 'Guapé' },
    { codigoTOM: 4563, codigoIBGE: 3128204, nomeTOM: 'GUARACIABA', nomeIBGE: 'Guaraciaba' },
    { codigoTOM: 4565, codigoIBGE: 3128303, nomeTOM: 'GUARANÉSIA', nomeIBGE: 'Guaranésia' },
    { codigoTOM: 4567, codigoIBGE: 3128402, nomeTOM: 'GUARANI', nomeIBGE: 'Guarani' },
    { codigoTOM: 4569, codigoIBGE: 3128501, nomeTOM: 'GUARARÁ', nomeIBGE: 'Guarará' },
    { codigoTOM: 4571, codigoIBGE: 3128600, nomeTOM: 'GUARDA-MOR', nomeIBGE: 'Guarda-Mor' },
    { codigoTOM: 4573, codigoIBGE: 3128709, nomeTOM: 'GUAXUPÉ', nomeIBGE: 'Guaxupé' },
    { codigoTOM: 4575, codigoIBGE: 3128808, nomeTOM: 'GUIDOVAL', nomeIBGE: 'Guidoval' },
    { codigoTOM: 4577, codigoIBGE: 3128907, nomeTOM: 'GUIMARÂNIA', nomeIBGE: 'Guimarânia' },
    { codigoTOM: 4579, codigoIBGE: 3129004, nomeTOM: 'GUIRICEMA', nomeIBGE: 'Guiricema' },
    { codigoTOM: 4581, codigoIBGE: 3129103, nomeTOM: 'GURINHATÃ', nomeIBGE: 'Gurinhatã' },
    { codigoTOM: 4583, codigoIBGE: 3129202, nomeTOM: 'HELIODORA', nomeIBGE: 'Heliodora' },
    { codigoTOM: 4585, codigoIBGE: 3129301, nomeTOM: 'IAPU', nomeIBGE: 'Iapu' },
    { codigoTOM: 4587, codigoIBGE: 3129400, nomeTOM: 'IBERTIOGA', nomeIBGE: 'Ibertioga' },
    { codigoTOM: 4589, codigoIBGE: 3129509, nomeTOM: 'IBIÁ', nomeIBGE: 'Ibiá' },
    { codigoTOM: 4591, codigoIBGE: 3129608, nomeTOM: 'IBIAÍ', nomeIBGE: 'Ibiaí' },
    { codigoTOM: 4593, codigoIBGE: 3129707, nomeTOM: 'IBIRACI', nomeIBGE: 'Ibiraci' },
    { codigoTOM: 4595, codigoIBGE: 3129806, nomeTOM: 'IBIRITÉ', nomeIBGE: 'Ibirité' },
    { codigoTOM: 4597, codigoIBGE: 3129905, nomeTOM: 'IBITIÚRA DE MINAS', nomeIBGE: 'Ibitiúra de Minas' },
    { codigoTOM: 4599, codigoIBGE: 3130002, nomeTOM: 'IBITURUNA', nomeIBGE: 'Ibituruna' },
    { codigoTOM: 4601, codigoIBGE: 3130101, nomeTOM: 'IGARAPÉ', nomeIBGE: 'Igarapé' },
    { codigoTOM: 4603, codigoIBGE: 3130200, nomeTOM: 'IGARATINGA', nomeIBGE: 'Igaratinga' },
    { codigoTOM: 4605, codigoIBGE: 3130309, nomeTOM: 'IGUATAMA', nomeIBGE: 'Iguatama' },
    { codigoTOM: 4607, codigoIBGE: 3130408, nomeTOM: 'IJACI', nomeIBGE: 'Ijaci' },
    { codigoTOM: 4609, codigoIBGE: 3130507, nomeTOM: 'ILICÍNEA', nomeIBGE: 'Ilicínea' },
    { codigoTOM: 4611, codigoIBGE: 3130606, nomeTOM: 'INCONFIDENTES', nomeIBGE: 'Inconfidentes' },
    { codigoTOM: 4613, codigoIBGE: 3130705, nomeTOM: 'INDIANÓPOLIS', nomeIBGE: 'Indianópolis' },
    { codigoTOM: 4615, codigoIBGE: 3130804, nomeTOM: 'INGAÍ', nomeIBGE: 'Ingaí' },
    { codigoTOM: 4617, codigoIBGE: 3130903, nomeTOM: 'INHAPIM', nomeIBGE: 'Inhapim' },
    { codigoTOM: 4619, codigoIBGE: 3131000, nomeTOM: 'INHAÚMA', nomeIBGE: 'Inhaúma' },
    { codigoTOM: 4621, codigoIBGE: 3131109, nomeTOM: 'INIMUTABA', nomeIBGE: 'Inimutaba' },
    { codigoTOM: 4623, codigoIBGE: 3131208, nomeTOM: 'IPANEMA', nomeIBGE: 'Ipanema' },
    { codigoTOM: 4625, codigoIBGE: 3131307, nomeTOM: 'IPATINGA', nomeIBGE: 'Ipatinga' },
    { codigoTOM: 4627, codigoIBGE: 3131406, nomeTOM: 'IPIAÇU', nomeIBGE: 'Ipiaçu' },
    { codigoTOM: 4629, codigoIBGE: 3131505, nomeTOM: 'IPUIÚNA', nomeIBGE: 'Ipuiúna' },
    { codigoTOM: 4631, codigoIBGE: 3131604, nomeTOM: 'IRAÍ DE MINAS', nomeIBGE: 'Iraí de Minas' },
    { codigoTOM: 4633, codigoIBGE: 3131703, nomeTOM: 'ITABIRA', nomeIBGE: 'Itabira' },
    { codigoTOM: 4635, codigoIBGE: 3131802, nomeTOM: 'ITABIRINHA', nomeIBGE: 'Itabirinha' },
    { codigoTOM: 4637, codigoIBGE: 3131901, nomeTOM: 'ITABIRITO', nomeIBGE: 'Itabirito' },
    { codigoTOM: 4639, codigoIBGE: 3132008, nomeTOM: 'ITACAMBIRA', nomeIBGE: 'Itacambira' },
    { codigoTOM: 4641, codigoIBGE: 3132107, nomeTOM: 'ITACARAMBI', nomeIBGE: 'Itacarambi' },
    { codigoTOM: 4643, codigoIBGE: 3132206, nomeTOM: 'ITAGUARA', nomeIBGE: 'Itaguara' },
    { codigoTOM: 4645, codigoIBGE: 3132305, nomeTOM: 'ITAIPÉ', nomeIBGE: 'Itaipé' },
    { codigoTOM: 4647, codigoIBGE: 3132404, nomeTOM: 'ITAJUBÁ', nomeIBGE: 'Itajubá' },
    { codigoTOM: 4649, codigoIBGE: 3132503, nomeTOM: 'ITAMARANDIBA', nomeIBGE: 'Itamarandiba' },
    { codigoTOM: 4651, codigoIBGE: 3132602, nomeTOM: 'ITAMARATI DE MINAS', nomeIBGE: 'Itamarati de Minas' },
    { codigoTOM: 4653, codigoIBGE: 3132701, nomeTOM: 'ITAMBACURI', nomeIBGE: 'Itambacuri' },
    { codigoTOM: 4655, codigoIBGE: 3132800, nomeTOM: 'ITAMBÉ DO MATO DENTRO', nomeIBGE: 'Itambé do Mato Dentro' },
    { codigoTOM: 4657, codigoIBGE: 3132909, nomeTOM: 'ITAMOGI', nomeIBGE: 'Itamogi' },
    { codigoTOM: 4659, codigoIBGE: 3133006, nomeTOM: 'ITAMONTE', nomeIBGE: 'Itamonte' },
    { codigoTOM: 4661, codigoIBGE: 3133105, nomeTOM: 'ITANHANDU', nomeIBGE: 'Itanhandu' },
    { codigoTOM: 4663, codigoIBGE: 3133204, nomeTOM: 'ITANHOMI', nomeIBGE: 'Itanhomi' },
    { codigoTOM: 4665, codigoIBGE: 3133303, nomeTOM: 'ITAOBIM', nomeIBGE: 'Itaobim' },
    { codigoTOM: 4667, codigoIBGE: 3133402, nomeTOM: 'ITAPAGIPE', nomeIBGE: 'Itapagipe' },
    { codigoTOM: 4669, codigoIBGE: 3133501, nomeTOM: 'ITAPECERICA', nomeIBGE: 'Itapecerica' },
    { codigoTOM: 4671, codigoIBGE: 3133600, nomeTOM: 'ITAPEVA', nomeIBGE: 'Itapeva' },
    { codigoTOM: 4673, codigoIBGE: 3133709, nomeTOM: 'ITATIAIUÇU', nomeIBGE: 'Itatiaiuçu' },
    { codigoTOM: 4675, codigoIBGE: 3133808, nomeTOM: 'ITAÚNA', nomeIBGE: 'Itaúna' },
    { codigoTOM: 4677, codigoIBGE: 3133907, nomeTOM: 'ITAVERAVA', nomeIBGE: 'Itaverava' },
    { codigoTOM: 4679, codigoIBGE: 3134004, nomeTOM: 'ITINGA', nomeIBGE: 'Itinga' },
    { codigoTOM: 4681, codigoIBGE: 3134103, nomeTOM: 'ITUETA', nomeIBGE: 'Itueta' },
    { codigoTOM: 4683, codigoIBGE: 3134202, nomeTOM: 'ITUIUTABA', nomeIBGE: 'Ituiutaba' },
    { codigoTOM: 4685, codigoIBGE: 3134301, nomeTOM: 'ITUMIRIM', nomeIBGE: 'Itumirim' },
    { codigoTOM: 4687, codigoIBGE: 3134400, nomeTOM: 'ITURAMA', nomeIBGE: 'Iturama' },
    { codigoTOM: 4689, codigoIBGE: 3134509, nomeTOM: 'ITUTINGA', nomeIBGE: 'Itutinga' },
    { codigoTOM: 4691, codigoIBGE: 3134608, nomeTOM: 'JABOTICATUBAS', nomeIBGE: 'Jaboticatubas' },
    { codigoTOM: 4693, codigoIBGE: 3134707, nomeTOM: 'JACINTO', nomeIBGE: 'Jacinto' },
    { codigoTOM: 4695, codigoIBGE: 3134806, nomeTOM: 'JACUÍ', nomeIBGE: 'Jacuí' },
    { codigoTOM: 4697, codigoIBGE: 3134905, nomeTOM: 'JACUTINGA', nomeIBGE: 'Jacutinga' },
    { codigoTOM: 4699, codigoIBGE: 3135001, nomeTOM: 'JAGUARAÇU', nomeIBGE: 'Jaguaraçu' },
    { codigoTOM: 4701, codigoIBGE: 3135100, nomeTOM: 'JANAÚBA', nomeIBGE: 'Janaúba' },
    { codigoTOM: 4703, codigoIBGE: 3135209, nomeTOM: 'JANUÁRIA', nomeIBGE: 'Januária' },
    { codigoTOM: 4705, codigoIBGE: 3135308, nomeTOM: 'JAPARAÍBA', nomeIBGE: 'Japaraíba' },
    { codigoTOM: 4707, codigoIBGE: 3135407, nomeTOM: 'JECEABA', nomeIBGE: 'Jeceaba' },
    { codigoTOM: 4709, codigoIBGE: 3135506, nomeTOM: 'JEQUERI', nomeIBGE: 'Jequeri' },
    { codigoTOM: 4711, codigoIBGE: 3135605, nomeTOM: 'JEQUITAÍ', nomeIBGE: 'Jequitaí' },
    { codigoTOM: 4713, codigoIBGE: 3135704, nomeTOM: 'JEQUITIBÁ', nomeIBGE: 'Jequitibá' },
    { codigoTOM: 4715, codigoIBGE: 3135803, nomeTOM: 'JEQUITINHONHA', nomeIBGE: 'Jequitinhonha' },
    { codigoTOM: 4717, codigoIBGE: 3135902, nomeTOM: 'JESUÂNIA', nomeIBGE: 'Jesuânia' },
    { codigoTOM: 4719, codigoIBGE: 3136009, nomeTOM: 'JOAÍMA', nomeIBGE: 'Joaíma' },
    { codigoTOM: 4721, codigoIBGE: 3136108, nomeTOM: 'JOANÉSIA', nomeIBGE: 'Joanésia' },
    { codigoTOM: 4723, codigoIBGE: 3136207, nomeTOM: 'JOÃO MONLEVADE', nomeIBGE: 'João Monlevade' },
    { codigoTOM: 4725, codigoIBGE: 3136306, nomeTOM: 'JOÃO PINHEIRO', nomeIBGE: 'João Pinheiro' },
    { codigoTOM: 4727, codigoIBGE: 3136405, nomeTOM: 'JOAQUIM FELÍCIO', nomeIBGE: 'Joaquim Felício' },
    { codigoTOM: 4729, codigoIBGE: 3136504, nomeTOM: 'JORDÂNIA', nomeIBGE: 'Jordânia' },
    { codigoTOM: 4731, codigoIBGE: 3136603, nomeTOM: 'NOVA UNIÃO', nomeIBGE: 'Nova União' },
    { codigoTOM: 4733, codigoIBGE: 3136702, nomeTOM: 'JUIZ DE FORA', nomeIBGE: 'Juiz de Fora' },
    { codigoTOM: 4735, codigoIBGE: 3136801, nomeTOM: 'JURAMENTO', nomeIBGE: 'Juramento' },
    { codigoTOM: 4737, codigoIBGE: 3136900, nomeTOM: 'JURUAIA', nomeIBGE: 'Juruaia' },
    { codigoTOM: 4739, codigoIBGE: 3137007, nomeTOM: 'LADAINHA', nomeIBGE: 'Ladainha' },
    { codigoTOM: 4741, codigoIBGE: 3137106, nomeTOM: 'LAGAMAR', nomeIBGE: 'Lagamar' },
    { codigoTOM: 4743, codigoIBGE: 3137205, nomeTOM: 'LAGOA DA PRATA', nomeIBGE: 'Lagoa da Prata' },
    { codigoTOM: 4745, codigoIBGE: 3137304, nomeTOM: 'LAGOA DOS PATOS', nomeIBGE: 'Lagoa dos Patos' },
    { codigoTOM: 4747, codigoIBGE: 3137403, nomeTOM: 'LAGOA DOURADA', nomeIBGE: 'Lagoa Dourada' },
    { codigoTOM: 4749, codigoIBGE: 3137502, nomeTOM: 'LAGOA FORMOSA', nomeIBGE: 'Lagoa Formosa' },
    { codigoTOM: 4751, codigoIBGE: 3137601, nomeTOM: 'LAGOA SANTA', nomeIBGE: 'Lagoa Santa' },
    { codigoTOM: 4753, codigoIBGE: 3137700, nomeTOM: 'LAJINHA', nomeIBGE: 'Lajinha' },
    { codigoTOM: 4755, codigoIBGE: 3137809, nomeTOM: 'LAMBARI', nomeIBGE: 'Lambari' },
    { codigoTOM: 4757, codigoIBGE: 3137908, nomeTOM: 'LAMIM', nomeIBGE: 'Lamim' },
    { codigoTOM: 4759, codigoIBGE: 3138005, nomeTOM: 'LARANJAL', nomeIBGE: 'Laranjal' },
    { codigoTOM: 4761, codigoIBGE: 3138104, nomeTOM: 'LASSANCE', nomeIBGE: 'Lassance' },
    { codigoTOM: 4763, codigoIBGE: 3138203, nomeTOM: 'LAVRAS', nomeIBGE: 'Lavras' },
    { codigoTOM: 4765, codigoIBGE: 3138302, nomeTOM: 'LEANDRO FERREIRA', nomeIBGE: 'Leandro Ferreira' },
    { codigoTOM: 4767, codigoIBGE: 3138401, nomeTOM: 'LEOPOLDINA', nomeIBGE: 'Leopoldina' },
    { codigoTOM: 4769, codigoIBGE: 3138500, nomeTOM: 'LIBERDADE', nomeIBGE: 'Liberdade' },
    { codigoTOM: 4771, codigoIBGE: 3138609, nomeTOM: 'LIMA DUARTE', nomeIBGE: 'Lima Duarte' },
    { codigoTOM: 4773, codigoIBGE: 3138708, nomeTOM: 'LUMINÁRIAS', nomeIBGE: 'Luminárias' },
    { codigoTOM: 4775, codigoIBGE: 3138807, nomeTOM: 'LUZ', nomeIBGE: 'Luz' },
    { codigoTOM: 4777, codigoIBGE: 3138906, nomeTOM: 'MACHACALIS', nomeIBGE: 'Machacalis' },
    { codigoTOM: 4779, codigoIBGE: 3139003, nomeTOM: 'MACHADO', nomeIBGE: 'Machado' },
    { codigoTOM: 4781, codigoIBGE: 3139102, nomeTOM: 'MADRE DE DEUS DE MINAS', nomeIBGE: 'Madre de Deus de Minas' },
    { codigoTOM: 4783, codigoIBGE: 3139201, nomeTOM: 'MALACACHETA', nomeIBGE: 'Malacacheta' },
    { codigoTOM: 4785, codigoIBGE: 3139300, nomeTOM: 'MANGA', nomeIBGE: 'Manga' },
    { codigoTOM: 4787, codigoIBGE: 3139409, nomeTOM: 'MANHUAÇU', nomeIBGE: 'Manhuaçu' },
    { codigoTOM: 4789, codigoIBGE: 3139508, nomeTOM: 'MANHUMIRIM', nomeIBGE: 'Manhumirim' },
    { codigoTOM: 4791, codigoIBGE: 3139607, nomeTOM: 'MANTENA', nomeIBGE: 'Mantena' },
    { codigoTOM: 4793, codigoIBGE: 3139706, nomeTOM: 'MARAVILHAS', nomeIBGE: 'Maravilhas' },
    { codigoTOM: 4795, codigoIBGE: 3139805, nomeTOM: 'MAR DE ESPANHA', nomeIBGE: 'Mar de Espanha' },
    { codigoTOM: 4797, codigoIBGE: 3139904, nomeTOM: 'MARIA DA FÉ', nomeIBGE: 'Maria da Fé' },
    { codigoTOM: 4799, codigoIBGE: 3140001, nomeTOM: 'MARIANA', nomeIBGE: 'Mariana' },
    { codigoTOM: 4801, codigoIBGE: 3140100, nomeTOM: 'MARILAC', nomeIBGE: 'Marilac' },
    { codigoTOM: 4803, codigoIBGE: 3140209, nomeTOM: 'MARIPÁ DE MINAS', nomeIBGE: 'Maripá de Minas' },
    { codigoTOM: 4805, codigoIBGE: 3140308, nomeTOM: 'MARLIÉRIA', nomeIBGE: 'Marliéria' },
    { codigoTOM: 4807, codigoIBGE: 3140407, nomeTOM: 'MARMELÓPOLIS', nomeIBGE: 'Marmelópolis' },
    { codigoTOM: 4809, codigoIBGE: 3140506, nomeTOM: 'MARTINHO CAMPOS', nomeIBGE: 'Martinho Campos' },
    { codigoTOM: 4811, codigoIBGE: 3140605, nomeTOM: 'MATERLÂNDIA', nomeIBGE: 'Materlândia' },
    { codigoTOM: 4813, codigoIBGE: 3140704, nomeTOM: 'MATEUS LEME', nomeIBGE: 'Mateus Leme' },
    { codigoTOM: 4815, codigoIBGE: 3140803, nomeTOM: 'MATIAS BARBOSA', nomeIBGE: 'Matias Barbosa' },
    { codigoTOM: 4817, codigoIBGE: 3140902, nomeTOM: 'MATIPÓ', nomeIBGE: 'Matipó' },
    { codigoTOM: 4819, codigoIBGE: 3141009, nomeTOM: 'MATO VERDE', nomeIBGE: 'Mato Verde' },
    { codigoTOM: 4821, codigoIBGE: 3141108, nomeTOM: 'MATOZINHOS', nomeIBGE: 'Matozinhos' },
    { codigoTOM: 4823, codigoIBGE: 3141207, nomeTOM: 'MATUTINA', nomeIBGE: 'Matutina' },
    { codigoTOM: 4825, codigoIBGE: 3141306, nomeTOM: 'MEDEIROS', nomeIBGE: 'Medeiros' },
    { codigoTOM: 4827, codigoIBGE: 3141405, nomeTOM: 'MEDINA', nomeIBGE: 'Medina' },
    { codigoTOM: 4829, codigoIBGE: 3141504, nomeTOM: 'MENDES PIMENTEL', nomeIBGE: 'Mendes Pimentel' },
    { codigoTOM: 4831, codigoIBGE: 3141603, nomeTOM: 'MERCÊS', nomeIBGE: 'Mercês' },
    { codigoTOM: 4833, codigoIBGE: 3141702, nomeTOM: 'MESQUITA', nomeIBGE: 'Mesquita' },
    { codigoTOM: 4835, codigoIBGE: 3141801, nomeTOM: 'MINAS NOVAS', nomeIBGE: 'Minas Novas' },
    { codigoTOM: 4837, codigoIBGE: 3141900, nomeTOM: 'MINDURI', nomeIBGE: 'Minduri' },
    { codigoTOM: 4839, codigoIBGE: 3142007, nomeTOM: 'MIRABELA', nomeIBGE: 'Mirabela' },
    { codigoTOM: 4841, codigoIBGE: 3142106, nomeTOM: 'MIRADOURO', nomeIBGE: 'Miradouro' },
    { codigoTOM: 4843, codigoIBGE: 3142205, nomeTOM: 'MIRAÍ', nomeIBGE: 'Miraí' },
    { codigoTOM: 4845, codigoIBGE: 3142304, nomeTOM: 'MOEDA', nomeIBGE: 'Moeda' },
    { codigoTOM: 4847, codigoIBGE: 3142403, nomeTOM: 'MOEMA', nomeIBGE: 'Moema' },
    { codigoTOM: 4849, codigoIBGE: 3142502, nomeTOM: 'MONJOLOS', nomeIBGE: 'Monjolos' },
    { codigoTOM: 4851, codigoIBGE: 3142601, nomeTOM: 'MONSENHOR PAULO', nomeIBGE: 'Monsenhor Paulo' },
    { codigoTOM: 4853, codigoIBGE: 3142700, nomeTOM: 'MONTALVÂNIA', nomeIBGE: 'Montalvânia' },
    { codigoTOM: 4855, codigoIBGE: 3142809, nomeTOM: 'MONTE ALEGRE DE MINAS', nomeIBGE: 'Monte Alegre de Minas' },
    { codigoTOM: 4857, codigoIBGE: 3142908, nomeTOM: 'MONTE AZUL', nomeIBGE: 'Monte Azul' },
    { codigoTOM: 4859, codigoIBGE: 3143005, nomeTOM: 'MONTE BELO', nomeIBGE: 'Monte Belo' },
    { codigoTOM: 4861, codigoIBGE: 3143104, nomeTOM: 'MONTE CARMELO', nomeIBGE: 'Monte Carmelo' },
    { codigoTOM: 4863, codigoIBGE: 3143203, nomeTOM: 'MONTE SANTO DE MINAS', nomeIBGE: 'Monte Santo de Minas' },
    { codigoTOM: 4865, codigoIBGE: 3143302, nomeTOM: 'MONTES CLAROS', nomeIBGE: 'Montes Claros' },
    { codigoTOM: 4867, codigoIBGE: 3143401, nomeTOM: 'MONTE SIÃO', nomeIBGE: 'Monte Sião' },
    { codigoTOM: 4869, codigoIBGE: 3143500, nomeTOM: 'MORADA NOVA DE MINAS', nomeIBGE: 'Morada Nova de Minas' },
    { codigoTOM: 4871, codigoIBGE: 3143609, nomeTOM: 'MORRO DA GARÇA', nomeIBGE: 'Morro da Garça' },
    { codigoTOM: 4873, codigoIBGE: 3143708, nomeTOM: 'MORRO DO PILAR', nomeIBGE: 'Morro do Pilar' },
    { codigoTOM: 4875, codigoIBGE: 3143807, nomeTOM: 'MUNHOZ', nomeIBGE: 'Munhoz' },
    { codigoTOM: 4877, codigoIBGE: 3143906, nomeTOM: 'MURIAÉ', nomeIBGE: 'Muriaé' },
    { codigoTOM: 4879, codigoIBGE: 3144003, nomeTOM: 'MUTUM', nomeIBGE: 'Mutum' },
    { codigoTOM: 4881, codigoIBGE: 3144102, nomeTOM: 'MUZAMBINHO', nomeIBGE: 'Muzambinho' },
    { codigoTOM: 4883, codigoIBGE: 3144201, nomeTOM: 'NACIP RAYDAN', nomeIBGE: 'Nacip Raydan' },
    { codigoTOM: 4885, codigoIBGE: 3144300, nomeTOM: 'NANUQUE', nomeIBGE: 'Nanuque' },
    { codigoTOM: 4887, codigoIBGE: 3144409, nomeTOM: 'NATÉRCIA', nomeIBGE: 'Natércia' },
    { codigoTOM: 4889, codigoIBGE: 3144508, nomeTOM: 'NAZARENO', nomeIBGE: 'Nazareno' },
    { codigoTOM: 4891, codigoIBGE: 3144607, nomeTOM: 'NEPOMUCENO', nomeIBGE: 'Nepomuceno' },
    { codigoTOM: 4893, codigoIBGE: 3144706, nomeTOM: 'NOVA ERA', nomeIBGE: 'Nova Era' },
    { codigoTOM: 4895, codigoIBGE: 3144805, nomeTOM: 'NOVA LIMA', nomeIBGE: 'Nova Lima' },
    { codigoTOM: 4897, codigoIBGE: 3144904, nomeTOM: 'NOVA MÓDICA', nomeIBGE: 'Nova Módica' },
    { codigoTOM: 4899, codigoIBGE: 3145000, nomeTOM: 'NOVA PONTE', nomeIBGE: 'Nova Ponte' },
    { codigoTOM: 4901, codigoIBGE: 3145109, nomeTOM: 'NOVA RESENDE', nomeIBGE: 'Nova Resende' },
    { codigoTOM: 4903, codigoIBGE: 3145208, nomeTOM: 'NOVA SERRANA', nomeIBGE: 'Nova Serrana' },
    { codigoTOM: 4905, codigoIBGE: 3145307, nomeTOM: 'NOVO CRUZEIRO', nomeIBGE: 'Novo Cruzeiro' },
    { codigoTOM: 4907, codigoIBGE: 3145406, nomeTOM: 'OLARIA', nomeIBGE: 'Olaria' },
    { codigoTOM: 4909, codigoIBGE: 3145505, nomeTOM: 'OLÍMPIO NORONHA', nomeIBGE: 'Olímpio Noronha' },
    { codigoTOM: 4911, codigoIBGE: 3145604, nomeTOM: 'OLIVEIRA', nomeIBGE: 'Oliveira' },
    { codigoTOM: 4913, codigoIBGE: 3145703, nomeTOM: 'OLIVEIRA FORTES', nomeIBGE: 'Oliveira Fortes' },
    { codigoTOM: 4915, codigoIBGE: 3145802, nomeTOM: 'ONÇA DE PITANGUI', nomeIBGE: 'Onça de Pitangui' },
    { codigoTOM: 4917, codigoIBGE: 3145901, nomeTOM: 'OURO BRANCO', nomeIBGE: 'Ouro Branco' },
    { codigoTOM: 4919, codigoIBGE: 3146008, nomeTOM: 'OURO FINO', nomeIBGE: 'Ouro Fino' },
    { codigoTOM: 4921, codigoIBGE: 3146107, nomeTOM: 'OURO PRETO', nomeIBGE: 'Ouro Preto' },
    { codigoTOM: 4923, codigoIBGE: 3146206, nomeTOM: 'OURO VERDE DE MINAS', nomeIBGE: 'Ouro Verde de Minas' },
    { codigoTOM: 4925, codigoIBGE: 3146305, nomeTOM: 'PADRE PARAÍSO', nomeIBGE: 'Padre Paraíso' },
    { codigoTOM: 4927, codigoIBGE: 3146404, nomeTOM: 'PAINEIRAS', nomeIBGE: 'Paineiras' },
    { codigoTOM: 4929, codigoIBGE: 3146503, nomeTOM: 'PAINS', nomeIBGE: 'Pains' },
    { codigoTOM: 4931, codigoIBGE: 3146602, nomeTOM: 'PAIVA', nomeIBGE: 'Paiva' },
    { codigoTOM: 4933, codigoIBGE: 3146701, nomeTOM: 'PALMA', nomeIBGE: 'Palma' },
    { codigoTOM: 4935, codigoIBGE: 3127057, nomeTOM: 'FRONTEIRA DOS VALES', nomeIBGE: 'Fronteira dos Vales' },
    { codigoTOM: 4937, codigoIBGE: 3146909, nomeTOM: 'PAPAGAIOS', nomeIBGE: 'Papagaios' },
    { codigoTOM: 4939, codigoIBGE: 3147006, nomeTOM: 'PARACATU', nomeIBGE: 'Paracatu' },
    { codigoTOM: 4941, codigoIBGE: 3147105, nomeTOM: 'PARÁ DE MINAS', nomeIBGE: 'Pará de Minas' },
    { codigoTOM: 4943, codigoIBGE: 3147204, nomeTOM: 'PARAGUAÇU', nomeIBGE: 'Paraguaçu' },
    { codigoTOM: 4945, codigoIBGE: 3147303, nomeTOM: 'PARAISÓPOLIS', nomeIBGE: 'Paraisópolis' },
    { codigoTOM: 4947, codigoIBGE: 3147402, nomeTOM: 'PARAOPEBA', nomeIBGE: 'Paraopeba' },
    { codigoTOM: 4949, codigoIBGE: 3147501, nomeTOM: 'PASSABÉM', nomeIBGE: 'Passabém' },
    { codigoTOM: 4951, codigoIBGE: 3147600, nomeTOM: 'PASSA QUATRO', nomeIBGE: 'Passa Quatro' },
    { codigoTOM: 4953, codigoIBGE: 3147709, nomeTOM: 'PASSA TEMPO', nomeIBGE: 'Passa Tempo' },
    { codigoTOM: 4955, codigoIBGE: 3147808, nomeTOM: 'PASSA VINTE', nomeIBGE: 'Passa Vinte' },
    { codigoTOM: 4957, codigoIBGE: 3147907, nomeTOM: 'PASSOS', nomeIBGE: 'Passos' },
    { codigoTOM: 4959, codigoIBGE: 3148004, nomeTOM: 'PATOS DE MINAS', nomeIBGE: 'Patos de Minas' },
    { codigoTOM: 4961, codigoIBGE: 3148103, nomeTOM: 'PATROCÍNIO', nomeIBGE: 'Patrocínio' },
    { codigoTOM: 4963, codigoIBGE: 3148202, nomeTOM: 'PATROCÍNIO DO MURIAÉ', nomeIBGE: 'Patrocínio do Muriaé' },
    { codigoTOM: 4965, codigoIBGE: 3148301, nomeTOM: 'PAULA CÂNDIDO', nomeIBGE: 'Paula Cândido' },
    { codigoTOM: 4967, codigoIBGE: 3148400, nomeTOM: 'PAULISTAS', nomeIBGE: 'Paulistas' },
    { codigoTOM: 4969, codigoIBGE: 3148509, nomeTOM: 'PAVÃO', nomeIBGE: 'Pavão' },
    { codigoTOM: 4971, codigoIBGE: 3148608, nomeTOM: 'PEÇANHA', nomeIBGE: 'Peçanha' },
    { codigoTOM: 4973, codigoIBGE: 3148707, nomeTOM: 'PEDRA AZUL', nomeIBGE: 'Pedra Azul' },
    { codigoTOM: 4975, codigoIBGE: 3148806, nomeTOM: 'PEDRA DO ANTA', nomeIBGE: 'Pedra do Anta' },
    { codigoTOM: 4977, codigoIBGE: 3148905, nomeTOM: 'PEDRA DO INDAIÁ', nomeIBGE: 'Pedra do Indaiá' },
    { codigoTOM: 4979, codigoIBGE: 3149002, nomeTOM: 'PEDRA DOURADA', nomeIBGE: 'Pedra Dourada' },
    { codigoTOM: 4981, codigoIBGE: 3149101, nomeTOM: 'PEDRALVA', nomeIBGE: 'Pedralva' },
    { codigoTOM: 4983, codigoIBGE: 3149200, nomeTOM: 'PEDRINÓPOLIS', nomeIBGE: 'Pedrinópolis' },
    { codigoTOM: 4985, codigoIBGE: 3149309, nomeTOM: 'PEDRO LEOPOLDO', nomeIBGE: 'Pedro Leopoldo' },
    { codigoTOM: 4987, codigoIBGE: 3149408, nomeTOM: 'PEDRO TEIXEIRA', nomeIBGE: 'Pedro Teixeira' },
    { codigoTOM: 4989, codigoIBGE: 3149507, nomeTOM: 'PEQUERI', nomeIBGE: 'Pequeri' },
    { codigoTOM: 4991, codigoIBGE: 3149606, nomeTOM: 'PEQUI', nomeIBGE: 'Pequi' },
    { codigoTOM: 4993, codigoIBGE: 3149705, nomeTOM: 'PERDIGÃO', nomeIBGE: 'Perdigão' },
    { codigoTOM: 4995, codigoIBGE: 3149804, nomeTOM: 'PERDIZES', nomeIBGE: 'Perdizes' },
    { codigoTOM: 4997, codigoIBGE: 3149903, nomeTOM: 'PERDÕES', nomeIBGE: 'Perdões' },
    { codigoTOM: 4999, codigoIBGE: 3150000, nomeTOM: 'PESCADOR', nomeIBGE: 'Pescador' },
    { codigoTOM: 5001, codigoIBGE: 3150109, nomeTOM: 'PIAU', nomeIBGE: 'Piau' },
    { codigoTOM: 5003, codigoIBGE: 3150208, nomeTOM: 'PIEDADE DE PONTE NOVA', nomeIBGE: 'Piedade de Ponte Nova' },
    { codigoTOM: 5005, codigoIBGE: 3150307, nomeTOM: 'PIEDADE DO RIO GRANDE', nomeIBGE: 'Piedade do Rio Grande' },
    { codigoTOM: 5007, codigoIBGE: 3150406, nomeTOM: 'PIEDADE DOS GERAIS', nomeIBGE: 'Piedade dos Gerais' },
    { codigoTOM: 5009, codigoIBGE: 3150505, nomeTOM: 'PIMENTA', nomeIBGE: 'Pimenta' },
    { codigoTOM: 5011, codigoIBGE: 3150604, nomeTOM: 'PIRACEMA', nomeIBGE: 'Piracema' },
    { codigoTOM: 5013, codigoIBGE: 3150703, nomeTOM: 'PIRAJUBA', nomeIBGE: 'Pirajuba' },
    { codigoTOM: 5015, codigoIBGE: 3150802, nomeTOM: 'PIRANGA', nomeIBGE: 'Piranga' },
    { codigoTOM: 5017, codigoIBGE: 3150901, nomeTOM: 'PIRANGUÇU', nomeIBGE: 'Piranguçu' },
    { codigoTOM: 5019, codigoIBGE: 3151008, nomeTOM: 'PIRANGUINHO', nomeIBGE: 'Piranguinho' },
    { codigoTOM: 5021, codigoIBGE: 3151107, nomeTOM: 'PIRAPETINGA', nomeIBGE: 'Pirapetinga' },
    { codigoTOM: 5023, codigoIBGE: 3151206, nomeTOM: 'PIRAPORA', nomeIBGE: 'Pirapora' },
    { codigoTOM: 5025, codigoIBGE: 3151305, nomeTOM: 'PIRAÚBA', nomeIBGE: 'Piraúba' },
    { codigoTOM: 5027, codigoIBGE: 3151404, nomeTOM: 'PITANGUI', nomeIBGE: 'Pitangui' },
    { codigoTOM: 5029, codigoIBGE: 3151503, nomeTOM: 'PIUMHI', nomeIBGE: 'Piumhi' },
    { codigoTOM: 5031, codigoIBGE: 3151602, nomeTOM: 'PLANURA', nomeIBGE: 'Planura' },
    { codigoTOM: 5033, codigoIBGE: 3151701, nomeTOM: 'POÇO FUNDO', nomeIBGE: 'Poço Fundo' },
    { codigoTOM: 5035, codigoIBGE: 3151800, nomeTOM: 'POÇOS DE CALDAS', nomeIBGE: 'Poços de Caldas' },
    { codigoTOM: 5037, codigoIBGE: 3151909, nomeTOM: 'POCRANE', nomeIBGE: 'Pocrane' },
    { codigoTOM: 5039, codigoIBGE: 3152006, nomeTOM: 'POMPÉU', nomeIBGE: 'Pompéu' },
    { codigoTOM: 5041, codigoIBGE: 3152105, nomeTOM: 'PONTE NOVA', nomeIBGE: 'Ponte Nova' },
    { codigoTOM: 5043, codigoIBGE: 3152204, nomeTOM: 'PORTEIRINHA', nomeIBGE: 'Porteirinha' },
    { codigoTOM: 5045, codigoIBGE: 3152303, nomeTOM: 'PORTO FIRME', nomeIBGE: 'Porto Firme' },
    { codigoTOM: 5047, codigoIBGE: 3152402, nomeTOM: 'POTÉ', nomeIBGE: 'Poté' },
    { codigoTOM: 5049, codigoIBGE: 3152501, nomeTOM: 'POUSO ALEGRE', nomeIBGE: 'Pouso Alegre' },
    { codigoTOM: 5051, codigoIBGE: 3152600, nomeTOM: 'POUSO ALTO', nomeIBGE: 'Pouso Alto' },
    { codigoTOM: 5053, codigoIBGE: 3152709, nomeTOM: 'PRADOS', nomeIBGE: 'Prados' },
    { codigoTOM: 5055, codigoIBGE: 3152808, nomeTOM: 'PRATA', nomeIBGE: 'Prata' },
    { codigoTOM: 5057, codigoIBGE: 3152907, nomeTOM: 'PRATÁPOLIS', nomeIBGE: 'Pratápolis' },
    { codigoTOM: 5059, codigoIBGE: 3153004, nomeTOM: 'PRATINHA', nomeIBGE: 'Pratinha' },
    { codigoTOM: 5061, codigoIBGE: 3153103, nomeTOM: 'PRESIDENTE BERNARDES', nomeIBGE: 'Presidente Bernardes' },
    { codigoTOM: 5063, codigoIBGE: 3153202, nomeTOM: 'PRESIDENTE JUSCELINO', nomeIBGE: 'Presidente Juscelino' },
    { codigoTOM: 5065, codigoIBGE: 3153301, nomeTOM: 'PRESIDENTE KUBITSCHEK', nomeIBGE: 'Presidente Kubitschek' },
    { codigoTOM: 5067, codigoIBGE: 3153400, nomeTOM: 'PRESIDENTE OLEGÁRIO', nomeIBGE: 'Presidente Olegário' },
    { codigoTOM: 5069, codigoIBGE: 3153509, nomeTOM: 'ALTO JEQUITIBÁ', nomeIBGE: 'Alto Jequitibá' },
    { codigoTOM: 5071, codigoIBGE: 3153608, nomeTOM: 'PRUDENTE DE MORAIS', nomeIBGE: 'Prudente de Morais' },
    { codigoTOM: 5073, codigoIBGE: 3153707, nomeTOM: 'QUARTEL GERAL', nomeIBGE: 'Quartel Geral' },
    { codigoTOM: 5075, codigoIBGE: 3153806, nomeTOM: 'Queluzito', nomeIBGE: 'Queluzito' },
    { codigoTOM: 5077, codigoIBGE: 3153905, nomeTOM: 'RAPOSOS', nomeIBGE: 'Raposos' },
    { codigoTOM: 5079, codigoIBGE: 3154002, nomeTOM: 'RAUL SOARES', nomeIBGE: 'Raul Soares' },
    { codigoTOM: 5081, codigoIBGE: 3154101, nomeTOM: 'RECREIO', nomeIBGE: 'Recreio' },
    { codigoTOM: 5083, codigoIBGE: 3154200, nomeTOM: 'RESENDE COSTA', nomeIBGE: 'Resende Costa' },
    { codigoTOM: 5085, codigoIBGE: 3154309, nomeTOM: 'RESPLENDOR', nomeIBGE: 'Resplendor' },
    { codigoTOM: 5087, codigoIBGE: 3154408, nomeTOM: 'RESSAQUINHA', nomeIBGE: 'Ressaquinha' },
    { codigoTOM: 5089, codigoIBGE: 3154507, nomeTOM: 'RIACHO DOS MACHADOS', nomeIBGE: 'Riacho dos Machados' },
    { codigoTOM: 5091, codigoIBGE: 3154606, nomeTOM: 'RIBEIRÃO DAS NEVES', nomeIBGE: 'Ribeirão das Neves' },
    { codigoTOM: 5093, codigoIBGE: 3154705, nomeTOM: 'RIBEIRÃO VERMELHO', nomeIBGE: 'Ribeirão Vermelho' },
    { codigoTOM: 5095, codigoIBGE: 3154804, nomeTOM: 'RIO ACIMA', nomeIBGE: 'Rio Acima' },
    { codigoTOM: 5097, codigoIBGE: 3154903, nomeTOM: 'RIO CASCA', nomeIBGE: 'Rio Casca' },
    { codigoTOM: 5099, codigoIBGE: 3155009, nomeTOM: 'RIO DOCE', nomeIBGE: 'Rio Doce' },
    { codigoTOM: 5101, codigoIBGE: 3155108, nomeTOM: 'RIO DO PRADO', nomeIBGE: 'Rio do Prado' },
    { codigoTOM: 5103, codigoIBGE: 3155207, nomeTOM: 'RIO ESPERA', nomeIBGE: 'Rio Espera' },
    { codigoTOM: 5105, codigoIBGE: 3155306, nomeTOM: 'RIO MANSO', nomeIBGE: 'Rio Manso' },
    { codigoTOM: 5107, codigoIBGE: 3155405, nomeTOM: 'RIO NOVO', nomeIBGE: 'Rio Novo' },
    { codigoTOM: 5109, codigoIBGE: 3155504, nomeTOM: 'RIO PARANAÍBA', nomeIBGE: 'Rio Paranaíba' },
    { codigoTOM: 5111, codigoIBGE: 3155603, nomeTOM: 'RIO PARDO DE MINAS', nomeIBGE: 'Rio Pardo de Minas' },
    { codigoTOM: 5113, codigoIBGE: 3155702, nomeTOM: 'RIO PIRACICABA', nomeIBGE: 'Rio Piracicaba' },
    { codigoTOM: 5115, codigoIBGE: 3155801, nomeTOM: 'RIO POMBA', nomeIBGE: 'Rio Pomba' },
    { codigoTOM: 5117, codigoIBGE: 3155900, nomeTOM: 'RIO PRETO', nomeIBGE: 'Rio Preto' },
    { codigoTOM: 5119, codigoIBGE: 3156007, nomeTOM: 'RIO VERMELHO', nomeIBGE: 'Rio Vermelho' },
    { codigoTOM: 5121, codigoIBGE: 3156106, nomeTOM: 'RITÁPOLIS', nomeIBGE: 'Ritápolis' },
    { codigoTOM: 5123, codigoIBGE: 3156205, nomeTOM: 'ROCHEDO DE MINAS', nomeIBGE: 'Rochedo de Minas' },
    { codigoTOM: 5125, codigoIBGE: 3156304, nomeTOM: 'RODEIRO', nomeIBGE: 'Rodeiro' },
    { codigoTOM: 5127, codigoIBGE: 3156403, nomeTOM: 'ROMARIA', nomeIBGE: 'Romaria' },
    { codigoTOM: 5129, codigoIBGE: 3156502, nomeTOM: 'RUBELITA', nomeIBGE: 'Rubelita' },
    { codigoTOM: 5131, codigoIBGE: 3156601, nomeTOM: 'RUBIM', nomeIBGE: 'Rubim' },
    { codigoTOM: 5133, codigoIBGE: 3156700, nomeTOM: 'SABARÁ', nomeIBGE: 'Sabará' },
    { codigoTOM: 5135, codigoIBGE: 3156809, nomeTOM: 'SABINÓPOLIS', nomeIBGE: 'Sabinópolis' },
    { codigoTOM: 5137, codigoIBGE: 3156908, nomeTOM: 'SACRAMENTO', nomeIBGE: 'Sacramento' },
    { codigoTOM: 5139, codigoIBGE: 3157005, nomeTOM: 'SALINAS', nomeIBGE: 'Salinas' },
    { codigoTOM: 5141, codigoIBGE: 3157104, nomeTOM: 'SALTO DA DIVISA', nomeIBGE: 'Salto da Divisa' },
    { codigoTOM: 5143, codigoIBGE: 3157203, nomeTOM: 'SANTA BÁRBARA', nomeIBGE: 'Santa Bárbara' },
    { codigoTOM: 5145, codigoIBGE: 3157302, nomeTOM: 'SANTA BÁRBARA DO TUGÚRIO', nomeIBGE: 'Santa Bárbara do Tugúrio' },
    { codigoTOM: 5147, codigoIBGE: 3157401, nomeTOM: 'SANTA CRUZ DO ESCALVADO', nomeIBGE: 'Santa Cruz do Escalvado' },
    { codigoTOM: 5149, codigoIBGE: 3157500, nomeTOM: 'SANTA EFIGÊNIA DE MINAS', nomeIBGE: 'Santa Efigênia de Minas' },
    { codigoTOM: 5151, codigoIBGE: 3157609, nomeTOM: 'SANTA FÉ DE MINAS', nomeIBGE: 'Santa Fé de Minas' },
    { codigoTOM: 5153, codigoIBGE: 3157708, nomeTOM: 'SANTA JULIANA', nomeIBGE: 'Santa Juliana' },
    { codigoTOM: 5155, codigoIBGE: 3157807, nomeTOM: 'SANTA LUZIA', nomeIBGE: 'Santa Luzia' },
    { codigoTOM: 5157, codigoIBGE: 3157906, nomeTOM: 'SANTA MARGARIDA', nomeIBGE: 'Santa Margarida' },
    { codigoTOM: 5159, codigoIBGE: 3158003, nomeTOM: 'SANTA MARIA DE ITABIRA', nomeIBGE: 'Santa Maria de Itabira' },
    { codigoTOM: 5161, codigoIBGE: 3158102, nomeTOM: 'SANTA MARIA DO SALTO', nomeIBGE: 'Santa Maria do Salto' },
    { codigoTOM: 5163, codigoIBGE: 3158201, nomeTOM: 'SANTA MARIA DO SUAÇUÍ', nomeIBGE: 'Santa Maria do Suaçuí' },
    { codigoTOM: 5165, codigoIBGE: 3158300, nomeTOM: 'SANTANA DA VARGEM', nomeIBGE: 'Santana da Vargem' },
    { codigoTOM: 5167, codigoIBGE: 3158409, nomeTOM: 'SANTANA DE CATAGUASES', nomeIBGE: 'Santana de Cataguases' },
    { codigoTOM: 5169, codigoIBGE: 3158508, nomeTOM: 'SANTANA DE PIRAPAMA', nomeIBGE: 'Santana de Pirapama' },
    { codigoTOM: 5171, codigoIBGE: 3158607, nomeTOM: 'SANTANA DO DESERTO', nomeIBGE: 'Santana do Deserto' },
    { codigoTOM: 5173, codigoIBGE: 3158706, nomeTOM: 'SANTANA DO GARAMBÉU', nomeIBGE: 'Santana do Garambéu' },
    { codigoTOM: 5175, codigoIBGE: 3158805, nomeTOM: 'SANTANA DO JACARÉ', nomeIBGE: 'Santana do Jacaré' },
    { codigoTOM: 5177, codigoIBGE: 3158904, nomeTOM: 'SANTANA DO MANHUAÇU', nomeIBGE: 'Santana do Manhuaçu' },
    { codigoTOM: 5179, codigoIBGE: 3159001, nomeTOM: 'SANTANA DO RIACHO', nomeIBGE: 'Santana do Riacho' },
    { codigoTOM: 5181, codigoIBGE: 3159100, nomeTOM: 'SANTANA DOS MONTES', nomeIBGE: 'Santana dos Montes' },
    { codigoTOM: 5183, codigoIBGE: 3159209, nomeTOM: 'SANTA RITA DE CALDAS', nomeIBGE: 'Santa Rita de Caldas' },
    { codigoTOM: 5185, codigoIBGE: 3159308, nomeTOM: 'SANTA RITA DE JACUTINGA', nomeIBGE: 'Santa Rita de Jacutinga' },
    { codigoTOM: 5187, codigoIBGE: 3159407, nomeTOM: 'SANTA RITA DE IBITIPOCA', nomeIBGE: 'Santa Rita de Ibitipoca' },
    { codigoTOM: 5189, codigoIBGE: 3159506, nomeTOM: 'SANTA RITA DO ITUETO', nomeIBGE: 'Santa Rita do Itueto' },
    { codigoTOM: 5191, codigoIBGE: 3159605, nomeTOM: 'SANTA RITA DO SAPUCAÍ', nomeIBGE: 'Santa Rita do Sapucaí' },
    { codigoTOM: 5193, codigoIBGE: 3159704, nomeTOM: 'SANTA ROSA DA SERRA', nomeIBGE: 'Santa Rosa da Serra' },
    { codigoTOM: 5195, codigoIBGE: 3159803, nomeTOM: 'SANTA VITÓRIA', nomeIBGE: 'Santa Vitória' },
    { codigoTOM: 5197, codigoIBGE: 3159902, nomeTOM: 'SANTO ANTÔNIO DO AMPARO', nomeIBGE: 'Santo Antônio do Amparo' },
    {
      codigoTOM: 5199,
      codigoIBGE: 3160009,
      nomeTOM: 'SANTO ANTÔNIO DO AVENTUREIRO',
      nomeIBGE: 'Santo Antônio do Aventureiro',
    },
    { codigoTOM: 5201, codigoIBGE: 3160108, nomeTOM: 'SANTO ANTÔNIO DO GRAMA', nomeIBGE: 'Santo Antônio do Grama' },
    { codigoTOM: 5203, codigoIBGE: 3160207, nomeTOM: 'SANTO ANTÔNIO DO ITAMBÉ', nomeIBGE: 'Santo Antônio do Itambé' },
    { codigoTOM: 5205, codigoIBGE: 3160306, nomeTOM: 'SANTO ANTÔNIO DO JACINTO', nomeIBGE: 'Santo Antônio do Jacinto' },
    { codigoTOM: 5207, codigoIBGE: 3160405, nomeTOM: 'SANTO ANTÔNIO DO MONTE', nomeIBGE: 'Santo Antônio do Monte' },
    {
      codigoTOM: 5209,
      codigoIBGE: 3160504,
      nomeTOM: 'SANTO ANTÔNIO DO RIO ABAIXO',
      nomeIBGE: 'Santo Antônio do Rio Abaixo',
    },
    { codigoTOM: 5211, codigoIBGE: 3160603, nomeTOM: 'SANTO HIPÓLITO', nomeIBGE: 'Santo Hipólito' },
    { codigoTOM: 5213, codigoIBGE: 3160702, nomeTOM: 'SANTOS DUMONT', nomeIBGE: 'Santos Dumont' },
    { codigoTOM: 5215, codigoIBGE: 3160801, nomeTOM: 'SÃO BENTO ABADE', nomeIBGE: 'São Bento Abade' },
    { codigoTOM: 5217, codigoIBGE: 3160900, nomeTOM: 'SÃO BRÁS DO SUAÇUÍ', nomeIBGE: 'São Brás do Suaçuí' },
    { codigoTOM: 5219, codigoIBGE: 3161007, nomeTOM: 'SÃO DOMINGOS DO PRATA', nomeIBGE: 'São Domingos do Prata' },
    { codigoTOM: 5221, codigoIBGE: 3161106, nomeTOM: 'SÃO FRANCISCO', nomeIBGE: 'São Francisco' },
    { codigoTOM: 5223, codigoIBGE: 3161205, nomeTOM: 'SÃO FRANCISCO DE PAULA', nomeIBGE: 'São Francisco de Paula' },
    { codigoTOM: 5225, codigoIBGE: 3161304, nomeTOM: 'SÃO FRANCISCO DE SALES', nomeIBGE: 'São Francisco de Sales' },
    { codigoTOM: 5227, codigoIBGE: 3161403, nomeTOM: 'SÃO FRANCISCO DO GLÓRIA', nomeIBGE: 'São Francisco do Glória' },
    { codigoTOM: 5229, codigoIBGE: 3161502, nomeTOM: 'SÃO GERALDO', nomeIBGE: 'São Geraldo' },
    { codigoTOM: 5231, codigoIBGE: 3161601, nomeTOM: 'SÃO GERALDO DA PIEDADE', nomeIBGE: 'São Geraldo da Piedade' },
    { codigoTOM: 5233, codigoIBGE: 3161700, nomeTOM: 'SÃO GONÇALO DO ABAETÉ', nomeIBGE: 'São Gonçalo do Abaeté' },
    { codigoTOM: 5235, codigoIBGE: 3161809, nomeTOM: 'SÃO GONÇALO DO PARÁ', nomeIBGE: 'São Gonçalo do Pará' },
    {
      codigoTOM: 5237,
      codigoIBGE: 3161908,
      nomeTOM: 'SÃO GONÇALO DO RIO ABAIXO',
      nomeIBGE: 'São Gonçalo do Rio Abaixo',
    },
    { codigoTOM: 5239, codigoIBGE: 3162005, nomeTOM: 'SÃO GONÇALO DO SAPUCAÍ', nomeIBGE: 'São Gonçalo do Sapucaí' },
    { codigoTOM: 5241, codigoIBGE: 3162104, nomeTOM: 'SÃO GOTARDO', nomeIBGE: 'São Gotardo' },
    {
      codigoTOM: 5243,
      codigoIBGE: 3162203,
      nomeTOM: 'SÃO JOÃO BATISTA DO GLÓRIA',
      nomeIBGE: 'São João Batista do Glória',
    },
    { codigoTOM: 5245, codigoIBGE: 3162302, nomeTOM: 'SÃO JOÃO DA MATA', nomeIBGE: 'São João da Mata' },
    { codigoTOM: 5247, codigoIBGE: 3162401, nomeTOM: 'SÃO JOÃO DA PONTE', nomeIBGE: 'São João da Ponte' },
    { codigoTOM: 5249, codigoIBGE: 3162500, nomeTOM: 'SÃO JOÃO DEL REI', nomeIBGE: 'São João del Rei' },
    { codigoTOM: 5251, codigoIBGE: 3162609, nomeTOM: 'SÃO JOÃO DO ORIENTE', nomeIBGE: 'São João do Oriente' },
    { codigoTOM: 5253, codigoIBGE: 3162708, nomeTOM: 'SÃO JOÃO DO PARAÍSO', nomeIBGE: 'São João do Paraíso' },
    { codigoTOM: 5255, codigoIBGE: 3162807, nomeTOM: 'SÃO JOÃO EVANGELISTA', nomeIBGE: 'São João Evangelista' },
    { codigoTOM: 5257, codigoIBGE: 3162906, nomeTOM: 'SÃO JOÃO NEPOMUCENO', nomeIBGE: 'São João Nepomuceno' },
    { codigoTOM: 5259, codigoIBGE: 3163003, nomeTOM: 'SÃO JOSÉ DA SAFIRA', nomeIBGE: 'São José da Safira' },
    { codigoTOM: 5261, codigoIBGE: 3163102, nomeTOM: 'SÃO JOSÉ DA VARGINHA', nomeIBGE: 'São José da Varginha' },
    { codigoTOM: 5263, codigoIBGE: 3163201, nomeTOM: 'SÃO JOSÉ DO ALEGRE', nomeIBGE: 'São José do Alegre' },
    { codigoTOM: 5265, codigoIBGE: 3163300, nomeTOM: 'SÃO JOSÉ DO DIVINO', nomeIBGE: 'São José do Divino' },
    { codigoTOM: 5267, codigoIBGE: 3163409, nomeTOM: 'SÃO JOSÉ DO GOIABAL', nomeIBGE: 'São José do Goiabal' },
    { codigoTOM: 5269, codigoIBGE: 3163508, nomeTOM: 'SÃO JOSÉ DO JACURI', nomeIBGE: 'São José do Jacuri' },
    { codigoTOM: 5271, codigoIBGE: 3163607, nomeTOM: 'SÃO JOSÉ DO MANTIMENTO', nomeIBGE: 'São José do Mantimento' },
    { codigoTOM: 5273, codigoIBGE: 3163706, nomeTOM: 'SÃO LOURENÇO', nomeIBGE: 'São Lourenço' },
    { codigoTOM: 5275, codigoIBGE: 3163805, nomeTOM: 'SÃO MIGUEL DO ANTA', nomeIBGE: 'São Miguel do Anta' },
    { codigoTOM: 5277, codigoIBGE: 3163904, nomeTOM: 'SÃO PEDRO DA UNIÃO', nomeIBGE: 'São Pedro da União' },
    { codigoTOM: 5279, codigoIBGE: 3164001, nomeTOM: 'SÃO PEDRO DOS FERROS', nomeIBGE: 'São Pedro dos Ferros' },
    { codigoTOM: 5281, codigoIBGE: 3164100, nomeTOM: 'SÃO PEDRO DO SUAÇUÍ', nomeIBGE: 'São Pedro do Suaçuí' },
    { codigoTOM: 5283, codigoIBGE: 3164209, nomeTOM: 'SÃO ROMÃO', nomeIBGE: 'São Romão' },
    { codigoTOM: 5285, codigoIBGE: 3164308, nomeTOM: 'SÃO ROQUE DE MINAS', nomeIBGE: 'São Roque de Minas' },
    {
      codigoTOM: 5287,
      codigoIBGE: 3164407,
      nomeTOM: 'SÃO SEBASTIÃO DA BELA VISTA',
      nomeIBGE: 'São Sebastião da Bela Vista',
    },
    {
      codigoTOM: 5289,
      codigoIBGE: 3164506,
      nomeTOM: 'SÃO SEBASTIÃO DO MARANHÃO',
      nomeIBGE: 'São Sebastião do Maranhão',
    },
    { codigoTOM: 5291, codigoIBGE: 3164605, nomeTOM: 'SÃO SEBASTIÃO DO OESTE', nomeIBGE: 'São Sebastião do Oeste' },
    { codigoTOM: 5293, codigoIBGE: 3164704, nomeTOM: 'SÃO SEBASTIÃO DO PARAÍSO', nomeIBGE: 'São Sebastião do Paraíso' },
    {
      codigoTOM: 5295,
      codigoIBGE: 3164803,
      nomeTOM: 'SÃO SEBASTIÃO DO RIO PRETO',
      nomeIBGE: 'São Sebastião do Rio Preto',
    },
    {
      codigoTOM: 5297,
      codigoIBGE: 3164902,
      nomeTOM: 'SÃO SEBASTIÃO DO RIO VERDE',
      nomeIBGE: 'São Sebastião do Rio Verde',
    },
    { codigoTOM: 5299, codigoIBGE: 3165008, nomeTOM: 'SÃO TIAGO', nomeIBGE: 'São Tiago' },
    { codigoTOM: 5301, codigoIBGE: 3165107, nomeTOM: 'SÃO TOMÁS DE AQUINO', nomeIBGE: 'São Tomás de Aquino' },
    { codigoTOM: 5303, codigoIBGE: 3165206, nomeTOM: 'SÃO TOMÉ DAS LETRAS', nomeIBGE: 'São Tomé das Letras' },
    { codigoTOM: 5305, codigoIBGE: 3165305, nomeTOM: 'SÃO VICENTE DE MINAS', nomeIBGE: 'São Vicente de Minas' },
    { codigoTOM: 5307, codigoIBGE: 3165404, nomeTOM: 'SAPUCAÍ-MIRIM', nomeIBGE: 'Sapucaí-Mirim' },
    { codigoTOM: 5309, codigoIBGE: 3165503, nomeTOM: 'SARDOÁ', nomeIBGE: 'Sardoá' },
    { codigoTOM: 5311, codigoIBGE: 3165602, nomeTOM: 'SENADOR CORTES', nomeIBGE: 'Senador Cortes' },
    { codigoTOM: 5313, codigoIBGE: 3165701, nomeTOM: 'SENADOR FIRMINO', nomeIBGE: 'Senador Firmino' },
    { codigoTOM: 5315, codigoIBGE: 3165800, nomeTOM: 'SENADOR JOSÉ BENTO', nomeIBGE: 'Senador José Bento' },
    {
      codigoTOM: 5317,
      codigoIBGE: 3165909,
      nomeTOM: 'SENADOR MODESTINO GONÇALVES',
      nomeIBGE: 'Senador Modestino Gonçalves',
    },
    { codigoTOM: 5319, codigoIBGE: 3166006, nomeTOM: 'SENHORA DE OLIVEIRA', nomeIBGE: 'Senhora de Oliveira' },
    { codigoTOM: 5321, codigoIBGE: 3166105, nomeTOM: 'SENHORA DO PORTO', nomeIBGE: 'Senhora do Porto' },
    { codigoTOM: 5323, codigoIBGE: 3166204, nomeTOM: 'SENHORA DOS REMÉDIOS', nomeIBGE: 'Senhora dos Remédios' },
    { codigoTOM: 5325, codigoIBGE: 3166303, nomeTOM: 'SERICITA', nomeIBGE: 'Sericita' },
    { codigoTOM: 5327, codigoIBGE: 3166402, nomeTOM: 'SERITINGA', nomeIBGE: 'Seritinga' },
    { codigoTOM: 5329, codigoIBGE: 3166501, nomeTOM: 'SERRA AZUL DE MINAS', nomeIBGE: 'Serra Azul de Minas' },
    { codigoTOM: 5331, codigoIBGE: 3166600, nomeTOM: 'SERRA DA SAUDADE', nomeIBGE: 'Serra da Saudade' },
    { codigoTOM: 5333, codigoIBGE: 3166709, nomeTOM: 'SERRA DOS AIMORÉS', nomeIBGE: 'Serra dos Aimorés' },
    { codigoTOM: 5335, codigoIBGE: 3166808, nomeTOM: 'SERRA DO SALITRE', nomeIBGE: 'Serra do Salitre' },
    { codigoTOM: 5337, codigoIBGE: 3166907, nomeTOM: 'SERRANIA', nomeIBGE: 'Serrania' },
    { codigoTOM: 5339, codigoIBGE: 3167004, nomeTOM: 'SERRANOS', nomeIBGE: 'Serranos' },
    { codigoTOM: 5341, codigoIBGE: 3167103, nomeTOM: 'SERRO', nomeIBGE: 'Serro' },
    { codigoTOM: 5343, codigoIBGE: 3167202, nomeTOM: 'SETE LAGOAS', nomeIBGE: 'Sete Lagoas' },
    { codigoTOM: 5345, codigoIBGE: 3167301, nomeTOM: 'SILVEIRÂNIA', nomeIBGE: 'Silveirânia' },
    { codigoTOM: 5347, codigoIBGE: 3167400, nomeTOM: 'SILVIANÓPOLIS', nomeIBGE: 'Silvianópolis' },
    { codigoTOM: 5349, codigoIBGE: 3167509, nomeTOM: 'SIMÃO PEREIRA', nomeIBGE: 'Simão Pereira' },
    { codigoTOM: 5351, codigoIBGE: 3167608, nomeTOM: 'SIMONÉSIA', nomeIBGE: 'Simonésia' },
    { codigoTOM: 5353, codigoIBGE: 3167707, nomeTOM: 'SOBRÁLIA', nomeIBGE: 'Sobrália' },
    { codigoTOM: 5355, codigoIBGE: 3167806, nomeTOM: 'SOLEDADE DE MINAS', nomeIBGE: 'Soledade de Minas' },
    { codigoTOM: 5357, codigoIBGE: 3167905, nomeTOM: 'TABULEIRO', nomeIBGE: 'Tabuleiro' },
    { codigoTOM: 5359, codigoIBGE: 3168002, nomeTOM: 'TAIOBEIRAS', nomeIBGE: 'Taiobeiras' },
    { codigoTOM: 5361, codigoIBGE: 3168101, nomeTOM: 'TAPIRA', nomeIBGE: 'Tapira' },
    { codigoTOM: 5363, codigoIBGE: 3168200, nomeTOM: 'TAPIRAÍ', nomeIBGE: 'Tapiraí' },
    { codigoTOM: 5365, codigoIBGE: 3168309, nomeTOM: 'TAQUARAÇU DE MINAS', nomeIBGE: 'Taquaraçu de Minas' },
    { codigoTOM: 5367, codigoIBGE: 3168408, nomeTOM: 'TARUMIRIM', nomeIBGE: 'Tarumirim' },
    { codigoTOM: 5369, codigoIBGE: 3168507, nomeTOM: 'TEIXEIRAS', nomeIBGE: 'Teixeiras' },
    { codigoTOM: 5371, codigoIBGE: 3168606, nomeTOM: 'TEÓFILO OTONI', nomeIBGE: 'Teófilo Otoni' },
    { codigoTOM: 5373, codigoIBGE: 3168705, nomeTOM: 'TIMÓTEO', nomeIBGE: 'Timóteo' },
    { codigoTOM: 5375, codigoIBGE: 3168804, nomeTOM: 'TIRADENTES', nomeIBGE: 'Tiradentes' },
    { codigoTOM: 5377, codigoIBGE: 3168903, nomeTOM: 'TIROS', nomeIBGE: 'Tiros' },
    { codigoTOM: 5379, codigoIBGE: 3169000, nomeTOM: 'TOCANTINS', nomeIBGE: 'Tocantins' },
    { codigoTOM: 5381, codigoIBGE: 3169109, nomeTOM: 'TOLEDO', nomeIBGE: 'Toledo' },
    { codigoTOM: 5383, codigoIBGE: 3169208, nomeTOM: 'TOMBOS', nomeIBGE: 'Tombos' },
    { codigoTOM: 5385, codigoIBGE: 3169307, nomeTOM: 'TRÊS CORAÇÕES', nomeIBGE: 'Três Corações' },
    { codigoTOM: 5387, codigoIBGE: 3169406, nomeTOM: 'TRÊS PONTAS', nomeIBGE: 'Três Pontas' },
    { codigoTOM: 5389, codigoIBGE: 3169505, nomeTOM: 'TUMIRITINGA', nomeIBGE: 'Tumiritinga' },
    { codigoTOM: 5391, codigoIBGE: 3169604, nomeTOM: 'TUPACIGUARA', nomeIBGE: 'Tupaciguara' },
    { codigoTOM: 5393, codigoIBGE: 3169703, nomeTOM: 'TURMALINA', nomeIBGE: 'Turmalina' },
    { codigoTOM: 5395, codigoIBGE: 3169802, nomeTOM: 'TURVOLÂNDIA', nomeIBGE: 'Turvolândia' },
    { codigoTOM: 5397, codigoIBGE: 3169901, nomeTOM: 'UBÁ', nomeIBGE: 'Ubá' },
    { codigoTOM: 5399, codigoIBGE: 3170008, nomeTOM: 'UBAÍ', nomeIBGE: 'Ubaí' },
    { codigoTOM: 5401, codigoIBGE: 3170107, nomeTOM: 'UBERABA', nomeIBGE: 'Uberaba' },
    { codigoTOM: 5403, codigoIBGE: 3170206, nomeTOM: 'UBERLÂNDIA', nomeIBGE: 'Uberlândia' },
    { codigoTOM: 5405, codigoIBGE: 3170305, nomeTOM: 'UMBURATIBA', nomeIBGE: 'Umburatiba' },
    { codigoTOM: 5407, codigoIBGE: 3170404, nomeTOM: 'UNAÍ', nomeIBGE: 'Unaí' },
    { codigoTOM: 5409, codigoIBGE: 3170503, nomeTOM: 'URUCÂNIA', nomeIBGE: 'Urucânia' },
    { codigoTOM: 5411, codigoIBGE: 3170602, nomeTOM: 'VARGEM BONITA', nomeIBGE: 'Vargem Bonita' },
    { codigoTOM: 5413, codigoIBGE: 3170701, nomeTOM: 'VARGINHA', nomeIBGE: 'Varginha' },
    { codigoTOM: 5415, codigoIBGE: 3170800, nomeTOM: 'VÁRZEA DA PALMA', nomeIBGE: 'Várzea da Palma' },
    { codigoTOM: 5417, codigoIBGE: 3170909, nomeTOM: 'VARZELÂNDIA', nomeIBGE: 'Varzelândia' },
    { codigoTOM: 5419, codigoIBGE: 3171006, nomeTOM: 'VAZANTE', nomeIBGE: 'Vazante' },
    { codigoTOM: 5421, codigoIBGE: 3172202, nomeTOM: 'WENCESLAU BRAZ', nomeIBGE: 'Wenceslau Braz' },
    { codigoTOM: 5423, codigoIBGE: 3171105, nomeTOM: 'VERÍSSIMO', nomeIBGE: 'Veríssimo' },
    { codigoTOM: 5425, codigoIBGE: 3171204, nomeTOM: 'VESPASIANO', nomeIBGE: 'Vespasiano' },
    { codigoTOM: 5427, codigoIBGE: 3171303, nomeTOM: 'VIÇOSA', nomeIBGE: 'Viçosa' },
    { codigoTOM: 5429, codigoIBGE: 3171402, nomeTOM: 'VIEIRAS', nomeIBGE: 'Vieiras' },
    { codigoTOM: 5431, codigoIBGE: 3171501, nomeTOM: 'MATHIAS LOBATO', nomeIBGE: 'Mathias Lobato' },
    { codigoTOM: 5433, codigoIBGE: 3171600, nomeTOM: 'VIRGEM DA LAPA', nomeIBGE: 'Virgem da Lapa' },
    { codigoTOM: 5435, codigoIBGE: 3171709, nomeTOM: 'VIRGÍNIA', nomeIBGE: 'Virgínia' },
    { codigoTOM: 5437, codigoIBGE: 3171808, nomeTOM: 'VIRGINÓPOLIS', nomeIBGE: 'Virginópolis' },
    { codigoTOM: 5439, codigoIBGE: 3171907, nomeTOM: 'VIRGOLÂNDIA', nomeIBGE: 'Virgolândia' },
    { codigoTOM: 5441, codigoIBGE: 3172004, nomeTOM: 'VISCONDE DO RIO BRANCO', nomeIBGE: 'Visconde do Rio Branco' },
    { codigoTOM: 5443, codigoIBGE: 3172103, nomeTOM: 'VOLTA GRANDE', nomeIBGE: 'Volta Grande' },
    { codigoTOM: 5731, codigoIBGE: 3133758, nomeTOM: 'ITAÚ DE MINAS', nomeIBGE: 'Itaú de Minas' },
  ],
  [Estados.ES]: [
    { codigoTOM: 758, codigoIBGE: 3201159, nomeTOM: 'BREJETUBA', nomeIBGE: 'Brejetuba' },
    { codigoTOM: 760, codigoIBGE: 3203320, nomeTOM: 'MARATAÍZES', nomeIBGE: 'Marataízes' },
    { codigoTOM: 762, codigoIBGE: 3204252, nomeTOM: 'PONTO BELO', nomeIBGE: 'Ponto Belo' },
    { codigoTOM: 764, codigoIBGE: 3204955, nomeTOM: 'SÃO ROQUE DO CANAÃ', nomeIBGE: 'São Roque do Canaã' },
    { codigoTOM: 766, codigoIBGE: 3205010, nomeTOM: 'SOORETAMA', nomeIBGE: 'Sooretama' },
    { codigoTOM: 768, codigoIBGE: 3205176, nomeTOM: 'VILA VALÉRIO', nomeIBGE: 'Vila Valério' },
    { codigoTOM: 2929, codigoIBGE: 3203346, nomeTOM: 'MARECHAL FLORIANO', nomeIBGE: 'Marechal Floriano' },
    { codigoTOM: 2931, codigoIBGE: 3202652, nomeTOM: 'IRUPI', nomeIBGE: 'Irupi' },
    { codigoTOM: 2933, codigoIBGE: 3204658, nomeTOM: 'SÃO DOMINGOS DO NORTE', nomeIBGE: 'São Domingos do Norte' },
    { codigoTOM: 2935, codigoIBGE: 3205150, nomeTOM: 'VILA PAVÃO', nomeIBGE: 'Vila Pavão' },
    { codigoTOM: 5601, codigoIBGE: 3200102, nomeTOM: 'AFONSO CLÁUDIO', nomeIBGE: 'Afonso Cláudio' },
    { codigoTOM: 5603, codigoIBGE: 3200201, nomeTOM: 'ALEGRE', nomeIBGE: 'Alegre' },
    { codigoTOM: 5605, codigoIBGE: 3200300, nomeTOM: 'ALFREDO CHAVES', nomeIBGE: 'Alfredo Chaves' },
    { codigoTOM: 5607, codigoIBGE: 3200409, nomeTOM: 'ANCHIETA', nomeIBGE: 'Anchieta' },
    { codigoTOM: 5609, codigoIBGE: 3200508, nomeTOM: 'APIACÁ', nomeIBGE: 'Apiacá' },
    { codigoTOM: 5611, codigoIBGE: 3200607, nomeTOM: 'ARACRUZ', nomeIBGE: 'Aracruz' },
    { codigoTOM: 5613, codigoIBGE: 3200706, nomeTOM: 'ATILIO VIVACQUA', nomeIBGE: 'Atílio Vivácqua' },
    { codigoTOM: 5615, codigoIBGE: 3200805, nomeTOM: 'BAIXO GUANDU', nomeIBGE: 'Baixo Guandu' },
    { codigoTOM: 5617, codigoIBGE: 3200904, nomeTOM: 'BARRA DE SÃO FRANCISCO', nomeIBGE: 'Barra de São Francisco' },
    { codigoTOM: 5619, codigoIBGE: 3201001, nomeTOM: 'BOA ESPERANÇA', nomeIBGE: 'Boa Esperança' },
    { codigoTOM: 5621, codigoIBGE: 3201100, nomeTOM: 'BOM JESUS DO NORTE', nomeIBGE: 'Bom Jesus do Norte' },
    { codigoTOM: 5623, codigoIBGE: 3201209, nomeTOM: 'CACHOEIRO DE ITAPEMIRIM', nomeIBGE: 'Cachoeiro de Itapemirim' },
    { codigoTOM: 5625, codigoIBGE: 3201308, nomeTOM: 'CARIACICA', nomeIBGE: 'Cariacica' },
    { codigoTOM: 5627, codigoIBGE: 3201407, nomeTOM: 'CASTELO', nomeIBGE: 'Castelo' },
    { codigoTOM: 5629, codigoIBGE: 3201506, nomeTOM: 'COLATINA', nomeIBGE: 'Colatina' },
    { codigoTOM: 5631, codigoIBGE: 3201605, nomeTOM: 'CONCEIÇÃO DA BARRA', nomeIBGE: 'Conceição da Barra' },
    { codigoTOM: 5633, codigoIBGE: 3201704, nomeTOM: 'CONCEIÇÃO DO CASTELO', nomeIBGE: 'Conceição do Castelo' },
    { codigoTOM: 5635, codigoIBGE: 3201803, nomeTOM: 'DIVINO DE SÃO LOURENÇO', nomeIBGE: 'Divino de São Lourenço' },
    { codigoTOM: 5637, codigoIBGE: 3201902, nomeTOM: 'DOMINGOS MARTINS', nomeIBGE: 'Domingos Martins' },
    { codigoTOM: 5639, codigoIBGE: 3202009, nomeTOM: 'DORES DO RIO PRETO', nomeIBGE: 'Dores do Rio Preto' },
    { codigoTOM: 5641, codigoIBGE: 3202108, nomeTOM: 'ECOPORANGA', nomeIBGE: 'Ecoporanga' },
    { codigoTOM: 5643, codigoIBGE: 3202207, nomeTOM: 'FUNDÃO', nomeIBGE: 'Fundão' },
    { codigoTOM: 5645, codigoIBGE: 3202306, nomeTOM: 'GUAÇUÍ', nomeIBGE: 'Guaçuí' },
    { codigoTOM: 5647, codigoIBGE: 3202405, nomeTOM: 'GUARAPARI', nomeIBGE: 'Guarapari' },
    { codigoTOM: 5649, codigoIBGE: 3202504, nomeTOM: 'IBIRAÇU', nomeIBGE: 'Ibiraçu' },
    { codigoTOM: 5651, codigoIBGE: 3202603, nomeTOM: 'ICONHA', nomeIBGE: 'Iconha' },
    { codigoTOM: 5653, codigoIBGE: 3202702, nomeTOM: 'ITAGUAÇU', nomeIBGE: 'Itaguaçu' },
    { codigoTOM: 5655, codigoIBGE: 3202801, nomeTOM: 'ITAPEMIRIM', nomeIBGE: 'Itapemirim' },
    { codigoTOM: 5657, codigoIBGE: 3202900, nomeTOM: 'ITARANA', nomeIBGE: 'Itarana' },
    { codigoTOM: 5659, codigoIBGE: 3203007, nomeTOM: 'IÚNA', nomeIBGE: 'Iúna' },
    { codigoTOM: 5661, codigoIBGE: 3203106, nomeTOM: 'JERÔNIMO MONTEIRO', nomeIBGE: 'Jerônimo Monteiro' },
    { codigoTOM: 5663, codigoIBGE: 3203205, nomeTOM: 'LINHARES', nomeIBGE: 'Linhares' },
    { codigoTOM: 5665, codigoIBGE: 3203304, nomeTOM: 'MANTENÓPOLIS', nomeIBGE: 'Mantenópolis' },
    { codigoTOM: 5667, codigoIBGE: 3203403, nomeTOM: 'MIMOSO DO SUL', nomeIBGE: 'Mimoso do Sul' },
    { codigoTOM: 5669, codigoIBGE: 3203502, nomeTOM: 'MONTANHA', nomeIBGE: 'Montanha' },
    { codigoTOM: 5671, codigoIBGE: 3203601, nomeTOM: 'MUCURICI', nomeIBGE: 'Mucurici' },
    { codigoTOM: 5673, codigoIBGE: 3203700, nomeTOM: 'MUNIZ FREIRE', nomeIBGE: 'Muniz Freire' },
    { codigoTOM: 5675, codigoIBGE: 3203809, nomeTOM: 'MUQUI', nomeIBGE: 'Muqui' },
    { codigoTOM: 5677, codigoIBGE: 3203908, nomeTOM: 'NOVA VENÉCIA', nomeIBGE: 'Nova Venécia' },
    { codigoTOM: 5679, codigoIBGE: 3204005, nomeTOM: 'PANCAS', nomeIBGE: 'Pancas' },
    { codigoTOM: 5681, codigoIBGE: 3204104, nomeTOM: 'PINHEIROS', nomeIBGE: 'Pinheiros' },
    { codigoTOM: 5683, codigoIBGE: 3204203, nomeTOM: 'PIÚMA', nomeIBGE: 'Piúma' },
    { codigoTOM: 5685, codigoIBGE: 3204302, nomeTOM: 'PRESIDENTE KENNEDY', nomeIBGE: 'Presidente Kennedy' },
    { codigoTOM: 5687, codigoIBGE: 3204401, nomeTOM: 'RIO NOVO DO SUL', nomeIBGE: 'Rio Novo do Sul' },
    { codigoTOM: 5689, codigoIBGE: 3204500, nomeTOM: 'SANTA LEOPOLDINA', nomeIBGE: 'Santa Leopoldina' },
    { codigoTOM: 5691, codigoIBGE: 3204609, nomeTOM: 'SANTA TERESA', nomeIBGE: 'Santa Teresa' },
    { codigoTOM: 5693, codigoIBGE: 3204708, nomeTOM: 'SÃO GABRIEL DA PALHA', nomeIBGE: 'São Gabriel da Palha' },
    { codigoTOM: 5695, codigoIBGE: 3204807, nomeTOM: 'SÃO JOSÉ DO CALÇADO', nomeIBGE: 'São José do Calçado' },
    { codigoTOM: 5697, codigoIBGE: 3204906, nomeTOM: 'SÃO MATEUS', nomeIBGE: 'São Mateus' },
    { codigoTOM: 5699, codigoIBGE: 3205002, nomeTOM: 'SERRA', nomeIBGE: 'Serra' },
    { codigoTOM: 5701, codigoIBGE: 3205101, nomeTOM: 'VIANA', nomeIBGE: 'Viana' },
    { codigoTOM: 5703, codigoIBGE: 3205200, nomeTOM: 'VILA VELHA', nomeIBGE: 'Vila Velha' },
    { codigoTOM: 5705, codigoIBGE: 3205309, nomeTOM: 'VITÓRIA', nomeIBGE: 'Vitória' },
    { codigoTOM: 5707, codigoIBGE: 3203353, nomeTOM: 'MARILÂNDIA', nomeIBGE: 'Marilândia' },
    { codigoTOM: 5709, codigoIBGE: 3202454, nomeTOM: 'IBATIBA', nomeIBGE: 'Ibatiba' },
    { codigoTOM: 5711, codigoIBGE: 3204351, nomeTOM: 'RIO BANANAL', nomeIBGE: 'Rio Bananal' },
    { codigoTOM: 5713, codigoIBGE: 3203056, nomeTOM: 'JAGUARÉ', nomeIBGE: 'Jaguaré' },
    { codigoTOM: 5715, codigoIBGE: 3204054, nomeTOM: 'PEDRO CANÁRIO', nomeIBGE: 'Pedro Canário' },
    { codigoTOM: 5717, codigoIBGE: 3200169, nomeTOM: 'ÁGUA DOCE DO NORTE', nomeIBGE: 'Água Doce do Norte' },
    { codigoTOM: 5719, codigoIBGE: 3200359, nomeTOM: 'ALTO RIO NOVO', nomeIBGE: 'Alto Rio Novo' },
    { codigoTOM: 5721, codigoIBGE: 3203130, nomeTOM: 'JOÃO NEIVA', nomeIBGE: 'João Neiva' },
    { codigoTOM: 5723, codigoIBGE: 3203163, nomeTOM: 'LARANJA DA TERRA', nomeIBGE: 'Laranja da Terra' },
    { codigoTOM: 5725, codigoIBGE: 3204559, nomeTOM: 'SANTA MARIA DE JETIBÁ', nomeIBGE: 'Santa Maria de Jetibá' },
    { codigoTOM: 5727, codigoIBGE: 3205036, nomeTOM: 'VARGEM ALTA', nomeIBGE: 'Vargem Alta' },
    { codigoTOM: 5729, codigoIBGE: 3205069, nomeTOM: 'VENDA NOVA DO IMIGRANTE', nomeIBGE: 'Venda Nova do Imigrante' },
    { codigoTOM: 5733, codigoIBGE: 3200136, nomeTOM: 'ÁGUIA BRANCA', nomeIBGE: 'Águia Branca' },
    { codigoTOM: 6011, codigoIBGE: 3202553, nomeTOM: 'IBITIRAMA', nomeIBGE: 'Ibitirama' },
    { codigoTOM: 1114, codigoIBGE: 3202256, nomeTOM: 'GOVERNADOR LINDENBERG', nomeIBGE: 'Governador Lindenberg' },
  ],
  [Estados.RJ]: [
    { codigoTOM: 770, codigoIBGE: 3300233, nomeTOM: 'Armação dos Búzios', nomeIBGE: 'Armação dos Búzios' },
    { codigoTOM: 772, codigoIBGE: 3300936, nomeTOM: 'CARAPEBUS', nomeIBGE: 'Carapebus' },
    { codigoTOM: 774, codigoIBGE: 3301876, nomeTOM: 'IGUABA GRANDE', nomeIBGE: 'Iguaba Grande' },
    { codigoTOM: 776, codigoIBGE: 3302452, nomeTOM: 'MACUCO', nomeIBGE: 'Macuco' },
    { codigoTOM: 778, codigoIBGE: 3303955, nomeTOM: 'PINHEIRAL', nomeIBGE: 'Pinheiral' },
    { codigoTOM: 780, codigoIBGE: 3304110, nomeTOM: 'PORTO REAL', nomeIBGE: 'Porto Real' },
    {
      codigoTOM: 782,
      codigoIBGE: 3304755,
      nomeTOM: 'SÃO FRANCISCO DE ITABAPOANA',
      nomeIBGE: 'São Francisco de Itabapoana',
    },
    { codigoTOM: 784, codigoIBGE: 3305133, nomeTOM: 'SÃO JOSÉ DE UBÁ', nomeIBGE: 'São José de Ubá' },
    { codigoTOM: 786, codigoIBGE: 3305554, nomeTOM: 'SEROPÉDICA', nomeIBGE: 'Seropédica' },
    { codigoTOM: 788, codigoIBGE: 3305752, nomeTOM: 'TANGUÁ', nomeIBGE: 'Tanguá' },
    { codigoTOM: 1116, codigoIBGE: 3302858, nomeTOM: 'MESQUITA', nomeIBGE: 'Mesquita' },
    { codigoTOM: 2907, codigoIBGE: 3301850, nomeTOM: 'GUAPIMIRIM', nomeIBGE: 'Guapimirim' },
    { codigoTOM: 2909, codigoIBGE: 3300456, nomeTOM: 'BELFORD ROXO', nomeIBGE: 'Belford Roxo' },
    { codigoTOM: 2911, codigoIBGE: 3304144, nomeTOM: 'QUEIMADOS', nomeIBGE: 'Queimados' },
    { codigoTOM: 2913, codigoIBGE: 3302270, nomeTOM: 'JAPERI', nomeIBGE: 'Japeri' },
    { codigoTOM: 2915, codigoIBGE: 3301157, nomeTOM: 'CARDOSO MOREIRA', nomeIBGE: 'Cardoso Moreira' },
    { codigoTOM: 2917, codigoIBGE: 3306156, nomeTOM: 'VARRE-SAI', nomeIBGE: 'Varre-Sai' },
    { codigoTOM: 2919, codigoIBGE: 3300159, nomeTOM: 'APERIBÉ', nomeIBGE: 'Aperibé' },
    { codigoTOM: 2921, codigoIBGE: 3304524, nomeTOM: 'RIO DAS OSTRAS', nomeIBGE: 'Rio das Ostras' },
    { codigoTOM: 2923, codigoIBGE: 3304128, nomeTOM: 'QUATIS', nomeIBGE: 'Quatis' },
    { codigoTOM: 2925, codigoIBGE: 3300225, nomeTOM: 'AREAL', nomeIBGE: 'Areal' },
    {
      codigoTOM: 2927,
      codigoIBGE: 3300951,
      nomeTOM: 'COMENDADOR LEVY GASPARIAN',
      nomeIBGE: 'Comendador Levy Gasparian',
    },
    { codigoTOM: 5801, codigoIBGE: 3300100, nomeTOM: 'ANGRA DOS REIS', nomeIBGE: 'Angra dos Reis' },
    { codigoTOM: 5803, codigoIBGE: 3300209, nomeTOM: 'ARARUAMA', nomeIBGE: 'Araruama' },
    { codigoTOM: 5805, codigoIBGE: 3300308, nomeTOM: 'BARRA DO PIRAÍ', nomeIBGE: 'Barra do Piraí' },
    { codigoTOM: 5807, codigoIBGE: 3300407, nomeTOM: 'BARRA MANSA', nomeIBGE: 'Barra Mansa' },
    { codigoTOM: 5809, codigoIBGE: 3300506, nomeTOM: 'BOM JARDIM', nomeIBGE: 'Bom Jardim' },
    { codigoTOM: 5811, codigoIBGE: 3300605, nomeTOM: 'BOM JESUS DO ITABAPOANA', nomeIBGE: 'Bom Jesus do Itabapoana' },
    { codigoTOM: 5813, codigoIBGE: 3300704, nomeTOM: 'CABO FRIO', nomeIBGE: 'Cabo Frio' },
    { codigoTOM: 5815, codigoIBGE: 3300803, nomeTOM: 'CACHOEIRAS DE MACACU', nomeIBGE: 'Cachoeiras de Macacu' },
    { codigoTOM: 5817, codigoIBGE: 3300902, nomeTOM: 'CAMBUCI', nomeIBGE: 'Cambuci' },
    { codigoTOM: 5819, codigoIBGE: 3301009, nomeTOM: 'CAMPOS DOS GOYTACAZES', nomeIBGE: 'Campos dos Goytacazes' },
    { codigoTOM: 5821, codigoIBGE: 3301108, nomeTOM: 'CANTAGALO', nomeIBGE: 'Cantagalo' },
    { codigoTOM: 5823, codigoIBGE: 3301207, nomeTOM: 'CARMO', nomeIBGE: 'Carmo' },
    { codigoTOM: 5825, codigoIBGE: 3301306, nomeTOM: 'CASIMIRO DE ABREU', nomeIBGE: 'Casimiro de Abreu' },
    { codigoTOM: 5827, codigoIBGE: 3301405, nomeTOM: 'CONCEIÇÃO DE MACABU', nomeIBGE: 'Conceição de Macabu' },
    { codigoTOM: 5829, codigoIBGE: 3301504, nomeTOM: 'CORDEIRO', nomeIBGE: 'Cordeiro' },
    { codigoTOM: 5831, codigoIBGE: 3301603, nomeTOM: 'DUAS BARRAS', nomeIBGE: 'Duas Barras' },
    { codigoTOM: 5833, codigoIBGE: 3301702, nomeTOM: 'DUQUE DE CAXIAS', nomeIBGE: 'Duque de Caxias' },
    {
      codigoTOM: 5835,
      codigoIBGE: 3301801,
      nomeTOM: 'ENGENHEIRO PAULO DE FRONTIN',
      nomeIBGE: 'Engenheiro Paulo de Frontin',
    },
    { codigoTOM: 5837, codigoIBGE: 3301900, nomeTOM: 'ITABORAÍ', nomeIBGE: 'Itaboraí' },
    { codigoTOM: 5839, codigoIBGE: 3302007, nomeTOM: 'ITAGUAÍ', nomeIBGE: 'Itaguaí' },
    { codigoTOM: 5841, codigoIBGE: 3302106, nomeTOM: 'ITAOCARA', nomeIBGE: 'Itaocara' },
    { codigoTOM: 5843, codigoIBGE: 3302205, nomeTOM: 'ITAPERUNA', nomeIBGE: 'Itaperuna' },
    { codigoTOM: 5845, codigoIBGE: 3302304, nomeTOM: 'LAJE DO MURIAÉ', nomeIBGE: 'Laje do Muriaé' },
    { codigoTOM: 5847, codigoIBGE: 3302403, nomeTOM: 'MACAÉ', nomeIBGE: 'Macaé' },
    { codigoTOM: 5849, codigoIBGE: 3302502, nomeTOM: 'MAGÉ', nomeIBGE: 'Magé' },
    { codigoTOM: 5851, codigoIBGE: 3302601, nomeTOM: 'MANGARATIBA', nomeIBGE: 'Mangaratiba' },
    { codigoTOM: 5853, codigoIBGE: 3302700, nomeTOM: 'MARICÁ', nomeIBGE: 'Maricá' },
    { codigoTOM: 5855, codigoIBGE: 3302809, nomeTOM: 'MENDES', nomeIBGE: 'Mendes' },
    { codigoTOM: 5857, codigoIBGE: 3302908, nomeTOM: 'MIGUEL PEREIRA', nomeIBGE: 'Miguel Pereira' },
    { codigoTOM: 5859, codigoIBGE: 3303005, nomeTOM: 'MIRACEMA', nomeIBGE: 'Miracema' },
    { codigoTOM: 5861, codigoIBGE: 3303104, nomeTOM: 'NATIVIDADE', nomeIBGE: 'Natividade' },
    { codigoTOM: 5863, codigoIBGE: 3303203, nomeTOM: 'NILÓPOLIS', nomeIBGE: 'Nilópolis' },
    { codigoTOM: 5865, codigoIBGE: 3303302, nomeTOM: 'NITERÓI', nomeIBGE: 'Niterói' },
    { codigoTOM: 5867, codigoIBGE: 3303401, nomeTOM: 'NOVA FRIBURGO', nomeIBGE: 'Nova Friburgo' },
    { codigoTOM: 5869, codigoIBGE: 3303500, nomeTOM: 'NOVA IGUAÇU', nomeIBGE: 'Nova Iguaçu' },
    { codigoTOM: 5871, codigoIBGE: 3303609, nomeTOM: 'PARACAMBI', nomeIBGE: 'Paracambi' },
    { codigoTOM: 5873, codigoIBGE: 3303708, nomeTOM: 'PARAÍBA DO SUL', nomeIBGE: 'Paraíba do Sul' },
    { codigoTOM: 5875, codigoIBGE: 3303807, nomeTOM: 'PARATI', nomeIBGE: 'Paraty' },
    { codigoTOM: 5877, codigoIBGE: 3303906, nomeTOM: 'PETRÓPOLIS', nomeIBGE: 'Petrópolis' },
    { codigoTOM: 5879, codigoIBGE: 3304003, nomeTOM: 'PIRAÍ', nomeIBGE: 'Piraí' },
    { codigoTOM: 5881, codigoIBGE: 3304102, nomeTOM: 'PORCIÚNCULA', nomeIBGE: 'Porciúncula' },
    { codigoTOM: 5883, codigoIBGE: 3304201, nomeTOM: 'RESENDE', nomeIBGE: 'Resende' },
    { codigoTOM: 5885, codigoIBGE: 3304300, nomeTOM: 'RIO BONITO', nomeIBGE: 'Rio Bonito' },
    { codigoTOM: 5887, codigoIBGE: 3304409, nomeTOM: 'RIO CLARO', nomeIBGE: 'Rio Claro' },
    { codigoTOM: 5889, codigoIBGE: 3304508, nomeTOM: 'RIO DAS FLORES', nomeIBGE: 'Rio das Flores' },
    { codigoTOM: 5891, codigoIBGE: 3304607, nomeTOM: 'SANTA MARIA MADALENA', nomeIBGE: 'Santa Maria Madalena' },
    { codigoTOM: 5893, codigoIBGE: 3304706, nomeTOM: 'SANTO ANTÔNIO DE PÁDUA', nomeIBGE: 'Santo Antônio de Pádua' },
    { codigoTOM: 5895, codigoIBGE: 3304805, nomeTOM: 'SÃO FIDÉLIS', nomeIBGE: 'São Fidélis' },
    { codigoTOM: 5897, codigoIBGE: 3304904, nomeTOM: 'SÃO GONÇALO', nomeIBGE: 'São Gonçalo' },
    { codigoTOM: 5899, codigoIBGE: 3305000, nomeTOM: 'SÃO JOÃO DA BARRA', nomeIBGE: 'São João da Barra' },
    { codigoTOM: 5901, codigoIBGE: 3305109, nomeTOM: 'SÃO JOÃO DE MERITI', nomeIBGE: 'São João de Meriti' },
    { codigoTOM: 5903, codigoIBGE: 3305208, nomeTOM: 'SÃO PEDRO DA ALDEIA', nomeIBGE: 'São Pedro da Aldeia' },
    { codigoTOM: 5905, codigoIBGE: 3305307, nomeTOM: 'SÃO SEBASTIÃO DO ALTO', nomeIBGE: 'São Sebastião do Alto' },
    { codigoTOM: 5907, codigoIBGE: 3305406, nomeTOM: 'SAPUCAIA', nomeIBGE: 'Sapucaia' },
    { codigoTOM: 5909, codigoIBGE: 3305505, nomeTOM: 'SAQUAREMA', nomeIBGE: 'Saquarema' },
    { codigoTOM: 5911, codigoIBGE: 3305604, nomeTOM: 'SILVA JARDIM', nomeIBGE: 'Silva Jardim' },
    { codigoTOM: 5913, codigoIBGE: 3305703, nomeTOM: 'SUMIDOURO', nomeIBGE: 'Sumidouro' },
    { codigoTOM: 5915, codigoIBGE: 3305802, nomeTOM: 'TERESÓPOLIS', nomeIBGE: 'Teresópolis' },
    { codigoTOM: 5917, codigoIBGE: 3305901, nomeTOM: 'TRAJANO DE MORAES', nomeIBGE: 'Trajano de Moraes' },
    { codigoTOM: 5919, codigoIBGE: 3306008, nomeTOM: 'TRÊS RIOS', nomeIBGE: 'Três Rios' },
    { codigoTOM: 5921, codigoIBGE: 3306107, nomeTOM: 'VALENÇA', nomeIBGE: 'Valença' },
    { codigoTOM: 5923, codigoIBGE: 3306206, nomeTOM: 'VASSOURAS', nomeIBGE: 'Vassouras' },
    { codigoTOM: 5925, codigoIBGE: 3306305, nomeTOM: 'VOLTA REDONDA', nomeIBGE: 'Volta Redonda' },
    { codigoTOM: 5927, codigoIBGE: 3300258, nomeTOM: 'ARRAIAL DO CABO', nomeIBGE: 'Arraial do Cabo' },
    { codigoTOM: 5929, codigoIBGE: 3302056, nomeTOM: 'ITALVA', nomeIBGE: 'Italva' },
    { codigoTOM: 6001, codigoIBGE: 3304557, nomeTOM: 'RIO DE JANEIRO', nomeIBGE: 'Rio de Janeiro' },
    { codigoTOM: 6003, codigoIBGE: 3302254, nomeTOM: 'ITATIAIA', nomeIBGE: 'Itatiaia' },
    { codigoTOM: 6005, codigoIBGE: 3303856, nomeTOM: 'PATY DO ALFERES', nomeIBGE: 'Paty do Alferes' },
    { codigoTOM: 6007, codigoIBGE: 3304151, nomeTOM: 'QUISSAMÃ', nomeIBGE: 'Quissamã' },
    {
      codigoTOM: 6009,
      codigoIBGE: 3305158,
      nomeTOM: 'SÃO JOSÉ DO VALE DO RIO PRETO',
      nomeIBGE: 'São José do Vale do Rio Preto',
    },
  ],
  [Estados.SP]: [
    { codigoTOM: 790, codigoIBGE: 3503356, nomeTOM: 'ARCO-ÍRIS', nomeIBGE: 'Arco-Íris' },
    { codigoTOM: 792, codigoIBGE: 3507753, nomeTOM: 'BREJO ALEGRE', nomeIBGE: 'Brejo Alegre' },
    { codigoTOM: 794, codigoIBGE: 3509957, nomeTOM: 'CANAS', nomeIBGE: 'Canas' },
    { codigoTOM: 796, codigoIBGE: 3515657, nomeTOM: 'FERNÃO', nomeIBGE: 'Fernão' },
    { codigoTOM: 798, codigoIBGE: 3516853, nomeTOM: 'GAVIÃO PEIXOTO', nomeIBGE: 'Gavião Peixoto' },
    { codigoTOM: 800, codigoIBGE: 3521150, nomeTOM: 'IPIGUÁ', nomeIBGE: 'Ipiguá' },
    { codigoTOM: 802, codigoIBGE: 3525854, nomeTOM: 'JUMIRIM', nomeIBGE: 'Jumirim' },
    { codigoTOM: 804, codigoIBGE: 3532157, nomeTOM: 'NANTES', nomeIBGE: 'Nantes' },
    { codigoTOM: 806, codigoIBGE: 3532868, nomeTOM: 'NOVA CASTILHO', nomeIBGE: 'Nova Castilho' },
    { codigoTOM: 808, codigoIBGE: 3534757, nomeTOM: 'OUROESTE', nomeIBGE: 'Ouroeste' },
    { codigoTOM: 810, codigoIBGE: 3536570, nomeTOM: 'PAULISTÂNIA', nomeIBGE: 'Paulistânia' },
    { codigoTOM: 812, codigoIBGE: 3540853, nomeTOM: 'PRACINHA', nomeIBGE: 'Pracinha' },
    { codigoTOM: 814, codigoIBGE: 3541059, nomeTOM: 'PRATÂNIA', nomeIBGE: 'Pratânia' },
    { codigoTOM: 816, codigoIBGE: 3541653, nomeTOM: 'QUADRA', nomeIBGE: 'Quadra' },
    { codigoTOM: 818, codigoIBGE: 3543238, nomeTOM: 'RIBEIRÃO DOS ÍNDIOS', nomeIBGE: 'Ribeirão dos Índios' },
    { codigoTOM: 820, codigoIBGE: 3546256, nomeTOM: 'SANTA CRUZ DA ESPERANÇA', nomeIBGE: 'Santa Cruz da Esperança' },
    { codigoTOM: 822, codigoIBGE: 3547650, nomeTOM: 'SANTA SALETE', nomeIBGE: 'Santa Salete' },
    { codigoTOM: 824, codigoIBGE: 3553658, nomeTOM: 'TAQUARAL', nomeIBGE: 'Taquaral' },
    { codigoTOM: 826, codigoIBGE: 3554755, nomeTOM: 'TRABIJU', nomeIBGE: 'Trabiju' },
    { codigoTOM: 828, codigoIBGE: 3556958, nomeTOM: 'VITÓRIA BRASIL', nomeIBGE: 'Vitória Brasil' },
    { codigoTOM: 2937, codigoIBGE: 3527256, nomeTOM: 'LOURDES', nomeIBGE: 'Lourdes' },
    {
      codigoTOM: 2939,
      codigoIBGE: 3548054,
      nomeTOM: 'SANTO ANTÔNIO DO ARACANGUÁ',
      nomeIBGE: 'Santo Antônio do Aracanguá',
    },
    { codigoTOM: 2941, codigoIBGE: 3549250, nomeTOM: 'SÃO JOÃO DE IRACEMA', nomeIBGE: 'São João de Iracema' },
    { codigoTOM: 2943, codigoIBGE: 3520442, nomeTOM: 'ILHA SOLTEIRA', nomeIBGE: 'Ilha Solteira' },
    { codigoTOM: 2945, codigoIBGE: 3552551, nomeTOM: 'SUZANÁPOLIS', nomeIBGE: 'Suzanápolis' },
    { codigoTOM: 2947, codigoIBGE: 3510153, nomeTOM: 'CANITAR', nomeIBGE: 'Canitar' },
    { codigoTOM: 2949, codigoIBGE: 3515152, nomeTOM: 'ENGENHEIRO COELHO', nomeIBGE: 'Engenheiro Coelho' },
    { codigoTOM: 2951, codigoIBGE: 3519071, nomeTOM: 'HORTOLÂNDIA', nomeIBGE: 'Hortolândia' },
    { codigoTOM: 2953, codigoIBGE: 3519055, nomeTOM: 'HOLAMBRA', nomeIBGE: 'Holambra' },
    { codigoTOM: 2955, codigoIBGE: 3554953, nomeTOM: 'TUIUTI', nomeIBGE: 'Tuiuti' },
    { codigoTOM: 2957, codigoIBGE: 3556354, nomeTOM: 'VARGEM', nomeIBGE: 'Vargem' },
    { codigoTOM: 2959, codigoIBGE: 3557303, nomeTOM: 'ESTIVA GERBI', nomeIBGE: 'Estiva Gerbi' },
    { codigoTOM: 2961, codigoIBGE: 3515129, nomeTOM: 'EMILIANÓPOLIS', nomeIBGE: 'Emilianópolis' },
    { codigoTOM: 2963, codigoIBGE: 3537156, nomeTOM: 'PEDRINHAS PAULISTA', nomeIBGE: 'Pedrinhas Paulista' },
    { codigoTOM: 2965, codigoIBGE: 3506359, nomeTOM: 'BERTIOGA', nomeIBGE: 'Bertioga' },
    { codigoTOM: 2967, codigoIBGE: 3509254, nomeTOM: 'CAJATI', nomeIBGE: 'Cajati' },
    { codigoTOM: 2969, codigoIBGE: 3520426, nomeTOM: 'ILHA COMPRIDA', nomeIBGE: 'Ilha Comprida' },
    { codigoTOM: 2971, codigoIBGE: 3555356, nomeTOM: 'UBARANA', nomeIBGE: 'Ubarana' },
    { codigoTOM: 2973, codigoIBGE: 3557154, nomeTOM: 'ZACARIAS', nomeIBGE: 'Zacarias' },
    { codigoTOM: 2975, codigoIBGE: 3514924, nomeTOM: 'ELISIÁRIO', nomeIBGE: 'Elisiário' },
    { codigoTOM: 2977, codigoIBGE: 3528858, nomeTOM: 'MARAPOAMA', nomeIBGE: 'Marapoama' },
    { codigoTOM: 2979, codigoIBGE: 3533254, nomeTOM: 'NOVAIS', nomeIBGE: 'Novais' },
    { codigoTOM: 2981, codigoIBGE: 3503950, nomeTOM: 'ASPÁSIA', nomeIBGE: 'Aspásia' },
    { codigoTOM: 2983, codigoIBGE: 3529658, nomeTOM: 'MESÓPOLIS', nomeIBGE: 'Mesópolis' },
    { codigoTOM: 2985, codigoIBGE: 3532843, nomeTOM: 'NOVA CANAÃ PAULISTA', nomeIBGE: 'Nova Canaã Paulista' },
    { codigoTOM: 2987, codigoIBGE: 3540259, nomeTOM: 'PONTALINDA', nomeIBGE: 'Pontalinda' },
    { codigoTOM: 2989, codigoIBGE: 3536257, nomeTOM: 'PARISI', nomeIBGE: 'Parisi' },
    { codigoTOM: 2991, codigoIBGE: 3503158, nomeTOM: 'ARAPEÍ', nomeIBGE: 'Arapeí' },
    { codigoTOM: 2993, codigoIBGE: 3540754, nomeTOM: 'POTIM', nomeIBGE: 'Potim' },
    { codigoTOM: 2995, codigoIBGE: 3500758, nomeTOM: 'ALAMBARI', nomeIBGE: 'Alambari' },
    { codigoTOM: 2997, codigoIBGE: 3505351, nomeTOM: 'BARRA DO CHAPÉU', nomeIBGE: 'Barra do Chapéu' },
    { codigoTOM: 2999, codigoIBGE: 3509452, nomeTOM: 'CAMPINA DO MONTE ALEGRE', nomeIBGE: 'Campina do Monte Alegre' },
    { codigoTOM: 3053, codigoIBGE: 3522158, nomeTOM: 'ITAÓCA', nomeIBGE: 'Itaoca' },
    { codigoTOM: 3055, codigoIBGE: 3522653, nomeTOM: 'ITAPIRAPUÃ PAULISTA', nomeIBGE: 'Itapirapuã Paulista' },
    { codigoTOM: 3057, codigoIBGE: 3543253, nomeTOM: 'RIBEIRÃO GRANDE', nomeIBGE: 'Ribeirão Grande' },
    { codigoTOM: 3059, codigoIBGE: 3507159, nomeTOM: 'BOM SUCESSO DE ITARARÉ', nomeIBGE: 'Bom Sucesso de Itararé' },
    { codigoTOM: 3061, codigoIBGE: 3532827, nomeTOM: 'NOVA CAMPINA', nomeIBGE: 'Nova Campina' },
    { codigoTOM: 3063, codigoIBGE: 3553856, nomeTOM: 'TAQUARIVAÍ', nomeIBGE: 'Taquarivaí' },
    { codigoTOM: 3065, codigoIBGE: 3501152, nomeTOM: 'ALUMÍNIO', nomeIBGE: 'Alumínio' },
    { codigoTOM: 3067, codigoIBGE: 3502754, nomeTOM: 'ARAÇARIGUAMA', nomeIBGE: 'Araçariguama' },
    { codigoTOM: 3227, codigoIBGE: 3554656, nomeTOM: 'TORRE DE PEDRA', nomeIBGE: 'Torre de Pedra' },
    { codigoTOM: 5445, codigoIBGE: 3545159, nomeTOM: 'SALTINHO', nomeIBGE: 'Saltinho' },
    { codigoTOM: 5447, codigoIBGE: 3549953, nomeTOM: 'SÃO LOURENÇO DA SERRA', nomeIBGE: 'São Lourenço da Serra' },
    { codigoTOM: 6101, codigoIBGE: 3500105, nomeTOM: 'ADAMANTINA', nomeIBGE: 'Adamantina' },
    { codigoTOM: 6103, codigoIBGE: 3500204, nomeTOM: 'ADOLFO', nomeIBGE: 'Adolfo' },
    { codigoTOM: 6105, codigoIBGE: 3500303, nomeTOM: 'AGUAÍ', nomeIBGE: 'Aguaí' },
    { codigoTOM: 6107, codigoIBGE: 3500402, nomeTOM: 'ÁGUAS DA PRATA', nomeIBGE: 'Águas da Prata' },
    { codigoTOM: 6109, codigoIBGE: 3500501, nomeTOM: 'ÁGUAS DE LINDÓIA', nomeIBGE: 'Águas de Lindóia' },
    { codigoTOM: 6111, codigoIBGE: 3500600, nomeTOM: 'ÁGUAS DE SÃO PEDRO', nomeIBGE: 'Águas de São Pedro' },
    { codigoTOM: 6113, codigoIBGE: 3500709, nomeTOM: 'AGUDOS', nomeIBGE: 'Agudos' },
    { codigoTOM: 6115, codigoIBGE: 3500808, nomeTOM: 'ALFREDO MARCONDES', nomeIBGE: 'Alfredo Marcondes' },
    { codigoTOM: 6117, codigoIBGE: 3500907, nomeTOM: 'ALTAIR', nomeIBGE: 'Altair' },
    { codigoTOM: 6119, codigoIBGE: 3501004, nomeTOM: 'ALTINÓPOLIS', nomeIBGE: 'Altinópolis' },
    { codigoTOM: 6121, codigoIBGE: 3501103, nomeTOM: 'ALTO ALEGRE', nomeIBGE: 'Alto Alegre' },
    { codigoTOM: 6123, codigoIBGE: 3501202, nomeTOM: 'ÁLVARES FLORENCE', nomeIBGE: 'Álvares Florence' },
    { codigoTOM: 6125, codigoIBGE: 3501301, nomeTOM: 'ÁLVARES MACHADO', nomeIBGE: 'Álvares Machado' },
    { codigoTOM: 6127, codigoIBGE: 3501400, nomeTOM: 'ÁLVARO DE CARVALHO', nomeIBGE: 'Álvaro de Carvalho' },
    { codigoTOM: 6129, codigoIBGE: 3501509, nomeTOM: 'ALVINLÂNDIA', nomeIBGE: 'Alvinlândia' },
    { codigoTOM: 6131, codigoIBGE: 3501608, nomeTOM: 'AMERICANA', nomeIBGE: 'Americana' },
    { codigoTOM: 6133, codigoIBGE: 3501707, nomeTOM: 'AMÉRICO BRASILIENSE', nomeIBGE: 'Américo Brasiliense' },
    { codigoTOM: 6135, codigoIBGE: 3501806, nomeTOM: 'AMÉRICO DE CAMPOS', nomeIBGE: 'Américo de Campos' },
    { codigoTOM: 6137, codigoIBGE: 3501905, nomeTOM: 'AMPARO', nomeIBGE: 'Amparo' },
    { codigoTOM: 6139, codigoIBGE: 3502002, nomeTOM: 'ANALÂNDIA', nomeIBGE: 'Analândia' },
    { codigoTOM: 6141, codigoIBGE: 3502101, nomeTOM: 'ANDRADINA', nomeIBGE: 'Andradina' },
    { codigoTOM: 6143, codigoIBGE: 3502200, nomeTOM: 'ANGATUBA', nomeIBGE: 'Angatuba' },
    { codigoTOM: 6145, codigoIBGE: 3502309, nomeTOM: 'ANHEMBI', nomeIBGE: 'Anhembi' },
    { codigoTOM: 6147, codigoIBGE: 3502408, nomeTOM: 'ANHUMAS', nomeIBGE: 'Anhumas' },
    { codigoTOM: 6149, codigoIBGE: 3502507, nomeTOM: 'APARECIDA', nomeIBGE: 'Aparecida' },
    { codigoTOM: 6151, codigoIBGE: 3502606, nomeTOM: "APARECIDA D'OESTE", nomeIBGE: "Aparecida d'Oeste" },
    { codigoTOM: 6153, codigoIBGE: 3502705, nomeTOM: 'APIAÍ', nomeIBGE: 'Apiaí' },
    { codigoTOM: 6155, codigoIBGE: 3502804, nomeTOM: 'ARAÇATUBA', nomeIBGE: 'Araçatuba' },
    { codigoTOM: 6157, codigoIBGE: 3502903, nomeTOM: 'ARAÇOIABA DA SERRA', nomeIBGE: 'Araçoiaba da Serra' },
    { codigoTOM: 6159, codigoIBGE: 3503000, nomeTOM: 'ARAMINA', nomeIBGE: 'Aramina' },
    { codigoTOM: 6161, codigoIBGE: 3503109, nomeTOM: 'ARANDU', nomeIBGE: 'Arandu' },
    { codigoTOM: 6163, codigoIBGE: 3503208, nomeTOM: 'ARARAQUARA', nomeIBGE: 'Araraquara' },
    { codigoTOM: 6165, codigoIBGE: 3503307, nomeTOM: 'ARARAS', nomeIBGE: 'Araras' },
    { codigoTOM: 6167, codigoIBGE: 3503406, nomeTOM: 'AREALVA', nomeIBGE: 'Arealva' },
    { codigoTOM: 6169, codigoIBGE: 3503505, nomeTOM: 'AREIAS', nomeIBGE: 'Areias' },
    { codigoTOM: 6171, codigoIBGE: 3503604, nomeTOM: 'AREIÓPOLIS', nomeIBGE: 'Areiópolis' },
    { codigoTOM: 6173, codigoIBGE: 3503703, nomeTOM: 'ARIRANHA', nomeIBGE: 'Ariranha' },
    { codigoTOM: 6175, codigoIBGE: 3503802, nomeTOM: 'ARTUR NOGUEIRA', nomeIBGE: 'Artur Nogueira' },
    { codigoTOM: 6177, codigoIBGE: 3503901, nomeTOM: 'ARUJÁ', nomeIBGE: 'Arujá' },
    { codigoTOM: 6179, codigoIBGE: 3504008, nomeTOM: 'ASSIS', nomeIBGE: 'Assis' },
    { codigoTOM: 6181, codigoIBGE: 3504107, nomeTOM: 'ATIBAIA', nomeIBGE: 'Atibaia' },
    { codigoTOM: 6183, codigoIBGE: 3504206, nomeTOM: 'AURIFLAMA', nomeIBGE: 'Auriflama' },
    { codigoTOM: 6185, codigoIBGE: 3504305, nomeTOM: 'AVAÍ', nomeIBGE: 'Avaí' },
    { codigoTOM: 6187, codigoIBGE: 3504404, nomeTOM: 'AVANHANDAVA', nomeIBGE: 'Avanhandava' },
    { codigoTOM: 6189, codigoIBGE: 3504503, nomeTOM: 'AVARÉ', nomeIBGE: 'Avaré' },
    { codigoTOM: 6191, codigoIBGE: 3504602, nomeTOM: 'BADY BASSITT', nomeIBGE: 'Bady Bassitt' },
    { codigoTOM: 6193, codigoIBGE: 3504701, nomeTOM: 'BALBINOS', nomeIBGE: 'Balbinos' },
    { codigoTOM: 6195, codigoIBGE: 3504800, nomeTOM: 'BÁLSAMO', nomeIBGE: 'Bálsamo' },
    { codigoTOM: 6197, codigoIBGE: 3504909, nomeTOM: 'BANANAL', nomeIBGE: 'Bananal' },
    { codigoTOM: 6199, codigoIBGE: 3505104, nomeTOM: 'BARBOSA', nomeIBGE: 'Barbosa' },
    { codigoTOM: 6201, codigoIBGE: 3505005, nomeTOM: 'BARÃO DE ANTONINA', nomeIBGE: 'Barão de Antonina' },
    { codigoTOM: 6203, codigoIBGE: 3505203, nomeTOM: 'BARIRI', nomeIBGE: 'Bariri' },
    { codigoTOM: 6205, codigoIBGE: 3505302, nomeTOM: 'BARRA BONITA', nomeIBGE: 'Barra Bonita' },
    { codigoTOM: 6207, codigoIBGE: 3505401, nomeTOM: 'BARRA DO TURVO', nomeIBGE: 'Barra do Turvo' },
    { codigoTOM: 6209, codigoIBGE: 3505500, nomeTOM: 'BARRETOS', nomeIBGE: 'Barretos' },
    { codigoTOM: 6211, codigoIBGE: 3505609, nomeTOM: 'BARRINHA', nomeIBGE: 'Barrinha' },
    { codigoTOM: 6213, codigoIBGE: 3505708, nomeTOM: 'BARUERI', nomeIBGE: 'Barueri' },
    { codigoTOM: 6215, codigoIBGE: 3505807, nomeTOM: 'BASTOS', nomeIBGE: 'Bastos' },
    { codigoTOM: 6217, codigoIBGE: 3505906, nomeTOM: 'BATATAIS', nomeIBGE: 'Batatais' },
    { codigoTOM: 6219, codigoIBGE: 3506003, nomeTOM: 'BAURU', nomeIBGE: 'Bauru' },
    { codigoTOM: 6221, codigoIBGE: 3506102, nomeTOM: 'BEBEDOURO', nomeIBGE: 'Bebedouro' },
    { codigoTOM: 6223, codigoIBGE: 3506201, nomeTOM: 'BENTO DE ABREU', nomeIBGE: 'Bento de Abreu' },
    { codigoTOM: 6225, codigoIBGE: 3506300, nomeTOM: 'BERNARDINO DE CAMPOS', nomeIBGE: 'Bernardino de Campos' },
    { codigoTOM: 6227, codigoIBGE: 3506409, nomeTOM: 'BILAC', nomeIBGE: 'Bilac' },
    { codigoTOM: 6229, codigoIBGE: 3506508, nomeTOM: 'BIRIGUI', nomeIBGE: 'Birigui' },
    { codigoTOM: 6231, codigoIBGE: 3506607, nomeTOM: 'BIRITIBA-MIRIM', nomeIBGE: 'Biritiba Mirim' },
    { codigoTOM: 6233, codigoIBGE: 3506706, nomeTOM: 'BOA ESPERANÇA DO SUL', nomeIBGE: 'Boa Esperança do Sul' },
    { codigoTOM: 6235, codigoIBGE: 3506805, nomeTOM: 'BOCAINA', nomeIBGE: 'Bocaina' },
    { codigoTOM: 6237, codigoIBGE: 3506904, nomeTOM: 'BOFETE', nomeIBGE: 'Bofete' },
    { codigoTOM: 6239, codigoIBGE: 3507001, nomeTOM: 'BOITUVA', nomeIBGE: 'Boituva' },
    { codigoTOM: 6241, codigoIBGE: 3507100, nomeTOM: 'BOM JESUS DOS PERDÕES', nomeIBGE: 'Bom Jesus dos Perdões' },
    { codigoTOM: 6243, codigoIBGE: 3507209, nomeTOM: 'BORÁ', nomeIBGE: 'Borá' },
    { codigoTOM: 6245, codigoIBGE: 3507308, nomeTOM: 'BORACÉIA', nomeIBGE: 'Boracéia' },
    { codigoTOM: 6247, codigoIBGE: 3507407, nomeTOM: 'BORBOREMA', nomeIBGE: 'Borborema' },
    { codigoTOM: 6249, codigoIBGE: 3507506, nomeTOM: 'BOTUCATU', nomeIBGE: 'Botucatu' },
    { codigoTOM: 6251, codigoIBGE: 3507605, nomeTOM: 'BRAGANÇA PAULISTA', nomeIBGE: 'Bragança Paulista' },
    { codigoTOM: 6255, codigoIBGE: 3507704, nomeTOM: 'BRAÚNA', nomeIBGE: 'Braúna' },
    { codigoTOM: 6257, codigoIBGE: 3507803, nomeTOM: 'BRODOWSKI', nomeIBGE: 'Brodowski' },
    { codigoTOM: 6259, codigoIBGE: 3507902, nomeTOM: 'BROTAS', nomeIBGE: 'Brotas' },
    { codigoTOM: 6261, codigoIBGE: 3508009, nomeTOM: 'BURI', nomeIBGE: 'Buri' },
    { codigoTOM: 6263, codigoIBGE: 3508108, nomeTOM: 'BURITAMA', nomeIBGE: 'Buritama' },
    { codigoTOM: 6265, codigoIBGE: 3508207, nomeTOM: 'BURITIZAL', nomeIBGE: 'Buritizal' },
    { codigoTOM: 6267, codigoIBGE: 3508306, nomeTOM: 'CABRÁLIA PAULISTA', nomeIBGE: 'Cabrália Paulista' },
    { codigoTOM: 6269, codigoIBGE: 3508405, nomeTOM: 'CABREÚVA', nomeIBGE: 'Cabreúva' },
    { codigoTOM: 6271, codigoIBGE: 3508504, nomeTOM: 'CAÇAPAVA', nomeIBGE: 'Caçapava' },
    { codigoTOM: 6273, codigoIBGE: 3508603, nomeTOM: 'CACHOEIRA PAULISTA', nomeIBGE: 'Cachoeira Paulista' },
    { codigoTOM: 6275, codigoIBGE: 3508702, nomeTOM: 'CACONDE', nomeIBGE: 'Caconde' },
    { codigoTOM: 6277, codigoIBGE: 3508801, nomeTOM: 'CAFELÂNDIA', nomeIBGE: 'Cafelândia' },
    { codigoTOM: 6279, codigoIBGE: 3508900, nomeTOM: 'CAIABU', nomeIBGE: 'Caiabu' },
    { codigoTOM: 6281, codigoIBGE: 3509007, nomeTOM: 'CAIEIRAS', nomeIBGE: 'Caieiras' },
    { codigoTOM: 6283, codigoIBGE: 3509106, nomeTOM: 'CAIUÁ', nomeIBGE: 'Caiuá' },
    { codigoTOM: 6285, codigoIBGE: 3509205, nomeTOM: 'CAJAMAR', nomeIBGE: 'Cajamar' },
    { codigoTOM: 6287, codigoIBGE: 3509304, nomeTOM: 'CAJOBI', nomeIBGE: 'Cajobi' },
    { codigoTOM: 6289, codigoIBGE: 3509403, nomeTOM: 'CAJURU', nomeIBGE: 'Cajuru' },
    { codigoTOM: 6291, codigoIBGE: 3509502, nomeTOM: 'CAMPINAS', nomeIBGE: 'Campinas' },
    { codigoTOM: 6293, codigoIBGE: 3509601, nomeTOM: 'CAMPO LIMPO PAULISTA', nomeIBGE: 'Campo Limpo Paulista' },
    { codigoTOM: 6295, codigoIBGE: 3509700, nomeTOM: 'CAMPOS DO JORDÃO', nomeIBGE: 'Campos do Jordão' },
    { codigoTOM: 6297, codigoIBGE: 3509809, nomeTOM: 'CAMPOS NOVOS PAULISTA', nomeIBGE: 'Campos Novos Paulista' },
    { codigoTOM: 6299, codigoIBGE: 3509908, nomeTOM: 'CANANÉIA', nomeIBGE: 'Cananéia' },
    { codigoTOM: 6301, codigoIBGE: 3510005, nomeTOM: 'CÂNDIDO MOTA', nomeIBGE: 'Cândido Mota' },
    { codigoTOM: 6303, codigoIBGE: 3510104, nomeTOM: 'CÂNDIDO RODRIGUES', nomeIBGE: 'Cândido Rodrigues' },
    { codigoTOM: 6305, codigoIBGE: 3510203, nomeTOM: 'CAPÃO BONITO', nomeIBGE: 'Capão Bonito' },
    { codigoTOM: 6307, codigoIBGE: 3510302, nomeTOM: 'CAPELA DO ALTO', nomeIBGE: 'Capela do Alto' },
    { codigoTOM: 6309, codigoIBGE: 3510401, nomeTOM: 'CAPIVARI', nomeIBGE: 'Capivari' },
    { codigoTOM: 6311, codigoIBGE: 3510500, nomeTOM: 'CARAGUATATUBA', nomeIBGE: 'Caraguatatuba' },
    { codigoTOM: 6313, codigoIBGE: 3510609, nomeTOM: 'CARAPICUÍBA', nomeIBGE: 'Carapicuíba' },
    { codigoTOM: 6315, codigoIBGE: 3510708, nomeTOM: 'CARDOSO', nomeIBGE: 'Cardoso' },
    { codigoTOM: 6317, codigoIBGE: 3510807, nomeTOM: 'CASA BRANCA', nomeIBGE: 'Casa Branca' },
    { codigoTOM: 6319, codigoIBGE: 3510906, nomeTOM: 'CÁSSIA DOS COQUEIROS', nomeIBGE: 'Cássia dos Coqueiros' },
    { codigoTOM: 6321, codigoIBGE: 3511003, nomeTOM: 'CASTILHO', nomeIBGE: 'Castilho' },
    { codigoTOM: 6323, codigoIBGE: 3511102, nomeTOM: 'CATANDUVA', nomeIBGE: 'Catanduva' },
    { codigoTOM: 6325, codigoIBGE: 3511201, nomeTOM: 'CATIGUÁ', nomeIBGE: 'Catiguá' },
    { codigoTOM: 6327, codigoIBGE: 3511300, nomeTOM: 'CEDRAL', nomeIBGE: 'Cedral' },
    { codigoTOM: 6329, codigoIBGE: 3511409, nomeTOM: 'CERQUEIRA CÉSAR', nomeIBGE: 'Cerqueira César' },
    { codigoTOM: 6331, codigoIBGE: 3511508, nomeTOM: 'CERQUILHO', nomeIBGE: 'Cerquilho' },
    { codigoTOM: 6333, codigoIBGE: 3511607, nomeTOM: 'CESÁRIO LANGE', nomeIBGE: 'Cesário Lange' },
    { codigoTOM: 6335, codigoIBGE: 3511706, nomeTOM: 'CHARQUEADA', nomeIBGE: 'Charqueada' },
    { codigoTOM: 6337, codigoIBGE: 3557204, nomeTOM: 'CHAVANTES', nomeIBGE: 'Chavantes' },
    { codigoTOM: 6339, codigoIBGE: 3511904, nomeTOM: 'CLEMENTINA', nomeIBGE: 'Clementina' },
    { codigoTOM: 6341, codigoIBGE: 3512001, nomeTOM: 'COLINA', nomeIBGE: 'Colina' },
    { codigoTOM: 6343, codigoIBGE: 3512100, nomeTOM: 'COLÔMBIA', nomeIBGE: 'Colômbia' },
    { codigoTOM: 6345, codigoIBGE: 3512209, nomeTOM: 'CONCHAL', nomeIBGE: 'Conchal' },
    { codigoTOM: 6347, codigoIBGE: 3512308, nomeTOM: 'CONCHAS', nomeIBGE: 'Conchas' },
    { codigoTOM: 6349, codigoIBGE: 3512407, nomeTOM: 'CORDEIRÓPOLIS', nomeIBGE: 'Cordeirópolis' },
    { codigoTOM: 6351, codigoIBGE: 3512506, nomeTOM: 'COROADOS', nomeIBGE: 'Coroados' },
    { codigoTOM: 6353, codigoIBGE: 3512605, nomeTOM: 'CORONEL MACEDO', nomeIBGE: 'Coronel Macedo' },
    { codigoTOM: 6355, codigoIBGE: 3512704, nomeTOM: 'CORUMBATAÍ', nomeIBGE: 'Corumbataí' },
    { codigoTOM: 6357, codigoIBGE: 3512803, nomeTOM: 'COSMÓPOLIS', nomeIBGE: 'Cosmópolis' },
    { codigoTOM: 6359, codigoIBGE: 3512902, nomeTOM: 'COSMORAMA', nomeIBGE: 'Cosmorama' },
    { codigoTOM: 6361, codigoIBGE: 3513009, nomeTOM: 'COTIA', nomeIBGE: 'Cotia' },
    { codigoTOM: 6363, codigoIBGE: 3513108, nomeTOM: 'CRAVINHOS', nomeIBGE: 'Cravinhos' },
    { codigoTOM: 6365, codigoIBGE: 3513207, nomeTOM: 'CRISTAIS PAULISTA', nomeIBGE: 'Cristais Paulista' },
    { codigoTOM: 6367, codigoIBGE: 3513306, nomeTOM: 'CRUZÁLIA', nomeIBGE: 'Cruzália' },
    { codigoTOM: 6369, codigoIBGE: 3513405, nomeTOM: 'CRUZEIRO', nomeIBGE: 'Cruzeiro' },
    { codigoTOM: 6371, codigoIBGE: 3513504, nomeTOM: 'CUBATÃO', nomeIBGE: 'Cubatão' },
    { codigoTOM: 6373, codigoIBGE: 3513603, nomeTOM: 'CUNHA', nomeIBGE: 'Cunha' },
    { codigoTOM: 6375, codigoIBGE: 3513702, nomeTOM: 'DESCALVADO', nomeIBGE: 'Descalvado' },
    { codigoTOM: 6377, codigoIBGE: 3513801, nomeTOM: 'DIADEMA', nomeIBGE: 'Diadema' },
    { codigoTOM: 6379, codigoIBGE: 3513900, nomeTOM: 'DIVINOLÂNDIA', nomeIBGE: 'Divinolândia' },
    { codigoTOM: 6381, codigoIBGE: 3514007, nomeTOM: 'DOBRADA', nomeIBGE: 'Dobrada' },
    { codigoTOM: 6383, codigoIBGE: 3514106, nomeTOM: 'DOIS CÓRREGOS', nomeIBGE: 'Dois Córregos' },
    { codigoTOM: 6385, codigoIBGE: 3514205, nomeTOM: 'DOLCINÓPOLIS', nomeIBGE: 'Dolcinópolis' },
    { codigoTOM: 6387, codigoIBGE: 3514304, nomeTOM: 'DOURADO', nomeIBGE: 'Dourado' },
    { codigoTOM: 6389, codigoIBGE: 3514403, nomeTOM: 'DRACENA', nomeIBGE: 'Dracena' },
    { codigoTOM: 6391, codigoIBGE: 3514502, nomeTOM: 'DUARTINA', nomeIBGE: 'Duartina' },
    { codigoTOM: 6393, codigoIBGE: 3514601, nomeTOM: 'DUMONT', nomeIBGE: 'Dumont' },
    { codigoTOM: 6395, codigoIBGE: 3514700, nomeTOM: 'ECHAPORÃ', nomeIBGE: 'Echaporã' },
    { codigoTOM: 6397, codigoIBGE: 3514809, nomeTOM: 'ELDORADO', nomeIBGE: 'Eldorado' },
    { codigoTOM: 6399, codigoIBGE: 3514908, nomeTOM: 'ELIAS FAUSTO', nomeIBGE: 'Elias Fausto' },
    { codigoTOM: 6401, codigoIBGE: 3515004, nomeTOM: 'EMBU DAS ARTES', nomeIBGE: 'Embu das Artes' },
    { codigoTOM: 6403, codigoIBGE: 3515103, nomeTOM: 'EMBU-GUAÇU', nomeIBGE: 'Embu-Guaçu' },
    { codigoTOM: 6405, codigoIBGE: 3515202, nomeTOM: "ESTRELA D'OESTE", nomeIBGE: "Estrela d'Oeste" },
    { codigoTOM: 6407, codigoIBGE: 3515301, nomeTOM: 'ESTRELA DO NORTE', nomeIBGE: 'Estrela do Norte' },
    { codigoTOM: 6409, codigoIBGE: 3515400, nomeTOM: 'FARTURA', nomeIBGE: 'Fartura' },
    { codigoTOM: 6411, codigoIBGE: 3515509, nomeTOM: 'FERNANDÓPOLIS', nomeIBGE: 'Fernandópolis' },
    { codigoTOM: 6413, codigoIBGE: 3515608, nomeTOM: 'FERNANDO PRESTES', nomeIBGE: 'Fernando Prestes' },
    { codigoTOM: 6415, codigoIBGE: 3515707, nomeTOM: 'FERRAZ DE VASCONCELOS', nomeIBGE: 'Ferraz de Vasconcelos' },
    { codigoTOM: 6417, codigoIBGE: 3515806, nomeTOM: 'FLORA RICA', nomeIBGE: 'Flora Rica' },
    { codigoTOM: 6419, codigoIBGE: 3515905, nomeTOM: 'FLOREAL', nomeIBGE: 'Floreal' },
    { codigoTOM: 6421, codigoIBGE: 3516002, nomeTOM: 'FLÓRIDA PAULISTA', nomeIBGE: 'Flórida Paulista' },
    { codigoTOM: 6423, codigoIBGE: 3516101, nomeTOM: 'FLORÍNEA', nomeIBGE: 'Florínea' },
    { codigoTOM: 6425, codigoIBGE: 3516200, nomeTOM: 'FRANCA', nomeIBGE: 'Franca' },
    { codigoTOM: 6427, codigoIBGE: 3516309, nomeTOM: 'FRANCISCO MORATO', nomeIBGE: 'Francisco Morato' },
    { codigoTOM: 6429, codigoIBGE: 3516408, nomeTOM: 'FRANCO DA ROCHA', nomeIBGE: 'Franco da Rocha' },
    { codigoTOM: 6431, codigoIBGE: 3516507, nomeTOM: 'GABRIEL MONTEIRO', nomeIBGE: 'Gabriel Monteiro' },
    { codigoTOM: 6433, codigoIBGE: 3516606, nomeTOM: 'GÁLIA', nomeIBGE: 'Gália' },
    { codigoTOM: 6435, codigoIBGE: 3516705, nomeTOM: 'GARÇA', nomeIBGE: 'Garça' },
    { codigoTOM: 6437, codigoIBGE: 3516804, nomeTOM: 'GASTÃO VIDIGAL', nomeIBGE: 'Gastão Vidigal' },
    { codigoTOM: 6439, codigoIBGE: 3516903, nomeTOM: 'GENERAL SALGADO', nomeIBGE: 'General Salgado' },
    { codigoTOM: 6441, codigoIBGE: 3517000, nomeTOM: 'GETULINA', nomeIBGE: 'Getulina' },
    { codigoTOM: 6443, codigoIBGE: 3517109, nomeTOM: 'GLICÉRIO', nomeIBGE: 'Glicério' },
    { codigoTOM: 6445, codigoIBGE: 3517208, nomeTOM: 'GUAIÇARA', nomeIBGE: 'Guaiçara' },
    { codigoTOM: 6447, codigoIBGE: 3517307, nomeTOM: 'GUAIMBÊ', nomeIBGE: 'Guaimbê' },
    { codigoTOM: 6449, codigoIBGE: 3517406, nomeTOM: 'GUAÍRA', nomeIBGE: 'Guaíra' },
    { codigoTOM: 6451, codigoIBGE: 3517505, nomeTOM: 'GUAPIAÇU', nomeIBGE: 'Guapiaçu' },
    { codigoTOM: 6453, codigoIBGE: 3517604, nomeTOM: 'GUAPIARA', nomeIBGE: 'Guapiara' },
    { codigoTOM: 6455, codigoIBGE: 3517703, nomeTOM: 'GUARÁ', nomeIBGE: 'Guará' },
    { codigoTOM: 6457, codigoIBGE: 3517802, nomeTOM: 'GUARAÇAÍ', nomeIBGE: 'Guaraçaí' },
    { codigoTOM: 6459, codigoIBGE: 3517901, nomeTOM: 'GUARACI', nomeIBGE: 'Guaraci' },
    { codigoTOM: 6461, codigoIBGE: 3518008, nomeTOM: "GUARANI D'OESTE", nomeIBGE: "Guarani d'Oeste" },
    { codigoTOM: 6463, codigoIBGE: 3518107, nomeTOM: 'GUARANTÃ', nomeIBGE: 'Guarantã' },
    { codigoTOM: 6465, codigoIBGE: 3518206, nomeTOM: 'GUARARAPES', nomeIBGE: 'Guararapes' },
    { codigoTOM: 6467, codigoIBGE: 3518305, nomeTOM: 'GUARAREMA', nomeIBGE: 'Guararema' },
    { codigoTOM: 6469, codigoIBGE: 3518404, nomeTOM: 'GUARATINGUETÁ', nomeIBGE: 'Guaratinguetá' },
    { codigoTOM: 6471, codigoIBGE: 3518503, nomeTOM: 'GUAREÍ', nomeIBGE: 'Guareí' },
    { codigoTOM: 6473, codigoIBGE: 3518602, nomeTOM: 'GUARIBA', nomeIBGE: 'Guariba' },
    { codigoTOM: 6475, codigoIBGE: 3518701, nomeTOM: 'GUARUJÁ', nomeIBGE: 'Guarujá' },
    { codigoTOM: 6477, codigoIBGE: 3518800, nomeTOM: 'GUARULHOS', nomeIBGE: 'Guarulhos' },
    { codigoTOM: 6479, codigoIBGE: 3518909, nomeTOM: 'GUZOLÂNDIA', nomeIBGE: 'Guzolândia' },
    { codigoTOM: 6481, codigoIBGE: 3519006, nomeTOM: 'HERCULÂNDIA', nomeIBGE: 'Herculândia' },
    { codigoTOM: 6483, codigoIBGE: 3519105, nomeTOM: 'IACANGA', nomeIBGE: 'Iacanga' },
    { codigoTOM: 6485, codigoIBGE: 3519204, nomeTOM: 'IACRI', nomeIBGE: 'Iacri' },
    { codigoTOM: 6487, codigoIBGE: 3519303, nomeTOM: 'IBATÉ', nomeIBGE: 'Ibaté' },
    { codigoTOM: 6489, codigoIBGE: 3519402, nomeTOM: 'IBIRÁ', nomeIBGE: 'Ibirá' },
    { codigoTOM: 6491, codigoIBGE: 3519501, nomeTOM: 'IBIRAREMA', nomeIBGE: 'Ibirarema' },
    { codigoTOM: 6493, codigoIBGE: 3519600, nomeTOM: 'IBITINGA', nomeIBGE: 'Ibitinga' },
    { codigoTOM: 6495, codigoIBGE: 3519709, nomeTOM: 'IBIÚNA', nomeIBGE: 'Ibiúna' },
    { codigoTOM: 6497, codigoIBGE: 3519808, nomeTOM: 'ICÉM', nomeIBGE: 'Icém' },
    { codigoTOM: 6499, codigoIBGE: 3519907, nomeTOM: 'IEPÊ', nomeIBGE: 'Iepê' },
    { codigoTOM: 6501, codigoIBGE: 3520004, nomeTOM: 'IGARAÇU DO TIETÊ', nomeIBGE: 'Igaraçu do Tietê' },
    { codigoTOM: 6503, codigoIBGE: 3520103, nomeTOM: 'IGARAPAVA', nomeIBGE: 'Igarapava' },
    { codigoTOM: 6505, codigoIBGE: 3520202, nomeTOM: 'IGARATÁ', nomeIBGE: 'Igaratá' },
    { codigoTOM: 6507, codigoIBGE: 3520301, nomeTOM: 'IGUAPE', nomeIBGE: 'Iguape' },
    { codigoTOM: 6509, codigoIBGE: 3520400, nomeTOM: 'ILHABELA', nomeIBGE: 'Ilhabela' },
    { codigoTOM: 6511, codigoIBGE: 3520509, nomeTOM: 'INDAIATUBA', nomeIBGE: 'Indaiatuba' },
    { codigoTOM: 6513, codigoIBGE: 3520608, nomeTOM: 'INDIANA', nomeIBGE: 'Indiana' },
    { codigoTOM: 6515, codigoIBGE: 3520707, nomeTOM: 'INDIAPORÃ', nomeIBGE: 'Indiaporã' },
    { codigoTOM: 6517, codigoIBGE: 3520806, nomeTOM: 'INÚBIA PAULISTA', nomeIBGE: 'Inúbia Paulista' },
    { codigoTOM: 6519, codigoIBGE: 3520905, nomeTOM: 'IPAUSSU', nomeIBGE: 'Ipaussu' },
    { codigoTOM: 6521, codigoIBGE: 3521002, nomeTOM: 'IPERÓ', nomeIBGE: 'Iperó' },
    { codigoTOM: 6523, codigoIBGE: 3521101, nomeTOM: 'IPEÚNA', nomeIBGE: 'Ipeúna' },
    { codigoTOM: 6525, codigoIBGE: 3521200, nomeTOM: 'IPORANGA', nomeIBGE: 'Iporanga' },
    { codigoTOM: 6527, codigoIBGE: 3521309, nomeTOM: 'IPUÃ', nomeIBGE: 'Ipuã' },
    { codigoTOM: 6529, codigoIBGE: 3521408, nomeTOM: 'IRACEMÁPOLIS', nomeIBGE: 'Iracemápolis' },
    { codigoTOM: 6531, codigoIBGE: 3521507, nomeTOM: 'IRAPUÃ', nomeIBGE: 'Irapuã' },
    { codigoTOM: 6533, codigoIBGE: 3521606, nomeTOM: 'IRAPURU', nomeIBGE: 'Irapuru' },
    { codigoTOM: 6535, codigoIBGE: 3521705, nomeTOM: 'ITABERÁ', nomeIBGE: 'Itaberá' },
    { codigoTOM: 6537, codigoIBGE: 3521804, nomeTOM: 'ITAÍ', nomeIBGE: 'Itaí' },
    { codigoTOM: 6539, codigoIBGE: 3521903, nomeTOM: 'ITAJOBI', nomeIBGE: 'Itajobi' },
    { codigoTOM: 6541, codigoIBGE: 3522000, nomeTOM: 'ITAJU', nomeIBGE: 'Itaju' },
    { codigoTOM: 6543, codigoIBGE: 3522109, nomeTOM: 'ITANHAÉM', nomeIBGE: 'Itanhaém' },
    { codigoTOM: 6545, codigoIBGE: 3522208, nomeTOM: 'ITAPECERICA DA SERRA', nomeIBGE: 'Itapecerica da Serra' },
    { codigoTOM: 6547, codigoIBGE: 3522307, nomeTOM: 'ITAPETININGA', nomeIBGE: 'Itapetininga' },
    { codigoTOM: 6549, codigoIBGE: 3522406, nomeTOM: 'ITAPEVA', nomeIBGE: 'Itapeva' },
    { codigoTOM: 6551, codigoIBGE: 3522505, nomeTOM: 'ITAPEVI', nomeIBGE: 'Itapevi' },
    { codigoTOM: 6553, codigoIBGE: 3522604, nomeTOM: 'ITAPIRA', nomeIBGE: 'Itapira' },
    { codigoTOM: 6555, codigoIBGE: 3522703, nomeTOM: 'ITÁPOLIS', nomeIBGE: 'Itápolis' },
    { codigoTOM: 6557, codigoIBGE: 3522802, nomeTOM: 'ITAPORANGA', nomeIBGE: 'Itaporanga' },
    { codigoTOM: 6559, codigoIBGE: 3522901, nomeTOM: 'ITAPUÍ', nomeIBGE: 'Itapuí' },
    { codigoTOM: 6561, codigoIBGE: 3523008, nomeTOM: 'ITAPURA', nomeIBGE: 'Itapura' },
    { codigoTOM: 6563, codigoIBGE: 3523107, nomeTOM: 'ITAQUAQUECETUBA', nomeIBGE: 'Itaquaquecetuba' },
    { codigoTOM: 6565, codigoIBGE: 3523206, nomeTOM: 'ITARARÉ', nomeIBGE: 'Itararé' },
    { codigoTOM: 6567, codigoIBGE: 3523305, nomeTOM: 'ITARIRI', nomeIBGE: 'Itariri' },
    { codigoTOM: 6569, codigoIBGE: 3523404, nomeTOM: 'ITATIBA', nomeIBGE: 'Itatiba' },
    { codigoTOM: 6571, codigoIBGE: 3523503, nomeTOM: 'ITATINGA', nomeIBGE: 'Itatinga' },
    { codigoTOM: 6573, codigoIBGE: 3523602, nomeTOM: 'ITIRAPINA', nomeIBGE: 'Itirapina' },
    { codigoTOM: 6575, codigoIBGE: 3523701, nomeTOM: 'ITIRAPUÃ', nomeIBGE: 'Itirapuã' },
    { codigoTOM: 6577, codigoIBGE: 3523800, nomeTOM: 'ITOBI', nomeIBGE: 'Itobi' },
    { codigoTOM: 6579, codigoIBGE: 3523909, nomeTOM: 'ITU', nomeIBGE: 'Itu' },
    { codigoTOM: 6581, codigoIBGE: 3524006, nomeTOM: 'ITUPEVA', nomeIBGE: 'Itupeva' },
    { codigoTOM: 6583, codigoIBGE: 3524105, nomeTOM: 'ITUVERAVA', nomeIBGE: 'Ituverava' },
    { codigoTOM: 6585, codigoIBGE: 3524204, nomeTOM: 'JABORANDI', nomeIBGE: 'Jaborandi' },
    { codigoTOM: 6587, codigoIBGE: 3524303, nomeTOM: 'JABOTICABAL', nomeIBGE: 'Jaboticabal' },
    { codigoTOM: 6589, codigoIBGE: 3524402, nomeTOM: 'JACAREÍ', nomeIBGE: 'Jacareí' },
    { codigoTOM: 6591, codigoIBGE: 3524501, nomeTOM: 'JACI', nomeIBGE: 'Jaci' },
    { codigoTOM: 6593, codigoIBGE: 3524600, nomeTOM: 'JACUPIRANGA', nomeIBGE: 'Jacupiranga' },
    { codigoTOM: 6595, codigoIBGE: 3524709, nomeTOM: 'JAGUARIÚNA', nomeIBGE: 'Jaguariúna' },
    { codigoTOM: 6597, codigoIBGE: 3524808, nomeTOM: 'JALES', nomeIBGE: 'Jales' },
    { codigoTOM: 6599, codigoIBGE: 3524907, nomeTOM: 'JAMBEIRO', nomeIBGE: 'Jambeiro' },
    { codigoTOM: 6601, codigoIBGE: 3525003, nomeTOM: 'JANDIRA', nomeIBGE: 'Jandira' },
    { codigoTOM: 6603, codigoIBGE: 3525102, nomeTOM: 'JARDINÓPOLIS', nomeIBGE: 'Jardinópolis' },
    { codigoTOM: 6605, codigoIBGE: 3525201, nomeTOM: 'JARINU', nomeIBGE: 'Jarinu' },
    { codigoTOM: 6607, codigoIBGE: 3525300, nomeTOM: 'JAÚ', nomeIBGE: 'Jaú' },
    { codigoTOM: 6609, codigoIBGE: 3525409, nomeTOM: 'JERIQUARA', nomeIBGE: 'Jeriquara' },
    { codigoTOM: 6611, codigoIBGE: 3525508, nomeTOM: 'JOANÓPOLIS', nomeIBGE: 'Joanópolis' },
    { codigoTOM: 6613, codigoIBGE: 3525607, nomeTOM: 'JOÃO RAMALHO', nomeIBGE: 'João Ramalho' },
    { codigoTOM: 6615, codigoIBGE: 3525706, nomeTOM: 'JOSÉ BONIFÁCIO', nomeIBGE: 'José Bonifácio' },
    { codigoTOM: 6617, codigoIBGE: 3525805, nomeTOM: 'JÚLIO MESQUITA', nomeIBGE: 'Júlio Mesquita' },
    { codigoTOM: 6619, codigoIBGE: 3525904, nomeTOM: 'JUNDIAÍ', nomeIBGE: 'Jundiaí' },
    { codigoTOM: 6621, codigoIBGE: 3526001, nomeTOM: 'JUNQUEIRÓPOLIS', nomeIBGE: 'Junqueirópolis' },
    { codigoTOM: 6623, codigoIBGE: 3526100, nomeTOM: 'JUQUIÁ', nomeIBGE: 'Juquiá' },
    { codigoTOM: 6625, codigoIBGE: 3526209, nomeTOM: 'JUQUITIBA', nomeIBGE: 'Juquitiba' },
    { codigoTOM: 6627, codigoIBGE: 3526308, nomeTOM: 'LAGOINHA', nomeIBGE: 'Lagoinha' },
    { codigoTOM: 6629, codigoIBGE: 3526407, nomeTOM: 'LARANJAL PAULISTA', nomeIBGE: 'Laranjal Paulista' },
    { codigoTOM: 6631, codigoIBGE: 3526506, nomeTOM: 'LAVÍNIA', nomeIBGE: 'Lavínia' },
    { codigoTOM: 6633, codigoIBGE: 3526605, nomeTOM: 'LAVRINHAS', nomeIBGE: 'Lavrinhas' },
    { codigoTOM: 6635, codigoIBGE: 3526704, nomeTOM: 'LEME', nomeIBGE: 'Leme' },
    { codigoTOM: 6637, codigoIBGE: 3526803, nomeTOM: 'LENÇÓIS PAULISTA', nomeIBGE: 'Lençóis Paulista' },
    { codigoTOM: 6639, codigoIBGE: 3526902, nomeTOM: 'LIMEIRA', nomeIBGE: 'Limeira' },
    { codigoTOM: 6641, codigoIBGE: 3527009, nomeTOM: 'LINDÓIA', nomeIBGE: 'Lindóia' },
    { codigoTOM: 6643, codigoIBGE: 3527108, nomeTOM: 'LINS', nomeIBGE: 'Lins' },
    { codigoTOM: 6645, codigoIBGE: 3527207, nomeTOM: 'LORENA', nomeIBGE: 'Lorena' },
    { codigoTOM: 6647, codigoIBGE: 3527306, nomeTOM: 'LOUVEIRA', nomeIBGE: 'Louveira' },
    { codigoTOM: 6649, codigoIBGE: 3527405, nomeTOM: 'LUCÉLIA', nomeIBGE: 'Lucélia' },
    { codigoTOM: 6651, codigoIBGE: 3527504, nomeTOM: 'LUCIANÓPOLIS', nomeIBGE: 'Lucianópolis' },
    { codigoTOM: 6653, codigoIBGE: 3527603, nomeTOM: 'LUÍS ANTÔNIO', nomeIBGE: 'Luís Antônio' },
    { codigoTOM: 6655, codigoIBGE: 3527702, nomeTOM: 'LUIZIÂNIA', nomeIBGE: 'Luiziânia' },
    { codigoTOM: 6657, codigoIBGE: 3527801, nomeTOM: 'LUPÉRCIO', nomeIBGE: 'Lupércio' },
    { codigoTOM: 6659, codigoIBGE: 3527900, nomeTOM: 'LUTÉCIA', nomeIBGE: 'Lutécia' },
    { codigoTOM: 6661, codigoIBGE: 3528007, nomeTOM: 'MACATUBA', nomeIBGE: 'Macatuba' },
    { codigoTOM: 6663, codigoIBGE: 3528106, nomeTOM: 'MACAUBAL', nomeIBGE: 'Macaubal' },
    { codigoTOM: 6665, codigoIBGE: 3528205, nomeTOM: 'MACEDÔNIA', nomeIBGE: 'Macedônia' },
    { codigoTOM: 6667, codigoIBGE: 3528304, nomeTOM: 'MAGDA', nomeIBGE: 'Magda' },
    { codigoTOM: 6669, codigoIBGE: 3528403, nomeTOM: 'MAIRINQUE', nomeIBGE: 'Mairinque' },
    { codigoTOM: 6671, codigoIBGE: 3528502, nomeTOM: 'MAIRIPORÃ', nomeIBGE: 'Mairiporã' },
    { codigoTOM: 6673, codigoIBGE: 3528601, nomeTOM: 'MANDURI', nomeIBGE: 'Manduri' },
    { codigoTOM: 6675, codigoIBGE: 3528700, nomeTOM: 'MARABÁ PAULISTA', nomeIBGE: 'Marabá Paulista' },
    { codigoTOM: 6677, codigoIBGE: 3528809, nomeTOM: 'MARACAÍ', nomeIBGE: 'Maracaí' },
    { codigoTOM: 6679, codigoIBGE: 3528908, nomeTOM: 'MARIÁPOLIS', nomeIBGE: 'Mariápolis' },
    { codigoTOM: 6681, codigoIBGE: 3529005, nomeTOM: 'MARÍLIA', nomeIBGE: 'Marília' },
    { codigoTOM: 6683, codigoIBGE: 3529104, nomeTOM: 'MARINÓPOLIS', nomeIBGE: 'Marinópolis' },
    { codigoTOM: 6685, codigoIBGE: 3529203, nomeTOM: 'MARTINÓPOLIS', nomeIBGE: 'Martinópolis' },
    { codigoTOM: 6687, codigoIBGE: 3529302, nomeTOM: 'MATÃO', nomeIBGE: 'Matão' },
    { codigoTOM: 6689, codigoIBGE: 3529401, nomeTOM: 'MAUÁ', nomeIBGE: 'Mauá' },
    { codigoTOM: 6691, codigoIBGE: 3529500, nomeTOM: 'MENDONÇA', nomeIBGE: 'Mendonça' },
    { codigoTOM: 6693, codigoIBGE: 3529609, nomeTOM: 'MERIDIANO', nomeIBGE: 'Meridiano' },
    { codigoTOM: 6695, codigoIBGE: 3529708, nomeTOM: 'MIGUELÓPOLIS', nomeIBGE: 'Miguelópolis' },
    { codigoTOM: 6697, codigoIBGE: 3529807, nomeTOM: 'MINEIROS DO TIETÊ', nomeIBGE: 'Mineiros do Tietê' },
    { codigoTOM: 6699, codigoIBGE: 3529906, nomeTOM: 'MIRACATU', nomeIBGE: 'Miracatu' },
    { codigoTOM: 6701, codigoIBGE: 3530003, nomeTOM: 'MIRA ESTRELA', nomeIBGE: 'Mira Estrela' },
    { codigoTOM: 6703, codigoIBGE: 3530102, nomeTOM: 'MIRANDÓPOLIS', nomeIBGE: 'Mirandópolis' },
    { codigoTOM: 6705, codigoIBGE: 3530201, nomeTOM: 'MIRANTE DO PARANAPANEMA', nomeIBGE: 'Mirante do Paranapanema' },
    { codigoTOM: 6707, codigoIBGE: 3530300, nomeTOM: 'MIRASSOL', nomeIBGE: 'Mirassol' },
    { codigoTOM: 6709, codigoIBGE: 3530409, nomeTOM: 'MIRASSOLÂNDIA', nomeIBGE: 'Mirassolândia' },
    { codigoTOM: 6711, codigoIBGE: 3530508, nomeTOM: 'MOCOCA', nomeIBGE: 'Mococa' },
    { codigoTOM: 6713, codigoIBGE: 3530607, nomeTOM: 'MOGI DAS CRUZES', nomeIBGE: 'Mogi das Cruzes' },
    { codigoTOM: 6715, codigoIBGE: 3530706, nomeTOM: 'MOGI-GUACU', nomeIBGE: 'Mogi Guaçu' },
    { codigoTOM: 6717, codigoIBGE: 3530805, nomeTOM: 'Mogi Mirim', nomeIBGE: 'Mogi Mirim' },
    { codigoTOM: 6719, codigoIBGE: 3530904, nomeTOM: 'MOMBUCA', nomeIBGE: 'Mombuca' },
    { codigoTOM: 6721, codigoIBGE: 3531001, nomeTOM: 'MONÇÕES', nomeIBGE: 'Monções' },
    { codigoTOM: 6723, codigoIBGE: 3531100, nomeTOM: 'MONGAGUÁ', nomeIBGE: 'Mongaguá' },
    { codigoTOM: 6725, codigoIBGE: 3531209, nomeTOM: 'MONTE ALEGRE DO SUL', nomeIBGE: 'Monte Alegre do Sul' },
    { codigoTOM: 6727, codigoIBGE: 3531308, nomeTOM: 'MONTE ALTO', nomeIBGE: 'Monte Alto' },
    { codigoTOM: 6729, codigoIBGE: 3531407, nomeTOM: 'MONTE APRAZÍVEL', nomeIBGE: 'Monte Aprazível' },
    { codigoTOM: 6731, codigoIBGE: 3531506, nomeTOM: 'MONTE AZUL PAULISTA', nomeIBGE: 'Monte Azul Paulista' },
    { codigoTOM: 6733, codigoIBGE: 3531605, nomeTOM: 'MONTE CASTELO', nomeIBGE: 'Monte Castelo' },
    { codigoTOM: 6735, codigoIBGE: 3531704, nomeTOM: 'MONTEIRO LOBATO', nomeIBGE: 'Monteiro Lobato' },
    { codigoTOM: 6737, codigoIBGE: 3531803, nomeTOM: 'MONTE MOR', nomeIBGE: 'Monte Mor' },
    { codigoTOM: 6739, codigoIBGE: 3531902, nomeTOM: 'MORRO AGUDO', nomeIBGE: 'Morro Agudo' },
    { codigoTOM: 6741, codigoIBGE: 3532009, nomeTOM: 'MORUNGABA', nomeIBGE: 'Morungaba' },
    { codigoTOM: 6743, codigoIBGE: 3532108, nomeTOM: 'MURUTINGA DO SUL', nomeIBGE: 'Murutinga do Sul' },
    { codigoTOM: 6745, codigoIBGE: 3532207, nomeTOM: 'NARANDIBA', nomeIBGE: 'Narandiba' },
    { codigoTOM: 6747, codigoIBGE: 3532306, nomeTOM: 'NATIVIDADE DA SERRA', nomeIBGE: 'Natividade da Serra' },
    { codigoTOM: 6749, codigoIBGE: 3532405, nomeTOM: 'NAZARÉ PAULISTA', nomeIBGE: 'Nazaré Paulista' },
    { codigoTOM: 6751, codigoIBGE: 3532504, nomeTOM: 'NEVES PAULISTA', nomeIBGE: 'Neves Paulista' },
    { codigoTOM: 6753, codigoIBGE: 3532603, nomeTOM: 'NHANDEARA', nomeIBGE: 'Nhandeara' },
    { codigoTOM: 6755, codigoIBGE: 3532702, nomeTOM: 'NIPOÃ', nomeIBGE: 'Nipoã' },
    { codigoTOM: 6757, codigoIBGE: 3532801, nomeTOM: 'NOVA ALIANÇA', nomeIBGE: 'Nova Aliança' },
    { codigoTOM: 6759, codigoIBGE: 3532900, nomeTOM: 'NOVA EUROPA', nomeIBGE: 'Nova Europa' },
    { codigoTOM: 6761, codigoIBGE: 3533007, nomeTOM: 'NOVA GRANADA', nomeIBGE: 'Nova Granada' },
    { codigoTOM: 6763, codigoIBGE: 3533106, nomeTOM: 'NOVA GUATAPORANGA', nomeIBGE: 'Nova Guataporanga' },
    { codigoTOM: 6765, codigoIBGE: 3533205, nomeTOM: 'NOVA INDEPENDÊNCIA', nomeIBGE: 'Nova Independência' },
    { codigoTOM: 6767, codigoIBGE: 3533304, nomeTOM: 'NOVA LUZITÂNIA', nomeIBGE: 'Nova Luzitânia' },
    { codigoTOM: 6769, codigoIBGE: 3533403, nomeTOM: 'NOVA ODESSA', nomeIBGE: 'Nova Odessa' },
    { codigoTOM: 6771, codigoIBGE: 3533502, nomeTOM: 'NOVO HORIZONTE', nomeIBGE: 'Novo Horizonte' },
    { codigoTOM: 6773, codigoIBGE: 3533601, nomeTOM: 'NUPORANGA', nomeIBGE: 'Nuporanga' },
    { codigoTOM: 6775, codigoIBGE: 3533700, nomeTOM: 'OCAUÇU', nomeIBGE: 'Ocauçu' },
    { codigoTOM: 6777, codigoIBGE: 3533809, nomeTOM: 'ÓLEO', nomeIBGE: 'Óleo' },
    { codigoTOM: 6779, codigoIBGE: 3533908, nomeTOM: 'OLÍMPIA', nomeIBGE: 'Olímpia' },
    { codigoTOM: 6781, codigoIBGE: 3534005, nomeTOM: 'ONDA VERDE', nomeIBGE: 'Onda Verde' },
    { codigoTOM: 6783, codigoIBGE: 3534104, nomeTOM: 'ORIENTE', nomeIBGE: 'Oriente' },
    { codigoTOM: 6785, codigoIBGE: 3534203, nomeTOM: 'ORINDIÚVA', nomeIBGE: 'Orindiúva' },
    { codigoTOM: 6787, codigoIBGE: 3534302, nomeTOM: 'ORLÂNDIA', nomeIBGE: 'Orlândia' },
    { codigoTOM: 6789, codigoIBGE: 3534401, nomeTOM: 'OSASCO', nomeIBGE: 'Osasco' },
    { codigoTOM: 6791, codigoIBGE: 3534500, nomeTOM: 'OSCAR BRESSANE', nomeIBGE: 'Oscar Bressane' },
    { codigoTOM: 6793, codigoIBGE: 3534609, nomeTOM: 'OSVALDO CRUZ', nomeIBGE: 'Osvaldo Cruz' },
    { codigoTOM: 6795, codigoIBGE: 3534708, nomeTOM: 'OURINHOS', nomeIBGE: 'Ourinhos' },
    { codigoTOM: 6797, codigoIBGE: 3534807, nomeTOM: 'OURO VERDE', nomeIBGE: 'Ouro Verde' },
    { codigoTOM: 6799, codigoIBGE: 3534906, nomeTOM: 'PACAEMBU', nomeIBGE: 'Pacaembu' },
    { codigoTOM: 6801, codigoIBGE: 3535002, nomeTOM: 'PALESTINA', nomeIBGE: 'Palestina' },
    { codigoTOM: 6803, codigoIBGE: 3535101, nomeTOM: 'PALMARES PAULISTA', nomeIBGE: 'Palmares Paulista' },
    { codigoTOM: 6805, codigoIBGE: 3535200, nomeTOM: "PALMEIRA D'OESTE", nomeIBGE: "Palmeira d'Oeste" },
    { codigoTOM: 6807, codigoIBGE: 3535309, nomeTOM: 'PALMITAL', nomeIBGE: 'Palmital' },
    { codigoTOM: 6809, codigoIBGE: 3535408, nomeTOM: 'PANORAMA', nomeIBGE: 'Panorama' },
    { codigoTOM: 6811, codigoIBGE: 3535507, nomeTOM: 'PARAGUAÇU PAULISTA', nomeIBGE: 'Paraguaçu Paulista' },
    { codigoTOM: 6813, codigoIBGE: 3535606, nomeTOM: 'PARAIBUNA', nomeIBGE: 'Paraibuna' },
    { codigoTOM: 6815, codigoIBGE: 3535705, nomeTOM: 'PARAÍSO', nomeIBGE: 'Paraíso' },
    { codigoTOM: 6817, codigoIBGE: 3535804, nomeTOM: 'PARANAPANEMA', nomeIBGE: 'Paranapanema' },
    { codigoTOM: 6819, codigoIBGE: 3535903, nomeTOM: 'PARANAPUÃ', nomeIBGE: 'Paranapuã' },
    { codigoTOM: 6821, codigoIBGE: 3536000, nomeTOM: 'PARAPUÃ', nomeIBGE: 'Parapuã' },
    { codigoTOM: 6823, codigoIBGE: 3536109, nomeTOM: 'PARDINHO', nomeIBGE: 'Pardinho' },
    { codigoTOM: 6825, codigoIBGE: 3536208, nomeTOM: 'PARIQUERA-AÇU', nomeIBGE: 'Pariquera-Açu' },
    { codigoTOM: 6827, codigoIBGE: 3536307, nomeTOM: 'PATROCÍNIO PAULISTA', nomeIBGE: 'Patrocínio Paulista' },
    { codigoTOM: 6829, codigoIBGE: 3536406, nomeTOM: 'PAULICÉIA', nomeIBGE: 'Paulicéia' },
    { codigoTOM: 6831, codigoIBGE: 3536505, nomeTOM: 'PAULÍNIA', nomeIBGE: 'Paulínia' },
    { codigoTOM: 6833, codigoIBGE: 3536604, nomeTOM: 'PAULO DE FARIA', nomeIBGE: 'Paulo de Faria' },
    { codigoTOM: 6835, codigoIBGE: 3536703, nomeTOM: 'PEDERNEIRAS', nomeIBGE: 'Pederneiras' },
    { codigoTOM: 6837, codigoIBGE: 3536802, nomeTOM: 'PEDRA BELA', nomeIBGE: 'Pedra Bela' },
    { codigoTOM: 6839, codigoIBGE: 3536901, nomeTOM: 'PEDRANÓPOLIS', nomeIBGE: 'Pedranópolis' },
    { codigoTOM: 6841, codigoIBGE: 3537008, nomeTOM: 'PEDREGULHO', nomeIBGE: 'Pedregulho' },
    { codigoTOM: 6843, codigoIBGE: 3537107, nomeTOM: 'PEDREIRA', nomeIBGE: 'Pedreira' },
    { codigoTOM: 6845, codigoIBGE: 3537206, nomeTOM: 'PEDRO DE TOLEDO', nomeIBGE: 'Pedro de Toledo' },
    { codigoTOM: 6847, codigoIBGE: 3537305, nomeTOM: 'PENÁPOLIS', nomeIBGE: 'Penápolis' },
    { codigoTOM: 6849, codigoIBGE: 3537404, nomeTOM: 'PEREIRA BARRETO', nomeIBGE: 'Pereira Barreto' },
    { codigoTOM: 6851, codigoIBGE: 3537503, nomeTOM: 'PEREIRAS', nomeIBGE: 'Pereiras' },
    { codigoTOM: 6853, codigoIBGE: 3537602, nomeTOM: 'PERUÍBE', nomeIBGE: 'Peruíbe' },
    { codigoTOM: 6855, codigoIBGE: 3537701, nomeTOM: 'PIACATU', nomeIBGE: 'Piacatu' },
    { codigoTOM: 6857, codigoIBGE: 3537800, nomeTOM: 'PIEDADE', nomeIBGE: 'Piedade' },
    { codigoTOM: 6859, codigoIBGE: 3537909, nomeTOM: 'PILAR DO SUL', nomeIBGE: 'Pilar do Sul' },
    { codigoTOM: 6861, codigoIBGE: 3538006, nomeTOM: 'PINDAMONHANGABA', nomeIBGE: 'Pindamonhangaba' },
    { codigoTOM: 6863, codigoIBGE: 3538105, nomeTOM: 'PINDORAMA', nomeIBGE: 'Pindorama' },
    { codigoTOM: 6865, codigoIBGE: 3515186, nomeTOM: 'ESPÍRITO SANTO DO PINHAL', nomeIBGE: 'Espírito Santo do Pinhal' },
    { codigoTOM: 6867, codigoIBGE: 3538204, nomeTOM: 'PINHALZINHO', nomeIBGE: 'Pinhalzinho' },
    { codigoTOM: 6869, codigoIBGE: 3538303, nomeTOM: 'PIQUEROBI', nomeIBGE: 'Piquerobi' },
    { codigoTOM: 6871, codigoIBGE: 3538501, nomeTOM: 'PIQUETE', nomeIBGE: 'Piquete' },
    { codigoTOM: 6873, codigoIBGE: 3538600, nomeTOM: 'PIRACAIA', nomeIBGE: 'Piracaia' },
    { codigoTOM: 6875, codigoIBGE: 3538709, nomeTOM: 'PIRACICABA', nomeIBGE: 'Piracicaba' },
    { codigoTOM: 6877, codigoIBGE: 3538808, nomeTOM: 'PIRAJU', nomeIBGE: 'Piraju' },
    { codigoTOM: 6879, codigoIBGE: 3538907, nomeTOM: 'PIRAJUÍ', nomeIBGE: 'Pirajuí' },
    { codigoTOM: 6881, codigoIBGE: 3539004, nomeTOM: 'PIRANGI', nomeIBGE: 'Pirangi' },
    { codigoTOM: 6883, codigoIBGE: 3539103, nomeTOM: 'PIRAPORA DO BOM JESUS', nomeIBGE: 'Pirapora do Bom Jesus' },
    { codigoTOM: 6885, codigoIBGE: 3539202, nomeTOM: 'PIRAPOZINHO', nomeIBGE: 'Pirapozinho' },
    { codigoTOM: 6887, codigoIBGE: 3539301, nomeTOM: 'PIRASSUNUNGA', nomeIBGE: 'Pirassununga' },
    { codigoTOM: 6889, codigoIBGE: 3539400, nomeTOM: 'PIRATININGA', nomeIBGE: 'Piratininga' },
    { codigoTOM: 6891, codigoIBGE: 3539509, nomeTOM: 'PITANGUEIRAS', nomeIBGE: 'Pitangueiras' },
    { codigoTOM: 6893, codigoIBGE: 3539608, nomeTOM: 'PLANALTO', nomeIBGE: 'Planalto' },
    { codigoTOM: 6895, codigoIBGE: 3539707, nomeTOM: 'PLATINA', nomeIBGE: 'Platina' },
    { codigoTOM: 6897, codigoIBGE: 3539806, nomeTOM: 'POÁ', nomeIBGE: 'Poá' },
    { codigoTOM: 6899, codigoIBGE: 3539905, nomeTOM: 'POLONI', nomeIBGE: 'Poloni' },
    { codigoTOM: 6901, codigoIBGE: 3540002, nomeTOM: 'POMPÉIA', nomeIBGE: 'Pompéia' },
    { codigoTOM: 6903, codigoIBGE: 3540101, nomeTOM: 'PONGAÍ', nomeIBGE: 'Pongaí' },
    { codigoTOM: 6905, codigoIBGE: 3540200, nomeTOM: 'PONTAL', nomeIBGE: 'Pontal' },
    { codigoTOM: 6907, codigoIBGE: 3540309, nomeTOM: 'PONTES GESTAL', nomeIBGE: 'Pontes Gestal' },
    { codigoTOM: 6909, codigoIBGE: 3540408, nomeTOM: 'POPULINA', nomeIBGE: 'Populina' },
    { codigoTOM: 6911, codigoIBGE: 3540507, nomeTOM: 'PORANGABA', nomeIBGE: 'Porangaba' },
    { codigoTOM: 6913, codigoIBGE: 3540606, nomeTOM: 'PORTO FELIZ', nomeIBGE: 'Porto Feliz' },
    { codigoTOM: 6915, codigoIBGE: 3540705, nomeTOM: 'PORTO FERREIRA', nomeIBGE: 'Porto Ferreira' },
    { codigoTOM: 6917, codigoIBGE: 3540804, nomeTOM: 'POTIRENDABA', nomeIBGE: 'Potirendaba' },
    { codigoTOM: 6919, codigoIBGE: 3540903, nomeTOM: 'PRADÓPOLIS', nomeIBGE: 'Pradópolis' },
    { codigoTOM: 6921, codigoIBGE: 3541000, nomeTOM: 'PRAIA GRANDE', nomeIBGE: 'Praia Grande' },
    { codigoTOM: 6923, codigoIBGE: 3541109, nomeTOM: 'PRESIDENTE ALVES', nomeIBGE: 'Presidente Alves' },
    { codigoTOM: 6925, codigoIBGE: 3541208, nomeTOM: 'PRESIDENTE BERNARDES', nomeIBGE: 'Presidente Bernardes' },
    { codigoTOM: 6927, codigoIBGE: 3541307, nomeTOM: 'PRESIDENTE EPITÁCIO', nomeIBGE: 'Presidente Epitácio' },
    { codigoTOM: 6929, codigoIBGE: 3541406, nomeTOM: 'PRESIDENTE PRUDENTE', nomeIBGE: 'Presidente Prudente' },
    { codigoTOM: 6931, codigoIBGE: 3541505, nomeTOM: 'PRESIDENTE VENCESLAU', nomeIBGE: 'Presidente Venceslau' },
    { codigoTOM: 6933, codigoIBGE: 3541604, nomeTOM: 'PROMISSÃO', nomeIBGE: 'Promissão' },
    { codigoTOM: 6935, codigoIBGE: 3541703, nomeTOM: 'QUATÁ', nomeIBGE: 'Quatá' },
    { codigoTOM: 6937, codigoIBGE: 3541802, nomeTOM: 'QUEIROZ', nomeIBGE: 'Queiroz' },
    { codigoTOM: 6939, codigoIBGE: 3541901, nomeTOM: 'QUELUZ', nomeIBGE: 'Queluz' },
    { codigoTOM: 6941, codigoIBGE: 3542008, nomeTOM: 'QUINTANA', nomeIBGE: 'Quintana' },
    { codigoTOM: 6943, codigoIBGE: 3542107, nomeTOM: 'RAFARD', nomeIBGE: 'Rafard' },
    { codigoTOM: 6945, codigoIBGE: 3542206, nomeTOM: 'RANCHARIA', nomeIBGE: 'Rancharia' },
    { codigoTOM: 6947, codigoIBGE: 3542305, nomeTOM: 'REDENÇÃO DA SERRA', nomeIBGE: 'Redenção da Serra' },
    { codigoTOM: 6949, codigoIBGE: 3542404, nomeTOM: 'REGENTE FEIJÓ', nomeIBGE: 'Regente Feijó' },
    { codigoTOM: 6951, codigoIBGE: 3542503, nomeTOM: 'REGINÓPOLIS', nomeIBGE: 'Reginópolis' },
    { codigoTOM: 6953, codigoIBGE: 3542602, nomeTOM: 'REGISTRO', nomeIBGE: 'Registro' },
    { codigoTOM: 6955, codigoIBGE: 3542701, nomeTOM: 'RESTINGA', nomeIBGE: 'Restinga' },
    { codigoTOM: 6957, codigoIBGE: 3542800, nomeTOM: 'RIBEIRA', nomeIBGE: 'Ribeira' },
    { codigoTOM: 6959, codigoIBGE: 3542909, nomeTOM: 'RIBEIRÃO BONITO', nomeIBGE: 'Ribeirão Bonito' },
    { codigoTOM: 6961, codigoIBGE: 3543006, nomeTOM: 'RIBEIRÃO BRANCO', nomeIBGE: 'Ribeirão Branco' },
    { codigoTOM: 6963, codigoIBGE: 3543105, nomeTOM: 'RIBEIRÃO CORRENTE', nomeIBGE: 'Ribeirão Corrente' },
    { codigoTOM: 6965, codigoIBGE: 3543204, nomeTOM: 'RIBEIRÃO DO SUL', nomeIBGE: 'Ribeirão do Sul' },
    { codigoTOM: 6967, codigoIBGE: 3543303, nomeTOM: 'RIBEIRÃO PIRES', nomeIBGE: 'Ribeirão Pires' },
    { codigoTOM: 6969, codigoIBGE: 3543402, nomeTOM: 'RIBEIRÃO PRETO', nomeIBGE: 'Ribeirão Preto' },
    { codigoTOM: 6971, codigoIBGE: 3543501, nomeTOM: 'RIVERSUL', nomeIBGE: 'Riversul' },
    { codigoTOM: 6973, codigoIBGE: 3543600, nomeTOM: 'RIFAINA', nomeIBGE: 'Rifaina' },
    { codigoTOM: 6975, codigoIBGE: 3543709, nomeTOM: 'RINCÃO', nomeIBGE: 'Rincão' },
    { codigoTOM: 6977, codigoIBGE: 3543808, nomeTOM: 'RINÓPOLIS', nomeIBGE: 'Rinópolis' },
    { codigoTOM: 6979, codigoIBGE: 3543907, nomeTOM: 'RIO CLARO', nomeIBGE: 'Rio Claro' },
    { codigoTOM: 6981, codigoIBGE: 3544004, nomeTOM: 'RIO DAS PEDRAS', nomeIBGE: 'Rio das Pedras' },
    { codigoTOM: 6983, codigoIBGE: 3544103, nomeTOM: 'RIO GRANDE DA SERRA', nomeIBGE: 'Rio Grande da Serra' },
    { codigoTOM: 6985, codigoIBGE: 3544202, nomeTOM: 'RIOLÂNDIA', nomeIBGE: 'Riolândia' },
    { codigoTOM: 6987, codigoIBGE: 3544301, nomeTOM: 'ROSEIRA', nomeIBGE: 'Roseira' },
    { codigoTOM: 6989, codigoIBGE: 3544400, nomeTOM: 'RUBIÁCEA', nomeIBGE: 'Rubiácea' },
    { codigoTOM: 6991, codigoIBGE: 3544509, nomeTOM: 'RUBINÉIA', nomeIBGE: 'Rubinéia' },
    { codigoTOM: 6993, codigoIBGE: 3544608, nomeTOM: 'SABINO', nomeIBGE: 'Sabino' },
    { codigoTOM: 6995, codigoIBGE: 3544707, nomeTOM: 'SAGRES', nomeIBGE: 'Sagres' },
    { codigoTOM: 6997, codigoIBGE: 3544806, nomeTOM: 'SALES', nomeIBGE: 'Sales' },
    { codigoTOM: 6999, codigoIBGE: 3544905, nomeTOM: 'SALES OLIVEIRA', nomeIBGE: 'Sales Oliveira' },
    { codigoTOM: 7001, codigoIBGE: 3545001, nomeTOM: 'SALESÓPOLIS', nomeIBGE: 'Salesópolis' },
    { codigoTOM: 7003, codigoIBGE: 3545100, nomeTOM: 'SALMOURÃO', nomeIBGE: 'Salmourão' },
    { codigoTOM: 7005, codigoIBGE: 3545209, nomeTOM: 'SALTO', nomeIBGE: 'Salto' },
    { codigoTOM: 7007, codigoIBGE: 3545308, nomeTOM: 'SALTO DE PIRAPORA', nomeIBGE: 'Salto de Pirapora' },
    { codigoTOM: 7009, codigoIBGE: 3545407, nomeTOM: 'SALTO GRANDE', nomeIBGE: 'Salto Grande' },
    { codigoTOM: 7011, codigoIBGE: 3545506, nomeTOM: 'SANDOVALINA', nomeIBGE: 'Sandovalina' },
    { codigoTOM: 7013, codigoIBGE: 3545605, nomeTOM: 'SANTA ADÉLIA', nomeIBGE: 'Santa Adélia' },
    { codigoTOM: 7015, codigoIBGE: 3545704, nomeTOM: 'SANTA ALBERTINA', nomeIBGE: 'Santa Albertina' },
    { codigoTOM: 7017, codigoIBGE: 3545803, nomeTOM: "SANTA BÁRBARA D'OESTE", nomeIBGE: "Santa Bárbara d'Oeste" },
    { codigoTOM: 7019, codigoIBGE: 3500550, nomeTOM: 'ÁGUAS DE SANTA BÁRBARA', nomeIBGE: 'Águas de Santa Bárbara' },
    { codigoTOM: 7021, codigoIBGE: 3546009, nomeTOM: 'SANTA BRANCA', nomeIBGE: 'Santa Branca' },
    { codigoTOM: 7023, codigoIBGE: 3546108, nomeTOM: "SANTA CLARA D'OESTE", nomeIBGE: "Santa Clara d'Oeste" },
    { codigoTOM: 7025, codigoIBGE: 3546207, nomeTOM: 'SANTA CRUZ DA CONCEIÇÃO', nomeIBGE: 'Santa Cruz da Conceição' },
    { codigoTOM: 7027, codigoIBGE: 3546306, nomeTOM: 'SANTA CRUZ DAS PALMEIRAS', nomeIBGE: 'Santa Cruz das Palmeiras' },
    { codigoTOM: 7029, codigoIBGE: 3546405, nomeTOM: 'SANTA CRUZ DO RIO PARDO', nomeIBGE: 'Santa Cruz do Rio Pardo' },
    { codigoTOM: 7031, codigoIBGE: 3546504, nomeTOM: 'SANTA ERNESTINA', nomeIBGE: 'Santa Ernestina' },
    { codigoTOM: 7033, codigoIBGE: 3546603, nomeTOM: 'SANTA FÉ DO SUL', nomeIBGE: 'Santa Fé do Sul' },
    { codigoTOM: 7035, codigoIBGE: 3546702, nomeTOM: 'SANTA GERTRUDES', nomeIBGE: 'Santa Gertrudes' },
    { codigoTOM: 7037, codigoIBGE: 3546801, nomeTOM: 'SANTA ISABEL', nomeIBGE: 'Santa Isabel' },
    { codigoTOM: 7039, codigoIBGE: 3546900, nomeTOM: 'SANTA LÚCIA', nomeIBGE: 'Santa Lúcia' },
    { codigoTOM: 7041, codigoIBGE: 3547007, nomeTOM: 'SANTA MARIA DA SERRA', nomeIBGE: 'Santa Maria da Serra' },
    { codigoTOM: 7043, codigoIBGE: 3547106, nomeTOM: 'SANTA MERCEDES', nomeIBGE: 'Santa Mercedes' },
    { codigoTOM: 7045, codigoIBGE: 3547205, nomeTOM: 'SANTANA DA PONTE PENSA', nomeIBGE: 'Santana da Ponte Pensa' },
    { codigoTOM: 7047, codigoIBGE: 3547304, nomeTOM: 'SANTANA DE PARNAÍBA', nomeIBGE: 'Santana de Parnaíba' },
    { codigoTOM: 7049, codigoIBGE: 3547403, nomeTOM: "SANTA RITA D'OESTE", nomeIBGE: "Santa Rita d'Oeste" },
    {
      codigoTOM: 7051,
      codigoIBGE: 3547502,
      nomeTOM: 'SANTA RITA DO PASSA QUATRO',
      nomeIBGE: 'Santa Rita do Passa Quatro',
    },
    { codigoTOM: 7053, codigoIBGE: 3547601, nomeTOM: 'SANTA ROSA DE VITERBO', nomeIBGE: 'Santa Rosa de Viterbo' },
    { codigoTOM: 7055, codigoIBGE: 3547700, nomeTOM: 'SANTO ANASTÁCIO', nomeIBGE: 'Santo Anastácio' },
    { codigoTOM: 7057, codigoIBGE: 3547809, nomeTOM: 'SANTO ANDRÉ', nomeIBGE: 'Santo André' },
    { codigoTOM: 7059, codigoIBGE: 3547908, nomeTOM: 'SANTO ANTÔNIO DA ALEGRIA', nomeIBGE: 'Santo Antônio da Alegria' },
    { codigoTOM: 7061, codigoIBGE: 3548005, nomeTOM: 'SANTO ANTÔNIO DE POSSE', nomeIBGE: 'Santo Antônio de Posse' },
    { codigoTOM: 7063, codigoIBGE: 3548104, nomeTOM: 'SANTO ANTÔNIO DO JARDIM', nomeIBGE: 'Santo Antônio do Jardim' },
    { codigoTOM: 7065, codigoIBGE: 3548203, nomeTOM: 'SANTO ANTÔNIO DO PINHAL', nomeIBGE: 'Santo Antônio do Pinhal' },
    { codigoTOM: 7067, codigoIBGE: 3548302, nomeTOM: 'SANTO EXPEDITO', nomeIBGE: 'Santo Expedito' },
    { codigoTOM: 7069, codigoIBGE: 3548401, nomeTOM: 'SANTÓPOLIS DO AGUAPEÍ', nomeIBGE: 'Santópolis do Aguapeí' },
    { codigoTOM: 7071, codigoIBGE: 3548500, nomeTOM: 'SANTOS', nomeIBGE: 'Santos' },
    { codigoTOM: 7073, codigoIBGE: 3548609, nomeTOM: 'SÃO BENTO DO SAPUCAÍ', nomeIBGE: 'São Bento do Sapucaí' },
    { codigoTOM: 7075, codigoIBGE: 3548708, nomeTOM: 'SÃO BERNARDO DO CAMPO', nomeIBGE: 'São Bernardo do Campo' },
    { codigoTOM: 7077, codigoIBGE: 3548807, nomeTOM: 'SÃO CAETANO DO SUL', nomeIBGE: 'São Caetano do Sul' },
    { codigoTOM: 7079, codigoIBGE: 3548906, nomeTOM: 'SÃO CARLOS', nomeIBGE: 'São Carlos' },
    { codigoTOM: 7081, codigoIBGE: 3549003, nomeTOM: 'SÃO FRANCISCO', nomeIBGE: 'São Francisco' },
    { codigoTOM: 7083, codigoIBGE: 3549102, nomeTOM: 'SÃO JOÃO DA BOA VISTA', nomeIBGE: 'São João da Boa Vista' },
    { codigoTOM: 7085, codigoIBGE: 3549201, nomeTOM: 'SÃO JOÃO DAS DUAS PONTES', nomeIBGE: 'São João das Duas Pontes' },
    { codigoTOM: 7087, codigoIBGE: 3549300, nomeTOM: "SÃO JOÃO DO PAU D'ALHO", nomeIBGE: "São João do Pau d'Alho" },
    { codigoTOM: 7089, codigoIBGE: 3549409, nomeTOM: 'SÃO JOAQUIM DA BARRA', nomeIBGE: 'São Joaquim da Barra' },
    { codigoTOM: 7091, codigoIBGE: 3549508, nomeTOM: 'SÃO JOSÉ DA BELA VISTA', nomeIBGE: 'São José da Bela Vista' },
    { codigoTOM: 7093, codigoIBGE: 3549607, nomeTOM: 'SÃO JOSÉ DO BARREIRO', nomeIBGE: 'São José do Barreiro' },
    { codigoTOM: 7095, codigoIBGE: 3549706, nomeTOM: 'SÃO JOSÉ DO RIO PARDO', nomeIBGE: 'São José do Rio Pardo' },
    { codigoTOM: 7097, codigoIBGE: 3549805, nomeTOM: 'SÃO JOSÉ DO RIO PRETO', nomeIBGE: 'São José do Rio Preto' },
    { codigoTOM: 7099, codigoIBGE: 3549904, nomeTOM: 'SÃO JOSÉ DOS CAMPOS', nomeIBGE: 'São José dos Campos' },
    { codigoTOM: 7101, codigoIBGE: 3550001, nomeTOM: 'SAO LUIZ DO PARAITINGA', nomeIBGE: 'São Luiz do Paraitinga' },
    { codigoTOM: 7103, codigoIBGE: 3550100, nomeTOM: 'SÃO MANUEL', nomeIBGE: 'São Manuel' },
    { codigoTOM: 7105, codigoIBGE: 3550209, nomeTOM: 'SÃO MIGUEL ARCANJO', nomeIBGE: 'São Miguel Arcanjo' },
    { codigoTOM: 7107, codigoIBGE: 3550308, nomeTOM: 'SÃO PAULO', nomeIBGE: 'São Paulo' },
    { codigoTOM: 7109, codigoIBGE: 3550407, nomeTOM: 'SÃO PEDRO', nomeIBGE: 'São Pedro' },
    { codigoTOM: 7111, codigoIBGE: 3550506, nomeTOM: 'SÃO PEDRO DO TURVO', nomeIBGE: 'São Pedro do Turvo' },
    { codigoTOM: 7113, codigoIBGE: 3550605, nomeTOM: 'SÃO ROQUE', nomeIBGE: 'São Roque' },
    { codigoTOM: 7115, codigoIBGE: 3550704, nomeTOM: 'SÃO SEBASTIÃO', nomeIBGE: 'São Sebastião' },
    { codigoTOM: 7117, codigoIBGE: 3550803, nomeTOM: 'SÃO SEBASTIÃO DA GRAMA', nomeIBGE: 'São Sebastião da Grama' },
    { codigoTOM: 7119, codigoIBGE: 3550902, nomeTOM: 'SÃO SIMÃO', nomeIBGE: 'São Simão' },
    { codigoTOM: 7121, codigoIBGE: 3551009, nomeTOM: 'SÃO VICENTE', nomeIBGE: 'São Vicente' },
    { codigoTOM: 7123, codigoIBGE: 3551108, nomeTOM: 'SARAPUÍ', nomeIBGE: 'Sarapuí' },
    { codigoTOM: 7125, codigoIBGE: 3551207, nomeTOM: 'SARUTAIÁ', nomeIBGE: 'Sarutaiá' },
    { codigoTOM: 7127, codigoIBGE: 3551306, nomeTOM: 'SEBASTIANÓPOLIS DO SUL', nomeIBGE: 'Sebastianópolis do Sul' },
    { codigoTOM: 7129, codigoIBGE: 3551405, nomeTOM: 'SERRA AZUL', nomeIBGE: 'Serra Azul' },
    { codigoTOM: 7131, codigoIBGE: 3551504, nomeTOM: 'SERRANA', nomeIBGE: 'Serrana' },
    { codigoTOM: 7133, codigoIBGE: 3551603, nomeTOM: 'SERRA NEGRA', nomeIBGE: 'Serra Negra' },
    { codigoTOM: 7135, codigoIBGE: 3551702, nomeTOM: 'SERTÃOZINHO', nomeIBGE: 'Sertãozinho' },
    { codigoTOM: 7137, codigoIBGE: 3551801, nomeTOM: 'SETE BARRAS', nomeIBGE: 'Sete Barras' },
    { codigoTOM: 7139, codigoIBGE: 3551900, nomeTOM: 'SEVERÍNIA', nomeIBGE: 'Severínia' },
    { codigoTOM: 7141, codigoIBGE: 3552007, nomeTOM: 'SILVEIRAS', nomeIBGE: 'Silveiras' },
    { codigoTOM: 7143, codigoIBGE: 3552106, nomeTOM: 'SOCORRO', nomeIBGE: 'Socorro' },
    { codigoTOM: 7145, codigoIBGE: 3552205, nomeTOM: 'SOROCABA', nomeIBGE: 'Sorocaba' },
    { codigoTOM: 7147, codigoIBGE: 3552304, nomeTOM: 'SUD MENNUCCI', nomeIBGE: 'Sud Mennucci' },
    { codigoTOM: 7149, codigoIBGE: 3552403, nomeTOM: 'SUMARÉ', nomeIBGE: 'Sumaré' },
    { codigoTOM: 7151, codigoIBGE: 3552502, nomeTOM: 'SUZANO', nomeIBGE: 'Suzano' },
    { codigoTOM: 7153, codigoIBGE: 3552601, nomeTOM: 'TABAPUÃ', nomeIBGE: 'Tabapuã' },
    { codigoTOM: 7155, codigoIBGE: 3552700, nomeTOM: 'TABATINGA', nomeIBGE: 'Tabatinga' },
    { codigoTOM: 7157, codigoIBGE: 3552809, nomeTOM: 'TABOÃO DA SERRA', nomeIBGE: 'Taboão da Serra' },
    { codigoTOM: 7159, codigoIBGE: 3552908, nomeTOM: 'TACIBA', nomeIBGE: 'Taciba' },
    { codigoTOM: 7161, codigoIBGE: 3553005, nomeTOM: 'TAGUAÍ', nomeIBGE: 'Taguaí' },
    { codigoTOM: 7163, codigoIBGE: 3553104, nomeTOM: 'TAIAÇU', nomeIBGE: 'Taiaçu' },
    { codigoTOM: 7165, codigoIBGE: 3553203, nomeTOM: 'TAIÚVA', nomeIBGE: 'Taiúva' },
    { codigoTOM: 7167, codigoIBGE: 3553302, nomeTOM: 'TAMBAÚ', nomeIBGE: 'Tambaú' },
    { codigoTOM: 7169, codigoIBGE: 3553401, nomeTOM: 'TANABI', nomeIBGE: 'Tanabi' },
    { codigoTOM: 7171, codigoIBGE: 3553500, nomeTOM: 'TAPIRAÍ', nomeIBGE: 'Tapiraí' },
    { codigoTOM: 7173, codigoIBGE: 3553609, nomeTOM: 'TAPIRATIBA', nomeIBGE: 'Tapiratiba' },
    { codigoTOM: 7175, codigoIBGE: 3553708, nomeTOM: 'TAQUARITINGA', nomeIBGE: 'Taquaritinga' },
    { codigoTOM: 7177, codigoIBGE: 3553807, nomeTOM: 'TAQUARITUBA', nomeIBGE: 'Taquarituba' },
    { codigoTOM: 7179, codigoIBGE: 3553906, nomeTOM: 'TARABAI', nomeIBGE: 'Tarabai' },
    { codigoTOM: 7181, codigoIBGE: 3554003, nomeTOM: 'TATUÍ', nomeIBGE: 'Tatuí' },
    { codigoTOM: 7183, codigoIBGE: 3554102, nomeTOM: 'TAUBATÉ', nomeIBGE: 'Taubaté' },
    { codigoTOM: 7185, codigoIBGE: 3554201, nomeTOM: 'TEJUPÁ', nomeIBGE: 'Tejupá' },
    { codigoTOM: 7187, codigoIBGE: 3554300, nomeTOM: 'TEODORO SAMPAIO', nomeIBGE: 'Teodoro Sampaio' },
    { codigoTOM: 7189, codigoIBGE: 3554409, nomeTOM: 'TERRA ROXA', nomeIBGE: 'Terra Roxa' },
    { codigoTOM: 7191, codigoIBGE: 3554508, nomeTOM: 'TIETÊ', nomeIBGE: 'Tietê' },
    { codigoTOM: 7193, codigoIBGE: 3554607, nomeTOM: 'TIMBURI', nomeIBGE: 'Timburi' },
    { codigoTOM: 7195, codigoIBGE: 3554706, nomeTOM: 'TORRINHA', nomeIBGE: 'Torrinha' },
    { codigoTOM: 7197, codigoIBGE: 3554805, nomeTOM: 'TREMEMBÉ', nomeIBGE: 'Tremembé' },
    { codigoTOM: 7199, codigoIBGE: 3554904, nomeTOM: 'TRÊS FRONTEIRAS', nomeIBGE: 'Três Fronteiras' },
    { codigoTOM: 7201, codigoIBGE: 3555000, nomeTOM: 'TUPÃ', nomeIBGE: 'Tupã' },
    { codigoTOM: 7203, codigoIBGE: 3555109, nomeTOM: 'TUPI PAULISTA', nomeIBGE: 'Tupi Paulista' },
    { codigoTOM: 7205, codigoIBGE: 3555208, nomeTOM: 'TURIÚBA', nomeIBGE: 'Turiúba' },
    { codigoTOM: 7207, codigoIBGE: 3555307, nomeTOM: 'TURMALINA', nomeIBGE: 'Turmalina' },
    { codigoTOM: 7209, codigoIBGE: 3555406, nomeTOM: 'UBATUBA', nomeIBGE: 'Ubatuba' },
    { codigoTOM: 7211, codigoIBGE: 3555505, nomeTOM: 'UBIRAJARA', nomeIBGE: 'Ubirajara' },
    { codigoTOM: 7213, codigoIBGE: 3555604, nomeTOM: 'UCHOA', nomeIBGE: 'Uchoa' },
    { codigoTOM: 7215, codigoIBGE: 3555703, nomeTOM: 'UNIÃO PAULISTA', nomeIBGE: 'União Paulista' },
    { codigoTOM: 7217, codigoIBGE: 3555802, nomeTOM: 'URÂNIA', nomeIBGE: 'Urânia' },
    { codigoTOM: 7219, codigoIBGE: 3555901, nomeTOM: 'URU', nomeIBGE: 'Uru' },
    { codigoTOM: 7221, codigoIBGE: 3556008, nomeTOM: 'URUPÊS', nomeIBGE: 'Urupês' },
    { codigoTOM: 7223, codigoIBGE: 3556107, nomeTOM: 'VALENTIM GENTIL', nomeIBGE: 'Valentim Gentil' },
    { codigoTOM: 7225, codigoIBGE: 3556206, nomeTOM: 'VALINHOS', nomeIBGE: 'Valinhos' },
    { codigoTOM: 7227, codigoIBGE: 3556305, nomeTOM: 'VALPARAÍSO', nomeIBGE: 'Valparaíso' },
    { codigoTOM: 7231, codigoIBGE: 3556404, nomeTOM: 'VARGEM GRANDE DO SUL', nomeIBGE: 'Vargem Grande do Sul' },
    { codigoTOM: 7233, codigoIBGE: 3556503, nomeTOM: 'VÁRZEA PAULISTA', nomeIBGE: 'Várzea Paulista' },
    { codigoTOM: 7235, codigoIBGE: 3556602, nomeTOM: 'VERA CRUZ', nomeIBGE: 'Vera Cruz' },
    { codigoTOM: 7237, codigoIBGE: 3556701, nomeTOM: 'VINHEDO', nomeIBGE: 'Vinhedo' },
    { codigoTOM: 7239, codigoIBGE: 3556800, nomeTOM: 'VIRADOURO', nomeIBGE: 'Viradouro' },
    { codigoTOM: 7241, codigoIBGE: 3556909, nomeTOM: 'VISTA ALEGRE DO ALTO', nomeIBGE: 'Vista Alegre do Alto' },
    { codigoTOM: 7243, codigoIBGE: 3557006, nomeTOM: 'VOTORANTIM', nomeIBGE: 'Votorantim' },
    { codigoTOM: 7245, codigoIBGE: 3557105, nomeTOM: 'VOTUPORANGA', nomeIBGE: 'Votuporanga' },
    { codigoTOM: 7247, codigoIBGE: 3507456, nomeTOM: 'BOREBI', nomeIBGE: 'Borebi' },
    { codigoTOM: 7249, codigoIBGE: 3513850, nomeTOM: 'DIRCE REIS', nomeIBGE: 'Dirce Reis' },
    { codigoTOM: 7251, codigoIBGE: 3514957, nomeTOM: 'EMBAÚBA', nomeIBGE: 'Embaúba' },
    { codigoTOM: 7253, codigoIBGE: 3515194, nomeTOM: 'ESPÍRITO SANTO DO TURVO', nomeIBGE: 'Espírito Santo do Turvo' },
    {
      codigoTOM: 7255,
      codigoIBGE: 3515350,
      nomeTOM: 'EUCLIDES DA CUNHA PAULISTA',
      nomeIBGE: 'Euclides da Cunha Paulista',
    },
    { codigoTOM: 7257, codigoIBGE: 3518859, nomeTOM: 'GUATAPARÁ', nomeIBGE: 'Guatapará' },
    { codigoTOM: 7259, codigoIBGE: 3519253, nomeTOM: 'IARAS', nomeIBGE: 'Iaras' },
    { codigoTOM: 7263, codigoIBGE: 3532058, nomeTOM: 'MOTUCA', nomeIBGE: 'Motuca' },
    { codigoTOM: 7265, codigoIBGE: 3544251, nomeTOM: 'ROSANA', nomeIBGE: 'Rosana' },
    { codigoTOM: 7267, codigoIBGE: 3553955, nomeTOM: 'TARUMÃ', nomeIBGE: 'Tarumã' },
    { codigoTOM: 7273, codigoIBGE: 3556453, nomeTOM: 'VARGEM GRANDE PAULISTA', nomeIBGE: 'Vargem Grande Paulista' },
  ],
  [Estados.PR]: [
    { codigoTOM: 830, codigoIBGE: 4101655, nomeTOM: 'ARAPUÃ', nomeIBGE: 'Arapuã' },
    { codigoTOM: 832, codigoIBGE: 4101853, nomeTOM: 'ARIRANHA DO IVAÍ', nomeIBGE: 'Ariranha do Ivaí' },
    { codigoTOM: 834, codigoIBGE: 4102752, nomeTOM: 'Bela Vista da Caroba', nomeIBGE: 'Bela Vista da Caroba' },
    { codigoTOM: 836, codigoIBGE: 4103040, nomeTOM: 'BOA VENTURA DE SÃO ROQUE', nomeIBGE: 'Boa Ventura de São Roque' },
    { codigoTOM: 838, codigoIBGE: 4103156, nomeTOM: 'BOM JESUS DO SUL', nomeIBGE: 'Bom Jesus do Sul' },
    { codigoTOM: 840, codigoIBGE: 4103958, nomeTOM: 'CAMPINA DO SIMÃO', nomeIBGE: 'Campina do Simão' },
    { codigoTOM: 842, codigoIBGE: 4104253, nomeTOM: 'CAMPO MAGRO', nomeIBGE: 'Campo Magro' },
    { codigoTOM: 844, codigoIBGE: 4104659, nomeTOM: 'CARAMBEÍ', nomeIBGE: 'Carambeí' },
    { codigoTOM: 846, codigoIBGE: 4106456, nomeTOM: 'CORONEL DOMINGOS SOARES', nomeIBGE: 'Coronel Domingos Soares' },
    { codigoTOM: 848, codigoIBGE: 4106852, nomeTOM: 'CRUZMALTINA', nomeIBGE: 'Cruzmaltina' },
    { codigoTOM: 850, codigoIBGE: 4107520, nomeTOM: 'ESPERANÇA NOVA', nomeIBGE: 'Esperança Nova' },
    { codigoTOM: 852, codigoIBGE: 4107546, nomeTOM: 'ESPIGÃO ALTO DO IGUAÇU', nomeIBGE: 'Espigão Alto do Iguaçu' },
    { codigoTOM: 854, codigoIBGE: 4107736, nomeTOM: 'FERNANDES PINHEIRO', nomeIBGE: 'Fernandes Pinheiro' },
    { codigoTOM: 856, codigoIBGE: 4108452, nomeTOM: 'FOZ DO JORDÃO', nomeIBGE: 'Foz do Jordão' },
    { codigoTOM: 858, codigoIBGE: 4108650, nomeTOM: 'GOIOXIM', nomeIBGE: 'Goioxim' },
    { codigoTOM: 860, codigoIBGE: 4108957, nomeTOM: 'GUAMIRANGA', nomeIBGE: 'Guamiranga' },
    { codigoTOM: 862, codigoIBGE: 4110078, nomeTOM: 'IMBAÚ', nomeIBGE: 'Imbaú' },
    { codigoTOM: 864, codigoIBGE: 4114351, nomeTOM: 'MANFRINÓPOLIS', nomeIBGE: 'Manfrinópolis' },
    { codigoTOM: 866, codigoIBGE: 4115457, nomeTOM: 'MARQUINHO', nomeIBGE: 'Marquinho' },
    { codigoTOM: 868, codigoIBGE: 4118857, nomeTOM: 'PEROBAL', nomeIBGE: 'Perobal' },
    { codigoTOM: 870, codigoIBGE: 4119954, nomeTOM: 'PONTAL DO PARANÁ', nomeIBGE: 'Pontal do Paraná' },
    { codigoTOM: 872, codigoIBGE: 4120150, nomeTOM: 'PORTO BARREIRO', nomeIBGE: 'Porto Barreiro' },
    { codigoTOM: 874, codigoIBGE: 4120333, nomeTOM: 'PRADO FERREIRA', nomeIBGE: 'Prado Ferreira' },
    { codigoTOM: 876, codigoIBGE: 4120655, nomeTOM: 'QUARTO CENTENÁRIO', nomeIBGE: 'Quarto Centenário' },
    { codigoTOM: 878, codigoIBGE: 4121752, nomeTOM: 'RESERVA DO IGUAÇU', nomeIBGE: 'Reserva do Iguaçu' },
    { codigoTOM: 880, codigoIBGE: 4122172, nomeTOM: 'RIO BRANCO DO IVAÍ', nomeIBGE: 'Rio Branco do Ivaí' },
    { codigoTOM: 882, codigoIBGE: 4126355, nomeTOM: 'SERRANÓPOLIS DO IGUAÇU', nomeIBGE: 'Serranópolis do Iguaçu' },
    { codigoTOM: 884, codigoIBGE: 4126678, nomeTOM: 'TAMARANA', nomeIBGE: 'Tamarana' },
    { codigoTOM: 5449, codigoIBGE: 4128633, nomeTOM: 'DOUTOR ULYSSES', nomeIBGE: 'Doutor Ulysses' },
    { codigoTOM: 5451, codigoIBGE: 4111258, nomeTOM: 'ITAPERUÇU', nomeIBGE: 'Itaperuçu' },
    { codigoTOM: 5453, codigoIBGE: 4119152, nomeTOM: 'PINHAIS', nomeIBGE: 'Pinhais' },
    { codigoTOM: 5455, codigoIBGE: 4127882, nomeTOM: 'TUNAS DO PARANÁ', nomeIBGE: 'Tunas do Paraná' },
    { codigoTOM: 5457, codigoIBGE: 4117214, nomeTOM: 'NOVA SANTA BÁRBARA', nomeIBGE: 'Nova Santa Bárbara' },
    { codigoTOM: 5459, codigoIBGE: 4115754, nomeTOM: 'MAUÁ DA SERRA', nomeIBGE: 'Mauá da Serra' },
    { codigoTOM: 5461, codigoIBGE: 4119657, nomeTOM: 'PITANGUEIRAS', nomeIBGE: 'Pitangueiras' },
    { codigoTOM: 5463, codigoIBGE: 4101051, nomeTOM: 'ANAHY', nomeIBGE: 'Anahy' },
    { codigoTOM: 5465, codigoIBGE: 4107124, nomeTOM: 'DIAMANTE DO SUL', nomeIBGE: 'Diamante do Sul' },
    { codigoTOM: 5467, codigoIBGE: 4110052, nomeTOM: 'IGUATU', nomeIBGE: 'Iguatu' },
    { codigoTOM: 5469, codigoIBGE: 4123824, nomeTOM: 'SANTA LÚCIA', nomeIBGE: 'Santa Lúcia' },
    { codigoTOM: 5471, codigoIBGE: 4103024, nomeTOM: 'BOA ESPERANÇA DO IGUAÇU', nomeIBGE: 'Boa Esperança do Iguaçu' },
    { codigoTOM: 5473, codigoIBGE: 4106571, nomeTOM: 'CRUZEIRO DO IGUAÇU', nomeIBGE: 'Cruzeiro do Iguaçu' },
    { codigoTOM: 5475, codigoIBGE: 4107850, nomeTOM: 'FLOR DA SERRA DO SUL', nomeIBGE: 'Flor da Serra do Sul' },
    {
      codigoTOM: 5477,
      codigoIBGE: 4116950,
      nomeTOM: 'NOVA ESPERANÇA DO SUDOESTE',
      nomeIBGE: 'Nova Esperança do Sudoeste',
    },
    { codigoTOM: 5479, codigoIBGE: 4117057, nomeTOM: 'NOVA LARANJEIRAS', nomeIBGE: 'Nova Laranjeiras' },
    { codigoTOM: 5481, codigoIBGE: 4122156, nomeTOM: 'RIO BONITO DO IGUAÇU', nomeIBGE: 'Rio Bonito do Iguaçu' },
    { codigoTOM: 5483, codigoIBGE: 4128658, nomeTOM: 'VIRMOND', nomeIBGE: 'Virmond' },
    { codigoTOM: 5485, codigoIBGE: 4110656, nomeTOM: 'IRACEMA DO OESTE', nomeIBGE: 'Iracema do Oeste' },
    { codigoTOM: 5487, codigoIBGE: 4115358, nomeTOM: 'MARIPÁ', nomeIBGE: 'Maripá' },
    { codigoTOM: 5489, codigoIBGE: 4125753, nomeTOM: 'SÃO PEDRO DO IGUAÇU', nomeIBGE: 'São Pedro do Iguaçu' },
    { codigoTOM: 5491, codigoIBGE: 4103479, nomeTOM: 'CAFEZAL DO SUL', nomeIBGE: 'Cafezal do Sul' },
    { codigoTOM: 5493, codigoIBGE: 4126272, nomeTOM: 'SAUDADE DO IGUAÇU', nomeIBGE: 'Saudade do Iguaçu' },
    { codigoTOM: 5495, codigoIBGE: 4119251, nomeTOM: 'Pinhal de São Bento', nomeIBGE: 'Pinhal de São Bento' },
    { codigoTOM: 5497, codigoIBGE: 4128534, nomeTOM: 'VENTANIA', nomeIBGE: 'Ventania' },
    { codigoTOM: 5499, codigoIBGE: 4104428, nomeTOM: 'CANDÓI', nomeIBGE: 'Candói' },
    { codigoTOM: 5501, codigoIBGE: 4113254, nomeTOM: 'LARANJAL', nomeIBGE: 'Laranjal' },
    { codigoTOM: 5503, codigoIBGE: 4115739, nomeTOM: 'MATO RICO', nomeIBGE: 'Mato Rico' },
    { codigoTOM: 5505, codigoIBGE: 4123857, nomeTOM: 'SANTA MARIA DO OESTE', nomeIBGE: 'Santa Maria do Oeste' },
    { codigoTOM: 5507, codigoIBGE: 4113429, nomeTOM: 'LIDIANÓPOLIS', nomeIBGE: 'Lidianópolis' },
    { codigoTOM: 5509, codigoIBGE: 4101150, nomeTOM: 'ÂNGULO', nomeIBGE: 'Ângulo' },
    { codigoTOM: 5511, codigoIBGE: 4107553, nomeTOM: 'FAROL', nomeIBGE: 'Farol' },
    { codigoTOM: 5513, codigoIBGE: 4121356, nomeTOM: "RANCHO ALEGRE D'OESTE", nomeIBGE: "Rancho Alegre D'Oeste" },
    { codigoTOM: 5515, codigoIBGE: 4125555, nomeTOM: 'SÃO MANOEL DO PARANÁ', nomeIBGE: 'São Manoel do Paraná' },
    { codigoTOM: 5517, codigoIBGE: 4117297, nomeTOM: 'NOVO ITACOLOMI', nomeIBGE: 'Novo Itacolomi' },
    { codigoTOM: 5519, codigoIBGE: 4123956, nomeTOM: 'SANTA MÔNICA', nomeIBGE: 'Santa Mônica' },
    { codigoTOM: 5521, codigoIBGE: 4103370, nomeTOM: 'BRASILÂNDIA DO SUL', nomeIBGE: 'Brasilândia do Sul' },
    { codigoTOM: 5523, codigoIBGE: 4128625, nomeTOM: 'ALTO PARAÍSO', nomeIBGE: 'Alto Paraíso' },
    { codigoTOM: 5525, codigoIBGE: 4110953, nomeTOM: 'ITAIPULÂNDIA', nomeIBGE: 'Itaipulândia' },
    { codigoTOM: 5527, codigoIBGE: 4121257, nomeTOM: 'RAMILÂNDIA', nomeIBGE: 'Ramilândia' },
    { codigoTOM: 5529, codigoIBGE: 4107538, nomeTOM: 'ENTRE RIOS DO OESTE', nomeIBGE: 'Entre Rios do Oeste' },
    { codigoTOM: 5531, codigoIBGE: 4115853, nomeTOM: 'MERCEDES', nomeIBGE: 'Mercedes' },
    { codigoTOM: 5533, codigoIBGE: 4118451, nomeTOM: 'PATO BRAGADO', nomeIBGE: 'Pato Bragado' },
    { codigoTOM: 5535, codigoIBGE: 4120853, nomeTOM: 'QUATRO PONTES', nomeIBGE: 'Quatro Pontes' },
    { codigoTOM: 7401, codigoIBGE: 4100103, nomeTOM: 'ABATIÁ', nomeIBGE: 'Abatiá' },
    { codigoTOM: 7403, codigoIBGE: 4100202, nomeTOM: 'ADRIANÓPOLIS', nomeIBGE: 'Adrianópolis' },
    { codigoTOM: 7405, codigoIBGE: 4100301, nomeTOM: 'AGUDOS DO SUL', nomeIBGE: 'Agudos do Sul' },
    { codigoTOM: 7407, codigoIBGE: 4100400, nomeTOM: 'ALMIRANTE TAMANDARÉ', nomeIBGE: 'Almirante Tamandaré' },
    { codigoTOM: 7409, codigoIBGE: 4100608, nomeTOM: 'ALTO PARANÁ', nomeIBGE: 'Alto Paraná' },
    { codigoTOM: 7411, codigoIBGE: 4100707, nomeTOM: 'ALTO PIQUIRI', nomeIBGE: 'Alto Piquiri' },
    { codigoTOM: 7413, codigoIBGE: 4100806, nomeTOM: 'ALVORADA DO SUL', nomeIBGE: 'Alvorada do Sul' },
    { codigoTOM: 7415, codigoIBGE: 4100905, nomeTOM: 'AMAPORÃ', nomeIBGE: 'Amaporã' },
    { codigoTOM: 7417, codigoIBGE: 4101002, nomeTOM: 'AMPÉRE', nomeIBGE: 'Ampére' },
    { codigoTOM: 7419, codigoIBGE: 4101101, nomeTOM: 'ANDIRÁ', nomeIBGE: 'Andirá' },
    { codigoTOM: 7421, codigoIBGE: 4101200, nomeTOM: 'ANTONINA', nomeIBGE: 'Antonina' },
    { codigoTOM: 7423, codigoIBGE: 4101309, nomeTOM: 'ANTÔNIO OLINTO', nomeIBGE: 'Antônio Olinto' },
    { codigoTOM: 7425, codigoIBGE: 4101408, nomeTOM: 'APUCARANA', nomeIBGE: 'Apucarana' },
    { codigoTOM: 7427, codigoIBGE: 4101507, nomeTOM: 'ARAPONGAS', nomeIBGE: 'Arapongas' },
    { codigoTOM: 7429, codigoIBGE: 4101606, nomeTOM: 'ARAPOTI', nomeIBGE: 'Arapoti' },
    { codigoTOM: 7431, codigoIBGE: 4101705, nomeTOM: 'ARARUNA', nomeIBGE: 'Araruna' },
    { codigoTOM: 7433, codigoIBGE: 4114906, nomeTOM: 'MARILÂNDIA DO SUL', nomeIBGE: 'Marilândia do Sul' },
    { codigoTOM: 7435, codigoIBGE: 4101804, nomeTOM: 'ARAUCÁRIA', nomeIBGE: 'Araucária' },
    { codigoTOM: 7437, codigoIBGE: 4101903, nomeTOM: 'ASSAÍ', nomeIBGE: 'Assaí' },
    { codigoTOM: 7439, codigoIBGE: 4102109, nomeTOM: 'ASTORGA', nomeIBGE: 'Astorga' },
    { codigoTOM: 7441, codigoIBGE: 4102208, nomeTOM: 'ATALAIA', nomeIBGE: 'Atalaia' },
    { codigoTOM: 7443, codigoIBGE: 4102307, nomeTOM: 'BALSA NOVA', nomeIBGE: 'Balsa Nova' },
    { codigoTOM: 7445, codigoIBGE: 4102406, nomeTOM: 'BANDEIRANTES', nomeIBGE: 'Bandeirantes' },
    { codigoTOM: 7447, codigoIBGE: 4102505, nomeTOM: 'BARBOSA FERRAZ', nomeIBGE: 'Barbosa Ferraz' },
    { codigoTOM: 7449, codigoIBGE: 4102604, nomeTOM: 'BARRACÃO', nomeIBGE: 'Barracão' },
    { codigoTOM: 7451, codigoIBGE: 4102703, nomeTOM: 'BARRA DO JACARÉ', nomeIBGE: 'Barra do Jacaré' },
    { codigoTOM: 7453, codigoIBGE: 4102802, nomeTOM: 'BELA VISTA DO PARAÍSO', nomeIBGE: 'Bela Vista do Paraíso' },
    { codigoTOM: 7455, codigoIBGE: 4102901, nomeTOM: 'BITURUNA', nomeIBGE: 'Bituruna' },
    { codigoTOM: 7457, codigoIBGE: 4103008, nomeTOM: 'BOA ESPERANÇA', nomeIBGE: 'Boa Esperança' },
    { codigoTOM: 7459, codigoIBGE: 4103107, nomeTOM: 'BOCAIÚVA DO SUL', nomeIBGE: 'Bocaiúva do Sul' },
    { codigoTOM: 7461, codigoIBGE: 4103206, nomeTOM: 'BOM SUCESSO', nomeIBGE: 'Bom Sucesso' },
    { codigoTOM: 7463, codigoIBGE: 4103305, nomeTOM: 'BORRAZÓPOLIS', nomeIBGE: 'Borrazópolis' },
    { codigoTOM: 7465, codigoIBGE: 4103404, nomeTOM: 'CAFEARA', nomeIBGE: 'Cafeara' },
    { codigoTOM: 7467, codigoIBGE: 4103503, nomeTOM: 'CALIFÓRNIA', nomeIBGE: 'Califórnia' },
    { codigoTOM: 7469, codigoIBGE: 4103602, nomeTOM: 'CAMBARÁ', nomeIBGE: 'Cambará' },
    { codigoTOM: 7471, codigoIBGE: 4103701, nomeTOM: 'CAMBÉ', nomeIBGE: 'Cambé' },
    { codigoTOM: 7473, codigoIBGE: 4103800, nomeTOM: 'CAMBIRA', nomeIBGE: 'Cambira' },
    { codigoTOM: 7475, codigoIBGE: 4103909, nomeTOM: 'CAMPINA DA LAGOA', nomeIBGE: 'Campina da Lagoa' },
    { codigoTOM: 7477, codigoIBGE: 4104006, nomeTOM: 'CAMPINA GRANDE DO SUL', nomeIBGE: 'Campina Grande do Sul' },
    { codigoTOM: 7479, codigoIBGE: 4104105, nomeTOM: 'CAMPO DO TENENTE', nomeIBGE: 'Campo do Tenente' },
    { codigoTOM: 7481, codigoIBGE: 4104204, nomeTOM: 'CAMPO LARGO', nomeIBGE: 'Campo Largo' },
    { codigoTOM: 7483, codigoIBGE: 4104303, nomeTOM: 'CAMPO MOURÃO', nomeIBGE: 'Campo Mourão' },
    { codigoTOM: 7485, codigoIBGE: 4104402, nomeTOM: 'CÂNDIDO DE ABREU', nomeIBGE: 'Cândido de Abreu' },
    { codigoTOM: 7487, codigoIBGE: 4104501, nomeTOM: 'CAPANEMA', nomeIBGE: 'Capanema' },
    { codigoTOM: 7489, codigoIBGE: 4104600, nomeTOM: 'CAPITÃO LEÔNIDAS MARQUES', nomeIBGE: 'Capitão Leônidas Marques' },
    { codigoTOM: 7491, codigoIBGE: 4104709, nomeTOM: 'CARLÓPOLIS', nomeIBGE: 'Carlópolis' },
    { codigoTOM: 7493, codigoIBGE: 4104808, nomeTOM: 'CASCAVEL', nomeIBGE: 'Cascavel' },
    { codigoTOM: 7495, codigoIBGE: 4104907, nomeTOM: 'CASTRO', nomeIBGE: 'Castro' },
    { codigoTOM: 7497, codigoIBGE: 4105003, nomeTOM: 'CATANDUVAS', nomeIBGE: 'Catanduvas' },
    { codigoTOM: 7499, codigoIBGE: 4105102, nomeTOM: 'CENTENÁRIO DO SUL', nomeIBGE: 'Centenário do Sul' },
    { codigoTOM: 7501, codigoIBGE: 4105201, nomeTOM: 'CERRO AZUL', nomeIBGE: 'Cerro Azul' },
    { codigoTOM: 7503, codigoIBGE: 4105409, nomeTOM: 'CHOPINZINHO', nomeIBGE: 'Chopinzinho' },
    { codigoTOM: 7505, codigoIBGE: 4105508, nomeTOM: 'CIANORTE', nomeIBGE: 'Cianorte' },
    { codigoTOM: 7507, codigoIBGE: 4105607, nomeTOM: 'CIDADE GAÚCHA', nomeIBGE: 'Cidade Gaúcha' },
    { codigoTOM: 7509, codigoIBGE: 4105706, nomeTOM: 'CLEVELÂNDIA', nomeIBGE: 'Clevelândia' },
    { codigoTOM: 7511, codigoIBGE: 4114401, nomeTOM: 'MANGUEIRINHA', nomeIBGE: 'Mangueirinha' },
    { codigoTOM: 7513, codigoIBGE: 4105805, nomeTOM: 'COLOMBO', nomeIBGE: 'Colombo' },
    { codigoTOM: 7515, codigoIBGE: 4105904, nomeTOM: 'COLORADO', nomeIBGE: 'Colorado' },
    { codigoTOM: 7517, codigoIBGE: 4106001, nomeTOM: 'CONGONHINHAS', nomeIBGE: 'Congonhinhas' },
    { codigoTOM: 7519, codigoIBGE: 4106100, nomeTOM: 'CONSELHEIRO MAIRINCK', nomeIBGE: 'Conselheiro Mairinck' },
    { codigoTOM: 7521, codigoIBGE: 4106209, nomeTOM: 'CONTENDA', nomeIBGE: 'Contenda' },
    { codigoTOM: 7523, codigoIBGE: 4106308, nomeTOM: 'CORBÉLIA', nomeIBGE: 'Corbélia' },
    { codigoTOM: 7525, codigoIBGE: 4106407, nomeTOM: 'CORNÉLIO PROCÓPIO', nomeIBGE: 'Cornélio Procópio' },
    { codigoTOM: 7527, codigoIBGE: 4106506, nomeTOM: 'CORONEL VIVIDA', nomeIBGE: 'Coronel Vivida' },
    { codigoTOM: 7529, codigoIBGE: 4106605, nomeTOM: 'CRUZEIRO DO OESTE', nomeIBGE: 'Cruzeiro do Oeste' },
    { codigoTOM: 7531, codigoIBGE: 4106704, nomeTOM: 'CRUZEIRO DO SUL', nomeIBGE: 'Cruzeiro do Sul' },
    { codigoTOM: 7533, codigoIBGE: 4106803, nomeTOM: 'CRUZ MACHADO', nomeIBGE: 'Cruz Machado' },
    { codigoTOM: 7535, codigoIBGE: 4106902, nomeTOM: 'CURITIBA', nomeIBGE: 'Curitiba' },
    { codigoTOM: 7537, codigoIBGE: 4107009, nomeTOM: 'CURIÚVA', nomeIBGE: 'Curiúva' },
    { codigoTOM: 7539, codigoIBGE: 4107108, nomeTOM: 'DIAMANTE DO NORTE', nomeIBGE: 'Diamante do Norte' },
    { codigoTOM: 7541, codigoIBGE: 4107207, nomeTOM: 'DOIS VIZINHOS', nomeIBGE: 'Dois Vizinhos' },
    { codigoTOM: 7543, codigoIBGE: 4107306, nomeTOM: 'DOUTOR CAMARGO', nomeIBGE: 'Doutor Camargo' },
    { codigoTOM: 7545, codigoIBGE: 4107405, nomeTOM: 'ENÉAS MARQUES', nomeIBGE: 'Enéas Marques' },
    { codigoTOM: 7547, codigoIBGE: 4107504, nomeTOM: 'ENGENHEIRO BELTRÃO', nomeIBGE: 'Engenheiro Beltrão' },
    { codigoTOM: 7549, codigoIBGE: 4107603, nomeTOM: 'FAXINAL', nomeIBGE: 'Faxinal' },
    { codigoTOM: 7551, codigoIBGE: 4107702, nomeTOM: 'FÊNIX', nomeIBGE: 'Fênix' },
    { codigoTOM: 7553, codigoIBGE: 4107801, nomeTOM: 'FLORAÍ', nomeIBGE: 'Floraí' },
    { codigoTOM: 7555, codigoIBGE: 4107900, nomeTOM: 'FLORESTA', nomeIBGE: 'Floresta' },
    { codigoTOM: 7557, codigoIBGE: 4108007, nomeTOM: 'FLORESTÓPOLIS', nomeIBGE: 'Florestópolis' },
    { codigoTOM: 7559, codigoIBGE: 4108106, nomeTOM: 'FLÓRIDA', nomeIBGE: 'Flórida' },
    { codigoTOM: 7561, codigoIBGE: 4108205, nomeTOM: 'FORMOSA DO OESTE', nomeIBGE: 'Formosa do Oeste' },
    { codigoTOM: 7563, codigoIBGE: 4108304, nomeTOM: 'FOZ DO IGUAÇU', nomeIBGE: 'Foz do Iguaçu' },
    { codigoTOM: 7565, codigoIBGE: 4108403, nomeTOM: 'FRANCISCO BELTRÃO', nomeIBGE: 'Francisco Beltrão' },
    { codigoTOM: 7567, codigoIBGE: 4108502, nomeTOM: 'GENERAL CARNEIRO', nomeIBGE: 'General Carneiro' },
    { codigoTOM: 7569, codigoIBGE: 4108601, nomeTOM: 'GOIOERÊ', nomeIBGE: 'Goioerê' },
    { codigoTOM: 7571, codigoIBGE: 4108809, nomeTOM: 'GUAÍRA', nomeIBGE: 'Guaíra' },
    { codigoTOM: 7573, codigoIBGE: 4108908, nomeTOM: 'GUAIRAÇÁ', nomeIBGE: 'Guairaçá' },
    { codigoTOM: 7575, codigoIBGE: 4109005, nomeTOM: 'GUAPIRAMA', nomeIBGE: 'Guapirama' },
    { codigoTOM: 7577, codigoIBGE: 4109104, nomeTOM: 'GUAPOREMA', nomeIBGE: 'Guaporema' },
    { codigoTOM: 7579, codigoIBGE: 4109203, nomeTOM: 'GUARACI', nomeIBGE: 'Guaraci' },
    { codigoTOM: 7581, codigoIBGE: 4109302, nomeTOM: 'GUARANIAÇU', nomeIBGE: 'Guaraniaçu' },
    { codigoTOM: 7583, codigoIBGE: 4109401, nomeTOM: 'GUARAPUAVA', nomeIBGE: 'Guarapuava' },
    { codigoTOM: 7585, codigoIBGE: 4109500, nomeTOM: 'GUARAQUEÇABA', nomeIBGE: 'Guaraqueçaba' },
    { codigoTOM: 7587, codigoIBGE: 4109609, nomeTOM: 'GUARATUBA', nomeIBGE: 'Guaratuba' },
    { codigoTOM: 7589, codigoIBGE: 4109708, nomeTOM: 'IBAITI', nomeIBGE: 'Ibaiti' },
    { codigoTOM: 7591, codigoIBGE: 4109807, nomeTOM: 'IBIPORÃ', nomeIBGE: 'Ibiporã' },
    { codigoTOM: 7593, codigoIBGE: 4109906, nomeTOM: 'ICARAÍMA', nomeIBGE: 'Icaraíma' },
    { codigoTOM: 7595, codigoIBGE: 4110003, nomeTOM: 'IGUARAÇU', nomeIBGE: 'Iguaraçu' },
    { codigoTOM: 7597, codigoIBGE: 4110102, nomeTOM: 'IMBITUVA', nomeIBGE: 'Imbituva' },
    { codigoTOM: 7599, codigoIBGE: 4110201, nomeTOM: 'INÁCIO MARTINS', nomeIBGE: 'Inácio Martins' },
    { codigoTOM: 7601, codigoIBGE: 4110300, nomeTOM: 'INAJÁ', nomeIBGE: 'Inajá' },
    { codigoTOM: 7603, codigoIBGE: 4110508, nomeTOM: 'IPIRANGA', nomeIBGE: 'Ipiranga' },
    { codigoTOM: 7605, codigoIBGE: 4110607, nomeTOM: 'IPORÃ', nomeIBGE: 'Iporã' },
    { codigoTOM: 7607, codigoIBGE: 4110706, nomeTOM: 'IRATI', nomeIBGE: 'Irati' },
    { codigoTOM: 7609, codigoIBGE: 4110805, nomeTOM: 'IRETAMA', nomeIBGE: 'Iretama' },
    { codigoTOM: 7611, codigoIBGE: 4110904, nomeTOM: 'ITAGUAJÉ', nomeIBGE: 'Itaguajé' },
    { codigoTOM: 7613, codigoIBGE: 4111001, nomeTOM: 'ITAMBARACÁ', nomeIBGE: 'Itambaracá' },
    { codigoTOM: 7615, codigoIBGE: 4111100, nomeTOM: 'ITAMBÉ', nomeIBGE: 'Itambé' },
    { codigoTOM: 7617, codigoIBGE: 4111209, nomeTOM: "ITAPEJARA D'OESTE", nomeIBGE: "Itapejara d'Oeste" },
    { codigoTOM: 7619, codigoIBGE: 4111308, nomeTOM: 'ITAÚNA DO SUL', nomeIBGE: 'Itaúna do Sul' },
    { codigoTOM: 7621, codigoIBGE: 4111407, nomeTOM: 'IVAÍ', nomeIBGE: 'Ivaí' },
    { codigoTOM: 7623, codigoIBGE: 4111506, nomeTOM: 'IVAIPORÃ', nomeIBGE: 'Ivaiporã' },
    { codigoTOM: 7625, codigoIBGE: 4111605, nomeTOM: 'IVATUBA', nomeIBGE: 'Ivatuba' },
    { codigoTOM: 7627, codigoIBGE: 4111704, nomeTOM: 'JABOTI', nomeIBGE: 'Jaboti' },
    { codigoTOM: 7629, codigoIBGE: 4111803, nomeTOM: 'JACAREZINHO', nomeIBGE: 'Jacarezinho' },
    { codigoTOM: 7631, codigoIBGE: 4111902, nomeTOM: 'JAGUAPITÃ', nomeIBGE: 'Jaguapitã' },
    { codigoTOM: 7633, codigoIBGE: 4112009, nomeTOM: 'JAGUARIAÍVA', nomeIBGE: 'Jaguariaíva' },
    { codigoTOM: 7635, codigoIBGE: 4112108, nomeTOM: 'JANDAIA DO SUL', nomeIBGE: 'Jandaia do Sul' },
    { codigoTOM: 7637, codigoIBGE: 4112207, nomeTOM: 'JANIÓPOLIS', nomeIBGE: 'Janiópolis' },
    { codigoTOM: 7639, codigoIBGE: 4112306, nomeTOM: 'JAPIRA', nomeIBGE: 'Japira' },
    { codigoTOM: 7641, codigoIBGE: 4112405, nomeTOM: 'JAPURÁ', nomeIBGE: 'Japurá' },
    { codigoTOM: 7643, codigoIBGE: 4112504, nomeTOM: 'JARDIM ALEGRE', nomeIBGE: 'Jardim Alegre' },
    { codigoTOM: 7645, codigoIBGE: 4112603, nomeTOM: 'JARDIM OLINDA', nomeIBGE: 'Jardim Olinda' },
    { codigoTOM: 7647, codigoIBGE: 4112702, nomeTOM: 'JATAIZINHO', nomeIBGE: 'Jataizinho' },
    { codigoTOM: 7649, codigoIBGE: 4112801, nomeTOM: 'JOAQUIM TÁVORA', nomeIBGE: 'Joaquim Távora' },
    { codigoTOM: 7651, codigoIBGE: 4112900, nomeTOM: 'JUNDIAÍ DO SUL', nomeIBGE: 'Jundiaí do Sul' },
    { codigoTOM: 7653, codigoIBGE: 4113007, nomeTOM: 'JUSSARA', nomeIBGE: 'Jussara' },
    { codigoTOM: 7655, codigoIBGE: 4113106, nomeTOM: 'KALORÉ', nomeIBGE: 'Kaloré' },
    { codigoTOM: 7657, codigoIBGE: 4113205, nomeTOM: 'LAPA', nomeIBGE: 'Lapa' },
    { codigoTOM: 7659, codigoIBGE: 4113304, nomeTOM: 'LARANJEIRAS DO SUL', nomeIBGE: 'Laranjeiras do Sul' },
    { codigoTOM: 7661, codigoIBGE: 4113403, nomeTOM: 'LEÓPOLIS', nomeIBGE: 'Leópolis' },
    { codigoTOM: 7663, codigoIBGE: 4113502, nomeTOM: 'LOANDA', nomeIBGE: 'Loanda' },
    { codigoTOM: 7665, codigoIBGE: 4113601, nomeTOM: 'LOBATO', nomeIBGE: 'Lobato' },
    { codigoTOM: 7667, codigoIBGE: 4113700, nomeTOM: 'LONDRINA', nomeIBGE: 'Londrina' },
    { codigoTOM: 7669, codigoIBGE: 4113809, nomeTOM: 'LUPIONÓPOLIS', nomeIBGE: 'Lupionópolis' },
    { codigoTOM: 7671, codigoIBGE: 4113908, nomeTOM: 'MALLET', nomeIBGE: 'Mallet' },
    { codigoTOM: 7673, codigoIBGE: 4114005, nomeTOM: 'MAMBORÊ', nomeIBGE: 'Mamborê' },
    { codigoTOM: 7675, codigoIBGE: 4114104, nomeTOM: 'MANDAGUAÇU', nomeIBGE: 'Mandaguaçu' },
    { codigoTOM: 7677, codigoIBGE: 4114203, nomeTOM: 'MANDAGUARI', nomeIBGE: 'Mandaguari' },
    { codigoTOM: 7679, codigoIBGE: 4114302, nomeTOM: 'MANDIRITUBA', nomeIBGE: 'Mandirituba' },
    { codigoTOM: 7681, codigoIBGE: 4114500, nomeTOM: 'MANOEL RIBAS', nomeIBGE: 'Manoel Ribas' },
    { codigoTOM: 7683, codigoIBGE: 4114609, nomeTOM: 'MARECHAL CÂNDIDO RONDON', nomeIBGE: 'Marechal Cândido Rondon' },
    { codigoTOM: 7685, codigoIBGE: 4114708, nomeTOM: 'MARIA HELENA', nomeIBGE: 'Maria Helena' },
    { codigoTOM: 7687, codigoIBGE: 4114807, nomeTOM: 'MARIALVA', nomeIBGE: 'Marialva' },
    { codigoTOM: 7689, codigoIBGE: 4115101, nomeTOM: 'MARILUZ', nomeIBGE: 'Mariluz' },
    { codigoTOM: 7691, codigoIBGE: 4115200, nomeTOM: 'MARINGÁ', nomeIBGE: 'Maringá' },
    { codigoTOM: 7693, codigoIBGE: 4115309, nomeTOM: 'MARIÓPOLIS', nomeIBGE: 'Mariópolis' },
    { codigoTOM: 7695, codigoIBGE: 4115408, nomeTOM: 'MARMELEIRO', nomeIBGE: 'Marmeleiro' },
    { codigoTOM: 7697, codigoIBGE: 4115507, nomeTOM: 'MARUMBI', nomeIBGE: 'Marumbi' },
    { codigoTOM: 7699, codigoIBGE: 4115606, nomeTOM: 'MATELÂNDIA', nomeIBGE: 'Matelândia' },
    { codigoTOM: 7701, codigoIBGE: 4115804, nomeTOM: 'MEDIANEIRA', nomeIBGE: 'Medianeira' },
    { codigoTOM: 7703, codigoIBGE: 4115903, nomeTOM: 'MIRADOR', nomeIBGE: 'Mirador' },
    { codigoTOM: 7705, codigoIBGE: 4116000, nomeTOM: 'MIRASELVA', nomeIBGE: 'Miraselva' },
    { codigoTOM: 7707, codigoIBGE: 4116109, nomeTOM: 'MOREIRA SALES', nomeIBGE: 'Moreira Sales' },
    { codigoTOM: 7709, codigoIBGE: 4116208, nomeTOM: 'MORRETES', nomeIBGE: 'Morretes' },
    { codigoTOM: 7711, codigoIBGE: 4116307, nomeTOM: 'Munhoz de Melo', nomeIBGE: 'Munhoz de Melo' },
    { codigoTOM: 7713, codigoIBGE: 4116406, nomeTOM: 'NOSSA SENHORA DAS GRAÇAS', nomeIBGE: 'Nossa Senhora das Graças' },
    { codigoTOM: 7715, codigoIBGE: 4116505, nomeTOM: 'NOVA ALIANÇA DO IVAÍ', nomeIBGE: 'Nova Aliança do Ivaí' },
    { codigoTOM: 7717, codigoIBGE: 4116604, nomeTOM: 'NOVA AMÉRICA DA COLINA', nomeIBGE: 'Nova América da Colina' },
    { codigoTOM: 7719, codigoIBGE: 4116802, nomeTOM: 'NOVA CANTU', nomeIBGE: 'Nova Cantu' },
    { codigoTOM: 7721, codigoIBGE: 4116901, nomeTOM: 'NOVA ESPERANÇA', nomeIBGE: 'Nova Esperança' },
    { codigoTOM: 7723, codigoIBGE: 4117008, nomeTOM: 'NOVA FÁTIMA', nomeIBGE: 'Nova Fátima' },
    { codigoTOM: 7725, codigoIBGE: 4117107, nomeTOM: 'NOVA LONDRINA', nomeIBGE: 'Nova Londrina' },
    { codigoTOM: 7727, codigoIBGE: 4117305, nomeTOM: 'ORTIGUEIRA', nomeIBGE: 'Ortigueira' },
    { codigoTOM: 7729, codigoIBGE: 4117404, nomeTOM: 'OURIZONA', nomeIBGE: 'Ourizona' },
    { codigoTOM: 7731, codigoIBGE: 4117503, nomeTOM: 'PAIÇANDU', nomeIBGE: 'Paiçandu' },
    { codigoTOM: 7733, codigoIBGE: 4117602, nomeTOM: 'PALMAS', nomeIBGE: 'Palmas' },
    { codigoTOM: 7735, codigoIBGE: 4117701, nomeTOM: 'PALMEIRA', nomeIBGE: 'Palmeira' },
    { codigoTOM: 7737, codigoIBGE: 4117800, nomeTOM: 'PALMITAL', nomeIBGE: 'Palmital' },
    { codigoTOM: 7739, codigoIBGE: 4117909, nomeTOM: 'PALOTINA', nomeIBGE: 'Palotina' },
    { codigoTOM: 7741, codigoIBGE: 4118006, nomeTOM: 'PARAÍSO DO NORTE', nomeIBGE: 'Paraíso do Norte' },
    { codigoTOM: 7743, codigoIBGE: 4118105, nomeTOM: 'PARANACITY', nomeIBGE: 'Paranacity' },
    { codigoTOM: 7745, codigoIBGE: 4118204, nomeTOM: 'PARANAGUÁ', nomeIBGE: 'Paranaguá' },
    { codigoTOM: 7747, codigoIBGE: 4118303, nomeTOM: 'PARANAPOEMA', nomeIBGE: 'Paranapoema' },
    { codigoTOM: 7749, codigoIBGE: 4118402, nomeTOM: 'PARANAVAÍ', nomeIBGE: 'Paranavaí' },
    { codigoTOM: 7751, codigoIBGE: 4118501, nomeTOM: 'PATO BRANCO', nomeIBGE: 'Pato Branco' },
    { codigoTOM: 7753, codigoIBGE: 4118600, nomeTOM: 'PAULA FREITAS', nomeIBGE: 'Paula Freitas' },
    { codigoTOM: 7755, codigoIBGE: 4118709, nomeTOM: 'PAULO FRONTIN', nomeIBGE: 'Paulo Frontin' },
    { codigoTOM: 7757, codigoIBGE: 4118808, nomeTOM: 'PEABIRU', nomeIBGE: 'Peabiru' },
    { codigoTOM: 7759, codigoIBGE: 4119004, nomeTOM: "PÉROLA D'OESTE", nomeIBGE: "Pérola d'Oeste" },
    { codigoTOM: 7761, codigoIBGE: 4119103, nomeTOM: 'PIÊN', nomeIBGE: 'Piên' },
    { codigoTOM: 7763, codigoIBGE: 4119202, nomeTOM: 'PINHALÃO', nomeIBGE: 'Pinhalão' },
    { codigoTOM: 7765, codigoIBGE: 4119301, nomeTOM: 'PINHÃO', nomeIBGE: 'Pinhão' },
    { codigoTOM: 7767, codigoIBGE: 4119400, nomeTOM: 'PIRAÍ DO SUL', nomeIBGE: 'Piraí do Sul' },
    { codigoTOM: 7769, codigoIBGE: 4119509, nomeTOM: 'PIRAQUARA', nomeIBGE: 'Piraquara' },
    { codigoTOM: 7771, codigoIBGE: 4119608, nomeTOM: 'PITANGA', nomeIBGE: 'Pitanga' },
    { codigoTOM: 7773, codigoIBGE: 4119707, nomeTOM: 'PLANALTINA DO PARANÁ', nomeIBGE: 'Planaltina do Paraná' },
    { codigoTOM: 7775, codigoIBGE: 4119806, nomeTOM: 'PLANALTO', nomeIBGE: 'Planalto' },
    { codigoTOM: 7777, codigoIBGE: 4119905, nomeTOM: 'PONTA GROSSA', nomeIBGE: 'Ponta Grossa' },
    { codigoTOM: 7779, codigoIBGE: 4120002, nomeTOM: 'PORECATU', nomeIBGE: 'Porecatu' },
    { codigoTOM: 7781, codigoIBGE: 4120101, nomeTOM: 'PORTO AMAZONAS', nomeIBGE: 'Porto Amazonas' },
    { codigoTOM: 7783, codigoIBGE: 4120200, nomeTOM: 'PORTO RICO', nomeIBGE: 'Porto Rico' },
    { codigoTOM: 7785, codigoIBGE: 4120309, nomeTOM: 'PORTO VITÓRIA', nomeIBGE: 'Porto Vitória' },
    {
      codigoTOM: 7787,
      codigoIBGE: 4120408,
      nomeTOM: 'PRESIDENTE CASTELO BRANCO',
      nomeIBGE: 'Presidente Castelo Branco',
    },
    { codigoTOM: 7789, codigoIBGE: 4120507, nomeTOM: 'PRIMEIRO DE MAIO', nomeIBGE: 'Primeiro de Maio' },
    { codigoTOM: 7791, codigoIBGE: 4120606, nomeTOM: 'PRUDENTÓPOLIS', nomeIBGE: 'Prudentópolis' },
    { codigoTOM: 7793, codigoIBGE: 4120705, nomeTOM: 'QUATIGUÁ', nomeIBGE: 'Quatiguá' },
    { codigoTOM: 7795, codigoIBGE: 4120804, nomeTOM: 'QUATRO BARRAS', nomeIBGE: 'Quatro Barras' },
    { codigoTOM: 7797, codigoIBGE: 4121000, nomeTOM: 'QUERÊNCIA DO NORTE', nomeIBGE: 'Querência do Norte' },
    { codigoTOM: 7799, codigoIBGE: 4121109, nomeTOM: 'QUINTA DO SOL', nomeIBGE: 'Quinta do Sol' },
    { codigoTOM: 7801, codigoIBGE: 4121208, nomeTOM: 'QUITANDINHA', nomeIBGE: 'Quitandinha' },
    { codigoTOM: 7803, codigoIBGE: 4121307, nomeTOM: 'RANCHO ALEGRE', nomeIBGE: 'Rancho Alegre' },
    { codigoTOM: 7805, codigoIBGE: 4121406, nomeTOM: 'REALEZA', nomeIBGE: 'Realeza' },
    { codigoTOM: 7807, codigoIBGE: 4121505, nomeTOM: 'REBOUÇAS', nomeIBGE: 'Rebouças' },
    { codigoTOM: 7809, codigoIBGE: 4121604, nomeTOM: 'RENASCENÇA', nomeIBGE: 'Renascença' },
    { codigoTOM: 7811, codigoIBGE: 4121703, nomeTOM: 'RESERVA', nomeIBGE: 'Reserva' },
    { codigoTOM: 7813, codigoIBGE: 4121802, nomeTOM: 'RIBEIRÃO CLARO', nomeIBGE: 'Ribeirão Claro' },
    { codigoTOM: 7815, codigoIBGE: 4121901, nomeTOM: 'RIBEIRÃO DO PINHAL', nomeIBGE: 'Ribeirão do Pinhal' },
    { codigoTOM: 7817, codigoIBGE: 4122008, nomeTOM: 'RIO AZUL', nomeIBGE: 'Rio Azul' },
    { codigoTOM: 7819, codigoIBGE: 4122107, nomeTOM: 'RIO BOM', nomeIBGE: 'Rio Bom' },
    { codigoTOM: 7821, codigoIBGE: 4122206, nomeTOM: 'RIO BRANCO DO SUL', nomeIBGE: 'Rio Branco do Sul' },
    { codigoTOM: 7823, codigoIBGE: 4122305, nomeTOM: 'RIO NEGRO', nomeIBGE: 'Rio Negro' },
    { codigoTOM: 7825, codigoIBGE: 4122404, nomeTOM: 'ROLÂNDIA', nomeIBGE: 'Rolândia' },
    { codigoTOM: 7827, codigoIBGE: 4122503, nomeTOM: 'RONCADOR', nomeIBGE: 'Roncador' },
    { codigoTOM: 7829, codigoIBGE: 4122602, nomeTOM: 'RONDON', nomeIBGE: 'Rondon' },
    { codigoTOM: 7831, codigoIBGE: 4122701, nomeTOM: 'SABÁUDIA', nomeIBGE: 'Sabáudia' },
    { codigoTOM: 7833, codigoIBGE: 4122800, nomeTOM: 'SALGADO FILHO', nomeIBGE: 'Salgado Filho' },
    { codigoTOM: 7835, codigoIBGE: 4122909, nomeTOM: 'SALTO DO ITARARÉ', nomeIBGE: 'Salto do Itararé' },
    { codigoTOM: 7837, codigoIBGE: 4123006, nomeTOM: 'SALTO DO LONTRA', nomeIBGE: 'Salto do Lontra' },
    { codigoTOM: 7839, codigoIBGE: 4123105, nomeTOM: 'SANTA AMÉLIA', nomeIBGE: 'Santa Amélia' },
    { codigoTOM: 7841, codigoIBGE: 4123204, nomeTOM: 'SANTA CECÍLIA DO PAVÃO', nomeIBGE: 'Santa Cecília do Pavão' },
    {
      codigoTOM: 7843,
      codigoIBGE: 4123303,
      nomeTOM: 'SANTA CRUZ DE MONTE CASTELO',
      nomeIBGE: 'Santa Cruz de Monte Castelo',
    },
    { codigoTOM: 7845, codigoIBGE: 4123402, nomeTOM: 'SANTA FÉ', nomeIBGE: 'Santa Fé' },
    { codigoTOM: 7847, codigoIBGE: 4123600, nomeTOM: 'SANTA INÊS', nomeIBGE: 'Santa Inês' },
    { codigoTOM: 7849, codigoIBGE: 4123709, nomeTOM: 'SANTA ISABEL DO IVAÍ', nomeIBGE: 'Santa Isabel do Ivaí' },
    { codigoTOM: 7851, codigoIBGE: 4123808, nomeTOM: 'SANTA IZABEL DO OESTE', nomeIBGE: 'Santa Izabel do Oeste' },
    { codigoTOM: 7853, codigoIBGE: 4123907, nomeTOM: 'SANTA MARIANA', nomeIBGE: 'Santa Mariana' },
    { codigoTOM: 7855, codigoIBGE: 4124004, nomeTOM: 'SANTANA DO ITARARÉ', nomeIBGE: 'Santana do Itararé' },
    {
      codigoTOM: 7857,
      codigoIBGE: 4124400,
      nomeTOM: 'SANTO ANTÔNIO DO SUDOESTE',
      nomeIBGE: 'Santo Antônio do Sudoeste',
    },
    { codigoTOM: 7859, codigoIBGE: 4124103, nomeTOM: 'SANTO ANTÔNIO DA PLATINA', nomeIBGE: 'Santo Antônio da Platina' },
    { codigoTOM: 7861, codigoIBGE: 4124202, nomeTOM: 'SANTO ANTÔNIO DO CAIUÁ', nomeIBGE: 'Santo Antônio do Caiuá' },
    { codigoTOM: 7863, codigoIBGE: 4124301, nomeTOM: 'SANTO ANTÔNIO DO PARAÍSO', nomeIBGE: 'Santo Antônio do Paraíso' },
    { codigoTOM: 7865, codigoIBGE: 4124509, nomeTOM: 'SANTO INÁCIO', nomeIBGE: 'Santo Inácio' },
    { codigoTOM: 7867, codigoIBGE: 4124608, nomeTOM: 'SÃO CARLOS DO IVAÍ', nomeIBGE: 'São Carlos do Ivaí' },
    { codigoTOM: 7869, codigoIBGE: 4124707, nomeTOM: 'SÃO JERÔNIMO DA SERRA', nomeIBGE: 'São Jerônimo da Serra' },
    { codigoTOM: 7871, codigoIBGE: 4124806, nomeTOM: 'SÃO JOÃO', nomeIBGE: 'São João' },
    { codigoTOM: 7873, codigoIBGE: 4124905, nomeTOM: 'SÃO JOÃO DO CAIUÁ', nomeIBGE: 'São João do Caiuá' },
    { codigoTOM: 7875, codigoIBGE: 4125001, nomeTOM: 'SÃO JOÃO DO IVAÍ', nomeIBGE: 'São João do Ivaí' },
    { codigoTOM: 7877, codigoIBGE: 4125100, nomeTOM: 'SÃO JOÃO DO TRIUNFO', nomeIBGE: 'São João do Triunfo' },
    { codigoTOM: 7879, codigoIBGE: 4125308, nomeTOM: 'SÃO JORGE DO IVAÍ', nomeIBGE: 'São Jorge do Ivaí' },
    { codigoTOM: 7881, codigoIBGE: 4125209, nomeTOM: "SÃO JORGE D'OESTE", nomeIBGE: "São Jorge d'Oeste" },
    { codigoTOM: 7883, codigoIBGE: 4125407, nomeTOM: 'SÃO JOSÉ DA BOA VISTA', nomeIBGE: 'São José da Boa Vista' },
    { codigoTOM: 7885, codigoIBGE: 4125506, nomeTOM: 'SÃO JOSÉ DOS PINHAIS', nomeIBGE: 'São José dos Pinhais' },
    { codigoTOM: 7887, codigoIBGE: 4125605, nomeTOM: 'SÃO MATEUS DO SUL', nomeIBGE: 'São Mateus do Sul' },
    { codigoTOM: 7889, codigoIBGE: 4125704, nomeTOM: 'SÃO MIGUEL DO IGUAÇU', nomeIBGE: 'São Miguel do Iguaçu' },
    { codigoTOM: 7891, codigoIBGE: 4125803, nomeTOM: 'SÃO PEDRO DO IVAÍ', nomeIBGE: 'São Pedro do Ivaí' },
    { codigoTOM: 7893, codigoIBGE: 4125902, nomeTOM: 'SÃO PEDRO DO PARANÁ', nomeIBGE: 'São Pedro do Paraná' },
    {
      codigoTOM: 7895,
      codigoIBGE: 4126009,
      nomeTOM: 'SÃO SEBASTIÃO DA AMOREIRA',
      nomeIBGE: 'São Sebastião da Amoreira',
    },
    { codigoTOM: 7897, codigoIBGE: 4126108, nomeTOM: 'SÃO TOMÉ', nomeIBGE: 'São Tomé' },
    { codigoTOM: 7899, codigoIBGE: 4126207, nomeTOM: 'SAPOPEMA', nomeIBGE: 'Sapopema' },
    { codigoTOM: 7901, codigoIBGE: 4126306, nomeTOM: 'SENGÉS', nomeIBGE: 'Sengés' },
    { codigoTOM: 7903, codigoIBGE: 4126405, nomeTOM: 'SERTANEJA', nomeIBGE: 'Sertaneja' },
    { codigoTOM: 7905, codigoIBGE: 4126504, nomeTOM: 'SERTANÓPOLIS', nomeIBGE: 'Sertanópolis' },
    { codigoTOM: 7907, codigoIBGE: 4126603, nomeTOM: 'SIQUEIRA CAMPOS', nomeIBGE: 'Siqueira Campos' },
    { codigoTOM: 7909, codigoIBGE: 4126702, nomeTOM: 'TAMBOARA', nomeIBGE: 'Tamboara' },
    { codigoTOM: 7911, codigoIBGE: 4126801, nomeTOM: 'TAPEJARA', nomeIBGE: 'Tapejara' },
    { codigoTOM: 7913, codigoIBGE: 4127007, nomeTOM: 'TEIXEIRA SOARES', nomeIBGE: 'Teixeira Soares' },
    { codigoTOM: 7915, codigoIBGE: 4127106, nomeTOM: 'TELÊMACO BORBA', nomeIBGE: 'Telêmaco Borba' },
    { codigoTOM: 7917, codigoIBGE: 4127205, nomeTOM: 'TERRA BOA', nomeIBGE: 'Terra Boa' },
    { codigoTOM: 7919, codigoIBGE: 4127304, nomeTOM: 'TERRA RICA', nomeIBGE: 'Terra Rica' },
    { codigoTOM: 7921, codigoIBGE: 4127403, nomeTOM: 'TERRA ROXA', nomeIBGE: 'Terra Roxa' },
    { codigoTOM: 7923, codigoIBGE: 4127502, nomeTOM: 'TIBAGI', nomeIBGE: 'Tibagi' },
    { codigoTOM: 7925, codigoIBGE: 4127601, nomeTOM: 'TIJUCAS DO SUL', nomeIBGE: 'Tijucas do Sul' },
    { codigoTOM: 7927, codigoIBGE: 4127700, nomeTOM: 'TOLEDO', nomeIBGE: 'Toledo' },
    { codigoTOM: 7929, codigoIBGE: 4127809, nomeTOM: 'TOMAZINA', nomeIBGE: 'Tomazina' },
    { codigoTOM: 7931, codigoIBGE: 4127908, nomeTOM: 'TUNEIRAS DO OESTE', nomeIBGE: 'Tuneiras do Oeste' },
    { codigoTOM: 7933, codigoIBGE: 4128005, nomeTOM: 'UBIRATÃ', nomeIBGE: 'Ubiratã' },
    { codigoTOM: 7935, codigoIBGE: 4128104, nomeTOM: 'UMUARAMA', nomeIBGE: 'Umuarama' },
    { codigoTOM: 7937, codigoIBGE: 4128203, nomeTOM: 'UNIÃO DA VITÓRIA', nomeIBGE: 'União da Vitória' },
    { codigoTOM: 7939, codigoIBGE: 4128302, nomeTOM: 'UNIFLOR', nomeIBGE: 'Uniflor' },
    { codigoTOM: 7941, codigoIBGE: 4128401, nomeTOM: 'URAÍ', nomeIBGE: 'Uraí' },
    { codigoTOM: 7943, codigoIBGE: 4128500, nomeTOM: 'WENCESLAU BRAZ', nomeIBGE: 'Wenceslau Braz' },
    { codigoTOM: 7945, codigoIBGE: 4128609, nomeTOM: 'VERÊ', nomeIBGE: 'Verê' },
    { codigoTOM: 7947, codigoIBGE: 4128708, nomeTOM: 'VITORINO', nomeIBGE: 'Vitorino' },
    { codigoTOM: 7949, codigoIBGE: 4128807, nomeTOM: 'XAMBRÊ', nomeIBGE: 'Xambrê' },
    { codigoTOM: 7951, codigoIBGE: 4100509, nomeTOM: 'ALTÔNIA', nomeIBGE: 'Altônia' },
    { codigoTOM: 7953, codigoIBGE: 4102000, nomeTOM: 'ASSIS CHATEAUBRIAND', nomeIBGE: 'Assis Chateaubriand' },
    { codigoTOM: 7955, codigoIBGE: 4120903, nomeTOM: 'QUEDAS DO IGUAÇU', nomeIBGE: 'Quedas do Iguaçu' },
    { codigoTOM: 7957, codigoIBGE: 4105300, nomeTOM: 'CÉU AZUL', nomeIBGE: 'Céu Azul' },
    { codigoTOM: 7959, codigoIBGE: 4108700, nomeTOM: 'GRANDES RIOS', nomeIBGE: 'Grandes Rios' },
    { codigoTOM: 7961, codigoIBGE: 4110409, nomeTOM: 'INDIANÓPOLIS', nomeIBGE: 'Indianópolis' },
    { codigoTOM: 7963, codigoIBGE: 4115705, nomeTOM: 'MATINHOS', nomeIBGE: 'Matinhos' },
    { codigoTOM: 7965, codigoIBGE: 4116703, nomeTOM: 'NOVA AURORA', nomeIBGE: 'Nova Aurora' },
    { codigoTOM: 7967, codigoIBGE: 4117206, nomeTOM: 'NOVA OLÍMPIA', nomeIBGE: 'Nova Olímpia' },
    { codigoTOM: 7969, codigoIBGE: 4118907, nomeTOM: 'PÉROLA', nomeIBGE: 'Pérola' },
    { codigoTOM: 7971, codigoIBGE: 4123501, nomeTOM: 'SANTA HELENA', nomeIBGE: 'Santa Helena' },
    { codigoTOM: 7973, codigoIBGE: 4126900, nomeTOM: 'TAPIRA', nomeIBGE: 'Tapira' },
    { codigoTOM: 7975, codigoIBGE: 4115002, nomeTOM: 'MARILENA', nomeIBGE: 'Marilena' },
    { codigoTOM: 7977, codigoIBGE: 4108320, nomeTOM: 'FRANCISCO ALVES', nomeIBGE: 'Francisco Alves' },
    { codigoTOM: 7979, codigoIBGE: 4117222, nomeTOM: 'NOVA SANTA ROSA', nomeIBGE: 'Nova Santa Rosa' },
    { codigoTOM: 7981, codigoIBGE: 4103057, nomeTOM: 'BOA VISTA DA APARECIDA', nomeIBGE: 'Boa Vista da Aparecida' },
    { codigoTOM: 7983, codigoIBGE: 4103354, nomeTOM: 'BRAGANEY', nomeIBGE: 'Braganey' },
    { codigoTOM: 7985, codigoIBGE: 4103453, nomeTOM: 'CAFELÂNDIA', nomeIBGE: 'Cafelândia' },
    { codigoTOM: 7987, codigoIBGE: 4127858, nomeTOM: 'TRÊS BARRAS DO PARANÁ', nomeIBGE: 'Três Barras do Paraná' },
    { codigoTOM: 7989, codigoIBGE: 4128559, nomeTOM: 'VERA CRUZ DO OESTE', nomeIBGE: 'Vera Cruz do Oeste' },
    { codigoTOM: 7991, codigoIBGE: 4120358, nomeTOM: 'PRANCHITA', nomeIBGE: 'Pranchita' },
    { codigoTOM: 7993, codigoIBGE: 4127957, nomeTOM: 'TUPÃSSI', nomeIBGE: 'Tupãssi' },
    { codigoTOM: 7995, codigoIBGE: 4117255, nomeTOM: 'NOVA PRATA DO IGUAÇU', nomeIBGE: 'Nova Prata do Iguaçu' },
    { codigoTOM: 7997, codigoIBGE: 4112751, nomeTOM: 'JESUÍTAS', nomeIBGE: 'Jesuítas' },
    { codigoTOM: 7999, codigoIBGE: 4125357, nomeTOM: 'SÃO JORGE DO PATROCÍNIO', nomeIBGE: 'São Jorge do Patrocínio' },
    { codigoTOM: 8451, codigoIBGE: 4104451, nomeTOM: 'CANTAGALO', nomeIBGE: 'Cantagalo' },
    { codigoTOM: 8453, codigoIBGE: 4127965, nomeTOM: 'TURVO', nomeIBGE: 'Turvo' },
    { codigoTOM: 8455, codigoIBGE: 4100459, nomeTOM: 'ALTAMIRA DO PARANÁ', nomeIBGE: 'Altamira do Paraná' },
    { codigoTOM: 8457, codigoIBGE: 4107751, nomeTOM: 'FIGUEIRA', nomeIBGE: 'Figueira' },
    { codigoTOM: 8459, codigoIBGE: 4113759, nomeTOM: 'LUNARDELLI', nomeIBGE: 'Lunardelli' },
    { codigoTOM: 8461, codigoIBGE: 4126256, nomeTOM: 'SARANDI', nomeIBGE: 'Sarandi' },
    { codigoTOM: 8463, codigoIBGE: 4112959, nomeTOM: 'JURANDA', nomeIBGE: 'Juranda' },
    { codigoTOM: 8465, codigoIBGE: 4107256, nomeTOM: 'DOURADINA', nomeIBGE: 'Douradina' },
    {
      codigoTOM: 8467,
      codigoIBGE: 4124053,
      nomeTOM: 'SANTA TEREZINHA DE ITAIPU',
      nomeIBGE: 'Santa Terezinha de Itaipu',
    },
    { codigoTOM: 8469, codigoIBGE: 4116059, nomeTOM: 'MISSAL', nomeIBGE: 'Missal' },
    { codigoTOM: 8471, codigoIBGE: 4125456, nomeTOM: 'SÃO JOSÉ DAS PALMEIRAS', nomeIBGE: 'São José das Palmeiras' },
    { codigoTOM: 8473, codigoIBGE: 4122651, nomeTOM: 'ROSÁRIO DO IVAÍ', nomeIBGE: 'Rosário do Ivaí' },
    { codigoTOM: 8475, codigoIBGE: 4104055, nomeTOM: 'CAMPO BONITO', nomeIBGE: 'Campo Bonito' },
    { codigoTOM: 8477, codigoIBGE: 4126652, nomeTOM: 'SULINA', nomeIBGE: 'Sulina' },
    { codigoTOM: 8479, codigoIBGE: 4106555, nomeTOM: 'CORUMBATAÍ DO SUL', nomeIBGE: 'Corumbataí do Sul' },
    { codigoTOM: 8481, codigoIBGE: 4113734, nomeTOM: 'LUIZIANA', nomeIBGE: 'Luiziana' },
    { codigoTOM: 9913, codigoIBGE: 4117271, nomeTOM: 'NOVA TEBAS', nomeIBGE: 'Nova Tebas' },
    { codigoTOM: 9915, codigoIBGE: 4107157, nomeTOM: "DIAMANTE D'OESTE", nomeIBGE: "Diamante D'Oeste" },
    { codigoTOM: 9947, codigoIBGE: 4108551, nomeTOM: 'GODOY MOREIRA', nomeIBGE: 'Godoy Moreira' },
    { codigoTOM: 9949, codigoIBGE: 4109757, nomeTOM: 'IBEMA', nomeIBGE: 'Ibema' },
    { codigoTOM: 9955, codigoIBGE: 4111555, nomeTOM: 'IVATÉ', nomeIBGE: 'Ivaté' },
    { codigoTOM: 9959, codigoIBGE: 4113452, nomeTOM: 'LINDOESTE', nomeIBGE: 'Lindoeste' },
    { codigoTOM: 9965, codigoIBGE: 4117453, nomeTOM: 'OURO VERDE DO OESTE', nomeIBGE: 'Ouro Verde do Oeste' },
    { codigoTOM: 9969, codigoIBGE: 4124020, nomeTOM: 'SANTA TEREZA DO OESTE', nomeIBGE: 'Santa Tereza do Oeste' },
    { codigoTOM: 9979, codigoIBGE: 4103222, nomeTOM: 'BOM SUCESSO DO SUL', nomeIBGE: 'Bom Sucesso do Sul' },
    { codigoTOM: 9981, codigoIBGE: 4109658, nomeTOM: 'HONÓRIO SERPA', nomeIBGE: 'Honório Serpa' },
    { codigoTOM: 9983, codigoIBGE: 4107652, nomeTOM: 'FAZENDA RIO GRANDE', nomeIBGE: 'Fazenda Rio Grande' },
  ],
  [Estados.SC]: [
    { codigoTOM: 886, codigoIBGE: 4200754, nomeTOM: 'ALTO BELA VISTA', nomeIBGE: 'Alto Bela Vista' },
    {
      codigoTOM: 888,
      codigoIBGE: 4201950,
      nomeTOM: 'BALNEÁRIO ARROIO DO SILVA',
      nomeIBGE: 'Balneário Arroio do Silva',
    },
    { codigoTOM: 890, codigoIBGE: 4202073, nomeTOM: 'BALNEÁRIO GAIVOTA', nomeIBGE: 'Balneário Gaivota' },
    { codigoTOM: 892, codigoIBGE: 4202081, nomeTOM: 'BANDEIRANTE', nomeIBGE: 'Bandeirante' },
    { codigoTOM: 894, codigoIBGE: 4202099, nomeTOM: 'BARRA BONITA', nomeIBGE: 'Barra Bonita' },
    { codigoTOM: 896, codigoIBGE: 4202131, nomeTOM: 'BELA VISTA DO TOLDO', nomeIBGE: 'Bela Vista do Toldo' },
    { codigoTOM: 898, codigoIBGE: 4202438, nomeTOM: 'BOCAINA DO SUL', nomeIBGE: 'Bocaina do Sul' },
    { codigoTOM: 900, codigoIBGE: 4202537, nomeTOM: 'BOM JESUS', nomeIBGE: 'Bom Jesus' },
    { codigoTOM: 902, codigoIBGE: 4202578, nomeTOM: 'BOM JESUS DO OESTE', nomeIBGE: 'Bom Jesus do Oeste' },
    { codigoTOM: 904, codigoIBGE: 4202875, nomeTOM: 'BRUNÓPOLIS', nomeIBGE: 'Brunópolis' },
    { codigoTOM: 906, codigoIBGE: 4203253, nomeTOM: 'CAPÃO ALTO', nomeIBGE: 'Capão Alto' },
    { codigoTOM: 908, codigoIBGE: 4204194, nomeTOM: 'CHAPADÃO DO LAGEADO', nomeIBGE: 'Chapadão do Lageado' },
    { codigoTOM: 910, codigoIBGE: 4204756, nomeTOM: 'CUNHATAÍ', nomeIBGE: 'Cunhataí' },
    { codigoTOM: 912, codigoIBGE: 4205175, nomeTOM: 'ENTRE RIOS', nomeIBGE: 'Entre Rios' },
    { codigoTOM: 914, codigoIBGE: 4205191, nomeTOM: 'ERMO', nomeIBGE: 'Ermo' },
    { codigoTOM: 916, codigoIBGE: 4205357, nomeTOM: 'FLOR DO SERTÃO', nomeIBGE: 'Flor do Sertão' },
    { codigoTOM: 918, codigoIBGE: 4205555, nomeTOM: 'FREI ROGÉRIO', nomeIBGE: 'Frei Rogério' },
    { codigoTOM: 920, codigoIBGE: 4206751, nomeTOM: 'IBIAM', nomeIBGE: 'Ibiam' },
    { codigoTOM: 922, codigoIBGE: 4207577, nomeTOM: 'IOMERÊ', nomeIBGE: 'Iomerê' },
    { codigoTOM: 924, codigoIBGE: 4209177, nomeTOM: 'JUPIÁ', nomeIBGE: 'Jupiá' },
    { codigoTOM: 926, codigoIBGE: 4210035, nomeTOM: 'LUZERNA', nomeIBGE: 'Luzerna' },
    { codigoTOM: 928, codigoIBGE: 4211876, nomeTOM: 'PAIAL', nomeIBGE: 'Paial' },
    { codigoTOM: 930, codigoIBGE: 4211892, nomeTOM: 'PAINEL', nomeIBGE: 'Painel' },
    { codigoTOM: 932, codigoIBGE: 4212056, nomeTOM: 'PALMEIRA', nomeIBGE: 'Palmeira' },
    { codigoTOM: 934, codigoIBGE: 4214151, nomeTOM: 'PRINCESA', nomeIBGE: 'Princesa' },
    { codigoTOM: 936, codigoIBGE: 4215356, nomeTOM: 'SALTINHO', nomeIBGE: 'Saltinho' },
    {
      codigoTOM: 938,
      codigoIBGE: 4215687,
      nomeTOM: 'SANTA TEREZINHA DO PROGRESSO',
      nomeIBGE: 'Santa Terezinha do Progresso',
    },
    { codigoTOM: 940, codigoIBGE: 4215695, nomeTOM: 'SANTIAGO DO SUL', nomeIBGE: 'Santiago do Sul' },
    { codigoTOM: 942, codigoIBGE: 4215752, nomeTOM: 'SÃO BERNARDINO', nomeIBGE: 'São Bernardino' },
    { codigoTOM: 944, codigoIBGE: 4217253, nomeTOM: 'SÃO PEDRO DE ALCÂNTARA', nomeIBGE: 'São Pedro de Alcântara' },
    { codigoTOM: 946, codigoIBGE: 4217956, nomeTOM: 'TIGRINHOS', nomeIBGE: 'Tigrinhos' },
    { codigoTOM: 948, codigoIBGE: 4218350, nomeTOM: 'TREVISO', nomeIBGE: 'Treviso' },
    { codigoTOM: 950, codigoIBGE: 4219853, nomeTOM: 'ZORTÉA', nomeIBGE: 'Zortéa' },
    { codigoTOM: 973, codigoIBGE: 4205456, nomeTOM: 'FORQUILHINHA', nomeIBGE: 'Forquilhinha' },
    { codigoTOM: 5537, codigoIBGE: 4202453, nomeTOM: 'BOMBINHAS', nomeIBGE: 'Bombinhas' },
    { codigoTOM: 5539, codigoIBGE: 4211256, nomeTOM: 'MORRO GRANDE', nomeIBGE: 'Morro Grande' },
    { codigoTOM: 5541, codigoIBGE: 4212254, nomeTOM: 'PASSO DE TORRES', nomeIBGE: 'Passo de Torres' },
    { codigoTOM: 5543, codigoIBGE: 4204251, nomeTOM: 'COCAL DO SUL', nomeIBGE: 'Cocal do Sul' },
    { codigoTOM: 5545, codigoIBGE: 4203956, nomeTOM: 'CAPIVARI DE BAIXO', nomeIBGE: 'Capivari de Baixo' },
    { codigoTOM: 5547, codigoIBGE: 4215455, nomeTOM: 'SANGÃO', nomeIBGE: 'Sangão' },
    { codigoTOM: 5549, codigoIBGE: 4202057, nomeTOM: 'BALNEÁRIO BARRA DO SUL', nomeIBGE: 'Balneário Barra do Sul' },
    { codigoTOM: 5551, codigoIBGE: 4216354, nomeTOM: 'SÃO JOÃO DO ITAPERIÚ', nomeIBGE: 'São João do Itaperiú' },
    { codigoTOM: 5553, codigoIBGE: 4203154, nomeTOM: 'CALMON', nomeIBGE: 'Calmon' },
    { codigoTOM: 5555, codigoIBGE: 4215679, nomeTOM: 'SANTA TEREZINHA', nomeIBGE: 'Santa Terezinha' },
    { codigoTOM: 5557, codigoIBGE: 4202859, nomeTOM: 'BRAÇO DO TROMBUDO', nomeIBGE: 'Braço do Trombudo' },
    { codigoTOM: 5559, codigoIBGE: 4210852, nomeTOM: 'MIRIM DOCE', nomeIBGE: 'Mirim Doce' },
    { codigoTOM: 5561, codigoIBGE: 4211058, nomeTOM: 'MONTE CARLO', nomeIBGE: 'Monte Carlo' },
    { codigoTOM: 5563, codigoIBGE: 4219150, nomeTOM: 'VARGEM', nomeIBGE: 'Vargem' },
    { codigoTOM: 5565, codigoIBGE: 4219176, nomeTOM: 'VARGEM BONITA', nomeIBGE: 'Vargem Bonita' },
    { codigoTOM: 5567, codigoIBGE: 4204178, nomeTOM: 'CERRO NEGRO', nomeIBGE: 'Cerro Negro' },
    { codigoTOM: 5569, codigoIBGE: 4213351, nomeTOM: 'PONTE ALTA DO NORTE', nomeIBGE: 'Ponte Alta do Norte' },
    { codigoTOM: 5571, codigoIBGE: 4215059, nomeTOM: 'RIO RUFINO', nomeIBGE: 'Rio Rufino' },
    { codigoTOM: 5573, codigoIBGE: 4216057, nomeTOM: 'SÃO CRISTOVÃO DO SUL', nomeIBGE: 'São Cristóvão do Sul' },
    { codigoTOM: 5575, codigoIBGE: 4210050, nomeTOM: 'MACIEIRA', nomeIBGE: 'Macieira' },
    { codigoTOM: 5577, codigoIBGE: 4200556, nomeTOM: 'ÁGUAS FRIAS', nomeIBGE: 'Águas Frias' },
    { codigoTOM: 5579, codigoIBGE: 4204350, nomeTOM: 'CORDILHEIRA ALTA', nomeIBGE: 'Cordilheira Alta' },
    { codigoTOM: 5581, codigoIBGE: 4205431, nomeTOM: 'FORMOSA DO SUL', nomeIBGE: 'Formosa do Sul' },
    { codigoTOM: 5583, codigoIBGE: 4206652, nomeTOM: 'GUATAMBÚ', nomeIBGE: 'Guatambú' },
    { codigoTOM: 5585, codigoIBGE: 4207858, nomeTOM: 'IRATI', nomeIBGE: 'Irati' },
    { codigoTOM: 5587, codigoIBGE: 4208955, nomeTOM: 'JARDINÓPOLIS', nomeIBGE: 'Jardinópolis' },
    { codigoTOM: 5589, codigoIBGE: 4211454, nomeTOM: 'NOVA ITABERABA', nomeIBGE: 'Nova Itaberaba' },
    { codigoTOM: 5591, codigoIBGE: 4211652, nomeTOM: 'NOVO HORIZONTE', nomeIBGE: 'Novo Horizonte' },
    { codigoTOM: 5593, codigoIBGE: 4213153, nomeTOM: 'PLANALTO ALEGRE', nomeIBGE: 'Planalto Alegre' },
    { codigoTOM: 5595, codigoIBGE: 4217758, nomeTOM: 'SUL BRASIL', nomeIBGE: 'Sul Brasil' },
    { codigoTOM: 5597, codigoIBGE: 4201273, nomeTOM: 'ARABUTÃ', nomeIBGE: 'Arabutã' },
    { codigoTOM: 5599, codigoIBGE: 4201653, nomeTOM: 'ARVOREDO', nomeIBGE: 'Arvoredo' },
    { codigoTOM: 5735, codigoIBGE: 4204459, nomeTOM: 'CORONEL MARTINS', nomeIBGE: 'Coronel Martins' },
    { codigoTOM: 5737, codigoIBGE: 4207684, nomeTOM: 'IPUAÇU', nomeIBGE: 'Ipuaçu' },
    { codigoTOM: 5739, codigoIBGE: 4209458, nomeTOM: 'Lajeado Grande', nomeIBGE: 'Lajeado Grande' },
    { codigoTOM: 5741, codigoIBGE: 4211850, nomeTOM: 'OURO VERDE', nomeIBGE: 'Ouro Verde' },
    { codigoTOM: 5743, codigoIBGE: 4212270, nomeTOM: 'PASSOS MAIA', nomeIBGE: 'Passos Maia' },
    { codigoTOM: 5745, codigoIBGE: 4202156, nomeTOM: 'BELMONTE', nomeIBGE: 'Belmonte' },
    { codigoTOM: 5747, codigoIBGE: 4212239, nomeTOM: 'PARAÍSO', nomeIBGE: 'Paraíso' },
    { codigoTOM: 5749, codigoIBGE: 4215075, nomeTOM: 'RIQUEZA', nomeIBGE: 'Riqueza' },
    { codigoTOM: 5751, codigoIBGE: 4215554, nomeTOM: 'SANTA HELENA', nomeIBGE: 'Santa Helena' },
    { codigoTOM: 5753, codigoIBGE: 4216255, nomeTOM: 'SÃO JOÃO DO OESTE', nomeIBGE: 'São João do Oeste' },
    { codigoTOM: 5755, codigoIBGE: 4217154, nomeTOM: 'SÃO MIGUEL DA BOA VISTA', nomeIBGE: 'São Miguel da Boa Vista' },
    { codigoTOM: 8001, codigoIBGE: 4200101, nomeTOM: 'ABELARDO LUZ', nomeIBGE: 'Abelardo Luz' },
    { codigoTOM: 8003, codigoIBGE: 4200200, nomeTOM: 'AGROLÂNDIA', nomeIBGE: 'Agrolândia' },
    { codigoTOM: 8005, codigoIBGE: 4200309, nomeTOM: 'AGRONÔMICA', nomeIBGE: 'Agronômica' },
    { codigoTOM: 8007, codigoIBGE: 4200408, nomeTOM: 'ÁGUA DOCE', nomeIBGE: 'Água Doce' },
    { codigoTOM: 8009, codigoIBGE: 4200507, nomeTOM: 'ÁGUAS DE CHAPECÓ', nomeIBGE: 'Águas de Chapecó' },
    { codigoTOM: 8011, codigoIBGE: 4200606, nomeTOM: 'ÁGUAS MORNAS', nomeIBGE: 'Águas Mornas' },
    { codigoTOM: 8013, codigoIBGE: 4200705, nomeTOM: 'ALFREDO WAGNER', nomeIBGE: 'Alfredo Wagner' },
    { codigoTOM: 8015, codigoIBGE: 4200804, nomeTOM: 'ANCHIETA', nomeIBGE: 'Anchieta' },
    { codigoTOM: 8017, codigoIBGE: 4200903, nomeTOM: 'ANGELINA', nomeIBGE: 'Angelina' },
    { codigoTOM: 8019, codigoIBGE: 4201000, nomeTOM: 'ANITA GARIBALDI', nomeIBGE: 'Anita Garibaldi' },
    { codigoTOM: 8021, codigoIBGE: 4201109, nomeTOM: 'ANITÁPOLIS', nomeIBGE: 'Anitápolis' },
    { codigoTOM: 8023, codigoIBGE: 4201208, nomeTOM: 'ANTÔNIO CARLOS', nomeIBGE: 'Antônio Carlos' },
    { codigoTOM: 8025, codigoIBGE: 4201307, nomeTOM: 'ARAQUARI', nomeIBGE: 'Araquari' },
    { codigoTOM: 8027, codigoIBGE: 4201406, nomeTOM: 'ARARANGUÁ', nomeIBGE: 'Araranguá' },
    { codigoTOM: 8029, codigoIBGE: 4201505, nomeTOM: 'ARMAZÉM', nomeIBGE: 'Armazém' },
    { codigoTOM: 8031, codigoIBGE: 4201604, nomeTOM: 'ARROIO TRINTA', nomeIBGE: 'Arroio Trinta' },
    { codigoTOM: 8033, codigoIBGE: 4201703, nomeTOM: 'ASCURRA', nomeIBGE: 'Ascurra' },
    { codigoTOM: 8035, codigoIBGE: 4201802, nomeTOM: 'ATALANTA', nomeIBGE: 'Atalanta' },
    { codigoTOM: 8037, codigoIBGE: 4201901, nomeTOM: 'AURORA', nomeIBGE: 'Aurora' },
    { codigoTOM: 8039, codigoIBGE: 4202008, nomeTOM: 'BALNEÁRIO CAMBORIÚ', nomeIBGE: 'Balneário Camboriú' },
    { codigoTOM: 8041, codigoIBGE: 4202107, nomeTOM: 'BARRA VELHA', nomeIBGE: 'Barra Velha' },
    { codigoTOM: 8043, codigoIBGE: 4202206, nomeTOM: 'BENEDITO NOVO', nomeIBGE: 'Benedito Novo' },
    { codigoTOM: 8045, codigoIBGE: 4202305, nomeTOM: 'BIGUAÇU', nomeIBGE: 'Biguaçu' },
    { codigoTOM: 8047, codigoIBGE: 4202404, nomeTOM: 'BLUMENAU', nomeIBGE: 'Blumenau' },
    { codigoTOM: 8049, codigoIBGE: 4202602, nomeTOM: 'BOM RETIRO', nomeIBGE: 'Bom Retiro' },
    { codigoTOM: 8051, codigoIBGE: 4202701, nomeTOM: 'BOTUVERÁ', nomeIBGE: 'Botuverá' },
    { codigoTOM: 8053, codigoIBGE: 4202800, nomeTOM: 'BRAÇO DO NORTE', nomeIBGE: 'Braço do Norte' },
    { codigoTOM: 8055, codigoIBGE: 4202909, nomeTOM: 'BRUSQUE', nomeIBGE: 'Brusque' },
    { codigoTOM: 8057, codigoIBGE: 4203006, nomeTOM: 'CAÇADOR', nomeIBGE: 'Caçador' },
    { codigoTOM: 8059, codigoIBGE: 4203105, nomeTOM: 'CAIBI', nomeIBGE: 'Caibi' },
    { codigoTOM: 8061, codigoIBGE: 4203204, nomeTOM: 'CAMBORIÚ', nomeIBGE: 'Camboriú' },
    { codigoTOM: 8063, codigoIBGE: 4203303, nomeTOM: 'CAMPO ALEGRE', nomeIBGE: 'Campo Alegre' },
    { codigoTOM: 8065, codigoIBGE: 4203402, nomeTOM: 'CAMPO BELO DO SUL', nomeIBGE: 'Campo Belo do Sul' },
    { codigoTOM: 8067, codigoIBGE: 4203501, nomeTOM: 'CAMPO ERÊ', nomeIBGE: 'Campo Erê' },
    { codigoTOM: 8069, codigoIBGE: 4203600, nomeTOM: 'CAMPOS NOVOS', nomeIBGE: 'Campos Novos' },
    { codigoTOM: 8071, codigoIBGE: 4203709, nomeTOM: 'CANELINHA', nomeIBGE: 'Canelinha' },
    { codigoTOM: 8073, codigoIBGE: 4203808, nomeTOM: 'CANOINHAS', nomeIBGE: 'Canoinhas' },
    { codigoTOM: 8075, codigoIBGE: 4203907, nomeTOM: 'CAPINZAL', nomeIBGE: 'Capinzal' },
    { codigoTOM: 8077, codigoIBGE: 4204004, nomeTOM: 'CATANDUVAS', nomeIBGE: 'Catanduvas' },
    { codigoTOM: 8079, codigoIBGE: 4204103, nomeTOM: 'CAXAMBU DO SUL', nomeIBGE: 'Caxambu do Sul' },
    { codigoTOM: 8081, codigoIBGE: 4204202, nomeTOM: 'CHAPECÓ', nomeIBGE: 'Chapecó' },
    { codigoTOM: 8083, codigoIBGE: 4204301, nomeTOM: 'CONCÓRDIA', nomeIBGE: 'Concórdia' },
    { codigoTOM: 8085, codigoIBGE: 4204400, nomeTOM: 'CORONEL FREITAS', nomeIBGE: 'Coronel Freitas' },
    { codigoTOM: 8087, codigoIBGE: 4204509, nomeTOM: 'CORUPÁ', nomeIBGE: 'Corupá' },
    { codigoTOM: 8089, codigoIBGE: 4204608, nomeTOM: 'CRICIÚMA', nomeIBGE: 'Criciúma' },
    { codigoTOM: 8091, codigoIBGE: 4204707, nomeTOM: 'CUNHA PORÃ', nomeIBGE: 'Cunha Porã' },
    { codigoTOM: 8093, codigoIBGE: 4204806, nomeTOM: 'CURITIBANOS', nomeIBGE: 'Curitibanos' },
    { codigoTOM: 8095, codigoIBGE: 4204905, nomeTOM: 'DESCANSO', nomeIBGE: 'Descanso' },
    { codigoTOM: 8097, codigoIBGE: 4205001, nomeTOM: 'DIONÍSIO CERQUEIRA', nomeIBGE: 'Dionísio Cerqueira' },
    { codigoTOM: 8099, codigoIBGE: 4205100, nomeTOM: 'DONA EMMA', nomeIBGE: 'Dona Emma' },
    { codigoTOM: 8101, codigoIBGE: 4205209, nomeTOM: 'ERVAL VELHO', nomeIBGE: 'Erval Velho' },
    { codigoTOM: 8103, codigoIBGE: 4205308, nomeTOM: 'FAXINAL DOS GUEDES', nomeIBGE: 'Faxinal dos Guedes' },
    { codigoTOM: 8105, codigoIBGE: 4205407, nomeTOM: 'FLORIANÓPOLIS', nomeIBGE: 'Florianópolis' },
    { codigoTOM: 8107, codigoIBGE: 4205506, nomeTOM: 'FRAIBURGO', nomeIBGE: 'Fraiburgo' },
    { codigoTOM: 8109, codigoIBGE: 4205605, nomeTOM: 'GALVÃO', nomeIBGE: 'Galvão' },
    { codigoTOM: 8111, codigoIBGE: 4206009, nomeTOM: 'GOVERNADOR CELSO RAMOS', nomeIBGE: 'Governador Celso Ramos' },
    { codigoTOM: 8113, codigoIBGE: 4205704, nomeTOM: 'GAROPABA', nomeIBGE: 'Garopaba' },
    { codigoTOM: 8115, codigoIBGE: 4205803, nomeTOM: 'GARUVA', nomeIBGE: 'Garuva' },
    { codigoTOM: 8117, codigoIBGE: 4205902, nomeTOM: 'GASPAR', nomeIBGE: 'Gaspar' },
    { codigoTOM: 8119, codigoIBGE: 4206108, nomeTOM: 'GRÃO PARÁ', nomeIBGE: 'Grão-Pará' },
    { codigoTOM: 8121, codigoIBGE: 4206207, nomeTOM: 'GRAVATAL', nomeIBGE: 'Gravatal' },
    { codigoTOM: 8123, codigoIBGE: 4206306, nomeTOM: 'GUABIRUBA', nomeIBGE: 'Guabiruba' },
    { codigoTOM: 8125, codigoIBGE: 4206405, nomeTOM: 'GUARACIABA', nomeIBGE: 'Guaraciaba' },
    { codigoTOM: 8127, codigoIBGE: 4206504, nomeTOM: 'GUARAMIRIM', nomeIBGE: 'Guaramirim' },
    { codigoTOM: 8129, codigoIBGE: 4206603, nomeTOM: 'GUARUJÁ DO SUL', nomeIBGE: 'Guarujá do Sul' },
    { codigoTOM: 8131, codigoIBGE: 4206702, nomeTOM: "HERVAL D'OESTE", nomeIBGE: "Herval d'Oeste" },
    { codigoTOM: 8133, codigoIBGE: 4206801, nomeTOM: 'IBICARÉ', nomeIBGE: 'Ibicaré' },
    { codigoTOM: 8135, codigoIBGE: 4206900, nomeTOM: 'IBIRAMA', nomeIBGE: 'Ibirama' },
    { codigoTOM: 8137, codigoIBGE: 4207007, nomeTOM: 'IÇARA', nomeIBGE: 'Içara' },
    { codigoTOM: 8139, codigoIBGE: 4207106, nomeTOM: 'ILHOTA', nomeIBGE: 'Ilhota' },
    { codigoTOM: 8141, codigoIBGE: 4207205, nomeTOM: 'IMARUÍ', nomeIBGE: 'Imaruí' },
    { codigoTOM: 8143, codigoIBGE: 4207304, nomeTOM: 'IMBITUBA', nomeIBGE: 'Imbituba' },
    { codigoTOM: 8145, codigoIBGE: 4207403, nomeTOM: 'IMBUIA', nomeIBGE: 'Imbuia' },
    { codigoTOM: 8147, codigoIBGE: 4207502, nomeTOM: 'INDAIAL', nomeIBGE: 'Indaial' },
    { codigoTOM: 8149, codigoIBGE: 4207601, nomeTOM: 'IPIRA', nomeIBGE: 'Ipira' },
    { codigoTOM: 8151, codigoIBGE: 4207700, nomeTOM: 'IPUMIRIM', nomeIBGE: 'Ipumirim' },
    { codigoTOM: 8153, codigoIBGE: 4207809, nomeTOM: 'IRANI', nomeIBGE: 'Irani' },
    { codigoTOM: 8155, codigoIBGE: 4207908, nomeTOM: 'IRINEÓPOLIS', nomeIBGE: 'Irineópolis' },
    { codigoTOM: 8157, codigoIBGE: 4208005, nomeTOM: 'ITÁ', nomeIBGE: 'Itá' },
    { codigoTOM: 8159, codigoIBGE: 4208104, nomeTOM: 'ITAIÓPOLIS', nomeIBGE: 'Itaiópolis' },
    { codigoTOM: 8161, codigoIBGE: 4208203, nomeTOM: 'ITAJAÍ', nomeIBGE: 'Itajaí' },
    { codigoTOM: 8163, codigoIBGE: 4208302, nomeTOM: 'ITAPEMA', nomeIBGE: 'Itapema' },
    { codigoTOM: 8165, codigoIBGE: 4208401, nomeTOM: 'ITAPIRANGA', nomeIBGE: 'Itapiranga' },
    { codigoTOM: 8167, codigoIBGE: 4208500, nomeTOM: 'ITUPORANGA', nomeIBGE: 'Ituporanga' },
    { codigoTOM: 8169, codigoIBGE: 4208609, nomeTOM: 'JABORÁ', nomeIBGE: 'Jaborá' },
    { codigoTOM: 8171, codigoIBGE: 4208708, nomeTOM: 'JACINTO MACHADO', nomeIBGE: 'Jacinto Machado' },
    { codigoTOM: 8173, codigoIBGE: 4208807, nomeTOM: 'JAGUARUNA', nomeIBGE: 'Jaguaruna' },
    { codigoTOM: 8175, codigoIBGE: 4208906, nomeTOM: 'JARAGUÁ DO SUL', nomeIBGE: 'Jaraguá do Sul' },
    { codigoTOM: 8177, codigoIBGE: 4209003, nomeTOM: 'JOAÇABA', nomeIBGE: 'Joaçaba' },
    { codigoTOM: 8179, codigoIBGE: 4209102, nomeTOM: 'JOINVILLE', nomeIBGE: 'Joinville' },
    { codigoTOM: 8181, codigoIBGE: 4209201, nomeTOM: 'LACERDÓPOLIS', nomeIBGE: 'Lacerdópolis' },
    { codigoTOM: 8183, codigoIBGE: 4209300, nomeTOM: 'LAGES', nomeIBGE: 'Lages' },
    { codigoTOM: 8185, codigoIBGE: 4209409, nomeTOM: 'LAGUNA', nomeIBGE: 'Laguna' },
    { codigoTOM: 8187, codigoIBGE: 4209508, nomeTOM: 'LAURENTINO', nomeIBGE: 'Laurentino' },
    { codigoTOM: 8189, codigoIBGE: 4209607, nomeTOM: 'LAURO MULLER', nomeIBGE: 'Lauro Müller' },
    { codigoTOM: 8191, codigoIBGE: 4209706, nomeTOM: 'LEBON RÉGIS', nomeIBGE: 'Lebon Régis' },
    { codigoTOM: 8193, codigoIBGE: 4209805, nomeTOM: 'LEOBERTO LEAL', nomeIBGE: 'Leoberto Leal' },
    { codigoTOM: 8195, codigoIBGE: 4209904, nomeTOM: 'LONTRAS', nomeIBGE: 'Lontras' },
    { codigoTOM: 8197, codigoIBGE: 4210001, nomeTOM: 'LUIZ ALVES', nomeIBGE: 'Luiz Alves' },
    { codigoTOM: 8199, codigoIBGE: 4210100, nomeTOM: 'MAFRA', nomeIBGE: 'Mafra' },
    { codigoTOM: 8201, codigoIBGE: 4210209, nomeTOM: 'MAJOR GERCINO', nomeIBGE: 'Major Gercino' },
    { codigoTOM: 8203, codigoIBGE: 4210308, nomeTOM: 'MAJOR VIEIRA', nomeIBGE: 'Major Vieira' },
    { codigoTOM: 8205, codigoIBGE: 4210506, nomeTOM: 'MARAVILHA', nomeIBGE: 'Maravilha' },
    { codigoTOM: 8207, codigoIBGE: 4210605, nomeTOM: 'MASSARANDUBA', nomeIBGE: 'Massaranduba' },
    { codigoTOM: 8209, codigoIBGE: 4210704, nomeTOM: 'MATOS COSTA', nomeIBGE: 'Matos Costa' },
    { codigoTOM: 8211, codigoIBGE: 4210803, nomeTOM: 'MELEIRO', nomeIBGE: 'Meleiro' },
    { codigoTOM: 8213, codigoIBGE: 4210902, nomeTOM: 'MODELO', nomeIBGE: 'Modelo' },
    { codigoTOM: 8215, codigoIBGE: 4211009, nomeTOM: 'MONDAÍ', nomeIBGE: 'Mondaí' },
    { codigoTOM: 8217, codigoIBGE: 4211108, nomeTOM: 'MONTE CASTELO', nomeIBGE: 'Monte Castelo' },
    { codigoTOM: 8219, codigoIBGE: 4211207, nomeTOM: 'MORRO DA FUMAÇA', nomeIBGE: 'Morro da Fumaça' },
    { codigoTOM: 8221, codigoIBGE: 4211306, nomeTOM: 'NAVEGANTES', nomeIBGE: 'Navegantes' },
    { codigoTOM: 8223, codigoIBGE: 4211405, nomeTOM: 'NOVA ERECHIM', nomeIBGE: 'Nova Erechim' },
    { codigoTOM: 8225, codigoIBGE: 4211504, nomeTOM: 'NOVA TRENTO', nomeIBGE: 'Nova Trento' },
    { codigoTOM: 8227, codigoIBGE: 4211603, nomeTOM: 'NOVA VENEZA', nomeIBGE: 'Nova Veneza' },
    { codigoTOM: 8229, codigoIBGE: 4211702, nomeTOM: 'ORLEANS', nomeIBGE: 'Orleans' },
    { codigoTOM: 8231, codigoIBGE: 4211801, nomeTOM: 'OURO', nomeIBGE: 'Ouro' },
    { codigoTOM: 8233, codigoIBGE: 4211900, nomeTOM: 'PALHOÇA', nomeIBGE: 'Palhoça' },
    { codigoTOM: 8235, codigoIBGE: 4212007, nomeTOM: 'PALMA SOLA', nomeIBGE: 'Palma Sola' },
    { codigoTOM: 8237, codigoIBGE: 4212106, nomeTOM: 'PALMITOS', nomeIBGE: 'Palmitos' },
    { codigoTOM: 8239, codigoIBGE: 4212205, nomeTOM: 'PAPANDUVA', nomeIBGE: 'Papanduva' },
    { codigoTOM: 8241, codigoIBGE: 4212304, nomeTOM: 'PAULO LOPES', nomeIBGE: 'Paulo Lopes' },
    { codigoTOM: 8243, codigoIBGE: 4212403, nomeTOM: 'PEDRAS GRANDES', nomeIBGE: 'Pedras Grandes' },
    { codigoTOM: 8245, codigoIBGE: 4212502, nomeTOM: 'PENHA', nomeIBGE: 'Penha' },
    { codigoTOM: 8247, codigoIBGE: 4212601, nomeTOM: 'PERITIBA', nomeIBGE: 'Peritiba' },
    { codigoTOM: 8249, codigoIBGE: 4212700, nomeTOM: 'PETROLÂNDIA', nomeIBGE: 'Petrolândia' },
    { codigoTOM: 8251, codigoIBGE: 4212809, nomeTOM: 'BALNEÁRIO DE PIÇARRAS', nomeIBGE: 'Balneário Piçarras' },
    { codigoTOM: 8253, codigoIBGE: 4212908, nomeTOM: 'PINHALZINHO', nomeIBGE: 'Pinhalzinho' },
    { codigoTOM: 8255, codigoIBGE: 4213005, nomeTOM: 'PINHEIRO PRETO', nomeIBGE: 'Pinheiro Preto' },
    { codigoTOM: 8257, codigoIBGE: 4213104, nomeTOM: 'PIRATUBA', nomeIBGE: 'Piratuba' },
    { codigoTOM: 8259, codigoIBGE: 4213203, nomeTOM: 'POMERODE', nomeIBGE: 'Pomerode' },
    { codigoTOM: 8261, codigoIBGE: 4213302, nomeTOM: 'PONTE ALTA', nomeIBGE: 'Ponte Alta' },
    { codigoTOM: 8263, codigoIBGE: 4213401, nomeTOM: 'PONTE SERRADA', nomeIBGE: 'Ponte Serrada' },
    { codigoTOM: 8265, codigoIBGE: 4213500, nomeTOM: 'PORTO BELO', nomeIBGE: 'Porto Belo' },
    { codigoTOM: 8267, codigoIBGE: 4213609, nomeTOM: 'PORTO UNIÃO', nomeIBGE: 'Porto União' },
    { codigoTOM: 8269, codigoIBGE: 4213708, nomeTOM: 'POUSO REDONDO', nomeIBGE: 'Pouso Redondo' },
    { codigoTOM: 8271, codigoIBGE: 4213807, nomeTOM: 'PRAIA GRANDE', nomeIBGE: 'Praia Grande' },
    {
      codigoTOM: 8273,
      codigoIBGE: 4213906,
      nomeTOM: 'PRESIDENTE CASTELLO BRANCO',
      nomeIBGE: 'Presidente Castello Branco',
    },
    { codigoTOM: 8275, codigoIBGE: 4214003, nomeTOM: 'PRESIDENTE GETÚLIO', nomeIBGE: 'Presidente Getúlio' },
    { codigoTOM: 8277, codigoIBGE: 4214102, nomeTOM: 'PRESIDENTE NEREU', nomeIBGE: 'Presidente Nereu' },
    { codigoTOM: 8279, codigoIBGE: 4214201, nomeTOM: 'QUILOMBO', nomeIBGE: 'Quilombo' },
    { codigoTOM: 8281, codigoIBGE: 4214300, nomeTOM: 'RANCHO QUEIMADO', nomeIBGE: 'Rancho Queimado' },
    { codigoTOM: 8283, codigoIBGE: 4214409, nomeTOM: 'RIO DAS ANTAS', nomeIBGE: 'Rio das Antas' },
    { codigoTOM: 8285, codigoIBGE: 4214508, nomeTOM: 'RIO DO CAMPO', nomeIBGE: 'Rio do Campo' },
    { codigoTOM: 8287, codigoIBGE: 4214607, nomeTOM: 'RIO DO OESTE', nomeIBGE: 'Rio do Oeste' },
    { codigoTOM: 8289, codigoIBGE: 4214706, nomeTOM: 'RIO DOS CEDROS', nomeIBGE: 'Rio dos Cedros' },
    { codigoTOM: 8291, codigoIBGE: 4214805, nomeTOM: 'RIO DO SUL', nomeIBGE: 'Rio do Sul' },
    { codigoTOM: 8293, codigoIBGE: 4214904, nomeTOM: 'RIO FORTUNA', nomeIBGE: 'Rio Fortuna' },
    { codigoTOM: 8295, codigoIBGE: 4215000, nomeTOM: 'RIO NEGRINHO', nomeIBGE: 'Rio Negrinho' },
    { codigoTOM: 8297, codigoIBGE: 4215109, nomeTOM: 'RODEIO', nomeIBGE: 'Rodeio' },
    { codigoTOM: 8299, codigoIBGE: 4215208, nomeTOM: 'ROMELÂNDIA', nomeIBGE: 'Romelândia' },
    { codigoTOM: 8301, codigoIBGE: 4215307, nomeTOM: 'SALETE', nomeIBGE: 'Salete' },
    { codigoTOM: 8303, codigoIBGE: 4215406, nomeTOM: 'SALTO VELOSO', nomeIBGE: 'Salto Veloso' },
    { codigoTOM: 8305, codigoIBGE: 4215505, nomeTOM: 'SANTA CECÍLIA', nomeIBGE: 'Santa Cecília' },
    { codigoTOM: 8307, codigoIBGE: 4215604, nomeTOM: 'SANTA ROSA DE LIMA', nomeIBGE: 'Santa Rosa de Lima' },
    {
      codigoTOM: 8309,
      codigoIBGE: 4215703,
      nomeTOM: 'SANTO AMARO DA IMPERATRIZ',
      nomeIBGE: 'Santo Amaro da Imperatriz',
    },
    { codigoTOM: 8311, codigoIBGE: 4215802, nomeTOM: 'SÃO BENTO DO SUL', nomeIBGE: 'São Bento do Sul' },
    { codigoTOM: 8313, codigoIBGE: 4215901, nomeTOM: 'SÃO BONIFÁCIO', nomeIBGE: 'São Bonifácio' },
    { codigoTOM: 8315, codigoIBGE: 4216008, nomeTOM: 'SÃO CARLOS', nomeIBGE: 'São Carlos' },
    { codigoTOM: 8317, codigoIBGE: 4216107, nomeTOM: 'SÃO DOMINGOS', nomeIBGE: 'São Domingos' },
    { codigoTOM: 8319, codigoIBGE: 4216206, nomeTOM: 'SÃO FRANCISCO DO SUL', nomeIBGE: 'São Francisco do Sul' },
    { codigoTOM: 8321, codigoIBGE: 4216305, nomeTOM: 'SÃO JOÃO BATISTA', nomeIBGE: 'São João Batista' },
    { codigoTOM: 8323, codigoIBGE: 4216404, nomeTOM: 'SÃO JOÃO DO SUL', nomeIBGE: 'São João do Sul' },
    { codigoTOM: 8325, codigoIBGE: 4216503, nomeTOM: 'SÃO JOAQUIM', nomeIBGE: 'São Joaquim' },
    { codigoTOM: 8327, codigoIBGE: 4216602, nomeTOM: 'SÃO JOSÉ', nomeIBGE: 'São José' },
    { codigoTOM: 8329, codigoIBGE: 4216701, nomeTOM: 'SÃO JOSÉ DO CEDRO', nomeIBGE: 'São José do Cedro' },
    { codigoTOM: 8331, codigoIBGE: 4216800, nomeTOM: 'SÃO JOSÉ DO CERRITO', nomeIBGE: 'São José do Cerrito' },
    { codigoTOM: 8333, codigoIBGE: 4216909, nomeTOM: 'São Lourenço do Oeste', nomeIBGE: 'São Lourenço do Oeste' },
    { codigoTOM: 8335, codigoIBGE: 4217006, nomeTOM: 'SÃO LUDGERO', nomeIBGE: 'São Ludgero' },
    { codigoTOM: 8337, codigoIBGE: 4217105, nomeTOM: 'SÃO MARTINHO', nomeIBGE: 'São Martinho' },
    { codigoTOM: 8339, codigoIBGE: 4217204, nomeTOM: 'SÃO MIGUEL DO OESTE', nomeIBGE: 'São Miguel do Oeste' },
    { codigoTOM: 8341, codigoIBGE: 4217303, nomeTOM: 'SAUDADES', nomeIBGE: 'Saudades' },
    { codigoTOM: 8343, codigoIBGE: 4217402, nomeTOM: 'SCHROEDER', nomeIBGE: 'Schroeder' },
    { codigoTOM: 8345, codigoIBGE: 4217501, nomeTOM: 'SEARA', nomeIBGE: 'Seara' },
    { codigoTOM: 8347, codigoIBGE: 4217600, nomeTOM: 'SIDERÓPOLIS', nomeIBGE: 'Siderópolis' },
    { codigoTOM: 8349, codigoIBGE: 4217709, nomeTOM: 'SOMBRIO', nomeIBGE: 'Sombrio' },
    { codigoTOM: 8351, codigoIBGE: 4217808, nomeTOM: 'TAIÓ', nomeIBGE: 'Taió' },
    { codigoTOM: 8353, codigoIBGE: 4217907, nomeTOM: 'TANGARÁ', nomeIBGE: 'Tangará' },
    { codigoTOM: 8355, codigoIBGE: 4218004, nomeTOM: 'TIJUCAS', nomeIBGE: 'Tijucas' },
    { codigoTOM: 8357, codigoIBGE: 4218202, nomeTOM: 'TIMBÓ', nomeIBGE: 'Timbó' },
    { codigoTOM: 8359, codigoIBGE: 4218301, nomeTOM: 'TRÊS BARRAS', nomeIBGE: 'Três Barras' },
    { codigoTOM: 8361, codigoIBGE: 4218400, nomeTOM: 'TREZE DE MAIO', nomeIBGE: 'Treze de Maio' },
    { codigoTOM: 8363, codigoIBGE: 4218509, nomeTOM: 'TREZE TÍLIAS', nomeIBGE: 'Treze Tílias' },
    { codigoTOM: 8365, codigoIBGE: 4218608, nomeTOM: 'TROMBUDO CENTRAL', nomeIBGE: 'Trombudo Central' },
    { codigoTOM: 8367, codigoIBGE: 4218707, nomeTOM: 'TUBARÃO', nomeIBGE: 'Tubarão' },
    { codigoTOM: 8369, codigoIBGE: 4218806, nomeTOM: 'TURVO', nomeIBGE: 'Turvo' },
    { codigoTOM: 8371, codigoIBGE: 4218905, nomeTOM: 'URUBICI', nomeIBGE: 'Urubici' },
    { codigoTOM: 8373, codigoIBGE: 4219002, nomeTOM: 'URUSSANGA', nomeIBGE: 'Urussanga' },
    { codigoTOM: 8375, codigoIBGE: 4219101, nomeTOM: 'VARGEÃO', nomeIBGE: 'Vargeão' },
    { codigoTOM: 8377, codigoIBGE: 4219200, nomeTOM: 'VIDAL RAMOS', nomeIBGE: 'Vidal Ramos' },
    { codigoTOM: 8379, codigoIBGE: 4219309, nomeTOM: 'VIDEIRA', nomeIBGE: 'Videira' },
    { codigoTOM: 8381, codigoIBGE: 4219408, nomeTOM: 'WITMARSUM', nomeIBGE: 'Witmarsum' },
    { codigoTOM: 8383, codigoIBGE: 4219507, nomeTOM: 'XANXERÊ', nomeIBGE: 'Xanxerê' },
    { codigoTOM: 8385, codigoIBGE: 4219606, nomeTOM: 'XAVANTINA', nomeIBGE: 'Xavantina' },
    { codigoTOM: 8387, codigoIBGE: 4219705, nomeTOM: 'XAXIM', nomeIBGE: 'Xaxim' },
    { codigoTOM: 8389, codigoIBGE: 4202503, nomeTOM: 'BOM JARDIM DA SERRA', nomeIBGE: 'Bom Jardim da Serra' },
    { codigoTOM: 8391, codigoIBGE: 4210407, nomeTOM: 'MARACAJÁ', nomeIBGE: 'Maracajá' },
    { codigoTOM: 8393, codigoIBGE: 4218103, nomeTOM: 'TIMBÉ DO SUL', nomeIBGE: 'Timbé do Sul' },
    { codigoTOM: 8395, codigoIBGE: 4204558, nomeTOM: 'CORREIA PINTO', nomeIBGE: 'Correia Pinto' },
    { codigoTOM: 8397, codigoIBGE: 4211751, nomeTOM: 'OTACÍLIO COSTA', nomeIBGE: 'Otacílio Costa' },
    { codigoTOM: 1192, codigoIBGE: 4220000, nomeTOM: 'BALNEÁRIO RINCÃO', nomeIBGE: 'Balneário Rincão' },
    { codigoTOM: 1194, codigoIBGE: 4212650, nomeTOM: 'PESCARIA BRAVA', nomeIBGE: 'Pescaria Brava' },
    { codigoTOM: 9939, codigoIBGE: 4200051, nomeTOM: 'ABDON BATISTA', nomeIBGE: 'Abdon Batista' },
    { codigoTOM: 9941, codigoIBGE: 4201257, nomeTOM: 'APIÚNA', nomeIBGE: 'Apiúna' },
    { codigoTOM: 9943, codigoIBGE: 4204152, nomeTOM: 'CELSO RAMOS', nomeIBGE: 'Celso Ramos' },
    { codigoTOM: 9945, codigoIBGE: 4205159, nomeTOM: 'DOUTOR PEDRINHO', nomeIBGE: 'Doutor Pedrinho' },
    { codigoTOM: 9951, codigoIBGE: 4207650, nomeTOM: 'IPORÃ DO OESTE', nomeIBGE: 'Iporã do Oeste' },
    { codigoTOM: 9953, codigoIBGE: 4207759, nomeTOM: 'IRACEMINHA', nomeIBGE: 'Iraceminha' },
    { codigoTOM: 9957, codigoIBGE: 4209151, nomeTOM: 'JOSÉ BOITEUX', nomeIBGE: 'José Boiteux' },
    { codigoTOM: 9961, codigoIBGE: 4209854, nomeTOM: 'LINDÓIA DO SUL', nomeIBGE: 'Lindóia do Sul' },
    { codigoTOM: 9963, codigoIBGE: 4210555, nomeTOM: 'MAREMA', nomeIBGE: 'Marema' },
    { codigoTOM: 9967, codigoIBGE: 4215653, nomeTOM: 'SANTA ROSA DO SUL', nomeIBGE: 'Santa Rosa do Sul' },
    { codigoTOM: 9971, codigoIBGE: 4218251, nomeTOM: 'TIMBÓ GRANDE', nomeIBGE: 'Timbó Grande' },
    { codigoTOM: 9973, codigoIBGE: 4218855, nomeTOM: 'UNIÃO DO OESTE', nomeIBGE: 'União do Oeste' },
    { codigoTOM: 9975, codigoIBGE: 4218954, nomeTOM: 'URUPEMA', nomeIBGE: 'Urupema' },
    { codigoTOM: 9977, codigoIBGE: 4219358, nomeTOM: 'VITOR MEIRELES', nomeIBGE: 'Vitor Meireles' },
    { codigoTOM: 9985, codigoIBGE: 4208450, nomeTOM: 'ITAPOÁ', nomeIBGE: 'Itapoá' },
    { codigoTOM: 9989, codigoIBGE: 4217550, nomeTOM: 'SERRA ALTA', nomeIBGE: 'Serra Alta' },
    { codigoTOM: 9991, codigoIBGE: 4218756, nomeTOM: 'TUNÁPOLIS', nomeIBGE: 'Tunápolis' },
  ],
  [Estados.RS]: [
    { codigoTOM: 952, codigoIBGE: 4300877, nomeTOM: 'ARARICÁ', nomeIBGE: 'Araricá' },
    { codigoTOM: 954, codigoIBGE: 4301636, nomeTOM: 'BALNEÁRIO PINHAL', nomeIBGE: 'Balneário Pinhal' },
    { codigoTOM: 956, codigoIBGE: 4301875, nomeTOM: 'BARRA DO QUARAÍ', nomeIBGE: 'Barra do Quaraí' },
    { codigoTOM: 958, codigoIBGE: 4302055, nomeTOM: 'BENJAMIN CONSTANT DO SUL', nomeIBGE: 'Benjamin Constant do Sul' },
    { codigoTOM: 960, codigoIBGE: 4302253, nomeTOM: 'BOA VISTA DO SUL', nomeIBGE: 'Boa Vista do Sul' },
    { codigoTOM: 962, codigoIBGE: 4304671, nomeTOM: 'CAPIVARI DO SUL', nomeIBGE: 'Capivari do Sul' },
    { codigoTOM: 964, codigoIBGE: 4304713, nomeTOM: 'CARAÁ', nomeIBGE: 'Caraá' },
    { codigoTOM: 966, codigoIBGE: 4305124, nomeTOM: 'CERRITO', nomeIBGE: 'Cerrito' },
    { codigoTOM: 968, codigoIBGE: 4305439, nomeTOM: 'CHUÍ', nomeIBGE: 'Chuí' },
    { codigoTOM: 970, codigoIBGE: 4305447, nomeTOM: 'CHUVISCA', nomeIBGE: 'Chuvisca' },
    { codigoTOM: 972, codigoIBGE: 4306072, nomeTOM: 'CRISTAL DO SUL', nomeIBGE: 'Cristal do Sul' },
    { codigoTOM: 974, codigoIBGE: 4306379, nomeTOM: 'DILERMANDO DE AGUIAR', nomeIBGE: 'Dilermando de Aguiar' },
    { codigoTOM: 976, codigoIBGE: 4306551, nomeTOM: 'DOM PEDRO DE ALCÂNTARA', nomeIBGE: 'Dom Pedro de Alcântara' },
    { codigoTOM: 978, codigoIBGE: 4306759, nomeTOM: 'DOUTOR RICARDO', nomeIBGE: 'Doutor Ricardo' },
    { codigoTOM: 980, codigoIBGE: 4307450, nomeTOM: 'ESPERANÇA DO SUL', nomeIBGE: 'Esperança do Sul' },
    { codigoTOM: 982, codigoIBGE: 4307815, nomeTOM: 'ESTRELA VELHA', nomeIBGE: 'Estrela Velha' },
    { codigoTOM: 984, codigoIBGE: 4308078, nomeTOM: 'FAZENDA VILANOVA', nomeIBGE: 'Fazenda Vilanova' },
    { codigoTOM: 986, codigoIBGE: 4308250, nomeTOM: 'FLORIANO PEIXOTO', nomeIBGE: 'Floriano Peixoto' },
    { codigoTOM: 988, codigoIBGE: 4309571, nomeTOM: 'HERVEIRAS', nomeIBGE: 'Herveiras' },
    { codigoTOM: 990, codigoIBGE: 4310538, nomeTOM: 'ITAARA', nomeIBGE: 'Itaara' },
    { codigoTOM: 992, codigoIBGE: 4311130, nomeTOM: 'JARI', nomeIBGE: 'Jari' },
    { codigoTOM: 994, codigoIBGE: 4311718, nomeTOM: 'MAÇAMBARA', nomeIBGE: 'Maçambará' },
    { codigoTOM: 996, codigoIBGE: 4311734, nomeTOM: 'MAMPITUBA', nomeIBGE: 'Mampituba' },
    { codigoTOM: 998, codigoIBGE: 4312054, nomeTOM: 'MARQUES DE SOUZA', nomeIBGE: 'Marques de Souza' },
    { codigoTOM: 1000, codigoIBGE: 4312377, nomeTOM: 'MONTE ALEGRE DOS CAMPOS', nomeIBGE: 'Monte Alegre dos Campos' },
    { codigoTOM: 1002, codigoIBGE: 4312617, nomeTOM: 'MUITOS CAPÕES', nomeIBGE: 'Muitos Capões' },
    { codigoTOM: 1004, codigoIBGE: 4313011, nomeTOM: 'NOVA CANDELÁRIA', nomeIBGE: 'Nova Candelária' },
    { codigoTOM: 1006, codigoIBGE: 4313334, nomeTOM: 'NOVA RAMADA', nomeIBGE: 'Nova Ramada' },
    { codigoTOM: 1008, codigoIBGE: 4313391, nomeTOM: 'NOVO CABRAIS', nomeIBGE: 'Novo Cabrais' },
    { codigoTOM: 1010, codigoIBGE: 4314068, nomeTOM: 'PASSA SETE', nomeIBGE: 'Passa Sete' },
    { codigoTOM: 1012, codigoIBGE: 4320321, nomeTOM: 'SENADOR SALGADO FILHO', nomeIBGE: 'Senador Salgado Filho' },
    { codigoTOM: 1014, codigoIBGE: 4320578, nomeTOM: 'SETE DE SETEMBRO', nomeIBGE: 'Sete de Setembro' },
    { codigoTOM: 1016, codigoIBGE: 4320859, nomeTOM: 'TABAÍ', nomeIBGE: 'Tabaí' },
    { codigoTOM: 1018, codigoIBGE: 4321493, nomeTOM: 'TOROPI', nomeIBGE: 'Toropi' },
    { codigoTOM: 1020, codigoIBGE: 4322327, nomeTOM: 'TURUÇU', nomeIBGE: 'Turuçu' },
    { codigoTOM: 1022, codigoIBGE: 4322343, nomeTOM: 'UBIRETAMA', nomeIBGE: 'Ubiretama' },
    { codigoTOM: 1024, codigoIBGE: 4322376, nomeTOM: 'UNISTALDA', nomeIBGE: 'Unistalda' },
    { codigoTOM: 1026, codigoIBGE: 4322525, nomeTOM: 'VALE VERDE', nomeIBGE: 'Vale Verde' },
    { codigoTOM: 1028, codigoIBGE: 4322855, nomeTOM: 'VESPASIANO CORREA', nomeIBGE: 'Vespasiano Corrêa' },
    { codigoTOM: 1030, codigoIBGE: 4323358, nomeTOM: 'VILA LÂNGARO', nomeIBGE: 'Vila Lângaro' },
    { codigoTOM: 1118, codigoIBGE: 4300034, nomeTOM: 'ACEGUÁ', nomeIBGE: 'Aceguá' },
    {
      codigoTOM: 1120,
      codigoIBGE: 4300471,
      nomeTOM: 'ALMIRANTE TAMANDARÉ DO SUL',
      nomeIBGE: 'Almirante Tamandaré do Sul',
    },
    { codigoTOM: 1122, codigoIBGE: 4301073, nomeTOM: 'ARROIO DO PADRE', nomeIBGE: 'Arroio do Padre' },
    { codigoTOM: 1124, codigoIBGE: 4302220, nomeTOM: 'BOA VISTA DO CADEADO', nomeIBGE: 'Boa Vista do Cadeado' },
    { codigoTOM: 1126, codigoIBGE: 4302238, nomeTOM: 'BOA VISTA DO INCRA', nomeIBGE: 'Boa Vista do Incra' },
    { codigoTOM: 1128, codigoIBGE: 4302584, nomeTOM: 'BOZANO', nomeIBGE: 'Bozano' },
    { codigoTOM: 1130, codigoIBGE: 4304614, nomeTOM: 'CANUDOS DO VALE', nomeIBGE: 'Canudos do Vale' },
    { codigoTOM: 1132, codigoIBGE: 4304622, nomeTOM: 'CAPÃO BONITO DO SUL', nomeIBGE: 'Capão Bonito do Sul' },
    { codigoTOM: 1134, codigoIBGE: 4304655, nomeTOM: 'CAPÃO DO CIPÓ', nomeIBGE: 'Capão do Cipó' },
    { codigoTOM: 1136, codigoIBGE: 4305835, nomeTOM: 'COQUEIRO BAIXO', nomeIBGE: 'Coqueiro Baixo' },
    { codigoTOM: 1138, codigoIBGE: 4305934, nomeTOM: 'CORONEL PILAR', nomeIBGE: 'Coronel Pilar' },
    { codigoTOM: 1140, codigoIBGE: 4306130, nomeTOM: 'CRUZALTENSE', nomeIBGE: 'Cruzaltense' },
    { codigoTOM: 1142, codigoIBGE: 4308433, nomeTOM: 'FORQUETINHA', nomeIBGE: 'Forquetinha' },
    { codigoTOM: 1144, codigoIBGE: 4310652, nomeTOM: 'ITATI', nomeIBGE: 'Itati' },
    { codigoTOM: 1146, codigoIBGE: 4310876, nomeTOM: 'JACUIZINHO', nomeIBGE: 'Jacuizinho' },
    { codigoTOM: 1148, codigoIBGE: 4311239, nomeTOM: 'LAGOA BONITA DO SUL', nomeIBGE: 'Lagoa Bonita do Sul' },
    { codigoTOM: 1150, codigoIBGE: 4312179, nomeTOM: 'MATO QUEIMADO', nomeIBGE: 'Mato Queimado' },
    { codigoTOM: 1152, codigoIBGE: 4313466, nomeTOM: 'NOVO XINGU', nomeIBGE: 'Novo Xingu' },
    { codigoTOM: 1154, codigoIBGE: 4314134, nomeTOM: 'PAULO BENTO', nomeIBGE: 'Paulo Bento' },
    { codigoTOM: 1156, codigoIBGE: 4314175, nomeTOM: 'PEDRAS ALTAS', nomeIBGE: 'Pedras Altas' },
    { codigoTOM: 1158, codigoIBGE: 4314464, nomeTOM: 'PINHAL DA SERRA', nomeIBGE: 'Pinhal da Serra' },
    { codigoTOM: 1160, codigoIBGE: 4314548, nomeTOM: 'PINTO BANDEIRA', nomeIBGE: 'Pinto Bandeira' },
    { codigoTOM: 1162, codigoIBGE: 4315313, nomeTOM: 'QUATRO IRMÃOS', nomeIBGE: 'Quatro Irmãos' },
    { codigoTOM: 1164, codigoIBGE: 4315958, nomeTOM: 'ROLADOR', nomeIBGE: 'Rolador' },
    { codigoTOM: 1166, codigoIBGE: 4316733, nomeTOM: 'SANTA CECÍLIA DO SUL', nomeIBGE: 'Santa Cecília do Sul' },
    { codigoTOM: 1168, codigoIBGE: 4316972, nomeTOM: 'SANTA MARGARIDA DO SUL', nomeIBGE: 'Santa Margarida do Sul' },
    { codigoTOM: 1170, codigoIBGE: 4318614, nomeTOM: 'SÃO JOSÉ DO SUL', nomeIBGE: 'São José do Sul' },
    { codigoTOM: 1172, codigoIBGE: 4319364, nomeTOM: 'SÃO PEDRO DAS MISSÕES', nomeIBGE: 'São Pedro das Missões' },
    { codigoTOM: 1174, codigoIBGE: 4321469, nomeTOM: 'TIO HUGO', nomeIBGE: 'Tio Hugo' },
    { codigoTOM: 1176, codigoIBGE: 4323770, nomeTOM: 'WESTFALIA', nomeIBGE: 'Westfália' },
    { codigoTOM: 5757, codigoIBGE: 4313375, nomeTOM: 'NOVA SANTA RITA', nomeIBGE: 'Nova Santa Rita' },
    { codigoTOM: 5759, codigoIBGE: 4311981, nomeTOM: 'MARIANA PIMENTEL', nomeIBGE: 'Mariana Pimentel' },
    { codigoTOM: 5761, codigoIBGE: 4320552, nomeTOM: 'SERTÃO SANTANA', nomeIBGE: 'Sertão Santana' },
    { codigoTOM: 5763, codigoIBGE: 4309159, nomeTOM: 'GRAMADO XAVIER', nomeIBGE: 'Gramado Xavier' },
    { codigoTOM: 5765, codigoIBGE: 4314076, nomeTOM: 'PASSO DO SOBRADO', nomeIBGE: 'Passo do Sobrado' },
    { codigoTOM: 5767, codigoIBGE: 4320677, nomeTOM: 'SINIMBU', nomeIBGE: 'Sinimbu' },
    { codigoTOM: 5769, codigoIBGE: 4322533, nomeTOM: 'VALE DO SOL', nomeIBGE: 'Vale do Sol' },
    { codigoTOM: 5771, codigoIBGE: 4301750, nomeTOM: 'BARÃO DO TRIUNFO', nomeIBGE: 'Barão do Triunfo' },
    { codigoTOM: 5773, codigoIBGE: 4312252, nomeTOM: 'MINAS DO LEÃO', nomeIBGE: 'Minas do Leão' },
    { codigoTOM: 5775, codigoIBGE: 4312443, nomeTOM: 'MORRINHOS DO SUL', nomeIBGE: 'Morrinhos do Sul' },
    { codigoTOM: 5777, codigoIBGE: 4321832, nomeTOM: 'TRÊS FORQUILHAS', nomeIBGE: 'Três Forquilhas' },
    { codigoTOM: 5779, codigoIBGE: 4300851, nomeTOM: 'ARAMBARÉ', nomeIBGE: 'Arambaré' },
    { codigoTOM: 5781, codigoIBGE: 4320354, nomeTOM: 'SENTINELA DO SUL', nomeIBGE: 'Sentinela do Sul' },
    { codigoTOM: 5783, codigoIBGE: 4311775, nomeTOM: 'MAQUINÉ', nomeIBGE: 'Maquiné' },
    { codigoTOM: 5785, codigoIBGE: 4323804, nomeTOM: 'XANGRI-LÁ', nomeIBGE: 'Xangri-lá' },
    { codigoTOM: 5787, codigoIBGE: 4314472, nomeTOM: 'PINHAL GRANDE', nomeIBGE: 'Pinhal Grande' },
    { codigoTOM: 5789, codigoIBGE: 4315321, nomeTOM: 'QUEVEDOS', nomeIBGE: 'Quevedos' },
    { codigoTOM: 5791, codigoIBGE: 4318432, nomeTOM: 'SÃO JOÃO DO POLÊSINE', nomeIBGE: 'São João do Polêsine' },
    { codigoTOM: 5793, codigoIBGE: 4319125, nomeTOM: 'SÃO MARTINHO DA SERRA', nomeIBGE: 'São Martinho da Serra' },
    { codigoTOM: 5795, codigoIBGE: 4323457, nomeTOM: 'VILA NOVA DO SUL', nomeIBGE: 'Vila Nova do Sul' },
    { codigoTOM: 5797, codigoIBGE: 4305975, nomeTOM: 'COXILHA', nomeIBGE: 'Coxilha' },
    { codigoTOM: 5799, codigoIBGE: 4308854, nomeTOM: 'GENTIL', nomeIBGE: 'Gentil' },
    { codigoTOM: 5931, codigoIBGE: 4312138, nomeTOM: 'MATO CASTELHANO', nomeIBGE: 'Mato Castelhano' },
    { codigoTOM: 5933, codigoIBGE: 4312427, nomeTOM: 'MORMAÇO', nomeIBGE: 'Mormaço' },
    { codigoTOM: 5935, codigoIBGE: 4312625, nomeTOM: 'MULITERNO', nomeIBGE: 'Muliterno' },
    { codigoTOM: 5937, codigoIBGE: 4312674, nomeTOM: 'NICOLAU VERGUEIRO', nomeIBGE: 'Nicolau Vergueiro' },
    { codigoTOM: 5939, codigoIBGE: 4314779, nomeTOM: 'PONTÃO', nomeIBGE: 'Pontão' },
    { codigoTOM: 5941, codigoIBGE: 4317558, nomeTOM: 'SANTO ANTÔNIO DO PALMA', nomeIBGE: 'Santo Antônio do Palma' },
    { codigoTOM: 5943, codigoIBGE: 4301958, nomeTOM: 'BARRA FUNDA', nomeIBGE: 'Barra Funda' },
    { codigoTOM: 5945, codigoIBGE: 4305850, nomeTOM: 'COQUEIROS DO SUL', nomeIBGE: 'Coqueiros do Sul' },
    { codigoTOM: 5947, codigoIBGE: 4306924, nomeTOM: 'ENGENHO VELHO', nomeIBGE: 'Engenho Velho' },
    { codigoTOM: 5949, codigoIBGE: 4309126, nomeTOM: 'GRAMADO DOS LOUREIROS', nomeIBGE: 'Gramado dos Loureiros' },
    { codigoTOM: 5951, codigoIBGE: 4311270, nomeTOM: 'LAGOA DOS TRÊS CANTOS', nomeIBGE: 'Lagoa dos Três Cantos' },
    { codigoTOM: 5953, codigoIBGE: 4312955, nomeTOM: 'NOVA BOA VISTA', nomeIBGE: 'Nova Boa Vista' },
    { codigoTOM: 5955, codigoIBGE: 4315552, nomeTOM: 'RIO DOS ÍNDIOS', nomeIBGE: 'Rio dos Índios' },
    {
      codigoTOM: 5957,
      codigoIBGE: 4317756,
      nomeTOM: 'SANTO ANTÔNIO DO PLANALTO',
      nomeIBGE: 'Santo Antônio do Planalto',
    },
    { codigoTOM: 5959, codigoIBGE: 4301925, nomeTOM: 'BARRA DO RIO AZUL', nomeIBGE: 'Barra do Rio Azul' },
    { codigoTOM: 5961, codigoIBGE: 4304853, nomeTOM: 'CARLOS GOMES', nomeIBGE: 'Carlos Gomes' },
    { codigoTOM: 5963, codigoIBGE: 4305116, nomeTOM: 'CENTENÁRIO', nomeIBGE: 'Centenário' },
    { codigoTOM: 5965, codigoIBGE: 4305371, nomeTOM: 'CHARRUA', nomeIBGE: 'Charrua' },
    { codigoTOM: 5967, codigoIBGE: 4314787, nomeTOM: 'PONTE PRETA', nomeIBGE: 'Ponte Preta' },
    { codigoTOM: 5969, codigoIBGE: 4300646, nomeTOM: 'AMETISTA DO SUL', nomeIBGE: 'Ametista do Sul' },
    { codigoTOM: 5971, codigoIBGE: 4306429, nomeTOM: 'DOIS IRMÃOS DAS MISSÕES', nomeIBGE: 'Dois Irmãos das Missões' },
    { codigoTOM: 5973, codigoIBGE: 4313441, nomeTOM: 'NOVO TIRADENTES', nomeIBGE: 'Novo Tiradentes' },
    { codigoTOM: 5975, codigoIBGE: 4314498, nomeTOM: 'PINHEIRINHO DO VALE', nomeIBGE: 'Pinheirinho do Vale' },
    { codigoTOM: 5977, codigoIBGE: 4317954, nomeTOM: 'SANTO EXPEDITO DO SUL', nomeIBGE: 'Santo Expedito do Sul' },
    { codigoTOM: 5979, codigoIBGE: 4322186, nomeTOM: 'TUPANCI DO SUL', nomeIBGE: 'Tupanci do Sul' },
    { codigoTOM: 5981, codigoIBGE: 4302154, nomeTOM: 'BOA VISTA DAS MISSÕES', nomeIBGE: 'Boa Vista das Missões' },
    { codigoTOM: 5983, codigoIBGE: 4311429, nomeTOM: 'LAJEADO DO BUGRE', nomeIBGE: 'Lajeado do Bugre' },
    { codigoTOM: 5985, codigoIBGE: 4313490, nomeTOM: 'NOVO BARREIRO', nomeIBGE: 'Novo Barreiro' },
    { codigoTOM: 5987, codigoIBGE: 4316428, nomeTOM: 'SAGRADA FAMÍLIA', nomeIBGE: 'Sagrada Família' },
    { codigoTOM: 5989, codigoIBGE: 4318457, nomeTOM: 'SÃO JOSÉ DAS MISSÕES', nomeIBGE: 'São José das Missões' },
    { codigoTOM: 5991, codigoIBGE: 4313086, nomeTOM: 'NOVA PÁDUA', nomeIBGE: 'Nova Pádua' },
    { codigoTOM: 5993, codigoIBGE: 4312385, nomeTOM: 'MONTE BELO DO SUL', nomeIBGE: 'Monte Belo do Sul' },
    { codigoTOM: 5995, codigoIBGE: 4317251, nomeTOM: 'SANTA TEREZA', nomeIBGE: 'Santa Tereza' },
    { codigoTOM: 5997, codigoIBGE: 4319711, nomeTOM: 'SÃO VALENTIM DO SUL', nomeIBGE: 'São Valentim do Sul' },
    { codigoTOM: 5999, codigoIBGE: 4322350, nomeTOM: 'UNIÃO DA SERRA', nomeIBGE: 'União da Serra' },
    { codigoTOM: 6013, codigoIBGE: 4303673, nomeTOM: 'CAMPESTRE DA SERRA', nomeIBGE: 'Campestre da Serra' },
    { codigoTOM: 6015, codigoIBGE: 4318622, nomeTOM: 'SÃO JOSÉ DOS AUSENTES', nomeIBGE: 'São José dos Ausentes' },
    { codigoTOM: 6017, codigoIBGE: 4311627, nomeTOM: 'LINDOLFO COLLOR', nomeIBGE: 'Lindolfo Collor' },
    { codigoTOM: 6019, codigoIBGE: 4312476, nomeTOM: 'MORRO REUTER', nomeIBGE: 'Morro Reuter' },
    { codigoTOM: 6021, codigoIBGE: 4314423, nomeTOM: 'PICADA CAFÉ', nomeIBGE: 'Picada Café' },
    { codigoTOM: 6023, codigoIBGE: 4315149, nomeTOM: 'PRESIDENTE LUCENA', nomeIBGE: 'Presidente Lucena' },
    { codigoTOM: 6025, codigoIBGE: 4304697, nomeTOM: 'CAPITÃO', nomeIBGE: 'Capitão' },
    { codigoTOM: 6027, codigoIBGE: 4310579, nomeTOM: 'ITAPUCA', nomeIBGE: 'Itapuca' },
    { codigoTOM: 6029, codigoIBGE: 4305587, nomeTOM: 'COLINAS', nomeIBGE: 'Colinas' },
    { codigoTOM: 6031, codigoIBGE: 4312153, nomeTOM: 'MATO LEITÃO', nomeIBGE: 'Mato Leitão' },
    { codigoTOM: 6033, codigoIBGE: 4316758, nomeTOM: 'SANTA CLARA DO SUL', nomeIBGE: 'Santa Clara do Sul' },
    { codigoTOM: 6035, codigoIBGE: 4320453, nomeTOM: 'SÉRIO', nomeIBGE: 'Sério' },
    { codigoTOM: 6037, codigoIBGE: 4321626, nomeTOM: 'TRAVESSEIRO', nomeIBGE: 'Travesseiro' },
    { codigoTOM: 6039, codigoIBGE: 4311791, nomeTOM: 'MARATÁ', nomeIBGE: 'Maratá' },
    { codigoTOM: 6041, codigoIBGE: 4314035, nomeTOM: 'PARECI NOVO', nomeIBGE: 'Pareci Novo' },
    { codigoTOM: 6043, codigoIBGE: 4319356, nomeTOM: 'SÃO PEDRO DA SERRA', nomeIBGE: 'São Pedro da Serra' },
    { codigoTOM: 6045, codigoIBGE: 4300570, nomeTOM: 'ALTO FELIZ', nomeIBGE: 'Alto Feliz' },
    { codigoTOM: 6047, codigoIBGE: 4311643, nomeTOM: 'LINHA NOVA', nomeIBGE: 'Linha Nova' },
    { codigoTOM: 6049, codigoIBGE: 4322541, nomeTOM: 'VALE REAL', nomeIBGE: 'Vale Real' },
    { codigoTOM: 6051, codigoIBGE: 4310413, nomeTOM: 'INHACORÁ', nomeIBGE: 'Inhacorá' },
    { codigoTOM: 6053, codigoIBGE: 4323754, nomeTOM: 'VITÓRIA DAS MISSÕES', nomeIBGE: 'Vitória das Missões' },
    { codigoTOM: 6055, codigoIBGE: 4305871, nomeTOM: 'CORONEL BARROS', nomeIBGE: 'Coronel Barros' },
    { codigoTOM: 6057, codigoIBGE: 4313425, nomeTOM: 'NOVO MACHADO', nomeIBGE: 'Novo Machado' },
    { codigoTOM: 6059, codigoIBGE: 4318499, nomeTOM: 'SÃO JOSÉ DO INHACORÁ', nomeIBGE: 'São José do Inhacorá' },
    { codigoTOM: 6061, codigoIBGE: 4316477, nomeTOM: 'SALVADOR DAS MISSÕES', nomeIBGE: 'Salvador das Missões' },
    { codigoTOM: 6063, codigoIBGE: 4319372, nomeTOM: 'SÃO PEDRO DO BUTIÁ', nomeIBGE: 'São Pedro do Butiá' },
    { codigoTOM: 6065, codigoIBGE: 4315057, nomeTOM: 'PORTO MAUÁ', nomeIBGE: 'Porto Mauá' },
    { codigoTOM: 6067, codigoIBGE: 4315073, nomeTOM: 'PORTO VERA CRUZ', nomeIBGE: 'Porto Vera Cruz' },
    { codigoTOM: 6069, codigoIBGE: 4301859, nomeTOM: 'BARRA DO GUARITA', nomeIBGE: 'Barra do Guarita' },
    { codigoTOM: 6071, codigoIBGE: 4302378, nomeTOM: 'BOM PROGRESSO', nomeIBGE: 'Bom Progresso' },
    { codigoTOM: 6073, codigoIBGE: 4306320, nomeTOM: 'DERRUBADAS', nomeIBGE: 'Derrubadas' },
    { codigoTOM: 6075, codigoIBGE: 4319737, nomeTOM: 'SÃO VALÉRIO DO SUL', nomeIBGE: 'São Valério do Sul' },
    { codigoTOM: 6077, codigoIBGE: 4321477, nomeTOM: 'TIRADENTES DO SUL', nomeIBGE: 'Tiradentes do Sul' },
    { codigoTOM: 6079, codigoIBGE: 4311759, nomeTOM: 'MANOEL VIANA', nomeIBGE: 'Manoel Viana' },
    { codigoTOM: 6081, codigoIBGE: 4308656, nomeTOM: 'GARRUCHOS', nomeIBGE: 'Garruchos' },
    { codigoTOM: 6083, codigoIBGE: 4304358, nomeTOM: 'CANDIOTA', nomeIBGE: 'Candiota' },
    { codigoTOM: 6085, codigoIBGE: 4309654, nomeTOM: 'HULHA NEGRA', nomeIBGE: 'Hulha Negra' },
    { codigoTOM: 7293, codigoIBGE: 4319752, nomeTOM: 'SÃO VENDELINO', nomeIBGE: 'São Vendelino' },
    { codigoTOM: 7295, codigoIBGE: 4310363, nomeTOM: 'IMIGRANTE', nomeIBGE: 'Imigrante' },
    { codigoTOM: 7297, codigoIBGE: 4310330, nomeTOM: 'IMBÉ', nomeIBGE: 'Imbé' },
    { codigoTOM: 7299, codigoIBGE: 4309951, nomeTOM: 'IBIRAPUITÃ', nomeIBGE: 'Ibirapuitã' },
    { codigoTOM: 7301, codigoIBGE: 4307559, nomeTOM: 'ESTAÇÃO', nomeIBGE: 'Estação' },
    { codigoTOM: 7303, codigoIBGE: 4323705, nomeTOM: 'VISTA GAÚCHA', nomeIBGE: 'Vista Gaúcha' },
    { codigoTOM: 7305, codigoIBGE: 4323606, nomeTOM: 'VISTA ALEGRE DO PRATA', nomeIBGE: 'Vista Alegre do Prata' },
    { codigoTOM: 7307, codigoIBGE: 4323507, nomeTOM: 'VISTA ALEGRE', nomeIBGE: 'Vista Alegre' },
    { codigoTOM: 7309, codigoIBGE: 4323408, nomeTOM: 'VILA MARIA', nomeIBGE: 'Vila Maria' },
    { codigoTOM: 7311, codigoIBGE: 4323309, nomeTOM: 'VILA FLORES', nomeIBGE: 'Vila Flores' },
    { codigoTOM: 7313, codigoIBGE: 4321329, nomeTOM: 'TAQUARUÇU DO SUL', nomeIBGE: 'Taquaruçu do Sul' },
    { codigoTOM: 7315, codigoIBGE: 4320651, nomeTOM: 'SILVEIRA MARTINS', nomeIBGE: 'Silveira Martins' },
    { codigoTOM: 7317, codigoIBGE: 4320263, nomeTOM: 'SEGREDO', nomeIBGE: 'Segredo' },
    { codigoTOM: 7319, codigoIBGE: 4322558, nomeTOM: 'VANINI', nomeIBGE: 'Vanini' },
    { codigoTOM: 7321, codigoIBGE: 4322251, nomeTOM: 'TUPANDI', nomeIBGE: 'Tupandi' },
    { codigoTOM: 7323, codigoIBGE: 4322152, nomeTOM: 'TUNAS', nomeIBGE: 'Tunas' },
    { codigoTOM: 7325, codigoIBGE: 4321956, nomeTOM: 'TRINDADE DO SUL', nomeIBGE: 'Trindade do Sul' },
    { codigoTOM: 7327, codigoIBGE: 4321857, nomeTOM: 'TRÊS PALMEIRAS', nomeIBGE: 'Três Palmeiras' },
    { codigoTOM: 7329, codigoIBGE: 4321667, nomeTOM: 'TRÊS CACHOEIRAS', nomeIBGE: 'Três Cachoeiras' },
    { codigoTOM: 7331, codigoIBGE: 4321634, nomeTOM: 'TRÊS ARROIOS', nomeIBGE: 'Três Arroios' },
    { codigoTOM: 7333, codigoIBGE: 4321436, nomeTOM: 'TERRA DE AREIA', nomeIBGE: 'Terra de Areia' },
    { codigoTOM: 7335, codigoIBGE: 4320230, nomeTOM: 'SEDE NOVA', nomeIBGE: 'Sede Nova' },
    { codigoTOM: 7337, codigoIBGE: 4316956, nomeTOM: 'SANTA MARIA DO HERVAL', nomeIBGE: 'Santa Maria do Herval' },
    { codigoTOM: 7339, codigoIBGE: 4316436, nomeTOM: 'SALDANHA MARINHO', nomeIBGE: 'Saldanha Marinho' },
    { codigoTOM: 7341, codigoIBGE: 4319158, nomeTOM: 'SÃO MIGUEL DAS MISSÕES', nomeIBGE: 'São Miguel das Missões' },
    { codigoTOM: 7343, codigoIBGE: 4318481, nomeTOM: 'SÃO JOSÉ DO HORTÊNCIO', nomeIBGE: 'São José do Hortêncio' },
    { codigoTOM: 7345, codigoIBGE: 4318465, nomeTOM: 'SÃO JOSÉ DO HERVAL', nomeIBGE: 'São José do Herval' },
    { codigoTOM: 7347, codigoIBGE: 4318440, nomeTOM: 'SÃO JORGE', nomeIBGE: 'São Jorge' },
    { codigoTOM: 7349, codigoIBGE: 4318424, nomeTOM: 'SÃO JOÃO DA URTIGA', nomeIBGE: 'São João da Urtiga' },
    { codigoTOM: 7351, codigoIBGE: 4318051, nomeTOM: 'SÃO DOMINGOS DO SUL', nomeIBGE: 'São Domingos do Sul' },
    { codigoTOM: 7353, codigoIBGE: 4315750, nomeTOM: 'RIOZINHO', nomeIBGE: 'Riozinho' },
    { codigoTOM: 7355, codigoIBGE: 4315453, nomeTOM: 'RELVADO', nomeIBGE: 'Relvado' },
    { codigoTOM: 7357, codigoIBGE: 4315354, nomeTOM: 'QUINZE DE NOVEMBRO', nomeIBGE: 'Quinze de Novembro' },
    { codigoTOM: 7359, codigoIBGE: 4315172, nomeTOM: 'PROTÁSIO ALVES', nomeIBGE: 'Protásio Alves' },
    { codigoTOM: 7361, codigoIBGE: 4315156, nomeTOM: 'PROGRESSO', nomeIBGE: 'Progresso' },
    { codigoTOM: 7363, codigoIBGE: 4315131, nomeTOM: 'POUSO NOVO', nomeIBGE: 'Pouso Novo' },
    { codigoTOM: 7365, codigoIBGE: 4314753, nomeTOM: 'POÇO DAS ANTAS', nomeIBGE: 'Poço das Antas' },
    { codigoTOM: 7367, codigoIBGE: 4314555, nomeTOM: 'PIRAPÓ', nomeIBGE: 'Pirapó' },
    { codigoTOM: 7369, codigoIBGE: 4314456, nomeTOM: 'PINHAL', nomeIBGE: 'Pinhal' },
    { codigoTOM: 7371, codigoIBGE: 4314159, nomeTOM: 'PAVERAMA', nomeIBGE: 'Paverama' },
    { codigoTOM: 7373, codigoIBGE: 4314027, nomeTOM: 'PARAÍSO DO SUL', nomeIBGE: 'Paraíso do Sul' },
    { codigoTOM: 7375, codigoIBGE: 4313953, nomeTOM: 'PANTANO GRANDE', nomeIBGE: 'Pantano Grande' },
    { codigoTOM: 7377, codigoIBGE: 4313359, nomeTOM: 'NOVA ROMA DO SUL', nomeIBGE: 'Nova Roma do Sul' },
    { codigoTOM: 7379, codigoIBGE: 4313060, nomeTOM: 'NOVA HARTZ', nomeIBGE: 'Nova Hartz' },
    { codigoTOM: 7381, codigoIBGE: 4313037, nomeTOM: 'NOVA ESPERANÇA DO SUL', nomeIBGE: 'Nova Esperança do Sul' },
    { codigoTOM: 7383, codigoIBGE: 4312757, nomeTOM: 'NOVA ALVORADA', nomeIBGE: 'Nova Alvorada' },
    { codigoTOM: 7385, codigoIBGE: 4312450, nomeTOM: 'MORRO REDONDO', nomeIBGE: 'Morro Redondo' },
    { codigoTOM: 7387, codigoIBGE: 4312351, nomeTOM: 'MONTAURI', nomeIBGE: 'Montauri' },
    { codigoTOM: 7389, codigoIBGE: 4311254, nomeTOM: 'LAGOÃO', nomeIBGE: 'Lagoão' },
    { codigoTOM: 7391, codigoIBGE: 4311122, nomeTOM: 'JAQUIRANA', nomeIBGE: 'Jaquirana' },
    { codigoTOM: 7393, codigoIBGE: 4310850, nomeTOM: 'JABOTICABA', nomeIBGE: 'Jaboticaba' },
    { codigoTOM: 7395, codigoIBGE: 4310751, nomeTOM: 'IVORÁ', nomeIBGE: 'Ivorá' },
    { codigoTOM: 7397, codigoIBGE: 4310553, nomeTOM: 'ITACURUBI', nomeIBGE: 'Itacurubi' },
    { codigoTOM: 7399, codigoIBGE: 4310462, nomeTOM: 'IPIRANGA DO SUL', nomeIBGE: 'Ipiranga do Sul' },
    { codigoTOM: 8399, codigoIBGE: 4310439, nomeTOM: 'IPÊ', nomeIBGE: 'Ipê' },
    { codigoTOM: 8401, codigoIBGE: 4309753, nomeTOM: 'IBARAMA', nomeIBGE: 'Ibarama' },
    { codigoTOM: 8403, codigoIBGE: 4309555, nomeTOM: 'HARMONIA', nomeIBGE: 'Harmonia' },
    { codigoTOM: 8405, codigoIBGE: 4309258, nomeTOM: 'GUABIJU', nomeIBGE: 'Guabiju' },
    { codigoTOM: 8407, codigoIBGE: 4309050, nomeTOM: 'GLORINHA', nomeIBGE: 'Glorinha' },
    { codigoTOM: 8409, codigoIBGE: 4308052, nomeTOM: 'FAXINALZINHO', nomeIBGE: 'Faxinalzinho' },
    { codigoTOM: 8411, codigoIBGE: 4307864, nomeTOM: 'FAGUNDES VARELA', nomeIBGE: 'Fagundes Varela' },
    { codigoTOM: 8413, codigoIBGE: 4307831, nomeTOM: 'EUGÊNIO DE CASTRO', nomeIBGE: 'Eugênio de Castro' },
    { codigoTOM: 8415, codigoIBGE: 4307054, nomeTOM: 'ERNESTINA', nomeIBGE: 'Ernestina' },
    { codigoTOM: 8417, codigoIBGE: 4306973, nomeTOM: 'EREBANGO', nomeIBGE: 'Erebango' },
    { codigoTOM: 8419, codigoIBGE: 4306932, nomeTOM: 'ENTRE IJUIS', nomeIBGE: 'Entre-Ijuís' },
    { codigoTOM: 8421, codigoIBGE: 4306957, nomeTOM: 'ENTRE RIOS DO SUL', nomeIBGE: 'Entre Rios do Sul' },
    { codigoTOM: 8423, codigoIBGE: 4306767, nomeTOM: 'ELDORADO DO SUL', nomeIBGE: 'Eldorado do Sul' },
    { codigoTOM: 8425, codigoIBGE: 4306734, nomeTOM: 'DOUTOR MAURÍCIO CARDOSO', nomeIBGE: 'Doutor Maurício Cardoso' },
    { codigoTOM: 8427, codigoIBGE: 4306452, nomeTOM: 'DOIS LAJEADOS', nomeIBGE: 'Dois Lajeados' },
    { codigoTOM: 8429, codigoIBGE: 4306353, nomeTOM: 'DEZESSEIS DE NOVEMBRO', nomeIBGE: 'Dezesseis de Novembro' },
    { codigoTOM: 8431, codigoIBGE: 4306056, nomeTOM: 'CRISTAL', nomeIBGE: 'Cristal' },
    { codigoTOM: 8433, codigoIBGE: 4305454, nomeTOM: 'CIDREIRA', nomeIBGE: 'Cidreira' },
    { codigoTOM: 8435, codigoIBGE: 4305173, nomeTOM: 'CERRO GRANDE DO SUL', nomeIBGE: 'Cerro Grande do Sul' },
    { codigoTOM: 8437, codigoIBGE: 4305157, nomeTOM: 'CERRO GRANDE', nomeIBGE: 'Cerro Grande' },
    { codigoTOM: 8439, codigoIBGE: 4305132, nomeTOM: 'CERRO BRANCO', nomeIBGE: 'Cerro Branco' },
    { codigoTOM: 8441, codigoIBGE: 4304952, nomeTOM: 'CASEIROS', nomeIBGE: 'Caseiros' },
    { codigoTOM: 8443, codigoIBGE: 4304689, nomeTOM: 'CAPELA DE SANTANA', nomeIBGE: 'Capela de Santana' },
    { codigoTOM: 8445, codigoIBGE: 4304101, nomeTOM: 'CAMPOS BORGES', nomeIBGE: 'Campos Borges' },
    { codigoTOM: 8447, codigoIBGE: 4303558, nomeTOM: 'CAMARGO', nomeIBGE: 'Camargo' },
    { codigoTOM: 8449, codigoIBGE: 4302659, nomeTOM: 'BROCHIER', nomeIBGE: 'Brochier' },
    { codigoTOM: 8483, codigoIBGE: 4302451, nomeTOM: 'BOQUEIRÃO DO LEÃO', nomeIBGE: 'Boqueirão do Leão' },
    { codigoTOM: 8485, codigoIBGE: 4301651, nomeTOM: 'BARÃO', nomeIBGE: 'Barão' },
    { codigoTOM: 8487, codigoIBGE: 4301552, nomeTOM: 'ÁUREA', nomeIBGE: 'Áurea' },
    { codigoTOM: 8489, codigoIBGE: 4301057, nomeTOM: 'ARROIO DO SAL', nomeIBGE: 'Arroio do Sal' },
    { codigoTOM: 8491, codigoIBGE: 4300661, nomeTOM: 'ANDRÉ DA ROCHA', nomeIBGE: 'André da Rocha' },
    { codigoTOM: 8493, codigoIBGE: 4300638, nomeTOM: 'AMARAL FERRADOR', nomeIBGE: 'Amaral Ferrador' },
    { codigoTOM: 8495, codigoIBGE: 4300554, nomeTOM: 'ALTO ALEGRE', nomeIBGE: 'Alto Alegre' },
    { codigoTOM: 8497, codigoIBGE: 4300455, nomeTOM: 'ALEGRIA', nomeIBGE: 'Alegria' },
    { codigoTOM: 8499, codigoIBGE: 4300059, nomeTOM: 'ÁGUA SANTA', nomeIBGE: 'Água Santa' },
    { codigoTOM: 8501, codigoIBGE: 4300109, nomeTOM: 'AGUDO', nomeIBGE: 'Agudo' },
    { codigoTOM: 8503, codigoIBGE: 4300208, nomeTOM: 'AJURICABA', nomeIBGE: 'Ajuricaba' },
    { codigoTOM: 8505, codigoIBGE: 4300307, nomeTOM: 'ALECRIM', nomeIBGE: 'Alecrim' },
    { codigoTOM: 8507, codigoIBGE: 4300406, nomeTOM: 'ALEGRETE', nomeIBGE: 'Alegrete' },
    { codigoTOM: 8509, codigoIBGE: 4300505, nomeTOM: 'ALPESTRE', nomeIBGE: 'Alpestre' },
    { codigoTOM: 8511, codigoIBGE: 4300604, nomeTOM: 'ALVORADA', nomeIBGE: 'Alvorada' },
    { codigoTOM: 8513, codigoIBGE: 4300703, nomeTOM: 'ANTA GORDA', nomeIBGE: 'Anta Gorda' },
    { codigoTOM: 8515, codigoIBGE: 4300802, nomeTOM: 'ANTÔNIO PRADO', nomeIBGE: 'Antônio Prado' },
    { codigoTOM: 8517, codigoIBGE: 4300901, nomeTOM: 'ARATIBA', nomeIBGE: 'Aratiba' },
    { codigoTOM: 8519, codigoIBGE: 4301008, nomeTOM: 'ARROIO DO MEIO', nomeIBGE: 'Arroio do Meio' },
    { codigoTOM: 8521, codigoIBGE: 4301107, nomeTOM: 'ARROIO DOS RATOS', nomeIBGE: 'Arroio dos Ratos' },
    { codigoTOM: 8523, codigoIBGE: 4301206, nomeTOM: 'ARROIO DO TIGRE', nomeIBGE: 'Arroio do Tigre' },
    { codigoTOM: 8525, codigoIBGE: 4301305, nomeTOM: 'ARROIO GRANDE', nomeIBGE: 'Arroio Grande' },
    { codigoTOM: 8527, codigoIBGE: 4301404, nomeTOM: 'ARVOREZINHA', nomeIBGE: 'Arvorezinha' },
    { codigoTOM: 8529, codigoIBGE: 4301503, nomeTOM: 'AUGUSTO PESTANA', nomeIBGE: 'Augusto Pestana' },
    { codigoTOM: 8531, codigoIBGE: 4301602, nomeTOM: 'BAGÉ', nomeIBGE: 'Bagé' },
    { codigoTOM: 8533, codigoIBGE: 4301701, nomeTOM: 'BARÃO DE COTEGIPE', nomeIBGE: 'Barão de Cotegipe' },
    { codigoTOM: 8535, codigoIBGE: 4301800, nomeTOM: 'BARRACÃO', nomeIBGE: 'Barracão' },
    { codigoTOM: 8537, codigoIBGE: 4301909, nomeTOM: 'BARRA DO RIBEIRO', nomeIBGE: 'Barra do Ribeiro' },
    { codigoTOM: 8539, codigoIBGE: 4302006, nomeTOM: 'BARROS CASSAL', nomeIBGE: 'Barros Cassal' },
    { codigoTOM: 8541, codigoIBGE: 4302105, nomeTOM: 'BENTO GONÇALVES', nomeIBGE: 'Bento Gonçalves' },
    { codigoTOM: 8543, codigoIBGE: 4302204, nomeTOM: 'BOA VISTA DO BURICÁ', nomeIBGE: 'Boa Vista do Buricá' },
    { codigoTOM: 8545, codigoIBGE: 4302303, nomeTOM: 'BOM JESUS', nomeIBGE: 'Bom Jesus' },
    { codigoTOM: 8547, codigoIBGE: 4302402, nomeTOM: 'BOM RETIRO DO SUL', nomeIBGE: 'Bom Retiro do Sul' },
    { codigoTOM: 8549, codigoIBGE: 4302501, nomeTOM: 'BOSSOROCA', nomeIBGE: 'Bossoroca' },
    { codigoTOM: 8551, codigoIBGE: 4302600, nomeTOM: 'BRAGA', nomeIBGE: 'Braga' },
    { codigoTOM: 8553, codigoIBGE: 4302709, nomeTOM: 'BUTIÁ', nomeIBGE: 'Butiá' },
    { codigoTOM: 8555, codigoIBGE: 4302808, nomeTOM: 'CAÇAPAVA DO SUL', nomeIBGE: 'Caçapava do Sul' },
    { codigoTOM: 8557, codigoIBGE: 4302907, nomeTOM: 'CACEQUI', nomeIBGE: 'Cacequi' },
    { codigoTOM: 8559, codigoIBGE: 4303004, nomeTOM: 'CACHOEIRA DO SUL', nomeIBGE: 'Cachoeira do Sul' },
    { codigoTOM: 8561, codigoIBGE: 4303103, nomeTOM: 'CACHOEIRINHA', nomeIBGE: 'Cachoeirinha' },
    { codigoTOM: 8563, codigoIBGE: 4303202, nomeTOM: 'CACIQUE DOBLE', nomeIBGE: 'Cacique Doble' },
    { codigoTOM: 8565, codigoIBGE: 4303301, nomeTOM: 'CAIBATÉ', nomeIBGE: 'Caibaté' },
    { codigoTOM: 8567, codigoIBGE: 4303400, nomeTOM: 'CAIÇARA', nomeIBGE: 'Caiçara' },
    { codigoTOM: 8569, codigoIBGE: 4303509, nomeTOM: 'CAMAQUÃ', nomeIBGE: 'Camaquã' },
    { codigoTOM: 8571, codigoIBGE: 4303608, nomeTOM: 'CAMBARÁ DO SUL', nomeIBGE: 'Cambará do Sul' },
    { codigoTOM: 8573, codigoIBGE: 4303707, nomeTOM: 'CAMPINA DAS MISSÕES', nomeIBGE: 'Campina das Missões' },
    { codigoTOM: 8575, codigoIBGE: 4303806, nomeTOM: 'CAMPINAS DO SUL', nomeIBGE: 'Campinas do Sul' },
    { codigoTOM: 8577, codigoIBGE: 4303905, nomeTOM: 'CAMPO BOM', nomeIBGE: 'Campo Bom' },
    { codigoTOM: 8579, codigoIBGE: 4304002, nomeTOM: 'CAMPO NOVO', nomeIBGE: 'Campo Novo' },
    { codigoTOM: 8581, codigoIBGE: 4304200, nomeTOM: 'CANDELÁRIA', nomeIBGE: 'Candelária' },
    { codigoTOM: 8583, codigoIBGE: 4304309, nomeTOM: 'CÂNDIDO GODÓI', nomeIBGE: 'Cândido Godói' },
    { codigoTOM: 8585, codigoIBGE: 4304408, nomeTOM: 'CANELA', nomeIBGE: 'Canela' },
    { codigoTOM: 8587, codigoIBGE: 4304507, nomeTOM: 'CANGUÇU', nomeIBGE: 'Canguçu' },
    { codigoTOM: 8589, codigoIBGE: 4304606, nomeTOM: 'CANOAS', nomeIBGE: 'Canoas' },
    { codigoTOM: 8591, codigoIBGE: 4304705, nomeTOM: 'CARAZINHO', nomeIBGE: 'Carazinho' },
    { codigoTOM: 8593, codigoIBGE: 4304804, nomeTOM: 'CARLOS BARBOSA', nomeIBGE: 'Carlos Barbosa' },
    { codigoTOM: 8595, codigoIBGE: 4304903, nomeTOM: 'CASCA', nomeIBGE: 'Casca' },
    { codigoTOM: 8597, codigoIBGE: 4305009, nomeTOM: 'CATUÍPE', nomeIBGE: 'Catuípe' },
    { codigoTOM: 8599, codigoIBGE: 4305108, nomeTOM: 'CAXIAS DO SUL', nomeIBGE: 'Caxias do Sul' },
    { codigoTOM: 8601, codigoIBGE: 4305207, nomeTOM: 'CERRO LARGO', nomeIBGE: 'Cerro Largo' },
    { codigoTOM: 8603, codigoIBGE: 4305306, nomeTOM: 'CHAPADA', nomeIBGE: 'Chapada' },
    { codigoTOM: 8605, codigoIBGE: 4305405, nomeTOM: 'CHIAPETTA', nomeIBGE: 'Chiapetta' },
    { codigoTOM: 8607, codigoIBGE: 4305504, nomeTOM: 'CIRÍACO', nomeIBGE: 'Ciríaco' },
    { codigoTOM: 8609, codigoIBGE: 4305603, nomeTOM: 'COLORADO', nomeIBGE: 'Colorado' },
    { codigoTOM: 8611, codigoIBGE: 4305702, nomeTOM: 'CONDOR', nomeIBGE: 'Condor' },
    { codigoTOM: 8613, codigoIBGE: 4305801, nomeTOM: 'CONSTANTINA', nomeIBGE: 'Constantina' },
    { codigoTOM: 8615, codigoIBGE: 4305900, nomeTOM: 'CORONEL BICACO', nomeIBGE: 'Coronel Bicaco' },
    { codigoTOM: 8617, codigoIBGE: 4306007, nomeTOM: 'CRISSIUMAL', nomeIBGE: 'Crissiumal' },
    { codigoTOM: 8619, codigoIBGE: 4306106, nomeTOM: 'CRUZ ALTA', nomeIBGE: 'Cruz Alta' },
    { codigoTOM: 8621, codigoIBGE: 4306205, nomeTOM: 'CRUZEIRO DO SUL', nomeIBGE: 'Cruzeiro do Sul' },
    { codigoTOM: 8623, codigoIBGE: 4306304, nomeTOM: 'DAVID CANABARRO', nomeIBGE: 'David Canabarro' },
    { codigoTOM: 8625, codigoIBGE: 4306403, nomeTOM: 'DOIS IRMÃOS', nomeIBGE: 'Dois Irmãos' },
    { codigoTOM: 8627, codigoIBGE: 4306502, nomeTOM: 'DOM FELICIANO', nomeIBGE: 'Dom Feliciano' },
    { codigoTOM: 8629, codigoIBGE: 4306601, nomeTOM: 'DOM PEDRITO', nomeIBGE: 'Dom Pedrito' },
    { codigoTOM: 8631, codigoIBGE: 4306700, nomeTOM: 'DONA FRANCISCA', nomeIBGE: 'Dona Francisca' },
    { codigoTOM: 8633, codigoIBGE: 4306809, nomeTOM: 'ENCANTADO', nomeIBGE: 'Encantado' },
    { codigoTOM: 8635, codigoIBGE: 4306908, nomeTOM: 'ENCRUZILHADA DO SUL', nomeIBGE: 'Encruzilhada do Sul' },
    { codigoTOM: 8637, codigoIBGE: 4307005, nomeTOM: 'ERECHIM', nomeIBGE: 'Erechim' },
    { codigoTOM: 8639, codigoIBGE: 4307104, nomeTOM: 'HERVAL', nomeIBGE: 'Herval' },
    { codigoTOM: 8641, codigoIBGE: 4307203, nomeTOM: 'ERVAL GRANDE', nomeIBGE: 'Erval Grande' },
    { codigoTOM: 8643, codigoIBGE: 4307302, nomeTOM: 'ERVAL SECO', nomeIBGE: 'Erval Seco' },
    { codigoTOM: 8645, codigoIBGE: 4307401, nomeTOM: 'ESMERALDA', nomeIBGE: 'Esmeralda' },
    { codigoTOM: 8647, codigoIBGE: 4307500, nomeTOM: 'ESPUMOSO', nomeIBGE: 'Espumoso' },
    { codigoTOM: 8649, codigoIBGE: 4307609, nomeTOM: 'ESTÂNCIA VELHA', nomeIBGE: 'Estância Velha' },
    { codigoTOM: 8651, codigoIBGE: 4307708, nomeTOM: 'ESTEIO', nomeIBGE: 'Esteio' },
    { codigoTOM: 8653, codigoIBGE: 4307807, nomeTOM: 'ESTRELA', nomeIBGE: 'Estrela' },
    { codigoTOM: 8655, codigoIBGE: 4307906, nomeTOM: 'FARROUPILHA', nomeIBGE: 'Farroupilha' },
    { codigoTOM: 8657, codigoIBGE: 4308003, nomeTOM: 'FAXINAL DO SOTURNO', nomeIBGE: 'Faxinal do Soturno' },
    { codigoTOM: 8659, codigoIBGE: 4308102, nomeTOM: 'FELIZ', nomeIBGE: 'Feliz' },
    { codigoTOM: 8661, codigoIBGE: 4308201, nomeTOM: 'FLORES DA CUNHA', nomeIBGE: 'Flores da Cunha' },
    { codigoTOM: 8663, codigoIBGE: 4308300, nomeTOM: 'FONTOURA XAVIER', nomeIBGE: 'Fontoura Xavier' },
    { codigoTOM: 8665, codigoIBGE: 4308409, nomeTOM: 'FORMIGUEIRO', nomeIBGE: 'Formigueiro' },
    { codigoTOM: 8667, codigoIBGE: 4308508, nomeTOM: 'FREDERICO WESTPHALEN', nomeIBGE: 'Frederico Westphalen' },
    { codigoTOM: 8669, codigoIBGE: 4308607, nomeTOM: 'GARIBALDI', nomeIBGE: 'Garibaldi' },
    { codigoTOM: 8671, codigoIBGE: 4308706, nomeTOM: 'GAURAMA', nomeIBGE: 'Gaurama' },
    { codigoTOM: 8673, codigoIBGE: 4308805, nomeTOM: 'GENERAL CÂMARA', nomeIBGE: 'General Câmara' },
    { codigoTOM: 8675, codigoIBGE: 4319802, nomeTOM: 'SÃO VICENTE DO SUL', nomeIBGE: 'São Vicente do Sul' },
    { codigoTOM: 8677, codigoIBGE: 4308904, nomeTOM: 'GETÚLIO VARGAS', nomeIBGE: 'Getúlio Vargas' },
    { codigoTOM: 8679, codigoIBGE: 4309001, nomeTOM: 'GIRUÁ', nomeIBGE: 'Giruá' },
    { codigoTOM: 8681, codigoIBGE: 4309100, nomeTOM: 'GRAMADO', nomeIBGE: 'Gramado' },
    { codigoTOM: 8683, codigoIBGE: 4309209, nomeTOM: 'GRAVATAÍ', nomeIBGE: 'Gravataí' },
    { codigoTOM: 8685, codigoIBGE: 4309308, nomeTOM: 'GUAÍBA', nomeIBGE: 'Guaíba' },
    { codigoTOM: 8687, codigoIBGE: 4309407, nomeTOM: 'GUAPORÉ', nomeIBGE: 'Guaporé' },
    { codigoTOM: 8689, codigoIBGE: 4309506, nomeTOM: 'GUARANI DAS MISSÕES', nomeIBGE: 'Guarani das Missões' },
    { codigoTOM: 8691, codigoIBGE: 4309605, nomeTOM: 'HORIZONTINA', nomeIBGE: 'Horizontina' },
    { codigoTOM: 8693, codigoIBGE: 4305355, nomeTOM: 'CHARQUEADAS', nomeIBGE: 'Charqueadas' },
    { codigoTOM: 8695, codigoIBGE: 4309704, nomeTOM: 'HUMAITÁ', nomeIBGE: 'Humaitá' },
    { codigoTOM: 8697, codigoIBGE: 4309803, nomeTOM: 'IBIAÇÁ', nomeIBGE: 'Ibiaçá' },
    { codigoTOM: 8699, codigoIBGE: 4309902, nomeTOM: 'IBIRAIARAS', nomeIBGE: 'Ibiraiaras' },
    { codigoTOM: 8701, codigoIBGE: 4310009, nomeTOM: 'IBIRUBÁ', nomeIBGE: 'Ibirubá' },
    { codigoTOM: 8703, codigoIBGE: 4310108, nomeTOM: 'IGREJINHA', nomeIBGE: 'Igrejinha' },
    { codigoTOM: 8705, codigoIBGE: 4310207, nomeTOM: 'IJUÍ', nomeIBGE: 'Ijuí' },
    { codigoTOM: 8707, codigoIBGE: 4310306, nomeTOM: 'ILÓPOLIS', nomeIBGE: 'Ilópolis' },
    { codigoTOM: 8709, codigoIBGE: 4310405, nomeTOM: 'INDEPENDÊNCIA', nomeIBGE: 'Independência' },
    { codigoTOM: 8711, codigoIBGE: 4310504, nomeTOM: 'IRAÍ', nomeIBGE: 'Iraí' },
    { codigoTOM: 8713, codigoIBGE: 4310603, nomeTOM: 'ITAQUI', nomeIBGE: 'Itaqui' },
    { codigoTOM: 8715, codigoIBGE: 4310702, nomeTOM: 'ITATIBA DO SUL', nomeIBGE: 'Itatiba do Sul' },
    { codigoTOM: 8717, codigoIBGE: 4310801, nomeTOM: 'IVOTI', nomeIBGE: 'Ivoti' },
    { codigoTOM: 8719, codigoIBGE: 4310900, nomeTOM: 'JACUTINGA', nomeIBGE: 'Jacutinga' },
    { codigoTOM: 8721, codigoIBGE: 4311007, nomeTOM: 'JAGUARÃO', nomeIBGE: 'Jaguarão' },
    { codigoTOM: 8723, codigoIBGE: 4311106, nomeTOM: 'JAGUARI', nomeIBGE: 'Jaguari' },
    { codigoTOM: 8725, codigoIBGE: 4311205, nomeTOM: 'JÚLIO DE CASTILHOS', nomeIBGE: 'Júlio de Castilhos' },
    { codigoTOM: 8727, codigoIBGE: 4311304, nomeTOM: 'LAGOA VERMELHA', nomeIBGE: 'Lagoa Vermelha' },
    { codigoTOM: 8729, codigoIBGE: 4311403, nomeTOM: 'LAJEADO', nomeIBGE: 'Lajeado' },
    { codigoTOM: 8731, codigoIBGE: 4311502, nomeTOM: 'LAVRAS DO SUL', nomeIBGE: 'Lavras do Sul' },
    { codigoTOM: 8733, codigoIBGE: 4311601, nomeTOM: 'LIBERATO SALZANO', nomeIBGE: 'Liberato Salzano' },
    { codigoTOM: 8735, codigoIBGE: 4311700, nomeTOM: 'MACHADINHO', nomeIBGE: 'Machadinho' },
    { codigoTOM: 8737, codigoIBGE: 4311809, nomeTOM: 'MARAU', nomeIBGE: 'Marau' },
    { codigoTOM: 8739, codigoIBGE: 4311908, nomeTOM: 'MARCELINO RAMOS', nomeIBGE: 'Marcelino Ramos' },
    { codigoTOM: 8741, codigoIBGE: 4312005, nomeTOM: 'MARIANO MORO', nomeIBGE: 'Mariano Moro' },
    { codigoTOM: 8743, codigoIBGE: 4312104, nomeTOM: 'MATA', nomeIBGE: 'Mata' },
    { codigoTOM: 8745, codigoIBGE: 4312203, nomeTOM: 'MAXIMILIANO DE ALMEIDA', nomeIBGE: 'Maximiliano de Almeida' },
    { codigoTOM: 8747, codigoIBGE: 4312302, nomeTOM: 'MIRAGUAÍ', nomeIBGE: 'Miraguaí' },
    { codigoTOM: 8749, codigoIBGE: 4312401, nomeTOM: 'MONTENEGRO', nomeIBGE: 'Montenegro' },
    { codigoTOM: 8751, codigoIBGE: 4312500, nomeTOM: 'MOSTARDAS', nomeIBGE: 'Mostardas' },
    { codigoTOM: 8753, codigoIBGE: 4312609, nomeTOM: 'MUÇUM', nomeIBGE: 'Muçum' },
    { codigoTOM: 8755, codigoIBGE: 4312658, nomeTOM: 'NÃO-ME-TOQUE', nomeIBGE: 'Não-Me-Toque' },
    { codigoTOM: 8757, codigoIBGE: 4312708, nomeTOM: 'NONOAI', nomeIBGE: 'Nonoai' },
    { codigoTOM: 8759, codigoIBGE: 4312807, nomeTOM: 'NOVA ARAÇÁ', nomeIBGE: 'Nova Araçá' },
    { codigoTOM: 8761, codigoIBGE: 4312906, nomeTOM: 'NOVA BASSANO', nomeIBGE: 'Nova Bassano' },
    { codigoTOM: 8763, codigoIBGE: 4313003, nomeTOM: 'NOVA BRÉSCIA', nomeIBGE: 'Nova Bréscia' },
    { codigoTOM: 8765, codigoIBGE: 4313102, nomeTOM: 'NOVA PALMA', nomeIBGE: 'Nova Palma' },
    { codigoTOM: 8767, codigoIBGE: 4313201, nomeTOM: 'NOVA PETRÓPOLIS', nomeIBGE: 'Nova Petrópolis' },
    { codigoTOM: 8769, codigoIBGE: 4313300, nomeTOM: 'NOVA PRATA', nomeIBGE: 'Nova Prata' },
    { codigoTOM: 8771, codigoIBGE: 4313409, nomeTOM: 'NOVO HAMBURGO', nomeIBGE: 'Novo Hamburgo' },
    { codigoTOM: 8773, codigoIBGE: 4313508, nomeTOM: 'OSÓRIO', nomeIBGE: 'Osório' },
    { codigoTOM: 8775, codigoIBGE: 4313607, nomeTOM: 'PAIM FILHO', nomeIBGE: 'Paim Filho' },
    { codigoTOM: 8777, codigoIBGE: 4313706, nomeTOM: 'PALMEIRA DAS MISSÕES', nomeIBGE: 'Palmeira das Missões' },
    { codigoTOM: 8779, codigoIBGE: 4313805, nomeTOM: 'PALMITINHO', nomeIBGE: 'Palmitinho' },
    { codigoTOM: 8781, codigoIBGE: 4313904, nomeTOM: 'PANAMBI', nomeIBGE: 'Panambi' },
    { codigoTOM: 8783, codigoIBGE: 4314001, nomeTOM: 'PARAÍ', nomeIBGE: 'Paraí' },
    { codigoTOM: 8785, codigoIBGE: 4314100, nomeTOM: 'PASSO FUNDO', nomeIBGE: 'Passo Fundo' },
    { codigoTOM: 8787, codigoIBGE: 4314209, nomeTOM: 'PEDRO OSÓRIO', nomeIBGE: 'Pedro Osório' },
    { codigoTOM: 8789, codigoIBGE: 4314308, nomeTOM: 'PEJUÇARA', nomeIBGE: 'Pejuçara' },
    { codigoTOM: 8791, codigoIBGE: 4314407, nomeTOM: 'PELOTAS', nomeIBGE: 'Pelotas' },
    { codigoTOM: 8793, codigoIBGE: 4314506, nomeTOM: 'PINHEIRO MACHADO', nomeIBGE: 'Pinheiro Machado' },
    { codigoTOM: 8795, codigoIBGE: 4314605, nomeTOM: 'PIRATINI', nomeIBGE: 'Piratini' },
    { codigoTOM: 8797, codigoIBGE: 4314704, nomeTOM: 'PLANALTO', nomeIBGE: 'Planalto' },
    { codigoTOM: 8799, codigoIBGE: 4314803, nomeTOM: 'PORTÃO', nomeIBGE: 'Portão' },
    { codigoTOM: 8801, codigoIBGE: 4314902, nomeTOM: 'PORTO ALEGRE', nomeIBGE: 'Porto Alegre' },
    { codigoTOM: 8803, codigoIBGE: 4315008, nomeTOM: 'PORTO LUCENA', nomeIBGE: 'Porto Lucena' },
    { codigoTOM: 8805, codigoIBGE: 4315107, nomeTOM: 'PORTO XAVIER', nomeIBGE: 'Porto Xavier' },
    { codigoTOM: 8807, codigoIBGE: 4315206, nomeTOM: 'PUTINGA', nomeIBGE: 'Putinga' },
    { codigoTOM: 8809, codigoIBGE: 4315305, nomeTOM: 'QUARAÍ', nomeIBGE: 'Quaraí' },
    { codigoTOM: 8811, codigoIBGE: 4315404, nomeTOM: 'REDENTORA', nomeIBGE: 'Redentora' },
    { codigoTOM: 8813, codigoIBGE: 4315503, nomeTOM: 'RESTINGA SECA', nomeIBGE: 'Restinga Sêca' },
    { codigoTOM: 8815, codigoIBGE: 4315602, nomeTOM: 'RIO GRANDE', nomeIBGE: 'Rio Grande' },
    { codigoTOM: 8817, codigoIBGE: 4315701, nomeTOM: 'RIO PARDO', nomeIBGE: 'Rio Pardo' },
    { codigoTOM: 8819, codigoIBGE: 4315800, nomeTOM: 'ROCA SALES', nomeIBGE: 'Roca Sales' },
    { codigoTOM: 8821, codigoIBGE: 4315909, nomeTOM: 'RODEIO BONITO', nomeIBGE: 'Rodeio Bonito' },
    { codigoTOM: 8823, codigoIBGE: 4316006, nomeTOM: 'ROLANTE', nomeIBGE: 'Rolante' },
    { codigoTOM: 8825, codigoIBGE: 4316105, nomeTOM: 'RONDA ALTA', nomeIBGE: 'Ronda Alta' },
    { codigoTOM: 8827, codigoIBGE: 4316204, nomeTOM: 'RONDINHA', nomeIBGE: 'Rondinha' },
    { codigoTOM: 8829, codigoIBGE: 4316303, nomeTOM: 'ROQUE GONZALES', nomeIBGE: 'Roque Gonzales' },
    { codigoTOM: 8831, codigoIBGE: 4316402, nomeTOM: 'ROSÁRIO DO SUL', nomeIBGE: 'Rosário do Sul' },
    { codigoTOM: 8833, codigoIBGE: 4316501, nomeTOM: 'SALVADOR DO SUL', nomeIBGE: 'Salvador do Sul' },
    { codigoTOM: 8835, codigoIBGE: 4316600, nomeTOM: 'SANANDUVA', nomeIBGE: 'Sananduva' },
    { codigoTOM: 8837, codigoIBGE: 4316709, nomeTOM: 'SANTA BÁRBARA DO SUL', nomeIBGE: 'Santa Bárbara do Sul' },
    { codigoTOM: 8839, codigoIBGE: 4316808, nomeTOM: 'SANTA CRUZ DO SUL', nomeIBGE: 'Santa Cruz do Sul' },
    { codigoTOM: 8841, codigoIBGE: 4316907, nomeTOM: 'SANTA MARIA', nomeIBGE: 'Santa Maria' },
    { codigoTOM: 8843, codigoIBGE: 4317004, nomeTOM: 'SANTANA DA BOA VISTA', nomeIBGE: 'Santana da Boa Vista' },
    { codigoTOM: 8845, codigoIBGE: 4317103, nomeTOM: 'SANTANA DO LIVRAMENTO', nomeIBGE: "Sant'Ana do Livramento" },
    { codigoTOM: 8847, codigoIBGE: 4317202, nomeTOM: 'SANTA ROSA', nomeIBGE: 'Santa Rosa' },
    { codigoTOM: 8849, codigoIBGE: 4317301, nomeTOM: 'SANTA VITÓRIA DO PALMAR', nomeIBGE: 'Santa Vitória do Palmar' },
    { codigoTOM: 8851, codigoIBGE: 4317400, nomeTOM: 'SANTIAGO', nomeIBGE: 'Santiago' },
    { codigoTOM: 8853, codigoIBGE: 4317509, nomeTOM: 'SANTO ÂNGELO', nomeIBGE: 'Santo Ângelo' },
    {
      codigoTOM: 8855,
      codigoIBGE: 4317608,
      nomeTOM: 'SANTO ANTÔNIO DA PATRULHA',
      nomeIBGE: 'Santo Antônio da Patrulha',
    },
    {
      codigoTOM: 8857,
      codigoIBGE: 4317707,
      nomeTOM: 'SANTO ANTÔNIO DAS MISSÕES',
      nomeIBGE: 'Santo Antônio das Missões',
    },
    { codigoTOM: 8859, codigoIBGE: 4317806, nomeTOM: 'SANTO AUGUSTO', nomeIBGE: 'Santo Augusto' },
    { codigoTOM: 8861, codigoIBGE: 4317905, nomeTOM: 'SANTO CRISTO', nomeIBGE: 'Santo Cristo' },
    { codigoTOM: 8863, codigoIBGE: 4318002, nomeTOM: 'SÃO BORJA', nomeIBGE: 'São Borja' },
    { codigoTOM: 8865, codigoIBGE: 4318101, nomeTOM: 'SÃO FRANCISCO DE ASSIS', nomeIBGE: 'São Francisco de Assis' },
    { codigoTOM: 8867, codigoIBGE: 4318200, nomeTOM: 'SÃO FRANCISCO DE PAULA', nomeIBGE: 'São Francisco de Paula' },
    { codigoTOM: 8869, codigoIBGE: 4318309, nomeTOM: 'SÃO GABRIEL', nomeIBGE: 'São Gabriel' },
    { codigoTOM: 8871, codigoIBGE: 4318408, nomeTOM: 'SÃO JERÔNIMO', nomeIBGE: 'São Jerônimo' },
    { codigoTOM: 8873, codigoIBGE: 4318507, nomeTOM: 'SÃO JOSÉ DO NORTE', nomeIBGE: 'São José do Norte' },
    { codigoTOM: 8875, codigoIBGE: 4318606, nomeTOM: 'SÃO JOSÉ DO OURO', nomeIBGE: 'São José do Ouro' },
    { codigoTOM: 8877, codigoIBGE: 4318705, nomeTOM: 'SÃO LEOPOLDO', nomeIBGE: 'São Leopoldo' },
    { codigoTOM: 8879, codigoIBGE: 4318804, nomeTOM: 'SÃO LOURENÇO DO SUL', nomeIBGE: 'São Lourenço do Sul' },
    { codigoTOM: 8881, codigoIBGE: 4318903, nomeTOM: 'SÃO LUIZ GONZAGA', nomeIBGE: 'São Luiz Gonzaga' },
    { codigoTOM: 8883, codigoIBGE: 4319000, nomeTOM: 'SÃO MARCOS', nomeIBGE: 'São Marcos' },
    { codigoTOM: 8885, codigoIBGE: 4319109, nomeTOM: 'SÃO MARTINHO', nomeIBGE: 'São Martinho' },
    { codigoTOM: 8887, codigoIBGE: 4319208, nomeTOM: 'SÃO NICOLAU', nomeIBGE: 'São Nicolau' },
    { codigoTOM: 8889, codigoIBGE: 4319307, nomeTOM: 'SÃO PAULO DAS MISSÕES', nomeIBGE: 'São Paulo das Missões' },
    { codigoTOM: 8891, codigoIBGE: 4319406, nomeTOM: 'SÃO PEDRO DO SUL', nomeIBGE: 'São Pedro do Sul' },
    { codigoTOM: 8893, codigoIBGE: 4319505, nomeTOM: 'SÃO SEBASTIÃO DO CAÍ', nomeIBGE: 'São Sebastião do Caí' },
    { codigoTOM: 8895, codigoIBGE: 4319604, nomeTOM: 'SÃO SEPÉ', nomeIBGE: 'São Sepé' },
    { codigoTOM: 8897, codigoIBGE: 4319703, nomeTOM: 'SÃO VALENTIM', nomeIBGE: 'São Valentim' },
    { codigoTOM: 8899, codigoIBGE: 4319901, nomeTOM: 'SAPIRANGA', nomeIBGE: 'Sapiranga' },
    { codigoTOM: 8901, codigoIBGE: 4320008, nomeTOM: 'SAPUCAIA DO SUL', nomeIBGE: 'Sapucaia do Sul' },
    { codigoTOM: 8903, codigoIBGE: 4320107, nomeTOM: 'SARANDI', nomeIBGE: 'Sarandi' },
    { codigoTOM: 8905, codigoIBGE: 4320206, nomeTOM: 'SEBERI', nomeIBGE: 'Seberi' },
    { codigoTOM: 8907, codigoIBGE: 4320305, nomeTOM: 'SELBACH', nomeIBGE: 'Selbach' },
    { codigoTOM: 8909, codigoIBGE: 4320404, nomeTOM: 'SERAFINA CORRÊA', nomeIBGE: 'Serafina Corrêa' },
    { codigoTOM: 8911, codigoIBGE: 4320503, nomeTOM: 'SERTÃO', nomeIBGE: 'Sertão' },
    { codigoTOM: 8913, codigoIBGE: 4320602, nomeTOM: 'SEVERIANO DE ALMEIDA', nomeIBGE: 'Severiano de Almeida' },
    { codigoTOM: 8915, codigoIBGE: 4304630, nomeTOM: 'CAPÃO DA CANOA', nomeIBGE: 'Capão da Canoa' },
    { codigoTOM: 8917, codigoIBGE: 4320701, nomeTOM: 'SOBRADINHO', nomeIBGE: 'Sobradinho' },
    { codigoTOM: 8919, codigoIBGE: 4320800, nomeTOM: 'SOLEDADE', nomeIBGE: 'Soledade' },
    { codigoTOM: 8921, codigoIBGE: 4320909, nomeTOM: 'TAPEJARA', nomeIBGE: 'Tapejara' },
    { codigoTOM: 8923, codigoIBGE: 4321006, nomeTOM: 'TAPERA', nomeIBGE: 'Tapera' },
    { codigoTOM: 8925, codigoIBGE: 4321105, nomeTOM: 'TAPES', nomeIBGE: 'Tapes' },
    { codigoTOM: 8927, codigoIBGE: 4321204, nomeTOM: 'TAQUARA', nomeIBGE: 'Taquara' },
    { codigoTOM: 8929, codigoIBGE: 4321303, nomeTOM: 'TAQUARI', nomeIBGE: 'Taquari' },
    { codigoTOM: 8931, codigoIBGE: 4321402, nomeTOM: 'TENENTE PORTELA', nomeIBGE: 'Tenente Portela' },
    { codigoTOM: 8933, codigoIBGE: 4321501, nomeTOM: 'TORRES', nomeIBGE: 'Torres' },
    { codigoTOM: 8935, codigoIBGE: 4321600, nomeTOM: 'TRAMANDAÍ', nomeIBGE: 'Tramandaí' },
    { codigoTOM: 8937, codigoIBGE: 4321709, nomeTOM: 'TRÊS COROAS', nomeIBGE: 'Três Coroas' },
    { codigoTOM: 8939, codigoIBGE: 4321808, nomeTOM: 'TRÊS DE MAIO', nomeIBGE: 'Três de Maio' },
    { codigoTOM: 8941, codigoIBGE: 4321907, nomeTOM: 'TRÊS PASSOS', nomeIBGE: 'Três Passos' },
    { codigoTOM: 8943, codigoIBGE: 4322004, nomeTOM: 'TRIUNFO', nomeIBGE: 'Triunfo' },
    { codigoTOM: 8945, codigoIBGE: 4322103, nomeTOM: 'TUCUNDUVA', nomeIBGE: 'Tucunduva' },
    { codigoTOM: 8947, codigoIBGE: 4322202, nomeTOM: 'TUPANCIRETÃ', nomeIBGE: 'Tupanciretã' },
    { codigoTOM: 8949, codigoIBGE: 4322301, nomeTOM: 'TUPARENDI', nomeIBGE: 'Tuparendi' },
    { codigoTOM: 8951, codigoIBGE: 4322400, nomeTOM: 'URUGUAIANA', nomeIBGE: 'Uruguaiana' },
    { codigoTOM: 8953, codigoIBGE: 4322509, nomeTOM: 'VACARIA', nomeIBGE: 'Vacaria' },
    { codigoTOM: 8955, codigoIBGE: 4322608, nomeTOM: 'VENÂNCIO AIRES', nomeIBGE: 'Venâncio Aires' },
    { codigoTOM: 8957, codigoIBGE: 4322707, nomeTOM: 'VERA CRUZ', nomeIBGE: 'Vera Cruz' },
    { codigoTOM: 8959, codigoIBGE: 4322806, nomeTOM: 'VERANÓPOLIS', nomeIBGE: 'Veranópolis' },
    { codigoTOM: 8961, codigoIBGE: 4322905, nomeTOM: 'VIADUTOS', nomeIBGE: 'Viadutos' },
    { codigoTOM: 8963, codigoIBGE: 4323002, nomeTOM: 'VIAMÃO', nomeIBGE: 'Viamão' },
    { codigoTOM: 8965, codigoIBGE: 4323101, nomeTOM: 'VICENTE DUTRA', nomeIBGE: 'Vicente Dutra' },
    { codigoTOM: 8967, codigoIBGE: 4313656, nomeTOM: 'PALMARES DO SUL', nomeIBGE: 'Palmares do Sul' },
    { codigoTOM: 8969, codigoIBGE: 4323200, nomeTOM: 'VICTOR GRAEFF', nomeIBGE: 'Victor Graeff' },
    { codigoTOM: 8971, codigoIBGE: 4321352, nomeTOM: 'TAVARES', nomeIBGE: 'Tavares' },
    { codigoTOM: 8973, codigoIBGE: 4304663, nomeTOM: 'CAPÃO DO LEÃO', nomeIBGE: 'Capão do Leão' },
    { codigoTOM: 8975, codigoIBGE: 4316451, nomeTOM: 'SALTO DO JACUÍ', nomeIBGE: 'Salto do Jacuí' },
    { codigoTOM: 8977, codigoIBGE: 4305959, nomeTOM: 'COTIPORÃ', nomeIBGE: 'Cotiporã' },
    { codigoTOM: 9821, codigoIBGE: 4321451, nomeTOM: 'TEUTÔNIA', nomeIBGE: 'Teutônia' },
    { codigoTOM: 9823, codigoIBGE: 4302352, nomeTOM: 'BOM PRINCÍPIO', nomeIBGE: 'Bom Princípio' },
    { codigoTOM: 9825, codigoIBGE: 4314050, nomeTOM: 'PAROBÉ', nomeIBGE: 'Parobé' },
    { codigoTOM: 9827, codigoIBGE: 4308458, nomeTOM: 'FORTALEZA DOS VALOS', nomeIBGE: 'Fortaleza dos Valos' },
    { codigoTOM: 9829, codigoIBGE: 4311155, nomeTOM: 'JÓIA', nomeIBGE: 'Jóia' },
  ],
  [Estados.CE]: [
    { codigoTOM: 983, codigoIBGE: 2303659, nomeTOM: 'CATUNDA', nomeIBGE: 'Catunda' },
    { codigoTOM: 985, codigoIBGE: 2307254, nomeTOM: 'JIJOCA DE JERICOACOARA', nomeIBGE: 'Jijoca de Jericoacoara' },
    { codigoTOM: 987, codigoIBGE: 2304459, nomeTOM: 'FORTIM', nomeIBGE: 'Fortim' },
    { codigoTOM: 989, codigoIBGE: 2301257, nomeTOM: 'ARARENDÁ', nomeIBGE: 'Ararendá' },
    { codigoTOM: 991, codigoIBGE: 2306256, nomeTOM: 'ITAITINGA', nomeIBGE: 'Itaitinga' },
    { codigoTOM: 993, codigoIBGE: 2303931, nomeTOM: 'CHORÓ', nomeIBGE: 'Choró' },
    { codigoTOM: 1231, codigoIBGE: 2300150, nomeTOM: 'ACARAPE', nomeIBGE: 'Acarape' },
    { codigoTOM: 1233, codigoIBGE: 2301851, nomeTOM: 'BANABUIÚ', nomeIBGE: 'Banabuiú' },
    { codigoTOM: 1235, codigoIBGE: 2301950, nomeTOM: 'BARREIRA', nomeIBGE: 'Barreira' },
    { codigoTOM: 1237, codigoIBGE: 2302057, nomeTOM: 'BARROQUINHA', nomeIBGE: 'Barroquinha' },
    { codigoTOM: 1239, codigoIBGE: 2303956, nomeTOM: 'CHOROZINHO', nomeIBGE: 'Chorozinho' },
    { codigoTOM: 1241, codigoIBGE: 2304236, nomeTOM: 'CROATÁ', nomeIBGE: 'Croatá' },
    {
      codigoTOM: 1243,
      codigoIBGE: 2304269,
      nomeTOM: 'DEPUTADO IRAPUAN PINHEIRO',
      nomeIBGE: 'Deputado Irapuan Pinheiro',
    },
    { codigoTOM: 1245, codigoIBGE: 2304277, nomeTOM: 'ERERÊ', nomeIBGE: 'Ereré' },
    { codigoTOM: 1247, codigoIBGE: 2304285, nomeTOM: 'EUSÉBIO', nomeIBGE: 'Eusébio' },
    { codigoTOM: 1249, codigoIBGE: 2304657, nomeTOM: 'GRAÇA', nomeIBGE: 'Graça' },
    { codigoTOM: 1251, codigoIBGE: 2304954, nomeTOM: 'GUAIÚBA', nomeIBGE: 'Guaiúba' },
    { codigoTOM: 1253, codigoIBGE: 2305233, nomeTOM: 'HORIZONTE', nomeIBGE: 'Horizonte' },
    { codigoTOM: 1255, codigoIBGE: 2305266, nomeTOM: 'IBARETAMA', nomeIBGE: 'Ibaretama' },
    { codigoTOM: 1257, codigoIBGE: 2305332, nomeTOM: 'IBICUITINGA', nomeIBGE: 'Ibicuitinga' },
    { codigoTOM: 1259, codigoIBGE: 2305654, nomeTOM: 'IPAPORANGA', nomeIBGE: 'Ipaporanga' },
    { codigoTOM: 1261, codigoIBGE: 2307635, nomeTOM: 'MADALENA', nomeIBGE: 'Madalena' },
    { codigoTOM: 1263, codigoIBGE: 2308377, nomeTOM: 'MIRAÍMA', nomeIBGE: 'Miraíma' },
    { codigoTOM: 1265, codigoIBGE: 2309458, nomeTOM: 'OCARA', nomeIBGE: 'Ocara' },
    { codigoTOM: 1267, codigoIBGE: 2310852, nomeTOM: 'PINDORETAMA', nomeIBGE: 'Pindoretama' },
    { codigoTOM: 1269, codigoIBGE: 2310951, nomeTOM: 'PIRES FERREIRA', nomeIBGE: 'Pires Ferreira' },
    { codigoTOM: 1271, codigoIBGE: 2311231, nomeTOM: 'POTIRETAMA', nomeIBGE: 'Potiretama' },
    { codigoTOM: 1273, codigoIBGE: 2311959, nomeTOM: 'SALITRE', nomeIBGE: 'Salitre' },
    { codigoTOM: 1275, codigoIBGE: 2313252, nomeTOM: 'TARRAFAS', nomeIBGE: 'Tarrafas' },
    { codigoTOM: 1277, codigoIBGE: 2313351, nomeTOM: 'TEJUÇUOCA', nomeIBGE: 'Tejuçuoca' },
    { codigoTOM: 1279, codigoIBGE: 2313559, nomeTOM: 'TURURU', nomeIBGE: 'Tururu' },
    { codigoTOM: 1301, codigoIBGE: 2300101, nomeTOM: 'ABAIARA', nomeIBGE: 'Abaiara' },
    { codigoTOM: 1303, codigoIBGE: 2300200, nomeTOM: 'ACARAÚ', nomeIBGE: 'Acaraú' },
    { codigoTOM: 1305, codigoIBGE: 2300309, nomeTOM: 'ACOPIARA', nomeIBGE: 'Acopiara' },
    { codigoTOM: 1307, codigoIBGE: 2300408, nomeTOM: 'AIUABA', nomeIBGE: 'Aiuaba' },
    { codigoTOM: 1309, codigoIBGE: 2300507, nomeTOM: 'ALCÂNTARAS', nomeIBGE: 'Alcântaras' },
    { codigoTOM: 1311, codigoIBGE: 2300606, nomeTOM: 'ALTANEIRA', nomeIBGE: 'Altaneira' },
    { codigoTOM: 1313, codigoIBGE: 2300705, nomeTOM: 'ALTO SANTO', nomeIBGE: 'Alto Santo' },
    { codigoTOM: 1315, codigoIBGE: 2300804, nomeTOM: 'ANTONINA DO NORTE', nomeIBGE: 'Antonina do Norte' },
    { codigoTOM: 1317, codigoIBGE: 2300903, nomeTOM: 'APUIARÉS', nomeIBGE: 'Apuiarés' },
    { codigoTOM: 1319, codigoIBGE: 2301000, nomeTOM: 'AQUIRAZ', nomeIBGE: 'Aquiraz' },
    { codigoTOM: 1321, codigoIBGE: 2301109, nomeTOM: 'ARACATI', nomeIBGE: 'Aracati' },
    { codigoTOM: 1323, codigoIBGE: 2301208, nomeTOM: 'ARACOIABA', nomeIBGE: 'Aracoiaba' },
    { codigoTOM: 1325, codigoIBGE: 2301307, nomeTOM: 'ARARIPE', nomeIBGE: 'Araripe' },
    { codigoTOM: 1327, codigoIBGE: 2301406, nomeTOM: 'ARATUBA', nomeIBGE: 'Aratuba' },
    { codigoTOM: 1329, codigoIBGE: 2301505, nomeTOM: 'ARNEIROZ', nomeIBGE: 'Arneiroz' },
    { codigoTOM: 1331, codigoIBGE: 2301604, nomeTOM: 'ASSARÉ', nomeIBGE: 'Assaré' },
    { codigoTOM: 1333, codigoIBGE: 2301703, nomeTOM: 'AURORA', nomeIBGE: 'Aurora' },
    { codigoTOM: 1335, codigoIBGE: 2301802, nomeTOM: 'BAIXIO', nomeIBGE: 'Baixio' },
    { codigoTOM: 1337, codigoIBGE: 2301901, nomeTOM: 'BARBALHA', nomeIBGE: 'Barbalha' },
    { codigoTOM: 1339, codigoIBGE: 2302008, nomeTOM: 'BARRO', nomeIBGE: 'Barro' },
    { codigoTOM: 1341, codigoIBGE: 2302107, nomeTOM: 'BATURITÉ', nomeIBGE: 'Baturité' },
    { codigoTOM: 1343, codigoIBGE: 2302206, nomeTOM: 'BEBERIBE', nomeIBGE: 'Beberibe' },
    { codigoTOM: 1345, codigoIBGE: 2302305, nomeTOM: 'BELA CRUZ', nomeIBGE: 'Bela Cruz' },
    { codigoTOM: 1347, codigoIBGE: 2302404, nomeTOM: 'BOA VIAGEM', nomeIBGE: 'Boa Viagem' },
    { codigoTOM: 1349, codigoIBGE: 2302503, nomeTOM: 'BREJO SANTO', nomeIBGE: 'Brejo Santo' },
    { codigoTOM: 1351, codigoIBGE: 2302602, nomeTOM: 'CAMOCIM', nomeIBGE: 'Camocim' },
    { codigoTOM: 1353, codigoIBGE: 2302701, nomeTOM: 'CAMPOS SALES', nomeIBGE: 'Campos Sales' },
    { codigoTOM: 1355, codigoIBGE: 2302800, nomeTOM: 'CANINDÉ', nomeIBGE: 'Canindé' },
    { codigoTOM: 1357, codigoIBGE: 2302909, nomeTOM: 'CAPISTRANO', nomeIBGE: 'Capistrano' },
    { codigoTOM: 1359, codigoIBGE: 2303006, nomeTOM: 'CARIDADE', nomeIBGE: 'Caridade' },
    { codigoTOM: 1361, codigoIBGE: 2303105, nomeTOM: 'CARIRÉ', nomeIBGE: 'Cariré' },
    { codigoTOM: 1363, codigoIBGE: 2303204, nomeTOM: 'CARIRIAÇU', nomeIBGE: 'Caririaçu' },
    { codigoTOM: 1365, codigoIBGE: 2303303, nomeTOM: 'CARIÚS', nomeIBGE: 'Cariús' },
    { codigoTOM: 1367, codigoIBGE: 2303402, nomeTOM: 'CARNAUBAL', nomeIBGE: 'Carnaubal' },
    { codigoTOM: 1369, codigoIBGE: 2303501, nomeTOM: 'CASCAVEL', nomeIBGE: 'Cascavel' },
    { codigoTOM: 1371, codigoIBGE: 2303600, nomeTOM: 'CATARINA', nomeIBGE: 'Catarina' },
    { codigoTOM: 1373, codigoIBGE: 2303709, nomeTOM: 'CAUCAIA', nomeIBGE: 'Caucaia' },
    { codigoTOM: 1375, codigoIBGE: 2303808, nomeTOM: 'CEDRO', nomeIBGE: 'Cedro' },
    { codigoTOM: 1377, codigoIBGE: 2303907, nomeTOM: 'CHAVAL', nomeIBGE: 'Chaval' },
    { codigoTOM: 1381, codigoIBGE: 2304004, nomeTOM: 'COREAÚ', nomeIBGE: 'Coreaú' },
    { codigoTOM: 1383, codigoIBGE: 2304103, nomeTOM: 'CRATEÚS', nomeIBGE: 'Crateús' },
    { codigoTOM: 1385, codigoIBGE: 2304202, nomeTOM: 'CRATO', nomeIBGE: 'Crato' },
    { codigoTOM: 1387, codigoIBGE: 2304301, nomeTOM: 'FARIAS BRITO', nomeIBGE: 'Farias Brito' },
    { codigoTOM: 1389, codigoIBGE: 2304400, nomeTOM: 'FORTALEZA', nomeIBGE: 'Fortaleza' },
    { codigoTOM: 1391, codigoIBGE: 2304509, nomeTOM: 'FRECHEIRINHA', nomeIBGE: 'Frecheirinha' },
    { codigoTOM: 1393, codigoIBGE: 2304608, nomeTOM: 'GENERAL SAMPAIO', nomeIBGE: 'General Sampaio' },
    { codigoTOM: 1395, codigoIBGE: 2304707, nomeTOM: 'GRANJA', nomeIBGE: 'Granja' },
    { codigoTOM: 1397, codigoIBGE: 2304806, nomeTOM: 'GRANJEIRO', nomeIBGE: 'Granjeiro' },
    { codigoTOM: 1399, codigoIBGE: 2304905, nomeTOM: 'GROAÍRAS', nomeIBGE: 'Groaíras' },
    { codigoTOM: 1401, codigoIBGE: 2305001, nomeTOM: 'GUARACIABA DO NORTE', nomeIBGE: 'Guaraciaba do Norte' },
    { codigoTOM: 1403, codigoIBGE: 2305100, nomeTOM: 'GUARAMIRANGA', nomeIBGE: 'Guaramiranga' },
    { codigoTOM: 1405, codigoIBGE: 2305209, nomeTOM: 'HIDROLÂNDIA', nomeIBGE: 'Hidrolândia' },
    { codigoTOM: 1407, codigoIBGE: 2305308, nomeTOM: 'IBIAPINA', nomeIBGE: 'Ibiapina' },
    { codigoTOM: 1409, codigoIBGE: 2305407, nomeTOM: 'ICÓ', nomeIBGE: 'Icó' },
    { codigoTOM: 1411, codigoIBGE: 2305506, nomeTOM: 'IGUATU', nomeIBGE: 'Iguatu' },
    { codigoTOM: 1413, codigoIBGE: 2305605, nomeTOM: 'INDEPENDÊNCIA', nomeIBGE: 'Independência' },
    { codigoTOM: 1415, codigoIBGE: 2305704, nomeTOM: 'IPAUMIRIM', nomeIBGE: 'Ipaumirim' },
    { codigoTOM: 1417, codigoIBGE: 2305803, nomeTOM: 'IPU', nomeIBGE: 'Ipu' },
    { codigoTOM: 1419, codigoIBGE: 2305902, nomeTOM: 'IPUEIRAS', nomeIBGE: 'Ipueiras' },
    { codigoTOM: 1421, codigoIBGE: 2306009, nomeTOM: 'IRACEMA', nomeIBGE: 'Iracema' },
    { codigoTOM: 1423, codigoIBGE: 2306108, nomeTOM: 'IRAUÇUBA', nomeIBGE: 'Irauçuba' },
    { codigoTOM: 1425, codigoIBGE: 2306207, nomeTOM: 'ITAIÇABA', nomeIBGE: 'Itaiçaba' },
    { codigoTOM: 1427, codigoIBGE: 2306306, nomeTOM: 'ITAPAJÉ', nomeIBGE: 'Itapajé' },
    { codigoTOM: 1429, codigoIBGE: 2306405, nomeTOM: 'ITAPIPOCA', nomeIBGE: 'Itapipoca' },
    { codigoTOM: 1431, codigoIBGE: 2306504, nomeTOM: 'ITAPIÚNA', nomeIBGE: 'Itapiúna' },
    { codigoTOM: 1433, codigoIBGE: 2306603, nomeTOM: 'ITATIRA', nomeIBGE: 'Itatira' },
    { codigoTOM: 1435, codigoIBGE: 2306702, nomeTOM: 'JAGUARETAMA', nomeIBGE: 'Jaguaretama' },
    { codigoTOM: 1437, codigoIBGE: 2306801, nomeTOM: 'JAGUARIBARA', nomeIBGE: 'Jaguaribara' },
    { codigoTOM: 1439, codigoIBGE: 2306900, nomeTOM: 'JAGUARIBE', nomeIBGE: 'Jaguaribe' },
    { codigoTOM: 1441, codigoIBGE: 2307007, nomeTOM: 'JAGUARUANA', nomeIBGE: 'Jaguaruana' },
    { codigoTOM: 1443, codigoIBGE: 2307106, nomeTOM: 'JARDIM', nomeIBGE: 'Jardim' },
    { codigoTOM: 1445, codigoIBGE: 2307205, nomeTOM: 'JATI', nomeIBGE: 'Jati' },
    { codigoTOM: 1447, codigoIBGE: 2307304, nomeTOM: 'JUAZEIRO DO NORTE', nomeIBGE: 'Juazeiro do Norte' },
    { codigoTOM: 1449, codigoIBGE: 2307403, nomeTOM: 'JUCÁS', nomeIBGE: 'Jucás' },
    { codigoTOM: 1451, codigoIBGE: 2307502, nomeTOM: 'LAVRAS DA MANGABEIRA', nomeIBGE: 'Lavras da Mangabeira' },
    { codigoTOM: 1453, codigoIBGE: 2307601, nomeTOM: 'LIMOEIRO DO NORTE', nomeIBGE: 'Limoeiro do Norte' },
    { codigoTOM: 1455, codigoIBGE: 2307700, nomeTOM: 'MARANGUAPE', nomeIBGE: 'Maranguape' },
    { codigoTOM: 1457, codigoIBGE: 2307809, nomeTOM: 'MARCO', nomeIBGE: 'Marco' },
    { codigoTOM: 1459, codigoIBGE: 2307908, nomeTOM: 'MARTINÓPOLE', nomeIBGE: 'Martinópole' },
    { codigoTOM: 1461, codigoIBGE: 2308005, nomeTOM: 'MASSAPÊ', nomeIBGE: 'Massapê' },
    { codigoTOM: 1463, codigoIBGE: 2308104, nomeTOM: 'MAURITI', nomeIBGE: 'Mauriti' },
    { codigoTOM: 1465, codigoIBGE: 2308203, nomeTOM: 'MERUOCA', nomeIBGE: 'Meruoca' },
    { codigoTOM: 1467, codigoIBGE: 2308302, nomeTOM: 'MILAGRES', nomeIBGE: 'Milagres' },
    { codigoTOM: 1469, codigoIBGE: 2308401, nomeTOM: 'MISSÃO VELHA', nomeIBGE: 'Missão Velha' },
    { codigoTOM: 1471, codigoIBGE: 2308500, nomeTOM: 'MOMBAÇA', nomeIBGE: 'Mombaça' },
    { codigoTOM: 1473, codigoIBGE: 2308609, nomeTOM: 'MONSENHOR TABOSA', nomeIBGE: 'Monsenhor Tabosa' },
    { codigoTOM: 1475, codigoIBGE: 2308708, nomeTOM: 'MORADA NOVA', nomeIBGE: 'Morada Nova' },
    { codigoTOM: 1477, codigoIBGE: 2308807, nomeTOM: 'MORAÚJO', nomeIBGE: 'Moraújo' },
    { codigoTOM: 1479, codigoIBGE: 2308906, nomeTOM: 'MORRINHOS', nomeIBGE: 'Morrinhos' },
    { codigoTOM: 1481, codigoIBGE: 2309003, nomeTOM: 'MUCAMBO', nomeIBGE: 'Mucambo' },
    { codigoTOM: 1483, codigoIBGE: 2309102, nomeTOM: 'MULUNGU', nomeIBGE: 'Mulungu' },
    { codigoTOM: 1485, codigoIBGE: 2309201, nomeTOM: 'NOVA OLINDA', nomeIBGE: 'Nova Olinda' },
    { codigoTOM: 1487, codigoIBGE: 2309300, nomeTOM: 'NOVA RUSSAS', nomeIBGE: 'Nova Russas' },
    { codigoTOM: 1489, codigoIBGE: 2309409, nomeTOM: 'NOVO ORIENTE', nomeIBGE: 'Novo Oriente' },
    { codigoTOM: 1491, codigoIBGE: 2309508, nomeTOM: 'ORÓS', nomeIBGE: 'Orós' },
    { codigoTOM: 1493, codigoIBGE: 2309607, nomeTOM: 'PACAJUS', nomeIBGE: 'Pacajus' },
    { codigoTOM: 1495, codigoIBGE: 2309706, nomeTOM: 'PACATUBA', nomeIBGE: 'Pacatuba' },
    { codigoTOM: 1497, codigoIBGE: 2309805, nomeTOM: 'PACOTI', nomeIBGE: 'Pacoti' },
    { codigoTOM: 1499, codigoIBGE: 2309904, nomeTOM: 'PACUJÁ', nomeIBGE: 'Pacujá' },
    { codigoTOM: 1501, codigoIBGE: 2310001, nomeTOM: 'PALHANO', nomeIBGE: 'Palhano' },
    { codigoTOM: 1503, codigoIBGE: 2310100, nomeTOM: 'PALMÁCIA', nomeIBGE: 'Palmácia' },
    { codigoTOM: 1505, codigoIBGE: 2310209, nomeTOM: 'PARACURU', nomeIBGE: 'Paracuru' },
    { codigoTOM: 1507, codigoIBGE: 2310308, nomeTOM: 'PARAMBU', nomeIBGE: 'Parambu' },
    { codigoTOM: 1509, codigoIBGE: 2310407, nomeTOM: 'PARAMOTI', nomeIBGE: 'Paramoti' },
    { codigoTOM: 1511, codigoIBGE: 2310506, nomeTOM: 'PEDRA BRANCA', nomeIBGE: 'Pedra Branca' },
    { codigoTOM: 1513, codigoIBGE: 2310605, nomeTOM: 'PENAFORTE', nomeIBGE: 'Penaforte' },
    { codigoTOM: 1515, codigoIBGE: 2310704, nomeTOM: 'PENTECOSTE', nomeIBGE: 'Pentecoste' },
    { codigoTOM: 1517, codigoIBGE: 2310803, nomeTOM: 'PEREIRO', nomeIBGE: 'Pereiro' },
    { codigoTOM: 1519, codigoIBGE: 2310902, nomeTOM: 'PIQUET CARNEIRO', nomeIBGE: 'Piquet Carneiro' },
    { codigoTOM: 1521, codigoIBGE: 2311009, nomeTOM: 'PORANGA', nomeIBGE: 'Poranga' },
    { codigoTOM: 1523, codigoIBGE: 2311108, nomeTOM: 'PORTEIRAS', nomeIBGE: 'Porteiras' },
    { codigoTOM: 1525, codigoIBGE: 2311207, nomeTOM: 'POTENGI', nomeIBGE: 'Potengi' },
    { codigoTOM: 1527, codigoIBGE: 2311306, nomeTOM: 'QUIXADÁ', nomeIBGE: 'Quixadá' },
    { codigoTOM: 1529, codigoIBGE: 2311405, nomeTOM: 'QUIXERAMOBIM', nomeIBGE: 'Quixeramobim' },
    { codigoTOM: 1531, codigoIBGE: 2311504, nomeTOM: 'QUIXERÉ', nomeIBGE: 'Quixeré' },
    { codigoTOM: 1533, codigoIBGE: 2311603, nomeTOM: 'REDENÇÃO', nomeIBGE: 'Redenção' },
    { codigoTOM: 1535, codigoIBGE: 2311702, nomeTOM: 'RERIUTABA', nomeIBGE: 'Reriutaba' },
    { codigoTOM: 1537, codigoIBGE: 2311801, nomeTOM: 'RUSSAS', nomeIBGE: 'Russas' },
    { codigoTOM: 1539, codigoIBGE: 2311900, nomeTOM: 'SABOEIRO', nomeIBGE: 'Saboeiro' },
    { codigoTOM: 1541, codigoIBGE: 2312007, nomeTOM: 'SANTANA DO ACARAÚ', nomeIBGE: 'Santana do Acaraú' },
    { codigoTOM: 1543, codigoIBGE: 2312106, nomeTOM: 'SANTANA DO CARIRI', nomeIBGE: 'Santana do Cariri' },
    { codigoTOM: 1545, codigoIBGE: 2312205, nomeTOM: 'SANTA QUITÉRIA', nomeIBGE: 'Santa Quitéria' },
    { codigoTOM: 1547, codigoIBGE: 2312304, nomeTOM: 'SÃO BENEDITO', nomeIBGE: 'São Benedito' },
    { codigoTOM: 1549, codigoIBGE: 2312403, nomeTOM: 'SÃO GONÇALO DO AMARANTE', nomeIBGE: 'São Gonçalo do Amarante' },
    { codigoTOM: 1551, codigoIBGE: 2312502, nomeTOM: 'SÃO JOÃO DO JAGUARIBE', nomeIBGE: 'São João do Jaguaribe' },
    { codigoTOM: 1553, codigoIBGE: 2312601, nomeTOM: 'SÃO LUÍS DO CURU', nomeIBGE: 'São Luís do Curu' },
    { codigoTOM: 1555, codigoIBGE: 2312700, nomeTOM: 'SENADOR POMPEU', nomeIBGE: 'Senador Pompeu' },
    { codigoTOM: 1557, codigoIBGE: 2312809, nomeTOM: 'SENADOR SÁ', nomeIBGE: 'Senador Sá' },
    { codigoTOM: 1559, codigoIBGE: 2312908, nomeTOM: 'SOBRAL', nomeIBGE: 'Sobral' },
    { codigoTOM: 1561, codigoIBGE: 2313005, nomeTOM: 'SOLONÓPOLE', nomeIBGE: 'Solonópole' },
    { codigoTOM: 1563, codigoIBGE: 2313104, nomeTOM: 'TABULEIRO DO NORTE', nomeIBGE: 'Tabuleiro do Norte' },
    { codigoTOM: 1565, codigoIBGE: 2313203, nomeTOM: 'TAMBORIL', nomeIBGE: 'Tamboril' },
    { codigoTOM: 1567, codigoIBGE: 2313302, nomeTOM: 'TAUÁ', nomeIBGE: 'Tauá' },
    { codigoTOM: 1569, codigoIBGE: 2313401, nomeTOM: 'TIANGUÁ', nomeIBGE: 'Tianguá' },
    { codigoTOM: 1571, codigoIBGE: 2313500, nomeTOM: 'TRAIRI', nomeIBGE: 'Trairi' },
    { codigoTOM: 1573, codigoIBGE: 2313609, nomeTOM: 'UBAJARA', nomeIBGE: 'Ubajara' },
    { codigoTOM: 1575, codigoIBGE: 2313708, nomeTOM: 'UMARI', nomeIBGE: 'Umari' },
    { codigoTOM: 1577, codigoIBGE: 2313807, nomeTOM: 'URUBURETAMA', nomeIBGE: 'Uruburetama' },
    { codigoTOM: 1579, codigoIBGE: 2313906, nomeTOM: 'URUOCA', nomeIBGE: 'Uruoca' },
    { codigoTOM: 1581, codigoIBGE: 2314003, nomeTOM: 'VÁRZEA ALEGRE', nomeIBGE: 'Várzea Alegre' },
    { codigoTOM: 1583, codigoIBGE: 2314102, nomeTOM: 'VIÇOSA DO CEARÁ', nomeIBGE: 'Viçosa do Ceará' },
    { codigoTOM: 1585, codigoIBGE: 2307650, nomeTOM: 'MARACANAÚ', nomeIBGE: 'Maracanaú' },
    { codigoTOM: 1587, codigoIBGE: 2300754, nomeTOM: 'AMONTADA', nomeIBGE: 'Amontada' },
    { codigoTOM: 1589, codigoIBGE: 2304251, nomeTOM: 'CRUZ', nomeIBGE: 'Cruz' },
    { codigoTOM: 1591, codigoIBGE: 2304350, nomeTOM: 'FORQUILHA', nomeIBGE: 'Forquilha' },
    { codigoTOM: 1593, codigoIBGE: 2305357, nomeTOM: 'ICAPUÍ', nomeIBGE: 'Icapuí' },
    { codigoTOM: 1595, codigoIBGE: 2306553, nomeTOM: 'ITAREMA', nomeIBGE: 'Itarema' },
    { codigoTOM: 1597, codigoIBGE: 2308351, nomeTOM: 'MILHÃ', nomeIBGE: 'Milhã' },
    { codigoTOM: 1599, codigoIBGE: 2310258, nomeTOM: 'PARAIPABA', nomeIBGE: 'Paraipaba' },
    { codigoTOM: 9853, codigoIBGE: 2311355, nomeTOM: 'QUIXELÔ', nomeIBGE: 'Quixelô' },
    { codigoTOM: 9855, codigoIBGE: 2313757, nomeTOM: 'UMIRIM', nomeIBGE: 'Umirim' },
    { codigoTOM: 9857, codigoIBGE: 2313955, nomeTOM: 'VARJOTA', nomeIBGE: 'Varjota' },
    { codigoTOM: 9917, codigoIBGE: 2311264, nomeTOM: 'QUITERIANÓPOLIS', nomeIBGE: 'Quiterianópolis' },
  ],
  [Estados.SE]: [
    { codigoTOM: 2647, codigoIBGE: 2806404, nomeTOM: 'SANTANA DO SÃO FRANCISCO', nomeIBGE: 'Santana do São Francisco' },
    { codigoTOM: 3101, codigoIBGE: 2800100, nomeTOM: 'AMPARO DE SÃO FRANCISCO', nomeIBGE: 'Amparo do São Francisco' },
    { codigoTOM: 3103, codigoIBGE: 2800209, nomeTOM: 'AQUIDABÃ', nomeIBGE: 'Aquidabã' },
    { codigoTOM: 3105, codigoIBGE: 2800308, nomeTOM: 'ARACAJU', nomeIBGE: 'Aracaju' },
    { codigoTOM: 3107, codigoIBGE: 2800407, nomeTOM: 'ARAUÁ', nomeIBGE: 'Arauá' },
    { codigoTOM: 3109, codigoIBGE: 2800506, nomeTOM: 'AREIA BRANCA', nomeIBGE: 'Areia Branca' },
    { codigoTOM: 3111, codigoIBGE: 2800605, nomeTOM: 'BARRA DOS COQUEIROS', nomeIBGE: 'Barra dos Coqueiros' },
    { codigoTOM: 3113, codigoIBGE: 2800704, nomeTOM: 'BREJO GRANDE', nomeIBGE: 'Brejo Grande' },
    { codigoTOM: 3115, codigoIBGE: 2800670, nomeTOM: 'BOQUIM', nomeIBGE: 'Boquim' },
    { codigoTOM: 3119, codigoIBGE: 2801009, nomeTOM: 'CAMPO DO BRITO', nomeIBGE: 'Campo do Brito' },
    { codigoTOM: 3121, codigoIBGE: 2801108, nomeTOM: 'CANHOBA', nomeIBGE: 'Canhoba' },
    { codigoTOM: 3123, codigoIBGE: 2801207, nomeTOM: 'CANINDÉ DE SÃO FRANCISCO', nomeIBGE: 'Canindé de São Francisco' },
    { codigoTOM: 3125, codigoIBGE: 2801306, nomeTOM: 'CAPELA', nomeIBGE: 'Capela' },
    { codigoTOM: 3127, codigoIBGE: 2801405, nomeTOM: 'CARIRA', nomeIBGE: 'Carira' },
    { codigoTOM: 3129, codigoIBGE: 2801504, nomeTOM: 'CARMÓPOLIS', nomeIBGE: 'Carmópolis' },
    { codigoTOM: 3131, codigoIBGE: 2801603, nomeTOM: 'CEDRO DE SÃO JOÃO', nomeIBGE: 'Cedro de São João' },
    { codigoTOM: 3133, codigoIBGE: 2801702, nomeTOM: 'CRISTINÁPOLIS', nomeIBGE: 'Cristinápolis' },
    { codigoTOM: 3135, codigoIBGE: 2804458, nomeTOM: 'NOSSA SENHORA APARECIDA', nomeIBGE: 'Nossa Senhora Aparecida' },
    { codigoTOM: 3137, codigoIBGE: 2801900, nomeTOM: 'CUMBE', nomeIBGE: 'Cumbe' },
    { codigoTOM: 3139, codigoIBGE: 2802007, nomeTOM: 'DIVINA PASTORA', nomeIBGE: 'Divina Pastora' },
    { codigoTOM: 3141, codigoIBGE: 2802106, nomeTOM: 'ESTÂNCIA', nomeIBGE: 'Estância' },
    { codigoTOM: 3143, codigoIBGE: 2802205, nomeTOM: 'FEIRA NOVA', nomeIBGE: 'Feira Nova' },
    { codigoTOM: 3145, codigoIBGE: 2802304, nomeTOM: 'FREI PAULO', nomeIBGE: 'Frei Paulo' },
    { codigoTOM: 3147, codigoIBGE: 2802502, nomeTOM: 'GENERAL MAYNARD', nomeIBGE: 'General Maynard' },
    { codigoTOM: 3149, codigoIBGE: 2802403, nomeTOM: 'GARARU', nomeIBGE: 'Gararu' },
    { codigoTOM: 3151, codigoIBGE: 2802601, nomeTOM: 'GRACCHO CARDOSO', nomeIBGE: 'Gracho Cardoso' },
    { codigoTOM: 3153, codigoIBGE: 2802700, nomeTOM: 'ILHA DAS FLORES', nomeIBGE: 'Ilha das Flores' },
    { codigoTOM: 3155, codigoIBGE: 2802809, nomeTOM: 'INDIAROBA', nomeIBGE: 'Indiaroba' },
    { codigoTOM: 3157, codigoIBGE: 2802908, nomeTOM: 'ITABAIANA', nomeIBGE: 'Itabaiana' },
    { codigoTOM: 3159, codigoIBGE: 2803005, nomeTOM: 'ITABAIANINHA', nomeIBGE: 'Itabaianinha' },
    { codigoTOM: 3161, codigoIBGE: 2803104, nomeTOM: 'ITABI', nomeIBGE: 'Itabi' },
    { codigoTOM: 3163, codigoIBGE: 2803203, nomeTOM: "ITAPORANGA D'AJUDA", nomeIBGE: "Itaporanga d'Ajuda" },
    { codigoTOM: 3165, codigoIBGE: 2803302, nomeTOM: 'JAPARATUBA', nomeIBGE: 'Japaratuba' },
    { codigoTOM: 3167, codigoIBGE: 2803401, nomeTOM: 'JAPOATÃ', nomeIBGE: 'Japoatã' },
    { codigoTOM: 3169, codigoIBGE: 2803500, nomeTOM: 'LAGARTO', nomeIBGE: 'Lagarto' },
    { codigoTOM: 3171, codigoIBGE: 2803609, nomeTOM: 'LARANJEIRAS', nomeIBGE: 'Laranjeiras' },
    { codigoTOM: 3173, codigoIBGE: 2803708, nomeTOM: 'MACAMBIRA', nomeIBGE: 'Macambira' },
    { codigoTOM: 3175, codigoIBGE: 2803807, nomeTOM: 'MALHADA DOS BOIS', nomeIBGE: 'Malhada dos Bois' },
    { codigoTOM: 3177, codigoIBGE: 2803906, nomeTOM: 'MALHADOR', nomeIBGE: 'Malhador' },
    { codigoTOM: 3179, codigoIBGE: 2804003, nomeTOM: 'MARUIM', nomeIBGE: 'Maruim' },
    { codigoTOM: 3181, codigoIBGE: 2804102, nomeTOM: 'MOITA BONITA', nomeIBGE: 'Moita Bonita' },
    { codigoTOM: 3183, codigoIBGE: 2804201, nomeTOM: 'MONTE ALEGRE DE SERGIPE', nomeIBGE: 'Monte Alegre de Sergipe' },
    { codigoTOM: 3185, codigoIBGE: 2804300, nomeTOM: 'MURIBECA', nomeIBGE: 'Muribeca' },
    { codigoTOM: 3187, codigoIBGE: 2804409, nomeTOM: 'NEÓPOLIS', nomeIBGE: 'Neópolis' },
    { codigoTOM: 3189, codigoIBGE: 2804508, nomeTOM: 'NOSSA SENHORA DA GLÓRIA', nomeIBGE: 'Nossa Senhora da Glória' },
    { codigoTOM: 3191, codigoIBGE: 2804607, nomeTOM: 'NOSSA SENHORA DAS DORES', nomeIBGE: 'Nossa Senhora das Dores' },
    { codigoTOM: 3193, codigoIBGE: 2804706, nomeTOM: 'NOSSA SENHORA DE LOURDES', nomeIBGE: 'Nossa Senhora de Lourdes' },
    { codigoTOM: 3195, codigoIBGE: 2804805, nomeTOM: 'NOSSA SENHORA DO SOCORRO', nomeIBGE: 'Nossa Senhora do Socorro' },
    { codigoTOM: 3197, codigoIBGE: 2804904, nomeTOM: 'PACATUBA', nomeIBGE: 'Pacatuba' },
    { codigoTOM: 3199, codigoIBGE: 2805000, nomeTOM: 'PEDRA MOLE', nomeIBGE: 'Pedra Mole' },
    { codigoTOM: 3201, codigoIBGE: 2805109, nomeTOM: 'PEDRINHAS', nomeIBGE: 'Pedrinhas' },
    { codigoTOM: 3203, codigoIBGE: 2805208, nomeTOM: 'PINHÃO', nomeIBGE: 'Pinhão' },
    { codigoTOM: 3205, codigoIBGE: 2805307, nomeTOM: 'PIRAMBU', nomeIBGE: 'Pirambu' },
    { codigoTOM: 3207, codigoIBGE: 2805406, nomeTOM: 'POÇO REDONDO', nomeIBGE: 'Poço Redondo' },
    { codigoTOM: 3209, codigoIBGE: 2805505, nomeTOM: 'POÇO VERDE', nomeIBGE: 'Poço Verde' },
    { codigoTOM: 3211, codigoIBGE: 2805604, nomeTOM: 'PORTO DA FOLHA', nomeIBGE: 'Porto da Folha' },
    { codigoTOM: 3213, codigoIBGE: 2805703, nomeTOM: 'PROPRIÁ', nomeIBGE: 'Propriá' },
    { codigoTOM: 3215, codigoIBGE: 2805802, nomeTOM: 'RIACHÃO DO DANTAS', nomeIBGE: 'Riachão do Dantas' },
    { codigoTOM: 3217, codigoIBGE: 2805901, nomeTOM: 'RIACHUELO', nomeIBGE: 'Riachuelo' },
    { codigoTOM: 3219, codigoIBGE: 2806008, nomeTOM: 'RIBEIRÓPOLIS', nomeIBGE: 'Ribeirópolis' },
    { codigoTOM: 3221, codigoIBGE: 2806107, nomeTOM: 'ROSÁRIO DO CATETE', nomeIBGE: 'Rosário do Catete' },
    { codigoTOM: 3223, codigoIBGE: 2806206, nomeTOM: 'SALGADO', nomeIBGE: 'Salgado' },
    { codigoTOM: 3225, codigoIBGE: 2806305, nomeTOM: 'SANTA LUZIA DO ITANHY', nomeIBGE: 'Santa Luzia do Itanhy' },
    { codigoTOM: 3229, codigoIBGE: 2806503, nomeTOM: 'SANTA ROSA DE LIMA', nomeIBGE: 'Santa Rosa de Lima' },
    { codigoTOM: 3231, codigoIBGE: 2806602, nomeTOM: 'SANTO AMARO DAS BROTAS', nomeIBGE: 'Santo Amaro das Brotas' },
    { codigoTOM: 3233, codigoIBGE: 2806701, nomeTOM: 'SÃO CRISTÓVÃO', nomeIBGE: 'São Cristóvão' },
    { codigoTOM: 3235, codigoIBGE: 2806800, nomeTOM: 'SÃO DOMINGOS', nomeIBGE: 'São Domingos' },
    { codigoTOM: 3237, codigoIBGE: 2806909, nomeTOM: 'SÃO FRANCISCO', nomeIBGE: 'São Francisco' },
    { codigoTOM: 3239, codigoIBGE: 2807006, nomeTOM: 'SÃO MIGUEL DO ALEIXO', nomeIBGE: 'São Miguel do Aleixo' },
    { codigoTOM: 3241, codigoIBGE: 2807105, nomeTOM: 'SIMÃO DIAS', nomeIBGE: 'Simão Dias' },
    { codigoTOM: 3243, codigoIBGE: 2807204, nomeTOM: 'SIRIRI', nomeIBGE: 'Siriri' },
    { codigoTOM: 3245, codigoIBGE: 2807303, nomeTOM: 'TELHA', nomeIBGE: 'Telha' },
    { codigoTOM: 3247, codigoIBGE: 2807402, nomeTOM: 'TOBIAS BARRETO', nomeIBGE: 'Tobias Barreto' },
    { codigoTOM: 3249, codigoIBGE: 2807501, nomeTOM: 'TOMAR DO GERU', nomeIBGE: 'Tomar do Geru' },
    { codigoTOM: 3251, codigoIBGE: 2807600, nomeTOM: 'UMBAÚBA', nomeIBGE: 'Umbaúba' },
  ],
  [Estados.BA]: [
    { codigoTOM: 3005, codigoIBGE: 2922250, nomeTOM: 'Muquém de São Francisco', nomeIBGE: 'Muquém do São Francisco' },
    { codigoTOM: 3007, codigoIBGE: 2922730, nomeTOM: 'NOVA FÁTIMA', nomeIBGE: 'Nova Fátima' },
    { codigoTOM: 3009, codigoIBGE: 2922755, nomeTOM: 'NOVA IBIÁ', nomeIBGE: 'Nova Ibiá' },
    { codigoTOM: 3011, codigoIBGE: 2922854, nomeTOM: 'NOVA REDENÇÃO', nomeIBGE: 'Nova Redenção' },
    { codigoTOM: 3013, codigoIBGE: 2923035, nomeTOM: 'NOVO HORIZONTE', nomeIBGE: 'Novo Horizonte' },
    { codigoTOM: 3015, codigoIBGE: 2923050, nomeTOM: 'NOVO TRIUNFO', nomeIBGE: 'Novo Triunfo' },
    { codigoTOM: 3017, codigoIBGE: 2923357, nomeTOM: 'OUROLÂNDIA', nomeIBGE: 'Ourolândia' },
    { codigoTOM: 3019, codigoIBGE: 2924678, nomeTOM: 'PIRAÍ DO NORTE', nomeIBGE: 'Piraí do Norte' },
    { codigoTOM: 3021, codigoIBGE: 2925253, nomeTOM: 'PONTO NOVO', nomeIBGE: 'Ponto Novo' },
    {
      codigoTOM: 3023,
      codigoIBGE: 2925758,
      nomeTOM: 'PRESIDENTE TANCREDO NEVES',
      nomeIBGE: 'Presidente Tancredo Neves',
    },
    { codigoTOM: 3025, codigoIBGE: 2925931, nomeTOM: 'QUIXABEIRA', nomeIBGE: 'Quixabeira' },
    { codigoTOM: 3027, codigoIBGE: 2926657, nomeTOM: 'RIBEIRÃO DO LARGO', nomeIBGE: 'Ribeirão do Largo' },
    { codigoTOM: 3029, codigoIBGE: 2928950, nomeTOM: 'SÃO DOMINGOS', nomeIBGE: 'São Domingos' },
    { codigoTOM: 3031, codigoIBGE: 2929057, nomeTOM: 'SÃO FÉLIX DO CORIBE', nomeIBGE: 'São Félix do Coribe' },
    { codigoTOM: 3033, codigoIBGE: 2929370, nomeTOM: 'SÃO JOSÉ DO JACUÍPE', nomeIBGE: 'São José do Jacuípe' },
    { codigoTOM: 3035, codigoIBGE: 2929354, nomeTOM: 'SÃO JOSÉ DA VITÓRIA', nomeIBGE: 'São José da Vitória' },
    { codigoTOM: 3037, codigoIBGE: 2929750, nomeTOM: 'SAUBARA', nomeIBGE: 'Saubara' },
    { codigoTOM: 3039, codigoIBGE: 2930154, nomeTOM: 'SERRA DO RAMALHO', nomeIBGE: 'Serra do Ramalho' },
    { codigoTOM: 3041, codigoIBGE: 2930758, nomeTOM: 'SÍTIO DO MATO', nomeIBGE: 'Sítio do Mato' },
    { codigoTOM: 3043, codigoIBGE: 2930766, nomeTOM: 'SÍTIO DO QUINTO', nomeIBGE: 'Sítio do Quinto' },
    { codigoTOM: 3045, codigoIBGE: 2930774, nomeTOM: 'SOBRADINHO', nomeIBGE: 'Sobradinho' },
    { codigoTOM: 3047, codigoIBGE: 2932457, nomeTOM: 'UMBURANAS', nomeIBGE: 'Umburanas' },
    { codigoTOM: 3049, codigoIBGE: 2933174, nomeTOM: 'VARZEDO', nomeIBGE: 'Varzedo' },
    { codigoTOM: 3051, codigoIBGE: 2933257, nomeTOM: 'VEREDA', nomeIBGE: 'Vereda' },
    { codigoTOM: 3069, codigoIBGE: 2918555, nomeTOM: 'JUSSARI', nomeIBGE: 'Jussari' },
    { codigoTOM: 3071, codigoIBGE: 2901155, nomeTOM: 'AMÉRICA DOURADA', nomeIBGE: 'América Dourada' },
    { codigoTOM: 3073, codigoIBGE: 2902252, nomeTOM: 'ARATACA', nomeIBGE: 'Arataca' },
    { codigoTOM: 3075, codigoIBGE: 2903235, nomeTOM: 'BARRO ALTO', nomeIBGE: 'Barro Alto' },
    { codigoTOM: 3079, codigoIBGE: 2904753, nomeTOM: 'BURITIRAMA', nomeIBGE: 'Buritirama' },
    { codigoTOM: 3081, codigoIBGE: 2906857, nomeTOM: 'CAPELA DO ALTO ALEGRE', nomeIBGE: 'Capela do Alto Alegre' },
    { codigoTOM: 3083, codigoIBGE: 2906873, nomeTOM: 'CAPIM GROSSO', nomeIBGE: 'Capim Grosso' },
    { codigoTOM: 3085, codigoIBGE: 2906824, nomeTOM: 'CANUDOS', nomeIBGE: 'Canudos' },
    { codigoTOM: 3087, codigoIBGE: 2910057, nomeTOM: "DIAS D'ÁVILA", nomeIBGE: "Dias d'Ávila" },
    { codigoTOM: 3089, codigoIBGE: 2910750, nomeTOM: 'FÁTIMA', nomeIBGE: 'Fátima' },
    { codigoTOM: 3091, codigoIBGE: 2910859, nomeTOM: 'FILADÉLFIA', nomeIBGE: 'Filadélfia' },
    { codigoTOM: 3093, codigoIBGE: 2911253, nomeTOM: 'GAVIÃO', nomeIBGE: 'Gavião' },
    { codigoTOM: 3095, codigoIBGE: 2911659, nomeTOM: 'GUAJERU', nomeIBGE: 'Guajeru' },
    { codigoTOM: 3097, codigoIBGE: 2911857, nomeTOM: 'HELIÓPOLIS', nomeIBGE: 'Heliópolis' },
    { codigoTOM: 3099, codigoIBGE: 2918357, nomeTOM: 'JOÃO DOURADO', nomeIBGE: 'João Dourado' },
    { codigoTOM: 3117, codigoIBGE: 2910727, nomeTOM: 'EUNÁPOLIS', nomeIBGE: 'Eunápolis' },
    { codigoTOM: 3253, codigoIBGE: 2900355, nomeTOM: 'ADUSTINA', nomeIBGE: 'Adustina' },
    { codigoTOM: 3255, codigoIBGE: 2901353, nomeTOM: 'ANDORINHA', nomeIBGE: 'Andorinha' },
    { codigoTOM: 3257, codigoIBGE: 2901957, nomeTOM: 'APUAREMA', nomeIBGE: 'Apuarema' },
    { codigoTOM: 3259, codigoIBGE: 2902054, nomeTOM: 'ARAÇAS', nomeIBGE: 'Araçás' },
    { codigoTOM: 3261, codigoIBGE: 2902658, nomeTOM: 'BANZAÊ', nomeIBGE: 'Banzaê' },
    { codigoTOM: 3263, codigoIBGE: 2903953, nomeTOM: 'BOM JESUS DA SERRA', nomeIBGE: 'Bom Jesus da Serra' },
    { codigoTOM: 3265, codigoIBGE: 2904050, nomeTOM: 'BONITO', nomeIBGE: 'Bonito' },
    { codigoTOM: 3267, codigoIBGE: 2904852, nomeTOM: 'CABACEIRAS DO PARAGUAÇU', nomeIBGE: 'Cabaceiras do Paraguaçu' },
    { codigoTOM: 3269, codigoIBGE: 2905156, nomeTOM: 'CAETANOS', nomeIBGE: 'Caetanos' },
    { codigoTOM: 3271, codigoIBGE: 2906899, nomeTOM: 'CARAÍBAS', nomeIBGE: 'Caraíbas' },
    { codigoTOM: 3273, codigoIBGE: 2907558, nomeTOM: 'CATURAMA', nomeIBGE: 'Caturama' },
    { codigoTOM: 3275, codigoIBGE: 2910776, nomeTOM: 'FEIRA DA MATA', nomeIBGE: 'Feira da Mata' },
    { codigoTOM: 3277, codigoIBGE: 2913457, nomeTOM: 'IGRAPIÚNA', nomeIBGE: 'Igrapiúna' },
    { codigoTOM: 3279, codigoIBGE: 2914653, nomeTOM: 'ITABELA', nomeIBGE: 'Itabela' },
    { codigoTOM: 3281, codigoIBGE: 2915353, nomeTOM: 'ITAGUAÇU DA BAHIA', nomeIBGE: 'Itaguaçu da Bahia' },
    { codigoTOM: 3283, codigoIBGE: 2916856, nomeTOM: 'ITATIM', nomeIBGE: 'Itatim' },
    { codigoTOM: 3285, codigoIBGE: 2917334, nomeTOM: 'IUIÚ', nomeIBGE: 'Iuiu' },
    { codigoTOM: 3287, codigoIBGE: 2918456, nomeTOM: 'JUCURUÇU', nomeIBGE: 'Jucuruçu' },
    { codigoTOM: 3289, codigoIBGE: 2918753, nomeTOM: 'LAGOA REAL', nomeIBGE: 'Lagoa Real' },
    { codigoTOM: 3291, codigoIBGE: 2919058, nomeTOM: 'Lajedo do Tabocal', nomeIBGE: 'Lajedo do Tabocal' },
    { codigoTOM: 3293, codigoIBGE: 2919926, nomeTOM: 'MADRE DE DEUS', nomeIBGE: 'Madre de Deus' },
    { codigoTOM: 3295, codigoIBGE: 2921054, nomeTOM: 'MATINA', nomeIBGE: 'Matina' },
    { codigoTOM: 3297, codigoIBGE: 2921450, nomeTOM: 'MIRANTE', nomeIBGE: 'Mirante' },
    { codigoTOM: 3299, codigoIBGE: 2922052, nomeTOM: 'MULUNGU DO MORRO', nomeIBGE: 'Mulungu do Morro' },
    { codigoTOM: 3301, codigoIBGE: 2900108, nomeTOM: 'ABAÍRA', nomeIBGE: 'Abaíra' },
    { codigoTOM: 3303, codigoIBGE: 2900207, nomeTOM: 'ABARÉ', nomeIBGE: 'Abaré' },
    { codigoTOM: 3305, codigoIBGE: 2900306, nomeTOM: 'ACAJUTIBA', nomeIBGE: 'Acajutiba' },
    { codigoTOM: 3307, codigoIBGE: 2900405, nomeTOM: 'ÁGUA FRIA', nomeIBGE: 'Água Fria' },
    { codigoTOM: 3309, codigoIBGE: 2900504, nomeTOM: 'ÉRICO CARDOSO', nomeIBGE: 'Érico Cardoso' },
    { codigoTOM: 3311, codigoIBGE: 2900603, nomeTOM: 'AIQUARA', nomeIBGE: 'Aiquara' },
    { codigoTOM: 3313, codigoIBGE: 2900702, nomeTOM: 'ALAGOINHAS', nomeIBGE: 'Alagoinhas' },
    { codigoTOM: 3315, codigoIBGE: 2900801, nomeTOM: 'ALCOBAÇA', nomeIBGE: 'Alcobaça' },
    { codigoTOM: 3317, codigoIBGE: 2900900, nomeTOM: 'ALMADINA', nomeIBGE: 'Almadina' },
    { codigoTOM: 3319, codigoIBGE: 2901007, nomeTOM: 'AMARGOSA', nomeIBGE: 'Amargosa' },
    { codigoTOM: 3321, codigoIBGE: 2901106, nomeTOM: 'AMÉLIA RODRIGUES', nomeIBGE: 'Amélia Rodrigues' },
    { codigoTOM: 3323, codigoIBGE: 2901205, nomeTOM: 'ANAGÉ', nomeIBGE: 'Anagé' },
    { codigoTOM: 3325, codigoIBGE: 2901304, nomeTOM: 'ANDARAÍ', nomeIBGE: 'Andaraí' },
    { codigoTOM: 3327, codigoIBGE: 2901403, nomeTOM: 'ANGICAL', nomeIBGE: 'Angical' },
    { codigoTOM: 3329, codigoIBGE: 2901502, nomeTOM: 'ANGUERA', nomeIBGE: 'Anguera' },
    { codigoTOM: 3331, codigoIBGE: 2901601, nomeTOM: 'ANTAS', nomeIBGE: 'Antas' },
    { codigoTOM: 3333, codigoIBGE: 2901700, nomeTOM: 'ANTÔNIO CARDOSO', nomeIBGE: 'Antônio Cardoso' },
    { codigoTOM: 3335, codigoIBGE: 2901809, nomeTOM: 'ANTÔNIO GONÇALVES', nomeIBGE: 'Antônio Gonçalves' },
    { codigoTOM: 3337, codigoIBGE: 2901908, nomeTOM: 'APORÁ', nomeIBGE: 'Aporá' },
    { codigoTOM: 3339, codigoIBGE: 2902005, nomeTOM: 'ARACATU', nomeIBGE: 'Aracatu' },
    { codigoTOM: 3341, codigoIBGE: 2902104, nomeTOM: 'ARACI', nomeIBGE: 'Araci' },
    { codigoTOM: 3343, codigoIBGE: 2902203, nomeTOM: 'ARAMARI', nomeIBGE: 'Aramari' },
    { codigoTOM: 3345, codigoIBGE: 2902302, nomeTOM: 'ARATUÍPE', nomeIBGE: 'Aratuípe' },
    { codigoTOM: 3347, codigoIBGE: 2902401, nomeTOM: 'AURELINO LEAL', nomeIBGE: 'Aurelino Leal' },
    { codigoTOM: 3349, codigoIBGE: 2902500, nomeTOM: 'BAIANÓPOLIS', nomeIBGE: 'Baianópolis' },
    { codigoTOM: 3351, codigoIBGE: 2902609, nomeTOM: 'BAIXA GRANDE', nomeIBGE: 'Baixa Grande' },
    { codigoTOM: 3353, codigoIBGE: 2902708, nomeTOM: 'BARRA', nomeIBGE: 'Barra' },
    { codigoTOM: 3355, codigoIBGE: 2902807, nomeTOM: 'BARRA DA ESTIVA', nomeIBGE: 'Barra da Estiva' },
    { codigoTOM: 3357, codigoIBGE: 2902906, nomeTOM: 'BARRA DO CHOÇA', nomeIBGE: 'Barra do Choça' },
    { codigoTOM: 3359, codigoIBGE: 2903003, nomeTOM: 'BARRA DO MENDES', nomeIBGE: 'Barra do Mendes' },
    { codigoTOM: 3361, codigoIBGE: 2903102, nomeTOM: 'BARRA DO ROCHA', nomeIBGE: 'Barra do Rocha' },
    { codigoTOM: 3363, codigoIBGE: 2903201, nomeTOM: 'BARREIRAS', nomeIBGE: 'Barreiras' },
    { codigoTOM: 3365, codigoIBGE: 2903300, nomeTOM: 'BARRO PRETO', nomeIBGE: 'Barro Preto' },
    { codigoTOM: 3367, codigoIBGE: 2903409, nomeTOM: 'BELMONTE', nomeIBGE: 'Belmonte' },
    { codigoTOM: 3369, codigoIBGE: 2903508, nomeTOM: 'BELO CAMPO', nomeIBGE: 'Belo Campo' },
    { codigoTOM: 3371, codigoIBGE: 2903607, nomeTOM: 'BIRITINGA', nomeIBGE: 'Biritinga' },
    { codigoTOM: 3373, codigoIBGE: 2903706, nomeTOM: 'BOA NOVA', nomeIBGE: 'Boa Nova' },
    { codigoTOM: 3375, codigoIBGE: 2903805, nomeTOM: 'BOA VISTA DO TUPIM', nomeIBGE: 'Boa Vista do Tupim' },
    { codigoTOM: 3377, codigoIBGE: 2903904, nomeTOM: 'BOM JESUS DA LAPA', nomeIBGE: 'Bom Jesus da Lapa' },
    { codigoTOM: 3379, codigoIBGE: 2904001, nomeTOM: 'BONINAL', nomeIBGE: 'Boninal' },
    { codigoTOM: 3381, codigoIBGE: 2904100, nomeTOM: 'BOQUIRA', nomeIBGE: 'Boquira' },
    { codigoTOM: 3383, codigoIBGE: 2904209, nomeTOM: 'BOTUPORÃ', nomeIBGE: 'Botuporã' },
    { codigoTOM: 3385, codigoIBGE: 2904308, nomeTOM: 'BREJÕES', nomeIBGE: 'Brejões' },
    { codigoTOM: 3387, codigoIBGE: 2904407, nomeTOM: 'BREJOLÂNDIA', nomeIBGE: 'Brejolândia' },
    { codigoTOM: 3389, codigoIBGE: 2904506, nomeTOM: 'BROTAS DE MACAÚBAS', nomeIBGE: 'Brotas de Macaúbas' },
    { codigoTOM: 3391, codigoIBGE: 2904605, nomeTOM: 'BRUMADO', nomeIBGE: 'Brumado' },
    { codigoTOM: 3393, codigoIBGE: 2904704, nomeTOM: 'BUERAREMA', nomeIBGE: 'Buerarema' },
    { codigoTOM: 3395, codigoIBGE: 2904803, nomeTOM: 'CAATIBA', nomeIBGE: 'Caatiba' },
    { codigoTOM: 3397, codigoIBGE: 2904902, nomeTOM: 'CACHOEIRA', nomeIBGE: 'Cachoeira' },
    { codigoTOM: 3399, codigoIBGE: 2905008, nomeTOM: 'CACULÉ', nomeIBGE: 'Caculé' },
    { codigoTOM: 3401, codigoIBGE: 2905107, nomeTOM: 'CAÉM', nomeIBGE: 'Caém' },
    { codigoTOM: 3403, codigoIBGE: 2905206, nomeTOM: 'CAETITÉ', nomeIBGE: 'Caetité' },
    { codigoTOM: 3405, codigoIBGE: 2905305, nomeTOM: 'CAFARNAUM', nomeIBGE: 'Cafarnaum' },
    { codigoTOM: 3407, codigoIBGE: 2905404, nomeTOM: 'CAIRU', nomeIBGE: 'Cairu' },
    { codigoTOM: 3409, codigoIBGE: 2905503, nomeTOM: 'CALDEIRÃO GRANDE', nomeIBGE: 'Caldeirão Grande' },
    { codigoTOM: 3411, codigoIBGE: 2905602, nomeTOM: 'CAMACAN', nomeIBGE: 'Camacan' },
    { codigoTOM: 3413, codigoIBGE: 2905701, nomeTOM: 'CAMAÇARI', nomeIBGE: 'Camaçari' },
    { codigoTOM: 3415, codigoIBGE: 2905800, nomeTOM: 'CAMAMU', nomeIBGE: 'Camamu' },
    { codigoTOM: 3417, codigoIBGE: 2905909, nomeTOM: 'CAMPO ALEGRE DE LOURDES', nomeIBGE: 'Campo Alegre de Lourdes' },
    { codigoTOM: 3419, codigoIBGE: 2906006, nomeTOM: 'CAMPO FORMOSO', nomeIBGE: 'Campo Formoso' },
    { codigoTOM: 3421, codigoIBGE: 2906105, nomeTOM: 'CANÁPOLIS', nomeIBGE: 'Canápolis' },
    { codigoTOM: 3423, codigoIBGE: 2906204, nomeTOM: 'CANARANA', nomeIBGE: 'Canarana' },
    { codigoTOM: 3425, codigoIBGE: 2906303, nomeTOM: 'CANAVIEIRAS', nomeIBGE: 'Canavieiras' },
    { codigoTOM: 3427, codigoIBGE: 2906402, nomeTOM: 'CANDEAL', nomeIBGE: 'Candeal' },
    { codigoTOM: 3429, codigoIBGE: 2906501, nomeTOM: 'CANDEIAS', nomeIBGE: 'Candeias' },
    { codigoTOM: 3431, codigoIBGE: 2906600, nomeTOM: 'CANDIBA', nomeIBGE: 'Candiba' },
    { codigoTOM: 3433, codigoIBGE: 2906709, nomeTOM: 'CÂNDIDO SALES', nomeIBGE: 'Cândido Sales' },
    { codigoTOM: 3435, codigoIBGE: 2906808, nomeTOM: 'CANSANÇÃO', nomeIBGE: 'Cansanção' },
    { codigoTOM: 3437, codigoIBGE: 2906907, nomeTOM: 'CARAVELAS', nomeIBGE: 'Caravelas' },
    { codigoTOM: 3439, codigoIBGE: 2907004, nomeTOM: 'CARDEAL DA SILVA', nomeIBGE: 'Cardeal da Silva' },
    { codigoTOM: 3441, codigoIBGE: 2907103, nomeTOM: 'CARINHANHA', nomeIBGE: 'Carinhanha' },
    { codigoTOM: 3443, codigoIBGE: 2907202, nomeTOM: 'CASA NOVA', nomeIBGE: 'Casa Nova' },
    { codigoTOM: 3445, codigoIBGE: 2907301, nomeTOM: 'CASTRO ALVES', nomeIBGE: 'Castro Alves' },
    { codigoTOM: 3447, codigoIBGE: 2907400, nomeTOM: 'CATOLÂNDIA', nomeIBGE: 'Catolândia' },
    { codigoTOM: 3449, codigoIBGE: 2907509, nomeTOM: 'CATU', nomeIBGE: 'Catu' },
    { codigoTOM: 3451, codigoIBGE: 2907608, nomeTOM: 'CENTRAL', nomeIBGE: 'Central' },
    { codigoTOM: 3453, codigoIBGE: 2907707, nomeTOM: 'CHORROCHÓ', nomeIBGE: 'Chorrochó' },
    { codigoTOM: 3455, codigoIBGE: 2907806, nomeTOM: 'CÍCERO DANTAS', nomeIBGE: 'Cícero Dantas' },
    { codigoTOM: 3457, codigoIBGE: 2907905, nomeTOM: 'CIPÓ', nomeIBGE: 'Cipó' },
    { codigoTOM: 3459, codigoIBGE: 2908002, nomeTOM: 'COARACI', nomeIBGE: 'Coaraci' },
    { codigoTOM: 3461, codigoIBGE: 2908101, nomeTOM: 'COCOS', nomeIBGE: 'Cocos' },
    { codigoTOM: 3463, codigoIBGE: 2908200, nomeTOM: 'CONCEIÇÃO DA FEIRA', nomeIBGE: 'Conceição da Feira' },
    { codigoTOM: 3465, codigoIBGE: 2908309, nomeTOM: 'CONCEIÇÃO DO ALMEIDA', nomeIBGE: 'Conceição do Almeida' },
    { codigoTOM: 3467, codigoIBGE: 2908408, nomeTOM: 'CONCEIÇÃO DO COITÉ', nomeIBGE: 'Conceição do Coité' },
    { codigoTOM: 3469, codigoIBGE: 2908507, nomeTOM: 'CONCEIÇÃO DO JACUÍPE', nomeIBGE: 'Conceição do Jacuípe' },
    { codigoTOM: 3471, codigoIBGE: 2908606, nomeTOM: 'CONDE', nomeIBGE: 'Conde' },
    { codigoTOM: 3473, codigoIBGE: 2908705, nomeTOM: 'CONDEÚBA', nomeIBGE: 'Condeúba' },
    { codigoTOM: 3475, codigoIBGE: 2908804, nomeTOM: 'CONTENDAS DO SINCORÁ', nomeIBGE: 'Contendas do Sincorá' },
    { codigoTOM: 3477, codigoIBGE: 2908903, nomeTOM: 'CORAÇÃO DE MARIA', nomeIBGE: 'Coração de Maria' },
    { codigoTOM: 3479, codigoIBGE: 2909000, nomeTOM: 'CORDEIROS', nomeIBGE: 'Cordeiros' },
    { codigoTOM: 3481, codigoIBGE: 2909109, nomeTOM: 'CORIBE', nomeIBGE: 'Coribe' },
    { codigoTOM: 3483, codigoIBGE: 2909208, nomeTOM: 'CORONEL JOÃO SÁ', nomeIBGE: 'Coronel João Sá' },
    { codigoTOM: 3485, codigoIBGE: 2909307, nomeTOM: 'CORRENTINA', nomeIBGE: 'Correntina' },
    { codigoTOM: 3487, codigoIBGE: 2909406, nomeTOM: 'COTEGIPE', nomeIBGE: 'Cotegipe' },
    { codigoTOM: 3489, codigoIBGE: 2909505, nomeTOM: 'CRAVOLÂNDIA', nomeIBGE: 'Cravolândia' },
    { codigoTOM: 3491, codigoIBGE: 2909604, nomeTOM: 'CRISÓPOLIS', nomeIBGE: 'Crisópolis' },
    { codigoTOM: 3493, codigoIBGE: 2909703, nomeTOM: 'CRISTÓPOLIS', nomeIBGE: 'Cristópolis' },
    { codigoTOM: 3495, codigoIBGE: 2909802, nomeTOM: 'CRUZ DAS ALMAS', nomeIBGE: 'Cruz das Almas' },
    { codigoTOM: 3497, codigoIBGE: 2909901, nomeTOM: 'CURAÇÁ', nomeIBGE: 'Curaçá' },
    { codigoTOM: 3499, codigoIBGE: 2910008, nomeTOM: 'DÁRIO MEIRA', nomeIBGE: 'Dário Meira' },
    { codigoTOM: 3501, codigoIBGE: 2910107, nomeTOM: 'DOM BASÍLIO', nomeIBGE: 'Dom Basílio' },
    { codigoTOM: 3503, codigoIBGE: 2910206, nomeTOM: 'DOM MACEDO COSTA', nomeIBGE: 'Dom Macedo Costa' },
    { codigoTOM: 3505, codigoIBGE: 2910305, nomeTOM: 'ELÍSIO MEDRADO', nomeIBGE: 'Elísio Medrado' },
    { codigoTOM: 3507, codigoIBGE: 2910404, nomeTOM: 'ENCRUZILHADA', nomeIBGE: 'Encruzilhada' },
    { codigoTOM: 3509, codigoIBGE: 2910503, nomeTOM: 'ENTRE RIOS', nomeIBGE: 'Entre Rios' },
    { codigoTOM: 3511, codigoIBGE: 2910602, nomeTOM: 'ESPLANADA', nomeIBGE: 'Esplanada' },
    { codigoTOM: 3513, codigoIBGE: 2910701, nomeTOM: 'EUCLIDES DA CUNHA', nomeIBGE: 'Euclides da Cunha' },
    { codigoTOM: 3515, codigoIBGE: 2910800, nomeTOM: 'FEIRA DE SANTANA', nomeIBGE: 'Feira de Santana' },
    { codigoTOM: 3517, codigoIBGE: 2910909, nomeTOM: 'FIRMINO ALVES', nomeIBGE: 'Firmino Alves' },
    { codigoTOM: 3519, codigoIBGE: 2911006, nomeTOM: 'FLORESTA AZUL', nomeIBGE: 'Floresta Azul' },
    { codigoTOM: 3521, codigoIBGE: 2911105, nomeTOM: 'FORMOSA DO RIO PRETO', nomeIBGE: 'Formosa do Rio Preto' },
    { codigoTOM: 3523, codigoIBGE: 2911204, nomeTOM: 'GANDU', nomeIBGE: 'Gandu' },
    { codigoTOM: 3525, codigoIBGE: 2911303, nomeTOM: 'GENTIO DO OURO', nomeIBGE: 'Gentio do Ouro' },
    { codigoTOM: 3527, codigoIBGE: 2911402, nomeTOM: 'GLÓRIA', nomeIBGE: 'Glória' },
    { codigoTOM: 3529, codigoIBGE: 2911501, nomeTOM: 'GONGOGI', nomeIBGE: 'Gongogi' },
    { codigoTOM: 3531, codigoIBGE: 2911600, nomeTOM: 'GOVERNADOR MANGABEIRA', nomeIBGE: 'Governador Mangabeira' },
    { codigoTOM: 3533, codigoIBGE: 2911709, nomeTOM: 'GUANAMBI', nomeIBGE: 'Guanambi' },
    { codigoTOM: 3535, codigoIBGE: 2911808, nomeTOM: 'GUARATINGA', nomeIBGE: 'Guaratinga' },
    { codigoTOM: 3537, codigoIBGE: 2911907, nomeTOM: 'IAÇU', nomeIBGE: 'Iaçu' },
    { codigoTOM: 3539, codigoIBGE: 2912004, nomeTOM: 'IBIASSUCÊ', nomeIBGE: 'Ibiassucê' },
    { codigoTOM: 3541, codigoIBGE: 2912103, nomeTOM: 'IBICARAÍ', nomeIBGE: 'Ibicaraí' },
    { codigoTOM: 3543, codigoIBGE: 2912202, nomeTOM: 'IBICOARA', nomeIBGE: 'Ibicoara' },
    { codigoTOM: 3545, codigoIBGE: 2912301, nomeTOM: 'IBICUÍ', nomeIBGE: 'Ibicuí' },
    { codigoTOM: 3547, codigoIBGE: 2912400, nomeTOM: 'IBIPEBA', nomeIBGE: 'Ibipeba' },
    { codigoTOM: 3549, codigoIBGE: 2928406, nomeTOM: 'SANTA RITA DE CÁSSIA', nomeIBGE: 'Santa Rita de Cássia' },
    { codigoTOM: 3551, codigoIBGE: 2912509, nomeTOM: 'IBIPITANGA', nomeIBGE: 'Ibipitanga' },
    { codigoTOM: 3553, codigoIBGE: 2912608, nomeTOM: 'IBIQUERA', nomeIBGE: 'Ibiquera' },
    { codigoTOM: 3555, codigoIBGE: 2912707, nomeTOM: 'IBIRAPITANGA', nomeIBGE: 'Ibirapitanga' },
    { codigoTOM: 3557, codigoIBGE: 2912806, nomeTOM: 'IBIRAPUÃ', nomeIBGE: 'Ibirapuã' },
    { codigoTOM: 3559, codigoIBGE: 2912905, nomeTOM: 'IBIRATAIA', nomeIBGE: 'Ibirataia' },
    { codigoTOM: 3561, codigoIBGE: 2913002, nomeTOM: 'IBITIARA', nomeIBGE: 'Ibitiara' },
    { codigoTOM: 3563, codigoIBGE: 2913101, nomeTOM: 'IBITITÁ', nomeIBGE: 'Ibititá' },
    { codigoTOM: 3565, codigoIBGE: 2913200, nomeTOM: 'IBOTIRAMA', nomeIBGE: 'Ibotirama' },
    { codigoTOM: 3567, codigoIBGE: 2913309, nomeTOM: 'ICHU', nomeIBGE: 'Ichu' },
    { codigoTOM: 3569, codigoIBGE: 2913408, nomeTOM: 'IGAPORÃ', nomeIBGE: 'Igaporã' },
    { codigoTOM: 3571, codigoIBGE: 2913507, nomeTOM: 'IGUAÍ', nomeIBGE: 'Iguaí' },
    { codigoTOM: 3573, codigoIBGE: 2913606, nomeTOM: 'ILHÉUS', nomeIBGE: 'Ilhéus' },
    { codigoTOM: 3575, codigoIBGE: 2913705, nomeTOM: 'INHAMBUPE', nomeIBGE: 'Inhambupe' },
    { codigoTOM: 3577, codigoIBGE: 2913804, nomeTOM: 'IPECAETÁ', nomeIBGE: 'Ipecaetá' },
    { codigoTOM: 3579, codigoIBGE: 2913903, nomeTOM: 'IPIAÚ', nomeIBGE: 'Ipiaú' },
    { codigoTOM: 3581, codigoIBGE: 2914000, nomeTOM: 'IPIRÁ', nomeIBGE: 'Ipirá' },
    { codigoTOM: 3583, codigoIBGE: 2914109, nomeTOM: 'IPUPIARA', nomeIBGE: 'Ipupiara' },
    { codigoTOM: 3585, codigoIBGE: 2914208, nomeTOM: 'IRAJUBA', nomeIBGE: 'Irajuba' },
    { codigoTOM: 3587, codigoIBGE: 2914307, nomeTOM: 'IRAMAIA', nomeIBGE: 'Iramaia' },
    { codigoTOM: 3589, codigoIBGE: 2914406, nomeTOM: 'IRAQUARA', nomeIBGE: 'Iraquara' },
    { codigoTOM: 3591, codigoIBGE: 2914505, nomeTOM: 'IRARÁ', nomeIBGE: 'Irará' },
    { codigoTOM: 3593, codigoIBGE: 2914604, nomeTOM: 'IRECÊ', nomeIBGE: 'Irecê' },
    { codigoTOM: 3595, codigoIBGE: 2914703, nomeTOM: 'ITABERABA', nomeIBGE: 'Itaberaba' },
    { codigoTOM: 3597, codigoIBGE: 2914802, nomeTOM: 'ITABUNA', nomeIBGE: 'Itabuna' },
    { codigoTOM: 3599, codigoIBGE: 2914901, nomeTOM: 'ITACARÉ', nomeIBGE: 'Itacaré' },
    { codigoTOM: 3601, codigoIBGE: 2915007, nomeTOM: 'ITAETÉ', nomeIBGE: 'Itaeté' },
    { codigoTOM: 3603, codigoIBGE: 2915106, nomeTOM: 'ITAGI', nomeIBGE: 'Itagi' },
    { codigoTOM: 3605, codigoIBGE: 2915205, nomeTOM: 'ITAGIBÁ', nomeIBGE: 'Itagibá' },
    { codigoTOM: 3607, codigoIBGE: 2915304, nomeTOM: 'ITAGIMIRIM', nomeIBGE: 'Itagimirim' },
    { codigoTOM: 3609, codigoIBGE: 2915403, nomeTOM: 'ITAJU DO COLÔNIA', nomeIBGE: 'Itaju do Colônia' },
    { codigoTOM: 3611, codigoIBGE: 2915502, nomeTOM: 'ITAJUÍPE', nomeIBGE: 'Itajuípe' },
    { codigoTOM: 3613, codigoIBGE: 2915601, nomeTOM: 'ITAMARAJU', nomeIBGE: 'Itamaraju' },
    { codigoTOM: 3615, codigoIBGE: 2915700, nomeTOM: 'ITAMARI', nomeIBGE: 'Itamari' },
    { codigoTOM: 3617, codigoIBGE: 2915809, nomeTOM: 'ITAMBÉ', nomeIBGE: 'Itambé' },
    { codigoTOM: 3619, codigoIBGE: 2915908, nomeTOM: 'ITANAGRA', nomeIBGE: 'Itanagra' },
    { codigoTOM: 3621, codigoIBGE: 2916005, nomeTOM: 'ITANHÉM', nomeIBGE: 'Itanhém' },
    { codigoTOM: 3623, codigoIBGE: 2916104, nomeTOM: 'ITAPARICA', nomeIBGE: 'Itaparica' },
    { codigoTOM: 3625, codigoIBGE: 2916203, nomeTOM: 'ITAPÉ', nomeIBGE: 'Itapé' },
    { codigoTOM: 3627, codigoIBGE: 2916302, nomeTOM: 'ITAPEBI', nomeIBGE: 'Itapebi' },
    { codigoTOM: 3629, codigoIBGE: 2916401, nomeTOM: 'ITAPETINGA', nomeIBGE: 'Itapetinga' },
    { codigoTOM: 3631, codigoIBGE: 2916500, nomeTOM: 'ITAPICURU', nomeIBGE: 'Itapicuru' },
    { codigoTOM: 3633, codigoIBGE: 2916609, nomeTOM: 'ITAPITANGA', nomeIBGE: 'Itapitanga' },
    { codigoTOM: 3635, codigoIBGE: 2916708, nomeTOM: 'ITAQUARA', nomeIBGE: 'Itaquara' },
    { codigoTOM: 3637, codigoIBGE: 2916807, nomeTOM: 'ITARANTIM', nomeIBGE: 'Itarantim' },
    { codigoTOM: 3639, codigoIBGE: 2916906, nomeTOM: 'ITIRUÇU', nomeIBGE: 'Itiruçu' },
    { codigoTOM: 3641, codigoIBGE: 2917003, nomeTOM: 'ITIÚBA', nomeIBGE: 'Itiúba' },
    { codigoTOM: 3643, codigoIBGE: 2917102, nomeTOM: 'ITORORÓ', nomeIBGE: 'Itororó' },
    { codigoTOM: 3645, codigoIBGE: 2917201, nomeTOM: 'ITUAÇU', nomeIBGE: 'Ituaçu' },
    { codigoTOM: 3647, codigoIBGE: 2917300, nomeTOM: 'ITUBERÁ', nomeIBGE: 'Ituberá' },
    { codigoTOM: 3649, codigoIBGE: 2917409, nomeTOM: 'JACARACI', nomeIBGE: 'Jacaraci' },
    { codigoTOM: 3651, codigoIBGE: 2917508, nomeTOM: 'JACOBINA', nomeIBGE: 'Jacobina' },
    { codigoTOM: 3653, codigoIBGE: 2917607, nomeTOM: 'JAGUAQUARA', nomeIBGE: 'Jaguaquara' },
    { codigoTOM: 3655, codigoIBGE: 2917706, nomeTOM: 'JAGUARARI', nomeIBGE: 'Jaguarari' },
    { codigoTOM: 3657, codigoIBGE: 2917805, nomeTOM: 'JAGUARIPE', nomeIBGE: 'Jaguaripe' },
    { codigoTOM: 3659, codigoIBGE: 2917904, nomeTOM: 'JANDAÍRA', nomeIBGE: 'Jandaíra' },
    { codigoTOM: 3661, codigoIBGE: 2918001, nomeTOM: 'JEQUIÉ', nomeIBGE: 'Jequié' },
    { codigoTOM: 3663, codigoIBGE: 2918100, nomeTOM: 'JEREMOABO', nomeIBGE: 'Jeremoabo' },
    { codigoTOM: 3665, codigoIBGE: 2918209, nomeTOM: 'JIQUIRIÇÁ', nomeIBGE: 'Jiquiriçá' },
    { codigoTOM: 3667, codigoIBGE: 2918308, nomeTOM: 'JITAÚNA', nomeIBGE: 'Jitaúna' },
    { codigoTOM: 3669, codigoIBGE: 2918407, nomeTOM: 'JUAZEIRO', nomeIBGE: 'Juazeiro' },
    { codigoTOM: 3671, codigoIBGE: 2918506, nomeTOM: 'JUSSARA', nomeIBGE: 'Jussara' },
    { codigoTOM: 3673, codigoIBGE: 2918605, nomeTOM: 'JUSSIAPE', nomeIBGE: 'Jussiape' },
    { codigoTOM: 3675, codigoIBGE: 2918704, nomeTOM: 'LAFAIETE COUTINHO', nomeIBGE: 'Lafaiete Coutinho' },
    { codigoTOM: 3677, codigoIBGE: 2918803, nomeTOM: 'LAJE', nomeIBGE: 'Laje' },
    { codigoTOM: 3679, codigoIBGE: 2918902, nomeTOM: 'LAJEDÃO', nomeIBGE: 'Lajedão' },
    { codigoTOM: 3681, codigoIBGE: 2919009, nomeTOM: 'LAJEDINHO', nomeIBGE: 'Lajedinho' },
    { codigoTOM: 3683, codigoIBGE: 2919108, nomeTOM: 'LAMARÃO', nomeIBGE: 'Lamarão' },
    { codigoTOM: 3685, codigoIBGE: 2919207, nomeTOM: 'LAURO DE FREITAS', nomeIBGE: 'Lauro de Freitas' },
    { codigoTOM: 3687, codigoIBGE: 2919306, nomeTOM: 'LENÇÓIS', nomeIBGE: 'Lençóis' },
    { codigoTOM: 3689, codigoIBGE: 2919405, nomeTOM: 'LICÍNIO DE ALMEIDA', nomeIBGE: 'Licínio de Almeida' },
    {
      codigoTOM: 3691,
      codigoIBGE: 2919504,
      nomeTOM: 'LIVRAMENTO DE NOSSA SENHORA',
      nomeIBGE: 'Livramento de Nossa Senhora',
    },
    { codigoTOM: 3693, codigoIBGE: 2919603, nomeTOM: 'MACAJUBA', nomeIBGE: 'Macajuba' },
    { codigoTOM: 3695, codigoIBGE: 2919702, nomeTOM: 'MACARANI', nomeIBGE: 'Macarani' },
    { codigoTOM: 3697, codigoIBGE: 2919801, nomeTOM: 'MACAÚBAS', nomeIBGE: 'Macaúbas' },
    { codigoTOM: 3699, codigoIBGE: 2919900, nomeTOM: 'MACURURÉ', nomeIBGE: 'Macururé' },
    { codigoTOM: 3701, codigoIBGE: 2920007, nomeTOM: 'MAIQUINIQUE', nomeIBGE: 'Maiquinique' },
    { codigoTOM: 3703, codigoIBGE: 2920106, nomeTOM: 'MAIRI', nomeIBGE: 'Mairi' },
    { codigoTOM: 3705, codigoIBGE: 2920205, nomeTOM: 'MALHADA', nomeIBGE: 'Malhada' },
    { codigoTOM: 3707, codigoIBGE: 2920304, nomeTOM: 'MALHADA DE PEDRAS', nomeIBGE: 'Malhada de Pedras' },
    { codigoTOM: 3709, codigoIBGE: 2920403, nomeTOM: 'MANOEL VITORINO', nomeIBGE: 'Manoel Vitorino' },
    { codigoTOM: 3711, codigoIBGE: 2920502, nomeTOM: 'MARACÁS', nomeIBGE: 'Maracás' },
    { codigoTOM: 3713, codigoIBGE: 2920601, nomeTOM: 'MARAGOGIPE', nomeIBGE: 'Maragogipe' },
    { codigoTOM: 3715, codigoIBGE: 2920700, nomeTOM: 'MARAÚ', nomeIBGE: 'Maraú' },
    { codigoTOM: 3717, codigoIBGE: 2920809, nomeTOM: 'MARCIONÍLIO SOUZA', nomeIBGE: 'Marcionílio Souza' },
    { codigoTOM: 3719, codigoIBGE: 2920908, nomeTOM: 'MASCOTE', nomeIBGE: 'Mascote' },
    { codigoTOM: 3721, codigoIBGE: 2921005, nomeTOM: 'MATA DE SÃO JOÃO', nomeIBGE: 'Mata de São João' },
    { codigoTOM: 3723, codigoIBGE: 2921104, nomeTOM: 'MEDEIROS NETO', nomeIBGE: 'Medeiros Neto' },
    { codigoTOM: 3725, codigoIBGE: 2921203, nomeTOM: 'MIGUEL CALMON', nomeIBGE: 'Miguel Calmon' },
    { codigoTOM: 3727, codigoIBGE: 2921302, nomeTOM: 'MILAGRES', nomeIBGE: 'Milagres' },
    { codigoTOM: 3729, codigoIBGE: 2921401, nomeTOM: 'MIRANGABA', nomeIBGE: 'Mirangaba' },
    { codigoTOM: 3731, codigoIBGE: 2921500, nomeTOM: 'MONTE SANTO', nomeIBGE: 'Monte Santo' },
    { codigoTOM: 3733, codigoIBGE: 2921609, nomeTOM: 'MORPARÁ', nomeIBGE: 'Morpará' },
    { codigoTOM: 3735, codigoIBGE: 2921708, nomeTOM: 'MORRO DO CHAPÉU', nomeIBGE: 'Morro do Chapéu' },
    { codigoTOM: 3737, codigoIBGE: 2921807, nomeTOM: 'MORTUGABA', nomeIBGE: 'Mortugaba' },
    { codigoTOM: 3739, codigoIBGE: 2921906, nomeTOM: 'MUCUGÊ', nomeIBGE: 'Mucugê' },
    { codigoTOM: 3741, codigoIBGE: 2922003, nomeTOM: 'MUCURI', nomeIBGE: 'Mucuri' },
    { codigoTOM: 3743, codigoIBGE: 2922102, nomeTOM: 'MUNDO NOVO', nomeIBGE: 'Mundo Novo' },
    { codigoTOM: 3745, codigoIBGE: 2922201, nomeTOM: 'MUNIZ FERREIRA', nomeIBGE: 'Muniz Ferreira' },
    { codigoTOM: 3747, codigoIBGE: 2922300, nomeTOM: 'MURITIBA', nomeIBGE: 'Muritiba' },
    { codigoTOM: 3749, codigoIBGE: 2922409, nomeTOM: 'MUTUÍPE', nomeIBGE: 'Mutuípe' },
    { codigoTOM: 3751, codigoIBGE: 2922508, nomeTOM: 'NAZARÉ', nomeIBGE: 'Nazaré' },
    { codigoTOM: 3753, codigoIBGE: 2922607, nomeTOM: 'NILO PEÇANHA', nomeIBGE: 'Nilo Peçanha' },
    { codigoTOM: 3755, codigoIBGE: 2922706, nomeTOM: 'NOVA CANAÃ', nomeIBGE: 'Nova Canaã' },
    { codigoTOM: 3757, codigoIBGE: 2922805, nomeTOM: 'NOVA ITARANA', nomeIBGE: 'Nova Itarana' },
    { codigoTOM: 3759, codigoIBGE: 2922904, nomeTOM: 'NOVA SOURE', nomeIBGE: 'Nova Soure' },
    { codigoTOM: 3761, codigoIBGE: 2923001, nomeTOM: 'NOVA VIÇOSA', nomeIBGE: 'Nova Viçosa' },
    { codigoTOM: 3763, codigoIBGE: 2923100, nomeTOM: 'OLINDINA', nomeIBGE: 'Olindina' },
    { codigoTOM: 3765, codigoIBGE: 2923209, nomeTOM: 'OLIVEIRA DOS BREJINHOS', nomeIBGE: 'Oliveira dos Brejinhos' },
    { codigoTOM: 3767, codigoIBGE: 2923308, nomeTOM: 'OURIÇANGAS', nomeIBGE: 'Ouriçangas' },
    { codigoTOM: 3769, codigoIBGE: 2923407, nomeTOM: 'PALMAS DE MONTE ALTO', nomeIBGE: 'Palmas de Monte Alto' },
    { codigoTOM: 3771, codigoIBGE: 2923506, nomeTOM: 'PALMEIRAS', nomeIBGE: 'Palmeiras' },
    { codigoTOM: 3773, codigoIBGE: 2923605, nomeTOM: 'PARAMIRIM', nomeIBGE: 'Paramirim' },
    { codigoTOM: 3775, codigoIBGE: 2923704, nomeTOM: 'PARATINGA', nomeIBGE: 'Paratinga' },
    { codigoTOM: 3777, codigoIBGE: 2923803, nomeTOM: 'PARIPIRANGA', nomeIBGE: 'Paripiranga' },
    { codigoTOM: 3779, codigoIBGE: 2923902, nomeTOM: 'PAU BRASIL', nomeIBGE: 'Pau Brasil' },
    { codigoTOM: 3781, codigoIBGE: 2924009, nomeTOM: 'PAULO AFONSO', nomeIBGE: 'Paulo Afonso' },
    { codigoTOM: 3783, codigoIBGE: 2924108, nomeTOM: 'PEDRÃO', nomeIBGE: 'Pedrão' },
    { codigoTOM: 3785, codigoIBGE: 2924207, nomeTOM: 'PEDRO ALEXANDRE', nomeIBGE: 'Pedro Alexandre' },
    { codigoTOM: 3787, codigoIBGE: 2924306, nomeTOM: 'PIATÃ', nomeIBGE: 'Piatã' },
    { codigoTOM: 3789, codigoIBGE: 2924405, nomeTOM: 'PILÃO ARCADO', nomeIBGE: 'Pilão Arcado' },
    { codigoTOM: 3791, codigoIBGE: 2924504, nomeTOM: 'PINDAÍ', nomeIBGE: 'Pindaí' },
    { codigoTOM: 3793, codigoIBGE: 2924603, nomeTOM: 'PINDOBAÇU', nomeIBGE: 'Pindobaçu' },
    { codigoTOM: 3795, codigoIBGE: 2924702, nomeTOM: 'PIRIPÁ', nomeIBGE: 'Piripá' },
    { codigoTOM: 3797, codigoIBGE: 2924801, nomeTOM: 'PIRITIBA', nomeIBGE: 'Piritiba' },
    { codigoTOM: 3799, codigoIBGE: 2924900, nomeTOM: 'PLANALTINO', nomeIBGE: 'Planaltino' },
    { codigoTOM: 3801, codigoIBGE: 2925006, nomeTOM: 'PLANALTO', nomeIBGE: 'Planalto' },
    { codigoTOM: 3803, codigoIBGE: 2925105, nomeTOM: 'POÇÕES', nomeIBGE: 'Poções' },
    { codigoTOM: 3805, codigoIBGE: 2925204, nomeTOM: 'POJUCA', nomeIBGE: 'Pojuca' },
    { codigoTOM: 3807, codigoIBGE: 2925303, nomeTOM: 'PORTO SEGURO', nomeIBGE: 'Porto Seguro' },
    { codigoTOM: 3809, codigoIBGE: 2925402, nomeTOM: 'POTIRAGUÁ', nomeIBGE: 'Potiraguá' },
    { codigoTOM: 3811, codigoIBGE: 2925501, nomeTOM: 'PRADO', nomeIBGE: 'Prado' },
    { codigoTOM: 3813, codigoIBGE: 2925600, nomeTOM: 'PRESIDENTE DUTRA', nomeIBGE: 'Presidente Dutra' },
    { codigoTOM: 3815, codigoIBGE: 2925709, nomeTOM: 'PRESIDENTE JÂNIO QUADROS', nomeIBGE: 'Presidente Jânio Quadros' },
    { codigoTOM: 3817, codigoIBGE: 2925808, nomeTOM: 'QUEIMADAS', nomeIBGE: 'Queimadas' },
    { codigoTOM: 3819, codigoIBGE: 2925907, nomeTOM: 'QUIJINGUE', nomeIBGE: 'Quijingue' },
    { codigoTOM: 3821, codigoIBGE: 2926004, nomeTOM: 'REMANSO', nomeIBGE: 'Remanso' },
    { codigoTOM: 3823, codigoIBGE: 2926103, nomeTOM: 'RETIROLÂNDIA', nomeIBGE: 'Retirolândia' },
    { codigoTOM: 3825, codigoIBGE: 2926202, nomeTOM: 'RIACHÃO DAS NEVES', nomeIBGE: 'Riachão das Neves' },
    { codigoTOM: 3827, codigoIBGE: 2926301, nomeTOM: 'RIACHÃO DO JACUÍPE', nomeIBGE: 'Riachão do Jacuípe' },
    { codigoTOM: 3829, codigoIBGE: 2926400, nomeTOM: 'RIACHO DE SANTANA', nomeIBGE: 'Riacho de Santana' },
    { codigoTOM: 3831, codigoIBGE: 2926509, nomeTOM: 'RIBEIRA DO AMPARO', nomeIBGE: 'Ribeira do Amparo' },
    { codigoTOM: 3833, codigoIBGE: 2926608, nomeTOM: 'RIBEIRA DO POMBAL', nomeIBGE: 'Ribeira do Pombal' },
    { codigoTOM: 3835, codigoIBGE: 2926707, nomeTOM: 'RIO DE CONTAS', nomeIBGE: 'Rio de Contas' },
    { codigoTOM: 3837, codigoIBGE: 2926806, nomeTOM: 'RIO DO ANTÔNIO', nomeIBGE: 'Rio do Antônio' },
    { codigoTOM: 3839, codigoIBGE: 2926905, nomeTOM: 'RIO DO PIRES', nomeIBGE: 'Rio do Pires' },
    { codigoTOM: 3841, codigoIBGE: 2927002, nomeTOM: 'RIO REAL', nomeIBGE: 'Rio Real' },
    { codigoTOM: 3843, codigoIBGE: 2927101, nomeTOM: 'RODELAS', nomeIBGE: 'Rodelas' },
    { codigoTOM: 3845, codigoIBGE: 2927200, nomeTOM: 'RUY BARBOSA', nomeIBGE: 'Ruy Barbosa' },
    { codigoTOM: 3847, codigoIBGE: 2927309, nomeTOM: 'SALINAS DA MARGARIDA', nomeIBGE: 'Salinas da Margarida' },
    { codigoTOM: 3849, codigoIBGE: 2927408, nomeTOM: 'SALVADOR', nomeIBGE: 'Salvador' },
    { codigoTOM: 3851, codigoIBGE: 2927507, nomeTOM: 'SANTA BÁRBARA', nomeIBGE: 'Santa Bárbara' },
    { codigoTOM: 3853, codigoIBGE: 2927606, nomeTOM: 'SANTA BRÍGIDA', nomeIBGE: 'Santa Brígida' },
    { codigoTOM: 3855, codigoIBGE: 2927705, nomeTOM: 'SANTA CRUZ CABRÁLIA', nomeIBGE: 'Santa Cruz Cabrália' },
    { codigoTOM: 3857, codigoIBGE: 2927804, nomeTOM: 'SANTA CRUZ DA VITÓRIA', nomeIBGE: 'Santa Cruz da Vitória' },
    { codigoTOM: 3859, codigoIBGE: 2927903, nomeTOM: 'SANTA INÊS', nomeIBGE: 'Santa Inês' },
    { codigoTOM: 3861, codigoIBGE: 2928000, nomeTOM: 'SANTALUZ', nomeIBGE: 'Santaluz' },
    { codigoTOM: 3863, codigoIBGE: 2928109, nomeTOM: 'SANTA MARIA DA VITÓRIA', nomeIBGE: 'Santa Maria da Vitória' },
    { codigoTOM: 3865, codigoIBGE: 2928208, nomeTOM: 'SANTANA', nomeIBGE: 'Santana' },
    { codigoTOM: 3867, codigoIBGE: 2928307, nomeTOM: 'SANTANÓPOLIS', nomeIBGE: 'Santanópolis' },
    { codigoTOM: 3869, codigoIBGE: 2928505, nomeTOM: 'SANTA TERESINHA', nomeIBGE: 'Santa Terezinha' },
    { codigoTOM: 3871, codigoIBGE: 2928604, nomeTOM: 'SANTO AMARO', nomeIBGE: 'Santo Amaro' },
    { codigoTOM: 3873, codigoIBGE: 2928703, nomeTOM: 'SANTO ANTÔNIO DE JESUS', nomeIBGE: 'Santo Antônio de Jesus' },
    { codigoTOM: 3875, codigoIBGE: 2928802, nomeTOM: 'SANTO ESTÊVÃO', nomeIBGE: 'Santo Estêvão' },
    { codigoTOM: 3877, codigoIBGE: 2928901, nomeTOM: 'SÃO DESIDÉRIO', nomeIBGE: 'São Desidério' },
    { codigoTOM: 3879, codigoIBGE: 2929008, nomeTOM: 'SÃO FÉLIX', nomeIBGE: 'São Félix' },
    { codigoTOM: 3881, codigoIBGE: 2929107, nomeTOM: 'SÃO FELIPE', nomeIBGE: 'São Felipe' },
    { codigoTOM: 3883, codigoIBGE: 2929206, nomeTOM: 'SÃO FRANCISCO DO CONDE', nomeIBGE: 'São Francisco do Conde' },
    { codigoTOM: 3885, codigoIBGE: 2929305, nomeTOM: 'SÃO GONÇALO DOS CAMPOS', nomeIBGE: 'São Gonçalo dos Campos' },
    { codigoTOM: 3887, codigoIBGE: 2929404, nomeTOM: 'SÃO MIGUEL DAS MATAS', nomeIBGE: 'São Miguel das Matas' },
    { codigoTOM: 3889, codigoIBGE: 2929503, nomeTOM: 'SÃO SEBASTIÃO DO PASSÉ', nomeIBGE: 'São Sebastião do Passé' },
    { codigoTOM: 3891, codigoIBGE: 2929602, nomeTOM: 'SAPEAÇU', nomeIBGE: 'Sapeaçu' },
    { codigoTOM: 3893, codigoIBGE: 2929701, nomeTOM: 'SÁTIRO DIAS', nomeIBGE: 'Sátiro Dias' },
    { codigoTOM: 3895, codigoIBGE: 2929800, nomeTOM: 'SAÚDE', nomeIBGE: 'Saúde' },
    { codigoTOM: 3897, codigoIBGE: 2929909, nomeTOM: 'SEABRA', nomeIBGE: 'Seabra' },
    { codigoTOM: 3899, codigoIBGE: 2930006, nomeTOM: 'SEBASTIÃO LARANJEIRAS', nomeIBGE: 'Sebastião Laranjeiras' },
    { codigoTOM: 3901, codigoIBGE: 2930105, nomeTOM: 'SENHOR DO BONFIM', nomeIBGE: 'Senhor do Bonfim' },
    { codigoTOM: 3903, codigoIBGE: 2930204, nomeTOM: 'SENTO SÉ', nomeIBGE: 'Sento Sé' },
    { codigoTOM: 3905, codigoIBGE: 2930303, nomeTOM: 'SERRA DOURADA', nomeIBGE: 'Serra Dourada' },
    { codigoTOM: 3907, codigoIBGE: 2930402, nomeTOM: 'SERRA PRETA', nomeIBGE: 'Serra Preta' },
    { codigoTOM: 3909, codigoIBGE: 2930501, nomeTOM: 'SERRINHA', nomeIBGE: 'Serrinha' },
    { codigoTOM: 3911, codigoIBGE: 2930600, nomeTOM: 'SERROLÂNDIA', nomeIBGE: 'Serrolândia' },
    { codigoTOM: 3913, codigoIBGE: 2930709, nomeTOM: 'SIMÕES FILHO', nomeIBGE: 'Simões Filho' },
    { codigoTOM: 3915, codigoIBGE: 2930808, nomeTOM: 'SOUTO SOARES', nomeIBGE: 'Souto Soares' },
    { codigoTOM: 3917, codigoIBGE: 2930907, nomeTOM: 'TABOCAS DO BREJO VELHO', nomeIBGE: 'Tabocas do Brejo Velho' },
    { codigoTOM: 3919, codigoIBGE: 2931004, nomeTOM: 'TANHAÇU', nomeIBGE: 'Tanhaçu' },
    { codigoTOM: 3921, codigoIBGE: 2931103, nomeTOM: 'TANQUINHO', nomeIBGE: 'Tanquinho' },
    { codigoTOM: 3923, codigoIBGE: 2931202, nomeTOM: 'TAPEROÁ', nomeIBGE: 'Taperoá' },
    { codigoTOM: 3925, codigoIBGE: 2931301, nomeTOM: 'TAPIRAMUTÁ', nomeIBGE: 'Tapiramutá' },
    { codigoTOM: 3927, codigoIBGE: 2931400, nomeTOM: 'TEODORO SAMPAIO', nomeIBGE: 'Teodoro Sampaio' },
    { codigoTOM: 3929, codigoIBGE: 2931509, nomeTOM: 'TEOFILÂNDIA', nomeIBGE: 'Teofilândia' },
    { codigoTOM: 3931, codigoIBGE: 2931608, nomeTOM: 'TEOLÂNDIA', nomeIBGE: 'Teolândia' },
    { codigoTOM: 3933, codigoIBGE: 2931707, nomeTOM: 'TERRA NOVA', nomeIBGE: 'Terra Nova' },
    { codigoTOM: 3935, codigoIBGE: 2931806, nomeTOM: 'TREMEDAL', nomeIBGE: 'Tremedal' },
    { codigoTOM: 3937, codigoIBGE: 2931905, nomeTOM: 'TUCANO', nomeIBGE: 'Tucano' },
    { codigoTOM: 3939, codigoIBGE: 2932002, nomeTOM: 'UAUÁ', nomeIBGE: 'Uauá' },
    { codigoTOM: 3941, codigoIBGE: 2932101, nomeTOM: 'UBAÍRA', nomeIBGE: 'Ubaíra' },
    { codigoTOM: 3943, codigoIBGE: 2932200, nomeTOM: 'UBAITABA', nomeIBGE: 'Ubaitaba' },
    { codigoTOM: 3945, codigoIBGE: 2932309, nomeTOM: 'UBATÃ', nomeIBGE: 'Ubatã' },
    { codigoTOM: 3947, codigoIBGE: 2932408, nomeTOM: 'UIBAÍ', nomeIBGE: 'Uibaí' },
    { codigoTOM: 3949, codigoIBGE: 2932507, nomeTOM: 'UNA', nomeIBGE: 'Una' },
    { codigoTOM: 3951, codigoIBGE: 2932606, nomeTOM: 'URANDI', nomeIBGE: 'Urandi' },
    { codigoTOM: 3953, codigoIBGE: 2932705, nomeTOM: 'URUÇUCA', nomeIBGE: 'Uruçuca' },
    { codigoTOM: 3955, codigoIBGE: 2932804, nomeTOM: 'UTINGA', nomeIBGE: 'Utinga' },
    { codigoTOM: 3957, codigoIBGE: 2932903, nomeTOM: 'VALENÇA', nomeIBGE: 'Valença' },
    { codigoTOM: 3959, codigoIBGE: 2933000, nomeTOM: 'VALENTE', nomeIBGE: 'Valente' },
    { codigoTOM: 3961, codigoIBGE: 2933109, nomeTOM: 'VÁRZEA DO POÇO', nomeIBGE: 'Várzea do Poço' },
    { codigoTOM: 3963, codigoIBGE: 2933208, nomeTOM: 'VERA CRUZ', nomeIBGE: 'Vera Cruz' },
    { codigoTOM: 3965, codigoIBGE: 2933307, nomeTOM: 'VITÓRIA DA CONQUISTA', nomeIBGE: 'Vitória da Conquista' },
    { codigoTOM: 3967, codigoIBGE: 2933406, nomeTOM: 'WAGNER', nomeIBGE: 'Wagner' },
    { codigoTOM: 3969, codigoIBGE: 2933505, nomeTOM: 'WENCESLAU GUIMARÃES', nomeIBGE: 'Wenceslau Guimarães' },
    { codigoTOM: 3971, codigoIBGE: 2933604, nomeTOM: 'XIQUE-XIQUE', nomeIBGE: 'Xique-Xique' },
    { codigoTOM: 3973, codigoIBGE: 2919157, nomeTOM: 'LAPÃO', nomeIBGE: 'Lapão' },
    { codigoTOM: 3975, codigoIBGE: 2919959, nomeTOM: 'MAETINGA', nomeIBGE: 'Maetinga' },
    { codigoTOM: 3977, codigoIBGE: 2920452, nomeTOM: 'MANSIDÃO', nomeIBGE: 'Mansidão' },
    { codigoTOM: 3979, codigoIBGE: 2922656, nomeTOM: 'NORDESTINA', nomeIBGE: 'Nordestina' },
    { codigoTOM: 3981, codigoIBGE: 2924058, nomeTOM: 'PÉ DE SERRA', nomeIBGE: 'Pé de Serra' },
    { codigoTOM: 3983, codigoIBGE: 2924652, nomeTOM: 'PINTADAS', nomeIBGE: 'Pintadas' },
    { codigoTOM: 3985, codigoIBGE: 2925956, nomeTOM: 'RAFAEL JAMBEIRO', nomeIBGE: 'Rafael Jambeiro' },
    { codigoTOM: 3987, codigoIBGE: 2928059, nomeTOM: 'SANTA LUZIA', nomeIBGE: 'Santa Luzia' },
    { codigoTOM: 3989, codigoIBGE: 2929255, nomeTOM: 'SÃO GABRIEL', nomeIBGE: 'São Gabriel' },
    { codigoTOM: 3991, codigoIBGE: 2931053, nomeTOM: 'TANQUE NOVO', nomeIBGE: 'Tanque Novo' },
    { codigoTOM: 3993, codigoIBGE: 2931350, nomeTOM: 'TEIXEIRA DE FREITAS', nomeIBGE: 'Teixeira de Freitas' },
    { codigoTOM: 3995, codigoIBGE: 2933158, nomeTOM: 'VÁRZEA NOVA', nomeIBGE: 'Várzea Nova' },
    { codigoTOM: 3997, codigoIBGE: 2933059, nomeTOM: 'VÁRZEA DA ROÇA', nomeIBGE: 'Várzea da Roça' },
    { codigoTOM: 3999, codigoIBGE: 2933455, nomeTOM: 'WANDERLEY', nomeIBGE: 'Wanderley' },
    { codigoTOM: 1110, codigoIBGE: 2903276, nomeTOM: 'BARROCAS', nomeIBGE: 'Barrocas' },
    { codigoTOM: 1112, codigoIBGE: 2919553, nomeTOM: 'LUÍS EDUARDO MAGALHÃES', nomeIBGE: 'Luís Eduardo Magalhães' },
    { codigoTOM: 9859, codigoIBGE: 2917359, nomeTOM: 'JABORANDI', nomeIBGE: 'Jaborandi' },
  ],
  [Estados.EX]: [{ codigoTOM: 9707, codigoIBGE: 0, nomeTOM: 'EXTERIOR', nomeIBGE: '' }],
  [Estados.DF]: [{ codigoTOM: 9701, codigoIBGE: 5300108, nomeTOM: 'BRASÍLIA', nomeIBGE: 'Brasília' }],
};

export const buscarCidadesPorEstado = (estado: Estados): Cidade[] => {
  return CIDADES_POR_ESTADO[estado] || [];
};
