import React from 'react';
import { Field, FieldArray, useField, ArrayHelpers } from 'formik';
import { Button, FieldArrayContainer, FieldGroup, FieldGroupPlusButton, SecondaryButton } from './styles';
import ErrorMessage from 'components/ErrorMessage';
import sumIcon from 'assets/images/sumIcon.svg';
import trashIcon from 'assets/images/trashYellowIcon.svg';

interface IProps {
  name: string;
}

const ArrayField: React.FC<IProps> = (props: IProps) => {
  const [field] = useField(props.name);
  const values: string[] = field.value || [''];
  const maxSize = 4;

  const showInsertButton = (index: number, length: number) => {
    return length === 1 && length < maxSize;
  };

  const showRemoveButton = (index: number, length: number) => {
    return length >= 2;
  };

  const showLineInsertButton = (index: number, length: number) => {
    return length >= 2 && length < maxSize;
  };

  return (
    <FieldArrayContainer>
      <FieldArray
        name={props.name}
        render={(arrayHelpers: ArrayHelpers) => (
          <article>
            {values.map((value, index, array) => (
              <>
                <FieldGroup key={index}>
                  <div>
                    <Field name={`${props.name}.${index}`} />
                    <ErrorMessage name={`${props.name}.${index}`} />
                  </div>
                  {showRemoveButton(index, array.length) && (
                    <SecondaryButton type="button" onClick={() => arrayHelpers.remove(index)}>
                      <img src={trashIcon} />
                    </SecondaryButton>
                  )}
                  {showInsertButton(index, array.length) && (
                    <Button type="button" onClick={() => arrayHelpers.insert(array.length, '')}>
                      <img src={sumIcon} />
                    </Button>
                  )}
                </FieldGroup>
              </>
            ))}
            {showLineInsertButton(values.length, values.length) && (
              <FieldGroupPlusButton>
                <Button type="button" onClick={() => arrayHelpers.insert(values.length, '')}>
                  <img src={sumIcon} />
                </Button>
              </FieldGroupPlusButton>
            )}
          </article>
        )}
      />
    </FieldArrayContainer>
  );
};

export default ArrayField;
