import Yup from 'utils/validation';
import { businessFormInitialValues } from '../utils';
import { validDDDs } from 'utils/basicUtils';

export const restaurantsInitialValues = {
  ...businessFormInitialValues,
};

export const restaurantsValidationSchema = Yup.object().shape({
  id: Yup.number(),
  businessRepresentative: Yup.string()
    .required()
    .test('not-empty', 'Campo não pode conter apenas espaços', value => value?.trim().length > 0),
  businessOwner: Yup.array().of(
    Yup.string()
      .required()
      .test('not-empty', 'Campo não pode conter apenas espaços', value => value?.trim().length > 0)
  ),
  businessType: Yup.string()
    .required()
    .test('not-empty', 'Campo não pode conter apenas espaços', value => value?.trim().length > 0),
  description: Yup.string()
    .required()
    .test('not-empty', 'Campo não pode conter apenas espaços', value => value?.trim().length > 0),
  document: Yup.string()
    .length(18)
    .required()
    .test('not-empty', 'Campo não pode conter apenas espaços', value => value?.trim().length > 0),
  experiences: Yup.array().min(1).required(),
  googleMapsLink: Yup.string()
    .required()
    .test('not-empty', 'Campo não pode conter apenas espaços', value => value?.trim().length > 0),
  tradeName: Yup.string()
    .required()
    .test('not-empty', 'Campo não pode conter apenas espaços', value => value?.trim().length > 0),
  tradeEmail: Yup.string()
    .lowercase()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'E-mail inválido. Use um formato válido, ex: usuario@email.com'
    ),
  title: Yup.string()
    .required()
    .test('not-empty', 'Campo não pode conter apenas espaços', value => value?.trim().length > 0),
  phone: Yup.string()
    .required('Telefone é obrigatório')
    .test('is-valid-ddd', 'DDD inválido', function (value) {
      if (!value) return false;
      const ddd = value.match(/^\((\d{2})\)/);
      if (!ddd) return false;
      return validDDDs.includes(ddd[1]);
    })
    .test('not-all-same', 'Telefone não pode ter todos os números iguais', function (value) {
      if (!value) return false;
      const digits = value.replace(/\D/g, '');
      return !/^(\d)\1+$/.test(digits);
    }),
  site: Yup.string().url('Link do site inválido'),
  youtubeLink: Yup.string().url('Link do Youtube inválido'),
  instagramLink: Yup.string().url('Link do Instagram inválido'),
  facebookLink: Yup.string().url('Link do Facebook inválido'),
  whatsapp: Yup.string(),
  image: Yup.array().min(1).required(),
  address: Yup.object().shape({
    id: Yup.number(),
    city: Yup.string()
      .required()
      .test('not-empty', 'Campo não pode conter apenas espaços', value => value?.trim().length > 0),
    neighborhood: Yup.string()
      .matches(/^(?!^\d+$)[\d\D]*$/, 'O bairro não pode conter somente números')
      .required()
      .test('not-empty', 'Campo não pode conter apenas espaços', value => value?.trim().length > 0),
    number: Yup.string()
      .matches(/^\d*$/, 'O campo número deve conter apenas números')
      .required()
      .test('not-empty', 'Campo não pode conter apenas espaços', value => value?.trim().length > 0),
    state: Yup.string()
      .required()
      .test('not-empty', 'Campo não pode conter apenas espaços', value => value?.trim().length > 0),
    street: Yup.string()
      .matches(/^(?!\d+$).*/, 'O campo endereço não pode ser apenas números')
      .required()
      .test('not-empty', 'Campo não pode conter apenas espaços', value => value?.trim().length > 0),
    zipCode: Yup.string()
      .required()
      .matches(/^\d{5}-?\d{3}$/, 'CEP deve ter o formato XXXXX-XXX')
      .test('is-not-equal', 'CEP não pode conter todos os números iguais', function (value) {
        if (!value) return false;
        const cleanedValue = value.replace('-', '');
        return !/^(.)\1+$/.test(cleanedValue);
      })
      .test('not-empty', 'Campo não pode conter apenas espaços', value => value?.trim().length > 0),
  }),
  images: Yup.array().min(1).required(),
});
