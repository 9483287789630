import React, { FC, useEffect, useRef, useState } from 'react';
import downArrowGrey from 'assets/images/downArrowGrey.svg';
import upArrowGrey from 'assets/images/upArrowGrey.svg';
import { SelectArea } from 'components/Select/styles';

interface SelectComponentProps {
  label: string;
  name: string;
  options: { label: string; value: string }[];
  onChange: (selectedValue: string) => void;
  value: string;
  placeholderText?: string;
  disabled?: boolean;
  required?: boolean;
  onBlur?: () => void;
}

const CitySelectComponent: FC<SelectComponentProps> = ({
  label,
  name,
  options,
  onChange,
  value,
  placeholderText = 'Selecione uma opção',
  disabled = false,
  required = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const selectRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessageText, setErrorMessageText] = useState('');

  // Função para remover acentos e normalizar o texto
  const normalizeText = (text: string) =>
    text
      .normalize('NFD') // Normaliza os caracteres com diacríticos
      .replace(/[\u0300-\u036f]/g, '') // Remove diacríticos (acentos)
      .toLowerCase(); // Converte para minúsculas

  // Atualiza as opções filtradas conforme o texto digitado
  useEffect(() => {
    setFilteredOptions(options.filter(option => normalizeText(option.label).includes(normalizeText(searchText))));
  }, [searchText, options]);

  const toggleOptions = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 0);
    }
  };

  const closeOptions = () => {
    setIsOpen(false);
    setSearchText('');
  };

  const handleSelect = (selectedValue: string) => {
    if (selectedValue !== value) {
      onChange(selectedValue); // Atualiza o valor somente se for diferente
    }
    closeOptions(); // Fecha o dropdown independentemente
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      if (isOpen && !value) {
        setErrorMessage(true);
        setErrorMessageText('* Por favor, selecione uma opção.');
      } else {
        setErrorMessage(false);
      }
      closeOptions();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, value]);

  return (
    <>
      <SelectArea ref={selectRef}>
        <label htmlFor={name}>
          {label} {required && <span>*</span>}
        </label>
        <div className={`CustomSelect ${isOpen ? 'open' : ''}`}>
          <div className={`SelectedOption ${disabled ? 'disabled' : ''}`} onClick={toggleOptions}>
            <p>{value ? options.find(option => option.value === value)?.label || placeholderText : placeholderText}</p>
            {isOpen ? <img src={upArrowGrey} alt="open" /> : <img src={downArrowGrey} alt="close" />}
          </div>
          {isOpen && (
            <div className="Dropdown">
              <input
                ref={searchInputRef}
                type="text"
                placeholder="Digite para buscar..."
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                className="SearchInput"
              />
              <ul className="OptionList">
                {searchText && (
                  <li className="TypedOption" onClick={() => handleSelect(searchText)}>
                    <strong>{`Buscar: "${searchText}"`}</strong>
                  </li>
                )}
                {filteredOptions.length > 0 ? (
                  filteredOptions.map(option => (
                    <li key={option.value} className="Option" onClick={() => handleSelect(option.value)}>
                      {option.label}
                    </li>
                  ))
                ) : (
                  <li className="NoOption">Selecione um estado</li>
                )}
              </ul>
            </div>
          )}
        </div>
      </SelectArea>
      {errorMessage && <p style={{ color: 'red', fontSize: '14.4px' }}>{errorMessageText}</p>}
    </>
  );
};

export default CitySelectComponent;
