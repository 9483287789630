import styled from 'styled-components';

export const ContainerSACInformation = styled.p`
  font-size: 30px;
  text-align: center;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;
