import React, { useEffect, useState } from 'react';
import { ExcursionPublicDetailsPageContainer } from './styles';
import MainTitle from 'components/MainTitle';
import ExcursionPublicDetailsArticle from './components/ExcursionPublicDetailsArticle';
import ExcursionPublicDetails404 from './components/ExcursionPublicDetails404';
import PublicService from 'services/PublicService';
import { useLoading } from 'hooks/useLoading';
//import { v4 as uuidv4 } from 'uuid';

const ExcursionPublicDetailsPage: React.FC = () => {
  const [excursionId, setExcursionId] = useState<string>('');
  const [excursion, setExcursion] = useState<any>();
  const { openLoading, closeLoading } = useLoading();
  const [page404, setPage404] = useState<boolean>(false);
  const [canceled, setCanceled] = useState<boolean>(false);
  //const [accessNumber, setAccessNumber] = useState<string>('');

  // useEffect(() => {
  //   const uuid = uuidv4();
  //   const formattedAccessNumber = uuid;
  //   setAccessNumber(formattedAccessNumber);
  // }, []);

  // const replaceIdUrlToAccessNumber = () => {
  //   window.history.replaceState({}, '', `/public/excursion-details/${accessNumber}`);
  // };

  const fetchExcursion = async () => {
    const excursionIdFromUrl = window.location.pathname.split('/').pop();
    if (!excursionIdFromUrl) return;
    try {
      openLoading();
      const response = await new PublicService().getPublicExcursion(excursionIdFromUrl);
      const data = response;
      if (data && data.status === 'Cancelada') {
        setExcursion(null);
        setCanceled(true);
      } else {
        setExcursion(data);
        setExcursionId(excursionIdFromUrl);
        console.log(data);
      }
    } catch (error) {
      console.error(error);
      setPage404(true);
    } finally {
      closeLoading();
    }
  };

  useEffect(() => {
    fetchExcursion();
    //replaceIdUrlToAccessNumber();
  }, [excursionId]);

  return (
    <>
      {excursion && (
        <ExcursionPublicDetailsPageContainer>
          <MainTitle>Dados da Excursão</MainTitle>
          <ExcursionPublicDetailsArticle excursionDetails={excursion} />
        </ExcursionPublicDetailsPageContainer>
      )}

      {page404 && (
        <ExcursionPublicDetails404
          title="Excursão cancelada"
          message="O selo é inválido devido ao cancelamento da excursão"
        />
      )}

      {canceled && (
        <ExcursionPublicDetails404
          title="Excursão cancelada"
          message="O selo é inválido devido ao cancelamento da excursão"
        />
      )}
    </>
  );
};

export default ExcursionPublicDetailsPage;
