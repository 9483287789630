import React from 'react';
import { Field, useField } from 'formik';
import ErrorMessage from 'components/ErrorMessage';
import { Container } from './styles';
import Tooltip from 'components/Tooltip';

interface IProps {
  name: string;
  maxLength?: number;
  title: string;
  isRequired?: boolean;
  hasQuestionMark?: boolean;
  questionMarkText?: string;
  placeholder?: string;
  lg?: boolean;
}

const TextArea: React.FC<IProps> = (props: IProps) => {
  const { name, maxLength = 300, title } = props;
  const [field] = useField(props.name);
  const valueLength = field.value ? field.value.length : 0;
  const color = valueLength === maxLength ? '#ec3237' : '#9a9a9a';
  const style = { color };

  return (
    <>
      <Container>
        <label>
          <p>{title}</p>
          <small id="carousel-length" style={style}>
            {`(${valueLength}/${maxLength})`}
          </small>
          {props.isRequired && <span>*</span>}
          {props.hasQuestionMark && <Tooltip text={props.questionMarkText} />}
        </label>
        <Field
          enableReinitialize
          validateOnBlur
          name={props.name}
          as="textarea"
          placeholder={props.placeholder}
          maxLength={maxLength}
          className={`${props.lg ? 'max-height' : ''}`}
        />
      </Container>
      <ErrorMessage name={props.name} />
    </>
  );
};

export default TextArea;
