import styled from 'styled-components';
import detailsIcon from 'assets/images/detailsIcon.svg';
import detailsIconHover from 'assets/images/detailsIconHover.svg';
import trashIcon from 'assets/images/trashIcon.svg';
import trashIconHover from 'assets/images/trashIconHover.svg';
import editIcon from 'assets/images/editIconTour.svg';
import editIconHover from 'assets/images/editIconHover.svg';

export const Container = styled.div`
  margin-top: 50px;
  min-height: 100vh;
  width: 100%;
`;

export const PageTitle = styled.div`
  display: flex;
  height: 5px;
  width: 100%;
  div {
    width: 100%;
    &:nth-child(1) {
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0%, #00a759),
        color-stop(90%, #00a759),
        color-stop(90%, #c63336),
        color-stop(100%, #c63336)
      );
    }
    &:nth-child(3) {
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0%, #c63336),
        color-stop(10%, #c63336),
        color-stop(10%, #ffcb29),
        color-stop(100%, #ffcb29)
      );
    }
  }
  span {
    color: #020c90;
    font-weight: 700;
    font-size: 33px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin: 0 10px;
  }
`;

export const BannerCarroussel = styled.div`
  height: 45vh;
  margin-top: 50px;
  div {
    height: 100%;
    width: 100%;
    --slider-height-percentage: 50%;
    --slider-transition-duration: 1000ms;
    --organic-arrow-thickness: 4px;
    --organic-arrow-border-radius: 3px;
    --organic-arrow-height: 20px;
    --organic-arrow-color: #ffffff;
    --control-button-width: 6%;
    --control-button-height: 25%;
    --control-button-background: #ffcb29;
    --control-bullet-color: #ffffff;
    --control-bullet-active-color: #ffffff;
    --loader-bar-color: #c33737;
    --loader-bar-height: 3px;

    .awssld__prev,
    .awssld__next {
      border-radius: 50%;
      width: 40px;
      height: 40px;

      &:hover {
        background-color: #020c90;
      }
    }
    .awssld__prev {
      margin-left: 50px;
    }
    .awssld__next {
      margin-right: 50px;
    }

    .awssld__content {
      background-color: transparent;
      position: relative;
    }

    .awssld__bullets {
      position: absolute;
      margin-bottom: 4%;
      z-index: 2;

      button {
        background: none;
        border-style: solid;
        border-color: white;
        border-width: 2.5px;
        height: 13px;
        width: 13px;
        transform: scale(1);

        &:hover {
          transform: scale(1.1);
        }
      }

      .awssld__bullets--active {
        background-color: white;
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const Arrow = styled.span`
  font-size: 34px;
  font-weight: 100;
`;

export const ActionButtons = styled.div`
  display: flex;
  white-space: nowrap;
  gap: 30px;

  button {
    width: fit-content;
    padding: 10px;
    &:nth-child(2),
    &:nth-child(3) {
      background-color: #fff;
      color: #ffcb29;
      border: 1px solid #ffcb29;
      &:hover {
        background-color: #020c90;
        color: #fff;
      }
    }
  }
`;

export const Content = styled.div`
  padding: 50px 50px;
`;

export const TourBoard = styled.div`
  margin: 50px 0;
  header {
    display: flex;
    margin-bottom: 10px;
    img {
      margin-left: 5px;
      cursor: pointer;
    }
  }
`;

export const Board = styled.section``;

export const BoardLine = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  min-height: 5vh;
  gap: 1vw;
  align-items: center;
  padding: 10px 3vw;
  justify-content: left;
  span {
    &:first-child {
      width: 5vw;
    }
    &:nth-child(2) {
      width: 20vw;
    }
    &:nth-child(3) {
      width: 10vw;
    }
    &:nth-child(4) {
      width: 10vw;
    }
    &:nth-child(5) {
      width: 20vw;
      color: red;
    }
  }
  &:first-child {
    background-color: #020c90;
    color: #fff;
    border-radius: 18px 18px 0 0;
    div {
      white-space: nowrap;
      img {
        margin-left: 5px;
        cursor: pointer;
      }
    }
    span {
      color: #fff;
    }
  }
  &:last-child {
    border-radius: 0 0 18px 18px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  div {
    &:first-child {
      width: 5vw;
    }
    &:nth-child(4) {
      width: 10vw;
    }
    &:nth-child(5) {
      width: 20vw;
    }
    &:last-child {
      button {
        width: 20px;
        height: 20px;
        border: none;
        &:nth-child(1) {
          background: url(${detailsIcon});
          &:hover {
            background: url(${detailsIconHover});
          }
        }
        &:nth-child(2) {
          background: url(${editIcon});
          margin: 0 20px;
          &:hover {
            background: url(${editIconHover});
          }
        }
        &:nth-child(3) {
          background: url(${trashIcon});
          &:hover {
            background: url(${trashIconHover});
          }
        }
      }
    }
  }
`;

export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  border-top: 1px dotted;
  margin: 2vh 0;
`;

export const FAQ = styled.section``;

export const Question = styled.section`
  header {
    display: flex;
    justify-content: space-between;
  }
`;

export const WppButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffcb29;
  border-radius: 18px;
  padding: 10px;
  border: none;
  position: fixed;
  bottom: 0;
  right: 25px;
  z-index: 999;
  img {
    margin-right: 10px;
  }
  span {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &:hover {
    background-color: #020c90;
  }
`;
