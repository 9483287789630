import React, { useEffect, useState } from 'react';
import { SelectMainNewsContainer, SelectMainNewsItem, SelectMainNewsSmall } from './styles';
import Select from 'components/Select';
import NewsService from 'services/NewsService';
import INews from 'interfaces/INews';

const SelectMainNews = (): JSX.Element => {
  const [allNews, setAllNews] = useState<INews[]>([]);
  const [mainNews, setMainNews] = useState<INews>({} as INews);
  const [news1, setNews1] = useState<INews>({} as INews);
  const [news2, setNews2] = useState<INews>({} as INews);
  const service = new NewsService();

  const findAllNews = () => {
    service.findAll().then(response => {
      setAllNews(response.filter(news => news.status === 'published').sort((a, b) => a.title.localeCompare(b.title)));
    });
  };

  const findNewsByLocation = (location: string) => {
    service.findAll().then(response => {
      const news = response.find(news => news.location === location);
      if (location === 'newsMain') {
        setMainNews(news || ({} as INews));
      } else if (location === 'newsOne') {
        setNews1(news || ({} as INews));
      } else if (location === 'newsTwo') {
        setNews2(news || ({} as INews));
      }
    });
  };

  const filterSelectedNewsOptions = (selectedNews: INews | null) => {
    return allNews
      .filter(news => news.id !== selectedNews?.id)
      .map(news => ({ label: news.title, value: news.id.toString() }));
  };

  const filterotherNews1Options = (otherNews1: INews | null) => {
    return allNews
      .filter(news => news.id !== otherNews1?.id)
      .map(news => ({ label: news.title, value: news.id.toString() }));
  };

  const filterotherNews2Options = (otherNews2: INews | null) => {
    return allNews
      .filter(news => news.id !== otherNews2?.id)
      .map(news => ({ label: news.title, value: news.id.toString() }));
  };

  const sendMainNews = (mainNews: INews) => {
    setMainNews(mainNews);
    service.findAll().then(response => {
      const newsMain = response.find(news => news.location === 'newsMain');
      if (newsMain) {
        service.update(newsMain.id, { ...newsMain, location: null });
      } else {
        service.update(mainNews.id, { ...mainNews, location: 'newsMain' });
      }
    });
    service.update(mainNews.id, { ...mainNews, location: 'newsMain' });
  };

  const sendNews1 = (news1: INews) => {
    setNews1(news1);
    service.findAll().then(response => {
      const newsOne = response.find(news => news.location === 'newsOne');
      if (newsOne) {
        service.update(newsOne.id, { ...newsOne, location: null });
      } else {
        service.update(news1.id, { ...news1, location: 'newsOne' });
      }
    });
    service.update(news1.id, { ...news1, location: 'newsOne' });
  };

  const sendNews2 = (news2: INews) => {
    setNews2(news2);
    service.findAll().then(response => {
      const newsTwo = response.find(news => news.location === 'newsTwo');
      if (newsTwo) {
        service.update(newsTwo.id, { ...newsTwo, location: null });
      } else {
        service.update(news2.id, { ...news2, location: 'newsTwo' });
      }
    });
    service.update(news2.id, { ...news2, location: 'newsTwo' });
  };

  useEffect(() => {
    findAllNews();
    findNewsByLocation('newsMain');
    findNewsByLocation('newsOne');
    findNewsByLocation('newsTwo');
  }, []);

  console.log('allNews', allNews);

  return (
    <SelectMainNewsContainer>
      <SelectMainNewsItem>
        <Select
          label="Notícia Principal"
          placeholderText="Selecione a Notícia Principal"
          options={filterSelectedNewsOptions(mainNews)}
          onChange={value => sendMainNews(allNews.find(news => news.id.toString() === value) as INews)}
          selected={[mainNews.title]}
        />
        <SelectMainNewsSmall>Primeira notícia exibida em Notícias e Mídias Sociais.</SelectMainNewsSmall>
      </SelectMainNewsItem>
      <SelectMainNewsItem>
        <Select
          label="Card de Notícia 1"
          placeholderText="Selecione a Notícia 1"
          options={filterotherNews1Options(news1)}
          onChange={value => sendNews1(allNews.find(news => news.id.toString() === value) as INews)}
          selected={[news1.title]}
        />
        <SelectMainNewsSmall>Primeiro card de notícia exibido em Início. </SelectMainNewsSmall>
      </SelectMainNewsItem>
      <SelectMainNewsItem>
        <Select
          label="Card de Notícia 2"
          placeholderText="Selecione a Notícia 2"
          options={filterotherNews2Options(news2)}
          onChange={value => sendNews2(allNews.find(news => news.id.toString() === value) as INews)}
          selected={[news2.title]}
        />
        <SelectMainNewsSmall>Segundo card de notícia exibido em Início.</SelectMainNewsSmall>
      </SelectMainNewsItem>
    </SelectMainNewsContainer>
  );
};

export default SelectMainNews;
