import React from 'react';
import { Container, MapContainer } from './styles';
import SubTitle from 'common/SubTitle/SubTitle';

interface ICityMapProps {
  image: string;
}

const CityMap: React.FC<ICityMapProps> = ({ image }) => {
  return (
    <Container>
      <SubTitle text="Mapa Turístico" />
      <MapContainer>
        <img src={image} width="100%" height="auto" />
      </MapContainer>
    </Container>
  );
};

export default CityMap;
