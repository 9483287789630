import React from 'react';
import QRCode from 'react-qr-code';
import { StampQrCodeDiv } from './styles';

interface StampQrCodeContainerProps {
  value: string;
}

const StampQrCodeContainer: React.FC<StampQrCodeContainerProps> = ({ value }) => {
  return (
    <StampQrCodeDiv>
      <QRCode value={value} />
    </StampQrCodeDiv>
  );
};

export default StampQrCodeContainer;
