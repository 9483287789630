import React, { useState } from 'react';
import yup from 'utils/validation';
import { ButtonsDiv, Divider, FormRow, InputField, SignUpForm } from './styles';
import Button from 'components/Buttom/Button';
import FieldMask from 'components/FieldMask';
import ErrorMessage from 'components/ErrorMessage';
import { Field, Form, Formik } from 'formik';
import InputPassword from 'common/InputPassword/InputPassword';
import SelectComponent from 'components/Select';
import { validateCPF } from '../SignupTourOrganizer/utils';

const initialValues = {
  document: '',
  email: '',
  name: '',
  password: '',
  extraInfo: {
    registration: '',
    role: '',
  },
};

interface RoleSelectProps {
  field: {
    name: string;
    value: string;
    onChange: (value: string) => void;
    onBlur: () => void;
  };
  form: {
    setFieldValue: (field: string, value: string) => void;
  };
}

interface Props {
  handleSubmit(type: string, data: any): Promise<void>;
}

const SignUpSETUR = (props: Props): JSX.Element => {
  const { handleSubmit } = props;
  const [selectedRole, setSelectedRole] = useState<string[]>([]);

  const submit = async (data: any) => {
    if (selectedRole.includes('Excursões')) {
      await handleSubmit('setur', { ...data, extraInfo: { ...data.extraInfo, role: 'Gestor de Excursão' } });
    } else {
      await handleSubmit('setur', data);
    }
  };

  const validationSchema = yup.object({
    document: yup
      .string()
      .trim()
      .matches(/^([\w\.\-\/]{18}|[\w\.\-\/]{14})$/, 'Informe um CPF completo')
      .test('is-valid-cpf', 'Informe um CPF válido', value => validateCPF(value || ''))
      .required(),
    email: yup
      .string()
      .trim()
      .email()
      .matches(/^(?!\d+$).*/, 'O campo email não pode ser apenas números')
      .required(),
    name: yup.string().trim().required(),
    password: yup.string().trim().min(8).required(),
    extraInfo: yup.object({
      registration: yup.string().trim().required(),
      role: yup.string().trim().required(),
    }),
  });

  const roles = ['Mercado', 'Marketing', 'Excursões'];

  const RoleSelect: React.FC<RoleSelectProps> = ({ field, form }) => {
    const { name } = field;

    return (
      <SelectComponent
        label=""
        options={roles.map(role => ({ label: role, value: role }))}
        onChange={value => {
          if (typeof value === 'string') {
            form.setFieldValue(name, value);
            setSelectedRole([value]);
          }
        }}
        name="roles"
        selected={selectedRole}
      />
    );
  };

  return (
    <SignUpForm>
      <header>
        <h2>Preencha as informações abaixo:</h2>
        <span>1/1</span>
      </header>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
        <Form>
          <FormRow>
            <InputField id="cadastur-input">
              <div>
                <label htmlFor="">
                  Nome Completo<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
            <InputField id="company-name-input">
              <div>
                <label htmlFor="">
                  CPF<span>*</span>
                </label>
                <FieldMask type="cpf">
                  <Field name="document" />
                </FieldMask>
              </div>
              <ErrorMessage name="document" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField id="cadastur-input">
              <div>
                <label htmlFor="">
                  Matrícula<span>*</span>
                </label>
                <Field name="extraInfo.registration" />
              </div>
              <ErrorMessage name="extraInfo.registration" />
            </InputField>
            <InputField id="company-name-input">
              <div>
                <label htmlFor="">
                  Setor<span>*</span>
                </label>
                <Field component={RoleSelect} name="extraInfo.role" />
              </div>
              <ErrorMessage name="extraInfo.role" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField id="cadastur-input">
              <div>
                <label htmlFor="">
                  E-mail<span>*</span>
                </label>
                <Field name="email" />
              </div>
              <ErrorMessage name="email" />
            </InputField>
            <InputField>
              <div>
                <label htmlFor="">
                  Senha<span>*</span>
                </label>
                <InputPassword name={'password'} />
                <ErrorMessage name="password" />
              </div>
            </InputField>
          </FormRow>
          <Divider />
          {/* <LabelCheck>
            <Input type="checkbox" />
            <label htmlFor="">
              Aceito os
              <a href="">
                <strong>Termos de Uso</strong>
              </a>
            </label>
          </LabelCheck>
          <LabelCheck>
            <Input type="checkbox" />
            <label htmlFor="">
              Aceito a
              <a href="">
                <strong>Política de Privacidade</strong>
              </a>
            </label>
          </LabelCheck>
          <LabelCheck>
            <Input type="checkbox" checked />
            <label htmlFor="">Desejo receber a newsletter em meu e-mail</label>
          </LabelCheck> */}
          <ButtonsDiv>
            <Button type="submit" text="CRIAR" />
          </ButtonsDiv>
        </Form>
      </Formik>
    </SignUpForm>
  );
};

export default SignUpSETUR;
