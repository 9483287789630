import styled from 'styled-components';

export const StampPcdInformationContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const StampPcdInformationImage = styled.img`
  width: 100px;

  @media (max-width: 768px) {
    width: 70px;
  }
`;

export const StampPcdInformationText = styled.p`
  font-size: 30px;
  text-align: center;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 25px;
  }
`;
