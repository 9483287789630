import React, { useState } from 'react';
import { Modal, Box, Typography, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Button from 'components/Button';
import fileDownIcon from 'assets/images/fileDown.svg';

interface IExcursionExportData {
  id: number;
  createdAt?: string;
  start_date?: string;
  end_date?: string;
  people_number?: number;
  organizer?: {
    name?: string;
    document_number?: string;
  };
  origin_city?: string;
  origin_state?: string;
  transport?: {
    vehicle_plate?: string;
    vehicle_type?: string;
    trade_name?: string;
  };
  accommodation?: {
    name?: string;
  };
  status?: string;
}

interface ExportButtonProps {
  data: IExcursionExportData[];
}

const ExportButton: React.FC<ExportButtonProps> = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [fileType, setFileType] = useState<'xlsx' | 'csv'>('xlsx');
  const [fileName, setFileName] = useState('Portal_Porto_Seguro_Excursões');

  const handleExport = () => {
    if (!data || data.length === 0) {
      alert('Nenhum dado disponível para exportação.');
      return;
    }

    const exportData = data.map(row => ({
      ID: row.id,
      'Criado em': row.createdAt ? new Date(row.createdAt).toLocaleString() : '-',
      Chegada: row.start_date || '-',
      Saída: row.end_date || '-',
      Responsável: row.organizer?.name || '-', // Corrigido
      CPF: row.organizer?.document_number || '-', // Corrigido
      Veículo: row.transport?.vehicle_type || '-', // Novo campo
      'Quantidade de Pessoas': row.people_number || '-', // Novo campo
      'Local de hospedagem': row.accommodation?.name || '-', // Novo campo
      Transportadora: row.transport?.trade_name || '-', // Novo campo
      Origem: row.origin_city ? `${row.origin_city} - ${row.origin_state}` : '-',
      Placa: row.transport?.vehicle_plate || '-',
      Status: row.status || '-',
    }));

    let sanitizedFileName = fileName.trim().replace(/[^a-zA-Z0-9-_]/g, '_');
    if (!sanitizedFileName) sanitizedFileName = 'Exportacao';

    if (fileType === 'xlsx') {
      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Excursões');
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const dataBlob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(dataBlob, `${sanitizedFileName}.xlsx`);
    } else {
      const csvContent = [
        Object.keys(exportData[0]).join(';'),
        ...exportData.map(row => Object.values(row).join(';')),
      ].join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, `${sanitizedFileName}.csv`);
    }

    setOpen(false);
  };

  return (
    <>
      {/* Botão de Download */}
      <Button buttonType="iconButton" onClick={() => setOpen(true)}>
        <img src={fileDownIcon} alt="Exportar" width={24} height={24} />
      </Button>

      {/* Modal de Exportação */}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 320,
            bgcolor: 'white',
            boxShadow: 24,
            p: 3,
            borderRadius: 4,
          }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Exportar dados:
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            O arquivo exportado irá conter os dados filtrados da tabela atual.
          </Typography>

          <Typography variant="body2" sx={{ mt: 2 }}>
            Nome do arquivo:
          </Typography>
          <input
            type="text"
            value={fileName}
            onChange={e => setFileName(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '18px',
              border: '1px solid #ccc',
              fontSize: '14px',
              fontFamily: 'inherit',
            }}
          />

          {/* Opções de Formato */}
          <Typography variant="body2" sx={{ mt: 2 }}>
            Exportar como
          </Typography>
          <RadioGroup
            value={fileType}
            onChange={e => setFileType(e.target.value as 'xlsx' | 'csv')}
            sx={{
              '& .MuiFormControlLabel-root': {
                marginBottom: '4px',
                marginLeft: '0px',
                display: 'flex',
                alignItems: 'center',
              },
              '& .MuiRadio-root': {
                color: '#000',
                padding: '0px',
                marginRight: '6px',
              },
              '& .Mui-checked': {
                color: '#FFD700 !important',
              },
            }}>
            <FormControlLabel value="xlsx" control={<Radio />} label=".xlsx" />
            <FormControlLabel value="csv" control={<Radio />} label=".csv" />
          </RadioGroup>

          {/* Botões */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button buttonType="secondary" onClick={() => setOpen(false)}>
              Cancelar
            </Button>
            <Button buttonType="primary" onClick={handleExport}>
              Exportar
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ExportButton;
