import React, { useEffect, useRef, useState } from 'react';
import {
  ButtonsDiv,
  Container,
  ExcursionFirstForm,
  ExcursionFirstFormContainer,
  FileViewer,
  FormRow4Cols,
  Input,
  InputField,
  InputLabel,
  RadioButtonsContainer,
  StepperBullet,
  StepperContainer,
  StepperItem,
  StepperLine,
  StepperName,
} from '../../styles';
import MainTitle from 'components/MainTitle';
import { Link, useHistory } from 'react-router-dom';
import Button from 'components/Button';
import { Formik, Field, Form, useFormikContext } from 'formik';
import ErrorMessageContext from 'components/ErrorMessageContext';
import FieldMask from 'components/FieldMask';
import {
  states,
  StatesEnum,
  StatesSelectProps,
  destination,
  DestinationEnum,
} from 'features/Signup/pages/SignupTourOrganizer/utils';
import SelectComponent from 'components/Select';
import {
  IconGuia,
  IconHospedagem,
  IconOrganizador,
  IconPagamento,
  IconResumo,
  IconTransporte,
  IconViagem,
} from 'features/NewExcursionForm/components/StepperIcons';
import { tourReasons, TourReasonsEnum, TourReasonsProps } from 'features/NewExcursionForm/utils';
import { Grid } from '@mui/material';
import ConfirmationModal from 'components/ConfirmationModal';
import { useExcursionOrganizatorForm } from 'common/ExcursionOrganizatorFormContext';
import yup from 'utils/validation';
import closeYellow from 'assets/images/closeYellow.svg';
import { useLoading } from 'hooks/useLoading';
import { buscarCidadesPorEstado, CIDADES_POR_ESTADO, Estados } from 'utils/location';
import CitySelectComponent from 'features/NewExcursionForm/components/Select/CitySelectComponent';
import ImageService from 'services/ImageService';
import DocsService from 'services/DocsService';
import ErrorMessage from 'components/ErrorMessage';
import LockNavigatorHook from 'features/NewExcursionForm/hooks/LockNavigatorHook';

interface Form2Props {
  disabledButton: boolean;
}

const Form2: React.FC<Form2Props> = ({ disabledButton }): JSX.Element => {
  const { values, setErrors, setFieldValue } = useFormikContext<any>();
  const { openLoading, closeLoading } = useLoading();
  const history = useHistory();
  const [selectedState, setSelectedState] = useState('' as StatesEnum);
  const [selectedTourReason, setSelectedTourReason] = useState('' as TourReasonsEnum);
  const [selectedDestination, setSelectedDestination] = useState<DestinationEnum[]>([]);
  const [isFileViewerVisible, setIsFileViewerVisible] = useState(false);
  const [isInputFileVisible, setIsInputFileVisible] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [showModal, setShowModal] = useState(false);
  const excursionOrganizatorFormContext = useExcursionOrganizatorForm();
  const [cidades, setCidades] = useState<{ label: string; value: string }[]>([]);

  const fileService = new ImageService();
  const service = new DocsService();

  useEffect(() => {
    if (
      excursionOrganizatorFormContext.form.declaration_file &&
      excursionOrganizatorFormContext.form.declaration_file_signed
    ) {
      setFileToUpload(new File([], excursionOrganizatorFormContext.form.declaration_file));
      setIsFileViewerVisible(true);
    }
  }, [
    excursionOrganizatorFormContext.form.declaration_file,
    excursionOrganizatorFormContext.form.declaration_file_signed,
  ]);

  useEffect(() => {
    if (values.declaration_file) {
      setIsInputFileVisible(true);
    }
  }, [values.declaration_file]);

  const validationSchema = yup.object().shape({
    origin_state: yup.string().required('Campo obrigatório'),
    origin_city: yup
      .string()
      .matches(/^(?!^\d+$)[\d\D]*$/, 'A cidade não pode conter somente números')
      .test('is-valid-city', 'Cidade inválida para o estado selecionado', function (value) {
        if (!value) return false;

        const selectedState = this.parent.origin_state;
        if (!selectedState) return false;

        const cidadesEncontradas = buscarCidadesPorEstado(selectedState as Estados);

        const normalizedUserInput = value
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();

        // Normalizando lista de cidades e verificando se alguma corresponde
        const cidadeExiste = cidadesEncontradas.some(
          cidade =>
            cidade.nomeIBGE
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase() === normalizedUserInput
        );

        return cidadeExiste;
      })
      .required('Campo obrigatório'),
    start_date: yup
      .string()
      .required('Campo obrigatório')
      .test('is-future-or-today', 'A data de chegada não pode ser anterior a hoje', function (value) {
        if (!value) return false;

        const today = new Date().toISOString().split('T')[0];
        const selectedDate = new Date(value).toISOString().split('T')[0];

        return selectedDate >= today;
      }),

    end_date: yup
      .string()
      .required('Campo obrigatório')
      .test(
        'is-after-or-equal-start-date',
        'A data de saída deve ser igual ou posterior à data de chegada',
        function (value) {
          const { start_date } = this.parent;
          return new Date(value) >= new Date(start_date);
        }
      ),
    hour_start: yup.string().required('Campo obrigatório'),
    hour_end: yup.string().required('Campo obrigatório'),
    people_number: yup.number().required('Campo obrigatório').min(1, 'O número mínimo de pessoas é 1').default(1),
    destination: yup
      .array()
      .of(yup.string().required('Campo obrigatório'))
      .min(1, 'Pelo menos um destino deve ser selecionado')
      .required('Campo obrigatório'),
    total_price: yup.string().required('Campo obrigatório'),
    travel_reason: yup.string().required('Campo obrigatório'),
    status: yup.string(),
    declaration_file_signed: yup.string(),
    declaration_file: yup.string(),
    central_access: yup.string().required('Campo obrigatório'),
  });

  const TourReasonsSelect: React.FC<TourReasonsProps> = ({ field, form }) => {
    const { name } = field;
    return (
      <SelectComponent
        label=""
        options={tourReasons.map(reasons => ({ label: reasons.label, value: reasons.value }))}
        onChange={value => {
          if (typeof value === 'string') {
            form.setFieldValue(name, value as TourReasonsEnum);
            setSelectedTourReason(value as TourReasonsEnum);
          }
        }}
        name="travel_reason"
        selected={[selectedTourReason]}
      />
    );
  };

  const onSubmit = async () => {
    try {
      const validationFormInputs = await validationSchema.validate(values, { abortEarly: false });
      let url = excursionOrganizatorFormContext.form.declaration_file_signed || ''; // Reutiliza a URL existente

      if (fileToUpload && !url) {
        // Realiza upload apenas se não existir uma URL
        const link = await fileService.upload(fileToUpload);
        url = link;
        excursionOrganizatorFormContext.setForm({
          ...excursionOrganizatorFormContext.form,
          declaration_file_signed: link,
        });
      }

      if (validationFormInputs) {
        excursionOrganizatorFormContext.setForm({
          ...excursionOrganizatorFormContext.form,
          ...values,
          origin_state: validationFormInputs.origin_state,
          origin_city: validationFormInputs.origin_city,
          start_date: validationFormInputs.start_date,
          end_date: validationFormInputs.end_date,
          hour_start: validationFormInputs.hour_start,
          hour_end: validationFormInputs.hour_end,
          people_number: validationFormInputs.people_number,
          destination: validationFormInputs.destination,
          travel_reason: validationFormInputs.travel_reason,
          declaration_file: fileToUpload?.name || validationFormInputs.declaration_file,
          declaration_file_signed: url,
          central_access: validationFormInputs.central_access === 'true' ? true : false,
          status: 'Aguardando',
        });
        history.push('/admin/new-excursion/step3');
      }
    } catch (error: any) {
      setErrors(
        error.inner.reduce((errors: any, innerError: any) => ({ ...errors, [innerError.path]: innerError.message }), {})
      );
      console.log(
        'setErrors',
        error.inner.reduce((errors: any, innerError: any) => ({ ...errors, [innerError.path]: innerError.message }), {})
      );
      console.error(error);
    }
  };

  const handleCancel = () => {
    excursionOrganizatorFormContext.setForm({
      ...excursionOrganizatorFormContext.form,
      organizer: {
        ...excursionOrganizatorFormContext.form.organizer,
        document_type: '',
        name: '',
        document_number: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
      },
      origin_city: '',
      origin_state: '',
      start_date: '',
      hour_start: '',
      end_date: '',
      hour_end: '',
      people_number: 0,
      destination: [],
      central_access: false,
      travel_reason: '',
      declaration_file: '',
      transport: {
        ...excursionOrganizatorFormContext.form.transport,
        trade_name: '',
        legal_name: '',
        document_number: '',
        cadastur: '',
        phone: '',
        email: '',
        vehicle_type: '',
        vehicle_plate: '',
        has_pcd: false,
      },
      accommodation: {
        ...excursionOrganizatorFormContext.form.accommodation,
        accommodation_type: 0,
        cadastur: '',
        license_number: '',
        document_number: '',
        name: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        justification: '',
        iss: '',
      },
      guide: {
        ...excursionOrganizatorFormContext.form.guide,
        city_tour: false,
        coastal_tour: false,
        type: '',
        guide_name: '',
        cadastur: '',
        phone: '',
        email: '',
      },
      total_price: 0,
    });
    setShowModal(false);
    history.push('/admin/excursions');
  };

  const handleRemoveFile = () => {
    values.declaration_file = '';
    values.declaration_file_signed = '';
    setFileToUpload(null);
    setIsFileViewerVisible(false);
    setIsInputFileVisible(false);
    excursionOrganizatorFormContext.setForm({
      ...excursionOrganizatorFormContext.form,
      declaration_file: '',
      declaration_file_signed: '',
    });
    const fileInput = document.getElementById('declaration_file') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const onImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files?.length) {
      try {
        openLoading();
        const img = event.target.files[0];
        setFileToUpload(img);

        const signedUrl = await fileService.upload(img);

        excursionOrganizatorFormContext.setForm({
          ...excursionOrganizatorFormContext.form,
          declaration_file: img.name,
          declaration_file_signed: signedUrl,
        });
        setIsInputFileVisible(true);
      } catch (error) {
        console.error('Erro ao fazer upload do arquivo:', error);
      } finally {
        closeLoading();
      }
    } else {
      setIsInputFileVisible(false);
    }
  };

  useEffect(() => {
    if (values.origin_state) {
      setSelectedState(values.orgin_state);

      const cidadesEncontradas = CIDADES_POR_ESTADO[values.origin_state as Estados] || [];
      setCidades(
        cidadesEncontradas
          .map(cidade => ({
            label: cidade.nomeIBGE,
            value: cidade.nomeIBGE,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)) // Ordenar em ordem alfabética
      );

      if (values.origin_city) {
        const cidadeExiste = cidadesEncontradas.some(cidade => cidade.nomeIBGE === values.origin_city);
        if (!cidadeExiste) {
          setFieldValue('origin_city', '');
        }
      }
    }
  }, [values.origin_state, values.origin_city, setFieldValue]);

  useEffect(() => {
    if (excursionOrganizatorFormContext.form.travel_reason) {
      setSelectedTourReason(excursionOrganizatorFormContext.form.travel_reason as TourReasonsEnum);
      setFieldValue('travel_reason', excursionOrganizatorFormContext.form.travel_reason); // Atualiza o Formik
      setFieldValue('destination', excursionOrganizatorFormContext.form.destination); // Atualiza o Formik
    }
  }, [
    excursionOrganizatorFormContext.form.travel_reason,
    excursionOrganizatorFormContext.form.destination,
    setFieldValue,
  ]);

  const handleStateChange = async (
    estado: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    setFieldValue('origin_state', estado);

    const cidadesEncontradas = CIDADES_POR_ESTADO[estado as Estados] || [];
    setCidades(
      cidadesEncontradas
        .map(cidade => ({
          label: cidade.nomeIBGE,
          value: cidade.nomeIBGE,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) // Ordenar em ordem alfabética
    );

    setFieldValue('origin_city', ''); // Limpa a cidade selecionada
  };

  console.log('values', values);
  console.log('excursionOrganizatorFormContext.form', excursionOrganizatorFormContext.form);
  console.log('selectedDestination', selectedDestination);

  return (
    <>
      <Form
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onSubmit();
          }
        }}>
        <FormRow4Cols>
          <InputField id="state-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                Estado de Origem<span>*</span>
              </label>
              <Field
                component={({ field, form }: { field: any; form: any }) => (
                  <SelectComponent
                    label=""
                    options={states}
                    onChange={value => {
                      handleStateChange(value as string, form.setFieldValue); // Atualiza o estado e carrega as cidades
                      form.setFieldValue(field.name, value); // Atualiza o estado selecionado no Formik
                    }}
                    name={field.name}
                    selected={field.value ? [field.value] : []} // Mantém o estado selecionado
                  />
                )}
                name="origin_state"
              />
            </div>
            <ErrorMessage name="origin_state" />
          </InputField>

          <InputField id="state-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                Cidade de Origem<span>*</span>
              </label>
              <Field
                name="origin_city"
                component={({ field, form }: { field: any; form: any }) => (
                  <CitySelectComponent
                    label=""
                    name={field.name}
                    options={cidades} // Lista de cidades para o estado selecionado
                    value={field.value} // Valor atual no Formik
                    onChange={selectedCity => form.setFieldValue(field.name, selectedCity)} // Atualiza diretamente a cidade
                    placeholderText="Selecione uma cidade"
                    disabled={!values.origin_state} // Desabilita se não tiver um estado selecionado
                  />
                )}
              />
            </div>
          </InputField>

          <InputField>
            <div>
              <label htmlFor="">
                Data de Chegada<span>*</span>
              </label>
              <FieldMask type="date">
                <Field
                  type="date"
                  name="start_date"
                  min={new Date().toISOString().split('T')[0]}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newDate = e.target.value;

                    // Atualiza o Formik
                    setFieldValue('start_date', newDate);

                    // Atualiza o contexto do formulário
                    excursionOrganizatorFormContext.setForm(prevState => ({
                      ...prevState,
                      start_date: newDate,
                    }));
                  }}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.replace(/(\d{4})\d+/g, '$1');
                  }}
                />
              </FieldMask>
            </div>
            <ErrorMessage name="start_date" />
          </InputField>
          <InputField>
            <div>
              <label htmlFor="">
                Data de Saída<span>*</span>
              </label>
              <FieldMask type="date">
                <Field
                  type="date"
                  name="end_date"
                  placeholder="__/__/____"
                  min={values.start_date || new Date().toISOString().split('T')[0]}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.replace(/(\d{4})\d+/g, '$1'); // Impede mais de 4 dígitos no ano
                  }}
                />
              </FieldMask>
            </div>
            <ErrorMessage name="end_date" />
          </InputField>
        </FormRow4Cols>
        <FormRow4Cols>
          <InputField>
            <div>
              <label htmlFor="">
                Hora de Chegada<span>*</span>
              </label>
              <FieldMask type="time">
                <Field name="hour_start" />
              </FieldMask>
            </div>
            <ErrorMessage name="hour_start" />
          </InputField>
          <InputField>
            <div>
              <label htmlFor="">
                Hora de Saída<span>*</span>
              </label>
              <FieldMask type="time">
                <Field name="hour_end" />
              </FieldMask>
            </div>
            <ErrorMessage name="hour_end" />
          </InputField>
          <InputField className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                Qtde de Pessoas<span>*</span>
                <small> (Incluir motoristas)</small>
              </label>
              <Field type="number" name="people_number" min="1" maxLength={4} />
            </div>
            <ErrorMessage name="people_number" />
          </InputField>
          <InputField id="state-input" className="responsive-grid-column-span-2">
            <div>
              {/* <Field component={DestinationSelect} name="destination" /> */}
              <SelectComponent
                label="Localidade de Destino"
                options={destination}
                onChange={value => {
                  if (Array.isArray(value)) {
                    setFieldValue('destination', value);
                    setSelectedDestination(value as DestinationEnum[]);
                  }
                }}
                required
                name="destination"
                withCheckbox
                selected={values.destination}
              />
            </div>
            <ErrorMessage name="destination" />
          </InputField>
        </FormRow4Cols>
        <FormRow4Cols>
          <InputField id="cadastur-input">
            <div>
              <label htmlFor="central_access">
                Irá acessar o Centro de Porto Seguro?<span>*</span>
              </label>
              <RadioButtonsContainer>
                <label htmlFor="central_access-yes">
                  <Field type="radio" id="central_access-yes" name="central_access" value="true" />
                  <p>Sim</p>
                </label>
                <label htmlFor="central_access-no">
                  <Field type="radio" id="central_access-no" name="central_access" value="false" />
                  <p>Não</p>
                </label>
              </RadioButtonsContainer>
            </div>
            <ErrorMessage name="central_access" />
          </InputField>
          <InputField id="state-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                Motivo da Viagem<span>*</span>
              </label>
              <Field component={TourReasonsSelect} name="travel_reason" />
            </div>
            <ErrorMessage name="travel_reason" />
          </InputField>
        </FormRow4Cols>
        {selectedTourReason && selectedTourReason !== 'Turismo' && (
          <section>
            <h3>Solicitação de Isenção de Pagamento</h3>
            <p style={{ color: '#272727' }}>
              Isenção de pagamento da taxa da excursão fornecida para visitas à trabalho e participação em eventos
              religiosos, esportivos e acadêmicos. Para solicitar o benefício, preencha o{' '}
              <a
                href="https://storage.googleapis.com/bkt-porto-seguro-portal-images-dev/docs/Modelo%20de%20comprovante%20de%20solicita%C3%A7%C3%A3o%20de%20insen%C3%A7%C3%A3o%20de%20taxa%20de%20excurs%C3%A3o.docx"
                target="_self"
                rel="noreferrer">
                modelo disponível para download
              </a>{' '}
              e envie em formato PDF, PNG ou JPEG.
            </p>
            <p style={{ marginBottom: '30px', color: '#272727' }}>
              <strong>Atenção!</strong> Toda solicitação está sujeita à avaliação da Secretaria do Turismo e, caso não
              seja aprovada, será necessário realizar o cadastro da excursão novamente.
            </p>
            <article>
              <Grid item sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                {isInputFileVisible && (
                  <FileViewer>
                    <p>{fileToUpload?.name || excursionOrganizatorFormContext.form.declaration_file}</p>
                    <img src={closeYellow} alt="Fechar" onClick={handleRemoveFile} />
                    {/* {excursionOrganizatorFormContext.form.declaration_file_signed && (
                      <a
                        href={excursionOrganizatorFormContext.form.declaration_file_signed}
                        target="_blank"
                        rel="noopener noreferrer">
                        Visualizar Arquivo
                      </a>
                    )} */}
                  </FileViewer>
                )}

                <InputLabel
                  style={fileToUpload?.name ? { display: 'none' } : { display: 'block' }}
                  htmlFor="declaration_file"
                  onClick={() => {
                    setIsFileViewerVisible(true);
                  }}>
                  Importar Comprovante
                </InputLabel>
              </Grid>
              {isFileViewerVisible && (
                <Input
                  hidden
                  onChange={onImport}
                  accept="application/pdf, image/png, image/jpg, image/jpeg, .doc, .docx"
                  id="declaration_file"
                  name="declaration_file"
                  type="file"
                />
              )}
            </article>
          </section>
        )}
        <ButtonsDiv>
          <Button buttonType="secondary" onClick={() => history.push('/admin/new-excursion/step1')}>
            Voltar
          </Button>
          <article>
            <Button
              type="button"
              buttonType="secondary"
              onClick={e => {
                e.preventDefault();
                setShowModal(true);
              }}>
              Cancelar
            </Button>
            <Button
              type="button"
              buttonType={disabledButton ? 'disabled' : 'primary'}
              onClick={onSubmit}
              disabled={disabledButton}>
              Próximo
            </Button>
          </article>
        </ButtonsDiv>
      </Form>
      {showModal && (
        <ConfirmationModal
          open={showModal}
          onCancel={() => {
            setShowModal(false);
          }}
          onConfirm={handleCancel}
          title="Cancelar Cadastro de Excursão"
          message={
            <p>
              Se prosseguir com a ação, <strong>não será possível recuperar os dados</strong> já perdidos. Deseja
              continuar?
            </p>
          }
          confirmText="Confirmar"
          cancelText="Voltar"
        />
      )}
      {/* <ProofExemptionModal file={fileToUpload} isOpen={isOpen} toggle={toggle} /> */}
    </>
  );
};

const NewExcursionStep2Form = (): JSX.Element => {
  const history = useHistory();
  const excursionOrganizatorFormContext = useExcursionOrganizatorForm();
  const [disabledButton, setDisabledButton] = useState(true);

  LockNavigatorHook(history);

  const initialValues = {
    user_id: excursionOrganizatorFormContext.form.user_id ?? null,
    origin_state: excursionOrganizatorFormContext.form.origin_state ?? '',
    origin_city: excursionOrganizatorFormContext.form.origin_city ?? '',
    start_date: excursionOrganizatorFormContext.form.start_date ?? '',
    end_date: excursionOrganizatorFormContext.form.end_date ?? '',
    hour_start: excursionOrganizatorFormContext.form.hour_start ?? '',
    hour_end: excursionOrganizatorFormContext.form.hour_end ?? '',
    people_number: excursionOrganizatorFormContext.form.people_number ?? 1,
    destination: excursionOrganizatorFormContext.form.destination ?? [],
    total_price: excursionOrganizatorFormContext.form.total_price ?? null,
    travel_reason: excursionOrganizatorFormContext.form.travel_reason ?? '',
    status: excursionOrganizatorFormContext.form.status ?? 'Aguardando',
    declaration_file_signed: excursionOrganizatorFormContext.form.declaration_file_signed ?? '',
    declaration_file: excursionOrganizatorFormContext.form.declaration_file ?? '',
    central_access: excursionOrganizatorFormContext.form.central_access ? 'true' : 'false',
  };

  const validationSchema = yup.object().shape({
    origin_state: yup.string().required('Campo obrigatório'),
    origin_city: yup
      .string()
      .matches(/^(?!^\d+$)[\d\D]*$/, 'A cidade não pode conter somente números')
      .required('Campo obrigatório'),
    start_date: yup
      .string()
      .required('Campo obrigatório')
      .test('is-future-or-today', 'A data de chegada não pode ser anterior a hoje', function (value) {
        if (!value) return false;

        const today = new Date().toISOString().split('T')[0];
        return value >= today;
      }),
    end_date: yup
      .string()
      .required('Campo obrigatório')
      .test(
        'is-after-or-equal-start-date',
        'A data de saída deve ser igual ou posterior à data de chegada',
        function (value) {
          const { start_date } = this.parent;
          return new Date(value) >= new Date(start_date);
        }
      ),
    hour_start: yup.string().required('Campo obrigatório'),
    hour_end: yup
      .string()
      .required('Campo obrigatório')
      .test(
        'is-after-hour-start',
        'A hora de saída deve ser maior que a hora de chegada se as datas forem iguais',
        function (value) {
          const { start_date, end_date, hour_start } = this.parent;

          if (start_date === end_date) {
            return value > hour_start;
          }

          return true;
        }
      ),
    people_number: yup.number().required('Campo obrigatório').min(1, 'O número mínimo de pessoas é 1').default(1),
    destination: yup
      .array()
      .of(yup.string().required('Campo obrigatório'))
      .min(1, 'Pelo menos um destino deve ser selecionado')
      .required('Campo obrigatório'),
    total_price: yup.string().required('Campo obrigatório'),
    travel_reason: yup.string().required('Campo obrigatório'),
    status: yup.string(),
    declaration_file_signed: yup.string(),
    declaration_file: yup.string(),
    central_access: yup.string().required('Campo obrigatório'),
  });

  console.log('excursionOrganizatorFormContext.form', excursionOrganizatorFormContext.form);
  console.log('initialValues', initialValues);

  return (
    <Container>
      <MainTitle>Cadastro de Excursão</MainTitle>

      <StepperContainer>
        <StepperItem className="flex flex-col items-center justify-center">
          <article>
            <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step1')}>
              <IconOrganizador color="#ffffff" />
            </StepperBullet>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Organizador</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <StepperBullet className="onEdit" onClick={e => e.preventDefault()}>
              <IconViagem color="#020c90" />
            </StepperBullet>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Viagem</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <StepperBullet onClick={e => e.preventDefault()}>
              <IconTransporte color="#ffffff" />
            </StepperBullet>
            <StepperLine />
          </article>
          <p>Transporte</p>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet onClick={e => e.preventDefault()}>
                <IconHospedagem />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <p>Hospedagem</p>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet onClick={e => e.preventDefault()}>
                <IconGuia />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <p>Guia</p>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet onClick={e => e.preventDefault()}>
                <IconPagamento />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <p>Pagamento</p>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet onClick={e => e.preventDefault()}>
                <IconResumo />
              </StepperBullet>
            </Link>
          </article>
          <p>Resumo</p>
        </StepperItem>
      </StepperContainer>

      <ExcursionFirstFormContainer>
        <p>*Campo Obrigatório</p>

        <ExcursionFirstForm>
          <h2>Dados da viagem:</h2>

          <Formik
            validate={values => {
              const isValid = validationSchema.isValidSync(values);
              const hasDestination = values.destination && values.destination.length > 0;
              setDisabledButton(!(isValid && hasDestination));
            }}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur
            onSubmit={() => {
              null;
            }}>
            <Form2 disabledButton={disabledButton} />
          </Formik>
        </ExcursionFirstForm>
      </ExcursionFirstFormContainer>
    </Container>
  );
};

export default NewExcursionStep2Form;
