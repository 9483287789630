import styled from 'styled-components';
import Button from 'components/Button';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  width: 100%;
  min-height: 60vh;

  @media (max-width: 768px) {
    margin-top: 0;
    width: calc(100% + 32px);
    margin-left: -16px;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .header h2 {
    margin-bottom: 0;
  }
  .header button {
    width: auto;
  }

  .users-account button,
  .users-account hr {
    display: none;
  }

  .createdDate {
    display: flex;
    flex-direction: column;
  }

  .approvedBadge,
  .reprovedBadge,
  .newProfileBadge,
  .deleteRequestBadge,
  .annaliseBadge,
  .processingBadge,
  .canceledBadge,
  .blockBadge {
    border-radius: 18px;
    padding: 5px 10px;
    color: #fff;
  }
  .approvedBadge {
    border: none;
    background: #00b050;
  }
  .reprovedBadge {
    background: #c63336;
  }
  .newProfileBadge {
    background: #ffcb29;
  }
  .deleteRequestBadge {
    background: #020c90;
  }
  .blockBadge {
    background: #d0a633;
  }
  .annaliseBadge {
    background: #ffcb29;
  }
  .processingBadge {
    background: #d0a633;
  }
  .canceledBadge,
  notDefinedBadge {
    background: #9a9a9a;
  }

  // Styling MUI DataGrid //

  .dataGrid {
    text-align: center !important;
  }

  .MuiDataGrid-root {
    width: 100%;
    height: 100%;
    overflow-x: auto;
  }

  .MuiDataGrid-columnHeader {
    background: #020c90;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    text-align: center !important;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    top: 0;
    position: sticky;

    .MuiSvgIcon-root {
      color: #fff;
      opacity: 1 !important;
    }
  }

  .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-virtualScrollerRenderZone {
    text-align: center;
  }

  .css-1rtnrqa {
    display: none;
  }

  .css-68pk0f {
    width: 40%;

    @media all and (max-width: 768px) {
      width: 100%;
    }
  }

  .css-1knaqv7-MuiButtonBase-root-MuiButton-root {
    display: none;
  }

  .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter {
    width: 40%;

    @media all and (max-width: 768px) {
      width: 100%;
    }
  }

  .button-icon {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0 5px;

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
      &:hover {
        filter: none;
      }
    }

    img {
      width: 20px;

      &:hover {
        filter: brightness(0) saturate(100%) invert(16%) sepia(41%) saturate(7143%) hue-rotate(237deg) brightness(63%)
          contrast(126%);
      }
    }
  }

  ::-webkit-scrollbar {
    width: 25px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #9a9a9a;
    height: 1px;
    border-radius: 10px;
  }
`;

export const ExcursionsButtonsContainer = styled.section`
  display: flex;
  justify-content: flex-start;
  padding: 20px 50px 0 50px;
  width: 100%;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  div {
    height: auto;
    padding-bottom: 10px;

    ::-webkit-scrollbar-thumb {
      background-color: #fff;
    }
  }

  button {
    margin-right: 20px;

    @media (max-width: 768px) {
      font-size: 13px;
    }
  }
`;

export const ExcursionsNavigationButtonsContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 2rem;
  width: 100%;
  max-width: 100%;
  padding: 0 50px;
  z-index: 1;

  @media (max-width: 768px) {
    margin-top: 10px;
    margin-left: 34px;
    padding: 0 16px;
    width: 100%;
    max-width: calc(100% - 32px); /* Garante alinhamento com o DataGrid */
    justify-content: flex-start;
  }

  button {
    margin: 0;
    width: auto;

    @media (max-width: 768px) {
      font-size: 13px;
    }
  }
`;

export const WppButton = styled(Button)`
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 0 20px 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  z-index: 10;

  img {
    margin-right: 8px;
  }
`;

export const StatusButton = styled.button`
  font-size: 14px;
  font-weight: 500;
  border-radius: 18px;
  border: none;
  padding: 5px 10px;
  color: #fff;

  &.approvedBadge {
    background: #00b050;
  }

  &.reprovedBadge {
    background: #c63336;
  }

  &.newProfileBadge {
    background: #ffcb29;
  }

  &.deleteRequestBadge {
    background: #020c90;
  }

  &.blockBadge {
    background: #d0a633;
  }

  &.annaliseBadge {
    background: #ffcb29;
  }

  &.processingBadge {
    background: #d0a633;
  }
`;

export const StatusButtonDashedLine = styled.hr`
  border: 1px dashed #ccc;
  margin: 0;
`;
