import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  label {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;

    p {
      color: #272727;
    }
  }

  small {
    margin-left: 8px;
  }

  img {
    margin-left: 5px;
  }

  textarea {
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #9a9a9a !important;
    border-radius: 18px;
    font-size: 16px;
    color: #333;
    resize: none;

    &::-webkit-scrollbar {
      margin-right: 500px;
      width: 5px;
      height: 5px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #9a9a9a;
      border-radius: 5px;
    }
  }

  .max-height {
    height: 300px;
  }
`;
