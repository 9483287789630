import React, { useState, useRef } from 'react';
import { Popper, Paper, Box, Typography, ClickAwayListener } from '@mui/material';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

dayjs.extend(customParseFormat);

interface IExcursionsDateFilterProps {
  onFilterByDate: (startDate: string | null, endDate: string | null) => void;
}

const DateFilter: React.FC<IExcursionsDateFilterProps> = ({ onFilterByDate }) => {
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [tempStartDate, setTempStartDate] = useState<string>('');
  const [tempEndDate, setTempEndDate] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [error, setError] = useState<string | null>(null);
  const startDateRef = useRef<HTMLInputElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setTempStartDate(startDate);
    setTempEndDate(endDate);
    setTimeout(() => {
      startDateRef.current?.focus();
    }, 0);
  };

  const handleClose = () => {
    if (!error) {
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
    } else {
      setTempStartDate(startDate);
      setTempEndDate(endDate);
      setError(null);
    }
    setAnchorEl(null);
  };

  const handleClearFilter = (event: React.MouseEvent) => {
    event.stopPropagation();
    setStartDate('');
    setEndDate('');
    setError(null);
    setAnchorEl(null);
    onFilterByDate(null, null);
  };

  const formatDate = (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d{0,2})/, '$1/$2')
      .replace(/^(\d{2})\/(\d{2})(\d{0,4})/, '$1/$2/$3')
      .substring(0, 10);
  };

  const applyFilter = (start: string, end: string) => {
    const startDateValid = start ? dayjs(start, 'DD/MM/YYYY', true) : null;
    const endDateValid = end ? dayjs(end, 'DD/MM/YYYY', true) : null;

    if (start && !startDateValid?.isValid()) {
      setError('Data inicial inválida.');
      return;
    }

    if (end && !endDateValid?.isValid()) {
      setError('Data final inválida.');
      return;
    }

    if (startDateValid && endDateValid && startDateValid.isAfter(endDateValid)) {
      setError('A data inicial não pode ser maior que a final.');
      return;
    }

    setError(null);
    setStartDate(start);
    setEndDate(end);

    // 🔹 Agora aceita apenas um dos campos preenchidos
    onFilterByDate(start || null, end || null);
    setTimeout(() => setAnchorEl(null), 100);
  };

  return (
    <>
      <div
        onClick={handleOpen}
        style={{
          width: '100%',
          height: '40px',
          padding: '10px',
          paddingLeft: '14px',
          border: '1px solid #ccc',
          borderRadius: '20px',
          background: '#fff',
          cursor: 'pointer',
          textAlign: 'left',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: '#9A9A9A',
        }}>
        {startDate && endDate ? `${startDate} - ${endDate}` : 'Período'}
        {startDate && endDate ? (
          <span
            onClick={handleClearFilter}
            style={{
              cursor: 'pointer',
              color: '#9A9A9A',
              fontSize: '14px',
              padding: '10px',
            }}>
            ✖
          </span>
        ) : (
          <ExpandMoreIcon sx={{ marginLeft: '5px' }} />
        )}
      </div>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          { name: 'preventOverflow', options: { boundary: 'window' } },
          { name: 'offset', options: { offset: [0, 2] } },
        ]}
        style={{ zIndex: 10000 }}>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper sx={{ padding: 2, width: 260, boxShadow: 3, borderRadius: 4 }}>
            <Box display="flex" flexDirection="column" gap={1}>
              <label style={{ fontSize: '14px', fontWeight: 'bold' }}>De</label>
              <input
                ref={startDateRef}
                type="text"
                value={tempStartDate}
                onChange={e => setTempStartDate(formatDate(e.target.value))}
                placeholder="dd/mm/aaaa"
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '10px',
                  border: '1px solid #ccc',
                  outline: 'none',
                }}
              />
              <label style={{ fontSize: '14px', fontWeight: 'bold' }}>Até</label>
              <input
                type="text"
                value={tempEndDate}
                onChange={e => {
                  const formattedValue = formatDate(e.target.value);
                  setTempEndDate(formattedValue);
                  if (formattedValue.length === 10) {
                    setTimeout(() => applyFilter(tempStartDate, formattedValue), 100);
                  }
                }}
                placeholder="dd/mm/aaaa"
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '10px',
                  border: '1px solid #ccc',
                  fontSize: '14px',
                  outline: 'none',
                }}
              />
              {error && (
                <Typography color="error" fontSize={12}>
                  {error}
                </Typography>
              )}
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default DateFilter;
