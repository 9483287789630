import React from 'react';
import * as formik from 'formik';
import { Paragraph } from './styles';

interface IProps {
  name: string;
}

const ErrorMessageContext = (props: IProps): JSX.Element => {
  const { name } = props;
  const { touched, errors } = formik.useFormikContext();

  const renderError = () => <Paragraph className="error-message">{(errors as any)[name]}</Paragraph>;

  return renderError();
};

export default ErrorMessageContext;
