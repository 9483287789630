import React from 'react';
import { ListItem, Menu } from '../DefaultMenu/styles';
import cameraIcon from '../../assets/images/camera_menu_item.svg';
import eventsIcon from '../../assets/images/events_menu_item.svg';
import storeIcon from '../../assets/images/storeIcon.svg';
import { NavLink } from 'react-router-dom';
import { usePortalContext } from 'common/PortalContext';

const TradeMenu: React.FC = () => {
  const PortalContext = usePortalContext();
  const midiaKitURL = PortalContext?.midiaKitURL;

  return (
    <Menu>
      <ul>
        <ListItem>
          <NavLink activeClassName="selected" to="/admin/trade/my-business">
            <img src={storeIcon} alt="icon for list item" />
            <span>Estabelecimentos e Atividades</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink exact activeClassName="selected" to="/admin/trade/schedule">
            <img src={eventsIcon} alt="icon for list item" />
            <span>Agenda</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <a href={midiaKitURL} target="_blank" rel="noopener noreferrer">
            <img src={cameraIcon} alt="icon for list item" />
            <span>Mídia Kit</span>
          </a>
        </ListItem>
      </ul>
    </Menu>
  );
};

export default TradeMenu;
