import React from 'react';
import PcdStampIcon from '../../../../assets/images/pcdStampIcon.svg';
import { StampPcdInformationContainer, StampPcdInformationImage, StampPcdInformationText } from './styles';

interface StampPcdInformationProps {
  visible: boolean;
}

const StampPcdInformation: React.FC<StampPcdInformationProps> = ({ visible }) => {
  switch (visible) {
    case true:
      return (
        <StampPcdInformationContainer>
          <StampPcdInformationImage src={PcdStampIcon} />
          <StampPcdInformationText>
            Lei nº 10.098, de 19 de dezembro de 2000 <br />
            (acessibilidade)
          </StampPcdInformationText>
        </StampPcdInformationContainer>
      );
    case false:
      return null;
  }
};

export default StampPcdInformation;
