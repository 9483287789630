export const navigateBasedOnProfile = (profileName: string): string => {
  switch (profileName) {
    case 'admin':
      return '/admin/observatory-tourist/overview';
    case 'news':
    case 'news-external':
      return '/news';
    case 'trade':
      return '/admin/trade/my-business';
    case 'setur-market':
      return '/admin/tourist-offers/business-list';
    case 'setur-marketing':
      return '/admin/config';
    case 'tour-pf':
      return '/admin/excursions';
    case 'tour-pj':
      return '/admin/excursions';
    case 'tour-admin':
      return '/admin/excursions';
    default:
      return '/';
  }
};
