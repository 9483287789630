import styled from 'styled-components';

export const FieldArrayContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FieldGroup = styled.article`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;

  input {
    margin-top: 0px !important;
    width: 100%;
    height: 5vh;
    display: flex;
    border-radius: 18px;
    border: 1px solid #9a9a9a;
    padding: 0 0.5vw;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 0px !important;
  width: 40px;
  border-radius: 20px;
  background: #ffcb29;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border: none;
`;

export const SecondaryButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-top: 0px !important;
  width: 40px;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border: 2px solid #ffcb29;
`;

export const FieldGroupPlusButton = styled.article`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;
