import React from 'react';
import Modal from 'components/Modal';
import Heading from 'components/Heading';
import Button from 'components/Button';
import { ButtonsDiv, Container } from './styles';
import { LoadingButton, PrimaryButton, Spinner } from 'components/Button/styles';

interface IProps {
  message: string | JSX.Element;
  open?: boolean;
  title?: string;
  type?: 'button' | 'submit' | 'reset';
  confirmText?: string;
  cancelText?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  isLoading?: boolean;
}

const ConfirmationModal = (props: IProps): JSX.Element => {
  const open = props.open || false;

  return (
    <Modal open={open} onClose={props.onCancel}>
      <Container>
        <Heading>{props.title || 'Confirmação'}</Heading>
        <p>{props.message}</p>
        <ButtonsDiv>
          <Button onClick={props.onCancel} buttonType="secondary" disabled={props.isLoading}>
            {props.cancelText || 'Cancelar'}
          </Button>

          {props.isLoading ? (
            <LoadingButton disabled>
              Processando... <Spinner />
            </LoadingButton>
          ) : (
            <PrimaryButton onClick={props.onConfirm} type={props.type}>
              {props.confirmText || 'Sim'}
            </PrimaryButton>
          )}
        </ButtonsDiv>
      </Container>
    </Modal>
  );
};

export default ConfirmationModal;
