import React, { useState, useRef } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  InputLabel,
  Popper,
  Paper,
  ClickAwayListener,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { selectStyles, menuItemStyles, checkboxStyles, listItemTextStyles } from './styles'; // Importando os estilos

interface StatusFilterProps {
  onFilterByStatus: (selectedStatus: string[]) => void;
}

const StatusFilter: React.FC<StatusFilterProps> = ({ onFilterByStatus }) => {
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const statusOptions = [
    'Isenção em Análise',
    'Em Processamento',
    'Pagamento Aprovado',
    'Pagamento Recusado',
    'Isenção Aprovada',
    'Isenção Recusada',
    'Cancelada',
  ];

  const handleStatusChange = (status: string) => {
    const newStatus = selectedStatus.includes(status)
      ? selectedStatus.filter(s => s !== status) // Remove se já estiver selecionado
      : [...selectedStatus, status]; // Adiciona se não estiver selecionado

    setSelectedStatus(newStatus);
    onFilterByStatus(newStatus); // Aplica o filtro imediatamente
  };

  const handleToggle = () => {
    if (open) {
      onFilterByStatus(selectedStatus); // Aplica o filtro ao fechar
    }
    setOpen(prev => !prev);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }
    setOpen(false);
    onFilterByStatus(selectedStatus); // Aplica o filtro ao clicar fora
  };

  return (
    <Box ref={anchorRef} sx={{ position: 'relative', width: '100%' }}>
      <FormControl fullWidth>
        <InputLabel
          shrink={false}
          sx={{
            position: 'absolute',
            left: '12px',
            top: '50%',
            transform: 'translateY(-50%)',
            color: '#9A9A9A',
            transition: 'all 0.2s',
            pointerEvents: 'none',
            display: selectedStatus.length > 0 ? 'none' : 'block',
            fontSize: '16px',
            fontFamily: 'Signika',
          }}>
          Status
        </InputLabel>
        <Select
          multiple
          open={false} // Desativa o dropdown padrão do MUI
          onClick={handleToggle} // Fecha ou abre ao clicar
          value={selectedStatus}
          renderValue={selected => (selected.length > 0 ? selected.join(', ') : '')}
          IconComponent={props => <ExpandMoreIcon {...props} sx={{ color: '#9A9A9A' }} />}
          sx={selectStyles}
        />
      </FormControl>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        modifiers={[
          {
            name: 'offset',
            options: { offset: [0, 2] },
          },
          {
            name: 'preventOverflow',
            options: {
              boundary: 'window',
              rootBoundary: 'document',
              padding: 0,
            },
          },
        ]}
        sx={{
          zIndex: 1200,
          width: anchorRef.current?.offsetWidth,
          minWidth: '220px',
          maxWidth: '300px',
          '@media (max-width: 768px)': {
            transformOrigin: 'top right',
            position: 'absolute !important',
          },
        }}>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper
            sx={{
              borderRadius: '10px',
              boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
              width: '100%',
              marginRight: '0px',
            }}>
            {statusOptions.map(status => (
              <MenuItem
                key={status}
                onClick={() => handleStatusChange(status)}
                sx={{
                  ...menuItemStyles,
                  paddingRight: '0px',
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: '100%',
                }}>
                <Checkbox checked={selectedStatus.includes(status)} sx={checkboxStyles} />
                <ListItemText
                  primary={status}
                  sx={{
                    ...listItemTextStyles,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                />
              </MenuItem>
            ))}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

export default StatusFilter;
//
