import React from 'react';
import { StampInformationList, StampInformationListItem, StampInformationListItemText } from './styles';

interface StampContainerListProps {
  information: { name: string; vehicle: string; plate: string; period: string }[];
}

const StampContainerList: React.FC<StampContainerListProps> = ({ information }) => {
  return (
    <StampInformationList>
      <StampInformationListItem>
        <StampInformationListItemText>
          <strong>Responsável: </strong>
          {information[0].name}
        </StampInformationListItemText>
      </StampInformationListItem>
      <StampInformationListItem>
        <StampInformationListItemText>
          <strong>Veículo: </strong>
          {information[0].vehicle}
        </StampInformationListItemText>
      </StampInformationListItem>
      <StampInformationListItem>
        <StampInformationListItemText>
          <strong>Placa: </strong>
          {information[0].plate}
        </StampInformationListItemText>
      </StampInformationListItem>
      <StampInformationListItem>
        <StampInformationListItemText>
          <strong>Período: </strong>
          {information[0].period}
        </StampInformationListItemText>
      </StampInformationListItem>
    </StampInformationList>
  );
};

export default StampContainerList;
