import styled from 'styled-components';

export const UploadImageContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
`;

export const UploadImageHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media all and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const Input = styled.input``;

export const InputLabel = styled.label`
  border-radius: 18px;
  box-shadow: 0px 0px 10px 0px #00000026;
  text-align: center;
  color: #fff !important;
  width: 12.5rem;
  padding: 0.5rem;
  background: #ffcb29;
  cursor: pointer;

  &.disabledButton {
    background: #ccc;
    cursor: not-allowed;
    box-shadow: none;

    &:hover {
      background: #ccc;
    }
  }

  &:hover {
    background-color: #020c90;
  }
`;

export const UploadImageTitle = styled.h2`
  color: #272727;
  font-size: 16px;
  font-weight: 500;
  max-width: 80%;

  @media all and (max-width: 800px) {
    max-width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  span {
    color: #f00;
  }
`;

export const UploadImageList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
`;

export const UploadImageListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 0.5rem;
  padding: 0.5rem;
  width: 118px;
  height: 118px;
  border-radius: 0.5rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  @media all and (max-width: 550px) {
    width: 80px;
    height: 80px;
  }
`;

export const CloseImageButton = styled.button`
  width: 30px;
  height: 30px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  border: none;
  background: #0f0;
  border-radius: 20px;
  background: #ffcb29;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover {
    background-color: #020c90;
  }
`;

export const LocalErrorMessage = styled.p`
  color: #f00;
  font-size: 14px;
  width: 100%;
`;
