import React, { useEffect, useState } from 'react';
import { HomePageDiv } from './styles';
import BannerCarousel from 'common/BannerCarousel/BannerCarousel';
import Destinations from 'common/Destinations/Destinations';
import Tourism from 'common/Tourism/Tourism';
import Schedule from 'common/Schedule/Schedule';
import News from 'common/News/News';
import SocialMedia from 'common/SocialMedia/SocialMedia';
import IHomeData from 'interfaces/IHomeData';
import PublicService from 'services/PublicService';
import HomeFooter from 'common/HomeFooter';
import { useLoading } from 'hooks/useLoading';
import QuizModal from 'features/QuizModal';
import CityMap from 'common/CityMap';

const HomePage = (): JSX.Element => {
  const [data, setHomeData] = useState<IHomeData | undefined>();
  const { openLoading, closeLoading } = useLoading();
  const mapImage =
    'https://storage.googleapis.com/bkt-porto-seguro-portal-images-dev/images/Mapa%20Turistico/mapa-tur%C3%ADstico-porto%20seguro.svg';

  const getData = async () => {
    try {
      openLoading();
      const service = new PublicService();
      await service.getHomeData().then(setHomeData);
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <HomePageDiv>
      <BannerCarousel banners={data?.banners} />
      <Destinations destinations={data?.destinations} />
      <Tourism experiences={data?.experiences} />
      <Schedule events={data?.events} />
      <News news={data?.news} />
      <SocialMedia />
      <CityMap image={mapImage} />
    </HomePageDiv>
  );
};

export default HomePage;
