import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridColumnVisibilityModel,
  GridToolbar,
} from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import infoIcon from 'assets/images/info.svg';
import xGrayIcon from 'assets/images/xgray_Icon.svg';
import checkGrayIcon from 'assets/images/checkgray_Icon.svg';
import blockGrayIcon from 'assets/images/blockGrayIcon.svg';
import { IUserAPI as IUser, UserStatusAPI, UserType } from 'interfaces/IUser';
import UserService from 'services/UserService';
import Heading from 'components/Heading';
import ConfirmationModal from 'components/ConfirmationModal';
import MessageModal from 'common/MessageModal';
import { useLoading } from 'hooks/useLoading';
import { useHistory } from 'react-router-dom';
import MainTitle from 'components/MainTitle';

const UserPanel = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [usersData, setUsersData] = useState<IUser[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
  });
  const [openModalApproveSolicitationData, setOpenModalApproveSolicitationData] = useState(false);
  const [openModalDeleteSolicitationData, setOpenModalDeleteSolicitationData] = useState(false);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);
  const history = useHistory();

  useEffect(() => {
    openLoading();
    const fetchData = async () => {
      const data = await service.users.findAll();
      setUsersData(data);
      closeLoading();
    };
    fetchData();
  }, []);

  const service = {
    users: new UserService(),
  };

  const stylingRowStatus = (status?: UserStatusAPI) => {
    switch (status) {
      case UserStatusAPI.APPROVED:
        return <span className="approvedBadge">Aprovado</span>;
      case UserStatusAPI.REFUSED:
        return <span className="reprovedBadge">Recusado</span>;
      case UserStatusAPI.NEW_PROFILE:
        return <span className="newProfileBadge">Novo Perfil</span>;
      case UserStatusAPI.DELETE_REQUEST:
        return <span className="deleteRequestBadge">Solicitação de Exclusão</span>;
      case UserStatusAPI.BLOCK:
        return <span className="blockBadge">Bloqueado</span>;
      default:
        return '- - -';
    }
  };

  const formatCreatedAtDate = (createdAt: string): JSX.Element => {
    const date = new Date(createdAt);
    const formattedDate = date.toLocaleDateString('pt-BR');
    const formattedTime = date.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });

    return (
      <div className="createdDate">
        <span>{formattedDate}</span>
        <span>{formattedTime}</span>
      </div>
    );
  };

  const getProfileType = (user: IUser) => {
    switch (user.type) {
      case UserType.ADMIN:
        return 'Superadmin';
      case UserType.NEWS:
        return 'Imprensa';
      case UserType.NEWS_EXTERNAL:
        return 'Imprensa (Externo)';
      case UserType.SETUR:
        return 'SETUR';
      case UserType.SETUR_MARKET:
        return 'SETUR (Mercado)';
      case UserType.SETUR_MARKETING:
        return 'SETUR (Marketing)';
      case UserType.SETUR_FUNDETUR:
        return 'SETUR (FUNDETUR)';
      case UserType.SETUR_ATTORNEY:
        return 'SETUR (Procuradoria)';
      case UserType.SETUR_EXCURSION:
        return 'SETUR (Excursão)';
      case UserType.TOUR:
        return 'Organizador de excursão';
      case UserType.TOUR_ADMIN:
        return 'SETUR (Excursão)';
      case UserType.TOUR_PF:
        return 'Organizador de excursão';
      case UserType.TOUR_PJ:
        return 'Organizador de excursão';
      case UserType.TOURIST:
        return 'Turista';
      case UserType.TRADE:
        return 'Trade';
      default:
        return '- - -';
    }
  };

  const handleMoreInfoClick = (userId: string) => {
    history.push(`/admin/users-panel/${userId}`);
  };

  const handleActionClick = (id: number, showModal: React.Dispatch<React.SetStateAction<boolean>>) => () => {
    setSelectedId(id);
    showModal(true);
  };

  const callAction = async (action: 'approve' | 'refuse' | 'block', status: UserStatusAPI) => {
    setOpenModalApproveSolicitationData(false);
    setOpenModalDeleteSolicitationData(false);
    setOpenBlockModal(false);

    openLoading();

    try {
      switch (action) {
        case 'approve':
          await service.users.approve(selectedId);
          break;
        case 'refuse':
          await service.users.refuse(selectedId);
          break;
        case 'block':
          await service.users.block(selectedId);
          break;
      }
      const user = usersData.find(({ id }) => id === selectedId);

      if (user) {
        user.status = status;
      }
      setSuccessMessage(true);
    } catch (err) {
      setErrorMessage(true);
    } finally {
      closeLoading();
    }
  };

  const usersColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'createdAt',
      headerName: 'Criação',
      flex: 1,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
      renderCell: (params: GridRenderCellParams<IUser, string, keyof IUser, GridTreeNodeWithRender>) =>
        formatCreatedAtDate(params.value as string),
    },
    {
      field: 'name',
      headerName: 'Nome do Usuário',
      flex: 1,
      minWidth: 220,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      flex: 1,
      minWidth: 220,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'type',
      headerName: 'Permissão',
      flex: 1,
      minWidth: 220,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
      renderCell: (param: GridRenderCellParams<IUser, string, keyof IUser, GridTreeNodeWithRender>) => {
        return getProfileType(param.row);
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 222,
      renderCell: (params: GridRenderCellParams<IUser, UserStatusAPI, keyof IUser, GridTreeNodeWithRender>) =>
        stylingRowStatus(params.value),
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      flex: 1,
      minWidth: 85,
      getActions: params => [
        <div key={params.id} style={{ width: 85, textAlign: 'initial' }}>
          <button className="button-icon" title="Mais informações" onClick={() => handleMoreInfoClick(params.row.id)}>
            <img src={infoIcon} alt="Mais informações" />
          </button>
          {params.row.status === UserStatusAPI.NEW_PROFILE && (
            <>
              <button
                className="button-icon"
                title="Excluir"
                onClick={handleActionClick(params.row.id, setOpenModalDeleteSolicitationData)}>
                <img src={xGrayIcon} alt="Excluir" />
              </button>
              <button
                className="button-icon"
                title="Confirmar"
                onClick={handleActionClick(params.row.id, setOpenModalApproveSolicitationData)}>
                <img src={checkGrayIcon} alt="Confirmar" />
              </button>
            </>
          )}
          {[UserStatusAPI.APPROVED, UserStatusAPI.DELETE_REQUEST].includes(params.row.status) && (
            <button
              className="button-icon"
              title="Bloquear"
              onClick={handleActionClick(params.row.id, setOpenBlockModal)}>
              <img src={blockGrayIcon} alt="Bloquear" />
            </button>
          )}
        </div>,
      ],
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
  ];

  return (
    <>
      <MainTitle>Painel de Usuários</MainTitle>
      <Container>
        <div className="header">
          <Heading>Lista de Usuários</Heading>
        </div>

        <Grid sx={{ width: '100%', marginBottom: '50px', textAlign: 'center' }}>
          <DataGrid
            sx={{
              background: '#fff',
              boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
              borderRadius: '18px',
              width: '100%',
            }}
            rows={usersData}
            columns={usersColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            className="dataGrid"
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={newModel => setColumnVisibilityModel(newModel)}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                densitySelector: false,
                exportButton: false,
              },
            }}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableColumnMenu
            disableDensitySelector
            disableVirtualization
          />
        </Grid>
        <MessageModal
          isOpen={successMessage}
          title="Sucesso"
          message="Operação realizada com sucesso!"
          onClose={() => setSuccessMessage(false)}
        />
        <MessageModal
          isOpen={errorMessage}
          title="Erro"
          message="Erro ao realizar ação com usuário!"
          onClose={() => setErrorMessage(false)}
        />
        <ConfirmationModal
          open={openModalDeleteSolicitationData}
          title="RECUSA DE CONTA DE USUÁRIO"
          message="Você tem certeza de que deseja recusar essa conta de usuário? Se continuar com a ação, não será possível desfazê-la."
          confirmText="Recusar"
          onCancel={() => setOpenModalDeleteSolicitationData(false)}
          onConfirm={() => callAction('refuse', UserStatusAPI.REFUSED)}
        />
        <ConfirmationModal
          open={openModalApproveSolicitationData}
          title="APROVAÇÃO DE CONTA DE USUÁRIO"
          message="Você tem certeza de que deseja aprovar essa conta de usuário? Se continuar com a ação, não será possível desfazê-la."
          confirmText="Aprovar"
          onCancel={() => setOpenModalApproveSolicitationData(false)}
          onConfirm={() => callAction('approve', UserStatusAPI.APPROVED)}
        />
        <ConfirmationModal
          open={openBlockModal}
          title="BLOQUEIO DE CONTA"
          message="Você está bloqueando uma conta. Se prosseguir com a ação, não será possível desbloquear essa conta depois. Deseja continuar?"
          confirmText="Bloquear"
          onCancel={() => setOpenBlockModal(false)}
          onConfirm={() => callAction('block', UserStatusAPI.BLOCK)}
        />
      </Container>
    </>
  );
};

export default UserPanel;
