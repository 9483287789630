import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IExcursion } from 'interfaces/IExcursion';
import { styled } from '@mui/system';
import UserService from 'services/UserService';
import { IUserAPI } from 'interfaces/IUser';

// Estilizando a Modal
const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    borderRadius: '18px',
    padding: '20px',
    fontFamily: 'Signika',
  },
});

const StyledTitle = styled(DialogTitle)`
  font-size: 20px;
  font-weight: 600;
  color: #272727;
  font-family: 'Signika';
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px;

  @media (max-width: 768px) {
    padding-left: 0px; /* Reduz o espaçamento no mobile */
  }
`;

const CloseButton = styled(IconButton)({
  backgroundColor: '#FFCB29',
  '&:hover': {
    backgroundColor: '#020C90',
  },
});

const StyledTypography = styled(Typography)`
  font-size: 16px;
  color: #333;
  font-family: 'Signika';
  line-height: 1.5;
  text-align: left; /* Garante alinhamento correto */

  @media (max-width: 768px) {
    margin: 0; /* Remove qualquer margem no mobile */
    text-align: left; /* Garante que fique alinhado à esquerda */
    padding-left: 0; /* Adiciona um espaçamento à esquerda */
  }
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 20px; /* Mantém o espaçamento no desktop */
  overflow-x: hidden; /* Remove rolagem horizontal */
  width: 100%; /* Garante que o conteúdo não ultrapasse a largura da modal */

  @media (max-width: 768px) {
    padding: 0px 0px;
    margin: 0px 0px;
  }
`;

const StyledGridItem = styled(Grid)`
  margin: 0 !important;
  width: 100%;
  padding-left: 0 !important;
`;

const StyledSectionTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  color: #272727;
  font-family: 'Signika';
  padding-bottom: 5px;
  text-align: left; /* Garante alinhamento correto */

  @media (max-width: 768px) {
    padding-left: 0; /* Remove qualquer deslocamento à esquerda */
  }
`;

// Linha pontilhada
const StyledHr = styled('hr')({
  border: 'none',
  borderTop: '1px dotted #333',
  margin: '20px 0',
});

// Componente para exibir apenas os valores que existem
const Field = ({ label, value }: { label: string; value?: string | number | null }) =>
  value ? (
    <StyledTypography>
      <strong>{label}:</strong> {value}
    </StyledTypography>
  ) : null;

interface ExcursionDetailsModalProps {
  open: boolean;
  onClose: () => void;
  excursion: IExcursion | null;
  userType: string;
}

const formatExcursionId = (id: number): string => {
  if (id <= 0) return 'A00001';

  const baseCharCode = 'A'.charCodeAt(0);
  const maxPerLetter = 99999;

  const letterIndex = Math.floor(id / maxPerLetter);
  const numericPart = id % maxPerLetter;

  const letter = String.fromCharCode(baseCharCode + letterIndex);
  return `${letter}${numericPart.toString().padStart(5, '0')}`;
};

const accommodationTypeMap: Record<number, string> = {
  1: 'Não irei me hospedar em Porto Seguro',
  2: 'Estabelecimento em Porto Seguro com CNPJ, alvará e Cadastur e contribuinte do ISS municipal',
  3: 'Imóvel de locação em Porto Seguro e contribuinte do ISS municipal',
  4: 'Imóvel de locação em Porto Seguro sem CNPJ, alvará e Cadastur e não contribuinte do ISS municipal',
};

const guideTypeMap: Record<string, string> = {
  '1': 'Desejo solicitar guia regional Bahia Sul',
  '2': 'Já possuo guia regional Bahia Sul',
  '3': 'Não será necessário guia regional Bahia Sul',
};

const renderGuideType = (type: string | number) => {
  return guideTypeMap[type.toString()] || 'Tipo não informado';
};

const ExcursionDetailsModal: React.FC<ExcursionDetailsModalProps> = ({ open, onClose, excursion, userType }) => {
  const [loadingUser, setLoadingUser] = useState(false);
  const [userData, setUserData] = useState<IUserAPI | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!excursion) return;

      setLoadingUser(true);
      try {
        const userService = new UserService();
        let userDetails: IUserAPI;

        if (userType === 'tour-admin' && excursion.user_id) {
          userDetails = await userService.findOneById(excursion.user_id);
        } else {
          userDetails = await userService.findOne();
        }

        setUserData(userDetails);
      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);
      } finally {
        setLoadingUser(false);
      }
    };

    if (open) {
      fetchUserData();
    }
  }, [excursion, open, userType]);

  useEffect(() => {
    if (!open) {
      setUserData(null);
      setLoadingUser(false);
    }
  }, [open]);

  if (!excursion || !userData) return null;

  const renderAccommodationFields = (accommodation: any) => {
    if (!accommodation) return null;

    switch (accommodation.accommodation_type) {
      case 1:
        return <Field label="Justificativa" value={accommodation.justification} />;
      case 2:
        return (
          <>
            <Field label="Cadastur" value={accommodation.cadastur} />
            <Field label="Número do Alvará" value={accommodation.license_number} />
            <Field label="ISS Municipal" value={accommodation.iss} />
            <Field label="CNPJ" value={accommodation.document_number} />
            <Field label="Nome Fantasia" value={accommodation.name} />
            <Field label="Telefone ou Celular" value={accommodation.phone} />
            <Field label="E-mail" value={accommodation.email} />
            <Field
              label="Endereço"
              value={`${accommodation.address}, ${accommodation.number}${
                accommodation.complement ? `, ${accommodation.complement}` : ''
              }, ${accommodation.neighborhood}, ${accommodation.cep}, ${accommodation.city} - ${accommodation.state}`}
            />
          </>
        );
      case 3:
        return (
          <>
            <Field label="ISS Municipal" value={accommodation.iss} />
            <Field label="CNPJ" value={accommodation.document_number} />
            <Field label="Nome Fantasia" value={accommodation.name} />
            <Field label="Telefone ou Celular" value={accommodation.phone} />
            <Field label="E-mail" value={accommodation.email} />
            <Field
              label="Endereço"
              value={`${accommodation.address}, ${accommodation.number}${
                accommodation.complement ? `, ${accommodation.complement}` : ''
              }, ${accommodation.neighborhood}, ${accommodation.cep}, ${accommodation.city} - ${accommodation.state}`}
            />
          </>
        );
      case 4:
        return (
          <>
            <Field label="CPF do Proprietário" value={accommodation.document_number} />
            <Field label="Nome Completo do Proprietário" value={accommodation.name} />
            <Field label="Telefone ou Celular" value={accommodation.phone} />
            <Field label="E-mail" value={accommodation.email} />
            <Field
              label="Endereço"
              value={`${accommodation.address}, ${accommodation.number}${
                accommodation.complement ? `, ${accommodation.complement}` : ''
              }, ${accommodation.neighborhood}, ${accommodation.cep}, ${accommodation.city} - ${accommodation.state}`}
            />
          </>
        );
      default:
        return null;
    }
  };

  const formatDestinationList = (destinations: string[] = []): string => {
    const items = [...destinations]; // copia segura

    if (items.length === 0) return '';
    if (items.length === 1) return items[0];
    if (items.length === 2) return items.join(' e ');

    const last = items.pop();
    return `${items.join(', ')} e ${last}`;
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <StyledTitle>
        Código ID {formatExcursionId(excursion.id)}
        <CloseButton onClick={onClose}>
          <CloseIcon sx={{ color: '#fff' }} />
        </CloseButton>
      </StyledTitle>

      <StyledDialogContent dividers>
        <StyledGridItem container spacing={2}>
          {/* Dados da Empresa (Somente para CNPJ) */}
          {userData.type === 'tour-pj' && (
            <StyledGridItem item xs={12} sm={6}>
              <StyledSectionTitle>Dados da empresa</StyledSectionTitle>
              <Field label="Nome fantasia" value={userData.extraInfo.legalName} />
              <Field label="Razão social" value={userData.extraInfo.responsibleName} />
              <Field label="CNPJ" value={userData.document} />
            </StyledGridItem>
          )}

          {/* Responsável pela Excursão */}
          <StyledGridItem item xs={12} sm={6}>
            <StyledSectionTitle>Dados do responsável pela excursão</StyledSectionTitle>
            <Field label="Nome completo" value={excursion.organizer?.name} />
            <Field label="CPF" value={excursion.organizer?.document_number} />
            <Field label="Telefone" value={excursion.organizer?.phone} />
            <Field label="E-mail" value={excursion.organizer?.email} />
            <Field
              label="Endereço"
              value={
                excursion.organizer?.address && excursion.organizer?.city && excursion.organizer?.state
                  ? `${excursion.organizer.address}, ${excursion.organizer.number || 'S/N'}${
                      excursion.organizer.complement ? `, ${excursion.organizer.complement}` : ''
                    }, ${excursion.organizer.neighborhood || ''}, ${excursion.organizer.cep || ''}, ${
                      excursion.organizer.city
                    } - ${excursion.organizer.state}`
                  : null
              }
            />
          </StyledGridItem>

          <StyledGridItem item xs={12}>
            <StyledHr />
          </StyledGridItem>

          {/* Dados da Viagem */}
          <StyledGridItem item xs={12}>
            <StyledSectionTitle>Dados da viagem</StyledSectionTitle>

            <Field
              label="Origem"
              value={excursion.origin_city ? `${excursion.origin_city} - ${excursion.origin_state}` : null}
            />

            <Field
              label="Chegada"
              value={
                excursion.start_date
                  ? `${excursion.start_date} - ${excursion.hour_start?.slice(0, 5) || '00:00'}`
                  : '00/00/0000 - 00:00'
              }
            />

            <Field
              label="Saída"
              value={
                excursion.end_date
                  ? `${excursion.end_date} - ${excursion.hour_end?.slice(0, 5) || '00:00'}`
                  : '00/00/0000 - 00:00'
              }
            />

            <Field label="Quantidade de pessoas" value={excursion.people_number || '00'} />

            <Field
              label="Destino"
              value={formatDestinationList(
                Array.isArray(excursion.destination)
                  ? excursion.destination
                  : typeof excursion.destination === 'string'
                  ? excursion.destination.split(',').map(d => d.trim())
                  : []
              )}
            />

            <Field label="Acesso ao Centro" value={excursion.central_access ? 'Sim' : 'Não'} />
            <Field label="Motivo da viagem" value={excursion.travel_reason || 'Opção selecionada'} />

            <Field label="Solicitação de isenção de taxa" value={excursion.declaration_file ? 'Não' : 'Sim'} />
          </StyledGridItem>

          <StyledGridItem item xs={12}>
            <StyledHr />
          </StyledGridItem>

          {/* Dados da Transportadora */}
          <StyledGridItem item xs={12} sm={6}>
            <StyledSectionTitle>Dados da transportadora</StyledSectionTitle>

            <Field label="Nome fantasia" value={excursion.transport?.trade_name} />
            <Field label="Razão social" value={excursion.transport?.legal_name} />
            <Field label="CNPJ" value={excursion.transport?.document_number} />
            <Field label="Telefone" value={excursion.transport?.phone} />
            <Field label="E-mail" value={excursion.transport?.email} />

            {/* Adicionando o campo Cadastur se estiver preenchido */}
            {excursion.transport?.cadastur && <Field label="Cadastur" value={excursion.transport?.cadastur} />}
          </StyledGridItem>

          {/* Dados do Transporte */}
          <StyledGridItem item xs={12} sm={6}>
            <StyledSectionTitle>Dados do transporte</StyledSectionTitle>
            <Field label="Tipo de veículo" value={excursion.transport?.vehicle_type} />
            <Field label="Placa" value={excursion.transport?.vehicle_plate} />
            <Field label="Passageiro PcD" value={excursion.transport?.has_pcd ? 'Sim' : 'Não'} />
          </StyledGridItem>

          <StyledGridItem item xs={12}>
            <StyledHr />
          </StyledGridItem>

          {/* Dados do Meio de Hospedagem */}
          <StyledGridItem item xs={12}>
            <StyledSectionTitle>Dados do meio de hospedagem</StyledSectionTitle>
            <Field
              label="Tipo de hospedagem"
              value={accommodationTypeMap[excursion.accommodation?.accommodation_type] || 'Tipo não informado'}
            />
            {renderAccommodationFields(excursion.accommodation)}
          </StyledGridItem>

          <StyledGridItem item xs={12}>
            <StyledHr />
          </StyledGridItem>

          {/* Dados do Guia de Turismo */}
          <StyledGridItem item xs={12}>
            <StyledSectionTitle>Dados do guia de turismo</StyledSectionTitle>
            <Field label="City Tour" value={excursion.guide?.city_tour ? 'Sim' : 'Não'} />
            <Field label="Passeio pelo Litoral Sul" value={excursion.guide?.coastal_tour ? 'Sim' : 'Não'} />
            <Field label="Tipo de presença" value={renderGuideType(excursion.guide?.type)} />
            <Field label="Nome completo do guia" value={excursion.guide?.guide_name} />
            <Field label="Cadastur" value={excursion.guide?.cadastur} />
            <Field label="Telefone" value={excursion.guide?.phone} />
            <Field label="E-mail" value={excursion.guide?.email} />
          </StyledGridItem>

          <StyledGridItem item xs={12}>
            <StyledHr />
          </StyledGridItem>

          {/* Dados do Pagamento */}
          <StyledGridItem item xs={12}>
            <StyledSectionTitle>Dados do pagamento</StyledSectionTitle>
            <Field
              label="Valor"
              value={
                excursion.total_price
                  ? new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                    }).format(excursion.total_price)
                  : null
              }
            />
          </StyledGridItem>
        </StyledGridItem>
      </StyledDialogContent>
    </StyledDialog>
  );
};

export default ExcursionDetailsModal;
