// styles.ts
export const selectStyles = {
  border: '1px solid #ccc',
  borderRadius: '18px',
  backgroundColor: '#fff',
  height: '40px',
  color: '#9a9a9a',
  fontFamily: 'Signika, sans-serif',
  '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
  '&.Mui-focused': {
    borderColor: '#ccc',
    boxShadow: 'none',
    backgroundColor: '#fff',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#fff',
  },
  '& .MuiSelect-select': {
    paddingLeft: '0px',
  },
  '& .MuiSelect-icon': {
    color: '#9a9a9a',
  },

  '& .MuiListItemText-primary': {
    fontFamily: 'Signika, sans-serif',
    color: '#272727',
  },

  '&.css-17gdbqk-MuiFormLabel-root-MuiInputLabel-root.Mui-focused': { color: '#9a9a9a' },
};

export const menuItemStyles = {
  fontFamily: 'Signika, sans-serif',
  color: '#FFF',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
};

export const checkboxStyles = {
  color: '#272727',
  '&.Mui-checked': { color: '#FFCB29' },
};

export const listItemTextStyles = {
  fontFamily: 'Signika, sans-serif',
  color: '#9a9a9a',
};
