import styled from 'styled-components';

export const FAQContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  padding: 0 50px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0 50px;
  }
`;

export const FAQContainerTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 20px;
  border-top: 1px dashed #9a9a9a;
  padding-top: 50px;
`;

export const FAQContainerListArticle = styled.article`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FAQContainerListItemQuestion = styled.h3`
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const FAQContainerListItemAnswer = styled.p`
  font-size: 14px;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;
