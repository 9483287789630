import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  ButtonsDiv,
  Container,
  ExcursionCompanyInformation,
  ExcursionFirstFormContainer,
  StepperBullet,
  StepperContainer,
  StepperItem,
  StepperLine,
  StepperName,
} from '../../styles';
import MainTitle from 'components/MainTitle';
import {
  IconGuia,
  IconHospedagem,
  IconOrganizador,
  IconPagamento,
  IconResumo,
  IconTransporte,
  IconViagem,
} from 'features/NewExcursionForm/components/StepperIcons';
import Button from 'components/Button';
import ConfirmationModal from 'components/ConfirmationModal';
import { useExcursionOrganizatorForm } from 'common/ExcursionOrganizatorFormContext';
import ExcursionsService from 'services/ExcursionsService';
import MessageModal from 'common/MessageModal';
import { useLoading } from 'hooks/useLoading';
import LockNavigatorHook from 'features/NewExcursionForm/hooks/LockNavigatorHook';
import UserService from 'services/UserService';

const NewExcursionStep7Form = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showMessageErrorModal, setShowMessageErrorModal] = useState(false);
  const excursionOrganizatorFormContext = useExcursionOrganizatorForm();
  const userService = new UserService();
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        openLoading();
        const user = await userService.findOne();
        setUser(user);
      } catch (error) {
        console.error(error);
      } finally {
        closeLoading();
      }
    };
    fetchUser();
  }, []);

  LockNavigatorHook(history);

  const handleCancel = () => {
    excursionOrganizatorFormContext.setForm({
      ...excursionOrganizatorFormContext.form,
      organizer: {
        ...excursionOrganizatorFormContext.form.organizer,
        document_type: '',
        name: '',
        document_number: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
      },
      origin_city: '',
      origin_state: '',
      start_date: '',
      hour_start: '',
      end_date: '',
      hour_end: '',
      people_number: 0,
      destination: [],
      central_access: false,
      travel_reason: '',
      declaration_file: '',
      transport: {
        ...excursionOrganizatorFormContext.form.transport,
        trade_name: '',
        legal_name: '',
        document_number: '',
        cadastur: '',
        phone: '',
        email: '',
        vehicle_type: '',
        vehicle_plate: '',
        has_pcd: false,
      },
      accommodation: {
        ...excursionOrganizatorFormContext.form.accommodation,
        accommodation_type: 0,
        cadastur: '',
        license_number: '',
        document_number: '',
        name: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        justification: '',
        iss: '',
      },
      guide: {
        ...excursionOrganizatorFormContext.form.guide,
        city_tour: false,
        coastal_tour: false,
        type: '',
        guide_name: '',
        cadastur: '',
        phone: '',
        email: '',
      },
      total_price: 0,
    });
    setShowModal(false);
    history.push('/admin/excursions');
  };

  console.log(excursionOrganizatorFormContext.form);

  const submitForm = async () => {
    try {
      openLoading();
      const excursionsService = new ExcursionsService();

      // Definir status baseado na presença do declaration_file
      const status =
        excursionOrganizatorFormContext.form.declaration_file &&
        excursionOrganizatorFormContext.form.declaration_file.trim() !== ''
          ? 'Isenção em Análise'
          : 'Em Processamento';
      // Criando uma cópia do form para evitar mutação direta do estado
      const cleanedForm = {
        ...excursionOrganizatorFormContext.form,
        status,
        organizer: {
          ...excursionOrganizatorFormContext.form.organizer,
          document_number: excursionOrganizatorFormContext.form.organizer.document_number.replace(/\D/g, ''), // Remove não numéricos
        },
        transport: {
          ...excursionOrganizatorFormContext.form.transport,
          document_number: excursionOrganizatorFormContext.form.transport.document_number.replace(/\D/g, ''),
        },
        accommodation: {
          ...excursionOrganizatorFormContext.form.accommodation,
          document_number: excursionOrganizatorFormContext.form.accommodation.document_number.replace(/\D/g, ''),
        },
        start_date: new Date(excursionOrganizatorFormContext.form.start_date).toISOString(),
        end_date: new Date(excursionOrganizatorFormContext.form.end_date).toISOString(),
      };

      await excursionsService.postExcursion(cleanedForm);

      excursionOrganizatorFormContext.setForm({
        ...excursionOrganizatorFormContext.form,
        organizer: {
          ...excursionOrganizatorFormContext.form.organizer,
          document_type: '',
          name: '',
          document_number: '',
          phone: '',
          email: '',
          address: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: '',
        },
        origin_city: '',
        origin_state: '',
        start_date: '',
        hour_start: '',
        end_date: '',
        hour_end: '',
        people_number: 0,
        destination: [],
        central_access: false,
        travel_reason: '',
        declaration_file: '',
        transport: {
          ...excursionOrganizatorFormContext.form.transport,
          trade_name: '',
          legal_name: '',
          document_number: '',
          cadastur: '',
          phone: '',
          email: '',
          vehicle_type: '',
          vehicle_plate: '',
          has_pcd: false,
        },
        accommodation: {
          ...excursionOrganizatorFormContext.form.accommodation,
          accommodation_type: 0,
          cadastur: '',
          license_number: '',
          document_number: '',
          name: '',
          phone: '',
          email: '',
          address: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: '',
          justification: '',
          iss: '',
        },
        guide: {
          ...excursionOrganizatorFormContext.form.guide,
          city_tour: false,
          coastal_tour: false,
          type: '',
          guide_name: '',
          cadastur: '',
          phone: '',
          email: '',
        },
        total_price: 0,
      });

      setShowMessageModal(true);
      history.push('/admin/excursions');
    } catch (error) {
      setShowMessageErrorModal(true);
      console.error(error);
    } finally {
      closeLoading();
    }
  };

  console.log('user', user);

  return (
    <Container>
      <MainTitle>Cadastro de Excursão</MainTitle>

      <StepperContainer>
        <StepperItem className="flex flex-col items-center justify-center">
          <article>
            <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step1')}>
              <IconOrganizador color="#ffffff" />
            </StepperBullet>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Organizador</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <Link to="#">
              <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step2')}>
                <IconViagem color="#ffffff" />
              </StepperBullet>
            </Link>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Viagem</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <Link to="#">
              <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step3')}>
                <IconTransporte />
              </StepperBullet>
            </Link>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Transporte</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <Link to="#">
              <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step4')}>
                <IconHospedagem />
              </StepperBullet>
            </Link>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Hospedagem</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <Link to="#">
              <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step5')}>
                <IconGuia />
              </StepperBullet>
            </Link>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Guia</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <Link to="#">
              <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step6')}>
                <IconPagamento />
              </StepperBullet>
            </Link>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Pagamento</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <Link to="#">
              <StepperBullet className="onEdit" onClick={e => e.preventDefault()}>
                <IconResumo color="#020c90" />
              </StepperBullet>
            </Link>
          </article>
          <StepperName className="onEdit">Resumo</StepperName>
        </StepperItem>
      </StepperContainer>

      <ExcursionFirstFormContainer>
        <ExcursionCompanyInformation>
          <h2>Revisão dos Dados:</h2>
          <p>
            <strong>Atenção!</strong> Revise os dados abaixo antes de confirmar o pagamento.
          </p>
        </ExcursionCompanyInformation>
        <ExcursionCompanyInformation>
          <section>
            {excursionOrganizatorFormContext.form.organizer.document_type === 'CNPJ' ? (
              <>
                <article>
                  <h2>Dados da Empresa:</h2>
                  <p>
                    <strong>Nome Fantasia</strong> {user?.extraInfo?.tradeName}
                  </p>
                  <p>
                    <strong>Razão Social</strong> {user?.extraInfo?.legalName}
                  </p>
                  <p>
                    <strong>CNPJ</strong> {user?.document}
                  </p>
                </article>
                <article>
                  <h2>Dados do Responsável pela Excursão:</h2>
                  <p>
                    <strong>Nome Completo</strong> {excursionOrganizatorFormContext.form.organizer.name}
                  </p>
                  <p>
                    <strong>CPF</strong> {excursionOrganizatorFormContext.form.organizer.document_number}
                  </p>
                  <p>
                    <strong>Telefone ou Celular</strong> {excursionOrganizatorFormContext.form.organizer.phone}
                  </p>
                  <p>
                    <strong>E-mail</strong> {excursionOrganizatorFormContext.form.organizer.email}
                  </p>
                  <p>
                    <strong>Endereço</strong> {excursionOrganizatorFormContext.form.organizer.address}, {''}
                    {excursionOrganizatorFormContext.form.organizer.number}, {''}
                    {excursionOrganizatorFormContext.form.accommodation.complement &&
                      `${excursionOrganizatorFormContext.form.accommodation.complement}, `}
                    {''}
                    {excursionOrganizatorFormContext.form.organizer.neighborhood}, {''}
                    {excursionOrganizatorFormContext.form.organizer.city} -{' '}
                    {excursionOrganizatorFormContext.form.organizer.state}
                  </p>
                </article>
              </>
            ) : (
              <article>
                <h2>Dados do Responsável pela Excursão:</h2>
                <p>
                  <strong>Nome Completo</strong> {excursionOrganizatorFormContext.form.organizer.name}
                </p>
                <p>
                  <strong>CPF</strong> {excursionOrganizatorFormContext.form.organizer.document_number}
                </p>
                <p>
                  <strong>Telefone ou Celular</strong> {excursionOrganizatorFormContext.form.organizer.phone}
                </p>
                <p>
                  <strong>E-mail</strong> {excursionOrganizatorFormContext.form.organizer.email}
                </p>
                <p>
                  <strong>Endereço</strong> {excursionOrganizatorFormContext.form.organizer.address}, {''}
                  {excursionOrganizatorFormContext.form.organizer.number}, {''}
                  {excursionOrganizatorFormContext.form.accommodation.complement &&
                    `${excursionOrganizatorFormContext.form.accommodation.complement}, `}
                  {''}
                  {excursionOrganizatorFormContext.form.organizer.neighborhood}, {''}
                  {excursionOrganizatorFormContext.form.organizer.city} -{' '}
                  {excursionOrganizatorFormContext.form.organizer.state}
                </p>
              </article>
            )}
          </section>
          <section>
            <article>
              <h2>Dados da Viagem:</h2>
              <p>
                <strong>Origem</strong> {excursionOrganizatorFormContext.form.origin_city} -{' '}
                {excursionOrganizatorFormContext.form.origin_state}
              </p>
              <p>
                <strong>Chegada</strong>{' '}
                {new Date(
                  excursionOrganizatorFormContext.form.start_date.split('T')[0] + 'T12:00:00'
                ).toLocaleDateString('pt-BR')}{' '}
                - {excursionOrganizatorFormContext.form.hour_start.slice(0, 5).replace(':', 'h')}
              </p>
              <p>
                <strong>Saída</strong>{' '}
                {new Date(excursionOrganizatorFormContext.form.end_date.split('T')[0] + 'T12:00:00').toLocaleDateString(
                  'pt-BR'
                )}{' '}
                - {excursionOrganizatorFormContext.form.hour_end.slice(0, 5).replace(':', 'h')}
              </p>
              <p>
                <strong>Quantidade de Pessoas</strong> {excursionOrganizatorFormContext.form.people_number}
              </p>
              <p>
                <strong>Destino</strong>{' '}
                {excursionOrganizatorFormContext.form.destination.length > 1
                  ? excursionOrganizatorFormContext.form.destination.slice(0, -1).join(', ') +
                    ' e ' +
                    excursionOrganizatorFormContext.form.destination.slice(-1)
                  : excursionOrganizatorFormContext.form.destination[0]}
              </p>
              <p>
                <strong>Acesso ao Centro</strong> {excursionOrganizatorFormContext.form.central_access ? 'Sim' : 'Não'}
              </p>
              <p>
                <strong>Motivo da Viagem</strong> {excursionOrganizatorFormContext.form.travel_reason}
              </p>
              <p>
                <strong>Solicitação de Insenção de Taxa </strong>
                {excursionOrganizatorFormContext.form.declaration_file ? 'Sim' : 'Não'}
              </p>
            </article>
          </section>
          <section>
            <article>
              <h2>Dados da Transportadora:</h2>
              <p>
                <strong>Nome Fantasia</strong> {excursionOrganizatorFormContext.form.transport.trade_name}
              </p>
              <p>
                <strong>Razão Social</strong> {excursionOrganizatorFormContext.form.transport.legal_name}
              </p>
              <p>
                <strong>CNPJ</strong> {excursionOrganizatorFormContext.form.transport.document_number}
              </p>
              {excursionOrganizatorFormContext.form.transport.cadastur.length > 0 && (
                <p>
                  <strong>Cadastur</strong> {excursionOrganizatorFormContext.form.transport.cadastur}
                </p>
              )}

              <p>
                <strong>Telefone ou Celular</strong> {excursionOrganizatorFormContext.form.transport.phone}
              </p>
              <p>
                <strong>E-mail</strong> {excursionOrganizatorFormContext.form.transport.email}
              </p>
            </article>
            <article>
              <h2>Dados do Transporte:</h2>
              <p>
                <strong>Tipo de Veículo</strong> {excursionOrganizatorFormContext.form.transport.vehicle_type}
              </p>
              <p>
                <strong>Placa</strong> {excursionOrganizatorFormContext.form.transport.vehicle_plate}
              </p>
              <p>
                <strong>Passageiro PcD</strong>{' '}
                {excursionOrganizatorFormContext.form.transport.has_pcd === true ? 'Sim' : 'Não'}
              </p>
            </article>
          </section>
          <section>
            <article>
              <h2>Dados do Meio de Hospedagem:</h2>
              {excursionOrganizatorFormContext.form.accommodation.accommodation_type === 1 && (
                <>
                  <p>
                    <strong>Tipo de Hospedagem</strong> Não irei me hospedar em Porto Seguro
                  </p>
                  <p>
                    <strong>Justificativa</strong> {excursionOrganizatorFormContext.form.accommodation.justification}
                  </p>
                  <br />
                </>
              )}

              {excursionOrganizatorFormContext.form.accommodation.accommodation_type === 2 && (
                <>
                  <p>
                    <strong>Tipo de Hospedagem</strong> Estabelecimento em Porto Seguro com CNPJ, alvará e Cadastur e
                    contribuinte do ISS municipal
                  </p>
                  <p>
                    <strong>Cadastur</strong> {excursionOrganizatorFormContext.form.accommodation.cadastur}
                  </p>
                  <p>
                    <strong>Número do Alvará</strong>{' '}
                    {excursionOrganizatorFormContext.form.accommodation.license_number}
                  </p>
                  <p>
                    <strong>ISS Municipal</strong> {excursionOrganizatorFormContext.form.accommodation.iss}
                  </p>
                  <p>
                    <strong>CNPJ</strong> {excursionOrganizatorFormContext.form.accommodation.document_number}
                  </p>
                  <p>
                    <strong>Nome Fantasia</strong> {excursionOrganizatorFormContext.form.accommodation.name}
                  </p>
                  <p>
                    <strong>Telefone ou Celular</strong> {excursionOrganizatorFormContext.form.accommodation.phone}
                  </p>
                  <p>
                    <strong>E-mail</strong> {excursionOrganizatorFormContext.form.accommodation.email}
                  </p>
                  <p>
                    <strong>Endereço</strong> {excursionOrganizatorFormContext.form.accommodation.address}, {''}
                    {excursionOrganizatorFormContext.form.accommodation.number}, {''}
                    {excursionOrganizatorFormContext.form.accommodation.complement &&
                      `${excursionOrganizatorFormContext.form.accommodation.complement}, `}
                    {''}
                    {excursionOrganizatorFormContext.form.accommodation.neighborhood}, {''}
                    {excursionOrganizatorFormContext.form.accommodation.city} -{' '}
                    {excursionOrganizatorFormContext.form.accommodation.state}
                  </p>
                  <br />
                </>
              )}

              {excursionOrganizatorFormContext.form.accommodation.accommodation_type === 3 && (
                <>
                  <p>
                    <strong>Tipo de Hospedagem</strong> Imóvel de locação em Porto Seguro e contribuinte no ISS
                    municipal
                  </p>
                  <p>
                    <strong>CPF do Proprietário ou CNPJ</strong>{' '}
                    {excursionOrganizatorFormContext.form.accommodation.document_number}
                  </p>
                  <p>
                    <strong>Nome Completo do Proprietário ou Nome Fantasia</strong>{' '}
                    {excursionOrganizatorFormContext.form.accommodation.name}
                  </p>
                  <p>
                    <strong>ISS Municipal</strong> {excursionOrganizatorFormContext.form.accommodation.iss}
                  </p>
                  <p>
                    <strong>Telefone ou Celular</strong> {excursionOrganizatorFormContext.form.accommodation.phone}
                  </p>
                  <p>
                    <strong>E-mail</strong> {excursionOrganizatorFormContext.form.accommodation.email}
                  </p>
                  <p>
                    <strong>Endereço</strong> {excursionOrganizatorFormContext.form.accommodation.address}, {''}
                    {excursionOrganizatorFormContext.form.accommodation.number}, {''}
                    {excursionOrganizatorFormContext.form.accommodation.complement &&
                      `${excursionOrganizatorFormContext.form.accommodation.complement}, `}
                    {''}
                    {excursionOrganizatorFormContext.form.accommodation.neighborhood}, {''}
                    {excursionOrganizatorFormContext.form.accommodation.city} -{' '}
                    {excursionOrganizatorFormContext.form.accommodation.state}
                  </p>
                  <br />
                </>
              )}

              {excursionOrganizatorFormContext.form.accommodation.accommodation_type === 4 && (
                <>
                  <p>
                    <strong>Tipo de Hospedagem</strong> Imóvel de locação em Porto Seguro sem CNPJ, alvará e Cadastur e
                    não contribuinte do ISS
                  </p>
                  <p>
                    <strong>CPF do Proprietário</strong>{' '}
                    {excursionOrganizatorFormContext.form.accommodation.document_number}
                  </p>
                  <p>
                    <strong>Nome Completo do Proprietário</strong>{' '}
                    {excursionOrganizatorFormContext.form.accommodation.name}
                  </p>
                  <p>
                    <strong>Telefone ou Celular</strong> {excursionOrganizatorFormContext.form.accommodation.phone}
                  </p>
                  <p>
                    <strong>E-mail</strong> {excursionOrganizatorFormContext.form.accommodation.email}
                  </p>
                  <p>
                    <strong>Endereço</strong> {excursionOrganizatorFormContext.form.accommodation.address}, {''}
                    {excursionOrganizatorFormContext.form.accommodation.number}, {''}
                    {excursionOrganizatorFormContext.form.accommodation.complement &&
                      `${excursionOrganizatorFormContext.form.accommodation.complement}, `}
                    {''}
                    {excursionOrganizatorFormContext.form.accommodation.neighborhood}, {''}
                    {excursionOrganizatorFormContext.form.accommodation.city} -{' '}
                    {excursionOrganizatorFormContext.form.accommodation.state}
                  </p>
                </>
              )}
            </article>
          </section>
          <section>
            <article>
              <h2>Dados do Guia de Turismo:</h2>
              {excursionOrganizatorFormContext.form.guide.type === '1' && (
                <>
                  <p>
                    <strong>City Tour</strong> {excursionOrganizatorFormContext.form.guide.city_tour ? 'Sim' : 'Não'}
                  </p>
                  <p>
                    <strong>Passeio pelo Litoral Sul</strong>{' '}
                    {excursionOrganizatorFormContext.form.guide.coastal_tour ? 'Sim' : 'Não'}
                  </p>
                  <p>
                    <strong>Presença do guia</strong> Desejo solicitar guia regional Bahia Sul
                  </p>
                  <br />
                </>
              )}

              {excursionOrganizatorFormContext.form.guide.type === '2' && (
                <>
                  <p>
                    <strong>City Tour</strong> {excursionOrganizatorFormContext.form.guide.city_tour ? 'Sim' : 'Não'}
                  </p>
                  <p>
                    <strong>Passeio pelo Litoral Sul</strong>{' '}
                    {excursionOrganizatorFormContext.form.guide.coastal_tour ? 'Sim' : 'Não'}
                  </p>
                  <p>
                    <strong>Presença do guia</strong> Já possuo guia regional Bahia Sul
                  </p>
                  <p>
                    <strong>Nome Completo do Guia</strong> {excursionOrganizatorFormContext.form.guide.guide_name}
                  </p>
                  <p>
                    <strong>Cadastur</strong> {excursionOrganizatorFormContext.form.guide.cadastur}
                  </p>
                  <p>
                    <strong>Telefone ou Celular</strong> {excursionOrganizatorFormContext.form.guide.phone}
                  </p>
                  <p>
                    <strong>E-mail</strong> {excursionOrganizatorFormContext.form.guide.email}
                  </p>
                  <br />
                </>
              )}

              {excursionOrganizatorFormContext.form.guide.type === '3' && (
                <>
                  <p>
                    <strong>City Tour</strong> {excursionOrganizatorFormContext.form.guide.city_tour ? 'Sim' : 'Não'}
                  </p>
                  <p>
                    <strong>Passeio pelo Litoral Sul</strong>{' '}
                    {excursionOrganizatorFormContext.form.guide.coastal_tour ? 'Sim' : 'Não'}
                  </p>
                  <p>
                    <strong>Presença do guia</strong> Não será necessário guia regional Bahia Sul
                  </p>
                  <br />
                </>
              )}
            </article>
          </section>
          <section>
            <article>
              <h2>Dados do Pagamento:</h2>
              <p>
                <strong>Valor</strong>{' '}
                {excursionOrganizatorFormContext.form.total_price.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </p>
            </article>
          </section>
        </ExcursionCompanyInformation>
        <ButtonsDiv>
          <Button buttonType="secondary" onClick={() => history.push('/admin/new-excursion/step6')}>
            Voltar
          </Button>
          <article>
            <Button
              type="button"
              buttonType="secondary"
              onClick={e => {
                e.preventDefault();
                setShowModal(true);
              }}>
              Cancelar
            </Button>
            <Button type="button" onClick={submitForm}>
              Finalizar
            </Button>
          </article>
        </ButtonsDiv>
      </ExcursionFirstFormContainer>
      {showModal && (
        <ConfirmationModal
          open={showModal}
          onCancel={() => {
            setShowModal(false);
          }}
          onConfirm={handleCancel}
          title="Cancelar Cadastro de Excursão"
          message={
            <p>
              Se prosseguir com a ação, <strong>não será possível recuperar os dados</strong> já perdidos. Deseja
              continuar?
            </p>
          }
          confirmText="Confirmar"
          cancelText="Voltar"
        />
      )}
      {showMessageModal ||
        (showMessageErrorModal && (
          <MessageModal
            onClose={() => {
              showMessageErrorModal ? setShowMessageErrorModal(false) : setShowMessageModal(false);
            }}
            title={showMessageErrorModal ? 'Erro ao cadastrar excursão' : 'Excursão cadastrada com sucesso!'}
            message={
              showMessageErrorModal
                ? 'Erro ao cadastrar excursão, tente novamente mais tarde.'
                : 'Cadastro de excursão realizado com sucesso!'
            }
            isOpen={showMessageErrorModal || showMessageModal}
          />
        ))}
    </Container>
  );
};

export default NewExcursionStep7Form;
