import React from 'react';
import {
  PrimaryButton,
  OutlineButton,
  DisabledButton,
  SecondaryButton,
  SelectedButton,
  UnselectedButton,
  IconButton,
} from './styles';

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: 'primary' | 'outline' | 'disabled' | 'secondary' | 'selectedButton' | 'unselectedButton' | 'iconButton';
}

const Button = (props: IButtonProps): JSX.Element => {
  const { buttonType = 'primary', ...buttonProps } = props;

  if (buttonType === 'outline') {
    return <OutlineButton {...buttonProps} />;
  }

  if (buttonType === 'disabled') {
    return <DisabledButton {...buttonProps} disabled={true} />;
  }

  if (buttonType === 'secondary') {
    return <SecondaryButton {...buttonProps} />;
  }

  if (buttonType === 'selectedButton') {
    return <SelectedButton {...buttonProps} />;
  }

  if (buttonType === 'unselectedButton') {
    return <UnselectedButton {...buttonProps} />;
  }

  if (buttonType === 'iconButton') {
    return <IconButton {...buttonProps} />;
  }

  return <PrimaryButton {...buttonProps} />;
};

export default Button;
