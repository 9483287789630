export enum TourReasonsEnum {
  'Turismo' = 'Turismo',
  'Acadêmico' = 'Acadêmico',
  'Evento Esportivo' = 'Evento Esportivo',
  'Religioso' = 'Religioso',
  'Trabalho' = 'Trabalho',
}

export const tourReasons = Object.entries(TourReasonsEnum).map(([key, value]) => ({
  label: key,
  value: value,
}));

export interface TourReasonsProps {
  field: {
    name: string;
    value: TourReasonsEnum;
    onChange: (value: TourReasonsEnum) => void;
    onBlur: () => void;
  };
  form: {
    setFieldValue: (field: string, value: TourReasonsEnum) => void;
  };
}
[];

export enum TransportTypeEnum {
  'Micro-ônibus (capac. de 18-25 passageiros)' = 'Micro-ônibus',
  'Ônibus (capac. acima de 25 passageiros)' = 'Ônibus',
  'Van ou Similar (capac. de 8-17 passageiros)' = 'Van ou Similar',
}

export const transportTypes = Object.entries(TransportTypeEnum).map(([key, value]) => ({
  label: key,
  value: value,
}));

export interface TransportTypesProps {
  field: {
    name: string;
    value: TransportTypeEnum;
    onChange: (value: TransportTypeEnum) => void;
    onBlur: () => void;
  };
  form: {
    setFieldValue: (field: string, value: TransportTypeEnum) => void;
  };
}
[];

export enum CityTourEnum {
  Sim = 'Sim',
  Não = 'Não',
}

export const cityTour = Object.entries(CityTourEnum).map(([key, value]) => ({
  label: key,
  value: value,
}));

export interface CityTourProps {
  field: {
    name: string;
    value: CityTourEnum;
    onChange: (value: CityTourEnum) => void;
    onBlur: () => void;
  };
  form: {
    setFieldValue: (field: string, value: CityTourEnum) => void;
  };
}
[];

export enum SouthTourEnum {
  Sim = 'Sim',
  Não = 'Não',
}

export const southTour = Object.entries(SouthTourEnum).map(([key, value]) => ({
  label: key,
  value: value,
}));

export interface SouthTourProps {
  field: {
    name: string;
    value: SouthTourEnum;
    onChange: (value: SouthTourEnum) => void;
    onBlur: () => void;
  };
  form: {
    setFieldValue: (field: string, value: SouthTourEnum) => void;
  };
}
[];

export enum AccommodationTypeEnum {
  'Não irei me hospedar em Porto Seguro' = 1,
  'Estabelecimento em Porto Seguro com CNPJ, alvará e Cadastur e contribuinte do ISS municipal' = 2,
  'Imóvel de locação em Porto Seguro e contribuinte do ISS municipal' = 3,
  'Imóvel de locação em Porto Seguro sem CNPJ, alvará e Cadastur e não contribuinte do ISS municipal' = 4,
}

export interface AddressData {
  logradouro: string;
  bairro: string;
  localidade: string;
  uf: string;
  cep: string;
  complemento: string;
}

export const fetchAddressByCep = async (cep: string): Promise<AddressData | Error> => {
  try {
    const response = await fetch(`https://viacep.com.br/ws/${cep}/json`);
    if (!response.ok) {
      throw new Error('Erro ao buscar endereço');
    }
    return await response.json(); // Certifique-se de que o formato retornado corresponde ao tipo AddressData
  } catch (error: any) {
    return new Error('Erro ao buscar o endereço: ' + error.message);
  }
};
