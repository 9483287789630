import styled from 'styled-components';

export const StampRedArticle = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  width: 100%;
  max-width: 500px;
  height: 500px;
  background: #fff;
  border: 30px solid #c63336;

  @media (max-width: 768px) {
    border: 20px solid #c63336;
    padding: 20px;
    height: auto;
    width: 100%;
    max-height: 400px;
    max-width: 400px;
  }
`;

export const StampBlueArticle = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  width: 100%;
  max-width: 500px;
  height: 500px;
  background: #fff;
  border-radius: 500px;
  border: 30px solid #020c90;

  @media (max-width: 768px) {
    border: 20px solid #020c90;
    padding: 20px;
    height: auto;
    width: 100%;
    max-height: 400px;
    max-width: 400px;
  }
`;

export const StampRedDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  width: 100%;
  max-width: 500px;
  height: 500px;
  background-color: #c63336;
  border: 1px solid #fff;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const StampBlueDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0 0 0;
  width: 100%;
  max-width: 500px;
  height: 500px;
  background-color: #020c90;
  border-radius: 500px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const StampDivTitle = styled.h3`
  margin-bottom: 10px;
  padding: 0;
  font-size: 35px;
  color: #fff;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 25px;
  }
`;

export const StampDivNumber = styled.h2`
  margin-bottom: 20px;
  font-size: 90px;
  color: #fff;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 70px;
  }
`;

export const StampDivImg = styled.img`
  width: 150px;

  @media (max-width: 768px) {
    width: 100px;
  }
`;
