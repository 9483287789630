import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { AddressData, fetchAddressByCep } from 'features/NewExcursionForm/utils';
import { Estados } from 'utils/location';

export interface FieldMapping {
  formField: string; // Nome do campo no Formik
  responseField: keyof AddressData; // Nome do campo na resposta da API
}

interface FieldCEPComponentProps {
  name: string;
  value: string; // Valor externo do campo
  onChange: (name: string, value: string) => void; // Callback para atualizar o valor no Formik
  currentCity?: string; // Valor atual da cidade para comparação
  placeholder?: string;
  label?: string;
  required?: boolean;
  nextFieldRef?: React.RefObject<HTMLInputElement>;
  fallbackFieldRef?: React.RefObject<HTMLInputElement>;
  fieldMappings: FieldMapping[]; // Mapeamentos dinâmicos
  disabled?: boolean;
  validateOnBlur?: boolean;
}

const FieldCEPComponent: React.FC<FieldCEPComponentProps> = ({
  name,
  value,
  onChange,
  currentCity = '',
  placeholder = '',
  label = 'CEP',
  required = false,
  nextFieldRef,
  fallbackFieldRef,
  fieldMappings,
  disabled = false,
  validateOnBlur,
  ...fieldProps // Captura as props adicionais
}) => {
  const [inputValue, setInputValue] = useState(value || ''); // Inicializa com o valor externo
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessageText, setErrorMessageText] = useState('');

  useEffect(() => {
    // Sincroniza o estado interno com o valor externo
    if (value !== inputValue) {
      setInputValue(value || '');
    }
  }, [value]);

  const formatCep = (cep: string) => cep.replace(/^(\d{5})(\d{1,3})$/, '$1-$2');

  const handleCepChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let cep = event.target.value.replace(/\D/g, '');
    if (cep.length > 8) cep = cep.slice(0, 8);
    const formattedCep = formatCep(cep);

    setInputValue(formattedCep);
    onChange(name, formattedCep); // Atualiza o valor no Formik via callback
  };

  const handleCepBlur = async () => {
    const cep = inputValue.replace(/\D/g, '');
    if (cep.length > 0 && cep.length !== 8) {
      clearFields();
      fallbackFieldRef?.current?.focus();
      return;
    } else if (cep.length === 0) {
      setErrorMessageText('* Campo Obrigatório');
      setErrorMessage(true);
      return;
    }

    setIsLoading(true);
    setErrorMessage(false);

    try {
      const response = await fetchAddressByCep(cep);

      if (response instanceof Error) {
        throw new Error(response.message);
      }

      const responseData = response;

      // Validação do estado
      const ufEncontrado = Object.values(Estados).find(uf => uf === responseData.uf);
      if (!ufEncontrado) {
        throw new Error('Estado não encontrado. Selecione manualmente.');
      }

      // Atualiza os campos dinamicamente
      fieldMappings.forEach(mapping => {
        const value = responseData[mapping.responseField] || '';
        onChange(mapping.formField, value); // Atualiza o valor no Formik
      });

      setInputValue(formatCep(cep));
      nextFieldRef?.current?.focus();
    } catch (error: any) {
      setErrorMessage(error.message || 'Erro ao buscar o endereço. Tente novamente.');
      clearFields();
      fallbackFieldRef?.current?.focus();
    } finally {
      setIsLoading(false);
    }
  };

  const clearFields = () => {
    setErrorMessage(false);
    fieldMappings
      .filter(mapping => mapping.responseField !== 'cep')
      .forEach(mapping => {
        onChange(mapping.formField, ''); // Limpa os campos dinamicamente
      });
  };

  return (
    <>
      <div>
        <label htmlFor={name}>
          {label} {required && <span>*</span>}
        </label>
        <input
          type="text"
          name={name}
          value={inputValue}
          placeholder={placeholder}
          onChange={handleCepChange}
          onBlur={handleCepBlur}
          disabled={disabled || isLoading}
          {...fieldProps} // Passa as props adicionais para o Field
        />
        {isLoading && <p>Buscando endereço...</p>}
        {errorMessage && <p style={{ color: 'red', fontSize: '14.4px' }}>{errorMessageText}</p>}
      </div>
    </>
  );
};

export default FieldCEPComponent;
