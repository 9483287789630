import Private from '../pages/Private';
import HomePage from 'features/Home/pages/HomePage';
import ChooseArea from 'common/ChooseArea/';
import SignUpTourist from 'features/Signup/pages/SignUpTourist';
import SignUpAdmin from 'features/Signup/pages/SignUpAdmin';
import HandleLoginAdmin from 'features/Login/pages/LoginAdmin';
import HandleAccount from 'features/Account/pages/HandleAccount';
import DestinationsPage from 'features/Destinations/pages/Destinations';
import AccommodationsPage from 'features/Accommodations/pages/Accommodations';
import TourOrganizerPage from 'features/TourOrganizer/pages/Tours';
import HandleTab from 'features/WhatToDo/components/HandleTab';
import Itineraries from 'features/WhatToDo/pages/Itinerates';
import News from 'features/News/pages/News';
import NewsDetail from 'features/News/pages/Detail';
import ActivitiesDetails from 'features/WhatToDo/pages/Activities';
import EventDetailCard from 'features/WhatToDo/components/EventDetail';
import AccomodationDetailCard from 'features/WhatToDo/components/AccommodationDetail';
import HowToGet from 'features/HowToGet/pages/HowToGetPage';
import Services from 'features/Services/pages/Services';
import MyExperiences from 'features/MyExperiences/pages/MyExperiences';
import Config from 'features/Config/pages/Config';
import EventForm from 'features/Config/components/Event/eventForm';
import ItineraryForm from 'features/Config/components/Itinerary/ItineraryForm';
import Expo from 'features/Expo/pages/Expo';
import Destination from 'features/Config/components/Destination';
import StrategicMap from 'features/StrategicMap';
import TouristOffers from 'features/TouristOffers';
import Logout from 'features/Logout';
import AccountManagement from 'features/AccountManagement';
import ReviewsPage from 'features/ReviewsPage';
import ExcursionsPage from 'features/ExcursionsPage';
import TouristOffersBusinessOverview from 'features/TouristOffers/pages/TouristOffersBusinessOverview';
import TouristOffersEventsOverview from 'features/TouristOffers/pages/TouristOffersEventsOverview';
import TradeMyBusinessNewPage from 'features/TradeMyBusinessNewPage';
import TradeOffers from 'features/EstablishmentsActivities';
import EventsSchedule from 'features/EstablishmentsActivities/pages/schedule';
import NewsAdmin from 'features/NewsAdmin';
import NewsAdminNewPage from 'features/NewsAdmin/components/pages/NewsAdminNewPage';
import ForgotPassword from 'features/Password/pages/ForgotPassword';
import PasswordReset from 'features/Password/pages/PasswordReset';
import EmailConfirmation from 'features/Password/pages/EmailConfirmation';
import ObservatoryTourist from 'features/TouristObservatory';
import UserPanel from 'features/UserPanel';
import UserDetail from 'features/UserPanel/UserDetail';
import TourActivityForm from 'features/TourActivityForm';
import EventPreview from 'features/AdminPreview/Event';
import ActivityPreview from 'features/AdminPreview/Activity';
import AccommodationPreview from 'features/AdminPreview/Accommodation';
import NewExcursionStep2Form from 'features/NewExcursionForm/pages/NewExcursionStep2Form';
import NewExcursionStep1Form from 'features/NewExcursionForm/pages/NewExcursionStep1Form';
import NewExcursionStep3Form from 'features/NewExcursionForm/pages/NewExcursionStep3Form';
import NewExcursionStep4Form from 'features/NewExcursionForm/pages/NewExcursionStep4Form';
import NewExcursionStep5Form from 'features/NewExcursionForm/pages/NewExcursionStep5Form';
import NewExcursionStep6Form from 'features/NewExcursionForm/pages/NewExcursionStep6Form';
import NewExcursionStep7Form from 'features/NewExcursionForm/pages/NewExcursionStep7Form';
import ExcursionStampPage from 'features/ExcursionStampPage';
import ExcursionPublicDetailsPage from 'features/ExcursionPublicDetailsPage';
import ExcursionPublicStampPage from 'features/ExcursionPublicStampPage';

const pages = [
  {
    exact: true,
    path: '/',
    auth: false,
    component: HomePage,
  },
  {
    path: '/expo',
    auth: false,
    component: Expo,
  },
  {
    path: '/private',
    auth: true,
    component: Private,
  },
  {
    exact: true,
    path: '/signup',
    auth: false,
    component: ChooseArea,
  },
  {
    exact: true,
    path: '/login',
    auth: false,
    component: ChooseArea,
  },
  {
    path: '/signup/tourist',
    auth: false,
    component: SignUpTourist,
  },
  {
    path: '/signup/admin',
    auth: false,
    component: SignUpAdmin,
  },
  {
    path: '/login/tourist',
    auth: false,
    component: SignUpTourist,
  },
  {
    path: '/login/admin',
    auth: false,
    component: HandleLoginAdmin,
  },
  {
    path: '/my-account',
    auth: true,
    component: HandleAccount,
  },
  {
    path: '/destinations/:destination?',
    auth: false,
    component: DestinationsPage,
  },
  {
    path: '/accommodations',
    auth: false,
    exact: true,
    component: AccommodationsPage,
  },
  {
    path: '/accommodations/:id',
    auth: false,
    component: AccomodationDetailCard,
  },
  {
    path: '/tour',
    auth: true,
    component: TourOrganizerPage,
  },
  {
    path: '/what-to-do/:slug?',
    exact: true,
    auth: false,
    component: HandleTab,
  },
  {
    path: '/what-to-do/itineraries/:slug',
    auth: false,
    component: Itineraries,
  },
  {
    path: '/news/:slug',
    auth: false,
    component: NewsDetail,
  },
  {
    path: '/news',
    auth: false,
    component: News,
  },
  {
    path: '/activities/:id',
    auth: false,
    component: ActivitiesDetails,
  },
  {
    path: '/events/:slug',
    auth: false,
    component: EventDetailCard,
  },
  {
    path: '/how-to-get',
    auth: false,
    component: HowToGet,
  },
  {
    path: '/services',
    auth: false,
    component: Services,
  },
  {
    path: '/forgot-password',
    auth: false,
    component: ForgotPassword,
  },
  {
    path: '/password-reset/:code?',
    auth: false,
    component: PasswordReset,
  },
  {
    path: '/email-confirmation/:code?',
    auth: false,
    component: EmailConfirmation,
  },
  {
    path: '/my-experiences',
    auth: true,
    component: MyExperiences,
  },
  {
    path: '/admin/destination/:id',
    auth: true,
    component: Destination,
  },
  {
    path: '/admin/strategic-map',
    auth: true,
    component: StrategicMap,
  },
  {
    path: '/admin/tourist-offers/:slug?',
    auth: true,
    component: TouristOffers,
  },
  {
    path: '/admin/business-list-overview/:id?',
    auth: true,
    component: TouristOffersBusinessOverview,
  },
  {
    path: '/admin/events-list-overview/:id?',
    auth: true,
    component: TouristOffersEventsOverview,
  },
  {
    path: '/admin/trade/my-business-new',
    auth: true,
    component: TradeMyBusinessNewPage,
  },
  {
    path: '/admin/config/:slug?',
    auth: true,
    component: Config,
  },
  {
    path: '/admin/activities/new-activity',
    auth: true,
    component: TourActivityForm,
  },
  {
    path: '/admin/activities/:id?',
    auth: true,
    component: TourActivityForm,
  },
  {
    path: '/admin/reviews',
    auth: true,
    component: ReviewsPage,
  },
  {
    path: '/admin/excursions',
    auth: true,
    component: ExcursionsPage,
  },
  {
    path: '/admin/excursion/excursion-stamp/:id?',
    auth: true,
    component: ExcursionStampPage,
  },
  {
    path: '/public/excursion/excursion-stamp/:id?',
    auth: false,
    component: ExcursionPublicStampPage,
  },
  {
    path: '/public/excursion-details/:id?',
    auth: false,
    component: ExcursionPublicDetailsPage,
  },
  {
    path: '/admin/new-excursion/form-new-excursion',
    auth: true,
    component: NewExcursionStep1Form,
  },
  {
    path: '/admin/new-excursion/step1',
    auth: true,
    component: NewExcursionStep1Form,
    exact: true,
  },
  {
    path: '/admin/new-excursion/step2',
    auth: true,
    component: NewExcursionStep2Form,
    exact: true,
  },
  {
    path: '/admin/new-excursion/step3',
    auth: true,
    component: NewExcursionStep3Form,
    exact: true,
  },
  {
    path: '/admin/new-excursion/step4',
    auth: true,
    component: NewExcursionStep4Form,
    exact: true,
  },
  {
    path: '/admin/new-excursion/step5',
    auth: true,
    component: NewExcursionStep5Form,
    exact: true,
  },
  {
    path: '/admin/new-excursion/step6',
    auth: true,
    component: NewExcursionStep6Form,
    exact: true,
  },
  {
    path: '/admin/new-excursion/step7',
    auth: true,
    component: NewExcursionStep7Form,
    exact: true,
  },
  {
    path: '/event/:id?',
    auth: true,
    component: EventForm,
  },
  {
    path: '/itinerary/:id?',
    auth: true,
    component: ItineraryForm,
  },
  {
    path: '/logout',
    auth: false,
    component: Logout,
  },
  {
    path: '/acctmgmt',
    auth: false,
    component: AccountManagement,
  },
  {
    path: '/admin/trade/my-business',
    auth: true,
    component: TradeOffers,
  },
  {
    path: '/admin/trade/my-business-edit/:id?',
    auth: true,
    component: TradeMyBusinessNewPage,
  },
  {
    path: '/admin/trade/schedule:id?',
    auth: true,
    component: EventsSchedule,
  },
  {
    path: '/admin/news',
    auth: true,
    component: NewsAdmin,
  },
  {
    path: '/admin/add-news',
    auth: true,
    component: NewsAdminNewPage,
  },
  {
    path: '/admin/news-edit/:id?',
    auth: true,
    component: NewsAdminNewPage,
  },
  {
    path: '/admin/observatory-tourist',
    auth: true,
    component: ObservatoryTourist,
  },
  {
    path: '/admin/users-panel/:id',
    auth: true,
    component: UserDetail,
  },
  {
    path: '/admin/users-panel',
    auth: true,
    component: UserPanel,
  },
  {
    path: '/admin/event/:id/preview',
    auth: true,
    component: EventPreview,
  },
  {
    path: '/admin/activity/:id/preview',
    auth: true,
    component: ActivityPreview,
  },
  {
    path: '/admin/accommodation/:id/preview',
    auth: true,
    component: AccommodationPreview,
  },
];

export default pages;
