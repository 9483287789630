import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  ButtonsDiv,
  Container,
  ExcursionCompanyInformation,
  ExcursionFirstForm,
  ExcursionFirstFormContainer,
  FormRow4Cols,
  InputField,
  StepperBullet,
  StepperContainer,
  StepperItem,
  StepperLine,
  StepperName,
} from '../../styles';
import MainTitle from 'components/MainTitle';
import Button from 'components/Button';
import { Formik, Field, Form, useFormikContext } from 'formik';
import ErrorMessage from 'components/ErrorMessage';
import FieldMask from 'components/FieldMask';
import SelectComponent from 'components/Select';
import {
  DocumentTypeEnum,
  documentTypes,
  DocumentTypeSelectProps,
  states,
  validateCNPJ,
  validateCPF,
  validateDocument,
} from 'features/Signup/pages/SignupTourOrganizer/utils';
import {
  IconGuia,
  IconHospedagem,
  IconOrganizador,
  IconPagamento,
  IconResumo,
  IconTransporte,
  IconViagem,
} from 'features/NewExcursionForm/components/StepperIcons';
import ConfirmationModal from 'components/ConfirmationModal';
import { useExcursionOrganizatorForm } from 'common/ExcursionOrganizatorFormContext';
import TextArea from 'components/TextArea';
import { AccommodationTypeEnum } from 'features/NewExcursionForm/utils';
import yup from 'utils/validation';
import { validDDDs } from 'utils/basicUtils';
import { CIDADES_POR_ESTADO, Estados } from 'utils/location';
import { useLoading } from 'hooks/useLoading';
import CitySelectComponent from 'features/NewExcursionForm/components/Select/CitySelectComponent';
import CEPField from 'features/NewExcursionForm/components/Select/CEPFieldComponent';
import LockNavigatorHook from 'features/NewExcursionForm/hooks/LockNavigatorHook';

interface Form4Case1Props {
  disabledButton: boolean;
}

interface Form4Case2Props {
  disabledButton: boolean;
}

interface Form4Case3Props {
  disabledButton: boolean;
}

interface Form4Case4Props {
  disabledButton: boolean;
}

const Form4Case1: React.FC<Form4Case1Props> = ({ disabledButton }) => {
  const { values, setErrors } = useFormikContext();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const excursionOrganizatorFormContext = useExcursionOrganizatorForm();

  const initialValues = {
    accommodation: {
      justification: excursionOrganizatorFormContext.form.accommodation.justification ?? '',
    },
  };

  const validationSchema = yup.object().shape({
    accommodation: yup.object().shape({
      justification: yup
        .string()
        .matches(/^(?!^\d+$)[\d\D]*$/, 'A justificativa não pode conter somente números')
        .required('Campo Obrigatório'),
    }),
  });

  const handleCancel = () => {
    excursionOrganizatorFormContext.setForm({
      ...excursionOrganizatorFormContext.form,
      organizer: {
        ...excursionOrganizatorFormContext.form.organizer,
        document_type: '',
        name: '',
        document_number: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
      },
      origin_city: '',
      origin_state: '',
      start_date: '',
      hour_start: '',
      end_date: '',
      hour_end: '',
      people_number: 0,
      destination: [],
      central_access: false,
      travel_reason: '',
      declaration_file: '',
      transport: {
        ...excursionOrganizatorFormContext.form.transport,
        trade_name: '',
        legal_name: '',
        document_number: '',
        cadastur: '',
        phone: '',
        email: '',
        vehicle_type: '',
        vehicle_plate: '',
        has_pcd: false,
      },
      accommodation: {
        ...excursionOrganizatorFormContext.form.accommodation,
        accommodation_type: 0,
        cadastur: '',
        license_number: '',
        document_number: '',
        name: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        justification: '',
        iss: '',
      },
      guide: {
        ...excursionOrganizatorFormContext.form.guide,
        city_tour: false,
        coastal_tour: false,
        type: '',
        guide_name: '',
        cadastur: '',
        phone: '',
        email: '',
      },
      total_price: 0,
    });
    setShowModal(false);
    history.push('/admin/excursions');
  };

  const onSubmit = async () => {
    try {
      const validationFormInputs = await validationSchema.validate(values, { abortEarly: false });

      if (validationFormInputs) {
        excursionOrganizatorFormContext.setForm({
          ...excursionOrganizatorFormContext.form,
          accommodation: {
            justification: validationFormInputs.accommodation.justification,
            cadastur: '',
            license_number: '',
            iss: '',
            document_number: '',
            document_type: 'ND',
            name: '',
            phone: '',
            email: '',
            cep: '',
            address: '',
            number: '',
            complement: '',
            neighborhood: '',
            state: '',
            city: '',
            accommodation_type: 1,
          },
        });
        history.push('/admin/new-excursion/step5');
      }
    } catch (error: any) {
      setErrors(
        error.inner.reduce((errors: any, innerError: any) => ({ ...errors, [innerError.path]: innerError.message }), {})
      );
      console.log(
        'setErrors',
        error.inner.reduce((errors: any, innerError: any) => ({ ...errors, [innerError.path]: innerError.message }), {})
      );
      console.error(error);
    }
  };

  return (
    <>
      <Form
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onSubmit();
          }
        }}>
        <InputField id="full-name-input">
          <TextArea
            name="accommodation.justification"
            placeholder="Ex.: Viagem de apenas 1 dia, Irei me hospedar fora de Porto Seguro, etc."
            isRequired
            title="Justificativa"
            maxLength={500}
            lg={true}
          />
        </InputField>
        <ButtonsDiv>
          <Button buttonType="secondary" onClick={() => history.push('/admin/new-excursion/step3')}>
            Voltar
          </Button>
          <article>
            <Button
              type="button"
              buttonType="secondary"
              onClick={e => {
                e.preventDefault();
                setShowModal(true);
              }}>
              Cancelar
            </Button>
            <Button
              type="button"
              buttonType={disabledButton ? 'disabled' : 'primary'}
              onClick={onSubmit}
              disabled={disabledButton}>
              Próximo
            </Button>
          </article>
        </ButtonsDiv>
      </Form>
      {showModal && (
        <ConfirmationModal
          open={showModal}
          onCancel={() => {
            setShowModal(false);
          }}
          onConfirm={handleCancel}
          title="Cancelar Cadastro de Excursão"
          message={
            <p>
              Se prosseguir com a ação, <strong>não será possível recuperar os dados</strong> já perdidos. Deseja
              continuar?
            </p>
          }
          confirmText="Confirmar"
          cancelText="Voltar"
        />
      )}
    </>
  );
};

const Form4Case2: React.FC<Form4Case2Props> = ({ disabledButton }) => {
  const { values, setErrors, setFieldValue } = useFormikContext<any>();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const excursionOrganizatorFormContext = useExcursionOrganizatorForm();
  const [selectedState, setSelectedState] = useState<string>('');
  const { openLoading, closeLoading } = useLoading();
  const [cidades, setCidades] = useState<{ label: string; value: string }[]>([]);
  const numberInputRef = useRef<HTMLInputElement>(null);
  const addressInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (excursionOrganizatorFormContext.form.accommodation.accommodation_type === 2) {
      setSelectedState(excursionOrganizatorFormContext.form.accommodation.state);
    }
  }, []);

  useEffect(() => {
    if (values.accommodation.state) {
      setSelectedState(values.accommodation.state);

      const cidadesEncontradas = CIDADES_POR_ESTADO[values.accommodation.state as Estados] || [];
      setCidades(
        cidadesEncontradas
          .map(cidade => ({
            label: cidade.nomeIBGE,
            value: cidade.nomeIBGE,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)) // Ordenar em ordem alfabética
      );

      if (values.accommodation.city) {
        const cidadeExiste = cidadesEncontradas.some(cidade => cidade.nomeIBGE === values.accommodation.city);
        if (!cidadeExiste) {
          setFieldValue('accommodation.city', '');
        }
      }
    }
  }, [values.accommodation.state, values.accommodation.city, setFieldValue]);

  const initialValues = {
    accommodation: {
      cadastur: excursionOrganizatorFormContext.form.accommodation.cadastur ?? '',
      license_number: Number(excursionOrganizatorFormContext.form.accommodation.license_number) ?? '',
      iss: Number(excursionOrganizatorFormContext.form.accommodation.iss) ?? '',
      document_number: excursionOrganizatorFormContext.form.accommodation.document_number ?? '',
      document_type: excursionOrganizatorFormContext.form.accommodation.document_type ?? '',
      name: excursionOrganizatorFormContext.form.accommodation.name ?? '',
      phone: excursionOrganizatorFormContext.form.accommodation.phone ?? '',
      email: excursionOrganizatorFormContext.form.accommodation.email ?? '',
      cep: excursionOrganizatorFormContext.form.accommodation.cep ?? '',
      address: excursionOrganizatorFormContext.form.accommodation.address ?? '',
      number: excursionOrganizatorFormContext.form.accommodation.number ?? '',
      complement: excursionOrganizatorFormContext.form.accommodation.complement ?? '',
      neighborhood: excursionOrganizatorFormContext.form.accommodation.neighborhood ?? '',
      state: excursionOrganizatorFormContext.form.accommodation.state ?? '',
      city: excursionOrganizatorFormContext.form.accommodation.city ?? '',
    },
  };

  const validationSchema = yup.object().shape({
    accommodation: yup.object().shape({
      cadastur: yup
        .string()
        .required('Número do Cadastur é obrigatório')
        .matches(/^\d{8,14}$/, 'Cadastur deve conter entre 8 e 14 dígitos numéricos'),
      license_number: yup
        .string()
        .required('Alvará de Funcionamento é obrigatório')
        .matches(/^[A-Za-z0-9./-]+$/, 'Alvará contém caracteres inválidos')
        .min(6, 'O Alvará deve ter pelo menos 6 caracteres')
        .max(20, 'O Alvará deve ter no máximo 20 caracteres'),
      iss: yup
        .string()
        .required('ISS Municipal é obrigatório')
        .matches(/^\d+$/, 'ISS deve conter apenas números')
        .max(15, 'ISS deve ter no máximo 15 dígitos'),
      document_number: yup
        .string()
        .matches(/^([\w\.\-\/]{18}|[\w\.\-\/]{14})$/, 'Informe um CNPJ completo')
        .test('is-valid-cnpj', 'Informe um CNPJ válido', value => validateCNPJ(value || ''))
        .required('Campo Obrigatório'),
      document_type: yup.string(),
      name: yup
        .string()
        .required('Nome Fantasia é obrigatório')
        .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ0-9\s&.,'-]+$/, 'Nome Fantasia contém caracteres inválidos')
        .min(2, 'Nome Fantasia deve ter pelo menos 2 caracteres')
        .max(100, 'Nome Fantasia deve ter no máximo 100 caracteres'),
      phone: yup
        .string()
        .matches(/^\(\d{2}\)\s\d{1}\s\d{4}-\d{4}$/, 'Telefone deve ter o formato (XX) X XXXX-XXXX')
        .test('is-valid-ddd', 'DDD inválido', function (value) {
          if (!value) return false;
          const ddd = value.match(/^\((\d{2})\)/);
          if (!ddd) return false;
          return validDDDs.includes(ddd[1]);
        })
        .test('not-all-same', 'O número do telefone não pode ter todos os dígitos iguais', function (value) {
          if (!value) return false;

          const digits = value.replace(/\D/g, ''); // Remove caracteres não numéricos
          const phoneNumber = digits.slice(2); // Remove os dois primeiros dígitos do DDD

          return !/^(\d)\1+$/.test(phoneNumber); // Garante que o telefone não tenha todos os números iguais
        })
        .required('Telefone é obrigatório'),
      email: yup
        .string()
        .trim()
        .lowercase()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'E-mail inválido. Use um formato válido, ex: usuario@email.com'
        )
        .required('Campo Obrigatório'),
      cep: yup
        .string()
        .required('Campo Obrigatório')
        .matches(/^\d{5}-?\d{3}$/, 'CEP deve ter o formato XXXXX-XXX'),
      address: yup
        .string()
        .matches(/^(?!\d+$).*/, 'O campo endereço não pode ser apenas números')
        .required('Campo Obrigatório'),
      number: yup.string().matches(/^\d+$/, 'Número deve conter apenas dígitos').required('Campo Obrigatório'),
      complement: yup.string(),
      neighborhood: yup
        .string()
        .matches(/^(?!^\d+$)[\d\D]*$/, 'O bairro não pode conter somente números')
        .required('Campo Obrigatório'),
      state: yup.string().required('Campo Obrigatório'),
      city: yup
        .string()
        .matches(/^(?!^\d+$)[\d\D]*$/, 'A cidade não pode conter somente números')
        .required('Campo Obrigatório'),
    }),
  });

  const handleCancel = () => {
    excursionOrganizatorFormContext.setForm({
      ...excursionOrganizatorFormContext.form,
      organizer: {
        ...excursionOrganizatorFormContext.form.organizer,
        document_type: '',
        name: '',
        document_number: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
      },
      origin_city: '',
      origin_state: '',
      start_date: '',
      hour_start: '',
      end_date: '',
      hour_end: '',
      people_number: 0,
      destination: [],
      central_access: false,
      travel_reason: '',
      declaration_file: '',
      transport: {
        ...excursionOrganizatorFormContext.form.transport,
        trade_name: '',
        legal_name: '',
        document_number: '',
        cadastur: '',
        phone: '',
        email: '',
        vehicle_type: '',
        vehicle_plate: '',
        has_pcd: false,
      },
      accommodation: {
        ...excursionOrganizatorFormContext.form.accommodation,
        accommodation_type: 0,
        cadastur: '',
        license_number: '',
        document_number: '',
        name: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        justification: '',
        iss: '',
      },
      guide: {
        ...excursionOrganizatorFormContext.form.guide,
        city_tour: false,
        coastal_tour: false,
        type: '',
        guide_name: '',
        cadastur: '',
        phone: '',
        email: '',
      },
      total_price: 0,
    });
    setShowModal(false);
    history.push('/admin/excursions');
  };

  useEffect(() => {
    console.log('Valor de cidade no Formik:', values.accommodation.city);
  }, [values.accommodation.city]);

  const onSubmit = async () => {
    try {
      const validationFormInputs = await validationSchema.validate(values, { abortEarly: false });

      if (validationFormInputs) {
        excursionOrganizatorFormContext.setForm({
          ...excursionOrganizatorFormContext.form,
          accommodation: {
            justification: '',
            cadastur: validationFormInputs.accommodation.cadastur,
            license_number: validationFormInputs.accommodation.license_number || '',
            iss: validationFormInputs.accommodation.iss || '',
            document_number: validationFormInputs.accommodation.document_number,
            document_type: validationFormInputs.accommodation.document_type || '',
            name: validationFormInputs.accommodation.name,
            phone: validationFormInputs.accommodation.phone,
            email: validationFormInputs.accommodation.email,
            cep: validationFormInputs.accommodation.cep || '',
            address: validationFormInputs.accommodation.address,
            number: validationFormInputs.accommodation.number,
            complement: validationFormInputs.accommodation.complement || '',
            neighborhood: validationFormInputs.accommodation.neighborhood,
            state: validationFormInputs.accommodation.state,
            city: validationFormInputs.accommodation.city,
            accommodation_type: 2,
          },
        });
        history.push('/admin/new-excursion/step5');
      }
    } catch (error: any) {
      setErrors(
        error.inner.reduce((errors: any, innerError: any) => ({ ...errors, [innerError.path]: innerError.message }), {})
      );
      console.log(
        'setErrors',
        error.inner.reduce((errors: any, innerError: any) => ({ ...errors, [innerError.path]: innerError.message }), {})
      );
      console.error(error);
    }
  };

  const handleStateChange = async (
    estado: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    setFieldValue('accommodation.state', estado);

    const cidadesEncontradas = CIDADES_POR_ESTADO[estado as Estados] || [];
    setCidades(
      cidadesEncontradas
        .map(cidade => ({
          label: cidade.nomeIBGE,
          value: cidade.nomeIBGE,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) // Ordenar em ordem alfabética
    );

    setFieldValue('accommodation.city', ''); // Limpa a cidade selecionada
  };

  return (
    <>
      <Form
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onSubmit();
          }
        }}>
        <FormRow4Cols>
          <InputField id="full-name-input">
            <div>
              <label htmlFor="">
                Cadastur <span>*</span>
              </label>
              <Field
                name="accommodation.cadastur"
                maxLength={14}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value.replace(/\D/g, '');
                }}
              />
            </div>
            <ErrorMessage name="accommodation.cadastur" />
          </InputField>
          <InputField>
            <div>
              <label htmlFor="">
                Número do Alvará <span>*</span>
              </label>
              <Field name="accommodation.license_number" maxLength={20} />
            </div>
            <ErrorMessage name="accommodation.license_number" />
          </InputField>
          <InputField>
            <div>
              <label htmlFor="">
                ISS Municipal <span>*</span>
              </label>
              <Field
                name="accommodation.iss"
                maxLength={15} // Limita o tamanho máximo
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value.replace(/\D/g, ''); // Remove qualquer caractere não numérico
                }}
              />
            </div>
            <ErrorMessage name="accommodation.iss" />
          </InputField>
          <InputField>
            <div>
              <label htmlFor="">
                CNPJ<span>*</span>
              </label>
              <FieldMask type="cnpj">
                <Field type="text" name="accommodation.document_number" placeholder="ex: xx.xxx.xxx/0001-xx" />
              </FieldMask>
            </div>
            <ErrorMessage name="accommodation.document_number" />
          </InputField>
        </FormRow4Cols>
        <FormRow4Cols>
          <InputField className="responsive-grid-column-span-2" style={{ gridColumn: 'span 2' }}>
            <div>
              <label htmlFor="">
                Nome Fantasia<span>*</span>
              </label>
              <Field
                name="accommodation.name"
                maxLength={100}
                onInput={(e: React.FormEvent<HTMLInputElement>) =>
                  (e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9\s&.,'-]/g, ''))
                }
              />
            </div>
            <ErrorMessage name="accommodation.name" />
          </InputField>
          <InputField id="cellphone-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                Telefone ou Celular<span>*</span>
              </label>
              <FieldMask type="phone">
                <Field name="accommodation.phone" placeholder="ex.: (xx) x xxxx-xxxx" />
              </FieldMask>
            </div>
            <ErrorMessage name="accommodation.phone" />
          </InputField>
          <InputField id="email-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                E-mail<span>*</span>
              </label>
              <FieldMask type="email">
                <Field type="text" name="accommodation.email" />
              </FieldMask>
            </div>
            <ErrorMessage name="accommodation.email" />
          </InputField>
        </FormRow4Cols>
        <FormRow4Cols>
          <InputField id="state-input" className="responsive-grid-column-span-2">
            <Field
              name="accommodation.cep"
              render={() => (
                <CEPField
                  name="accommodation.cep"
                  label="CEP"
                  currentCity={values.accommodation.city} // Passa o valor atual da cidade
                  required
                  value={values.accommodation.cep}
                  onChange={(name, value) => setFieldValue(name, value)} // Atualiza no Formik
                  nextFieldRef={numberInputRef}
                  fallbackFieldRef={addressInputRef}
                  fieldMappings={[
                    { formField: 'accommodation.city', responseField: 'localidade' },
                    { formField: 'accommodation.address', responseField: 'logradouro' },
                    { formField: 'accommodation.neighborhood', responseField: 'bairro' },
                    { formField: 'accommodation.state', responseField: 'uf' },
                    { formField: 'accommodation.complement', responseField: 'complemento' },
                  ]}
                />
              )}
            />
            <ErrorMessage name="accommodation.cep" />
          </InputField>
          <InputField id="address-input" style={{ gridColumn: 'span 2' }}>
            <div>
              <label htmlFor="">
                Endereço<span>*</span>
              </label>
              <Field name="accommodation.address" innerRef={addressInputRef} maxLength={200} />
            </div>
            <ErrorMessage name="accommodation.address" />
          </InputField>
          <InputField id="number-input">
            <div>
              <label htmlFor="">
                Número<span>*</span>
              </label>
              <Field
                name="accommodation.number"
                innerRef={numberInputRef} // Conecta a referência
                maxLength={9}
              />
            </div>
            <ErrorMessage name="accommodation.number" />
          </InputField>
          <InputField id="complement-input">
            <div>
              <label htmlFor="">Complemento</label>
              <Field name="accommodation.complement" />
            </div>
            <ErrorMessage name="accommodation.complement" />
          </InputField>
          <InputField id="neighborhood-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                Bairro<span>*</span>
              </label>
              <Field name="accommodation.neighborhood" />
            </div>
            <ErrorMessage name="accommodation.neighborhood" />
          </InputField>
          <InputField id="state-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                Estado<span>*</span>
              </label>
              <Field
                component={({ field, form }: { field: any; form: any }) => (
                  <SelectComponent
                    label=""
                    options={states}
                    onChange={value => {
                      handleStateChange(value as string, form.setFieldValue); // Atualiza o estado e carrega as cidades
                      form.setFieldValue(field.name, value); // Atualiza o estado selecionado no Formik
                    }}
                    name={field.name}
                    selected={field.value ? [field.value] : []} // Mantém o estado selecionado
                  />
                )}
                name="accommodation.state"
              />
            </div>
            <ErrorMessage name="accommodation.state" />
          </InputField>

          <InputField id="state-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                Cidade<span>*</span>
              </label>
              <Field
                name="accommodation.city"
                component={({ field, form }: { field: any; form: any }) => (
                  <CitySelectComponent
                    label=""
                    name={field.name}
                    options={cidades} // Lista de cidades para o estado selecionado
                    value={field.value} // Valor atual no Formik
                    onChange={selectedCity => form.setFieldValue(field.name, selectedCity)} // Atualiza diretamente a cidade
                    placeholderText="Selecione uma cidade"
                    disabled={!values.accommodation.state} // Desabilita se não tiver um estado selecionado
                  />
                )}
              />
            </div>
            <ErrorMessage name="accommodation.city" />
          </InputField>
        </FormRow4Cols>
        <ButtonsDiv>
          <Button buttonType="secondary" onClick={() => history.push('/admin/new-excursion/step3')}>
            Voltar
          </Button>
          <article>
            <Button
              type="button"
              buttonType="secondary"
              onClick={e => {
                e.preventDefault();
                setShowModal(true);
              }}>
              Cancelar
            </Button>
            <Button
              type="button"
              buttonType={disabledButton ? 'disabled' : 'primary'}
              onClick={onSubmit}
              disabled={disabledButton}>
              Próximo
            </Button>
          </article>
        </ButtonsDiv>
      </Form>
      {showModal && (
        <ConfirmationModal
          open={showModal}
          onCancel={() => {
            setShowModal(false);
          }}
          onConfirm={handleCancel}
          title="Cancelar Cadastro de Excursão"
          message={
            <p>
              Se prosseguir com a ação, <strong>não será possível recuperar os dados</strong> já perdidos. Deseja
              continuar?
            </p>
          }
          confirmText="Confirmar"
          cancelText="Voltar"
        />
      )}
    </>
  );
};

const Form4Case3: React.FC<Form4Case3Props> = ({ disabledButton }) => {
  const { values, setErrors, setFieldValue } = useFormikContext<any>();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const excursionOrganizatorFormContext = useExcursionOrganizatorForm();
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>('');
  const [documentTypeForm, setDocumentTypeForm] = useState<string>('');
  const [selectedState, setSelectedState] = useState<string>('');
  const [cidades, setCidades] = useState<{ label: string; value: string }[]>([]);
  const { openLoading, closeLoading } = useLoading();
  const numberInputRef = useRef<HTMLInputElement>(null);
  const addressInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (excursionOrganizatorFormContext.form.accommodation.accommodation_type === 3) {
      setSelectedDocumentType(excursionOrganizatorFormContext.form.accommodation.document_type);
      setDocumentTypeForm(excursionOrganizatorFormContext.form.accommodation.document_type);
      setSelectedState(excursionOrganizatorFormContext.form.accommodation.state);
    }
  }, []);

  useEffect(() => {
    if (values.accommodation.state) {
      setSelectedState(values.accommodation.state);

      const cidadesEncontradas = CIDADES_POR_ESTADO[values.accommodation.state as Estados] || [];
      setCidades(
        cidadesEncontradas
          .map(cidade => ({
            label: cidade.nomeIBGE,
            value: cidade.nomeIBGE,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)) // Ordenar em ordem alfabética
      );

      if (values.accommodation.city) {
        const cidadeExiste = cidadesEncontradas.some(cidade => cidade.nomeIBGE === values.accommodation.city);
        if (!cidadeExiste) {
          setFieldValue('accommodation.city', '');
        }
      }
    }
  }, [values.accommodation.state, values.accommodation.city, setFieldValue]);

  const initialValues = {
    accommodation: {
      iss: '',
      document_number: '',
      document_type: '',
      name: '',
      phone: '',
      email: '',
      cep: '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      state: '',
      city: '',
    },
  };

  const validationSchema = yup.object().shape({
    accommodation: yup.object().shape({
      document_number: yup
        .string()
        .test('is-valid-document', 'Documento inválido', function (value) {
          const documentType = this.parent.document_type;
          return validateDocument(value ?? '', documentType);
        })
        .required('Campo Obrigatório'),
      document_type: yup.string(),
      name: yup.string().when('document_type', {
        is: (val: string) => val === 'CPF',
        then: schema =>
          schema
            .trim()
            .required('Campo Obrigatório')
            .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, 'O nome deve conter apenas letras e espaços')
            .min(2, 'Nome deve possuir no mínimo 2 caracteres com sobrenome')
            .max(150, 'Nome muito longo, máximo 150 caracteres')
            .test('contains-full-name', 'Informe um nome e sobrenome', value => {
              if (!value) return false;
              return value.trim().split(/\s+/).length > 1;
            }),
        otherwise: schema =>
          schema
            .trim()
            .required('Campo Obrigatório')
            .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, 'O nome deve conter apenas letras e espaços')
            .min(2, 'Nome deve possuir no mínimo 2 caracteres')
            .max(150, 'Nome muito longo, máximo 150 caracteres'),
      }),
      iss: yup
        .string()
        .required('ISS Municipal é obrigatório')
        .matches(/^\d+$/, 'ISS deve conter apenas números')
        .max(15, 'ISS deve ter no máximo 15 dígitos'),
      phone: yup
        .string()
        .matches(/^\(\d{2}\)\s\d{1}\s\d{4}-\d{4}$/, 'Telefone deve ter o formato (XX) X XXXX-XXXX')
        .test('is-valid-ddd', 'DDD inválido', function (value) {
          if (!value) return false;
          const ddd = value.match(/^\((\d{2})\)/);
          if (!ddd) return false;
          return validDDDs.includes(ddd[1]);
        })
        .test('not-all-same', 'O número do telefone não pode ter todos os dígitos iguais', function (value) {
          if (!value) return false;

          const digits = value.replace(/\D/g, ''); // Remove caracteres não numéricos
          const phoneNumber = digits.slice(2); // Remove os dois primeiros dígitos do DDD

          return !/^(\d)\1+$/.test(phoneNumber); // Garante que o telefone não tenha todos os números iguais
        })
        .required('Telefone é obrigatório'),
      email: yup
        .string()
        .trim()
        .lowercase()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'E-mail inválido. Use um formato válido, ex: usuario@email.com'
        )
        .required('Campo Obrigatório'),
      cep: yup
        .string()
        .matches(/^\d{5}-?\d{3}$/, 'CEP deve ter o formato XXXXX-XXX')
        .test('is-not-equal', 'CEP não pode conter todos os números iguais', function (value) {
          if (!value) return false;
          const cleanedValue = value.replace('-', '');
          return !/^(.)\1+$/.test(cleanedValue);
        })
        .required('Campo Obrigatório'),
      address: yup
        .string()
        .matches(/^(?!\d+$).*/, 'O campo endereço não pode ser apenas números')
        .required('Campo Obrigatório'),
      number: yup.string().matches(/^\d+$/, 'Número deve conter apenas dígitos').required('Campo Obrigatório'),
      complement: yup.string(),
      neighborhood: yup
        .string()
        .matches(/^(?!^\d+$)[\d\D]*$/, 'O bairro não pode conter somente números')
        .required('Campo Obrigatório'),
      state: yup.string().required('Campo Obrigatório'),
      city: yup
        .string()
        .matches(/^(?!^\d+$)[\d\D]*$/, 'A cidade não pode conter somente números')
        .required('Campo Obrigatório'),
    }),
  });

  const handleCancel = () => {
    excursionOrganizatorFormContext.setForm({
      ...excursionOrganizatorFormContext.form,
      organizer: {
        ...excursionOrganizatorFormContext.form.organizer,
        document_type: '',
        name: '',
        document_number: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
      },
      origin_city: '',
      origin_state: '',
      start_date: '',
      hour_start: '',
      end_date: '',
      hour_end: '',
      people_number: 0,
      destination: [],
      central_access: false,
      travel_reason: '',
      declaration_file: '',
      transport: {
        ...excursionOrganizatorFormContext.form.transport,
        trade_name: '',
        legal_name: '',
        document_number: '',
        cadastur: '',
        phone: '',
        email: '',
        vehicle_type: '',
        vehicle_plate: '',
        has_pcd: false,
      },
      accommodation: {
        ...excursionOrganizatorFormContext.form.accommodation,
        accommodation_type: 0,
        cadastur: '',
        license_number: '',
        document_number: '',
        name: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        justification: '',
        iss: '',
      },
      guide: {
        ...excursionOrganizatorFormContext.form.guide,
        city_tour: false,
        coastal_tour: false,
        type: '',
        guide_name: '',
        cadastur: '',
        phone: '',
        email: '',
      },
      total_price: 0,
    });
    setShowModal(false);
    history.push('/admin/excursions');
  };

  useEffect(() => {
    console.log('Valor de cidade no Formik:', values.accommodation.city);
  }, [values.accommodation.city]);

  const handleStateChange = async (
    estado: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    setFieldValue('accommodation.state', estado);

    const cidadesEncontradas = CIDADES_POR_ESTADO[estado as Estados] || [];
    setCidades(
      cidadesEncontradas
        .map(cidade => ({
          label: cidade.nomeIBGE,
          value: cidade.nomeIBGE,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) // Ordenar em ordem alfabética
    );

    setFieldValue('accommodation.city', ''); // Limpa a cidade selecionada
  };

  const onSubmit = async () => {
    try {
      const validationFormInputs = await validationSchema.validate(values, { abortEarly: false });

      if (validationFormInputs) {
        excursionOrganizatorFormContext.setForm({
          ...excursionOrganizatorFormContext.form,
          accommodation: {
            justification: '',
            cadastur: '',
            license_number: '',
            iss: validationFormInputs.accommodation.iss || '',
            document_number: validationFormInputs.accommodation.document_number,
            document_type: validationFormInputs.accommodation.document_type || '',
            name: validationFormInputs.accommodation.name || '',
            phone: validationFormInputs.accommodation.phone,
            email: validationFormInputs.accommodation.email,
            cep: validationFormInputs.accommodation.cep,
            address: validationFormInputs.accommodation.address,
            number: validationFormInputs.accommodation.number,
            complement: validationFormInputs.accommodation.complement || '',
            neighborhood: validationFormInputs.accommodation.neighborhood,
            state: validationFormInputs.accommodation.state,
            city: validationFormInputs.accommodation.city,
            accommodation_type: 3,
          },
        });
        history.push('/admin/new-excursion/step5');
      }
    } catch (error: any) {
      setErrors(
        error.inner.reduce((errors: any, innerError: any) => ({ ...errors, [innerError.path]: innerError.message }), {})
      );
      console.log(
        'setErrors',
        error.inner.reduce((errors: any, innerError: any) => ({ ...errors, [innerError.path]: innerError.message }), {})
      );
      console.error(error);
    }
  };

  const DocumentTypeSelect: React.FC<DocumentTypeSelectProps> = ({ field, form }) => {
    const { name } = field;
    return (
      <SelectComponent
        label=""
        options={documentTypes.map(documentType => ({ label: documentType.label, value: documentType.value }))}
        onChange={value => {
          if (typeof value === 'string') {
            form.setFieldValue(name, value as DocumentTypeEnum);
            setSelectedDocumentType(value);
            setDocumentTypeForm(value);
          }
        }}
        name="documentType"
        selected={[selectedDocumentType]}
      />
    );
  };

  console.log('Document Type Form:', documentTypeForm);
  console.log('accommodation.document_type:', values.accommodation.document_type);

  return (
    <>
      <Form
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onSubmit();
          }
        }}>
        <FormRow4Cols>
          <InputField id="document-type-input">
            <div>
              <label htmlFor="">
                Tipo de Pessoa <span>*</span>
              </label>
              <Field component={DocumentTypeSelect} name="accommodation.document_type" />
            </div>
            <ErrorMessage name="accommodation.document_type" />
          </InputField>
          {documentTypeForm === 'CPF' && (
            <>
              <InputField>
                <div>
                  <label htmlFor="">
                    CPF do Proprietário<span>*</span>
                  </label>
                  <FieldMask type="cpf">
                    <Field type="text" name="accommodation.document_number" placeholder="ex: xxx-xxx-xxx-xx" />
                  </FieldMask>
                </div>
                <ErrorMessage name="accommodation.document_number" />
              </InputField>
              <InputField className="responsive-grid-column-span-2" style={{ gridColumn: 'span 2' }}>
                <div>
                  <label htmlFor="">
                    Nome Completo do Prioprietário<span>*</span>
                  </label>
                  <Field name="accommodation.name" />
                </div>
                <ErrorMessage name="accommodation.name" />
              </InputField>
            </>
          )}
          {documentTypeForm === 'CNPJ' && (
            <>
              <InputField>
                <div>
                  <label htmlFor="">
                    CNPJ<span>*</span>
                  </label>
                  <FieldMask type="cnpj">
                    <Field type="text" name="accommodation.document_number" placeholder="ex: xx.xxx.xxx/0001-xx" />
                  </FieldMask>
                </div>
                <ErrorMessage name="accommodation.document_number" />
              </InputField>
              <InputField className="responsive-grid-column-span-2" style={{ gridColumn: 'span 2' }}>
                <div>
                  <label htmlFor="">
                    Nome Fantasia<span>*</span>
                  </label>
                  <Field name="accommodation.name" />
                </div>
                <ErrorMessage name="accommodation.name" />
              </InputField>
            </>
          )}
        </FormRow4Cols>
        {documentTypeForm !== '' && (
          <>
            <FormRow4Cols>
              <InputField>
                <div>
                  <label htmlFor="">
                    ISS Municipal <span>*</span>
                  </label>
                  <Field
                    name="accommodation.iss"
                    maxLength={15}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.target.value = e.target.value.replace(/\D/g, '');
                    }}
                  />
                </div>
                <ErrorMessage name="accommodation.iss" />
              </InputField>
              <InputField id="cellphone-input">
                <div>
                  <label htmlFor="">
                    Telefone ou Celular<span>*</span>
                  </label>
                  <FieldMask type="phone">
                    <Field name="accommodation.phone" placeholder="ex.: (xx) x xxxx-xxxx" />
                  </FieldMask>
                </div>
                <ErrorMessage name="accommodation.phone" />
              </InputField>
              <InputField id="email-input" className="responsive-grid-column-span-2">
                <div>
                  <label htmlFor="">
                    E-mail<span>*</span>
                  </label>
                  <FieldMask type="email">
                    <Field type="text" name="accommodation.email" />
                  </FieldMask>
                </div>
                <ErrorMessage name="accommodation.email" />
              </InputField>
              <InputField id="state-input" className="responsive-grid-column-span-2">
                <Field
                  name="accommodation.cep"
                  render={() => (
                    <CEPField
                      name="accommodation.cep"
                      label="CEP"
                      currentCity={values.accommodation.city} // Passa o valor atual da cidade
                      required
                      value={values.accommodation.cep}
                      onChange={(name, value) => setFieldValue(name, value)} // Atualiza no Formik
                      nextFieldRef={numberInputRef}
                      fallbackFieldRef={addressInputRef}
                      fieldMappings={[
                        { formField: 'accommodation.city', responseField: 'localidade' },
                        { formField: 'accommodation.address', responseField: 'logradouro' },
                        { formField: 'accommodation.neighborhood', responseField: 'bairro' },
                        { formField: 'accommodation.state', responseField: 'uf' },
                        { formField: 'accommodation.complement', responseField: 'complemento' },
                      ]}
                    />
                  )}
                />
                <ErrorMessage name="accommodation.cep" />
              </InputField>
            </FormRow4Cols>
            <FormRow4Cols>
              <InputField id="address-input" style={{ gridColumn: 'span 2' }}>
                <div>
                  <label htmlFor="">
                    Endereço<span>*</span>
                  </label>
                  <Field name="accommodation.address" innerRef={addressInputRef} maxLength={200} />
                </div>
                <ErrorMessage name="accommodation.address" />
              </InputField>
              <InputField id="number-input">
                <div>
                  <label htmlFor="">
                    Número<span>*</span>
                  </label>
                  <Field name="accommodation.number" innerRef={numberInputRef} />
                </div>
                <ErrorMessage name="accommodation.number" />
              </InputField>
              <InputField id="complement-input">
                <div>
                  <label htmlFor="">Complemento</label>
                  <Field name="accommodation.complement" />
                </div>
                <ErrorMessage name="accommodation.complement" />
              </InputField>
              <InputField id="neighborhood-input" className="responsive-grid-column-span-2">
                <div>
                  <label htmlFor="">
                    Bairro<span>*</span>
                  </label>
                  <Field name="accommodation.neighborhood" />
                </div>
                <ErrorMessage name="accommodation.neighborhood" />
              </InputField>
              <InputField id="state-input" className="responsive-grid-column-span-2">
                <div>
                  <label htmlFor="">
                    Estado<span>*</span>
                  </label>
                  <Field
                    component={({ field, form }: { field: any; form: any }) => (
                      <SelectComponent
                        label=""
                        options={states.map(state => ({ label: state.label, value: state.value }))}
                        onChange={value => {
                          handleStateChange(value as string, form.setFieldValue); // Atualiza o estado e carrega as cidades
                          form.setFieldValue(field.name, value); // Atualiza o estado selecionado no Formik
                        }}
                        name={field.name}
                        selected={field.value ? [field.value] : []} // Mantém o estado selecionado
                      />
                    )}
                    name="accommodation.state"
                  />
                </div>
                <ErrorMessage name="accommodation.state" />
              </InputField>
              <InputField id="state-input" className="responsive-grid-column-span-2">
                <div>
                  <label htmlFor="">
                    Cidade<span>*</span>
                  </label>
                  <Field
                    name="accommodation.city"
                    component={({ field, form }: { field: any; form: any }) => (
                      <CitySelectComponent
                        label=""
                        name={field.name}
                        options={cidades} // Lista de cidades para o estado selecionado
                        value={field.value} // Valor atual no Formik
                        onChange={selectedCity => form.setFieldValue(field.name, selectedCity)} // Atualiza diretamente a cidade
                        placeholderText="Selecione uma cidade"
                        disabled={!values.accommodation.state} // Desabilita se não tiver um estado selecionado
                      />
                    )}
                  />
                </div>
                <ErrorMessage name="accommodation.city" />
              </InputField>
            </FormRow4Cols>
            <ButtonsDiv>
              <Button buttonType="secondary" onClick={() => history.push('/admin/new-excursion/step3')}>
                Voltar
              </Button>
              <article>
                <Button
                  type="button"
                  buttonType="secondary"
                  onClick={e => {
                    e.preventDefault();
                    setShowModal(true);
                  }}>
                  Cancelar
                </Button>
                <Button
                  type="button"
                  buttonType={disabledButton ? 'disabled' : 'primary'}
                  onClick={onSubmit}
                  disabled={disabledButton}>
                  Próximo
                </Button>
              </article>
            </ButtonsDiv>
          </>
        )}
      </Form>
      {!documentTypeForm && (
        <ButtonsDiv>
          <Button buttonType="secondary" type="button" onClick={() => history.push('/admin/new-excursion/step3')}>
            Voltar
          </Button>
          <article>
            <Button
              type="button"
              buttonType="secondary"
              onClick={e => {
                e.preventDefault();
                setShowModal(true);
              }}>
              Cancelar
            </Button>
            <Button type="button" buttonType="disabled" disabled>
              Próximo
            </Button>
          </article>
        </ButtonsDiv>
      )}

      {showModal && (
        <ConfirmationModal
          open={showModal}
          onCancel={() => {
            setShowModal(false);
          }}
          onConfirm={handleCancel}
          title="Cancelar Cadastro de Excursão"
          message={
            <p>
              Se prosseguir com a ação, <strong>não será possível recuperar os dados</strong> já perdidos. Deseja
              continuar?
            </p>
          }
          confirmText="Confirmar"
          cancelText="Voltar"
        />
      )}
    </>
  );
};

const Form4Case4: React.FC<Form4Case4Props> = ({ disabledButton }) => {
  const { values, setErrors, setFieldValue } = useFormikContext<any>();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const excursionOrganizatorFormContext = useExcursionOrganizatorForm();
  const [selectedState, setSelectedState] = useState<string>('');
  const [cidades, setCidades] = useState<{ label: string; value: string }[]>([]);
  const numberInputRef = useRef<HTMLInputElement>(null);
  const addressInputRef = useRef<HTMLInputElement>(null);

  const initialValues = {
    accommodation: {
      document_number: '',
      name: '',
      phone: '',
      email: '',
      cep: '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      state: '',
      city: '',
    },
  };

  const validationSchema = yup.object().shape({
    accommodation: yup.object().shape({
      document_number: yup
        .string()
        .matches(/^([\w\.\-\/]{18}|[\w\.\-\/]{14})$/, 'Informe um CPF completo')
        .test('is-valid-cpf', 'Informe um CPF válido', value => validateCPF(value || ''))
        .required('Campo Obrigatório'),
      name: yup
        .string()
        .trim()
        .required('Campo Obrigatório')
        .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, 'O nome deve conter apenas letras e espaços')
        .test('contains-full-name', 'Informe um nome e sobrenome', value => {
          if (!value) return false;
          return value.trim().split(/\s+/).length > 1;
        })
        .min(2, 'Nome deve possuir no mínimo 2 caracteres com sobrenome')
        .max(150, 'Nome muito longo, máximo 150 caracteres'),
      phone: yup
        .string()
        .matches(/^\(\d{2}\)\s\d{1}\s\d{4}-\d{4}$/, 'Telefone deve ter o formato (XX) X XXXX-XXXX')
        .test('is-valid-ddd', 'DDD inválido', function (value) {
          if (!value) return false;
          const ddd = value.match(/^\((\d{2})\)/);
          if (!ddd) return false;
          return validDDDs.includes(ddd[1]);
        })
        .test('not-all-same', 'O número do telefone não pode ter todos os dígitos iguais', function (value) {
          if (!value) return false;

          const digits = value.replace(/\D/g, ''); // Remove caracteres não numéricos
          const phoneNumber = digits.slice(2); // Remove os dois primeiros dígitos do DDD

          return !/^(\d)\1+$/.test(phoneNumber); // Garante que o telefone não tenha todos os números iguais
        })
        .required('Telefone é obrigatório'),
      email: yup
        .string()
        .email()
        .matches(/^(?!\d+$).*/, 'O campo email não pode ser apenas números')
        .required('Campo Obrigatório'),
      cep: yup
        .string()
        .matches(/^\d{5}-?\d{3}$/, 'CEP deve ter o formato XXXXX-XXX')
        .test('is-not-equal', 'CEP não pode conter todos os números iguais', function (value) {
          if (!value) return false;
          const cleanedValue = value.replace('-', '');
          return !/^(.)\1+$/.test(cleanedValue);
        })
        .required('Campo Obrigatório'),
      address: yup
        .string()
        .matches(/^(?!\d+$).*/, 'O campo endereço não pode ser apenas números')
        .required('Campo Obrigatório'),
      number: yup.string().matches(/^\d+$/, 'Número deve conter apenas dígitos').required('Campo Obrigatório'),
      complement: yup.string(),
      neighborhood: yup
        .string()
        .matches(/^(?!^\d+$)[\d\D]*$/, 'O bairro não pode conter somente números')
        .required('Campo Obrigatório'),
      state: yup.string().required('Campo Obrigatório'),
      city: yup
        .string()
        .matches(/^(?!^\d+$)[\d\D]*$/, 'A cidade não pode conter somente números')
        .required('Campo Obrigatório'),
    }),
  });

  const handleCancel = () => {
    excursionOrganizatorFormContext.setForm({
      ...excursionOrganizatorFormContext.form,
      organizer: {
        ...excursionOrganizatorFormContext.form.organizer,
        document_type: '',
        name: '',
        document_number: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
      },
      origin_city: '',
      origin_state: '',
      start_date: '',
      hour_start: '',
      end_date: '',
      hour_end: '',
      people_number: 0,
      destination: [],
      central_access: false,
      travel_reason: '',
      declaration_file: '',
      transport: {
        ...excursionOrganizatorFormContext.form.transport,
        trade_name: '',
        legal_name: '',
        document_number: '',
        cadastur: '',
        phone: '',
        email: '',
        vehicle_type: '',
        vehicle_plate: '',
        has_pcd: false,
      },
      accommodation: {
        ...excursionOrganizatorFormContext.form.accommodation,
        accommodation_type: 0,
        cadastur: '',
        license_number: '',
        document_number: '',
        name: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        justification: '',
        iss: '',
      },
      guide: {
        ...excursionOrganizatorFormContext.form.guide,
        city_tour: false,
        coastal_tour: false,
        type: '',
        guide_name: '',
        cadastur: '',
        phone: '',
        email: '',
      },
      total_price: 0,
    });
    setShowModal(false);
    history.push('/admin/excursions');
  };

  useEffect(() => {
    if (values.accommodation.state) {
      setSelectedState(values.accommodation.state);

      const cidadesEncontradas = CIDADES_POR_ESTADO[values.accommodation.state as Estados] || [];
      setCidades(
        cidadesEncontradas
          .map(cidade => ({
            label: cidade.nomeIBGE,
            value: cidade.nomeIBGE,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)) // Ordenar em ordem alfabética
      );

      if (values.accommodation.city) {
        const cidadeExiste = cidadesEncontradas.some(cidade => cidade.nomeIBGE === values.accommodation.city);
        if (!cidadeExiste) {
          setFieldValue('accommodation.city', '');
        }
      }
    }
  }, [values.accommodation.state, values.accommodation.city, setFieldValue]);

  const onSubmit = async () => {
    try {
      const validationFormInputs = await validationSchema.validate(values, { abortEarly: false });

      if (validationFormInputs) {
        excursionOrganizatorFormContext.setForm({
          ...excursionOrganizatorFormContext.form,
          accommodation: {
            justification: '',
            cadastur: '',
            license_number: '',
            iss: '',
            document_type: 'CPF',
            document_number: validationFormInputs.accommodation.document_number,
            name: validationFormInputs.accommodation.name,
            phone: validationFormInputs.accommodation.phone,
            email: validationFormInputs.accommodation.email,
            cep: validationFormInputs.accommodation.cep,
            address: validationFormInputs.accommodation.address,
            number: validationFormInputs.accommodation.number,
            complement: validationFormInputs.accommodation.complement || '',
            neighborhood: validationFormInputs.accommodation.neighborhood,
            state: validationFormInputs.accommodation.state,
            city: validationFormInputs.accommodation.city,
            accommodation_type: 4,
          },
        });
        history.push('/admin/new-excursion/step5');
      }
    } catch (error: any) {
      setErrors(
        error.inner.reduce((errors: any, innerError: any) => ({ ...errors, [innerError.path]: innerError.message }), {})
      );
      console.log(
        'setErrors',
        error.inner.reduce((errors: any, innerError: any) => ({ ...errors, [innerError.path]: innerError.message }), {})
      );
      console.error(error);
    }
  };

  const handleStateChange = async (
    estado: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    setFieldValue('accommodation.state', estado);

    const cidadesEncontradas = CIDADES_POR_ESTADO[estado as Estados] || [];
    setCidades(
      cidadesEncontradas
        .map(cidade => ({
          label: cidade.nomeIBGE,
          value: cidade.nomeIBGE,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) // Ordenar em ordem alfabética
    );

    setFieldValue('accommodation.city', ''); // Limpa a cidade selecionada
  };

  return (
    <>
      <Form
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onSubmit();
          }
        }}>
        <FormRow4Cols>
          <InputField className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                CPF do Proprietário<span>*</span>
              </label>
              <FieldMask type="cpf">
                <Field type="text" name="accommodation.document_number" placeholder="ex: xxx.xxx.xxx-xx" />
              </FieldMask>
            </div>
            <ErrorMessage name="accommodation.document_number" />
          </InputField>
          <InputField className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                Nome Completo do Prioprietário<span>*</span>
              </label>
              <Field name="accommodation.name" />
            </div>
            <ErrorMessage name="accommodation.name" />
          </InputField>
          <InputField id="cellphone-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                Telefone ou Celular<span>*</span>
              </label>
              <FieldMask type="phone">
                <Field name="accommodation.phone" placeholder="ex.: (xx) x xxxx-xxxx" />
              </FieldMask>
            </div>
            <ErrorMessage name="accommodation.phone" />
          </InputField>
          <InputField id="email-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                E-mail<span>*</span>
              </label>
              <FieldMask type="email">
                <Field type="text" name="accommodation.email" />
              </FieldMask>
            </div>
            <ErrorMessage name="accommodation.email" />
          </InputField>
        </FormRow4Cols>
        <FormRow4Cols>
          <InputField id="cep-input" className="responsive-grid-column-span-2">
            <Field
              name="accommodation.cep"
              render={() => (
                <CEPField
                  name="accommodation.cep"
                  label="CEP"
                  currentCity={values.accommodation.city} // Passa o valor atual da cidade
                  required
                  value={values.accommodation.cep}
                  onChange={(name, value) => setFieldValue(name, value)} // Atualiza no Formik
                  nextFieldRef={numberInputRef}
                  fallbackFieldRef={addressInputRef}
                  fieldMappings={[
                    { formField: 'accommodation.city', responseField: 'localidade' },
                    { formField: 'accommodation.address', responseField: 'logradouro' },
                    { formField: 'accommodation.neighborhood', responseField: 'bairro' },
                    { formField: 'accommodation.state', responseField: 'uf' },
                    { formField: 'accommodation.complement', responseField: 'complemento' },
                  ]}
                />
              )}
            />
            <ErrorMessage name="accommodation.cep" />
          </InputField>
          <InputField id="address-input" style={{ gridColumn: 'span 2' }}>
            <div>
              <label htmlFor="">
                Endereço<span>*</span>
              </label>
              <Field name="accommodation.address" innerRef={addressInputRef} maxLength={200} />
            </div>
            <ErrorMessage name="accommodation.address" />
          </InputField>
          <InputField id="number-input">
            <div>
              <label htmlFor="">
                Número<span>*</span>
              </label>
              <Field
                name="accommodation.number"
                innerRef={numberInputRef} // Conecta a referência
                maxLength={9}
              />
            </div>
            <ErrorMessage name="accommodation.number" />
          </InputField>
          <InputField id="complement-input">
            <div>
              <label htmlFor="">Complemento</label>
              <Field name="accommodation.complement" />
            </div>
            <ErrorMessage name="accommodation.complement" />
          </InputField>
          <InputField id="neighborhood-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                Bairro<span>*</span>
              </label>
              <Field name="accommodation.neighborhood" />
            </div>
            <ErrorMessage name="accommodation.neighborhood" />
          </InputField>
          <InputField id="state-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                Estado<span>*</span>
              </label>
              <Field
                component={({ field, form }: { field: any; form: any }) => (
                  <SelectComponent
                    label=""
                    options={states.map(state => ({ label: state.label, value: state.value }))}
                    onChange={value => {
                      handleStateChange(value as string, form.setFieldValue); // Atualiza o estado e carrega as cidades
                      form.setFieldValue(field.name, value); // Atualiza o estado selecionado no Formik
                    }}
                    name={field.name}
                    selected={field.value ? [field.value] : []} // Mantém o estado selecionado
                  />
                )}
                name="accommodation.state"
              />
            </div>
            <ErrorMessage name="accommodation.state" />
          </InputField>
          <InputField id="city-input" className="responsive-grid-column-span-2">
            <div id="city-select">
              <label htmlFor="">
                Cidade<span>*</span>
              </label>
              <Field
                name="accommodation.city"
                component={({ field, form }: { field: any; form: any }) => (
                  <CitySelectComponent
                    label=""
                    name={field.name}
                    options={cidades} // Lista de cidades para o estado selecionado
                    value={field.value} // Valor atual no Formik
                    onChange={selectedCity => form.setFieldValue(field.name, selectedCity)} // Atualiza diretamente a cidade
                    placeholderText="Selecione uma cidade"
                    disabled={!values.accommodation.state} // Desabilita se não tiver um estado selecionado
                  />
                )}
              />
            </div>
            <ErrorMessage name="accommodation.city" />
          </InputField>
        </FormRow4Cols>
        <ButtonsDiv>
          <Button buttonType="secondary" onClick={() => history.push('/admin/new-excursion/step3')}>
            Voltar
          </Button>
          <article>
            <Button
              type="button"
              buttonType="secondary"
              onClick={e => {
                e.preventDefault();
                setShowModal(true);
              }}>
              Cancelar
            </Button>
            <Button
              type="button"
              buttonType={disabledButton ? 'disabled' : 'primary'}
              onClick={onSubmit}
              disabled={disabledButton}>
              Próximo
            </Button>
          </article>
        </ButtonsDiv>
      </Form>
      {showModal && (
        <ConfirmationModal
          open={showModal}
          onCancel={() => {
            setShowModal(false);
          }}
          onConfirm={handleCancel}
          title="Cancelar Cadastro de Excursão"
          message={
            <p>
              Se prosseguir com a ação, <strong>não será possível recuperar os dados</strong> já perdidos. Deseja
              continuar?
            </p>
          }
          confirmText="Confirmar"
          cancelText="Voltar"
        />
      )}
    </>
  );
};

const NewExcursionStep4Form = (): JSX.Element => {
  const [selectedRadio, setSelectedRadio] = useState<AccommodationTypeEnum>();
  const history = useHistory();
  const excursionOrganizatorFormContext = useExcursionOrganizatorForm();
  const [showModal, setShowModal] = useState(false);
  const [disabledInput, setDisabledInput] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);

  LockNavigatorHook(history);

  const initialValuesCase1 = {
    accommodation: {
      type: 1,
      justification: excursionOrganizatorFormContext.form.accommodation.justification || '',
      document_type: 'ND',
    },
  };

  const validationSchemaCase1 = yup.object().shape({
    accommodation: yup.object().shape({
      type: yup.number(),
      justification: yup
        .string()
        .matches(/^(?!^\d+$)[\d\D]*$/, 'A justificativa não pode conter somente números')
        .required('Campo Obrigatório'),
    }),
  });

  const initialValuesCase2 = {
    accommodation: {
      type: 2,
      cadastur: excursionOrganizatorFormContext.form.accommodation.cadastur || '',
      license_number: excursionOrganizatorFormContext.form.accommodation.license_number || '',
      iss: excursionOrganizatorFormContext.form.accommodation.iss || '',
      document_number: excursionOrganizatorFormContext.form.accommodation.document_number || '',
      document_type: 'CNPJ',
      name: excursionOrganizatorFormContext.form.accommodation.name || '',
      phone: excursionOrganizatorFormContext.form.accommodation.phone || '',
      email: excursionOrganizatorFormContext.form.accommodation.email || '',
      cep: excursionOrganizatorFormContext.form.accommodation.cep || '',
      address: excursionOrganizatorFormContext.form.accommodation.address || '',
      number: excursionOrganizatorFormContext.form.accommodation.number || '',
      complement: excursionOrganizatorFormContext.form.accommodation.complement || '',
      neighborhood: excursionOrganizatorFormContext.form.accommodation.neighborhood || '',
      state: excursionOrganizatorFormContext.form.accommodation.state || '',
      city: excursionOrganizatorFormContext.form.accommodation.city || '',
    },
  };

  const validationSchemaCase2 = yup.object().shape({
    accommodation: yup.object().shape({
      type: yup.number(),
      cadastur: yup
        .string()
        .required('Número do Cadastur é obrigatório')
        .matches(/^\d{8,14}$/, 'Cadastur deve conter entre 8 e 14 dígitos numéricos'),
      license_number: yup
        .string()
        .required('Alvará de Funcionamento é obrigatório')
        .matches(/^[A-Za-z0-9./-]+$/, 'Alvará contém caracteres inválidos')
        .min(6, 'O Alvará deve ter pelo menos 6 caracteres')
        .max(20, 'O Alvará deve ter no máximo 20 caracteres'),
      iss: yup
        .string()
        .required('ISS Municipal é obrigatório')
        .matches(/^\d+$/, 'ISS deve conter apenas números')
        .max(15, 'ISS deve ter no máximo 15 dígitos'),
      document_number: yup
        .string()
        .matches(/^([\w\.\-\/]{18}|[\w\.\-\/]{14})$/, 'Informe um CNPJ completo')
        .test('is-valid-cnpj', 'Informe um CNPJ válido', value => validateCNPJ(value || ''))
        .required('Campo Obrigatório'),
      document_type: yup.string().required('Campo obrigatório'),
      name: yup
        .string()
        .trim()
        .required('Campo Obrigatório')
        .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, 'O nome deve conter apenas letras e espaços')
        .min(2, 'Nome deve possuir no mínimo 2 caracteres com sobrenome')
        .max(150, 'Nome muito longo, máximo 150 caracteres'),
      phone: yup
        .string()
        .matches(/^\(\d{2}\)\s\d{1}\s\d{4}-\d{4}$/, 'Telefone deve ter o formato (XX) X XXXX-XXXX')
        .test('is-valid-ddd', 'DDD inválido', function (value) {
          if (!value) return false;
          const ddd = value.match(/^\((\d{2})\)/);
          if (!ddd) return false;
          return validDDDs.includes(ddd[1]);
        })
        .test('not-all-same', 'O número do telefone não pode ter todos os dígitos iguais', function (value) {
          if (!value) return false;

          const digits = value.replace(/\D/g, ''); // Remove caracteres não numéricos
          const phoneNumber = digits.slice(2); // Remove os dois primeiros dígitos do DDD

          return !/^(\d)\1+$/.test(phoneNumber); // Garante que o telefone não tenha todos os números iguais
        })
        .required('Telefone é obrigatório'),
      email: yup
        .string()
        .trim()
        .lowercase()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'E-mail inválido. Use um formato válido, ex: usuario@email.com'
        )
        .required('Campo Obrigatório'),
      cep: yup.string().required('Campo obrigatório'),
      address: yup.string().required('Campo obrigatório'),
      number: yup.string().required('Campo obrigatório'),
      complement: yup.string(),
      neighborhood: yup.string().required('Campo obrigatório'),
      state: yup.string().required('Campo obrigatório'),
      city: yup.string().required('Campo obrigatório'),
    }),
  });

  const initialValuesCase3 = {
    accommodation: {
      type: 3,
      cadastur: excursionOrganizatorFormContext.form.accommodation.cadastur || '',
      document_type: excursionOrganizatorFormContext.form.accommodation.document_type || '',
      document_number: excursionOrganizatorFormContext.form.accommodation.document_number || '',
      name: excursionOrganizatorFormContext.form.accommodation.name || '',
      iss: excursionOrganizatorFormContext.form.accommodation.iss || '',
      phone: excursionOrganizatorFormContext.form.accommodation.phone || '',
      email: excursionOrganizatorFormContext.form.accommodation.email || '',
      cep: excursionOrganizatorFormContext.form.accommodation.cep || '',
      address: excursionOrganizatorFormContext.form.accommodation.address || '',
      number: excursionOrganizatorFormContext.form.accommodation.number || '',
      complement: excursionOrganizatorFormContext.form.accommodation.complement || '',
      neighborhood: excursionOrganizatorFormContext.form.accommodation.neighborhood || '',
      state: excursionOrganizatorFormContext.form.accommodation.state || '',
      city: excursionOrganizatorFormContext.form.accommodation.city || '',
    },
  };

  const validationSchemaCase3 = yup.object().shape({
    accommodation: yup.object().shape({
      type: yup.number(),
      document_type: yup.string().required('Campo obrigatório'),
      document_number: yup
        .string()
        .test('is-valid-document', 'Documento inválido', function (value) {
          const documentType = this.parent.document_type;
          return validateDocument(value ?? '', documentType);
        })
        .required('Campo Obrigatório'),
      name: yup.string().when('document_type', {
        is: (val: string) => val === 'CPF',
        then: schema =>
          schema
            .trim()
            .required('Campo Obrigatório')
            .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, 'O nome deve conter apenas letras e espaços')
            .min(2, 'Nome deve possuir no mínimo 2 caracteres com sobrenome')
            .max(150, 'Nome muito longo, máximo 150 caracteres')
            .test('contains-full-name', 'Informe um nome e sobrenome', value => {
              if (!value) return false;
              return value.trim().split(/\s+/).length > 1;
            }),
        otherwise: schema =>
          schema
            .trim()
            .required('Campo Obrigatório')
            .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, 'O nome deve conter apenas letras e espaços')
            .min(2, 'Nome deve possuir no mínimo 2 caracteres')
            .max(150, 'Nome muito longo, máximo 150 caracteres'),
      }),
      iss: yup.string().required('Campo obrigatório'),
      phone: yup
        .string()
        .matches(/^\(\d{2}\)\s\d{1}\s\d{4}-\d{4}$/, 'Telefone deve ter o formato (XX) X XXXX-XXXX')
        .test('is-valid-ddd', 'DDD inválido', function (value) {
          if (!value) return false;
          const ddd = value.match(/^\((\d{2})\)/);
          if (!ddd) return false;
          return validDDDs.includes(ddd[1]);
        })
        .test('not-all-same', 'O número do telefone não pode ter todos os dígitos iguais', function (value) {
          if (!value) return false;

          const digits = value.replace(/\D/g, ''); // Remove caracteres não numéricos
          const phoneNumber = digits.slice(2); // Remove os dois primeiros dígitos do DDD

          return !/^(\d)\1+$/.test(phoneNumber); // Garante que o telefone não tenha todos os números iguais
        })
        .required('Telefone é obrigatório'),
      email: yup
        .string()
        .trim()
        .lowercase()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'E-mail inválido. Use um formato válido, ex: usuario@email.com'
        )
        .required('Campo Obrigatório'),
      cep: yup.string().required('Campo obrigatório'),
      address: yup.string().required('Campo obrigatório'),
      number: yup.string().required('Campo obrigatório'),
      complement: yup.string(),
      neighborhood: yup.string().required('Campo obrigatório'),
      state: yup.string().required('Campo obrigatório'),
      city: yup.string().required('Campo obrigatório'),
    }),
  });

  const initialValuesCase4 = {
    accommodation: {
      type: 4,
      document_number: excursionOrganizatorFormContext.form.accommodation.document_number || '',
      document_type: 'CPF',
      name: excursionOrganizatorFormContext.form.accommodation.name || '',
      phone: excursionOrganizatorFormContext.form.accommodation.phone || '',
      email: excursionOrganizatorFormContext.form.accommodation.email || '',
      cep: excursionOrganizatorFormContext.form.accommodation.cep || '',
      address: excursionOrganizatorFormContext.form.accommodation.address || '',
      number: excursionOrganizatorFormContext.form.accommodation.number || '',
      complement: excursionOrganizatorFormContext.form.accommodation.complement || '',
      neighborhood: excursionOrganizatorFormContext.form.accommodation.neighborhood || '',
      state: excursionOrganizatorFormContext.form.accommodation.state || '',
      city: excursionOrganizatorFormContext.form.accommodation.city || '',
    },
  };

  const validationSchemaCase4 = yup.object().shape({
    accommodation: yup.object().shape({
      type: yup.number(),
      document_number: yup
        .string()
        .matches(/^([\w\.\-\/]{18}|[\w\.\-\/]{14})$/, 'Informe um CPF completo')
        .test('is-valid-cpf', 'Informe um CPF válido', value => validateCPF(value || ''))
        .required('Campo Obrigatório'),
      name: yup
        .string()
        .trim()
        .required('Campo Obrigatório')
        .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, 'O nome deve conter apenas letras e espaços')
        .test('contains-full-name', 'Informe um nome e sobrenome', value => {
          if (!value) return false;
          return value.trim().split(/\s+/).length > 1;
        })
        .min(2, 'Nome deve possuir no mínimo 2 caracteres com sobrenome')
        .max(150, 'Nome muito longo, máximo 150 caracteres'),
      phone: yup
        .string()
        .matches(/^\(\d{2}\)\s\d{1}\s\d{4}-\d{4}$/, 'Telefone deve ter o formato (XX) X XXXX-XXXX')
        .test('is-valid-ddd', 'DDD inválido', function (value) {
          if (!value) return false;
          const ddd = value.match(/^\((\d{2})\)/);
          if (!ddd) return false;
          return validDDDs.includes(ddd[1]);
        })
        .test('not-all-same', 'O número do telefone não pode ter todos os dígitos iguais', function (value) {
          if (!value) return false;

          const digits = value.replace(/\D/g, '');
          const phoneNumber = digits.slice(2);

          return !/^(\d)\1+$/.test(phoneNumber);
        })
        .required('Telefone é obrigatório'),
      email: yup
        .string()
        .trim()
        .lowercase()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'E-mail inválido. Use um formato válido, ex: usuario@email.com'
        )
        .required('Campo Obrigatório'),
      cep: yup.string().required('Campo obrigatório'),
      address: yup.string().required('Campo obrigatório'),
      number: yup.string().required('Campo obrigatório'),
      complement: yup.string(),
      neighborhood: yup.string().required('Campo obrigatório'),
      state: yup.string().required('Campo obrigatório'),
      city: yup.string().required('Campo obrigatório'),
    }),
  });

  const handleRadioChange = () => {
    switch (selectedRadio) {
      case 1:
        return (
          <Formik
            validate={values => {
              const isValid = validationSchemaCase1.isValidSync(values);
              setDisabledButton(!isValid);
            }}
            initialValues={initialValuesCase1}
            validationSchema={validationSchemaCase1}
            onSubmit={() => {
              null;
            }}
            enableReinitialize>
            {({ validateForm }) => {
              useEffect(() => {
                validateForm();
              }, [validateForm]);
              return <Form4Case1 disabledButton={disabledButton} />;
            }}
          </Formik>
        );
        break;
      case 2:
        return (
          <Formik
            validate={values => {
              const isValid = validationSchemaCase2.isValidSync(values);
              setDisabledButton(!isValid);
            }}
            initialValues={initialValuesCase2}
            validationSchema={validationSchemaCase2}
            onSubmit={() => {
              null;
            }}
            enableReinitialize>
            {({ validateForm }) => {
              useEffect(() => {
                validateForm();
              }, [validateForm]);
              return <Form4Case2 disabledButton={disabledButton} />;
            }}
          </Formik>
        );
        break;
      case 3:
        return (
          <Formik
            validate={values => {
              const isValid = validationSchemaCase3.isValidSync(values);
              setDisabledButton(!isValid);
            }}
            initialValues={initialValuesCase3}
            validationSchema={validationSchemaCase3}
            onSubmit={() => {
              null;
            }}
            enableReinitialize>
            {({ validateForm }) => {
              useEffect(() => {
                validateForm();
              }, [validateForm]);
              return <Form4Case3 disabledButton={disabledButton} />;
            }}
          </Formik>
        );
        break;
      case 4:
        return (
          <Formik
            validate={values => {
              const isValid = validationSchemaCase4.isValidSync(values);
              setDisabledButton(!isValid);
            }}
            initialValues={initialValuesCase4}
            validationSchema={validationSchemaCase4}
            onSubmit={() => {
              null;
            }}
            enableReinitialize>
            {({ validateForm }) => {
              useEffect(() => {
                validateForm();
              }, [validateForm]);
              return <Form4Case4 disabledButton={disabledButton} />;
            }}
          </Formik>
        );
        break;
      default:
        return <></>;
        break;
    }
  };

  useEffect(() => {
    excursionOrganizatorFormContext.form.accommodation.accommodation_type === 1 && setSelectedRadio(1);
    excursionOrganizatorFormContext.form.accommodation.accommodation_type === 2 && setSelectedRadio(2);
    excursionOrganizatorFormContext.form.accommodation.accommodation_type === 3 && setSelectedRadio(3);
    excursionOrganizatorFormContext.form.accommodation.accommodation_type === 4 && setSelectedRadio(4);
  }, []);

  console.log('selectedRadio', selectedRadio);
  console.log('excursionOrganizatorFormContext.form.accommodation', excursionOrganizatorFormContext.form.accommodation);

  const emptyAccommodationForm = () => {
    excursionOrganizatorFormContext.setForm({
      ...excursionOrganizatorFormContext.form,
      accommodation: {
        ...excursionOrganizatorFormContext.form.accommodation,
        accommodation_type: 0,
        cadastur: '',
        license_number: '',
        document_number: '',
        name: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        justification: '',
        iss: '',
      },
    });

    setDisabledButton(true);
  };

  const handleCancel = () => {
    excursionOrganizatorFormContext.setForm({
      ...excursionOrganizatorFormContext.form,
      organizer: {
        ...excursionOrganizatorFormContext.form.organizer,
        document_type: '',
        name: '',
        document_number: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
      },
      origin_city: '',
      origin_state: '',
      start_date: '',
      hour_start: '',
      end_date: '',
      hour_end: '',
      people_number: 0,
      destination: [],
      central_access: false,
      travel_reason: '',
      declaration_file: '',
      transport: {
        ...excursionOrganizatorFormContext.form.transport,
        trade_name: '',
        legal_name: '',
        document_number: '',
        cadastur: '',
        phone: '',
        email: '',
        vehicle_type: '',
        vehicle_plate: '',
        has_pcd: false,
      },
      accommodation: {
        ...excursionOrganizatorFormContext.form.accommodation,
        accommodation_type: 0,
        cadastur: '',
        license_number: '',
        document_number: '',
        name: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        justification: '',
        iss: '',
      },
      guide: {
        ...excursionOrganizatorFormContext.form.guide,
        city_tour: false,
        coastal_tour: false,
        type: '',
        guide_name: '',
        cadastur: '',
        phone: '',
        email: '',
      },
      total_price: 0,
    });
    setShowModal(false);
    history.push('/admin/excursions');
  };

  return (
    <Container>
      <MainTitle>Cadastro de Excursão</MainTitle>

      <StepperContainer>
        <StepperItem className="flex flex-col items-center justify-center">
          <article>
            <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step1')}>
              <IconOrganizador color="#ffffff" />
            </StepperBullet>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Organizador</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <Link to="#">
              <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step2')}>
                <IconViagem color="#ffffff" />
              </StepperBullet>
            </Link>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Viagem</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <Link to="#">
              <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step3')}>
                <IconTransporte />
              </StepperBullet>
            </Link>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Transporte</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <Link to="#">
              <StepperBullet className="onEdit" onClick={e => e.preventDefault()}>
                <IconHospedagem color="#020c90" />
              </StepperBullet>
            </Link>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Hospedagem</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet onClick={e => e.preventDefault()}>
                <IconGuia />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <StepperName>Guia</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet onClick={e => e.preventDefault()}>
                <IconPagamento />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <StepperName>Pagamento</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet onClick={e => e.preventDefault()}>
                <IconResumo />
              </StepperBullet>
            </Link>
          </article>
          <StepperName>Resumo</StepperName>
        </StepperItem>
      </StepperContainer>

      <ExcursionFirstFormContainer>
        <p>*Campo Obrigatório</p>
        <ExcursionCompanyInformation>
          <h2>Dados do meio de hospedagem:</h2>
          <p>
            <strong>Atenção!</strong> As taxas de pagamento variam com o tipo de hospedagem e são aplicadas apenas para
            as localizadas em Porto Seguro. Consulte os valores{' '}
            <a
              href="https://storage.googleapis.com/bkt-porto-seguro-portal-images-dev/docs/Porto_Seguro_Taxas_de_transporte_e_hospedagem_2025.pdf"
              target="_blank"
              rel="noreferrer">
              aqui
            </a>
            . Caso não saiba informar os dados solicitados, entre em contato com o local de hospedagem.
          </p>
        </ExcursionCompanyInformation>

        <ExcursionFirstForm>
          <h3>Tipo de Hospedagem:</h3>
          <ul>
            <li>
              <input
                type="radio"
                name="accommodation.accommodation_type"
                id="accommodation_type_1"
                value="1"
                onChange={e => {
                  setSelectedRadio(Number(e.target.value) as AccommodationTypeEnum), emptyAccommodationForm();
                }}
                checked={selectedRadio === 1}
              />
              <label htmlFor="accommodation_type_1">Não irei me hospedar em Porto Seguro</label>
            </li>
            <li>
              <input
                type="radio"
                name="accommodation.accommodation_type"
                id="accommodation_type_2"
                value="2"
                onChange={e => {
                  setSelectedRadio(Number(e.target.value) as AccommodationTypeEnum), emptyAccommodationForm();
                }}
                checked={selectedRadio === 2}
              />
              <label htmlFor="accommodation_type_2">
                Estabelecimento em Porto Seguro com CNPJ, alvará e Cadastur e contribuinte do ISS municipal
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="accommodation.accommodation_type"
                id="accommodation_type_3"
                value="3"
                onChange={e => {
                  setSelectedRadio(Number(e.target.value) as AccommodationTypeEnum), emptyAccommodationForm();
                }}
                checked={selectedRadio === 3}
              />
              <label htmlFor="accommodation_type_3">
                Imóvel de locação em Porto Seguro e contribuinte do ISS municipal
              </label>
            </li>
            <li>
              <input
                type="radio"
                name="accommodation.accommodation_type"
                id="accommodation_type_4"
                value="4"
                onChange={e => {
                  setSelectedRadio(Number(e.target.value) as AccommodationTypeEnum), emptyAccommodationForm();
                }}
                checked={selectedRadio === 4}
              />
              <label htmlFor="accommodation_type_4">
                Imóvel de locação em Porto Seguro sem CNPJ, alvará e Cadastur e não contribuinte do ISS municipal
              </label>
            </li>
          </ul>

          {handleRadioChange()}
          {!selectedRadio && (
            <ButtonsDiv>
              <Button buttonType="secondary" type="button" onClick={() => history.push('/admin/new-excursion/step3')}>
                Voltar
              </Button>
              <article>
                <Button
                  type="button"
                  buttonType="secondary"
                  onClick={e => {
                    e.preventDefault();
                    setShowModal(true);
                  }}>
                  Cancelar
                </Button>
                <Button type="button" buttonType="disabled" disabled>
                  Próximo
                </Button>
              </article>
            </ButtonsDiv>
          )}
        </ExcursionFirstForm>
      </ExcursionFirstFormContainer>
      {showModal && (
        <ConfirmationModal
          open={showModal}
          onCancel={() => {
            setShowModal(false);
          }}
          onConfirm={handleCancel}
          title="Cancelar Cadastro de Excursão"
          message={
            <p>
              Se prosseguir com a ação, <strong>não será possível recuperar os dados</strong> já perdidos. Deseja
              continuar?
            </p>
          }
          confirmText="Confirmar"
          cancelText="Voltar"
        />
      )}
    </Container>
  );
};

export default NewExcursionStep4Form;
