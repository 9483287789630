import React from 'react';
import {
  ExcursionPublicDetails404Container,
  ExcursionPublicDetails404ContainerIcon,
  ExcursionPublicDetails404ContainerMessage,
  ExcursionPublicDetails404ContainerTitle,
} from './styles';
import CancelIcon from 'assets/images/blockGrayIcon.svg';

interface ExcursionPublicDetails404Props {
  title: string;
  message: string;
}

const ExcursionPublicDetails404: React.FC<ExcursionPublicDetails404Props> = ({ title, message }) => {
  return (
    <ExcursionPublicDetails404Container>
      <ExcursionPublicDetails404ContainerIcon src={CancelIcon} alt="Cancel Icon" />
      <ExcursionPublicDetails404ContainerTitle>{title}</ExcursionPublicDetails404ContainerTitle>
      <ExcursionPublicDetails404ContainerMessage>{message}</ExcursionPublicDetails404ContainerMessage>
    </ExcursionPublicDetails404Container>
  );
};

export default ExcursionPublicDetails404;
