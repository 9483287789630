import React from 'react';
import {
  FAQContainer,
  FAQContainerListArticle,
  FAQContainerListItemAnswer,
  FAQContainerListItemQuestion,
  FAQContainerTitle,
} from './styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface FAQComponentProps {
  title: string;
  faqList: {
    question: string;
    answer: string;
  }[];
}

const FAQComponent: React.FC<FAQComponentProps> = ({ title, faqList }) => {
  return (
    <FAQContainer>
      <FAQContainerTitle>{title}</FAQContainerTitle>
      <FAQContainerListArticle>
        {faqList.map((faq, index) => (
          <>
            <Accordion
              key={index}
              style={{
                boxShadow: 'none',
                border: 'none',
                borderBottom: '1px dashed #9a9a9a',
                paddingBottom: '10px',
                backgroundColor: '#f9f9f9',
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#272727' }} />}
                sx={{ backgroundColor: '#f9f9f9', boxShadow: 'none', padding: '0' }}>
                <FAQContainerListItemQuestion>{faq.question}</FAQContainerListItemQuestion>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: '#f9f9f9', boxShadow: 'none', padding: '0' }}>
                <FAQContainerListItemAnswer>{faq.answer}</FAQContainerListItemAnswer>
              </AccordionDetails>
            </Accordion>
            {/* <hr style={{ border: '1px dashed #9a9a9a' }} /> */}
          </>
        ))}
      </FAQContainerListArticle>
    </FAQContainer>
  );
};

export default FAQComponent;
