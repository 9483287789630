import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { ButtonsDiv, Container, FormRow, SignUpForm } from './styles';
import Button from 'components/Buttom/Button';
import FieldMask from 'components/FieldMask';
import ErrorMessage from 'components/ErrorMessage';
import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import IService from 'interfaces/IService';
import ServiceRestService from 'services/ServiceRestService';
import { useLoading } from 'hooks/useLoading';
import MessageModal from 'common/MessageModal';
import SelectComponent from 'components/Select';
import { placeIDLink } from 'utils/placeIDLink';
import questionMarkIcon from 'assets/images/questionMarkIcon.svg';

interface IBasicServiceFormProps {
  onCancel: (reload?: boolean) => void;
  editingService: IService | null;
  editingMode: boolean;
}

const BasicServiceForm = ({ onCancel, editingService, editingMode }: IBasicServiceFormProps): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const form = useRef<FormikProps<FormikValues>>(null);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [selectTouched, setSelectTouched] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [messageInfo, setMessageInfo] = useState({ title: '', message: '' });
  const service = new ServiceRestService();
  const options = [
    { label: `Sim`, value: `Sim` },
    { label: 'Não', value: 'Não' },
  ];

  useEffect(() => {
    openLoading();
    closeLoading();
  }, []);

  useEffect(() => {
    if (editingService) {
      const options = editingService.searchTerm ? 'Sim' : 'Não';
      form.current?.setValues({
        description: editingService.description || '',
        email: editingService.email || '',
        phone: editingService.phone || '',
        searchTerm: editingService.searchTerm || '',
        options,
      });
      handleChange(options);
    } else {
      form.current?.resetForm();
    }
  }, [editingService]);

  const handleChange = (selectedValue: string | string[]) => {
    let optionsValue = false;
    if (typeof selectedValue === 'string') {
      optionsValue = selectedValue === 'Sim';
      setShowAdditionalFields(selectedValue === 'Não');
      setSelectTouched(selectedValue);

      if (optionsValue) {
        form.current?.setFieldValue('phone', '');
        form.current?.setFieldValue('email', '');
      } else {
        form.current?.setFieldValue('searchTerm', '');
      }
    }
    form.current?.setFieldValue('options', optionsValue);
  };

  const handleCancel = () => {
    onCancel(messageInfo.title === 'Sucesso');
  };

  const validationSchema = yup.lazy(() => {
    return yup.object().shape({
      description: yup.string().required(),
      email: showAdditionalFields ? yup.string().email().required() : yup.string().email(),
      phone: showAdditionalFields ? yup.string().required() : yup.string(),
      searchTerm: !showAdditionalFields ? yup.string().required() : yup.string().notRequired(),
    });
  });

  const onSubmit = async (values: any) => {
    openLoading();

    try {
      const newServiceData: IService = {
        id: 0,
        description: values.description,
        email: values.email,
        phone: values.phone,
        searchTerm: values.searchTerm,
        options: values.options,
      };

      if (editingMode && editingService) {
        await service.update(editingService.id, newServiceData);
        setShowModal(true);
        const messageWithSpan = 'O serviço foi <span>editado</span> com sucesso.';
        setMessageInfo({
          title: 'Sucesso',
          message: messageWithSpan,
        });
      } else {
        await service.create(newServiceData);
        setShowModal(true);
        const messageWithSpan = 'O serviço foi <span>criado</span> com sucesso.';
        setMessageInfo({
          title: 'Sucesso',
          message: messageWithSpan,
        });
      }
    } catch (error) {
      setShowModal(true);
      const messageWithSpan = editingMode
        ? 'Erro ao <span>editar</span> serviço.'
        : 'Erro ao <span>criar</span> serviço.';
      setMessageInfo({
        title: 'Erro',
        message: messageWithSpan,
      });
    }

    closeLoading();
  };

  return (
    <>
      <Formik
        innerRef={form}
        initialValues={{ description: '', email: '', phone: '', searchTerm: '', options: false }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}>
        <Container>
          <div className="header">
            <h2>Dados do Serviço</h2>
            <span>1/1</span>
          </div>
          <SignUpForm>
            <Form>
              <FormRow>
                <div className="NameField">
                  <label>Nome do Serviço</label>
                  <Field name="description" />
                  <ErrorMessage name="description" />
                </div>
                <div className="NameField">
                  <SelectComponent
                    label="Possui Unidade Física?"
                    options={options}
                    onChange={handleChange}
                    withCheckbox={false}
                    selected={[selectTouched]}
                    name="options"
                  />
                </div>
              </FormRow>
              {selectTouched && (
                <>
                  {showAdditionalFields && (
                    <FormRow>
                      <div className="NameField">
                        <label>Telefone</label>
                        <FieldMask type="phone">
                          <Field name="phone" />
                        </FieldMask>
                        <ErrorMessage name="phone" />
                      </div>
                      <div className="NameField">
                        <label>E-mail</label>
                        <Field name="email" />
                        <ErrorMessage name="email" />
                      </div>
                    </FormRow>
                  )}
                  {!showAdditionalFields && (
                    <FormRow>
                      <div className="PlaceIDField">
                        <div className="TitleHeader">
                          <label>
                            Tipo de Serviço (ex.: Hospital, Delegacia) ou endereço (ex.: Rua Alfredo Dutra, 1 - Centro,
                            Porto Seguro - BA, 45810-000, Brasil)
                          </label>
                        </div>
                        <Field name="searchTerm" />
                        <ErrorMessage name="searchTerm" />
                      </div>
                    </FormRow>
                  )}
                </>
              )}
              <ButtonsDiv>
                <Button onClick={handleCancel} text="CANCELAR" />
                <Button type="submit" text="SALVAR" />
              </ButtonsDiv>
            </Form>
          </SignUpForm>
        </Container>
      </Formik>

      <MessageModal isOpen={showModal} title={messageInfo.title} message={messageInfo.message} onClose={handleCancel} />
    </>
  );
};

export default BasicServiceForm;
