import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  ButtonsDiv,
  Container,
  ExcursionCompanyInformation,
  ExcursionFirstForm,
  ExcursionFirstFormContainer,
  FormRow4Cols,
  InputField,
  StepperBullet,
  StepperContainer,
  StepperItem,
  StepperLine,
  StepperName,
} from '../../styles';
import MainTitle from 'components/MainTitle';
import Button from 'components/Button';
import { Formik, Field, Form, useFormikContext } from 'formik';
import FieldMask from 'components/FieldMask';
import SelectComponent from 'components/Select';
import {
  IconGuia,
  IconHospedagem,
  IconOrganizador,
  IconPagamento,
  IconResumo,
  IconTransporte,
  IconViagem,
} from 'features/NewExcursionForm/components/StepperIcons';
import {
  cityTour,
  CityTourEnum,
  CityTourProps,
  southTour,
  SouthTourEnum,
  SouthTourProps,
} from 'features/NewExcursionForm/utils';
import ConfirmationModal from 'components/ConfirmationModal';
import { useExcursionOrganizatorForm } from 'common/ExcursionOrganizatorFormContext';
import yup from 'utils/validation';
import { validDDDs } from 'utils/basicUtils';
import ErrorMessage from 'components/ErrorMessage';
import LockNavigatorHook from 'features/NewExcursionForm/hooks/LockNavigatorHook';

const GuideForm: React.FC = () => {
  const { values, setErrors } = useFormikContext();
  const history = useHistory();
  const [disabledInput, setDisabledInput] = useState(true);
  const [selectedCityTour, setSelectedCityTour] = useState<string>('');
  const [selectedSouthTour, setSelectedSouthTour] = useState<string>('');
  const [selectedRadio, setSelectedRadio] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const excursionOrganizatorFormContext = useExcursionOrganizatorForm();
  const [informationCheck, setInformationCheck] = useState(false);

  const clearGuideForm = () => {
    setSelectedRadio('');
    setDisabledInput(true);
    (values as any).guide.type = '';
    (values as any).guide.guide_name = '';
    (values as any).guide.cadastur = '';
    (values as any).guide.phone = '';
    (values as any).guide.email = '';
    (values as any).guide.information_check = false;
  };

  useEffect(() => {
    if (excursionOrganizatorFormContext.form.guide.city_tour === true) {
      setSelectedCityTour('Sim');
    } else if (excursionOrganizatorFormContext.form.guide.city_tour === false) {
      setSelectedCityTour('Não');
    }

    if (excursionOrganizatorFormContext.form.guide.coastal_tour === true) {
      setSelectedSouthTour('Sim');
    } else if (excursionOrganizatorFormContext.form.guide.coastal_tour === false) {
      setSelectedSouthTour('Não');
    }

    if (excursionOrganizatorFormContext.form.guide.type === '1') {
      setSelectedRadio('1');
    } else if (excursionOrganizatorFormContext.form.guide.type === '2') {
      setSelectedRadio('2');
    } else if (excursionOrganizatorFormContext.form.guide.type === '3') {
      setSelectedRadio('3');
    }
  }, []);

  useEffect(() => {
    if (selectedRadio !== '2') {
      (values as any).guide.information_check ? setDisabledInput(false) : setDisabledInput(true);
      excursionOrganizatorFormContext.form.guide.information_check ? setDisabledInput(false) : setDisabledInput(true);
    } else {
      validationSchemaGuideForm.isValidSync(values) ? setDisabledInput(false) : setDisabledInput(true);
    }
  }, [selectedRadio, values]);

  const validationSchemaGuideForm = yup.object().shape({
    type: yup.string(),
    guide: yup.object().shape({
      city_tour: yup.string().required('Campo obrigatório'),
      coastal_tour: yup.string().required('Campo obrigatório'),
      guide_name:
        selectedRadio === '2'
          ? yup
              .string()
              .trim()
              .required('Campo obrigatório')
              .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, 'O nome deve conter apenas letras e espaços')
              .test('contains-full-name', 'Informe um nome e sobrenome', value => {
                if (!value) return false;
                return value.trim().split(/\s+/).length > 1;
              })
              .min(2, 'Nome deve possuir no mínimo 2 caracteres com sobrenome')
              .max(150, 'Nome muito longo, máximo 150 caracteres')
          : yup.string().notRequired(),
      cadastur:
        selectedRadio === '2'
          ? yup
              .string()
              .required('Número do Cadastur é obrigatório')
              .matches(/^\d{8,14}$/, 'Cadastur deve conter entre 8 e 14 dígitos numéricos')
          : yup.string().notRequired(),
      phone:
        selectedRadio === '2'
          ? yup
              .string()
              .matches(/^\(\d{2}\)\s\d{1}\s\d{4}-\d{4}$/, 'Telefone deve ter o formato (XX) X XXXX-XXXX')
              .test('is-valid-ddd', 'DDD inválido', function (value) {
                if (!value) return false;
                const ddd = value.match(/^\((\d{2})\)/);
                if (!ddd) return false;
                return validDDDs.includes(ddd[1]);
              })
              .test('not-all-same', 'Telefone não pode ter todos os números iguais', function (value) {
                if (!value) return false;
                const digits = value.replace(/\D/g, '');
                return !/^(\d)\1+$/.test(digits);
              })
              .required('Telefone é obrigatório')
          : yup.string().notRequired(),
      email:
        selectedRadio === '2'
          ? yup
              .string()
              .trim()
              .lowercase()
              .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                'E-mail inválido. Use um formato válido, ex: usuario@email.com'
              )
              .required('Campo Obrigatório')
          : yup.string().notRequired(),
      information_check: yup.boolean().oneOf([true], 'Campo obrigatório'),
    }),
  });

  const CityTourSelect: React.FC<CityTourProps> = ({ field, form }) => {
    const { name } = field;
    return (
      <SelectComponent
        label=""
        options={cityTour.map(boolean => ({ label: boolean.label, value: boolean.value }))}
        onChange={value => {
          if (typeof value === 'string') {
            form.setFieldValue(name, value as CityTourEnum);
            setSelectedCityTour(value);
            clearGuideForm();
          }
        }}
        name="guide.city_tour"
        selected={[selectedCityTour]}
      />
    );
  };

  const SouthTourSelect: React.FC<SouthTourProps> = ({ field, form }) => {
    const { name } = field;
    return (
      <SelectComponent
        label=""
        options={southTour.map(boolean => ({ label: boolean.label, value: boolean.value }))}
        onChange={value => {
          if (typeof value === 'string') {
            form.setFieldValue(name, value as SouthTourEnum);
            setSelectedSouthTour(value);
            clearGuideForm();
          }
        }}
        name="guide.coastal_tour"
        selected={[selectedSouthTour]}
      />
    );
  };

  const handleSelectChange = () => {
    if (selectedCityTour === 'Sim' && selectedSouthTour === 'Sim') {
      return (
        <ExcursionFirstForm>
          <h3>Presença do Guia Regional Bahia Sul</h3>
          <ul>
            <li>
              <input
                type="radio"
                name="guia"
                id="city_tour_and_south_tour_1"
                value="1"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  (values as any).guide.information_check = false;
                  (values as any).guide.guide_name = '';
                  (values as any).guide.cadastur = '';
                  (values as any).guide.phone = '';
                  (values as any).guide.email = '';
                  setSelectedRadio(e.target.value);
                  setInformationCheck(false);
                  setDisabledInput(true);
                  excursionOrganizatorFormContext.form.guide.information_check = false;
                }}
                checked={selectedRadio === '1'}
              />
              <label htmlFor="city_tour_and_south_tour_1">Desejo solicitar guia regional Bahia Sul</label>
            </li>
            <li>
              <input
                type="radio"
                name="guia"
                id="city_tour_and_south_tour_2"
                value="2"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  (values as any).guide.information_check = false;
                  (values as any).guide.guide_name = '';
                  (values as any).guide.cadastur = '';
                  (values as any).guide.phone = '';
                  (values as any).guide.email = '';
                  setSelectedRadio(e.target.value);
                  disabledInput ? setDisabledInput(false) : setDisabledInput(true);
                  excursionOrganizatorFormContext.form.guide.information_check = false;
                  setInformationCheck(false);
                }}
                checked={selectedRadio === '2'}
              />
              <label htmlFor="city_tour_and_south_tour_2">Já possuo guia regional Bahia Sul</label>
            </li>
          </ul>
        </ExcursionFirstForm>
      );
    } else if (selectedCityTour === 'Sim' && selectedSouthTour === 'Não') {
      return (
        <ExcursionFirstForm>
          <h3>Presença do Guia Regional Bahia Sul</h3>
          <ul>
            <li>
              <input
                type="radio"
                name="guia"
                id="city_tour_and_no_south_tour_1"
                value="1"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSelectedRadio(e.target.value);
                  disabledInput ? setDisabledInput(false) : setDisabledInput(true);
                  (values as any).guide.information_check = false;
                  (values as any).guide.guide_name = '';
                  (values as any).guide.cadastur = '';
                  (values as any).guide.phone = '';
                  (values as any).guide.email = '';
                  setInformationCheck(false);
                  excursionOrganizatorFormContext.form.guide.information_check = false;
                }}
                checked={selectedRadio === '1'}
              />
              <label htmlFor="city_tour_and_no_south_tour_1">Desejo solicitar guia regional Bahia Sul</label>
            </li>
            <li>
              <input
                type="radio"
                name="guia"
                id="city_tour_and_no_south_tour_2"
                value="2"
                onChange={e => {
                  setSelectedRadio(e.target.value);
                  disabledInput ? setDisabledInput(false) : setDisabledInput(true);
                  (values as any).guide.information_check = false;
                  (values as any).guide.guide_name = '';
                  (values as any).guide.cadastur = '';
                  (values as any).guide.phone = '';
                  (values as any).guide.email = '';
                  setInformationCheck(false);
                  excursionOrganizatorFormContext.form.guide.information_check = false;
                }}
                checked={selectedRadio === '2'}
              />
              <label htmlFor="city_tour_and_no_south_tour_2">Já possuo guia regional Bahia Sul</label>
            </li>
          </ul>
        </ExcursionFirstForm>
      );
    } else if (selectedCityTour === 'Não' && selectedSouthTour === 'Sim') {
      return (
        <ExcursionFirstForm>
          <h3>Presença do Guia Regional Bahia Sul</h3>
          <ul>
            <li>
              <input
                type="radio"
                name="guia"
                id="no_city_tour_and_south_tour_1"
                value="1"
                onChange={e => {
                  setSelectedRadio(e.target.value);
                  disabledInput ? setDisabledInput(false) : setDisabledInput(true);
                  (values as any).guide.information_check = false;
                  (values as any).guide.guide_name = '';
                  (values as any).guide.cadastur = '';
                  (values as any).guide.phone = '';
                  (values as any).guide.email = '';
                  setInformationCheck(false);
                  excursionOrganizatorFormContext.form.guide.information_check = false;
                }}
                checked={selectedRadio === '1'}
              />
              <label htmlFor="no_city_tour_and_south_tour_1">Desejo solicitar guia regional Bahia Sul</label>
            </li>
            <li>
              <input
                type="radio"
                name="guia"
                id="no_city_tour_and_south_tour_2"
                value="2"
                onChange={e => {
                  setSelectedRadio(e.target.value);
                  disabledInput ? setDisabledInput(false) : setDisabledInput(true);
                  (values as any).guide.information_check = false;
                  (values as any).guide.guide_name = '';
                  (values as any).guide.cadastur = '';
                  (values as any).guide.phone = '';
                  (values as any).guide.email = '';
                  setInformationCheck(false);
                  excursionOrganizatorFormContext.form.guide.information_check = false;
                }}
                checked={selectedRadio === '2'}
              />
              <label htmlFor="no_city_tour_and_south_tour_2">Já possuo guia regional Bahia Sul</label>
            </li>
          </ul>
        </ExcursionFirstForm>
      );
    } else if (selectedCityTour === 'Não' && selectedSouthTour === 'Não') {
      return (
        <ExcursionFirstForm>
          <h3>Presença do Guia Regional Bahia Sul</h3>
          <ul>
            <li>
              <input
                type="radio"
                name="guia"
                id="no_city_tour_and_no_south_tour_1"
                value="1"
                onChange={e => {
                  setSelectedRadio(e.target.value);
                  disabledInput ? setDisabledInput(false) : setDisabledInput(true);
                  (values as any).guide.information_check = false;
                  (values as any).guide.guide_name = '';
                  (values as any).guide.cadastur = '';
                  (values as any).guide.phone = '';
                  (values as any).guide.email = '';
                  setInformationCheck(false);
                  excursionOrganizatorFormContext.form.guide.information_check = false;
                }}
                checked={selectedRadio === '1'}
              />
              <label htmlFor="no_city_tour_and_no_south_tour_1">Desejo solicitar guia regional Bahia Sul</label>
            </li>
            <li>
              <input
                type="radio"
                name="guia"
                id="no_city_tour_and_no_south_tour_2"
                value="2"
                onChange={e => {
                  setSelectedRadio(e.target.value);
                  disabledInput ? setDisabledInput(false) : setDisabledInput(true);
                  (values as any).guide.information_check = false;
                  (values as any).guide.guide_name = '';
                  (values as any).guide.cadastur = '';
                  (values as any).guide.phone = '';
                  (values as any).guide.email = '';
                  setInformationCheck(false);
                  excursionOrganizatorFormContext.form.guide.information_check = false;
                }}
                checked={selectedRadio === '2'}
              />
              <label htmlFor="no_city_tour_and_no_south_tour_2">Já possuo guia regional Bahia Sul</label>
            </li>
            <li>
              <input
                type="radio"
                name="guia"
                id="no_city_tour_and_no_south_tour_3"
                value="3"
                onChange={e => {
                  setSelectedRadio(e.target.value);
                  disabledInput ? setDisabledInput(false) : setDisabledInput(true);
                  (values as any).guide.information_check = false;
                  (values as any).guide.guide_name = '';
                  (values as any).guide.cadastur = '';
                  (values as any).guide.phone = '';
                  (values as any).guide.email = '';
                  setInformationCheck(false);
                  excursionOrganizatorFormContext.form.guide.information_check = false;
                }}
                checked={selectedRadio === '3'}
              />
              <label htmlFor="no_city_tour_and_no_south_tour_3">Não será necessário guia regional Bahia Sul</label>
            </li>
          </ul>
        </ExcursionFirstForm>
      );
    } else {
      return <></>;
    }
  };

  const handleRadioChange = () => {
    if (selectedRadio === '1') {
      return (
        <>
          <section>
            <p style={{ color: '#272727' }}>
              Para solicitar um guia regional Bahia Sul entre em contato com <strong>(73) 98866-7507</strong>. O
              pagamento do guia deve ser realizado <strong>diretamente</strong> com o mesmo e{' '}
              <strong>não está incluso</strong> no valor apresentado ao final desse cadastro.
            </p>
            <article>
              <div>
                <Field
                  validateOnBlur
                  type="checkbox"
                  id="checkbox-id"
                  name="guide.information_check"
                  onChange={() => {
                    (values as any).guide.information_check = !informationCheck;
                    (values as any).guide.information_check ? setDisabledInput(!disabledInput) : setDisabledInput(true);
                    setInformationCheck(!informationCheck);
                  }}
                />
              </div>

              <span onClick={() => document.getElementById('checkbox-id')?.click()}>
                Estou ciente das lei municipal 1.950/23 de obrigatoriedade de presença de guia regional Bahia Sul e de
                que o não cumprimento delas pode gerar multa.
              </span>
            </article>
            <ErrorMessage name="guide.information_check" />
          </section>
        </>
      );
    } else if (selectedRadio === '2') {
      return (
        <>
          <FormRow4Cols>
            <InputField className="responsive-grid-column-span-2">
              <div>
                <label htmlFor="">
                  Nome Completo<span>*</span>
                </label>
                <Field validateOnBlur name="guide.guide_name" />
              </div>
              <ErrorMessage name="guide.guide_name" />
            </InputField>
            <InputField id="full-name-input">
              <div>
                <label htmlFor="">
                  Cadastur <span>*</span>
                </label>
                <Field
                  name="guide.cadastur"
                  maxLength={14}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.replace(/\D/g, '');
                  }}
                />
              </div>
              <ErrorMessage name="guide.cadastur" />
            </InputField>
            <InputField id="cellphone-input" className="responsive-grid-column-span-2">
              <div>
                <label htmlFor="">
                  Telefone ou Celular<span>*</span>
                </label>
                <FieldMask type="phone">
                  <Field name="guide.phone" placeholder="ex.: (xx) x xxxx-xxxx" />
                </FieldMask>
              </div>
              <ErrorMessage name="guide.phone" />
            </InputField>
            <InputField id="email-input" className="responsive-grid-column-span-2">
              <div>
                <label htmlFor="">
                  E-mail<span>*</span>
                </label>
                <FieldMask type="email">
                  <Field type="text" name="guide.email" />
                </FieldMask>
              </div>
              <ErrorMessage name="guide.email" />
            </InputField>
          </FormRow4Cols>
          <article>
            <div>
              <Field
                validateOnBlur
                type="checkbox"
                id="checkbox-id"
                name="guide.information_check"
                onChange={() => {
                  setInformationCheck(!informationCheck);
                  (values as any).guide.information_check = !informationCheck;
                  (values as any).guide.information_check &&
                  (values as any).guide.guide_name.length > 0 &&
                  (values as any).guide.cadastur.length > 0 &&
                  (values as any).guide.phone.length > 0 &&
                  (values as any).guide.email.length > 0
                    ? setDisabledInput(false)
                    : setDisabledInput(true);
                }}
              />
            </div>
            <span onClick={() => document.getElementById('checkbox-id')?.click()}>
              Estou ciente das lei municipal 1.950/23 de obrigatoriedade de presença de guia regional Bahia Sul e de que
              o não cumprimento delas pode gerar multa.
            </span>
          </article>
          <ErrorMessage name="guide.information_check" />
        </>
      );
    } else if (selectedRadio === '3') {
      return (
        <section>
          <article>
            <div>
              <Field
                validateOnBlur
                type="checkbox"
                id="checkbox-id"
                name="guide.information_check"
                onChange={() => {
                  (values as any).guide.information_check = !informationCheck;
                  (values as any).guide.information_check ? setDisabledInput(!disabledInput) : setDisabledInput(true);
                  setInformationCheck(!informationCheck);
                }}
              />
            </div>
            <span onClick={() => document.getElementById('checkbox-id')?.click()}>
              Estou ciente das lei municipal 1.950/23 de obrigatoriedade de presença de guia regional Bahia Sul e de que
              o não cumprimento delas pode gerar multa.
            </span>
          </article>
          <ErrorMessage name="guide.information_check" />
        </section>
      );
    }
  };

  const handleCancel = () => {
    excursionOrganizatorFormContext.setForm({
      ...excursionOrganizatorFormContext.form,
      organizer: {
        ...excursionOrganizatorFormContext.form.organizer,
        document_type: '',
        name: '',
        document_number: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
      },
      origin_city: '',
      origin_state: '',
      start_date: '',
      hour_start: '',
      end_date: '',
      hour_end: '',
      people_number: 0,
      destination: [],
      central_access: false,
      travel_reason: '',
      declaration_file: '',
      transport: {
        ...excursionOrganizatorFormContext.form.transport,
        trade_name: '',
        legal_name: '',
        document_number: '',
        cadastur: '',
        phone: '',
        email: '',
        vehicle_type: '',
        vehicle_plate: '',
        has_pcd: false,
      },
      accommodation: {
        ...excursionOrganizatorFormContext.form.accommodation,
        accommodation_type: 0,
        cadastur: '',
        license_number: '',
        document_number: '',
        name: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        justification: '',
        iss: '',
      },
      guide: {
        ...excursionOrganizatorFormContext.form.guide,
        city_tour: false,
        coastal_tour: false,
        type: '',
        guide_name: '',
        cadastur: '',
        phone: '',
        email: '',
        information_check: false,
      },
      total_price: 0,
    });
    setShowModal(false);
    history.push('/admin/excursions');
  };

  const onSubmit = async () => {
    try {
      const validationFormInputs = await validationSchemaGuideForm.validate(values, { abortEarly: false });
      if (validationFormInputs) {
        excursionOrganizatorFormContext.setForm({
          ...excursionOrganizatorFormContext.form,
          guide: {
            type: selectedRadio,
            city_tour: selectedCityTour === 'Sim' ? true : false,
            coastal_tour: selectedSouthTour === 'Sim' ? true : false,
            guide_name: validationFormInputs.guide.guide_name ?? '',
            cadastur: validationFormInputs.guide.cadastur ?? '',
            phone: validationFormInputs.guide.phone ?? '',
            email: validationFormInputs.guide.email ?? '',
            information_check: validationFormInputs.guide.information_check ?? false,
          },
        });

        history.push('/admin/new-excursion/step6');
      }
    } catch (error: any) {
      setErrors(
        error.inner.reduce((errors: any, innerError: any) => ({ ...errors, [innerError.path]: innerError.message }), {})
      );
      console.log(
        'setErrors',
        error.inner.reduce((errors: any, innerError: any) => ({ ...errors, [innerError.path]: innerError.message }), {})
      );
      console.error(error);
    }
  };

  console.log('information_check >>>', informationCheck);
  console.log('disabledInput >>>', disabledInput);
  console.log('values.guide.information_check >>>', (values as any).guide.information_check);

  return (
    <>
      <Form
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onSubmit();
          }
        }}>
        <FormRow4Cols>
          <InputField id="document-type-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                Será realizado City Tour? <span>*</span>
              </label>
              <Field component={CityTourSelect} name="guide.city_tour" />
            </div>
            <ErrorMessage name="guide.city_tour" />
          </InputField>
          <InputField id="document-type-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                Será realizado passeio pelo litoral sul? <span>*</span>
              </label>
              <Field component={SouthTourSelect} name="guide.coastal_tour" />
            </div>
            <ErrorMessage name="guide.coastal_tour" />
          </InputField>
        </FormRow4Cols>
        {handleSelectChange()}
        {handleRadioChange()}
        <ButtonsDiv>
          <Button buttonType="secondary" type="button" onClick={() => history.push('/admin/new-excursion/step4')}>
            Voltar
          </Button>
          <article>
            <Button
              type="button"
              buttonType="secondary"
              onClick={e => {
                e.preventDefault();
                setShowModal(true);
              }}>
              Cancelar
            </Button>
            <Button
              type="button"
              buttonType={disabledInput ? 'disabled' : 'primary'}
              onClick={onSubmit}
              disabled={disabledInput}>
              Próximo
            </Button>
          </article>
        </ButtonsDiv>
      </Form>
      {showModal && (
        <ConfirmationModal
          open={showModal}
          onCancel={() => {
            setShowModal(false);
          }}
          onConfirm={handleCancel}
          title="Cancelar Cadastro de Excursão"
          message={
            <p>
              Se prosseguir com a ação, <strong>não será possível recuperar os dados</strong> já perdidos. Deseja
              continuar?
            </p>
          }
          confirmText="Confirmar"
          cancelText="Voltar"
        />
      )}
    </>
  );
};

const NewExcursionStep5Form = (): JSX.Element => {
  const history = useHistory();
  const excursionOrganizatorFormContext = useExcursionOrganizatorForm();

  LockNavigatorHook(history);

  const initialValues = {
    guide: {
      type: excursionOrganizatorFormContext.form.guide.type ?? null,
      city_tour: excursionOrganizatorFormContext.form.guide.city_tour ?? null,
      coastal_tour: excursionOrganizatorFormContext.form.guide.coastal_tour ?? null,
      guide_name: excursionOrganizatorFormContext.form.guide.guide_name ?? '',
      cadastur: excursionOrganizatorFormContext.form.guide.cadastur ?? '',
      phone: excursionOrganizatorFormContext.form.guide.phone ?? '',
      email: excursionOrganizatorFormContext.form.guide.email ?? '',
      information_check: excursionOrganizatorFormContext.form.guide.information_check ?? false,
    },
  };

  const validationSchema = yup.object().shape({
    guide: yup.object().shape({
      city_tour: yup.string().required('Campo obrigatório'),
      coastal_tour: yup.string().required('Campo obrigatório'),
      guide_name: yup
        .string()
        .trim()
        .required('Campo obrigatório')
        .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, 'O nome deve conter apenas letras e espaços')
        .test('contains-full-name', 'Informe um nome e sobrenome', value => {
          if (!value) return false;
          return value.trim().split(/\s+/).length > 1;
        })
        .min(2, 'Nome deve possuir no mínimo 2 caracteres com sobrenome')
        .max(150, 'Nome muito longo, máximo 150 caracteres'),
      cadastur: yup
        .string()
        .required('Número do Cadastur é obrigatório')
        .matches(/^\d{8,14}$/, 'Cadastur deve conter entre 8 e 14 dígitos numéricos'),
      phone: yup
        .string()
        .matches(/^\(\d{2}\)\s\d{1}\s\d{4}-\d{4}$/, 'Telefone deve ter o formato (XX) X XXXX-XXXX')
        .test('is-valid-ddd', 'DDD inválido', function (value) {
          if (!value) return false;
          const ddd = value.match(/^\((\d{2})\)/);
          if (!ddd) return false;
          return validDDDs.includes(ddd[1]);
        })
        .test('not-all-same', 'Telefone não pode ter todos os números iguais', function (value) {
          if (!value) return false;
          const digits = value.replace(/\D/g, '');
          return !/^(\d)\1+$/.test(digits);
        })
        .required('Telefone é obrigatório'),
      email: yup
        .string()
        .trim()
        .lowercase()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'E-mail inválido. Use um formato válido, ex: usuario@email.com'
        )
        .required('Campo Obrigatório'),
      information_check: yup.boolean().oneOf([true], 'Campo obrigatório'),
    }),
  });

  console.log('initialValues', initialValues);

  console.log('excursionOrganizatorFormContext.form.guide', excursionOrganizatorFormContext.form.guide);

  return (
    <>
      <Container>
        <MainTitle>Cadastro de Excursão</MainTitle>

        <StepperContainer>
          <StepperItem className="flex flex-col items-center justify-center">
            <article>
              <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step1')}>
                <IconOrganizador color="#ffffff" />
              </StepperBullet>
              <StepperLine className="onEdit" />
            </article>
            <StepperName className="onEdit">Organizador</StepperName>
          </StepperItem>

          <StepperItem>
            <article>
              <StepperLine className="onEdit" />
              <Link to="#">
                <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step2')}>
                  <IconViagem color="#ffffff" />
                </StepperBullet>
              </Link>
              <StepperLine className="onEdit" />
            </article>
            <StepperName className="onEdit">Viagem</StepperName>
          </StepperItem>

          <StepperItem>
            <article>
              <StepperLine className="onEdit" />
              <Link to="#">
                <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step3')}>
                  <IconTransporte />
                </StepperBullet>
              </Link>
              <StepperLine className="onEdit" />
            </article>
            <StepperName className="onEdit">Transporte</StepperName>
          </StepperItem>

          <StepperItem>
            <article>
              <StepperLine className="onEdit" />
              <Link to="#">
                <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step4')}>
                  <IconHospedagem />
                </StepperBullet>
              </Link>
              <StepperLine className="onEdit" />
            </article>
            <StepperName className="onEdit">Hospedagem</StepperName>
          </StepperItem>

          <StepperItem>
            <article>
              <StepperLine className="onEdit" />
              <Link to="#">
                <StepperBullet className="onEdit" onClick={e => e.preventDefault()}>
                  <IconGuia color="#020c90" />
                </StepperBullet>
              </Link>
              <StepperLine className="onEdit" />
            </article>
            <StepperName className="onEdit">Guia</StepperName>
          </StepperItem>

          <StepperItem>
            <article>
              <StepperLine />
              <Link to="#">
                <StepperBullet onClick={e => e.preventDefault()}>
                  <IconPagamento />
                </StepperBullet>
              </Link>
              <StepperLine />
            </article>
            <StepperName>Pagamento</StepperName>
          </StepperItem>

          <StepperItem>
            <article>
              <StepperLine />
              <Link to="#">
                <StepperBullet onClick={e => e.preventDefault()}>
                  <IconResumo />
                </StepperBullet>
              </Link>
            </article>
            <StepperName>Resumo</StepperName>
          </StepperItem>
        </StepperContainer>

        <ExcursionFirstFormContainer>
          <p>*Campo Obrigatório</p>
          <ExcursionCompanyInformation>
            <h2>Dados do guia de turismo:</h2>
            <p>
              <strong>Atenção!</strong> É obrigatória a presença de guia de turismo regional Bahia Sul em excursão com
              City Tour e/ou passeio pelo Litoral Sul{' '}
              <a
                href="https://storage.googleapis.com/bkt-porto-seguro-portal-images-dev/Porto%20Seguro%20-%20Regras%20Basicas%20de%20Circulação.pdf"
                target="_blank"
                rel="noreferrer">
                <strong>(lei municipal 1.950/23)</strong>
              </a>
              .
            </p>
          </ExcursionCompanyInformation>

          <ExcursionFirstForm>
            <Formik
              validateOnBlur
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={() => {
                null;
              }}>
              {({ validateForm }) => {
                useEffect(() => {
                  validateForm();
                }, [validateForm]);
                return <GuideForm />;
              }}
            </Formik>
          </ExcursionFirstForm>
        </ExcursionFirstFormContainer>
      </Container>
    </>
  );
};

export default NewExcursionStep5Form;
