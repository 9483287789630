export interface IExcursion {
  id: number;
  user_id: number;
  origin_state: string;
  origin_city: string;
  start_date: string;
  end_date: string;
  hour_start: string;
  hour_end: string;
  people_number: number;
  destination: string;
  total_price: number;
  travel_reason: string;
  declaration_file: string;
  declaration_file_signed: string;
  status: string;
  central_access: boolean;
  organizer: {
    name: string;
    document_number: string;
    document_type: string;
    phone: string;
    email: string;
    cep: string;
    address: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
  };
  transport: {
    document_number: string;
    trade_name: string;
    legal_name: string;
    cadastur: string;
    has_pcd: boolean;
    phone: string;
    email: string;
    vehicle_type: string;
    vehicle_plate: string;
  };
  accommodation: {
    justification: string;
    cadastur: string;
    license_number: string;
    iss: string;
    document_number: string;
    document_type: string;
    name: string;
    phone: string;
    email: string;
    cep: string;
    address: string;
    number: string;
    complement: string;
    neighborhood: string;
    state: string;
    city: string;
    accommodation_type: number;
  };
  guide: {
    type: string;
    city_tour: boolean;
    coastal_tour: boolean;
    guide_name: string;
    cadastur: string;
    phone: string;
    email: string;
  };
}

export enum ExcursionStatusAPI {
  APPROVED = 'Isenção Aprovada',
  PAYMENT_APPROVED = 'Pagamento Aprovado',
  PAYMENT_DENIED = 'Pagamento Recusado',
  REFUSED = 'Isenção Recusada',
  CANCELED = 'Cancelada',
  WAITING = 'Aguardando Aprovação',
  PROCESSING = 'Em Processamento',
  ANNALYSIS = 'Isenção em Análise',
  NOT_DEFINED = 'Aguardando Aprovação',
}
