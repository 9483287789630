import React, { useState, useMemo, useEffect } from 'react';
import { Container, MainContent, MainContentFormsBox, MainContentSelectBox, MainContentTitle } from './styles';
import MainTitle from 'components/MainTitle';
import MyBusinessAccomodationsForm from './components/MyBusinessAccomodationsForm';
import SelectComponent from 'components/Select';
import { businessTypes } from './components/utils';
import MyBusinessRestaurantsForm from './components/MyBusinessRestaurantsForm';
import MyBusinessTransportsForm from './components/MyBusinessTransportsForm';
import MyBusinessToursForm from './components/MyBusinessToursForm';
import { useParams } from 'react-router-dom';
import { useLoading } from 'hooks/useLoading';
import TouristOffersBusinessService from 'services/TouristOffersBusinessService';

const TradeMyBusinessNewPage = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [selectedForm, setSelectedForm] = useState<string>('');
  const [selectedBusiness, setSelectedBusiness] = useState<any | null>();
  const { id } = useParams<{ id?: string }>();
  const accomodationCategories: { label: string; value: string }[] = [];
  const restaurantCategories: { label: string; value: string }[] = [];
  const tourCategories: { label: string; value: string }[] = [];
  const transportsCategories: { label: string; value: string }[] = [];

  useEffect(() => {
    openLoading();
    const fetchBusinessData = [fetchTouristOffersBusiness()];
    Promise.all(fetchBusinessData).finally(() => closeLoading());
  }, []);

  const service = {
    businessService: new TouristOffersBusinessService(),
  };

  const fetchTouristOffersBusiness = async () => {
    if (id?.length !== undefined && id?.length > 0) {
      const data = await service.businessService.findOne(Number(id));
      const images = data.images.filter(image => image.description !== 'Estruturas Anexas').map(image => image.image);
      const structures = data.images
        .filter(image => image.description === 'Estruturas Anexas')
        .map(image => image.image);
      const newBusiness = { ...data, images, image: [data.image], structures };
      setSelectedBusiness(newBusiness);
    }
  };

  const handleForms = (value: string | string[]) => {
    if (typeof value === 'string') {
      setSelectedForm(value);
    }
  };

  const formEditComponent = useMemo(() => {
    switch (selectedBusiness?.type) {
      case 'Hospedagem':
        return (
          <MainContentFormsBox>
            <MyBusinessAccomodationsForm
              selectedId={id}
              item={selectedBusiness}
              type="Hospedagem"
              categories={accomodationCategories}
            />
          </MainContentFormsBox>
        );
      case 'Passeio e Lazer':
        return (
          <MainContentFormsBox>
            <MyBusinessToursForm
              selectedId={id}
              item={selectedBusiness}
              type="Passeio e Lazer"
              categories={tourCategories}
            />
          </MainContentFormsBox>
        );
      case 'Restaurante (Alimento e Bebida)':
        return (
          <MainContentFormsBox>
            <MyBusinessRestaurantsForm
              selectedId={id}
              item={selectedBusiness}
              type="Restaurante (Alimento e Bebida)"
              categories={restaurantCategories}
            />
          </MainContentFormsBox>
        );
      case 'Transporte':
        return (
          <MainContentFormsBox>
            <MyBusinessTransportsForm
              selectedId={id}
              item={selectedBusiness}
              type="Transporte"
              categories={transportsCategories}
            />
          </MainContentFormsBox>
        );
      default:
        return (
          <MainContentFormsBox>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '10px 0',
                width: '100%',
                height: '50px',
                color: '#aaa',
                background: '#f1f1f1',
                borderRadius: '18px',
              }}>
              Nenhum formulário selecionado
            </div>
          </MainContentFormsBox>
        );
    }
  }, [selectedBusiness]);

  const formComponent = useMemo(() => {
    switch (selectedForm) {
      case 'Hospedagem':
        return (
          <MainContentFormsBox>
            <MyBusinessAccomodationsForm selectedId={id} type="Hospedagem" />
          </MainContentFormsBox>
        );
      case 'Passeio e Lazer':
        return (
          <MainContentFormsBox>
            <MyBusinessToursForm selectedId={id} type="Passeio e Lazer" />
          </MainContentFormsBox>
        );
      case 'Restaurante (Alimento e Bebida)':
        return (
          <MainContentFormsBox>
            <MyBusinessRestaurantsForm selectedId={id} type="Restaurante (Alimento e Bebida)" />
          </MainContentFormsBox>
        );
      case 'Transporte':
        return (
          <MainContentFormsBox>
            <MyBusinessTransportsForm selectedId={id} type="Transporte" />
          </MainContentFormsBox>
        );
      default:
        return (
          <MainContentFormsBox>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '10px 0',
                width: '100%',
                height: '50px',
                color: '#aaa',
                background: '#f1f1f1',
                borderRadius: '18px',
              }}>
              Nenhum formulário selecionado
            </div>
          </MainContentFormsBox>
        );
    }
  }, [selectedForm]);

  const editTitleForm = useMemo(() => {
    if (id) {
      return selectedForm === 'Passeio e Lazer' ? 'Editar Atividade' : 'Editar Negócio';
    }
    return selectedForm === 'Passeio e Lazer' ? 'Nova Atividade' : 'Novo Negócio';
  }, [id]);

  return (
    <Container>
      <MainTitle>Estabelecimentos e Atividades</MainTitle>
      <MainContent>
        <MainContentTitle>{editTitleForm}</MainContentTitle>
        <MainContentSelectBox className="custom-select">
          <div>
            {id ? (
              <>
                <label htmlFor="business-types">Seguimento</label>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
                  <select style={{ width: '100%' }} disabled>
                    <option>{selectedBusiness?.type}</option>
                  </select>
                </div>
              </>
            ) : (
              <SelectComponent
                name="business-types"
                options={businessTypes}
                label="Seguimento"
                onChange={(value: string | string[]) => handleForms(value)}
                required
              />
            )}
          </div>
        </MainContentSelectBox>
        {id ? formEditComponent : formComponent}
      </MainContent>
    </Container>
  );
};

export default TradeMyBusinessNewPage;
