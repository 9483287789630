import { ExcursionOrganizatorForm, ExcursionOrganizatorFormContextProps } from 'interfaces/IExcursionOrganizerForm';
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { initialExcursionOrganizatorForm } from './initialExcursionOrganizatorFormState';

const ExcursionOrganizatorFormContext = createContext<ExcursionOrganizatorFormContextProps | undefined>(undefined);

export const ExcursionOrganizatorFormProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [form, setForm] = useState<ExcursionOrganizatorForm>(initialExcursionOrganizatorForm);

  return (
    <ExcursionOrganizatorFormContext.Provider value={{ form, setForm }}>
      {children}
    </ExcursionOrganizatorFormContext.Provider>
  );
};

export const useExcursionOrganizatorForm = (): ExcursionOrganizatorFormContextProps => {
  const context = useContext(ExcursionOrganizatorFormContext);
  if (!context) {
    throw new Error('useExcursionOrganizatorForm deve ser usado dentro de um ExcursionOrganizatorFormProvider');
  }
  return context;
};
