import React, { useRef, useState } from 'react';
import yup from 'utils/validation';
import { ButtonsDiv, FormRow, InputField, LabelCheck, SignUpForm } from './styles';
import Button from 'components/Buttom/Button';
import Input from 'components/Input/Input';
import ArrayField from 'components/ArrayField';
import ErrorMessage from 'components/ErrorMessage';
import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import InputPassword from 'common/InputPassword/InputPassword';
import FieldMask from 'components/FieldMask';
import { validDDDs } from 'utils/basicUtils';
import { validateCPF } from '../SignupTourOrganizer/utils';

const initialValues = {
  document: '',
  email: '',
  password: '',
  extraInfo: {
    businessRepresentative: '',
    businessOwner: [''],
    phone: '',
  },
};

interface Props {
  handleSubmit(type: string, data: any): Promise<void>;
}

const SignUpTrade = (props: Props): JSX.Element => {
  const { handleSubmit } = props;
  const [show, setShow] = useState(true);
  const form = useRef<FormikProps<FormikValues>>(null);

  const validationSchema = yup.object({
    document: yup
      .string()
      .trim()
      .matches(/^([\w\.\-\/]{18}|[\w\.\-\/]{14})$/, 'Informe um CPF completo')
      .test('is-valid-cpf', 'Informe um CPF válido', value => validateCPF(value || ''))
      .required(),
    email: yup
      .string()
      .trim()
      .matches(/^(?!\d+$).*/, 'O campo email não pode ser apenas números')
      .required(),
    password: yup.string().trim().required(),
    extraInfo: yup.object({
      businessOwner: yup.array().of(yup.string().trim().required()),
      businessRepresentative: yup.string().trim().required(),
      phone: yup
        .string()
        .trim()
        .required('Telefone é obrigatório')
        .test('is-valid-ddd', 'DDD inválido', function (value) {
          if (!value) return false;
          const ddd = value.match(/^\((\d{2})\)/);
          if (!ddd) return false;
          return validDDDs.includes(ddd[1]);
        })
        .test('not-all-same', 'Telefone não pode ter todos os números iguais', function (value) {
          if (!value) return false;
          const digits = value.replace(/\D/g, '');
          return !/^(\d)\1+$/.test(digits);
        }),
    }),
  });

  const submit = async (data: any) => {
    const firstOwner = data.extraInfo.businessOwner[0];
    data.name = firstOwner;
    await handleSubmit('trade', data);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
      <SignUpForm>
        <header>
          <h2>Preencha as informações abaixo:</h2>
          <span>1/1</span>
        </header>
        <Form>
          <FormRow className="full-name-row" id="proper-full-name-row">
            <InputField>
              <div>
                <label htmlFor="">
                  Nome Completo do Proprietário <span>*</span>
                </label>
                <ArrayField name="extraInfo.businessOwner" />
              </div>
            </InputField>
          </FormRow>
          <FormRow className="full-name-row">
            <InputField>
              <div>
                <label htmlFor="">
                  Nome Completo do Responsável Comercial <span>*</span>
                </label>
                <Field name="extraInfo.businessRepresentative" />
              </div>
              <ErrorMessage name="extraInfo.businessRepresentative" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField>
              <div>
                <label htmlFor="">
                  CPF do Primeiro Proprietário <span>*</span>
                </label>
                <FieldMask type="cpf">
                  <Field name="document" />
                </FieldMask>
              </div>
              <ErrorMessage name="document" />
            </InputField>
            <InputField>
              <div>
                <label htmlFor="">
                  Contato <span>*</span>
                </label>
                <FieldMask type="phone">
                  <Field name="extraInfo.phone" />
                </FieldMask>
              </div>
              <ErrorMessage name="extraInfo.phone" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField>
              <div>
                <label htmlFor="">
                  E-mail<span>*</span>
                </label>
                <Field name="email" />
              </div>
              <ErrorMessage name="email" />
            </InputField>
            <InputField>
              <div>
                <label htmlFor="">
                  Senha<span>*</span>
                </label>
                <InputPassword name={'password'} />
                <ErrorMessage name="password" />
              </div>
            </InputField>
          </FormRow>
          {/* <LabelCheck>
            <Input type="checkbox" />
            <label htmlFor="">
              Aceito os
              <a href="">
                <strong>Termos de Uso</strong>
              </a>
            </label>
          </LabelCheck>
          <LabelCheck>
            <Input type="checkbox" />
            <label htmlFor="">
              Aceito a
              <a href="">
                <strong>Política de Privacidade</strong>
              </a>
            </label>
          </LabelCheck>
          <LabelCheck>
            <Input type="checkbox" checked />
            <label htmlFor="">Desejo receber a newsletter em meu e-mail</label>
          </LabelCheck> */}
          <ButtonsDiv>
            <Button type="submit" text="CRIAR" />
          </ButtonsDiv>
        </Form>
      </SignUpForm>
    </Formik>
  );
};

export default SignUpTrade;
