import styled from 'styled-components';

export const ExcursionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const Carousel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  div {
    width: 100%;
    --slider-height-percentage: 20%;
    --slider-transition-duration: 1000ms;
    --organic-arrow-thickness: 4px;
    --organic-arrow-border-radius: 3px;
    --organic-arrow-height: 20px;
    --organic-arrow-color: #ffffff;
    --control-button-width: 6%;
    --control-button-height: 25%;
    --control-button-background: #ffcb29;
    --control-bullet-color: #ffffff;
    --control-bullet-active-color: #ffffff;
    --loader-bar-color: #c33737;
    --loader-bar-height: 30px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    @media (max-width: 500px) {
      --slider-height-percentage: 16%;
    }

    .awssld__prev,
    .awssld__next {
      border-radius: 50%;
      width: 40px;
      height: 40px;

      &:hover {
        background-color: #020c90;
      }
    }

    .awssld__prev {
      margin-left: 50px;
      margin-top: 20px;

      @media (max-width: 768px) {
        margin-top: 0;
        margin-left: 20px;
      }
    }

    .awssld__next {
      margin-right: 50px;
      margin-top: 20px;

      @media (max-width: 768px) {
        margin-top: 0;
        margin-right: 20px;
      }
    }

    .awssld__content {
      background-color: transparent;
      position: relative;
    }

    .awssld__bullets {
      position: absolute;
      margin-bottom: 5%;
      z-index: 2;

      @media (max-width: 768px) {
        bottom: -15px;
      }

      button {
        height: 13px;
        width: 13px;
        background: none;
        border-style: solid;
        border-color: white;
        border-width: 2.5px;

        transform: scale(1);
        &:hover {
          transform: scale(1.1);
        }

        @media (max-width: 768px) {
          height: 8px;
          width: 8px;
          border-width: 1px;
        }
      }

      .awssld__bullets--active {
        background-color: white;
      }
    }
    div {
      span {
        &:nth-child(2) {
          visibility: hidden;
        }
        position: absolute;
        z-index: 2;
        display: flex;
        align-items: center;
        &:nth-last-of-type(2) {
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          padding: 10px 50px;
          background-color: #ffcb29;
          height: 69px;
          top: 12%;
          left: 0;
          color: #ffff;
          width: fit-content;
          white-space: nowrap;
          border-radius: 0 18px 18px 0;

          @media (max-width: 768px) {
            font-size: 23px;
            padding: 2px 25px;
            margin-top: 20px;
            height: 48px;
            border-radius: 0px 6px 6px 0px;
          }
        }
        &:nth-child(2) {
          height: 60px;
          background-color: #ffff;
          top: 80%;
          right: 0;
          padding: 0 50px;
          border-radius: 6px 0px 0px 6px;
          font-size: 15pt;
          @media (max-width: 768px) {
            font-size: 16px;
            border-radius: 0px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            top: unset;
            bottom: 6px;
          }
          button {
            width: 190px;
            margin-left: 10px;
            @media (max-width: 768px) {
              width: 230px;
            }
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const Arrow = styled.span`
  font-size: 34px;
  font-weight: 100;
`;
