import React, { useEffect, useState } from 'react';

import {
  ButtonsDiv,
  Container,
  ExcursionCompanyInformation,
  ExcursionFirstForm,
  ExcursionFirstFormContainer,
  FormRow4Cols,
  InputField,
  RadioButtonsContainer,
  StepperBullet,
  StepperContainer,
  StepperItem,
  StepperLine,
  StepperName,
} from '../../styles';
import MainTitle from 'components/MainTitle';
import { Link, useHistory } from 'react-router-dom';
import Button from 'components/Button';
import { Formik, Field, Form, useFormikContext } from 'formik';
import FieldMask from 'components/FieldMask';
import SelectComponent from 'components/Select';
import {
  IconGuia,
  IconHospedagem,
  IconOrganizador,
  IconPagamento,
  IconResumo,
  IconTransporte,
  IconViagem,
} from 'features/NewExcursionForm/components/StepperIcons';
import { TransportTypeEnum, transportTypes, TransportTypesProps } from 'features/NewExcursionForm/utils';
import ConfirmationModal from 'components/ConfirmationModal';
import { useExcursionOrganizatorForm } from 'common/ExcursionOrganizatorFormContext';
import yup from 'utils/validation';
import { validateCNPJ } from 'features/Signup/pages/SignupTourOrganizer/utils';
import { validDDDs } from 'utils/basicUtils';
import ErrorMessage from 'components/ErrorMessage';
import LockNavigatorHook from 'features/NewExcursionForm/hooks/LockNavigatorHook';

interface Form3Props {
  disabledButton: boolean;
}

const Form3: React.FC<Form3Props> = ({ disabledButton }) => {
  const { values, setErrors } = useFormikContext<Record<string, any>>();
  const history = useHistory();
  const [selectedTransportType, setSelectedTransportType] = useState('');
  const [showModal, setShowModal] = useState(false);
  const excursionOrganizatorFormContext = useExcursionOrganizatorForm();

  const validationSchema = yup.object().shape({
    transport: yup.object().shape({
      document_number: yup
        .string()
        .matches(/^([\w\.\-\/]{18}|[\w\.\-\/]{14})$/, 'Informe um CNPJ completo')
        .test('is-valid-cnpj', 'Informe um CNPJ válido', value => validateCNPJ(value || ''))
        .required('Campo obrigatório'),
      cadastur: yup.string(),
      trade_name: yup
        .string()
        .required('Nome Fantasia é obrigatório')
        .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ0-9\s&.,'-]+$/, 'Nome Fantasia contém caracteres inválidos')
        .min(2, 'Nome Fantasia deve ter pelo menos 2 caracteres')
        .max(100, 'Nome Fantasia deve ter no máximo 100 caracteres'),
      legal_name: yup
        .string()
        .required('Razão Social é obrigatória')
        .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ0-9\s&.,'-]+$/, 'Razão Social contém caracteres inválidos')
        .min(2, 'Razão Social deve ter pelo menos 2 caracteres')
        .max(150, 'Razão Social deve ter no máximo 150 caracteres'),
      phone: yup
        .string()
        .matches(/^\(\d{2}\)\s\d{1}\s\d{4}-\d{4}$/, 'Telefone deve ter o formato (XX) X XXXX-XXXX')
        .test('is-valid-ddd', 'DDD inválido', function (value) {
          if (!value) return false;
          const ddd = value.match(/^\((\d{2})\)/);
          if (!ddd) return false;
          return validDDDs.includes(ddd[1]);
        })
        .test('not-all-same', 'O número do telefone não pode ter todos os dígitos iguais', function (value) {
          if (!value) return false;

          const digits = value.replace(/\D/g, ''); // Remove caracteres não numéricos
          const phoneNumber = digits.slice(2); // Remove os dois primeiros dígitos do DDD

          return !/^(\d)\1+$/.test(phoneNumber); // Garante que o telefone não tenha todos os números iguais
        })
        .required('Telefone é obrigatório'),
      email: yup
        .string()
        .trim()
        .lowercase()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'E-mail inválido. Use um formato válido, ex: usuario@email.com'
        )
        .required('Campo Obrigatório'),
      vehicle_type: yup.string().required('Campo obrigatório'),
      vehicle_plate: yup
        .string()
        .required('Placa é obrigatória')
        .test(
          'is-valid-plate-format',
          'Placa inválida! Use o formato ABC1234 (antigo) ou ABC1D23 (Mercosul)',
          value => {
            if (!value) return false;

            // Regex para placa antiga (ABC1234)
            const regexAntiga = /^[A-Z]{3}[0-9]{4}$/;

            // Regex atualizado para Mercosul (ABC1D23 ou RIO2A18)
            const regexMercosul = /^[A-Z]{3,4}[0-9][A-Z][0-9]{2}$/;

            return regexAntiga.test(value) || regexMercosul.test(value);
          }
        ),
      has_pcd: yup.string().required('Campo obrigatório'),
    }),
  });

  useEffect(() => {
    if (values.transport.vehicle_type) {
      setSelectedTransportType(values.transport.vehicle_type);
    }
  }, [selectedTransportType]);

  const TransportTypesSelect: React.FC<TransportTypesProps> = ({ field, form }) => {
    const { name } = field;
    return (
      <SelectComponent
        label=""
        options={transportTypes.map(transportType => ({ label: transportType.label, value: transportType.value }))}
        onChange={value => {
          if (typeof value === 'string') {
            form.setFieldValue(name, value as TransportTypeEnum);
            setSelectedTransportType(value as TransportTypeEnum);
          }
        }}
        name="transport.vehicle_type"
        selected={[selectedTransportType]}
      />
    );
  };

  const handleCancel = () => {
    excursionOrganizatorFormContext.setForm({
      ...excursionOrganizatorFormContext.form,
      organizer: {
        ...excursionOrganizatorFormContext.form.organizer,
        document_type: '',
        name: '',
        document_number: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
      },
      origin_city: '',
      origin_state: '',
      start_date: '',
      hour_start: '',
      end_date: '',
      hour_end: '',
      people_number: 0,
      destination: [],
      central_access: false,
      travel_reason: '',
      declaration_file: '',
      transport: {
        ...excursionOrganizatorFormContext.form.transport,
        trade_name: '',
        legal_name: '',
        document_number: '',
        cadastur: '',
        phone: '',
        email: '',
        vehicle_type: '',
        vehicle_plate: '',
        has_pcd: false,
      },
      accommodation: {
        ...excursionOrganizatorFormContext.form.accommodation,
        accommodation_type: 0,
        cadastur: '',
        license_number: '',
        document_number: '',
        name: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        justification: '',
        iss: '',
      },
      guide: {
        ...excursionOrganizatorFormContext.form.guide,
        city_tour: false,
        coastal_tour: false,
        type: '',
        guide_name: '',
        cadastur: '',
        phone: '',
        email: '',
      },
      total_price: 0,
    });
    setShowModal(false);
    history.push('/admin/excursions');
  };

  const onSubmit = async () => {
    console.log('values', values);
    try {
      const validationFormInputs = await validationSchema.validate(values, { abortEarly: false });

      if (validationFormInputs) {
        excursionOrganizatorFormContext.setForm({
          ...excursionOrganizatorFormContext.form,
          transport: {
            ...excursionOrganizatorFormContext.form.transport,
            document_number: validationFormInputs.transport.document_number,
            trade_name: validationFormInputs.transport.trade_name,
            legal_name: validationFormInputs.transport.legal_name,
            cadastur: validationFormInputs.transport.cadastur ?? '',
            phone: validationFormInputs.transport.phone,
            email: validationFormInputs.transport.email,
            vehicle_type: validationFormInputs.transport.vehicle_type,
            vehicle_plate: validationFormInputs.transport.vehicle_plate,
            has_pcd: validationFormInputs.transport.has_pcd === 'true' ? true : false,
          },
        });
        history.push('/admin/new-excursion/step4');
      }
    } catch (error: any) {
      setErrors(
        error.inner.reduce((errors: any, innerError: any) => ({ ...errors, [innerError.path]: innerError.message }), {})
      );
      console.log(
        'setErrors',
        error.inner.reduce((errors: any, innerError: any) => ({ ...errors, [innerError.path]: innerError.message }), {})
      );
      console.error(error);
    }
  };

  return (
    <>
      <Form
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onSubmit();
          }
        }}>
        <FormRow4Cols>
          <InputField id="cadastur-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                CNPJ <span>*</span>
              </label>
              <FieldMask type="cnpj">
                <Field type="text" name="transport.document_number" placeholder="ex: xx.xxx.xxx/0001-xx" />
              </FieldMask>
            </div>
            <ErrorMessage name="transport.document_number" />
          </InputField>
          <InputField id="company-name-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                Nome Fantasia<span>*</span>
              </label>
              <Field
                name="transport.trade_name"
                maxLength={100}
                onInput={(e: React.FormEvent<HTMLInputElement>) =>
                  (e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9\s&.,'-]/g, ''))
                }
              />
            </div>
            <ErrorMessage name="transport.trade_name" />
          </InputField>
          <InputField id="social-reason-input" style={{ gridColumn: 'span 2' }}>
            <div>
              <label htmlFor="">
                Razão Social<span>*</span>
              </label>
              <Field
                name="transport.legal_name"
                onInput={(e: React.FormEvent<HTMLInputElement>) =>
                  (e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9\s&.,'-]/g, ''))
                }
                maxLength={150}
              />
            </div>
            <ErrorMessage name="transport.legal_name" />
          </InputField>
        </FormRow4Cols>
        <FormRow4Cols>
          <InputField id="cadastur-input">
            <div>
              <label htmlFor="">Cadastur</label>
              <Field
                type="text"
                name="transport.cadastur"
                maxLength={14} // Limita ao tamanho máximo permitido
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value.replace(/\D/g, ''); // Remove qualquer caractere não numérico
                }}
              />
            </div>
            <ErrorMessage name="transport.cadastur" />
          </InputField>
          <InputField id="cellphone-input">
            <div>
              <label htmlFor="">
                Telefone ou Celular<span>*</span>
              </label>
              <FieldMask type="phone">
                <Field name="transport.phone" placeholder="ex.: (xx) x xxxx-xxxx" />
              </FieldMask>
            </div>
            <ErrorMessage name="transport.phone" />
          </InputField>
          <InputField id="email-input" className="responsive-grid-column-span-2">
            <div>
              <label htmlFor="">
                E-mail<span>*</span>
              </label>
              <FieldMask type="email">
                <Field type="text" name="transport.email" />
              </FieldMask>
            </div>
            <ErrorMessage name="transport.email" />
          </InputField>
        </FormRow4Cols>
        <ExcursionCompanyInformation style={{ alignItems: 'flex-start' }}>
          <h2>Dados do veículo:</h2>
          <p>
            <strong>Atenção!</strong> A taxa de pagamento pode variar de acordo com o tipo de veículo. Consulte os
            valores{' '}
            <a
              href="https://storage.googleapis.com/bkt-porto-seguro-portal-images-dev/docs/Porto_Seguro_Taxas_de_transporte_e_hospedagem_2025.pdf"
              target="_blank"
              rel="noreferrer">
              aqui
            </a>
            . Em caso de irregularidade com a documentação do veículo cadastrado, a entrada na cidade pode ser barrada
            durante a fiscalização.
          </p>
        </ExcursionCompanyInformation>
        <FormRow4Cols>
          <InputField id="state-input">
            <div>
              <label htmlFor="">
                Tipo<span>*</span>
              </label>
              <Field component={TransportTypesSelect} name="transport.vehicle_type" />
            </div>
            <ErrorMessage name="transport.vehicle_type" />
          </InputField>
          <InputField id="cadastur-input">
            <div>
              <label htmlFor="">
                Placa<span>*</span>
              </label>
              <Field
                type="text"
                name="transport.vehicle_plate"
                placeholder="Ex: ABC1234 ou ABC1D23"
                maxLength={7} // Placa Mercosul tem 7 caracteres
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value
                    .toUpperCase() // Sempre maiúsculas
                    .replace(/[^A-Z0-9]/g, '') // Remove caracteres inválidos
                    .slice(0, 7); // Limita a 7 caracteres
                }}
              />
            </div>
            <ErrorMessage name="transport.vehicle_plate" />
          </InputField>
          <InputField id="cadastur-input" style={{ gridColumn: 'span 2' }}>
            <div>
              <label htmlFor="transport-has-pcd">
                Irá conduzir passageiro PcD (Pessoa com Deficiência)?<span>*</span>
              </label>
              <RadioButtonsContainer>
                <label htmlFor="transport-has-pcd-yes">
                  <Field type="radio" id="transport-has-pcd-yes" name="transport.has_pcd" value="true" />
                  <p>Sim</p>
                </label>
                <label htmlFor="transport-has-pcd-no">
                  <Field type="radio" id="transport-has-pcd-no" name="transport.has_pcd" value="false" />
                  <p>Não</p>
                </label>
              </RadioButtonsContainer>
            </div>
            <ErrorMessage name="transport.has_pcd" />
          </InputField>
        </FormRow4Cols>
        <ButtonsDiv>
          <Button buttonType="secondary" onClick={() => history.push('/admin/new-excursion/step2')}>
            Voltar
          </Button>
          <article>
            <Button
              type="button"
              buttonType="secondary"
              onClick={e => {
                e.preventDefault();
                setShowModal(true);
              }}>
              Cancelar
            </Button>
            <Button
              type="button"
              buttonType={disabledButton ? 'disabled' : 'primary'}
              onClick={onSubmit}
              disabled={disabledButton}>
              Próximo
            </Button>
          </article>
        </ButtonsDiv>
      </Form>
      {showModal && (
        <ConfirmationModal
          open={showModal}
          onCancel={() => {
            setShowModal(false);
          }}
          onConfirm={handleCancel}
          title="Cancelar Cadastro de Excursão"
          message={
            <p>
              Se prosseguir com a ação, <strong>não será possível recuperar os dados</strong> já perdidos. Deseja
              continuar?
            </p>
          }
          confirmText="Confirmar"
          cancelText="Voltar"
        />
      )}
    </>
  );
};

const NewExcursionStep3Form = (): JSX.Element => {
  const history = useHistory();
  const excursionOrganizatorFormContext = useExcursionOrganizatorForm();
  const [disabledButton, setDisabledButton] = useState(true);
  const [errors, setErrors] = useState({});

  LockNavigatorHook(history);

  const initialValues = {
    transport: {
      document_number: excursionOrganizatorFormContext.form.transport.document_number ?? '',
      trade_name: excursionOrganizatorFormContext.form.transport.trade_name ?? '',
      legal_name: excursionOrganizatorFormContext.form.transport.legal_name ?? '',
      cadastur: excursionOrganizatorFormContext.form.transport.cadastur ?? '',
      phone: excursionOrganizatorFormContext.form.transport.phone ?? '',
      email: excursionOrganizatorFormContext.form.transport.email ?? '',
      vehicle_type: excursionOrganizatorFormContext.form.transport.vehicle_type ?? '',
      vehicle_plate: excursionOrganizatorFormContext.form.transport.vehicle_plate ?? '',
      has_pcd: excursionOrganizatorFormContext.form.transport.has_pcd ? 'true' : 'false',
    },
  };

  const validationSchema = yup.object().shape({
    transport: yup.object().shape({
      document_number: yup
        .string()
        .matches(/^([\w\.\-\/]{18}|[\w\.\-\/]{14})$/, 'Informe um CNPJ completo')
        .test('is-valid-cnpj', 'Informe um CNPJ válido', value => validateCNPJ(value || ''))
        .required('Campo obrigatório'),
      cadastur: yup.string(),
      trade_name: yup
        .string()
        .required('Nome Fantasia é obrigatório')
        .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ0-9\s&.,'-]+$/, 'Nome Fantasia contém caracteres inválidos')
        .min(2, 'Nome Fantasia deve ter pelo menos 2 caracteres')
        .max(100, 'Nome Fantasia deve ter no máximo 100 caracteres'),
      legal_name: yup
        .string()
        .required('Razão Social é obrigatória')
        .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ0-9\s&.,'-]+$/, 'Razão Social contém caracteres inválidos')
        .min(2, 'Razão Social deve ter pelo menos 2 caracteres')
        .max(150, 'Razão Social deve ter no máximo 150 caracteres'),
      phone: yup
        .string()
        .matches(/^\(\d{2}\)\s\d{1}\s\d{4}-\d{4}$/, 'Telefone deve ter o formato (XX) X XXXX-XXXX')
        .test('is-valid-ddd', 'DDD inválido', function (value) {
          if (!value) return false;
          const ddd = value.match(/^\((\d{2})\)/);
          if (!ddd) return false;
          return validDDDs.includes(ddd[1]);
        })
        .test('not-all-same', 'O número do telefone não pode ter todos os dígitos iguais', function (value) {
          if (!value) return false;

          const digits = value.replace(/\D/g, ''); // Remove caracteres não numéricos
          const phoneNumber = digits.slice(2); // Remove os dois primeiros dígitos do DDD

          return !/^(\d)\1+$/.test(phoneNumber); // Garante que o telefone não tenha todos os números iguais
        })
        .required('Telefone é obrigatório'),
      email: yup
        .string()
        .trim()
        .lowercase()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'E-mail inválido. Use um formato válido, ex: usuario@email.com'
        )
        .required('Campo Obrigatório'),
      vehicle_type: yup.string().required('Campo obrigatório'),
      vehicle_plate: yup
        .string()
        .required('Placa é obrigatória')
        .matches(
          /^[A-Z]{3}[0-9]{4}|[A-Z]{3}[0-9][A-Z][0-9]{2}$/,
          'Placa inválida! Use o formato ABC1234 (antigo) ou ABC1C23 (Mercosul)'
        )
        .test(
          'is-valid-plate-format',
          'Placa inválida! Use o formato ABC1234 (antigo) ou ABC1C23 (Mercosul)',
          value => {
            if (!value) return false;

            // Expressões Regulares para os formatos válidos
            const regexAntiga = /^[A-Z]{3}[0-9]{4}$/; // Formato antigo ABC1234
            const regexMercosul = /^[A-Z]{3}[0-9][A-Z][0-9]{2}$/; // Formato Mercosul ABC1D23

            return regexAntiga.test(value) || regexMercosul.test(value);
          }
        ),
      has_pcd: yup.string().required('Campo obrigatório'),
    }),
  });

  return (
    <Container>
      <MainTitle>Cadastro de Excursão</MainTitle>

      <StepperContainer>
        <StepperItem className="flex flex-col items-center justify-center">
          <article>
            <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step1')}>
              <IconOrganizador color="#ffffff" />
            </StepperBullet>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Organizador</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <Link to="#">
              <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step2')}>
                <IconViagem color="#ffffff" />
              </StepperBullet>
            </Link>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Viagem</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <Link to="#">
              <StepperBullet className="onEdit" onClick={e => e.preventDefault()}>
                <IconTransporte color="#020c90" />
              </StepperBullet>
            </Link>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Transporte</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet onClick={e => e.preventDefault()}>
                <IconHospedagem />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <StepperName>Hospedagem</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet onClick={e => e.preventDefault()}>
                <IconGuia />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <StepperName>Guia</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet onClick={e => e.preventDefault()}>
                <IconPagamento />
              </StepperBullet>
            </Link>
            <StepperLine />
          </article>
          <StepperName>Pagamento</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet onClick={e => e.preventDefault()}>
                <IconResumo />
              </StepperBullet>
            </Link>
          </article>
          <StepperName>Resumo</StepperName>
        </StepperItem>
      </StepperContainer>

      <ExcursionFirstFormContainer>
        <p>*Campo Obrigatório</p>

        <ExcursionFirstForm>
          <h2>Dados da transportadora:</h2>

          <Formik
            validate={values => {
              const isValid = validationSchema.isValidSync(values);
              setDisabledButton(!isValid);
            }}
            enableReinitialize
            validateOnBlur
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={() => {
              null;
            }}>
            {({ validateForm }) => {
              useEffect(() => {
                validateForm();
              }, [validateForm]);
              return <Form3 disabledButton={disabledButton} />;
            }}
          </Formik>
        </ExcursionFirstForm>
      </ExcursionFirstFormContainer>
    </Container>
  );
};

export default NewExcursionStep3Form;
