import React from 'react';
import StampLogo from '../../../../assets/images/stampLogo.svg';
import {
  StampRedArticle,
  StampBlueArticle,
  StampRedDiv,
  StampBlueDiv,
  StampDivTitle,
  StampDivNumber,
  StampDivImg,
} from './styles';

interface StampContainerProps {
  centralAccess: string;
  hasPCD: boolean;
  AccessNumber: string;
}

const StampContainer: React.FC<StampContainerProps> = ({ centralAccess, AccessNumber, hasPCD }) => {
  if (hasPCD) {
    return (
      <StampRedArticle>
        <StampRedDiv>
          <StampDivTitle>Acesso Centro</StampDivTitle>
          <StampDivNumber>{AccessNumber}</StampDivNumber>
          <StampDivImg src={StampLogo} />
        </StampRedDiv>
      </StampRedArticle>
    );
  }

  if (centralAccess === 'Sim') {
    return (
      <StampRedArticle>
        <StampRedDiv>
          <StampDivTitle>Acesso Centro</StampDivTitle>
          <StampDivNumber>{AccessNumber}</StampDivNumber>
          <StampDivImg src={StampLogo} />
        </StampRedDiv>
      </StampRedArticle>
    );
  }

  if (centralAccess === 'Não') {
    return (
      <StampBlueArticle>
        <StampBlueDiv>
          <StampDivTitle>Acesso Padrão</StampDivTitle>
          <StampDivNumber>{AccessNumber}</StampDivNumber>
          <StampDivImg src={StampLogo} />
        </StampBlueDiv>
      </StampBlueArticle>
    );
  }

  return null;
};

export default StampContainer;
