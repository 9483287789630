import React, { useEffect, useState } from 'react';
import { Container, ExcursionsButtonsContainer, ExcursionsNavigationButtonsContainer, WppButton } from './styles';
import MainTitle from 'components/MainTitle';
import IExcursionsData from 'interfaces/IExcursionsData';
import ExcursionsCarousel from 'common/ExcursionsCarousel/ExcursionsCarousel';
import PublicService from 'services/PublicService';
import { useLoading } from 'hooks/useLoading';
import Button from 'components/Button';
import { Link, useHistory } from 'react-router-dom';
import WppIcon from 'assets/images/wppIconWhite.svg';
import { Grid, InputAdornment, TextField } from '@mui/material';
import Search from '@mui/icons-material/Search';
import UserService from 'services/UserService';
import { UserType } from 'interfaces/IUser';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid';
import infoIcon from 'assets/images/info.svg';
import newsReport from 'assets/images/newsReport.svg';
import ConfirmationModal from 'components/ConfirmationModal';
import { IExcursion, ExcursionStatusAPI } from 'interfaces/IExcursion';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import StatusFilter from 'components/StatusFilter';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
import DateFilter from 'components/DateFilter';
import ExcursionsService from 'services/ExcursionsService';
import ExcursionStatusDropdown from './components/ExcursionStatusDropdown';
import ExcursionDetailsModal from './components/ExcursionDetailsModal';
import cancelIcon from 'assets/images/blockGrayIcon.svg';
import seloIcon from 'assets/images/seloIcon.svg';
import carrosselImages from './components/CarrosselImages/carrossel_images.json';
import IBanner from 'interfaces/IBanner';
import Loading from 'components/Loading';
import FAQComponent from './components/FAQComponent';
import faqList from './components/FAQComponent/FaqList/faqList.json';
import ExportButton from './components/ExportButton';

const ExcursionsPage = (): JSX.Element => {
  const [excursionsImageData, setExcursionsImageData] = useState<IExcursionsData | undefined>();
  const [excursionsCarrosselImages, setExcursionsCarrosselImages] = useState<IBanner[]>([]);
  const faqListItems = faqList;
  const [usersData, setUsersData] = useState<IExcursion[]>([]);
  const [openModalExcursion, setOpenModalExcursion] = useState<{
    id: number | null;
    action: string | null;
    status: string | null;
  }>({
    id: null,
    action: null,
    status: null,
  });
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: true,
    organizer_document: true,
  });
  const { openLoading, closeLoading, loading } = useLoading();
  const history = useHistory();
  const [filteredData, setFilteredData] = useState<IExcursion[]>([]);
  const [selectedTab, setSelectedTab] = useState<'proximas' | 'historico'>('proximas');
  const [showAdminSettings, setShowAdminSettings] = useState(false);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [userType, setUserType] = useState<UserType | null>(null);
  const [selectedExcursion, setSelectedExcursion] = useState<IExcursion | null>(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [excursionToCancel, setExcursionToCancel] = useState<IExcursion | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const service = {
    excursions: new ExcursionsService(),
  };

  const handleOpenDetailsModal = (excursion: IExcursion) => {
    setSelectedExcursion(excursion);
    setIsDetailsModalOpen(true);
  };

  const handleCloseDetailsModal = () => {
    setSelectedExcursion(null);
    setIsDetailsModalOpen(false);
  };

  const handleOpenModalExcursion = (
    id: number,
    action: 'approve' | 'refuse' | 'cancel',
    status: ExcursionStatusAPI
  ) => {
    setOpenModalExcursion({ id, action, status });
  };

  const handleConfirmExcursionAction = async () => {
    if (!openModalExcursion.id || !openModalExcursion.action) return;
    setIsLoading(true);
    openLoading();
    try {
      let newStatus: ExcursionStatusAPI;

      if (openModalExcursion.action === 'approve') {
        if (openModalExcursion.status === ExcursionStatusAPI.PROCESSING) {
          newStatus = ExcursionStatusAPI.PAYMENT_APPROVED;
        } else if (openModalExcursion.status === ExcursionStatusAPI.ANNALYSIS) {
          newStatus = ExcursionStatusAPI.APPROVED;
        }
        await new ExcursionsService().approveExcursion(openModalExcursion.id.toString());
      } else if (openModalExcursion.action === 'refuse') {
        if (openModalExcursion.status === ExcursionStatusAPI.PROCESSING) {
          newStatus = ExcursionStatusAPI.PAYMENT_DENIED;
        } else if (openModalExcursion.status === ExcursionStatusAPI.ANNALYSIS) {
          newStatus = ExcursionStatusAPI.REFUSED;
        }
        await new ExcursionsService().refuseExcursion(openModalExcursion.id.toString());
      }

      setUsersData(prevData =>
        prevData.map(excursion =>
          excursion.id === openModalExcursion.id ? { ...excursion, status: newStatus } : excursion
        )
      );
    } catch (error) {
      console.error('Erro ao atualizar status da excursão:', error);
    } finally {
      setIsLoading(false);
      closeLoading();
      setOpenModalExcursion({ id: null, action: null, status: null });
    }
  };

  const handleConfirmCancel = async () => {
    if (!excursionToCancel) return;
    openLoading();
    setIsLoading(true);

    try {
      await new ExcursionsService().cancelExcursion(excursionToCancel.id.toString());
      setUsersData(prevData =>
        prevData.map(excursion =>
          excursion.id === excursionToCancel.id ? { ...excursion, status: ExcursionStatusAPI.CANCELED } : excursion
        )
      );
    } catch (error) {
      console.error('Erro ao cancelar excursão:', error);
    } finally {
      setIsLoading(false);
      closeLoading();
      setOpenCancelModal(false);
      setExcursionToCancel(null);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      openLoading();
      try {
        openLoading();
        const user = await new UserService().findOne();
        if (user) {
          setUserType(user.type);
          setShowAdminSettings(user.type === 'tour-admin' || user.type === 'admin');
        }
      } catch (error) {
        console.error(error);
      } finally {
        closeLoading();
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    setColumnVisibilityModel(prev => ({
      ...prev,
      organizer_document: userType === 'tour-admin',
      createdAt: userType === 'tour-admin',
      organizer_name: userType === 'tour-admin',
    }));
  }, [userType]);

  useEffect(() => {
    openLoading();
    const fetchData = async () => {
      openLoading();
      try {
        const data = await service.excursions.getExcursions();

        // Formatação será mantida
        const formattedData = data.map(item => ({
          ...item,
          start_date: item.start_date,
          end_date: item.end_date,
        }));

        setUsersData(formattedData);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      } finally {
        closeLoading();
      }
    };
    fetchData();
  }, []);

  const handleFilterByStatus = (statusList: string[]) => {
    setSelectedStatus(statusList);
  };

  // Aplica os filtros sempre que startDate, endDate ou selectedTab mudarem
  useEffect(() => {
    applyFilters();
  }, [startDate, endDate, selectedTab, usersData, searchTerm, selectedStatus]);

  const applyFilters = () => {
    let filtered = [...usersData];
    const today = dayjs().startOf('day');

    if (startDate && endDate) {
      const startFilter = dayjs(startDate, 'DD/MM/YYYY');
      const endFilter = dayjs(endDate, 'DD/MM/YYYY');

      filtered = filtered.filter(item => {
        const excursionStart = dayjs(item.start_date, 'DD/MM/YYYY');
        return excursionStart.isSameOrAfter(startFilter) && excursionStart.isSameOrBefore(endFilter);
      });
    }

    filtered = filtered.filter(item => {
      const excursionEnd = dayjs(item.end_date, 'DD/MM/YYYY').endOf('day');

      if (selectedTab === 'proximas') {
        return excursionEnd.isSame(today, 'day') || excursionEnd.isAfter(today, 'day');
      } else {
        return excursionEnd.isBefore(today, 'day');
      }
    });

    if (searchTerm.trim() !== '') {
      const lowerSearchTerm = searchTerm.toLowerCase();

      filtered = filtered.filter(excursion =>
        [
          formatId(excursion.id),
          excursion.organizer?.name,
          excursion.organizer?.document_number,
          excursion.origin_city,
          excursion.transport?.vehicle_plate,
        ]
          .filter(Boolean)
          .some(value => value.toString().toLowerCase().includes(lowerSearchTerm))
      );
    }

    if (selectedStatus.length > 0) {
      filtered = filtered.filter(excursion => selectedStatus.includes(excursion.status));
    }

    setFilteredData(filtered);
  };

  const handleFilterByDate = (start: string | null, end: string | null) => {
    setStartDate(start);
    setEndDate(end);
  };

  // Aplica os filtros sempre que startDate, endDate ou selectedTab mudarem
  useEffect(() => {
    applyFilters();
  }, [startDate, endDate, selectedTab, usersData, searchTerm, selectedStatus]);

  const stylingRowStatus = (status?: ExcursionStatusAPI, id?: number) => {
    return (
      <ExcursionStatusDropdown id={id!} status={status!} userType={userType} onAction={handleOpenModalExcursion} />
    );
  };

  const handleOpenCancelModal = (excursion: IExcursion) => {
    setExcursionToCancel(excursion);
    setOpenCancelModal(true);
  };

  const formatId = (id: number): string => {
    const baseNumber = id ? Math.abs(id) % 100000 : 0;
    const letter = String.fromCharCode(65 + Math.floor(baseNumber / 100000));
    const number = (baseNumber % 100000).toString().padStart(5, '0');
    return `${letter}${number}`;
  };

  const excursionsColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 80,
      maxWidth: 100,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
      // valueGetter(params) {
      //   const baseNumber = params.value ? Math.abs(params.value) % 100000 : 0;
      //   const letter = String.fromCharCode(65 + Math.floor(baseNumber / 100000));
      //   const number = (baseNumber % 100000).toString().padStart(5, '0');
      //   return `${letter}${number}`;
      // },
      valueFormatter: params => formatId(params.value as number),
    },
    {
      field: 'createdAt',
      headerName: 'Criado em',
      flex: 1,
      minWidth: 120,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
      renderCell: params => (
        <div style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
          {dayjs(params.value).format('DD/MM/YYYY') + '\n' + dayjs(params.value).format('HH:mm')}
        </div>
      ),
    },
    {
      field: 'start_date',
      headerName: 'Chegada',
      flex: 1,
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
      renderCell: params => {
        return params.value ? params.value : '-';
      },
    },
    {
      field: 'end_date',
      headerName: 'Saída',
      flex: 1,
      minWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: params => {
        return params.value ? params.value : '-';
      },
    },
    {
      field: 'organizer_name',
      headerName: 'Responsável',
      flex: 1,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
      valueGetter: params => params.row.organizer?.name || 'N/A',
    },
    {
      field: 'organizer_document',
      headerName: 'CPF',
      flex: 1,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
      valueGetter: params => params.row.organizer?.document_number || 'N/A',
    },
    {
      field: 'origin_city',
      headerName: 'Origem',
      flex: 1,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
      valueGetter: params => `${params.row.origin_city} - ${params.row.origin_state}`,
    },
    {
      field: 'transport.vehicle_plate',
      headerName: 'Placa',
      flex: 1,
      minWidth: 120,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
      valueGetter: params => params.row.transport?.vehicle_plate || 'N/A',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 222,
      renderCell: (
        params: GridRenderCellParams<IExcursion, ExcursionStatusAPI, keyof IExcursion, GridTreeNodeWithRender>
      ) => stylingRowStatus(params.value, params.row.id),
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      flex: 1,
      minWidth: 120,
      getActions: params => [
        <div key={params.id} style={{ width: 120, textAlign: 'initial', display: 'flex', justifyContent: 'flex-end' }}>
          {/* Ícone para abrir a modal com os detalhes */}
          <button className="button-icon" title="Mais informações" onClick={() => handleOpenDetailsModal(params.row)}>
            <img src={infoIcon} alt="Mais informações" />
          </button>
          {/* Ícone de Declaração */}
          {params.row.declaration_file && (
            <button
              className="button-icon"
              title="Abrir declaração"
              onClick={() => window.open(params.row.declaration_file_signed, '_blank')}>
              <img src={newsReport} alt="Abrir declaração" />
            </button>
          )}
          {/* Ícone de Cancelar */}
          {userType !== 'tour-admin' && params.row.status !== ExcursionStatusAPI.CANCELED && (
            <button className="button-icon" title="Cancelar" onClick={() => handleOpenCancelModal(params.row)}>
              <img src={cancelIcon} alt="Cancelar" />
            </button>
          )}
          {/* Ícone de Declaração */}
          {params.row.id && (
            <button
              className="button-icon"
              disabled={
                params.row.status !== ExcursionStatusAPI.APPROVED &&
                params.row.status !== ExcursionStatusAPI.PAYMENT_APPROVED
              }
              title="Gerar Selo"
              style={{ width: '14px', height: '14px', fill: '#D9D9D9' }}
              onClick={() => window.open(`excursion/excursion-stamp/${params.row.id}`, '_blank')}>
              <img src={seloIcon} alt="Abrir declaração" />
            </button>
          )}
        </div>,
      ],
      align: 'right',
      headerAlign: 'right',
    },
  ];

  const getExcursionsData = async () => {
    try {
      openLoading();
      const service = new PublicService();
      await service.getHomeData().then(setExcursionsImageData);
      setExcursionsCarrosselImages(carrosselImages.carrosselImages);
      closeLoading();
    } catch (error) {
      closeLoading();
    }
  };

  useEffect(() => {
    getExcursionsData();
  }, []);

  return (
    <>
      {userType !== null && (
        <>
          <Container>
            <MainTitle>Excursões</MainTitle>
            {!showAdminSettings && (
              <>
                <ExcursionsCarousel banners={excursionsCarrosselImages} />
                <WppButton onClick={() => window.open('https://api.whatsapp.com/send?phone=5573988667507')}>
                  <img src={WppIcon} alt="WhatsApp Icon" />
                  Fale Conosco
                </WppButton>
              </>
            )}
            {!showAdminSettings && (
              <ExcursionsButtonsContainer>
                <Grid container xl="auto" md="auto" sm="auto" xs="auto" sx={{ height: 'max-content' }}>
                  <Link to="/admin/new-excursion/step1">
                    <Button type="button">Cadastrar excursão</Button>
                  </Link>
                  <Button
                    buttonType="secondary"
                    onClick={() =>
                      window.open(
                        'https://storage.googleapis.com/bkt-porto-seguro-portal-images-dev/Porto%20Seguro%20-%20Regras%20Basicas%20de%20Circula%C3%A7%C3%A3o.pdf',
                        '_blank'
                      )
                    }>
                    Regras Básicas de Circulação
                  </Button>

                  <Button
                    buttonType="secondary"
                    onClick={() =>
                      window.open(
                        'https://storage.googleapis.com/bkt-porto-seguro-portal-images-dev/docs/Porto_Seguro_Taxas_de_transporte_e_hospedagem_2025.pdf',
                        '_blank'
                      )
                    }>
                    Taxas de Transporte e Hospedagem
                  </Button>
                </Grid>
              </ExcursionsButtonsContainer>
            )}
            {/* Botões "Próximas" e "Histórico" */}
            <ExcursionsNavigationButtonsContainer>
              <Button
                buttonType={selectedTab === 'proximas' ? 'selectedButton' : 'unselectedButton'}
                onClick={() => setSelectedTab('proximas')}>
                Próximas
              </Button>
              <Button
                buttonType={selectedTab === 'historico' ? 'selectedButton' : 'unselectedButton'}
                onClick={() => setSelectedTab('historico')}>
                Histórico
              </Button>
            </ExcursionsNavigationButtonsContainer>
            <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 4, marginTop: 4, padding: '0 50px' }}>
              {/* Campo de pesquisa */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Digite o que procura"
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  InputProps={{
                    sx: {
                      borderRadius: '20px',
                      height: '40px',
                      backgroundColor: '#fff',
                      padding: '7px',
                      paddingLeft: '0px',
                      cursor: 'pointer',
                      fontFamily: 'inherit',
                      '& .MuiOutlinedInput-notchedOutline': { borderColor: '#ccc !important' },
                      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#ccc !important' },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#ccc !important',
                        borderWidth: '1px',
                      },
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search sx={{ color: '#9A9A9A' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* Filtro de Período - Ocupa 50% no mobile e 2.5 ou 3 no desktop */}
              <Grid item xs={userType === 'tour-admin' ? 5 : 6} md={userType === 'tour-admin' ? 2.5 : 3}>
                <DateFilter onFilterByDate={handleFilterByDate} />
              </Grid>

              {/* Filtro de Status - Ocupa 50% no mobile e 2.5 ou 3 no desktop */}
              <Grid item xs={userType === 'tour-admin' ? 5 : 6} md={userType === 'tour-admin' ? 2.5 : 3}>
                <StatusFilter onFilterByStatus={handleFilterByStatus} />
              </Grid>

              {/* Botão de Exportação - Ocupa 33% no mobile e 1 no desktop (somente tour-admin) */}
              {userType === 'tour-admin' && (
                <Grid
                  item
                  xs={2}
                  md={1}
                  sx={{
                    display: 'flex',
                    justifyContent: { md: 'flex-start' },
                  }}>
                  <ExportButton data={filteredData} />
                </Grid>
              )}
            </Grid>

            {/* Data Grid */}
            <Grid
              sx={{
                width: '100%',
                minWidth: '100%',
                marginBottom: '50px',
                textAlign: 'center',
                padding: '0 50px',
              }}>
              <DataGrid
                sx={{
                  borderRadius: '18px', // Mantém os cantos arredondados
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: '#001F99', // Azul correto no cabeçalho
                    color: 'white',
                    fontWeight: 'bold',
                  },
                  '& .MuiDataGrid-columnHeadersInner': {
                    display: 'flex',
                    width: '100%', // Evita espaços extras
                  },
                  '& .MuiDataGrid-columnHeader': {
                    flexGrow: 1, // Garante que todas as colunas se expandam
                  },
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none', // Remove linhas de separação entre colunas
                  },
                  '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                    outline: 'none !important', // Remove o outline azul
                    border: 'none', // Remove qualquer borda
                  },
                }}
                rows={filteredData}
                columns={excursionsColumns}
                getRowId={row => row.id}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                className="dataGrid"
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={newModel => setColumnVisibilityModel(newModel)}
                slotProps={{
                  toolbar: {
                    densitySelector: false,
                    exportButton: false,
                  },
                }}
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                disableDensitySelector
                disableVirtualization
              />
            </Grid>
            {!showAdminSettings && <FAQComponent title="Perguntas Frequentes" faqList={faqListItems} />}
            <ConfirmationModal
              open={openModalExcursion.id !== null && openModalExcursion.action === 'approve'}
              title={
                openModalExcursion.status === ExcursionStatusAPI.PROCESSING
                  ? 'APROVAR PAGAMENTO'
                  : 'APROVAR SOLICITAÇÃO DE ISENÇÃO'
              }
              message={
                openModalExcursion.status === ExcursionStatusAPI.PROCESSING ? (
                  <>
                    Deseja <strong>aprovar</strong> o pagamento? <br />
                    Se continuar com a ação, <strong>não será possível desfazê-la</strong>.
                  </>
                ) : (
                  <>
                    Deseja <strong>aprovar</strong> a solicitação de isenção? <br />
                    Se continuar com a ação, <strong>não será possível desfazê-la</strong>.
                  </>
                )
              }
              confirmText="Aprovar"
              onCancel={() => {
                setOpenModalExcursion({ id: null, action: null, status: null });
              }}
              onConfirm={handleConfirmExcursionAction}
              isLoading={isLoading}
            />
            <ConfirmationModal
              open={openModalExcursion.id !== null && openModalExcursion.action === 'refuse'}
              title={
                openModalExcursion.status === ExcursionStatusAPI.PROCESSING
                  ? 'RECUSAR PAGAMENTO'
                  : 'RECUSAR SOLICITAÇÃO DE ISENÇÃO'
              }
              message={
                openModalExcursion.status === ExcursionStatusAPI.PROCESSING ? (
                  <>
                    Deseja <strong>recusar</strong> o pagamento? <br />
                    Se continuar com a ação, <strong>não será possível desfazê-la</strong>.
                  </>
                ) : (
                  <>
                    Deseja <strong>recusar</strong> a solicitação de isenção? <br />
                    Se continuar com a ação, <strong>não será possível desfazê-la</strong>.
                  </>
                )
              }
              confirmText="Recusar"
              onCancel={() => setOpenModalExcursion({ id: null, action: null, status: null })}
              onConfirm={handleConfirmExcursionAction}
              isLoading={isLoading}
            />
            <ExcursionDetailsModal
              open={isDetailsModalOpen}
              onClose={handleCloseDetailsModal}
              excursion={selectedExcursion}
              userType={userType}
            />
            <ConfirmationModal
              open={openCancelModal}
              title="CANCELAR EXCURSÃO"
              message={
                <>
                  Você está cancelando uma excursão. Se prosseguir com a ação,{' '}
                  <strong>não será possível desfazê-la</strong>.<br></br>
                  <br></br>
                  <p>
                    Caso já tenha realizado o pagamento,{' '}
                    <strong>não será possível receber reembolso através do portal</strong>. Se necessário, entre em
                    contato com a Secretaria do Turismo. <br></br>
                    <br></br>
                    <p>Deseja continuar?</p>
                  </p>
                </>
              }
              confirmText="Confirmar"
              onCancel={() => setOpenCancelModal(false)}
              cancelText="Voltar"
              onConfirm={handleConfirmCancel}
            />
          </Container>
        </>
      )}
    </>
  );
};

export default ExcursionsPage;
