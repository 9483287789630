import { facebook } from 'assets/images/facebook-white.svg';
import Yup from 'utils/validation';
import { businessFormInitialValues } from '../utils';
import { validDDDs } from 'utils/basicUtils';

export const accomodationsInitialValues = {
  ...businessFormInitialValues,
  accommodation: {
    id: '',
    category: '',
    description: '',
    numberOfBeds: '',
    numberOfRooms: '',
  },
};

export const accomodationsValidationSchema = Yup.object().shape({
  id: Yup.number().notRequired(),
  businessOwner: Yup.array().of(
    Yup.string()
      .required('O nome do proprietário é obrigatório')
      .test(
        'not-only-spaces',
        'O nome do proprietário não pode conter apenas espaços',
        value => value !== undefined && value.trim().length > 0
      )
  ),
  businessRepresentative: Yup.string()
    .required('O nome do responsável comercial é obrigatório')
    .test(
      'not-only-spaces',
      'O nome do responsável comercial não pode conter apenas espaços',
      value => value !== undefined && value.trim().length > 0
    ),
  businessType: Yup.string()
    .required('O tipo de negócio é obrigatório')
    .test(
      'not-only-spaces',
      'O tipo de negócio não pode conter apenas espaços',
      value => value !== undefined && value.trim().length > 0
    ),
  description: Yup.string()
    .required('A descrição é obrigatória')
    .test(
      'not-only-spaces',
      'A descrição não pode conter apenas espaços',
      value => value !== undefined && value.trim().length > 0
    ),
  document: Yup.string()
    .length(18, 'O CNPJ deve ter 18 caracteres')
    .required('O CNPJ é obrigatório')
    .test(
      'not-only-spaces',
      'O CNPJ não pode conter apenas espaços',
      value => value !== undefined && value.trim().length > 0
    ),
  googleMapsLink: Yup.string()
    .required('O link do Google Maps é obrigatório')
    .test(
      'not-only-spaces',
      'O link do Google Maps não pode conter apenas espaços',
      value => value !== undefined && value.trim().length > 0
    ),
  tradeName: Yup.string()
    .required('O nome fantasia é obrigatório')
    .test(
      'not-only-spaces',
      'O nome fantasia não pode conter apenas espaços',
      value => value !== undefined && value.trim().length > 0
    ),
  title: Yup.string()
    .required('O título é obrigatório')
    .test(
      'not-only-spaces',
      'O título não pode conter apenas espaços',
      value => value !== undefined && value.trim().length > 0
    ),
  phone: Yup.string()
    .strict(true)
    .required('Telefone é obrigatório')
    .test('is-valid-ddd', 'DDD inválido', function (value) {
      if (!value) return false;
      const ddd = value.match(/^\((\d{2})\)/);
      if (!ddd) return false;
      return validDDDs.includes(ddd[1]);
    })
    .test('not-all-same', 'Telefone não pode ter todos os números iguais', function (value) {
      if (!value) return false;
      const digits = value.replace(/\D/g, '');
      return !/^(\d)\1+$/.test(digits);
    }),
  site: Yup.string().url('Link do site inválido'),
  youtubeLink: Yup.string().url('Link do Youtube inválido'),
  instagramLink: Yup.string().url('Link do Instagram inválido'),
  facebookLink: Yup.string().url('Link do Facebook inválido'),
  whatsapp: Yup.string(),
  image: Yup.array().min(1, 'Pelo menos uma imagem é obrigatória').required(),
  address: Yup.object().shape({
    id: Yup.number(),
    city: Yup.string()
      .strict(true)
      .required('A cidade é obrigatória')
      .test(
        'not-only-spaces',
        'A cidade não pode conter apenas espaços',
        value => value !== undefined && value.trim().length > 0
      ),
    neighborhood: Yup.string()
      .strict(true)
      .matches(/^(?!^\d+$)[\d\D]*$/, 'O bairro não pode conter somente números')
      .required('O bairro é obrigatório')
      .test(
        'not-only-spaces',
        'O bairro não pode conter apenas espaços',
        value => value !== undefined && value.trim().length > 0
      ),
    number: Yup.string()
      .strict(true)
      .matches(/^\d+$/, 'O número deve conter apenas dígitos')
      .required('O número é obrigatório'),
    state: Yup.string()
      .strict(true)
      .required('O estado é obrigatório')
      .test(
        'not-only-spaces',
        'O estado não pode conter apenas espaços',
        value => value !== undefined && value.trim().length > 0
      ),
    street: Yup.string()
      .strict(true)
      .matches(/^(?!\d+$).*/, 'O campo endereço não pode ser apenas números')
      .required('O endereço é obrigatório')
      .test(
        'not-only-spaces',
        'O endereço não pode conter apenas espaços',
        value => value !== undefined && value.trim().length > 0
      ),
    zipCode: Yup.string()
      .strict(true)
      .required('O CEP é obrigatório')
      .matches(/^\d{5}-?\d{3}$/, 'CEP deve ter o formato XXXXX-XXX')
      .test('is-not-equal', 'CEP não pode conter todos os números iguais', function (value) {
        if (!value) return false;
        const cleanedValue = value.replace('-', '');
        return !/^(.)\1+$/.test(cleanedValue);
      }),
  }),
  images: Yup.array().min(1, 'Pelo menos uma imagem é obrigatória').required(),
  accommodation: Yup.object().shape({
    id: Yup.string().matches(/^\d+$/, 'O ID deve conter apenas dígitos'),
    category: Yup.string()
      .strict(true)
      .required('A categoria é obrigatória')
      .test(
        'not-only-spaces',
        'A categoria não pode conter apenas espaços',
        value => value !== undefined && value.trim().length > 0
      ),
    description: Yup.string(),
    numberOfBeds: Yup.string()
      .strict(true)
      .matches(/^\d+$/, 'O número de leitos deve conter apenas dígitos')
      .required('O número de leitos é obrigatório'),
    numberOfRooms: Yup.string()
      .strict(true)
      .matches(/^\d+$/, 'O número de quartos deve conter apenas dígitos')
      .required('O número de quartos é obrigatório'),
  }),
});
