import React from 'react';
import { ContainerSACInformation } from './styles';

interface StampSacInformationProps {
  visible: boolean;
}

const StampSacInformation: React.FC<StampSacInformationProps> = ({ visible }) => {
  switch (visible) {
    case true:
      return (
        <ContainerSACInformation>
          <strong>Atendimento ao cliente: </strong>
          (73) 98866-7507 - WhatsApp
        </ContainerSACInformation>
      );
    case false:
      return null;
  }
};

export default StampSacInformation;
