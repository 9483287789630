import React from 'react';
import { ListItem, Menu } from '../DefaultMenu/styles';
import cameraIcon from '../../assets/images/camera_menu_item.svg';
import binoculars from 'assets/images/binoculars.svg';
import checklist from 'assets/images/checklist.svg';
import mindMap from 'assets/images/mindMap.svg';
import usersIcon from 'assets/images/usersIcon.svg';
import configIcon from '../../assets/images/configIcon.svg';
import starIcon from 'assets/images/starIcon.svg';
import myNews from 'assets/images/newsReport.svg';
import tourIcon from '../../assets/images/tour_menu_item.svg';
import { NavLink } from 'react-router-dom';
import { usePortalContext } from 'common/PortalContext';

const SuperAdminMenu: React.FC = () => {
  const PortalContext = usePortalContext();
  const midiaKitURL = PortalContext?.midiaKitURL;

  return (
    <Menu>
      <ul>
        <ListItem>
          <NavLink activeClassName="selected" className="observatory-tourist" to="/admin/observatory-tourist/overview">
            <img src={binoculars} alt="icon for list item" />
            <span>Observatório Turístico</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink activeClassName="selected" to="/admin/users-panel">
            <img src={usersIcon} alt="icon for list item" />
            <span>Painel de Usuários</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink activeClassName="selected" to="/admin/tourist-offers/business-list">
            <img src={checklist} alt="icon for list item" />
            <span>Ofertas Turísticas</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink activeClassName="selected" to="/admin/config">
            <img src={configIcon} alt="icon for list item" />
            <span>Configurações</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink exact activeClassName="selected" to="/admin/news">
            <img src={myNews} alt="icon for list item" />
            <span>Gestão de Notícias</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink activeClassName="selected" to="/admin/strategic-map">
            <img src={mindMap} alt="icon for list item" />
            <span>Mapa Estratégico</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <NavLink activeClassName="selected" to="/admin/reviews">
            <img src={starIcon} alt="icon for list item" />
            <span>Avaliações</span>
          </NavLink>
        </ListItem>
        <ListItem>
          <a href={midiaKitURL} target="_blank" rel="noopener noreferrer">
            <img src={cameraIcon} alt="icon for list item" />
            <span>Mídia Kit</span>
          </a>
        </ListItem>
        <ListItem>
          <NavLink activeClassName="selected" to="/admin/excursions">
            <img src={tourIcon} alt="icon for list item" />
            <span>Excursões</span>
          </NavLink>
        </ListItem>
      </ul>
    </Menu>
  );
};

export default SuperAdminMenu;
