import styled from 'styled-components';

export const StampInformationList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  list-style: none;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

export const StampInformationListItem = styled.li`
  margin-bottom: 25px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
`;

export const StampInformationListItemText = styled.p`
  font-size: 40px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;
