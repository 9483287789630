import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid white;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: ${spin} 0.8s linear infinite;
  display: inline-block;
`;

const BaseButton = styled.button`
  border-radius: 18px;
  border: none;
  box-shadow: 0px 0px 10px 0px #00000026;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  height: 40px;
  padding: 0px 20px;
  text-transform: uppercase;

  &:hover {
    background-color: #020c90;
    border: 0.5px solid #020c90;
    color: #ffffff;
  }
`;

export const PrimaryButton = styled(BaseButton)`
  background-color: #ffcb29;
  border: 1px solid #ffcb29;
`;

export const LoadingButton = styled(PrimaryButton)`
  cursor: not-allowed;
`;

export const OutlineButton = styled(BaseButton)`
  background-color: #ffffff;
  border: 0.5px solid #9a9a9a;
  color: #9a9a9a;
  box-shadow: none;

  &:visited {
    color: #9a9a9a;
    border-color: #9a9a9a;
  }

  .selected {
    color: #020c90;
    border-color: #020c90;
  }
`;

export const DisabledButton = styled(BaseButton)`
  background-color: #dadada;
  cursor: initial;
  border: 1px solid #dadada;
  box-shadow: none;

  &:hover {
    background-color: #dadada;
    color: #ffffff;
    cursor: initial;
    border: 1px solid #dadada;
  }
`;

export const SecondaryButton = styled(BaseButton)`
  min-width: 118px;
  background-color: #ffffff;
  border: 0.5px solid #ffcb29;
  color: #ffcb29;

  &:visited {
    color: #9a9a9a;
    border-color: #9a9a9a;
  }

  &:hover {
    border: none;
    border: 0.5px solid #020c90;
  }

  .selected {
    color: #020c90;
    border-color: #020c90;
  }
`;

export const SelectedButton = styled(BaseButton)`
  min-width: 118px;
  background-color: transparent;
  border: 0.5px solid #020c90;
  color: #020c90;
  cursor: initial;
  box-shadow: none;

  &:hover {
    border: none;
    border: 0.5px solid #020c90;
  }

  .selected {
    color: #020c90;
    border-color: #020c90;
  }
`;

export const UnselectedButton = styled(BaseButton)`
  min-width: 118px;
  background-color: transparent;
  border: 0.5px solid #9a9a9a;
  color: #9a9a9a;
  box-shadow: none;

  &:hover {
    border: none;
    border: 0.5px solid #020c90;
  }

  .selected {
    color: #020c90;
    border-color: #020c90;
  }
`;
export const IconButton = styled.button`
  background-color: #ffd700;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  min-width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  /* Adicionando a sombra */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #e6c200;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: none;
  }

  img {
    width: 60%;
    height: 60%;
    max-width: 24px;
    max-height: 24px;
    object-fit: contain; /* Garante que a imagem não fique esticada */
  }
`;
