import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  ButtonsDiv,
  Container,
  ExcursionCompanyInformation,
  ExcursionFirstFormContainer,
  StepperBullet,
  StepperContainer,
  StepperItem,
  StepperLine,
  StepperName,
} from '../../styles';
import MainTitle from 'components/MainTitle';
import {
  IconGuia,
  IconHospedagem,
  IconOrganizador,
  IconPagamento,
  IconResumo,
  IconTransporte,
  IconViagem,
} from 'features/NewExcursionForm/components/StepperIcons';
import Button from 'components/Button';
import ConfirmationModal from 'components/ConfirmationModal';
import { useExcursionOrganizatorForm } from 'common/ExcursionOrganizatorFormContext';
import { useLoading } from 'hooks/useLoading';
import LockNavigatorHook from 'features/NewExcursionForm/hooks/LockNavigatorHook';

const NewExcursionStep6Form = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const excursionOrganizatorFormContext = useExcursionOrganizatorForm();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const accommodationType = excursionOrganizatorFormContext.form.accommodation.accommodation_type;
  const vehicleType = excursionOrganizatorFormContext.form.transport.vehicle_type;
  const [vehiclePrice, setVehiclePrice] = useState(0);
  const [totalAccommodationDays, setTotalAccommodationDays] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  LockNavigatorHook(history);

  useEffect(() => {
    openLoading();
    handleVehiclePrice();
    handleAccommodationDays();
    handleTotalPrice();
    closeLoading();
  }, [accommodationType, vehicleType, totalAccommodationDays]);

  const handleVehiclePrice = () => {
    let price = 0;
    switch (accommodationType) {
      case 1:
      case 2:
        price = vehicleType === 'Ônibus' ? 432.69 : vehicleType === 'Micro-ônibus' ? 288.47 : 144.24;
        break;
      case 3:
        price = vehicleType === 'Ônibus' ? 649.03 : vehicleType === 'Micro-ônibus' ? 432.69 : 259.63;
        break;
      case 4:
        price = vehicleType === 'Ônibus' ? 3605.79 : vehicleType === 'Micro-ônibus' ? 2884.62 : 1442.31;
        break;
      default:
        break;
    }
    setVehiclePrice(price);
  };

  const handleAccommodationDays = () => {
    const startDate = new Date(excursionOrganizatorFormContext.form.start_date);
    const endDate = new Date(excursionOrganizatorFormContext.form.end_date);
    const totalDays = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
    setTotalAccommodationDays(totalDays === 0 ? 1 : totalDays);
  };

  const handleTotalPrice = async () => {
    try {
      let finalPrice = vehiclePrice;
      if (totalAccommodationDays > 7) {
        const extraDays = totalAccommodationDays - 7;
        finalPrice += vehiclePrice * 0.1 * extraDays;
      }
      setTotalPrice(finalPrice);
      excursionOrganizatorFormContext.setForm({
        ...excursionOrganizatorFormContext.form,
        total_price: totalPrice,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    excursionOrganizatorFormContext.setForm({
      ...excursionOrganizatorFormContext.form,
      organizer: {
        ...excursionOrganizatorFormContext.form.organizer,
        document_type: '',
        name: '',
        document_number: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
      },
      origin_city: '',
      origin_state: '',
      start_date: '',
      hour_start: '',
      end_date: '',
      hour_end: '',
      people_number: 0,
      destination: [],
      central_access: false,
      travel_reason: '',
      declaration_file: '',
      transport: {
        ...excursionOrganizatorFormContext.form.transport,
        trade_name: '',
        legal_name: '',
        document_number: '',
        cadastur: '',
        phone: '',
        email: '',
        vehicle_type: '',
        vehicle_plate: '',
        has_pcd: false,
      },
      accommodation: {
        ...excursionOrganizatorFormContext.form.accommodation,
        accommodation_type: 0,
        cadastur: '',
        license_number: '',
        document_number: '',
        name: '',
        phone: '',
        email: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        justification: '',
        iss: '',
      },
      guide: {
        ...excursionOrganizatorFormContext.form.guide,
        city_tour: false,
        coastal_tour: false,
        type: '',
        guide_name: '',
        cadastur: '',
        phone: '',
        email: '',
      },
      total_price: 0,
    });
    setShowModal(false);
    history.push('/admin/excursions');
  };

  const handleNext = () => {
    try {
      openLoading();
      excursionOrganizatorFormContext.setForm({
        ...excursionOrganizatorFormContext.form,
        total_price: parseFloat(totalPrice.toFixed(2)),
      });
      history.push('/admin/new-excursion/step7');
    } catch (error) {
      console.error(error);
    } finally {
      closeLoading();
    }
  };

  return (
    <Container>
      <MainTitle>Cadastro de Excursão</MainTitle>

      <StepperContainer>
        <StepperItem className="flex flex-col items-center justify-center">
          <article>
            <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step1')}>
              <IconOrganizador color="#ffffff" />
            </StepperBullet>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Organizador</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <Link to="#">
              <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step2')}>
                <IconViagem color="#ffffff" />
              </StepperBullet>
            </Link>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Viagem</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <Link to="#">
              <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step3')}>
                <IconTransporte />
              </StepperBullet>
            </Link>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Transporte</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <Link to="#">
              <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step4')}>
                <IconHospedagem />
              </StepperBullet>
            </Link>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Hospedagem</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <Link to="#">
              <StepperBullet className="edited" onClick={() => history.push('/admin/new-excursion/step5')}>
                <IconGuia />
              </StepperBullet>
            </Link>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Guia</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine className="onEdit" />
            <Link to="#">
              <StepperBullet className="onEdit" onClick={e => e.preventDefault()}>
                <IconPagamento color="#020c90" />
              </StepperBullet>
            </Link>
            <StepperLine className="onEdit" />
          </article>
          <StepperName className="onEdit">Pagamento</StepperName>
        </StepperItem>

        <StepperItem>
          <article>
            <StepperLine />
            <Link to="#">
              <StepperBullet onClick={e => e.preventDefault()}>
                <IconResumo />
              </StepperBullet>
            </Link>
          </article>
          <StepperName>Resumo</StepperName>
        </StepperItem>
      </StepperContainer>

      <ExcursionFirstFormContainer>
        <ExcursionCompanyInformation>
          <h2>Dados do Pagamento:</h2>
          {/* <p>
            <strong>Tipo de veículo:</strong> {excursionOrganizatorFormContext.form.transport.vehicle_type}
          </p>
          <p>
            <strong>Valor do veículo:</strong>{' '}
            {vehiclePrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </p>
          <p>
            <strong>Tipo de hospedagem:</strong> {excursionOrganizatorFormContext.form.accommodation.accommodation_type}
          </p> */}
          <p>
            <strong>Valor:</strong> {totalPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </p>

          <p>
            Após a finalização do cadastro, será enviado um boleto, em até 48 horas, para o e-mail do responsável pela
            excursão para a realização do pagamento.
          </p>
        </ExcursionCompanyInformation>
        <ButtonsDiv>
          <Button buttonType="secondary" onClick={() => history.push('/admin/new-excursion/step5')}>
            Voltar
          </Button>
          <article>
            <Button
              type="button"
              buttonType="secondary"
              onClick={e => {
                e.preventDefault();
                setShowModal(true);
              }}>
              Cancelar
            </Button>
            <Button type="button" onClick={handleNext}>
              Próximo
            </Button>
          </article>
        </ButtonsDiv>
      </ExcursionFirstFormContainer>
      {showModal && (
        <ConfirmationModal
          open={showModal}
          onCancel={() => {
            setShowModal(false);
          }}
          onConfirm={handleCancel}
          title="Cancelar Cadastro de Excursão"
          message={
            <p>
              Se prosseguir com a ação, <strong>não será possível recuperar os dados</strong> já perdidos. Deseja
              continuar?
            </p>
          }
          confirmText="Confirmar"
          cancelText="Voltar"
        />
      )}
    </Container>
  );
};

export default NewExcursionStep6Form;
