import React, { useEffect, useState } from 'react';
import { BusinessOwner, Container, ErrorMessageBox, FormList, FormListButtonBox, FormListRow } from '../styles';
import { Field, Form, Formik } from 'formik';
import UploadImage from 'components/UploadImage/field';
import SelectComponent from 'components/Select';
import { TradeMyBusinessNewPageProps, states } from '../utils';
import Button from 'components/Button';
import { transportsInitialValues, transportsValidationSchema } from './utils';
import FieldMask from 'components/FieldMask';
import ErrorMessage from 'components/ErrorMessage';
import TextArea from 'components/TextArea';
import { useHistory } from 'react-router-dom';
import { useLoading } from 'hooks/useLoading';
import TouristOffersBusinessService from 'services/TouristOffersBusinessService';
import ImageService from 'services/ImageService';
import MessageModal from 'common/MessageModal';
import ArrayField from 'components/ArrayField';
import ConfirmationModal from 'components/ConfirmationModal';
import Tooltip from 'components/Tooltip';
import questionMarkIcon from 'assets/images/questionMarkIcon.svg';
import { placeIDLink } from 'utils/placeIDLink';
import TransportCategoryService from 'services/TransportCategoryService';

const MyBusinessTransportsForm = ({ selectedId, item, type }: TradeMyBusinessNewPageProps): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const history = useHistory();
  const [messageInfo, setMessageInfo] = useState({ title: '', message: '' });
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [transportCategories, setTransportCategories] = useState<{ label: string; value: string }[]>([]);

  const service = new TouristOffersBusinessService();
  const imageService = new ImageService();
  const transportCategoryService = new TransportCategoryService();

  useEffect(() => {
    if (selectedId) {
      console.log('selectedId', selectedId);
    }
  }, [selectedId]);

  useEffect(() => {
    fetchTransportCategories();
  }, []);

  const fetchTransportCategories = async () => {
    const categories = await transportCategoryService.findAll();
    const formattedCategories = categories.map(category => ({
      label: category.description,
      value: category.description,
    }));
    setTransportCategories(formattedCategories);
  };

  const onSubmit = async (values: any) => {
    openLoading();

    try {
      const [images, image, structures] = await Promise.all([
        imageService.uploadMultiple(values.images),
        imageService.uploadMultiple(values.image),
        imageService.uploadMultiple(values.structures),
      ]);

      const restoredImages = images.map((image: string) => ({
        image,
        description: '',
      }));

      const restoredStructuresImages = structures.map((image: string) => ({
        image,
        description: 'Estruturas Anexas',
      }));

      const restoredObject = {
        ...values,
        images: [...restoredImages, ...restoredStructuresImages],
        image: image[0],
      };

      selectedId && selectedId.length > 0
        ? await service.update(Number(selectedId), restoredObject)
        : await service.create({
            ...restoredObject,
            type: type,
            businessId: item?.status === 'approved' ? item?.id : null,
          });

      setMessageInfo({ title: 'Sucesso', message: 'Dados salvos com sucesso' });
      setShowModal(true);
    } catch (error) {
      setMessageInfo({ title: 'Erro', message: 'Erro ao salvar os dados' });
      setShowModal(true);
    }
    closeLoading();
  };

  const closeModal = () => {
    setShowModal(false);

    if (messageInfo.title === 'Sucesso') {
      history.push('/admin/trade/my-business');
    }
  };

  return (
    <Container>
      <Formik
        initialValues={{ ...transportsInitialValues, ...item }}
        validationSchema={transportsValidationSchema}
        onSubmit={() => setShowConfirmModal(true)}
        enableReinitialize>
        {formik => (
          <Form>
            <FormList>
              <FormListRow className="col-4">
                <label htmlFor="businessRepresentative">
                  Nome Completo do Responsável Comercial <span>*</span>
                </label>
                <Field id="businessRepresentative" name="businessRepresentative" type="text" />
                <ErrorMessageBox>
                  <ErrorMessage name="businessRepresentative" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow className="col-4">
                <label htmlFor="businessOwner">
                  Nome Completo do(s) Proprietário(s) <span>*</span>
                </label>
                <BusinessOwner>
                  <ArrayField name="businessOwner" />
                </BusinessOwner>
              </FormListRow>
              <FormListRow>
                <SelectComponent
                  name="businessType"
                  options={transportCategories}
                  label="Classificação"
                  onChange={value => formik.setFieldValue('businessType', value)}
                  selected={formik.values.businessType ? [formik.values.businessType] : []}
                  required
                />
                <ErrorMessageBox>
                  <ErrorMessage name="businessType" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow>
                <label htmlFor="tradeName">
                  Nome Fantasia <span>*</span>
                </label>
                <Field id="tradeName" name="tradeName" type="text" />
                <ErrorMessageBox>
                  <ErrorMessage name="tradeName" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow>
                <label htmlFor="title" style={{ display: 'flex', alignItems: 'center' }}>
                  Título da Atividade <span>*</span>
                  <Tooltip text="Campo referente ao título que aparecerá no card para visualização do turista." />
                </label>
                <Field id="title" name="title" type="text" />
                <ErrorMessageBox>
                  <ErrorMessage name="title" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow>
                <label htmlFor="document">
                  CNPJ <span>*</span>
                </label>
                <FieldMask type="cnpj">
                  <Field id="document" name="document" type="text" />
                </FieldMask>
                <ErrorMessageBox>
                  <ErrorMessage name="document" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow>
                <label htmlFor="address.zipCode">
                  CEP <span>*</span>
                </label>
                <FieldMask type="zipcode">
                  <Field id="address.zipCode" name="address.zipCode" type="text" />
                </FieldMask>
                <ErrorMessageBox>
                  <ErrorMessage name="address.zipCode" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow className="col-2">
                <label htmlFor="address.street">
                  Endereço <span>*</span>
                </label>
                <Field id="address.street" name="address.street" type="text" />
                <ErrorMessageBox>
                  <ErrorMessage name="address.street" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow>
                <label htmlFor="address.number">
                  Número <span>*</span>
                </label>
                <Field id="address.number" name="address.number" type="text" />
                <ErrorMessageBox>
                  <ErrorMessage name="address.number" />
                </ErrorMessageBox>
              </FormListRow>

              <FormListRow>
                <label htmlFor="address.additionalData">Complemento</label>
                <Field id="address.additionalData" name="address.additionalData" type="text" />
                <ErrorMessageBox>
                  <ErrorMessage name="address.additionalData" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow>
                <label htmlFor="address.neighborhood">
                  Bairro <span>*</span>
                </label>
                <Field id="address.neighborhood" name="address.neighborhood" type="text" />
                <ErrorMessageBox>
                  <ErrorMessage name="address.neighborhood" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow>
                <label htmlFor="address.city">
                  Cidade <span>*</span>
                </label>
                <Field id="address.city" name="address.city" type="text" />
                <ErrorMessageBox>
                  <ErrorMessage name="address.city" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow>
                <SelectComponent
                  name="address.state"
                  options={states}
                  label="Estado"
                  onChange={value => formik.setFieldValue('address.state', value)}
                  selected={formik.values.address.state ? [formik.values.address.state] : []}
                  required
                />
                <ErrorMessageBox>
                  <ErrorMessage name="address.state" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow>
                <label htmlFor="googleMapsLink" style={{ display: 'flex', alignItems: 'center' }}>
                  Place ID do Google Maps <span>*</span>
                  <a
                    href={placeIDLink}
                    target="_blank"
                    rel="noreferrer"
                    title="Clique aqui para baixar o PDF de instruções sobre o PlaceID do Google Maps">
                    <img src={questionMarkIcon} alt="Mais informações" />
                  </a>
                </label>
                <Field id="googleMapsLink" name="googleMapsLink" type="text" />
                <ErrorMessageBox>
                  <ErrorMessage name="googleMapsLink" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow className="col-4">
                <TextArea name="description" title="Descrição" isRequired maxLength={750} />
                {/* <ErrorMessageBox className="for-textarea">
                  <ErrorMessage name="description" />
                </ErrorMessageBox> */}
              </FormListRow>
              <FormListRow>
                <label htmlFor="phone">
                  Telefone <span>*</span>
                </label>
                <FieldMask type="phone" id="phone">
                  <Field id="phone" name="phone" type="text" />
                </FieldMask>
                <ErrorMessageBox>
                  <ErrorMessage name="phone" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow>
                <label htmlFor="whatsapp">WhatsApp</label>
                <FieldMask type="phone" id="whatsapp">
                  <Field id="whatsapp" name="whatsapp" type="text" />
                </FieldMask>
                <ErrorMessageBox>
                  <ErrorMessage name="whatsapp" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow className="col-2">
                <label htmlFor="tradeEmail">E-mail Comercial</label>
                <FieldMask type="email" id="tradeEmail">
                  <Field id="tradeEmail" name="tradeEmail" type="text" />
                </FieldMask>
                <ErrorMessageBox>
                  <ErrorMessage name="tradeEmail" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow>
                <label htmlFor="youtubeLink">Link de Vídeo do YouTube</label>
                <Field id="youtubeLink" name="youtubeLink" type="text" />
                <ErrorMessageBox>
                  <ErrorMessage name="youtubeLink" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow>
                <label htmlFor="site">Link do Site</label>
                <Field id="site" name="site" type="text" />
                <ErrorMessageBox>
                  <ErrorMessage name="site" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow>
                <label htmlFor="instagramLink">Link do Instagram</label>
                <Field id="instagramLink" name="instagramLink" type="text" />
                <ErrorMessageBox>
                  <ErrorMessage name="instagramLink" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow>
                <label htmlFor="facebookLink">Link do Facebook</label>
                <Field id="facebookLink" name="facebookLink" type="text" />
                <ErrorMessageBox>
                  <ErrorMessage name="facebookLink" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow className="col-4">
                <UploadImage
                  descriptionText="Imagem do Card: Adicione 1 imagem. Para garantir a qualidade da visualização, as imagens precisam ter dimensão mínima de 500 x 600 pixels"
                  multipleFiles={false}
                  limitFiles={1}
                  htmlFor="image"
                  name="image"
                  required
                />
                <ErrorMessageBox className="for-uploadimages">
                  <ErrorMessage name="image" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow className="col-4">
                <UploadImage
                  descriptionText="Imagens da Biblioteca: Adicione até 12 imagens. Para garantir a qualidade da visualização, as imagens precisam ter dimensão mínima de 572 x 432 pixels"
                  multipleFiles
                  limitFiles={12}
                  htmlFor="images"
                  name="images"
                  required
                />
                <ErrorMessageBox className="for-uploadimages">
                  <ErrorMessage name="images" />
                </ErrorMessageBox>
              </FormListRow>
              <FormListRow className="col-4">
                <FormListButtonBox>
                  <Button type="button" buttonType="secondary" onClick={() => history.push('/admin/trade/my-business')}>
                    Cancelar
                  </Button>
                  <Button type="submit">{selectedId && selectedId.length > 0 ? 'Salvar' : 'Criar'}</Button>
                </FormListButtonBox>
              </FormListRow>
            </FormList>
            <ConfirmationModal
              message="O cadastro do seu negócio precisa ser aprovado pela Secretaria do Turismo. Após o envio do formulário, você só poderá editar os dados de negócios aprovados. Deseja Continuar?"
              title="Cadastro de Negócio"
              onConfirm={() => {
                onSubmit(formik.values);
                setShowConfirmModal(false);
              }}
              onCancel={() => setShowConfirmModal(false)}
              confirmText="Enviar"
              cancelText="Cancelar"
              open={showConfirmModal}
              type="submit"
            />
          </Form>
        )}
      </Formik>
      <MessageModal isOpen={showModal} title={messageInfo.title} message={messageInfo.message} onClose={closeModal} />
    </Container>
  );
};

export default MyBusinessTransportsForm;
