import React, { useRef, useState } from 'react';
import yup from 'utils/validation';
import { Container, DeleteAccountButton, Divider, FooterButtonsForm, FormRow, LabelData } from './styles';
import { Form, Formik, FormikProps, FormikValues, FormikHelpers } from 'formik';
import Button from 'components/Buttom/Button';
import ToggleField from 'components/ToggleField';
import ProfileImage from 'components/ProfileImage';
import { usePortalContext } from 'common/PortalContext';

interface Props {
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  saveUserData?: (userData: any) => Promise<void>;
  userData: any;
}

const TouristAccount = (props: Props): JSX.Element => {
  const form = useRef<FormikProps<FormikValues>>(null);
  const [editing, setEditing] = useState(false);
  const { setShowModal } = props;
  const PortalContext = usePortalContext();

  const validationSchema = yup.object({
    email: yup.string().matches(/^(?!\d+$).*/, 'O campo email não pode ser apenas números'),
    name: yup.string(),
    extraInfo: yup.object({
      birthData: yup.string().length(10),
      gender: yup.string(),
      nationality: yup.string(),
      address: yup.object({
        additionalData: yup.string(),
        city: yup.string(),
        neighborhood: yup
          .string()
          .matches(/^(?!^\d+$)[\d\D]*$/, 'O bairro não pode conter somente números')
          .required(),
        number: yup.string().matches(/^\d+$/, 'Número deve conter apenas dígitos'),
        state: yup.string(),
        street: yup.string(),
        zipCode: yup
          .string()
          .matches(/^\d{5}-?\d{3}$/, 'CEP deve ter o formato XXXXX-XXX')
          .test('is-not-equal', 'CEP não pode conter todos os números iguais', function (value) {
            if (!value) return false;
            const cleanedValue = value.replace('-', '');
            return !/^(.)\1+$/.test(cleanedValue);
          }),
      }),
    }),
  });

  const handleCancelButtonClick = () => {
    form?.current?.setValues(props.userData);
    setEditing(false);
  };

  const handleSaveButtonClick = async () => {
    const newData = JSON.parse(localStorage.getItem('userData') || '{}');
    newData.name = form?.current?.values.name;
    localStorage.setItem('userData', JSON.stringify(newData));
    PortalContext.setPortalUser(form?.current?.values.name);

    await form?.current?.submitForm();

    if (!form?.current?.isValid) {
      return;
    }

    setEditing(false);
  };

  const onlyViewForm = (): JSX.Element => {
    const { extraInfo: userInfo, ...userData } = props.userData;
    const { address, ...extraInfo } = userInfo || {};

    const values = {
      email: '',
      name: '',
      ...userData,
      extraInfo: {
        birthData: '',
        gender: '',
        nationality: '',
        ...extraInfo,
        address: {
          additionalData: '',
          city: '',
          neighborhood: '',
          number: '',
          state: '',
          street: '',
          zipCode: '',
          ...address,
        },
      },
    };

    return (
      <Formik
        innerRef={form}
        initialValues={values}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}>
        <Form>
          <FormRow id="only-view-first-row">
            <section>
              <article className="profile-image">
                <ProfileImage name="profileImage" />
              </article>
              <article className="profile-info">
                {editing && <label>Nome Completo</label>}
                <ToggleField showField={editing} name="name" />
                {!editing && <p>Turista</p>}
              </article>
            </section>
            {!editing && <Button text={'EDITAR'} onClick={() => setEditing(true)} />}
          </FormRow>
          <FormRow id="only-view-second-row">
            <LabelData>
              <label>Data de Nascimento</label>
              <ToggleField showField={editing} name="extraInfo.birthData" mask="date" />
            </LabelData>
            <LabelData>
              <label>Gênero</label>
              <ToggleField showField={editing} name="extraInfo.gender" />
            </LabelData>
            <LabelData>
              <label>Nacionalidade</label>
              <ToggleField showField={editing} name="extraInfo.nationality" />
            </LabelData>
          </FormRow>
          <FormRow id="only-view-third-row">
            <LabelData>
              <label>CEP</label>
              <ToggleField showField={editing} name="extraInfo.address.zipCode" mask="zipcode" />
            </LabelData>
            <LabelData>
              <label>Endereço</label>
              <ToggleField showField={editing} name="extraInfo.address.street" />
            </LabelData>
            <LabelData>
              <label>Número</label>
              <ToggleField showField={editing} name="extraInfo.address.number" />
            </LabelData>
          </FormRow>
          <FormRow id="only-view-fourth-row">
            <LabelData>
              <label>Complemento</label>
              <ToggleField showField={editing} name="extraInfo.address.additionalData" />
            </LabelData>
            <LabelData>
              <label>Bairro</label>
              <ToggleField showField={editing} name="extraInfo.address.neighborhood" />
            </LabelData>
            <LabelData>
              <label>Cidade</label>
              <ToggleField showField={editing} name="extraInfo.address.city" />
            </LabelData>
            <LabelData>
              <label>Estado</label>
              <ToggleField showField={editing} name="extraInfo.address.state" />
            </LabelData>
          </FormRow>
          <FormRow id="only-view-fifth-row">
            <LabelData>
              <label>E-mail</label>
              <ToggleField disabled={true} showField={editing} name="email" />
            </LabelData>
          </FormRow>
        </Form>
      </Formik>
    );
  };

  const handleSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
    if (props.saveUserData) {
      await props.saveUserData(values);
    }
  };

  return (
    <Container>
      {onlyViewForm()}
      {editing && (
        <div className="editButtons">
          <Button text={'Cancelar'} onClick={handleCancelButtonClick} />
          <Button text={'Salvar'} type="submit" onClick={handleSaveButtonClick} />
        </div>
      )}
      <Divider />
      <FooterButtonsForm>
        <div style={{ display: 'flex' }}>
          {/* <DeleteAccountButton onClick={() => setShowModal && setShowModal(true)}>
            <img src="" alt="" />
            <span>Excluir conta</span>
          </DeleteAccountButton> */}
        </div>
      </FooterButtonsForm>
    </Container>
  );
};

export default TouristAccount;
